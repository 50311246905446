import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Box, Button, Flex, Text } from "rebass";
import {
  RiCopyleftLine,
  RiFileCopy2Line,
  RiMailSettingsLine,
  RiSurveyLine,
} from "react-icons/ri";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Settings from "../ProductSettings";
import { PlaybookIcon, WorkflowIcon } from "../../../Common/Icons";
import PlaybookList from "../Playbook/PlaybookList";
import EmailTemplateList from "../EmailTemplate/EmailTemplateList";
import SurveysList from "../SurveyQuestions/SurveysList";
import { useUrlParams } from "../../../../hooks/useUrlParams";
import AdminOnly from "../../AdminOnly";
import AutomationsCopyMutation from "../../../../graphql/mutations/AutomationsCopy";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@apollo/client";
import { SuccessParams } from "../../../Common/Notifications/Success";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";

const tabs = ["playbooks", "emails", "surveys"];
function Library(props) {
  const { addToast } = useToasts();
  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const defaultTab = getUrlParameter("tab") || "playbooks";

  const [automationsCopy] = useMutation(AutomationsCopyMutation);
  const [selectedTab, setSelectedTab] = useState(
    tabs.indexOf(tabs.find((t) => t.toLowerCase() === defaultTab.toLowerCase()))
  );
  return (
    <Settings>
      <Flex width="100%">
        <Flex p={2} flex={1} flexDirection="column">
          <Text variant="h2" mb={2}>
            Automation Library
          </Text>
          <Tabs
            width="100%"
            selectedIndex={selectedTab}
            onSelect={(index) => {
              setSelectedTab(index);

              const tab = tabs[index];
              window.history.replaceState(
                {},
                document.title,
                `${window.location.pathname}?tab=${tab}`
              );
            }}
          >
            <TabList>
              <Flex>
                <Tab>
                  <PlaybookIcon /> Playbooks
                </Tab>
                <Tab>
                  <RiMailSettingsLine /> Email Templates
                </Tab>
                <Tab>
                  <RiSurveyLine /> Surveys
                </Tab>
                <Flex flex={1} justifyContent={"flex-end"}>
                  <AdminOnly>
                    <Box>
                      <PrimaryButton
                        sx={{
                          bg: "warning",
                        }}
                        onClick={() => {
                          automationsCopy({}).then(
                            (response) => {
                              addToast(
                                <Flex>
                                  Copying template - please refresh in a minute
                                </Flex>,
                                SuccessParams
                              );
                            },
                            (error) => {}
                          );
                        }}
                      >
                        <RiFileCopy2Line /> Copy Templates
                      </PrimaryButton>
                    </Box>
                  </AdminOnly>
                </Flex>
              </Flex>
            </TabList>
            <TabPanel key="PlaybookList">
              <Flex mx={3}>
                <PlaybookList />
              </Flex>
            </TabPanel>
            <TabPanel key="EmailTemplatesList">
              <Flex mx={3}>
                <EmailTemplateList />
              </Flex>
            </TabPanel>
            <TabPanel key="SurveyList">
              <Flex mx={3}>
                <SurveysList />
              </Flex>
            </TabPanel>
          </Tabs>
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(Library);
