import React from "react";
import { Flex, Button, Text, Box } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Prompt } from "react-router-dom";
import { FieldArray, Formik } from "formik";
import { MODEL_TYPES } from "consts";
import { getChurnReasons } from "hooks/getChurnReasons";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { PlusIcon, TrashIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import ChurnReasonMutation from "../../../../graphql/mutations/ChurnReason.gql";

function ChurnReasonForm(props) {
  const { data, refetch } = getChurnReasons();
  const { addToast } = useToasts();
  const [save, {}] = useMutation(ChurnReasonMutation);

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={{
          churnReasons: data?.churnReasons || [{ name: "" }],
        }}
        autoComplete={false}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values, actions) => {
          save({
            variables: {
              churnReasons: values.churnReasons.map((r) => {
                return { id: r.id || null, name: r.name };
              }),
            },
          }).then(
            (response) => {
              if (response.data?.churnReason?.errors) {
                addToast(
                  <Flex>{response.data?.churnReason?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                actions.resetForm({
                  values: response.data?.churnReason?.churnReasons,
                });
                props.onComplete && props.onComplete();
                addToast(<Flex>Saved!</Flex>, SuccessParams);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, dirty, handleSubmit } = formProps;
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <FieldArray
                name="churnReasons"
                render={(arrayHelpers) => (
                  <Box my={2}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text variant="h2">Churn Reasons</Text>
                      <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                    </Flex>
                    <Box pt={2}>
                      <Text>
                        Define a list of Churn Reasons which indicate why an
                        account has stopped using your product.
                      </Text>
                    </Box>
                    {values.churnReasons && values.churnReasons.length > 0
                      ? values.churnReasons.map((field, index) => (
                          <Flex
                            key={index}
                            alignItems="center"
                            justifyContent="flex-start"
                            my={2}
                          >
                            <CustomFormGroup
                              placeholder="Churn Reason"
                              name={`churnReasons[${index}].name`}
                              value={field.name}
                              onChange={(name, value) =>
                                setFieldValue(name, value)
                              }
                            />
                            {index > 0 && (
                              <Button
                                m={1}
                                variant="ghost"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <TrashIcon />
                                </Flex>
                              </Button>
                            )}
                          </Flex>
                        ))
                      : undefined}
                    <Flex justifyContent="space-between">
                      <PrimaryButton
                        onClick={() =>
                          arrayHelpers.push({
                            name: "",
                          })
                        }
                        leftIcon={<PlusIcon size="12" />}
                      >
                        Add Reason
                      </PrimaryButton>
                    </Flex>
                  </Box>
                )}
              />
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default ChurnReasonForm;
