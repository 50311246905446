import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text, Button, Box, Link } from "rebass";
import PropTypes from "prop-types";
import UserBadge from "../Common/UserBadge";
import moment from "moment";

export const ActivityCommentTitle = ({ activity }) => {
  return (
    <Flex>
      <Text fontWeight={"bold"} mr={1}>
        Note
      </Text>
      added
    </Flex>
  );
};
function ActivityComment({ activity, refetch }) {
  const [open, setOpen] = useState(false);
  return (
    <Flex flexDirection={"column"} bg="gray.100" p={3}>
      <Flex justifyContent={"space-between"} alignItems="center">
        <UserBadge
          height={30}
          name={activity.actionable?.user?.name}
          email={activity.actionable?.user?.email}
          avatarUrl={activity.actionable?.user?.avatarUrl}
        />
        <Text variant="muted">
          {moment(activity?.actionable?.createdAt).format(
            "DD MMM YYYY hh:mm a"
          )}
        </Text>
      </Flex>
      <Text
        p={3}
        bg="gray.100"
        dangerouslySetInnerHTML={{
          __html: activity.actionable?.comment,
        }}
      />
    </Flex>
  );
}
ActivityComment.propTypes = {
  activity: PropTypes.object,
};
export default ActivityComment;
