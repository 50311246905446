import { useQuery } from "@apollo/react-hooks";
import EventGroupsQuery from "../graphql/queries/EventGroups.gql";

export function getEventGroups() {
  const eventGroupsQuery = useQuery(EventGroupsQuery, {});

  return {
    ...eventGroupsQuery,
  };
}
