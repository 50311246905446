import React, { useState } from "react";
import { Text, Flex, Image, Box } from "rebass";
import { getProduct } from "hooks/getProduct";
import RouterLink from "./Link";
import { onAdminDomain } from "../../../auth-helper";
import AdminOnly from "../AdminOnly";

const ProductSidebar = (props) => {
  const { data: productData } = getProduct();
  const currentProduct = productData?.currentProduct;
  return (
    <Flex
      py={3}
      flexDirection="column"
      sx={{
        fontFamily: "body",
        width: "250px",
      }}
    >
      <Text mt={3} variant="menuHeading">
        <Flex alignItems="center" justifyContent="flex-end">
          {currentProduct?.logoUrl && (
            <Box sx={{ borderRadius: 9999, width: 30, height: 30 }}>
              <Image
                src={currentProduct?.logoUrl}
                sx={{ maxHeight: 30, maxWidth: 30 }}
              />
            </Box>
          )}
          <Text ml={2}>{currentProduct?.name} Settings</Text>
        </Flex>
      </Text>
      <RouterLink to="/settings/product/general">General</RouterLink>
      <RouterLink to="/settings/product/teams">Teams</RouterLink>
      <RouterLink to="/settings/product/fields">Custom Fields</RouterLink>
      <Text mt={3} variant="menuHeading">
        Process Automations
      </Text>
      <RouterLink to="/settings/product/library">Automation Library</RouterLink>
      <RouterLink to="/settings/product/automations">Automations</RouterLink>
      <RouterLink to="/settings/product/plans">Lifecycle Journeys</RouterLink>
      <Text mt={3} variant="menuHeading">
        Configuration
      </Text>
      <RouterLink to="/settings/product/healthscores">Health Score</RouterLink>
      <RouterLink to="/settings/product/trialconversion">
        Trial Qualification
      </RouterLink>
      <RouterLink to="/settings/product/statuses">
        Status &amp; Lifecycle
      </RouterLink>
      <RouterLink to="/settings/product/events">Events</RouterLink>
      <Text mt={3} variant="menuHeading">
        Integration
      </Text>
      <RouterLink to="/settings/product/api">Api Keys</RouterLink>
      <RouterLink to="/settings/product/integrations">Integrations</RouterLink>
      <RouterLink to="/settings/product/import">Import</RouterLink>
      <RouterLink to="/settings/product/logs">Logs</RouterLink>
      <AdminOnly>
        <RouterLink to="/settings/product/dev-logs">Dev Logs</RouterLink>
      </AdminOnly>
    </Flex>
  );
};

ProductSidebar.propTypes = {};

export default ProductSidebar;
