import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Flex, Text, Box } from "rebass";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";
import Modal from "Components/Common/Modal";
import Avatar from "react-avatar";
import { MODEL_TYPES } from "consts";
import CommandCenter from "Components/Dashboard/CommandCenter";
import { getUsers } from "hooks/getUsers";
import { getCurrentUser } from "hooks/getCurrentUser";
import TaskForm from "./TaskForm";
import TaskList from "./TaskList";
import TaskMenu from "./TaskMenu";
import DatePicker, { defaultDate, getLabel } from "../DatePicker";
import TaskFilter from "./TaskFilter";
import Filter from "Components/Filter";
import { getSearchFilter } from "../../../hooks/getSearchFilter";
import UserlotCalendar from "./Calendar";
import TaskMutation from "../../../graphql/mutations/Task.gql";
import TasksQuery from "../../../graphql/queries/Tasks.gql";
import TaskKanban from "./TaskKanban";
import { getProduct } from "hooks/getProduct";
import TaskDashboard from "./TaskDashboard";

function Tasks(props) {
  const history = useHistory();

  const { id } = useParams();

  const usersQuery = getUsers();
  const { data: currentProductData } = getProduct();
  const currentUser = getCurrentUser();
  const [forceRefresh, setForceRefresh] = useState();
  const [viewType, setViewType] = useState("list");
  const [includeDone, setIncludeDone] = useState(true);
  const [includeOverdue, setIncludeOverdue] = useState(true);
  const [event, setEvent] = useState(undefined);
  const [defaultCalendarView, setDefaultCalendarView] = useState("week");
  const [open, setOpen] = useState();
  const { data: searchFilters } = getSearchFilter();
  const [save] = useMutation(TaskMutation);
  const [taskType, setTaskType] = useState({
    type: "date",
    startDate: defaultDate?.startDate,
    endDate: defaultDate?.endDate,
  });
  const [activityTypes, setActivityType] = useState([]);

  const ownerUserId = searchFilters?.filters?.ownerUserId;
  const startOffset = moment(taskType.startDate).diff(
    moment({ hours: 0 }),
    "days"
  );
  const endOffset = moment(taskType.endDate).diff(moment(), "days");

  const tasksQuery = useQuery(TasksQuery, {
    variables: {
      ownerUserId: searchFilters?.filters?.ownerUserId,
      teamId: searchFilters?.filters?.teamId,
      segmentId: searchFilters?.filters?.segmentId,
      activityTypes,
      startDays: startOffset || 0,
      endDays: endOffset || 0,
      includeDone,
      includeOverdue,
    },
    skip: !searchFilters && activityTypes.length > 0,
  });
  const { data: tasks, loading } = tasksQuery;

  const filteredTasks = tasksQuery.data?.tasks;
  const accountOptions = [
    { value: undefined, label: "All Users" },
    { value: currentUser?.currentUser?.id, label: "My Accounts" },
  ];
  usersQuery.data?.users?.map((user) => {
    if (user.id !== currentUser?.currentUser?.id) {
      accountOptions.push({ value: user.id, label: user.name });
    }
  });

  useEffect(() => {
    if (!loading && id) {
      let task = tasksQuery.data?.tasks?.find((t) => t.id == id);
      if (task) {
        setEvent(task);
        setOpen(true);
      }
    }
  }, [tasksQuery.loading, id]);

  const handleMoveEvent = (event) => {
    save({
      variables: {
        tasks: [
          {
            id: event.id ? event.id : null,
            actionableId: event.actionableId,
            actionableType: event.actionableType,
            recurrenceCode: event.recurrenceCode,
            title: event.title,
            description: event.description,
            taskCategoryId: event.taskCategoryId,
            priority: event.priority,
            dueAt: event.dueAt,
            endAt: event.endAt,
            userId: event.user.id,
            teamId: event.team.id,
            taskStatusId: event.taskStatusId,
            taskStatusName: event.taskStatusName,
            busy: event.busy,
            allDay: event.allDay,
          },
        ],
      },
    }).then(
      (response) => {
        setForceRefresh(new Date());
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const refetch = () => {
    tasksQuery
      .refetch({
        variables: {
          ownerUserId: ownerUserId,
          activityTypes,
          startDays: startOffset || 0,
          endDays: endOffset || 0,
          includeDone,
          includeOverdue,
        },
      })
      .then((result) => {});
  };
  const getCalendarView = (startDate, endDate) => {
    const label = getLabel(startDate, endDate);
    let view = "week";
    switch (label) {
      case "Today":
      case "Yesterday":
      case "Tomorrow":
        view = "day";
        break;
      case "Last Week":
      case "This Week":
        view = "week";
        break;
      case "Last Month":
      case "This Month":
        view = "month";
        break;
      default:
        view = "week";
        break;
    }
    setDefaultCalendarView(view);
  };

  return (
    <CommandCenter>
      <Filter
        inlineSegments
        menu={<Text variant="h2">Activities</Text>}
        onChange={() => {
          setForceRefresh(new Date());
        }}
        typeName={MODEL_TYPES.Company.name}
      >
        <Flex width="100%" flexDirection="column" px={3}>
          <Flex flex={1} alignItems="center" justifyContent="space-between">
            <TaskMenu
              viewType={viewType}
              onViewChange={(value) => setViewType(value)}
              onAdd={(taskCategoryId) => {
                setEvent({
                  taskCategoryId: taskCategoryId,
                  product: currentProductData?.currentProduct,
                });
                setOpen(true);
              }}
            />
            <Flex flex={1} flexWrap="flex" justifyContent="flex-end">
              <TaskFilter
                onChange={(types) => setActivityType(types)}
                types={activityTypes}
                includeDone={includeDone}
                includeOverdue={includeOverdue}
                onIncludeOverdue={() => {
                  setIncludeOverdue(!includeOverdue);
                }}
                onIncludeDone={() => {
                  setIncludeDone(!includeDone);
                }}
              />
              <Box mx={1} />
              <DatePicker
                dates={taskType}
                onChange={(type, startDate, endDate) => {
                  getCalendarView(startDate, endDate);
                  setTaskType({
                    type,
                    startDate: startDate || taskType.startDate,
                    endDate: endDate || taskType.endDate,
                  });
                }}
              />
            </Flex>
          </Flex>
          <TaskDashboard
            filters={searchFilters?.filters}
            startOffset={startOffset}
            endOffset={endOffset}
            includeDone={includeDone}
            includeOverdue={includeOverdue}
            activityTypes={activityTypes}
            onClick={() => {
              setForceRefresh(new Date());
            }}
          />
          <Flex flex={1} mt={3} width="100%">
            {viewType === "list" && (
              <TaskList
                data={filteredTasks}
                loading={loading}
                detailedView
                onSelected={(ev) => {
                  window.history.replaceState(
                    {},
                    document.title,
                    `/tasks/${ev.id}`
                  );
                  setEvent(ev);
                  setOpen(true);
                }}
                onSave={() => {
                  console.log("refetching 2");
                  refetch();
                  setOpen(false);
                }}
              />
            )}

            {viewType === "calendar" && (
              <Flex
                width={"100%"}
                bg="white"
                p={3}
                alignItems="center"
                justifyContent="center"
              >
                <UserlotCalendar
                  key={defaultCalendarView}
                  onMoveEvent={handleMoveEvent}
                  data={filteredTasks.filter((task) => !task.completedAt)}
                  height={"65vh"}
                  defaultView={defaultCalendarView}
                  onEventSelected={(event) => {
                    setEvent(event);
                    if (event && event.type !== "Task") {
                      history.push(`/tasks/accounts/${event.id}`);
                    } else {
                      window.scrollTo(0, 0);
                      setOpen(true);
                    }
                  }}
                />
              </Flex>
            )}
            {viewType === "column" && (
              <Flex
                width="100%"
                p={3}
                my={3}
                alignItems="center"
                justifyContent="center"
                bg="gray.100"
              >
                <TaskKanban
                  data={filteredTasks}
                  loading={loading}
                  onSelected={(event) => {
                    setEvent(event);
                    setOpen(true);
                    window.scrollTo(0, 0);
                  }}
                  onSave={() => {
                    console.log("refetching 3");
                    refetch();
                    setOpen(false);
                  }}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Filter>
      {open && (
        <Modal
          title={
            <Flex justifyContent="center" alignItems="center">
              <Avatar
                round
                name={event?.product?.name || "New"}
                src={event?.product?.logoUrl}
                size="30"
              />
              <Text mx={2}>Activity</Text>
            </Flex>
          }
          open={open}
          width="950px"
          onDismiss={() => {
            setOpen(false);
            setEvent(undefined);
          }}
        >
          <Flex ml={2} flex={1}>
            <TaskForm
              open
              id={event?.id}
              parentType={event?.actionableType}
              task={event}
              tasks={tasks?.tasks}
              onSave={() => {
                refetch();
                setOpen(false);
                setEvent(undefined);
              }}
              onClose={() => {
                setOpen(false);
                setEvent(undefined);
              }}
            />
          </Flex>
        </Modal>
      )}
    </CommandCenter>
  );
}

export default Tasks;
