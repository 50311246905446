import { useMutation } from "@apollo/react-hooks";
import { Input } from "@rebass/forms";
import React, { useEffect, useState } from "react";
import { Flex } from "rebass";
import { useTheme } from "emotion-theming";
import { getSearchFilter } from "hooks/getSearchFilter";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import { CloseIcon, SearchIcon, TagIcon } from "../../Common/Icons";
import MenuMultiSelect from "../../Common/Forms/MenuMultiSelect";
import TagsQuery from "../../../graphql/queries/Tags.gql";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import DebouncedInput from "../../Common/Table/components/DebouncedInput";

function CompanyFilter(props) {
  const theme = useTheme();
  const { data: filters } = getSearchFilter();
  const [searchText, setSearchText] = useState(filters?.filters?.filterText);
  const [saveFilter] = useMutation(SearchFilterMutation);
  const [selectedTags, setSelectedTags] = useState([]);

  const { data: tags } = useQuery(TagsQuery, {
    variables: {
      modelType: "tags",
    },
  });

  useEffect(() => {
    if (filters?.filters) {
      if (!_.isEqual(filters?.filters[props.module].filterText, searchText)) {
        setSearchText(filters?.filters[props.module].filterText);
      }
      if (!_.isEqual(filters.filters.tags, selectedTags)) {
        setSelectedTags(filters?.filters?.tags);
      }
    }
  }, [filters?.filters]);

  useEffect(() => {
    if (filters?.filters && !_.isEqual(filters.filters.tags, selectedTags)) {
      if (selectedTags.length > 0) {
        updateFilter("tags", selectedTags, `Tags (${selectedTags.length})`);
      } else {
        updateFilter("tags", [], undefined);
      }
    }
  }, [selectedTags]);

  const updateFilter = (name, id, label) => {
    const newFilters = filters?.filters;

    let ref = newFilters;
    let nestedNames = name.split(".").reverse();
    while (nestedNames.length > 1) {
      const n = _.last(nestedNames);
      ref[n] = {};
      ref = ref[n];
      nestedNames = nestedNames.slice(0, -1);
    }
    const n = _.last(nestedNames);
    ref[n] = id;

    if (!newFilters.labels) {
      newFilters.labels = {};
    }
    newFilters.labels[name] = label;
    saveFilter({
      variables: {
        filters: newFilters,
      },
    }).then(
      (response) => {
        props.onChange && props.onChange();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex alignItems="center" justifyContent="flex-end">
      <MenuMultiSelect
        key={selectedTags?.length || 0}
        onChange={(types) => {
          setSelectedTags(types);
        }}
        options={tags?.tags.map((o) => {
          return { value: o, label: o };
        })}
        value={selectedTags || []}
        icon={TagIcon}
      />
      <Flex>
        <DebouncedInput
          value={searchText}
          onChange={(value) => {
            if (value != undefined) {
              updateFilter(`${props.module}.filterText`, value, value);
            }
          }}
          onClear={() => {
            setSearchText("");
            updateFilter(`${props.module}.filterText`, "", undefined);
          }}
        />
      </Flex>
    </Flex>
  );
}
CompanyFilter.propTypes = {
  module: PropTypes.string,
  onChange: PropTypes.func,
};
export default CompanyFilter;
