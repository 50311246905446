import React, { useState } from "react";
import { Box, Flex, Text } from "rebass";
import { CalendarIcon, TimeIcon } from "Components/Common/Icons";
import { RiCheckboxBlankFill } from "react-icons/ri";
import ProgressBar from "@ramonak/react-progress-bar";
import { useTheme } from "emotion-theming";
import moment from "moment";
import { rearg } from "lodash";

const TaskTimeline = ({ row }) => {
  const [isHovering, setIsHovering] = useState(false);
  const theme = useTheme();

  let duration = moment(row.endAt).diff(moment(row.dueAt), "days") + 1;

  let period = "day";
  let type = "duration";
  let d = moment(row.endAt).diff(moment(row.dueAt), "hours");

  if (d <= 8 && row.allDay) {
    type = "milestone";
    percentage = moment(row.endAt).isAfter(moment(), "day") ? 100 : 0;
  } else if (d <= 8) {
    duration = d;
    period = "hour";
    type = "meeting";
  }

  period = duration > 1 ? period + "s" : "day";

  let currentDuration = moment(moment().utc()).diff(row.dueAt, "minutes");
  let totalDuration = moment(row.endAt).diff(moment(row.dueAt), "minutes");
  let percentage =
    moment(row.endAt).utc().isBefore(moment().utc()) || totalDuration == 0
      ? 0 // Has not started
      : (currentDuration / totalDuration).toFixed(2) * 100;

  let primaryColor = "green";
  let secondaryColor = "";
  let progressColor = theme.colors.greenDark;
  if (row.taskStatusName === "Done") {
    // check done flag
    primaryColor = "gray.800";
    secondaryColor = "gray.100";
    progressColor = theme.colors.gray[500];
  } else {
    // It is overdue
    if (moment(row.endAt).utc().isBefore(moment().utc())) {
      primaryColor = "red";
      secondaryColor = "redLight";
      progressColor = theme.colors.red;
    }
  }

  return (
    <Flex
      data-tag="allowRowEvents"
      width="100%"
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      px={2}
      sx={{
        color: primaryColor,
        backgroundColor: secondaryColor,
        borderColor: primaryColor,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 99,
        height: 30,
      }}
    >
      {isHovering ? (
        <Flex data-tag="allowRowEvents" flex={1} alignItems="center">
          <Text data-tag="allowRowEvents" fontSize="xxs">
            {duration} {period}
          </Text>
        </Flex>
      ) : (
        <Flex
          data-tag="allowRowEvents"
          alignItems="center"
          flex={1}
          px={2}
          fontSize="xxs"
        >
          <Text mx={1} mt={1} mb={0}>
            {type === "duration" && <CalendarIcon />}
            {type === "meeting" && <TimeIcon />}
            {type === "milestone" && (
              <Text sx={{ transform: "rotate(45deg)" }}>
                <RiCheckboxBlankFill />
              </Text>
            )}
          </Text>
          {type !== "meeting" ? (
            <Flex>
              <Text data-tag="allowRowEvents">
                {moment(row.dueAt).format("MMM DD")}
              </Text>
              {type === "duration" &&
                !moment(row.endAt).isSame(moment(row.dueAt), "day") && (
                  <>
                    <Text data-tag="allowRowEvents">-</Text>
                    <Text data-tag="allowRowEvents">
                      {moment(row.endAt).format("MMM DD")}
                    </Text>
                  </>
                )}
            </Flex>
          ) : (
            <Flex fontSize="xxs">
              <Text data-tag="allowRowEvents">
                {moment(row.dueAt).isSame(moment().utc(), "day") ? (
                  <Flex>
                    Today
                    <Text ml={1}>{moment(row.dueAt).format("h:mm a")}</Text>
                  </Flex>
                ) : (
                  <Flex>
                    {moment(row.dueAt).format("MMM DD")}
                    <Text ml={1}>{moment(row.dueAt).format("h:mm a")}</Text>
                  </Flex>
                )}
              </Text>
            </Flex>
          )}
        </Flex>
      )}
      {percentage > 0 && row.taskStatusName !== "Done" && (
        <Box width="90%" mb={1}>
          <ProgressBar
            completed={percentage > 100 ? 100.0 : percentage}
            height={5}
            margin="0"
            padding="0"
            bgColor={progressColor}
            baseBgColor={theme.colors.gray[200]}
            isLabelVisible={false}
          />
        </Box>
      )}
    </Flex>
  );
};
export default TaskTimeline;
