import React from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import ApplicationKeyList from "./ApplicationKeyList";
import ApplicationKeyForm from "./ApplicationKeyForm";
import { Flex, Image, Text, Box } from "rebass";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import Settings from "../ProductSettings";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";
import {
  TickIcon,
  MailIcon,
  NotificationIcon,
  PlusIcon,
  SlackIcon,
} from "Components/Common/Icons";

function ApplicationKeys(props) {
  const { id } = useParams();
  return (
    <Settings>
      <Flex width="100%">
        <Flex
          p={2}
          bg="gray.100"
          sx={{
            height: "100%",
            maxWidth: "300px",
          }}
          flex={1}
        >
          <ApplicationKeyList />
        </Flex>
        <Flex flex={1} p={4} bg="white">
          {id && <ApplicationKeyForm />}
          {!id && <EmptyState />}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(ApplicationKeys);

const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex
      p={5}
      flex={1}
      justifyContent="center"
      sx={{
        minHeight: 400,
      }}
    >
      <Flex flex={1} alignItems="flex-start" flexDirection="column">
        <Text variant="h2">Integration</Text>
        <Text my={3}>Integration into Userlot can be through</Text>
        <Box mx={1}>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Webhooks
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Javascript API
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Zapier
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Manual import
          </Flex>
        </Box>
        <Text my={3}>Before importing you will need to:</Text>
        <Box mx={1}>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Set up events
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Categorise events
          </Flex>
        </Box>
        <Flex my={2}>
          Don't forget to check integrations state through dev logs.
        </Flex>
        <Flex width="100%" my={3} justifyContent="space-between">
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/api/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New API
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              history.push("/settings/product/import");
            }}
            leftIcon={<PlusIcon />}
          >
            Manual Import
          </SecondaryButton>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ marginTop: "-10%", height: "150px", objectFit: "contain" }}
        />
      </Flex>
    </Flex>
  );
};
