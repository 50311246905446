import React, { useState } from "react";
import Avatar from "react-avatar";
import { Flex, Text } from "rebass";
import PropTypes from "prop-types";
import { RiUser2Fill } from "react-icons/ri";

function AssignedTo({ user, detailed }) {
  const [isHovering, setIsHovering] = useState(false);
  return user ? (
    <Flex data-tag="allowRowEvents" alignItems="center">
      <Avatar
        src={user?.avatarUrl}
        name={`${user?.firstName || ""} ${user?.lastName || ""}`}
        email={user?.email}
        size="30"
        round
        textSizeRatio={2}
      />
      {detailed && (
        <Text mx={2} fontSize="xs">
          {user?.firstName || ""} {user?.lastName || ""}
        </Text>
      )}
    </Flex>
  ) : (
    <Flex
      justifyContent="center"
      data-tag="allowRowEvents"
      alignItems="flex-end"
      sx={{
        backgroundColor: "gray.200",
        borderRadius: 99,
        width: 30,
        height: 30,
      }}
      color="white"
    >
      <RiUser2Fill size="22" />
    </Flex>
  );
}
AssignedTo.propTypes = {
  row: PropTypes.object,
};

export default AssignedTo;
