import { useMutation, useQuery } from "@apollo/react-hooks";
import React from "react";
import { useToasts } from "react-toast-notifications";
import { Box, Flex, Text } from "rebass";
import Loader from "Components/Common/Loader";
import AssignOwnerMutation from "../../../graphql/mutations/AssignOwner.gql";
import UsersQuery from "../../../graphql/queries/Users.gql";
import UserBadge from "../../Common/UserBadge";

function AssignOwner(props) {
  const { addToast } = useToasts();
  const [save, { loading }] = useMutation(AssignOwnerMutation);
  const { data: userData, loading: userLoading } = useQuery(UsersQuery);

  const users = userData?.users || [];
  const assignOwner = (id) => {
    save({
      variables: {
        companyIds: props.companyIds,
        ownerUserId: !props.shadow ? id : undefined,
        shadowOwnerId: props.shadow ? id : undefined,
      },
    }).then(
      (response) => {
        if (!response.data?.assignOwner?.errors) {
          props.onSave();
        } else {
          addToast(<Flex>{response.data?.relationship?.errors[0]}</Flex>, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flexDirection="column" px={3}>
      {loading ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Loader />
          <Text mx={2}>Please wait we are updating your account's owner</Text>
        </Flex>
      ) : (
        <Flex flexWrap={"wrap"}>
          {users.map((user) => {
            return (
              <Box
                key={user.id}
                p={2}
                width={1 / 2}
                sx={{
                  "&:hover": { backgroundColor: "brandHighlight" },
                  borderRadius: "medium",
                  cursor: "pointer",
                }}
                onClick={() => assignOwner(user.id)}
              >
                <UserBadge
                  name={user.name}
                  subtitle={user.email}
                  email={user.email}
                  avatarUrl={user.avatarUrl}
                />
              </Box>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}

export default AssignOwner;
