import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link, Flex, Button, Text, Box, Image } from "rebass";
import StepWizard from "react-step-wizard";
import UploadFile from "./UploadFile";
import MapFields from "./MapFields";
import PreviewData from "./PreviewData";
import WizardNav from "Components/Common/WizardNav";
import Loader from "Components/Common/Loader";
import ImportQuery from "../../../../graphql/queries/Import.gql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { UploadAnimatedIcon } from "Components/Common/AnimatedIcon";

function ImportWizard(props) {
  const { loading, data, error, refetch } = useQuery(ImportQuery, {});
  const [importData, setImportData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (data && data.import) {
      setImportData(data?.import);
      if (!!data?.import.filename) {
        setCurrentStep(2);
      }
    }
  }, [data]);

  if (loading && !data) return <Loader />;

  return (
    <Flex width="100%" id="outer-container-trigger">
      <Flex
        flexDirection="column"
        p={4}
        bg="white"
        width="100%"
        id="page-wrap-trigger"
      >
        <Flex justifyContent="space-between">
          <Text variant="h2">Import</Text>
        </Flex>

        {currentStep && (
          <>
            <StepWizard
              onStepChange={setCurrentStep}
              initialStep={currentStep}
              nav={
                <WizardNav
                  stepLabels={[
                    "Upload File",
                    "Field Mapping",
                    "Preview Data",
                    "Complete",
                  ]}
                />
              }
            >
              <UploadFile
                onUploadComplete={() => {
                  refetch();
                  window.scrollTo(0, 0);
                }}
              />
              <MapFields
                importData={importData}
                onUploadComplete={() => {
                  refetch();
                  window.scrollTo(0, 0);
                }}
              />
              <PreviewData
                importData={importData}
                onUploadComplete={() => {
                  props.onComplete && props.onComplete();
                  refetch();
                  window.scrollTo(0, 0);
                }}
              />

              <Flex
                p={4}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <UploadAnimatedIcon />
                <Text>Import is now in progress!</Text>
                <Text variant="muted">
                  You can leave this page and continue your work we'll notify
                  you once it's complete.
                </Text>
              </Flex>
            </StepWizard>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default withRouter(ImportWizard);
