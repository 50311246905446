import React, { useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import { getLifeCycles } from "hooks/getLifeCycles";
import SegmentConfigForm from "Components/Filter/Segment/SegmentConfigForm";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { ErrorParams } from "Components/Common/Notifications/Error";
import LifeCycleStageMutation from "../../../../graphql/mutations/LifeCycleStage.gql";

function LifeCycleForm(props) {
  const { addToast } = useToasts();
  const { data } = getLifeCycles(props.statusId);
  const [triggerSegmentOpen, setTriggerSegmentOpen] = useState(false);
  const [save, {}] = useMutation(LifeCycleStageMutation);

  const saveStages = (values) => {
    save({
      variables: {
        lifeCycleStages: values.stages.map((r) => {
          return {
            id: r.id || null,
            name: r.name,
            config: r.config,
          };
        }),
      },
    }).then(
      (response) => {
        if (response.data?.lifeCycleStages?.errors) {
          addToast(
            <Flex>{response.data?.lifeCycleStages?.errors[0]}</Flex>,
            ErrorParams
          );
        } else {
          props.onComplete && props.onComplete();
          addToast(<Flex>Saved!</Flex>, SuccessParams);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={{
          lifeCycles: data?.lifeCycles || [{ name: "" }],
        }}
        autoComplete={false}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values, actions) => {}}
      >
        {(formProps) => {
          const { values, dirty } = formProps;

          return (
            <>
              <Text variant="h4">Lifecycles</Text>
              <FieldArray
                name="lifeCycles"
                render={(arrayHelpers) => (
                  <Box my={2}>
                    {values.lifeCycles &&
                      values.lifeCycles.length > 0 &&
                      values.lifeCycles.map((lc, idx) => {
                        return (
                          <Flex key={lc.id} flexDirection="column">
                            {lc.stages?.map((stage, index) => (
                              <Flex
                                key={stage.id}
                                p={1}
                                m={1}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Flex alignItems="center">
                                  <Text mx={1} fontWeight="bold">
                                    {stage.name}
                                  </Text>
                                </Flex>
                                {values.lifeCycles &&
                                index <
                                  values.lifeCycles[idx].stages.length - 1 ? (
                                  <Flex alignItems="center">
                                    <Box mx={2} color="gray.500">
                                      <Link
                                        sx={{ cursor: "pointer" }}
                                        mx={2}
                                        onClick={() =>
                                          setTriggerSegmentOpen([idx, index])
                                        }
                                      >
                                        {values.lifeCycles[idx] &&
                                        values.lifeCycles[idx].stages[index] &&
                                        values.lifeCycles[idx].stages[index]
                                          .config?.andFields?.length > 0
                                          ? values.lifeCycles[idx].stages[index]
                                              .config?.andFields?.length
                                          : "add"}{" "}
                                        exit condition(s)
                                      </Link>
                                    </Box>
                                  </Flex>
                                ) : (
                                  <Box mx={2}></Box>
                                )}
                                {triggerSegmentOpen && (
                                  <SegmentConfigForm
                                    open={
                                      idx === triggerSegmentOpen[0] &&
                                      index === triggerSegmentOpen[1]
                                    }
                                    field={
                                      values.lifeCycles[triggerSegmentOpen[0]]
                                        .stages[triggerSegmentOpen[1]].config ||
                                      {}
                                    }
                                    fieldName={`lifeCycles[${triggerSegmentOpen[0]}].stages[${triggerSegmentOpen[1]}].config`}
                                    onClose={() => {
                                      setTriggerSegmentOpen(false);
                                    }}
                                    onSave={(newValues) => {
                                      let newSegment = {
                                        ...values.lifeCycles[
                                          triggerSegmentOpen[0]
                                        ],
                                      };
                                      newSegment.stages[
                                        triggerSegmentOpen[1]
                                      ].config = newValues;
                                      saveStages(newSegment);
                                      setTriggerSegmentOpen(false);
                                    }}
                                    {...formProps}
                                  />
                                )}
                              </Flex>
                            ))}
                          </Flex>
                        );
                      })}
                  </Box>
                )}
              />
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default LifeCycleForm;
