import React, { useState } from "react";
import { Flex } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { Formik } from "formik";
import * as Yup from "yup";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";

function AlertForm(props) {
  const { field, setFieldValue, fieldName, handleBlur } = props;

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        assignToOwner: Yup.boolean()
          .required("Required")
          .oneOf([true], "Required"),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        return (
          <>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <CustomFormGroup
              placeholder="Title"
              label="Title"
              name={`${defaultFieldName}.title`}
              value={field.config?.title}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.title && errors.title}
            />
            <CustomFormGroup
              placeholder="Description"
              label="Description"
              type="textarea"
              name={`${defaultFieldName}.description`}
              value={field.config?.description}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.description && errors.description}
            />
            <Flex alignItems="center" justifyContent="space-between">
              <CustomFormGroup
                placeholder="Assign To Owner"
                label="Assign To Owner"
                name={`${defaultFieldName}.assignToOwner`}
                value={field?.config?.assignToOwner}
                type="switch"
                onChange={setFieldValue}
                onBlur={handleBlur}
                helperText={errors.assignToOwner && errors.assignToOwner}
              />
              <CustomFormGroup
                placeholder="Assign To Team"
                label="Assign To Team"
                name={`${defaultFieldName}.assignToTeam`}
                value={field?.config?.assignToTeam}
                type="switch"
                onChange={setFieldValue}
                onBlur={handleBlur}
                helperText={errors.assignToTeam && errors.assignToTeam}
              />
              <CustomFormGroup
                placeholder="Assign To Team Manager"
                label="Assign To Team Manager"
                name={`${defaultFieldName}.assignToTeamManager`}
                value={field?.config?.assignToTeamManager}
                type="switch"
                onChange={setFieldValue}
                onBlur={handleBlur}
                helperText={
                  errors.assignToTeamManager && errors.assignToTeamManager
                }
              />
            </Flex>
          </>
        );
      }}
    </Formik>
  );
}

export default AlertForm;
