import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Flex, Button, Text, Box, Image, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { FieldArray, Formik } from "formik";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import Dropzone from "react-dropzone";
import { useTheme } from "emotion-theming";
import { getProduct } from "hooks/getProduct";
import { ImageIcon, TrashIcon } from "Components/Common/Icons";
import FileUploadTemplate from "Components/Data/FileUploadTemplate";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import ProductMutation from "../../../../graphql/mutations/Products.gql";
import ColorSelect from "../../../Common/Forms/ColorSelect";
import Label from "../../../Common/Forms/Label";
import PlanTypeForm from "../PlanTypes/PlanTypesForm";

function ProductForm(props) {
  const theme = useTheme();
  const { addToast } = useToasts();
  const [save, {}] = useMutation(ProductMutation);
  const { data, refetch } = getProduct();

  console.log(data);

  const product = props.product || {
    name: "",
    externalId: "",
    defaultEmailDisabled: true,
    userlotDefaultEmail: data?.currentProduct?.userlotDefaultEmail,
  };
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={{
          products: [product],
        }}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          products: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required("Required"),
                externalId: Yup.string().required("Required"),
                defaultEmail: Yup.string()
                  .nullable()
                  .when("defaultEmailDisabled", {
                    is: false,
                    then: Yup.string("Invalid email")
                      .required("Required")
                      .email("Invalid email")
                      .nullable(),
                  }),
              })
            )
            .required("Required"),
        })}
        onSubmit={(values, actions) => {
          save({
            variables: {
              products: values.products.map((r) => {
                return {
                  id: r.id || null,
                  name: r.name,
                  externalId: r.externalId,
                  defaultEmail: r.defaultEmail,
                  fromName: r.fromName,
                  emailFooter: r.emailFooter,
                  emailHeader: r.emailHeader,
                  brandPrimaryColor: r.brandPrimaryColor,
                  brandSecondaryColor: r.brandSecondaryColor,
                  brandBgColor: r.brandBgColor,
                  file: r.file && r.file,
                };
              }),
            },
            context: {
              hasUpload: true,
            },
          }).then(
            (response) => {
              if (response.data?.product?.errors) {
                addToast(
                  <Flex>{response.data?.product?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                props.onComplete && props.onComplete();
                addToast(<Flex>Saved!</Flex>, SuccessParams);
                props.onSave && props.onSave();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const {
            values,
            setFieldValue,
            handleBlur,
            handleSubmit,
            errors,
            dirty,
          } = formProps;
          console.log(errors);
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <FieldArray
                name="products"
                render={(arrayHelpers) => (
                  <Box my={2}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text variant="h2">Product Details</Text>
                      <Flex justifyContent="flex-end">
                        <PrimaryButton onClick={handleSubmit}>
                          Save
                        </PrimaryButton>
                      </Flex>
                    </Flex>
                    {values.products && values.products.length > 0
                      ? values.products.map((field, index) => (
                          <Flex
                            key={index}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            my={2}
                            flex={1}
                          >
                            <Flex
                              alignItems="flex-start"
                              justifyContent="flex-start"
                              flex={1}
                            >
                              <Dropzone
                                onDrop={(acceptedFiles) =>
                                  setFieldValue(
                                    `products[${index}].file`,
                                    acceptedFiles[0]
                                  )
                                }
                                multiple={false}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    flex={1}
                                    sx={{
                                      maxWidth: "200px",
                                      height: "100%",
                                      borderStyle: "dashed",
                                      borderColor: theme.colors.accent,
                                      borderWidth: 1,
                                      borderRadius: "medium",
                                    }}
                                    p={4}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {field && field.file ? (
                                      <>
                                        <Box color="secondaryLight">
                                          <ImageIcon size={40} />
                                          <Text>{field.file?.name}</Text>
                                        </Box>
                                      </>
                                    ) : field && field.logoUrl ? (
                                      <Box variant="avatar">
                                        <Image src={field.logoUrl} />
                                      </Box>
                                    ) : (
                                      <FileUploadTemplate logo />
                                    )}
                                  </Flex>
                                )}
                              </Dropzone>
                              <Flex flex={1} flexDirection="column" mx={2}>
                                <CustomFormGroup
                                  label="Product Name"
                                  placeholder="Product Name"
                                  name={`products[${index}].name`}
                                  value={field?.name}
                                  onChange={(name, value) =>
                                    setFieldValue(name, value)
                                  }
                                  helperText={
                                    errors.products &&
                                    errors.products[index].name &&
                                    errors.products[index].name
                                  }
                                />
                                <CustomFormGroup
                                  label="External Id"
                                  placeholder=" External Id"
                                  name={`products[${index}].externalId`}
                                  value={field?.externalId}
                                  onChange={(name, value) =>
                                    setFieldValue(name, value)
                                  }
                                  readOnly={
                                    values?.products[index]?.id !== undefined
                                  }
                                  helperText={
                                    errors.products &&
                                    errors.products[index].externalId &&
                                    errors.products[index].externalId
                                  }
                                />
                                <Text variant="h3" mb={2} mt={3}>
                                  Branding
                                </Text>
                                <Flex justifyContent={"space-between"} mb={3}>
                                  <ColorSelect
                                    label="Primary"
                                    name={`products[${index}].brandPrimaryColor`}
                                    value={field?.brandPrimaryColor}
                                    onChange={(name, value) =>
                                      setFieldValue(name, value)
                                    }
                                  />
                                  <ColorSelect
                                    label="Secondary"
                                    name={`products[${index}].brandSecondaryColor`}
                                    value={field?.brandSecondaryColor}
                                    onChange={(name, value) =>
                                      setFieldValue(name, value)
                                    }
                                  />
                                  <ColorSelect
                                    label="Background"
                                    name={`products[${index}].brandBgColor`}
                                    value={field?.brandBgColor}
                                    onChange={(name, value) =>
                                      setFieldValue(name, value)
                                    }
                                  />
                                </Flex>
                                <Text variant="h3" mb={2} mt={3}>
                                  Email
                                </Text>
                                <Tabs width="100%" direction="rtl">
                                  <TabList>
                                    <Tab key={"Settings"}>
                                      <Flex
                                        alignItems="center"
                                        justifyContent="flex-end"
                                      >
                                        Settings
                                      </Flex>
                                    </Tab>
                                    <Tab key={"Preview"}>
                                      <Flex
                                        alignItems="center"
                                        justifyContent="flex-end"
                                      >
                                        Preview
                                      </Flex>
                                    </Tab>
                                  </TabList>
                                  <TabPanel
                                    key={"Settings"}
                                    className="health-react-tabs__tab-panel"
                                  >
                                    <Box>
                                      <Flex flex={1} mb={3}>
                                        {field?.defaultEmailDisabled ? (
                                          <Flex
                                            width={"50%"}
                                            flexDirection="column"
                                          >
                                            <CustomFormGroup
                                              label="Default Email"
                                              placeholder="Default Email"
                                              name={`products[${index}].userlotDefaultEmail`}
                                              value={field?.userlotDefaultEmail}
                                              onChange={(name, value) =>
                                                setFieldValue(name, value)
                                              }
                                              readOnly
                                            />
                                            <Text variant="muted">
                                              Note: Set up
                                              <Link
                                                mx={1}
                                                href="/settings/account/domains"
                                              >
                                                email domains
                                              </Link>
                                              to send from a custom email
                                            </Text>
                                          </Flex>
                                        ) : (
                                          <CustomFormGroup
                                            label="Default Email"
                                            placeholder="Default Email"
                                            name={`products[${index}].defaultEmail`}
                                            value={field?.defaultEmail}
                                            onChange={(name, value) =>
                                              setFieldValue(name, value)
                                            }
                                            helperText={
                                              errors.products &&
                                              errors.products[index]
                                                .defaultEmail &&
                                              errors.products[index]
                                                .defaultEmail
                                            }
                                          />
                                        )}
                                        <Box mx={2}></Box>
                                        <Flex
                                          width={"50%"}
                                          flexDirection="column"
                                        >
                                          <CustomFormGroup
                                            label="From Name"
                                            placeholder="From Name"
                                            name={`products[${index}].fromName`}
                                            value={field?.fromName}
                                            onChange={(name, value) =>
                                              setFieldValue(name, value)
                                            }
                                            helperText={
                                              errors.products &&
                                              errors.products[index].fromName &&
                                              errors.products[index].fromName
                                            }
                                          />
                                        </Flex>
                                      </Flex>

                                      <CustomFormGroup
                                        type="richtext"
                                        label="Email Header"
                                        placeholder="Email Header"
                                        name={`products[${index}].emailHeader`}
                                        value={field?.emailHeader}
                                        onChange={(name, value) =>
                                          setFieldValue(name, value)
                                        }
                                        helperText={
                                          errors.products &&
                                          errors.products[index].emailHeader &&
                                          errors.products[index].emailHeader
                                        }
                                      />
                                      <CustomFormGroup
                                        type="richtext"
                                        label="Email Footer"
                                        placeholder="Email Footer"
                                        name={`products[${index}].emailFooter`}
                                        value={field?.emailFooter}
                                        onChange={(name, value) =>
                                          setFieldValue(name, value)
                                        }
                                        helperText={
                                          errors.products &&
                                          errors.products[index].emailFooter &&
                                          errors.products[index].emailFooter
                                        }
                                      />
                                    </Box>
                                  </TabPanel>

                                  <TabPanel
                                    key={"Settings"}
                                    className="health-react-tabs__tab-panel"
                                  >
                                    <Box>
                                      <EmailPreview
                                        values={values.products[index]}
                                      />
                                    </Box>
                                  </TabPanel>
                                </Tabs>
                              </Flex>
                            </Flex>
                            <Box variant="divider" />
                          </Flex>
                        ))
                      : undefined}
                  </Box>
                )}
              />
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default ProductForm;

const EmailPreview = ({ values }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
      bg={values.brandBgColor || "#F7FAFC"}
      p={4}
    >
      <Flex flexDirection="column" sx={{ width: 800 }}>
        <Flex width="100%">
          <Flex
            width="100%"
            dangerouslySetInnerHTML={{ __html: values.emailHeader }}
          ></Flex>
        </Flex>
        <Flex flexDirection="column" bg="white" px={3}>
          <Text my={4} variant="muted">
            Your email template content
          </Text>
        </Flex>
        <Flex width="100%">
          <Flex
            width="100%"
            dangerouslySetInnerHTML={{ __html: values.emailFooter }}
          ></Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
EmailPreview.propTypes = {
  values: PropTypes.object,
};
