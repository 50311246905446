import React from "react";
import { HomeIcon, ReportIcon } from "Components/Common/Icons";
import Topbar from "Components/Common/Topbar/Topbar";

function ProductTopbar(props) {
  return (
    <Topbar
      title="Adoption"
      menuItems={[
        { name: "Home", icon: HomeIcon, link: "/adoption" },
        {
          name: "Reports",
          icon: ReportIcon,
          link: "/adoption/report",
        },
      ]}
      crumbs={props.crumbs}
    />
  );
}

export default ProductTopbar;
