import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Flex, Text, Box, Button } from "rebass";
import "react-tabs/style/react-tabs.css";
import Settings from ".";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { Formik, FieldArray } from "formik";
import UserQuery from "../../../graphql/queries/User.gql";
import UsersQuery from "../../../graphql/queries/Users.gql";
import UserMutation from "../../../graphql/mutations/User.gql";
import UserTeamMutation from "../../../graphql/mutations/UserTeam.gql";
import UserDeleteMutation from "../../../graphql/mutations/UserDelete.gql";
import ResetPasswordMutation from "../../../graphql/mutations/UserRequestResetPassword.gql";
import TeamsQuery from "../../../graphql/queries/Teams.gql";
import RolesQuery from "../../../graphql/queries/Roles.gql";
import Select from "Components/Common/Forms/Select";
import { useToasts } from "react-toast-notifications";
import ShowError, { ErrorParams } from "Components/Common/Notifications/Error";
import ShowSuccess, {
  SuccessParams,
} from "Components/Common/Notifications/Success";
import DeleteButton from "./Common/DeleteButton";
import { PlusIcon, SaveIcon, TrashIcon } from "Components/Common/Icons";
import Modal from "Components/Common/Modal";
import PromptButton from "./Common/PromptButton";
import moment from "moment";
import Label from "Components/Common/Forms/Label";
import UserStatusBadge from "./UserStatusBadge";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import Avatar from "react-avatar";
import LocalisationForm from "./Localisation";
import { getCurrentUser } from "../../../hooks/getCurrentUser";
const _ = require("lodash");

function User(props) {
  const history = useHistory();
  const { currentUser } = getCurrentUser();
  const { addToast } = useToasts();
  const { id } = useParams();
  const isNew = !id || id === "new";
  const [openTeam, setOpenTeam] = useState(false);
  const [saveUser, {}] = useMutation(UserMutation);
  const [saveUserTeams, {}] = useMutation(UserTeamMutation, {
    refetchQueries: [
      { query: UserQuery, variables: { id: id } },
      { query: TeamsQuery },
    ],
  });
  const [destroy, {}] = useMutation(UserDeleteMutation, {
    refetchQueries: [{ query: UsersQuery }, { query: TeamsQuery }],
  });
  const [requestResetPassword, {}] = useMutation(ResetPasswordMutation);

  const teamsQuery = useQuery(TeamsQuery, {});
  const rolesQuery = useQuery(RolesQuery, {});
  const { loading, data, refetch } = useQuery(UserQuery, {
    variables: { id: id },
    skip: isNew,
  });
  const teams = teamsQuery.data?.teams;
  const roles = rolesQuery.data?.roles;
  let defaultTeam = _.first(teams);
  const user =
    id == "new"
      ? {
          name: "",
          memberships: [
            {
              id: defaultTeam?.id,
              name: defaultTeam?.name,
              teamId: defaultTeam?.id,
              role: roles?.find((r) => r.name === "User"),
            },
          ],
        }
      : data && data.user;

  const postDelete = () => {
    refetch();
    history.push(`/settings/account/users`);
  };
  console.log(currentUser);
  return (
    <Settings>
      <Formik
        enableReinitialize={true}
        initialValues={user || {}}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().nullable().required("Required"),
          email: Yup.string().nullable().email().required("Required"),
          memberships: Yup.array()
            .of(
              Yup.object().shape({
                teamId: Yup.string().required("Required"),
                role: Yup.object().shape({
                  name: Yup.string().nullable().required("Required"),
                }),
              })
            )
            .required("At least one team needs to be added"),
        })}
        onSubmit={(values, actions) => {
          saveUser({
            variables: {
              id: values.id ? values.id : null,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              timezone: values.timezone,
              locale: values.locale,
            },
          }).then(
            (response) => {
              let result = response.data?.userMutation;
              if (result?.errors) {
                actions.setErrors(result?.errors);
                ShowError(result?.errors && result?.errors[0]);
              } else {
                let userId = result?.user?.id;

                if (values.memberships && values.memberships.length > 0) {
                  saveUserTeams({
                    variables: {
                      id: userId,
                      memberships: values.memberships.map((team) => {
                        return {
                          teamId: team.teamId,
                          roleName: team.role?.name,
                          deleted: team.deleted || false,
                        };
                      }),
                    },
                  }).then(
                    (response) => {
                      teamsQuery.refetch();
                      refetch();
                      let result = response.data?.userTeamMutation;
                      if (result?.errors) {
                        actions.setErrors(result?.errors);
                        addToast(
                          <ShowError message={result?.errors[0]} />,
                          ErrorParams
                        );
                      } else {
                        addToast(
                          <ShowSuccess message={"Saved!!"} />,
                          SuccessParams
                        );
                      }

                      history.push(`/settings/account/users`);
                    },
                    (error) => {
                      addToast(
                        <ShowError message={"Save error!"} />,
                        ErrorParams
                      );
                    }
                  );
                } else {
                  addToast(<ShowSuccess message={"Saved!!"} />, SuccessParams);
                }
              }
            },
            (error) => {
              addToast(<ShowError message={"Save error!"} />, ErrorParams);
            }
          );
        }}
      >
        {(formProps) => {
          const {
            values,
            errors,
            setFieldValue,
            handleBlur,
            handleSubmit,
            resetForm,
          } = formProps;

          return (
            <Flex flex={1} m={4} flexDirection="column">
              <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                  <Avatar
                    size={60}
                    round
                    email={values?.email}
                    name={values?.name}
                    email={values?.email}
                    src={values?.avatarUrl}
                  />
                  <Text mx={3} variant="h2">{`${
                    values?.firstName || "New User"
                  } ${values?.lastName || ""}`}</Text>
                  <UserStatusBadge user={values} />
                </Flex>
                <Flex>
                  {currentUser?.id !== id && (
                    <DeleteButton
                      callback={destroy}
                      onCancel={() => history.goBack()}
                      id={id}
                      refetch={postDelete}
                      name="user"
                    />
                  )}

                  {values.unconfirmedEmail || !!values.invitedAt ? (
                    <PromptButton
                      message={`Do you want to resend ${values.firstName} their invite`}
                      buttonLabel="Resend Invite"
                      onConfirm={() => {
                        requestResetPassword({
                          variables: {
                            email: values.email,
                          },
                        }).then(
                          (response) => {
                            addToast(
                              <ShowSuccess message={"Resent invite!"} />,
                              SuccessParams
                            );
                          },
                          (error) => {
                            addToast(
                              <ShowError message={"Save error!"} />,
                              ErrorParams
                            );
                          }
                        );
                      }}
                    />
                  ) : (
                    <PromptButton
                      message={`Do you want to reset ${values.firstName}'s password`}
                      buttonLabel="Reset Password"
                      onConfirm={() => {
                        requestResetPassword({
                          variables: {
                            email: values.email,
                          },
                        }).then(
                          (response) => {
                            addToast(
                              <ShowSuccess
                                message={"Sent password reset instructions!"}
                              />,
                              SuccessParams
                            );
                          },
                          (error) => {
                            addToast(
                              <ShowError message={"Save error!"} />,
                              ErrorParams
                            );
                          }
                        );
                      }}
                    />
                  )}
                  <Box mx={2} />
                  <PrimaryButton leftIcon={<SaveIcon />} onClick={handleSubmit}>
                    Save
                  </PrimaryButton>
                </Flex>
              </Flex>

              <Flex flex={1} mt={4}>
                <Flex
                  width={1 / 2}
                  flexDirection="column"
                  pr={3}
                  sx={{
                    borderRightColor: "accent",
                    borderRightWidth: 1,
                    borderRightStyle: "solid",
                  }}
                >
                  <Text variant="h2" mb={2}>
                    Profile
                  </Text>

                  <CustomFormGroup
                    placeholder="Email"
                    label={<Flex>Email</Flex>}
                    name="email"
                    value={values.email}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.email && errors.email}
                  />
                  {values.unconfirmedEmail && (
                    <Flex alignItems="center" mb={2}>
                      <Text variant="muted" color="red">
                        Awaiting confirmation of
                      </Text>
                      <Text variant="muted" mx={1} color="red">
                        {values.unconfirmedEmail}
                      </Text>
                    </Flex>
                  )}
                  <CustomFormGroup
                    placeholder="First Name"
                    label="First Name"
                    name="firstName"
                    value={values.firstName}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.firstName && errors.firstName}
                  />
                  <CustomFormGroup
                    placeholder="Last Name"
                    label="Last Name"
                    name="lastName"
                    value={values.lastName}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.lastName && errors.lastName}
                  />
                  <LocalisationForm {...formProps} />
                  <Flex mt={3} justifyContent="space-between">
                    <Box>
                      <Label name="created">Created</Label>
                      <Text variant="label">
                        {moment(values.createdAt).format("DD MMM YYYY")}
                      </Text>
                    </Box>
                    <Box>
                      <Label name="created">Last Seen</Label>
                      <Text variant="label">
                        {moment(values.createdAt).format("DD MMM YYYY")}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
                <Flex width={1 / 2} flexDirection="column" pl={3}>
                  <Text variant="h3">Teams</Text>
                  <Text variant="label" color="error">
                    {errors.memberships &&
                      typeof errors.memberships == "string" &&
                      errors.memberships}
                  </Text>
                  <FieldArray
                    name="memberships"
                    render={(arrayHelpers) => (
                      <Flex flexDirection="column" width={"100%"}>
                        <Flex my={1}>
                          <Flex width={"50px"}>
                            <Text variant="label"></Text>
                          </Flex>
                          <Flex width={2 / 4}>
                            <Text variant="label">Name</Text>
                          </Flex>
                          <Flex width={1 / 4}>
                            <Text variant="label">Role</Text>
                          </Flex>
                          <Flex width={"50px"}>
                            <Text variant="label"></Text>
                          </Flex>
                        </Flex>
                        {values.memberships &&
                          values.memberships
                            .filter((m) => !m.deleted)
                            .map((child, index) => {
                              return (
                                <Flex
                                  key={index}
                                  my={1}
                                  alignItems="center"
                                  width="100%"
                                >
                                  <Flex width={"50px"} alignItems="center">
                                    <Avatar
                                      src={child.team?.product?.logoUrl}
                                      size="30"
                                      name={`${child.team?.product?.name}`}
                                      round
                                      textSizeRatio={2}
                                    />
                                  </Flex>
                                  <Flex width={2 / 4} alignItems="center">
                                    <Text>{child.name}</Text>
                                  </Flex>
                                  <Flex width={1 / 4}>{child.role?.name}</Flex>
                                  <Flex width={"50px"}>
                                    <Button
                                      variant="buttons.ghost"
                                      m={1}
                                      p={2}
                                      onClick={() => {
                                        //arrayHelpers.remove(index);
                                        setFieldValue(
                                          `memberships[${index}].deleted`,
                                          true
                                        );
                                      }}
                                    >
                                      <TrashIcon />
                                    </Button>
                                  </Flex>
                                </Flex>
                              );
                            })}
                        <Box my={3} />
                        <SecondaryButton onClick={() => setOpenTeam(true)}>
                          Add Another Team
                        </SecondaryButton>
                        <AddTeam
                          open={openTeam}
                          onDismiss={() => setOpenTeam(false)}
                          onSelected={(item) => {
                            let idx =
                              values.memberships &&
                              values.memberships.indexOf(
                                values.memberships.find(
                                  (a) => a.id === item.id && !a.deleted
                                )
                              );

                            if (!idx || idx < 0) {
                              arrayHelpers.push({
                                id: item.id,
                                teamId: item.id,
                                deleted: false,
                                role: {
                                  id: item.role?.value,
                                  name: item.role?.label,
                                },
                                name: item.name,
                              });
                            }
                          }}
                        />
                      </Flex>
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </Settings>
  );
}

export default withRouter(User);

const AddTeam = (props) => {
  const teamsQuery = useQuery(TeamsQuery, {});
  const rolesQuery = useQuery(RolesQuery, {});
  const [selectedTeam, setSelectedTeam] = useState();

  const teams = teamsQuery.data?.teams;
  const teamOptions = teams?.map((r) => {
    return { value: r.id, label: r.name, data: r };
  });
  const roles = rolesQuery.data?.roles;
  const roleOptions = roles?.map((r) => {
    return { value: r.id, label: r.name };
  });
  return (
    <Modal
      title="Add Team"
      open={props.open}
      onDismiss={props.onDismiss}
      width={"700px"}
    >
      <Flex justifyContent={"center"}>
        <Flex mm={3} p={3} flexDirection={"column"} sx={{ maxWidth: 500 }}>
          <Select
            placeholder="Select a team"
            label="Team"
            width={350}
            name="memberships"
            onChange={(name, value) => {
              let team = {
                ...selectedTeam,
                ...value?.data,
              };
              setSelectedTeam(team);
            }}
            mb={0}
            options={teamOptions}
          />
          <Box my={2} />
          <Select
            placeholder="Roles"
            label="Role"
            width={350}
            name={`role`}
            onChange={(name, value) => {
              setSelectedTeam({
                ...selectedTeam,
                role: value,
              });
            }}
            options={roleOptions}
          />
          <Flex mt={3} justifyContent="flex-end">
            <PrimaryButton
              onClick={() => {
                props.onSelected(selectedTeam);
                props.onDismiss();
              }}
              leftIcon={<PlusIcon />}
            >
              Add
            </PrimaryButton>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
AddTeam.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSelected: PropTypes.func,
};
