import React, { useEffect, useState } from "react";
import { Button, Flex, Text, Box, Image, Link } from "rebass";
import CustomFormGroup from "../../../Common/Forms/CustomFormGroup";
import {
  SaveIcon,
  CloseIcon,
  TrashIcon,
  PlusIcon,
} from "../../../Common/Icons";
import PropTypes from "prop-types";
import SurveyButtons, { SurveyButton } from "./SurveyButtons";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import GhostButton from "../../../Common/Buttons/GhostButton";

export const SurveyWidget = ({
  data,
  showUnsubscribe,
  product,
  onSubmit,
  preview,
}) => {
  const [question, setQuestion] = useState();
  const [followupQuestion, setFollowupQuestion] = useState();
  const [submitted, setSubmitted] = useState();
  const brandColor =
    data.config?.primaryColor || product?.brandPrimaryColor || "brandLight";

  const askQuestion =
    question?.question || "Enter a question to ask your customers";

  const askFollowUpQuestion = followupQuestion?.question;

  useEffect(() => {
    setQuestion(
      data?.surveyQuestions.length > 0
        ? data?.surveyQuestions.find(
            (q) => (q.primary || q.orderSeq == 0) && !q.isArchived
          )
        : null
    );
  }, [data]);

  useEffect(() => {
    if (question?.score >= 0) {
      setFollowupQuestion(
        data?.surveyQuestions
          ?.filter((q) => !q.primary && q.orderSeq > 0 && !q.isArchived)
          .find(
            (q) =>
              q.id != question.id &&
              (!data?.config?.followUpConditional ||
                (question?.score >= q.minScore &&
                  question?.score <= q.maxScore))
          )
      );
    }
  }, [question]);

  const thankyouMessage = !!submitted
    ? data?.config?.messageEnabled
      ? data?.config.message
      : "Your time is appriciated"
    : null;

  return (
    <Flex
      sx={{
        borderTopStyle: "solid",
        borderTopColor: brandColor,
        borderTopWidth: 4,
        minWidth: "600px",
      }}
      bg={"gray.100"}
      flexDirection="column"
      flex={1}
      px={3}
    >
      {preview && (
        <Flex justifyContent={"flex-end"}>
          <GhostButton
            onClick={() => {
              question.score = undefined;
              setQuestion(question);
              setFollowupQuestion(undefined);
              setSubmitted(undefined);
            }}
          >
            <CloseIcon />
          </GhostButton>
        </Flex>
      )}
      <Flex flexGrow={1} alignItems="center" justifyContent="center">
        <Flex sx={{ maxWidth: 600 }} flexDirection="column">
          {!thankyouMessage && (
            <>
              <Text p={2}>{askQuestion}</Text>
              <SurveyButtons
                surveyType={data.surveyType}
                brandColor={brandColor}
                value={question?.score}
                onClick={(value) => setQuestion({ ...question, score: value })}
              />

              {askFollowUpQuestion && (
                <Box m={2}>
                  <Text color={"gray.700"}>{askFollowUpQuestion}</Text>
                  <CustomFormGroup
                    name={`followupResponse`}
                    value={followupQuestion?.responseText}
                    onChange={(name, value) =>
                      setFollowupQuestion({
                        ...followupQuestion,
                        responseText: value,
                      })
                    }
                    type="textarea"
                  />
                </Box>
              )}
              <Flex justifyContent="center" my={2}>
                <SecondaryButton
                  onClick={() => {
                    setSubmitted(true);
                    onSubmit && onSubmit([question, followupQuestion]);
                  }}
                >
                  <Text color={brandColor}>Send Feedback</Text>
                </SecondaryButton>
              </Flex>
            </>
          )}
          {thankyouMessage && (
            <Box mt={3}>
              <Text as="h4">Thank you for your feedback</Text>
              <Text
                mt={2}
                dangerouslySetInnerHTML={{
                  __html: thankyouMessage,
                }}
              ></Text>
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex p={1} width="100%">
        <Flex
          flex={1}
          alignItems="center"
          justifyContent={showUnsubscribe ? "space-between" : "center"}
        >
          {showUnsubscribe && (
            <Flex alignItems="center" flex={1}>
              <Text variant="muted">Unsubscribe</Text>
            </Flex>
          )}
          <Flex alignItems="center" flex={1} justifyContent="flex-end">
            <Text p={2} variant="muted">
              Powered by Userlot
            </Text>
            <Image
              src={require("assets/images/logo.png")}
              sx={{ height: 30 }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
SurveyWidget.propTypes = {
  data: PropTypes.object,
  product: PropTypes.object,
  showUnsubscribe: PropTypes.bool,
  preview: PropTypes.bool,
};
