import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { Text, Flex, Image, Box, Link, Button } from "rebass";
import { useTheme } from "emotion-theming";
import Loader from "../Loader";
import "./table_override.css";
import { DownloadIcon, SettingIcon } from "../Icons";
import SecondaryButton from "../Buttons/SecondaryButton";
import ExportButton from "./ExportButton";

const _ = require("lodash");

const Export = ({ onExport }) => (
  <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
);
Export.propTypes = {
  onExport: PropTypes.func,
};
function Table(props) {
  const [selectableRowsVisibleOnly, setSelectableRowsVisibleOnly] =
    useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const theme = useTheme();

  const handleChange = (state) => {
    if (props.onSelectedRowsChange) {
      props.onSelectedRowsChange(state.selectedRows);
    }
    setSelectedRows(state);
  };

  useEffect(() => {
    if (props.selectedRows?.length === 0) {
      setClearRows(!clearRows);
      setSelectedRows(undefined);
    }
  }, [props.selectedRows]);

  const customStyles = {
    table: {
      style: {
        color: theme.text.primary,
        borderColor: "transparent",
      },
    },
    tableWrapper: {
      style: {
        minHeight: 300,
        paddingBottom: 0,
        marginBottom: 0,
      },
    },
    rows: {
      style: {
        backgroundColor: "transparent",
        position: "relative",
        borderRadius: 99,
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      selectedHighlighStyle: {
        backgroundColor: theme.colors.brandHighlight,
      },
      highlightOnHoverStyle: {
        backgroundColor: theme.colors.brandHighlight,
      },
    },
    headRow: {
      style: {
        whiteSpace: "break-spaces",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
    },
    headCells: {
      style: {
        height: 40,
        whiteSpace: "break-spaces",
        paddingLeft: "0px", // override the cell padding for head cells
        paddingRight: "0px",
        color: theme.colors.text,
        fontSize: theme.fontSizes.xs,
        fontFamily: theme.fonts.body,
        fontWeight: "bold",
        borderColor: "transparent",
        backgroundColor: "white",
      },
      draggingStyle: {
        cursor: "move",
      },
      sortStyle: {
        width: "100%",
        paddingLeft: "0px", // override the cell padding for head cells
        paddingRight: "0px",
        cursor: "pointer",
        "&:focus": {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          outline: "none",
          backgroundColor: theme.colors.gray[200],
        },
        "&:hover": {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: theme.colors.gray[200],
        },
        "&:active": {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: theme.colors.gray[200],
        },
      },
    },
    cells: {
      style: {
        fontSize: theme.fontSizes.xs,
        fontFamily: theme.fonts.label,
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "0px",
        borderBottomColor: theme.colors.gray[200],
        borderBottomWidth: "thin",
        borderBottomStyle: "solid",
        "&:last-of-type": {
          paddingRight: "20px",
        },
        "&:first-of-type": {
          paddingLeft: "20px",
        },
      },
    },
    noData: {
      style: {
        backgroundColor: "transparent",
      },
    },
  };

  return (
    <Flex flex={1} flexDirection="column" bg="white" pb={3}>
      {props.title && <Text>{props.title}</Text>}
      <Flex alignItems="center" justifyContent="space-between" my={2}>
        <Flex flex={1}>
          {selectedRows && selectedRows?.selectedCount > 0 && (
            <Flex p={3}>
              <Text variant="muted" color="brandLight">
                {selectedRows?.selectedCount} Rows Selected
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex>
          {props.search}
          {props.exportable && (
            <ExportButton columns={props.columns} data={props.data} />
          )}
          <Box mx={1} />
          {props.actions}
        </Flex>
      </Flex>

      <DataTable
        defaultSortField={props.defaultSortField}
        defaultSortAsc={
          props.defaultSortAsc === undefined ? true : props.defaultSortAsc
        }
        responsive={true}
        columns={props.columns}
        data={props.data}
        noHeader
        fixedHeader
        selectableRows={!!props.selectableRows} // add for checkbox selection
        selectableRowsHighlight
        onSelectedRowsChange={handleChange}
        clearSelectedRows={clearRows}
        selectableRowsVisibleOnly={selectableRowsVisibleOnly}
        selectableRowDisabled={props.selectableRowDisabled}
        customStyles={customStyles}
        overflowY
        highlightOnHover
        pointerOnHover
        onRowClicked={props.onRowClicked}
        contextActions={props.contextActions}
        progressPending={props.loading}
        progressComponent={<TableLoader />}
        sortServer={!!props.sortServer}
        onSort={props.onSort}
        pagination={!!props.paginationTotalRows || props.pagination}
        paginationServer={!!props.paginationTotalRows}
        onChangePage={props.onChangePage}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        paginationTotalRows={props.paginationTotalRows}
        paginationPerPage={props.pageSize || 50}
        paginationRowsPerPageOptions={[25, 50, 100, 150]}
        paginationComponentOptions={{
          rowsPerPageText: "Rows",
          rangeSeparatorText: "of",
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "All",
        }}
        expandableRowDisabled={props.expandableRowDisabled}
        expandableRows={props.expandableRowsComponent}
        expandableRowsComponent={props.expandableRowsComponent}
        noDataComponent={
          props.noDataComponent ? (
            props.noDataComponent
          ) : (
            <Flex
              my={4}
              flexDirection="column"
              flexGrow={1}
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src={require("assets/images/Tables.png")}
                sx={{ height: "80px" }}
              />
              {props.noDataText}
            </Flex>
          )
        }
      />
    </Flex>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  clearSelectedRows: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  selectedRows: PropTypes.array,
  onSelectedRowsChange: PropTypes.func,
  onRowClicked: PropTypes.func,
  contextActions: PropTypes.object,
  actions: PropTypes.node,
  expandableRowsComponent: PropTypes.any,
  noDataComponent: PropTypes.any,
  noDataText: PropTypes.any,
  pageSize: PropTypes.number,
  onChangePage: PropTypes.func,
  paginationTotalRows: PropTypes.number,
  filter: PropTypes.bool,
  selectableRows: PropTypes.bool,
  sortServer: PropTypes.bool,
  exportable: PropTypes.bool,
  configurable: PropTypes.bool,
  onSort: PropTypes.func,
  search: PropTypes.bool,
  defaultSortField: PropTypes.string,
  defaultSortAsc: PropTypes.bool,
};
export default Table;

const TableLoader = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      sx={{ height: 100 }}
      flexDirection="column"
    >
      <Loader large />
      <Text variant="muted">Loading data</Text>
    </Flex>
  );
};
