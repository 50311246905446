import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Flex } from "rebass";
import { useTheme } from "emotion-theming";
import { MoveIcon } from "Components/Common/Icons";

function DraggableItem(props) {
  const theme = useTheme();
  const { item, index } = props;
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    boxShadow: isDragging ? theme.shadows.large : "",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        if (snapshot.isDragging) {
          const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

          const offset = { x: 0, y: -50 }; // your fixed container left/top position
          const x = provided.draggableProps.style.left - offset.x;
          const y = provided.draggableProps.style.top + winScroll;
          provided.draggableProps.style.left = x;
          provided.draggableProps.style.top = y;
        }
        return (
          <Flex
            variant="box"
            width="100%"
            bg="white"
            my={2}
            sx={{
              position: "relative",
              cursor: "pointer",
              ":hover": {
                backgroundColor: "gray.100",
                borderColor: "brandHighlight",
                boxShadow: theme.shadows.large,
              },
            }}
            justifyContent="space-between"
            alignItems="center"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {props.children}
            <Flex
              alignItems="center"
              color={theme.colors.primary}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
              pl={3}
              pb={3}
              pt={2}
              pr={2}
            >
              <MoveIcon />
            </Flex>
          </Flex>
        );
      }}
    </Draggable>
  );
}

export default DraggableItem;
