import React, { useState } from "react";
import { Flex, Box, Link, Text, Button } from "rebass";
import { FieldArray, Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import Modal from "Components/Common/Modal";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useDynamicSchemas } from "hooks/DynamicSchema";
import { getSegment } from "hooks/getSegment";
import { getUsers } from "hooks/getUsers";
import { getCompanyStatus } from "hooks/getCompanyStatuses";
import { getEventGroups } from "hooks/getEventGroups";
import { MODEL_TYPES } from "consts";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { ErrorParams } from "Components/Common/Notifications/Error";
import DeleteButton from "../../MyAccount/Settings/Common/DeleteButton";
import SegmentMutation from "../../../graphql/mutations/Segment.gql";
import SegmentDeleteMutation from "../../../graphql/mutations/SegmentDelete.gql";
import SegmentSubForm from "./SegmentSubForm";
import { PrivateIcon, SharedIcon } from "../../Common/Icons";
import VisibilityFilter from "./VisibilityFilter";
import SegmentsQuery from "../../../graphql/queries/Segments.gql";
import { SuccessParams } from "Components/Common/Notifications/Success";

const _ = require("lodash");

function SegmentForm(props) {
  const segmentableType = props.typeName;
  const [save, { client }] = useMutation(SegmentMutation, {
    refetchQueries: [{ query: SegmentsQuery, variables: { segmentableType } }],
  });
  const [destroy] = useMutation(SegmentDeleteMutation, {
    refetchQueries: [{ query: SegmentsQuery, variables: { segmentableType } }],
  });
  const { addToast, removeAllToasts } = useToasts();
  const segmentQuery = getSegment(props.segmentId);

  let segment = segmentQuery.data?.segment;
  segment = { name: "", private: false, ...segment, ...segment?.config };

  const saveMutation = (values) => {
    save({
      variables: {
        ...values,
        segmentableType: props.typeName,
      },
    }).then(
      (response) => {
        let segment = response.data?.segment?.segment;
        if (response.data?.segment?.errors) {
          addToast(
            <Flex>{response.data?.segment?.errors[0]}</Flex>,
            ErrorParams
          );
        } else {
          addToast(<Flex>Saved!</Flex>, SuccessParams);
          //client.stop();
          //client.cache.reset();
          props.onClose(segment);
          window.history.replaceState(
            {},
            document.title,
            `${window.location.pathname}?segment=${segment?.id}`
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getValues = (values) => {
    return {
      id: values.id,
      name: values.name,
      private: values.private,
      config: {
        andFields: values.config.andFields?.map((f) => {
          return {
            entity: f.entity,
            field: f.field,
            operator: f.operator || "EQUALS",
            value: f.value,
            valueEnd: f.valueEnd,
            dateOffset: f.dateOffset,
          };
        }),
        orFields: values.config.orFields?.map((f) => {
          return {
            entity: f.entity,
            field: f.field,
            operator: f.operator || "EQUALS",
            value: f.value,
            valueEnd: f.valueEnd,
            dateOffset: f.dateOffset,
          };
        }),
      },
    };
  };
  return (
    <Modal
      key={segment?.id}
      title={!segment?.id ? "Create New Segment" : "Edit Segment"}
      open={props.open}
      onDismiss={props.onClose}
      width="950px"
    >
      <Formik
        initialValues={segment || { name: "", private: false }}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        enableReinitialize
        onSubmit={(values, actions) => {
          saveMutation(getValues(values));
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, errors, handleSubmit } = formProps;

          return (
            <Flex px={3} pb={3} alignItems="flex-start" flexDirection="column">
              <Flex width="100%" justifyContent="space-between">
                <Box width={3 / 4}>
                  <CustomFormGroup
                    label="Segment Name"
                    name="name"
                    value={values.name}
                    onChange={(name, value) => setFieldValue(name, value)}
                    helperText={errors?.name && errors.name}
                  />
                </Box>
                <Box width={1 / 4} pl={3}>
                  <VisibilityFilter
                    label="Visibility"
                    name="private"
                    value={values.private}
                    options={[
                      {
                        label: (
                          <Flex>
                            <PrivateIcon /> <Text mx={2}>Private</Text>
                          </Flex>
                        ),
                        description: (
                          <Flex>
                            <PrivateIcon />{" "}
                            <Box mx={2}>
                              <Text>Private</Text>

                              <Text variant="muted">
                                Only the creator can see this segment.
                              </Text>
                            </Box>
                          </Flex>
                        ),
                        value: true,
                      },
                      {
                        label: (
                          <Flex>
                            <SharedIcon /> <Text mx={2}>Shared</Text>
                          </Flex>
                        ),
                        description: (
                          <Flex>
                            <SharedIcon />{" "}
                            <Box mx={2}>
                              <Text>Shared</Text>
                              <Text variant="muted">
                                Only the creator can see this segment.
                              </Text>
                            </Box>
                          </Flex>
                        ),
                        value: false,
                      },
                    ]}
                    onChange={(name, value) =>
                      setFieldValue(name, value?.value)
                    }
                  />
                </Box>
              </Flex>
              <SegmentSubForm
                field={values?.config}
                fieldName="config"
                {...formProps}
              />
              <Flex width="100%" justifyContent="space-between" mt={3}>
                <DeleteButton
                  callback={destroy}
                  onCancel={() => props.onClose()}
                  id={segment?.id}
                  refetch={() => props.onClose()}
                  name="segment"
                />
                <Flex>
                  <SecondaryButton
                    onClick={() => {
                      saveMutation({ ...getValues(values), id: undefined });
                      props.onClose();
                    }}
                  >
                    Save As New
                  </SecondaryButton>

                  <Box px={2} />
                  <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default SegmentForm;
