import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Flex, Link, Box, Text, Heading, Button } from "rebass";
import { Input } from "@rebass/forms";
import { useQuery } from "@apollo/react-hooks";
import { useDebounce } from "react-use";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useTheme } from "emotion-theming";
import Page from "../Page";
import GlobalSearchQuery from "../../graphql/queries/GlobalSearch.gql";
import Loader from "Components/Common/Loader";
import { useDynamicSchemas } from "hooks/DynamicSchema";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ModelTypeIcon, MODEL_TYPES, SEARCH_MODEL_TYPES } from "consts";
const _ = require("lodash");
function GlobalSearch(props) {
  const theme = useTheme();
  let dataModels = useDynamicSchemas();

  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  useEffect(() => {
    setSearchText(props.match.params.searchText);
  }, [props.match.params.searchText]);

  const [,] = useDebounce(
    () => {
      setDebouncedSearchText(searchText);
    },
    200,
    [searchText]
  );

  const { loading, data, error, refetch } = useQuery(GlobalSearchQuery, {
    variables: { limit: 100, skip: 0, search: debouncedSearchText },
    fetchPolicy: "network-only",
    skip: !debouncedSearchText || debouncedSearchText === "",
  });

  const getLink = (result) => {
    switch (result.type) {
      case "tickets":
        return "/support";
      case "tasks":
        return `/tasks/${result.id}`;
      default:
        return `/data${SEARCH_MODEL_TYPES[result.type]?.route}/${result.id}`;
    }
  };
  const getResults = (type) => {
    results = data.globalSearch.results
      .filter((r) => r.type == type)
      .map((result) => {
        const model = SEARCH_MODEL_TYPES[result.type];
        let icon = model ? ModelTypeIcon(model.name) : null;

        return (
          <Flex
            variant="box"
            flexDirection="column"
            p={2}
            my={2}
            key={`${result.type}/${result.id}`}
          >
            <Box>
              <RouterLink to={getLink(result)} component={Link}>
                <Flex alignItems="center">
                  {icon} <Text mx={2}>{result.title}</Text>
                </Flex>
              </RouterLink>
            </Box>
          </Flex>
        );
      });
    return results;
  };

  let results = data?.globalSearch?.results;
  let resultCount = data?.globalSearch?.total || 0;

  const types = _.uniq(data?.globalSearch?.results?.map((t) => t.type)).map(
    (type) => {
      const model = SEARCH_MODEL_TYPES[type];
      let icon = model ? ModelTypeIcon(model.name) : null;
      return {
        type: type,
        label: (
          <Flex alignItems="center">
            {icon} <Text mx={2}>{model.label}</Text>
          </Flex>
        ),
        count: data.globalSearch.results.filter((r) => r.type == type).length,
      };
    }
  );
  return (
    <Page title="Global Search">
      <Flex
        flex={1}
        p={4}
        style={{
          color: "text",
        }}
        flexDirection="column"
      >
        <Flex width={["100%", 1 / 2]}>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            rounded="large"
            px={2}
            sx={{
              borderColor: theme.colors.line,
              backgroundColor: "white",
              borderWidth: 1,
            }}
          />
          <Button
            ml={2}
            onClick={() =>
              refetch({
                variables: { limit: 100, skip: 0, search: searchText },
              })
            }
          >
            Search
          </Button>
        </Flex>
        {loading && <Loader />}
        {!loading && resultCount === 0 && (
          <Flex py={3}>
            <Text my={3} variant="muted">
              No results found
            </Text>
          </Flex>
        )}
        {!loading && resultCount > 0 && (
          <Flex flexDirection="column">
            <Box my={3}>
              <Text variant="muted">
                Showing results 1-{Math.min(100, resultCount)} of {resultCount}
              </Text>
            </Box>
            <Tabs width="100%" direction="rtl">
              <TabList>
                {types.map((t) => (
                  <Tab key={t.type}>
                    <Flex>
                      {t.label}
                      <Flex
                        mx={2}
                        variant="badge"
                        bg="gray.100"
                        alignItems={"center"}
                        justifyContent="center"
                      >
                        <Text variant="muted">{t.count}</Text>
                      </Flex>
                    </Flex>
                  </Tab>
                ))}
              </TabList>

              {types.map((t) => (
                <TabPanel key={t.type}>{getResults(t.type)}</TabPanel>
              ))}
            </Tabs>
          </Flex>
        )}
      </Flex>
    </Page>
  );
}

export default withRouter(GlobalSearch);
