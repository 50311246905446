import React from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import SurveyForm from "./SurveyQuestionForm";
import { Box, Flex, Image, Text } from "rebass";
import Settings from "../ProductSettings";
import { useQuery } from "@apollo/react-hooks";
import SurveyQuery from "../../../../graphql/queries/Survey.gql";
import GhostButton from "../../../Common/Buttons/GhostButton";
import { RiArrowGoBackLine } from "react-icons/ri";

function Surveys(props) {
  const { id } = useParams();
  const history = useHistory();
  const { data, loading, error } = useQuery(SurveyQuery, {
    variables: { id: id },
    skip: !id || id === "new",
  });
  return (
    <Settings>
      <Flex width="100%">
        <Flex flex={1} flexDirection="column">
          <Box>
            <GhostButton
              onClick={() =>
                history.push("/settings/product/library?tab=surveys")
              }
            >
              <RiArrowGoBackLine /> Back to Library
            </GhostButton>
          </Box>
          {!id ? (
            <EmptyState />
          ) : (
            <Flex flexDirection="column" flex={1}>
              <Text variant="h3">{data?.SurveyQuestion?.surveyName}</Text>
              <Flex p={3}>
                <SurveyForm />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(Surveys);

const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex p={5} justifyContent="center">
      <Flex flex={1} alignItems="flex-start" flexDirection="column">
        <Text variant="h2">Surveys</Text>
        <Text my={3}>
          Create your default surveys for your Accounts and then define
          automations to send them to your cusotmers.
        </Text>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ marginTop: "-10%", height: "150px", objectFit: "contain" }}
        />
      </Flex>
    </Flex>
  );
};
