import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import EventsByEntityQuery from "../graphql/queries/EventsByEntity.gql";

export function getEventsByEntity(id, parentType) {
  const query = useQuery(EventsByEntityQuery, {
    variables: {
      id: id,
      parentType: parentType,
    },
    skip: !id || id === "new",
  });
  return {
    ...query,
  };
}
