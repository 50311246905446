import React from "react";
import { Box, Link, Flex, Button } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";

function StepOne(props) {
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
      })}
      autoComplete={false}
      onSubmit={(values, actions) => {
        props.onUpdate(values);
        props.nextStep();
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;

        return (
          <Box
            as="form"
            flexDirection="column"
            onSubmit={handleSubmit}
            noValidate
            width="100%"
            sx={{
              minWidth: 350,
            }}
            py={4}
          >
            <CustomFormGroup
              placeholder="First Name"
              label="First Name"
              name="firstName"
              value={values.firstName}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.firstName && errors.firstName}
            />
            <CustomFormGroup
              placeholder="Last Name"
              label="Last Name"
              name="lastName"
              value={values.lastName}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.lastName && errors.lastName}
            />
            <Flex mt={2} justifyContent="center" flexDirection="column">
              <Button
                rounded="large"
                size="md"
                flex={1}
                primary
                onClick={handleSubmit}
              >
                <Flex flex={1} alignItems="center" justifyContent="center">
                  Continue
                </Flex>
              </Button>
            </Flex>
          </Box>
        );
      }}
    </Formik>
  );
}

export default StepOne;
