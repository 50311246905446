import React, { Component } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTheme } from "emotion-theming";

function DroppableSection(props) {
  const theme = useTheme();

  return (
    <Droppable droppableId={props.droppableId}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default DroppableSection;
