import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Label } from "@rebass/forms";
import _uniqueId from "lodash/uniqueId";
import { Flex, Text } from "rebass";

const CheckboxGroup = ({
  title,
  labelSx,
  options = [],
  selectedOptions,
  onChange,
  ...otherProps
}) => {
  // useMemo ensures the groupId prop doesn't needlessly change on re-render
  const groupId = useMemo(() => _uniqueId("checkbox-group-"), []);

  const groupPillLabelStyle = {
    label: {
      display: "flex",
      flexGrow: 1,
      variant: "buttons.pill",
      fontWeight: "body",
      padding: "0",
      "&:hover": {
        bg: "gray.200",
      },
      "&:first-of-type:not(:last-of-type)": {
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      },
      "&:last-of-type:not(:first-of-type)": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
      },
      "&:not(:first-of-type):not(:last-of-type)": {
        borderRadius: "0",
        ml: "-1px",
        mr: "-1px",
      },
      "&:nth-last-of-type(2):first-of-type": {
        mr: "-1px",
      },
      'input[type="checkbox"]': {
        opacity: "0",
        position: "absolute",
        "&:checked + span": {
          bg: "secondaryLight",
          borderRadius: "inherit",
          color: "white",
        },
      },
    },
  };
  return (
    <Flex
      role="checkboxgroup"
      aria-labelledby={groupId}
      display="flex"
      sx={groupPillLabelStyle}
      {...otherProps}
    >
      {options.map(({ label, value, cyName }, index) => (
        <Label
          key={index}
          width="100%"
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            checked={selectedOptions?.find((o) => o === value)}
            name={name}
            onChange={onChange}
            type="checkbox"
            value={value}
            data-cy={cyName}
          />
          <Text
            alignItems="center"
            as="span"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            px={2}
            py={1}
            fontSize="xs"
            role="presentation"
            width="100%"
          >
            {label}
          </Text>
        </Label>
      ))}
    </Flex>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.string,
    })
  ),
  selectedOption: PropTypes.string,
  title: PropTypes.string,
  labelSx: PropTypes.object,
};

export default CheckboxGroup;
