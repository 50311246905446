import React, { useEffect, useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
import { useTheme } from "emotion-theming";
import moment from "moment";
import TasksQuery from "../../../graphql/queries/Tasks.gql";
import Avatar from "react-avatar";
import { searchFilters } from "hooks/searchFilters";
import { useQuery } from "@apollo/client";

function TaskDasboard({
  filters,
  startOffset,
  endOffset,
  onClick,
  includeDone,
  includeOverdue,
  activityTypes,
}) {
  const theme = useTheme();
  const [filterQuery, setFilter] = searchFilters();

  const tasksQuery = useQuery(TasksQuery, {
    variables: {
      teamId: filters?.teamId,
      segmentId: filters?.segmentId,
      activityTypes,
      startDays: startOffset || 0,
      endDays: endOffset || 0,
      includeDone,
      includeOverdue,
    },
    skip: !filters,
  });
  const { data: tasks, loading } = tasksQuery;

  const data = tasks?.tasks;
  const hasData = !!data;
  const teamMembers = hasData
    ? _.uniq(data?.filter((t) => t.user).map((t) => t.user))
    : [];
  const teamMemberIds = hasData
    ? _.uniq(data?.filter((t) => t.user).map((t) => t.user?.id))
    : [];

  let teamMemberData = [];
  teamMemberIds.map((id, i) => {
    const c = teamMembers.find((t) => t.id === id);

    const ud = data?.filter((t) => t.user?.id === c.id);
    teamMemberData.push({
      total: ud?.length,
      overdue: ud?.filter((t) => moment(t.dueAt).isBefore(moment())).length,
      overdueData: [
        {
          name: "Overdue",
          value: ud
            ?.filter((t) => !t.completedAt)
            .filter((t) => moment(t.dueAt).isBefore(moment())).length,
          color: theme.colors.red,
        },
        {
          name: "Other",
          value: ud
            ?.filter((t) => !t.completedAt)
            .filter((t) => moment(t.dueAt).isSameOrAfter(moment())).length,
          color: theme.colors.green,
        },
      ],
      priorityData: [
        {
          name: "Done",
          value: ud.filter((t) => t.completedAt).length,
          color: theme.colors.greenDark,
        },
        {
          name: "Critical",
          value: ud
            ?.filter((t) => t.priority >= 4)
            .filter((t) => !t.completedAt).length,
          color: theme.colors.redLight,
        },
        {
          name: "Low",
          value: ud.filter((t) => !t.completedAt).length,
          color: "#ABD89C",
        },
      ],
      data: [
        {
          name: "Stuck",
          value: ud
            ?.filter((t) => t.priority <= 3)
            ?.filter((t) => t.taskStatusName === "Stuck")
            ?.filter((t) => !t.completedAt).length,
          priority: ud
            ?.filter((t) => t.priority >= 4)
            ?.filter((t) => t.taskStatusName === "Stuck")
            ?.filter((t) => !t.completedAt).length,
          color: theme.colors.yellow,
        },
        {
          name: "Open",
          value: ud
            ?.filter((t) => t.priority <= 3)
            .filter((t) => t.taskStatusName === "Open")
            ?.filter((t) => !t.completedAt).length,
          priority: ud
            ?.filter((t) => t.priority >= 4)
            .filter((t) => t.taskStatusName === "Open")
            ?.filter((t) => !t.completedAt).length,
          color: "#ABD89C", // Override greenLight as it is unreadable here
        },
        {
          name: "In Progress",
          value: ud
            ?.filter((t) => t.priority <= 3)
            .filter((t) => t.taskStatusName === "In Progress")
            ?.filter((t) => !t.completedAt).length,
          priority: ud
            ?.filter((t) => t.priority >= 4)
            .filter((t) => t.taskStatusName === "In Progress")
            ?.filter((t) => !t.completedAt).length,
          color: theme.colors.green,
        },
        {
          name: "Done",
          value: ud
            ?.filter((t) => t.priority <= 3)
            .filter((t) => !!t.completedAt || t.taskStatusName === "Done")
            .length,
          priority: ud
            ?.filter((t) => t.priority >= 4)
            .filter((t) => !!t.completedAt || t.taskStatusName === "Done")
            .length,
          color: theme.colors.greenDark,
        },
      ],
      avatarUrl: c.avatarUrl,
      email: c.email,
      name: c.name,
      id: c.id,
    });
  });
  teamMemberData.sort((a, b) => b.total - a.total);

  return (
    <Flex flex={1} alignItems={"flex-start"} my={4} pt={3}>
      <Flex alignItems={"flex-start"} flexDirection="column">
        <Flex flex={1} alignItems="center" mb={3}>
          {!loading &&
            teamMemberData.map((m) => {
              const height = 160;
              const maxCalc =
                100 / ((teamMemberData[0].total / data?.length) * height);

              return (
                <Flex
                  key={m.id}
                  mx={3}
                  flexDirection={"column"}
                  height={height}
                  justifyContent="flex-end"
                  alignItems={"center"}
                  sx={{ cursor: onClick && "pointer" }}
                  onClick={() => {
                    setFilter("ownerUserId", m.id, m.name);
                    onClick && onClick();
                  }}
                >
                  <Text mx={1} variant="muted">
                    {m.total}
                  </Text>
                  <Flex justifyContent="flex-end" alignItems={"flex-end"}>
                    <Box>
                      {m.data.map((d, i) => {
                        return (
                          <Box key={i}>
                            <Flex
                              key={i}
                              height={
                                (d.priority / data?.length) * height * maxCalc
                              }
                              width={60}
                              bg={d.color}
                              sx={{
                                backgroundColor: theme.colors.gray[100],
                                backgroundImage:
                                  "repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(255,255,255,.5) 4px, rgba(255,255,255,.5) 8px)",
                              }}
                            ></Flex>
                            <Flex
                              height={(d.value / data?.length) * 160 * maxCalc}
                              width={60}
                              bg={d.color}
                            ></Flex>
                          </Box>
                        );
                      })}
                    </Box>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent={"center"}
                    mt={2}
                    sx={{ cursor: onClick && "pointer" }}
                  >
                    <DonutGraph
                      noLegend
                      data={m.overdueData}
                      width={55}
                      value={
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                          bg="white"
                          sx={{ borderRadius: 99, width: 20, height: 20 }}
                        >
                          <Avatar
                            size={45}
                            email={m.email}
                            round
                            name={m.name}
                            src={m.avatarUrl}
                          />
                        </Flex>
                      }
                    />
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
      </Flex>
      <Flex ml={4} flexDirection={"column"}>
        <Flex
          flex={1}
          height="100%"
          alignItems={"flex-start"}
          flexDirection={"column"}
        >
          <Flex ml={3} flex={1} flexDirection={"column"}>
            {teamMemberData &&
              teamMemberData[0]?.data.map((d) => (
                <Flex key={d.label} my={1} alignItems="center">
                  <Box
                    m={1}
                    sx={{ width: 10, height: 10, borderRadius: 10 }}
                    bg={d.color}
                  />
                  <Text mx={1} variant="muted" fontSize="xxs">
                    {d.name}
                  </Text>
                </Flex>
              ))}
            <Flex my={1} alignItems="center">
              <Box
                m={1}
                sx={{ width: 10, height: 10, borderRadius: 10 }}
                bg={theme.colors.red}
              />
              <Text mx={1} variant="muted" fontSize="xxs">
                Overdue
              </Text>
            </Flex>

            <Flex my={1} alignItems="center">
              <Flex
                m={1}
                height={10}
                width={10}
                sx={{
                  borderRadius: 10,
                  backgroundColor: theme.colors.gray[600],
                  backgroundImage:
                    "repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(255,255,255,.5) 4px, rgba(255,255,255,.5) 8px)",
                }}
              ></Flex>
              <Text mx={1} variant="muted" fontSize="xxs">
                Critical
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TaskDasboard;
