import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import HealthscoresQuery from "../graphql/queries/Healthscores.gql";

export function getHealthscores() {
  const query = useQuery(HealthscoresQuery, {});

  return {
    ...query,
  };
}
