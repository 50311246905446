import React, { useState } from "react";
const _ = require("lodash");
import { MODEL_TYPES } from "consts";
import CompanyHealth from "./CompanyHealth";
import EndUserHealth from "./EndUserHealth";

function HealthDashboard(props) {
  return props.parentType === MODEL_TYPES.Company.name ? (
    <CompanyHealth {...props} />
  ) : (
    <EndUserHealth {...props} />
  );
}

export default HealthDashboard;
