import React, { useEffect, useState } from "react";
import { Flex, Box, Link, Text } from "rebass";
import Select from "Components/Common/Forms/Select";
import { getSegments } from "hooks/getSegments";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getCurrentUser } from "hooks/getCurrentUser";
import { getSearchFilter } from "hooks/getSearchFilter";
import { getUsers } from "hooks/getUsers";
import { useTheme } from "emotion-theming";
import { deepOmit } from "consts";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";
import _ from "lodash";
import { Input } from "@rebass/forms";
import Avatar from "react-avatar";
import { RiLayoutRightLine, RiLayoutTop2Line } from "react-icons/ri";
import {
  EditIcon,
  PlusIcon,
  ArrowDropDownIcon,
  PrivateIcon,
  SearchIcon,
  SegmentIcon,
  StarIcon,
  StarOffIcon,
  CloseIcon,
  UserIcon,
  TagIcon,
  TeamIcon,
} from "../Common/Icons";
import SegmentEditor from "./Segment";

import PropTypes from "prop-types";
import SearchFilterMutation from "../../graphql/mutations/SearchFilter";
import SegmentMutation from "../../graphql/mutations/Segment.gql";
import { getCoreSegments } from "../../hooks/getSegments";
import { useUrlParams } from "../../hooks/useUrlParams";
import TeamsQuery from "../../graphql/queries/Teams.gql";
import UserBadge from "../Common/UserBadge";
import UserTeamSelect from "./UserTeamSelect";

function Filter(props) {
  const { inlineSegments, module, includeCoreSegments = false } = props;

  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const [showSegment, setShowSegment] = useState(!inlineSegments);
  const [pinned, setPinned] = useState(!inlineSegments);
  const [saveFilter] = useMutation(SearchFilterMutation);

  const segmentQuery = getSegments(props.typeName);
  const coreSegmentQuery = getCoreSegments();

  const currentUser = getCurrentUser();
  const filterQuery = getSearchFilter();
  const filters = filterQuery.data?.filters;
  const usersQuery = getUsers();
  const teamsQuery = useQuery(TeamsQuery);

  const product = currentUser?.currentProduct;

  let accountOptions = [
    {
      value: undefined,
      label: (
        <Flex alignItems={"center"}>
          <Avatar size={25} round name={product?.name} src={product?.logoUrl} />
          <Text mx={2}>All Accounts</Text>
        </Flex>
      ),
    },
  ];

  const defaultSegment = getUrlParameter("segment");

  useEffect(() => {
    if (filters && filters.segmentPinned !== undefined) {
      setPinned(filters.segmentPinned);
      if (!filters.segmentPinned) setShowSegment(false);
    }
  }, [filters?.segmentPinned]);

  useEffect(() => {
    if (
      defaultSegment &&
      segmentQuery?.data &&
      filters?.segmentId != defaultSegment
    ) {
      const segment = segmentQuery?.data?.segments.find(
        (s) => s.id === defaultSegment
      );
      if (segment) {
        updateFilter("segmentId", segment.id, segment.name);
      }
    }
  }, [defaultSegment, segmentQuery?.data]);

  usersQuery.data?.users?.forEach((user) => {
    if (user.id == currentUser?.currentUser?.id) {
      accountOptions.push({
        value: user.id,
        userId: user.id,
        name: "My Accounts",
        label: (
          <Flex
            alignItems="center"
            sx={{
              borderBottomColor: "gray.300",
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderTopColor: "gray.300",
              borderTopWidth: 1,
              borderTopStyle: "solid",
            }}
            py={2}
          >
            <Avatar
              size={25}
              round
              name={user.name}
              src={user.avatarUrl}
              email={user?.email}
            />
            <Text mx={2}>My Accounts</Text>
          </Flex>
        ),
      });
    }
  });

  teamsQuery?.data?.teams?.forEach((team) => {
    accountOptions.push({
      value: team.id,
      name: undefined,
      userId: undefined,
      teamId: team.id,
      teamName: team.name,
      label: (
        <Flex alignItems="center">
          <Avatar size={25} round name={team.name} src={team.logoUrl} />
          <Text mx={2} fontWeight="bold" color={"brand"}>
            {team.name}
          </Text>
        </Flex>
      ),
    });
    team.memberships.map((r) => {
      accountOptions.push({
        value: `${team.id}${r.user?.id}`,
        name: `${r.user?.firstName || ""} ${r.user?.lastName || ""}`,
        label: (
          <Flex alignItems="center" ml={3}>
            <Avatar
              size={25}
              round
              name={`${r.user?.firstName || ""} ${r.user?.lastName || ""}`}
              email={r.user?.email}
              src={r.user?.avatarUrl}
            />
            <Box mx={2}>
              <Text fontWeight="bold" color={"brand"}>
                {`${r.user?.firstName || ""} ${r.user?.lastName || ""}`}
              </Text>
              <Text variant="label" fontSize="xxs">
                {team.name}
              </Text>
            </Box>
          </Flex>
        ),
        teamId: team.id,
        teamName: team.name,
        userId: r.user?.id,
      });
    });
  });
  const updateFilter = (name, id, label) => {
    const newFilters = filters;

    let ref = newFilters;
    let nestedNames = name.split(".").reverse();
    while (nestedNames.length > 1) {
      const n = _.last(nestedNames);
      ref[n] = {};
      ref = ref[n];
      nestedNames = nestedNames.slice(0, -1);
    }
    const n = _.last(nestedNames);
    ref[n] = id;

    if (!newFilters.labels) {
      newFilters.labels = {};
    }
    newFilters.labels[name] = label;
    saveFilter({
      variables: {
        filters: newFilters,
      },
    }).then(
      (response) => {
        props.onChange && props.onChange();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  let data = segmentQuery.data?.segments || [];

  if (includeCoreSegments) {
    const coreData = coreSegmentQuery.data?.coreSegments || [];
    data = _.union(coreData, data);
  }

  data.sort((x, y) => {
    // true values first
    return Number(y.pinned) - Number(x.pinned);
  });

  const selectedSegmentId = filters?.segmentId;
  const selectedSegment = data && data.find((s) => s.id === selectedSegmentId);

  return (
    <Flex flex={1} width="100%" flexDirection="column">
      <Flex mx={3} mt={2} justifyContent="space-between">
        <Flex flex={1} alignItems="center">
          {props.menu}
        </Flex>
        <Flex ml={2} alignItems="flex-end" sx={{ position: "relative" }}>
          {!pinned && (
            <SecondaryButton
              leftIcon={<SegmentIcon selected={!!selectedSegmentId} />}
              onClick={() => setShowSegment(!showSegment)}
              rightIcon={<ArrowDropDownIcon />}
            >
              Segments
            </SecondaryButton>
          )}
          <Box mx={1} />
          <UserTeamSelect
            onChange={() => {
              props.onChange && props.onChange();
            }}
          />
          {!pinned && (
            <>
              <SegmentDetail
                showSegment={showSegment}
                setShowSegment={setShowSegment}
                saveSegmentFilter={(id, label) => {
                  updateFilter("segmentId", id, label);
                  props.onChange();
                }}
                selectedSegment={selectedSegment}
                setPinned={(p) => {
                  //setPinned(p);
                  updateFilter("segmentPinned", p);
                }}
                pinned={pinned}
                data={data}
                onChange={() => {
                  window.history.replaceState(
                    {},
                    document.title,
                    `${window.location.pathname}?segment=${id}`
                  );
                  props.onChange();
                }}
                typeName={props.typeName}
              />
            </>
          )}
        </Flex>
      </Flex>
      {filters?.labels &&
        JSON.stringify(filters?.labels) !== JSON.stringify({}) && (
          <Flex alignItems="center" mx={3} my={2}>
            <Text fontWeight="bold" variant="label">
              Filters
            </Text>
            <FilterLabel
              onCancel={() => updateFilter("segmentId", undefined, undefined)}
              id="segmentId"
              icon={<SegmentIcon size={13} />}
            />
            <FilterLabel
              onCancel={() => updateFilter("ownerUserId", undefined, undefined)}
              id="ownerUserId"
              icon={<UserIcon size={13} />}
            />
            <FilterLabel
              onCancel={() => updateFilter("teamId", undefined, undefined)}
              id="teamId"
              icon={<TeamIcon size={13} />}
            />
            <FilterLabel
              onCancel={() =>
                updateFilter(`${module}.planId`, undefined, undefined)
              }
              id={`${module}.planId`}
            />
            <FilterLabel
              onCancel={() =>
                updateFilter(`${module}.lifeCycleStageId`, undefined, undefined)
              }
              id={`${module}.lifeCycleStageId`}
            />
            <FilterLabel
              onCancel={() => updateFilter(`tags`, [], undefined)}
              id={`tags`}
              icon={<TagIcon size={13} />}
            />
          </Flex>
        )}
      <Flex flex={1}>
        <Flex flex={1} mx={3} my={3}>
          {props.children}
        </Flex>
        {pinned && (
          <Flex my={2}>
            <SegmentDetail
              showSegment={showSegment}
              setShowSegment={setShowSegment}
              saveSegmentFilter={(id, label) => {
                updateFilter("segmentId", id, label);
                props.onChange();
              }}
              selectedSegment={selectedSegment}
              setPinned={(p) => updateFilter("segmentPinned", p)}
              pinned={pinned}
              data={data}
              onChange={props.onChange}
              typeName={props.typeName}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
Filter.propTypes = {
  inlineSegments: PropTypes.bool,
  includeCoreSegments: PropTypes.bool,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  module: PropTypes.string,
  typeName: PropTypes.string,
  id: PropTypes.string,
  menu: PropTypes.object,
  children: PropTypes.object,
};
export default Filter;

const FilterLabel = ({ id, onCancel, icon }) => {
  const filterQuery = getSearchFilter();
  const filters = filterQuery.data?.filters?.labels;

  return filters && filters[id] ? (
    <Flex
      bg="gray.200"
      fontSize="xs"
      p={1}
      px={2}
      ml={2}
      alignItems="center"
      sx={{ borderRadius: 99 }}
    >
      <Text color="brandLight">{icon}</Text>
      <Text mx={1}>{filters[id]}</Text>
      <Flex
        justifyContent="flex-end"
        width={20}
        color="line"
        sx={{ cursor: "pointer" }}
        onClick={onCancel}
      >
        <CloseIcon />
      </Flex>
    </Flex>
  ) : null;
};
FilterLabel.propTypes = {
  onCancel: PropTypes.func,
  id: PropTypes.string,
  icon: PropTypes.object,
};
const SegmentDetail = ({
  showSegment,
  setShowSegment,
  selectedSegment,
  saveSegmentFilter,
  pinned,
  setPinned,
  data,
  typeName,
  onChange,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState(undefined);
  const [segmentId, setSegmentId] = useState(false);
  const [filterBy, setFilterBy] = useState(undefined);
  const [save] = useMutation(SegmentMutation);

  let prevSegmentableType = undefined;
  const saveMutation = (values) => {
    save({
      variables: {
        id: values.id,
        name: values.name,
        config: deepOmit(values.config, "__typename"),
        segmentableType: values.segmentableType,
        pinned: !values.pinned,
      },
    }).then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    showSegment && (
      <Flex
        p={3}
        bg="white"
        flexDirection="column"
        sx={{
          borderColor: pinned && "accent",
          borderLeftWidth: pinned ? 1 : 0,
          borderRadius: !pinned && 8,
          borderLeftStyle: "solid",
          zIndex: 99,
          position: !pinned ? "absolute" : "inline-block",

          top: !pinned ? 40 : 0,
          width: !pinned ? 400 : 300,
          boxShadow: !pinned
            ? `2px 0px 6px -1px ${theme.colors.gray[500]}`
            : "none",
        }}
      >
        {pinned && (
          <Flex justifyContent="space-between">
            <Flex alignItems="center" my={3}>
              <Text color="brandLight">
                <SegmentIcon selected={!!selectedSegment} />
              </Text>
              <Text mx={2} fontWeight="bold">
                Segments
              </Text>
            </Flex>

            {pinned && (
              <GhostButton onClick={() => setPinned(!pinned)}>
                <RiLayoutTop2Line color="brandLight" />
              </GhostButton>
            )}
          </Flex>
        )}
        <Flex alignItems="center">
          <Flex
            flex={1}
            sx={{ position: "relative" }}
            alignItems="flex-start"
            mb={3}
            mt={1}
          >
            <Input
              placeholder="Search"
              value={searchText}
              width="100%"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <Flex
              sx={{ position: "absolute", right: 0, top: 0, bottom: 0 }}
              width={35}
              color="brandLight"
              alignItems="center"
              justifyContent="center"
              p={2}
            >
              <SearchIcon size={16} />
            </Flex>
          </Flex>
          {!pinned && (
            <Box mt={1}>
              <GhostButton onClick={() => setPinned(!pinned)}>
                <RiLayoutRightLine color="brandLight" />
              </GhostButton>
            </Box>
          )}
        </Flex>
        <Flex>
          <GhostButton
            leftIcon={<SegmentIcon color="brandLight" />}
            onClick={() => setFilterBy(undefined)}
            active={!filterBy}
          >
            <Text mx={2} fontSize="xs">
              All
            </Text>
          </GhostButton>
          <GhostButton
            leftIcon={
              <Text color="yellow">
                <StarIcon />
              </Text>
            }
            onClick={() => setFilterBy("pinned")}
            active={filterBy === "pinned"}
          >
            <Text mx={2} fontSize="xs">
              Favorites
            </Text>
          </GhostButton>
          <GhostButton
            leftIcon={<PrivateIcon color="brandLight" />}
            onClick={() => setFilterBy("private")}
            active={filterBy === "private"}
          >
            <Text mx={2} fontSize="xs">
              Private
            </Text>
          </GhostButton>
        </Flex>

        {data && data.length > 0 ? (
          data
            .filter(
              (i) =>
                !searchText ||
                searchText === "" ||
                _.includes(i.name.toLowerCase(), searchText.toLowerCase())
            )
            .filter((i) => !filterBy || i[filterBy])
            .map((segment) => {
              let addBreak = segment.segmentableType !== prevSegmentableType;
              prevSegmentableType = segment.segmentableType;
              return (
                <>
                  {addBreak && <Flex variant="divider" />}
                  <Flex
                    key={segment.id}
                    px={2}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      fontWeight:
                        selectedSegment?.id === segment.id ? "bold" : "normal",
                      bg:
                        selectedSegment?.id === segment.id
                          ? "brandHighlight"
                          : "white",
                      borderRadius: selectedSegment?.id === segment.id ? 99 : 0,
                      "&:hover": {
                        backgroundColor:
                          selectedSegment?.id === segment.id
                            ? "brandHighlight"
                            : "white",
                        borderRadius:
                          selectedSegment?.id === segment.id ? 99 : 0,
                      },
                    }}
                  >
                    <Flex
                      alignItems="center"
                      flex={1}
                      onClick={() => {
                        if (!pinned) {
                          setShowSegment(false);
                        }

                        if (selectedSegment?.id === segment?.id) {
                          saveSegmentFilter(undefined, undefined);
                          window.history.replaceState(
                            {},
                            document.title,
                            `${window.location.pathname}`
                          );
                        } else {
                          if (segment) {
                            saveSegmentFilter(segment.id, segment.name);
                            window.history.replaceState(
                              {},
                              document.title,
                              `${window.location.pathname}?segment=${segment.id}`
                            );
                          } else {
                            saveSegmentFilter(undefined, undefined);
                            window.history.replaceState(
                              {},
                              document.title,
                              `${window.location.pathname}`
                            );
                          }
                        }
                      }}
                      sx={{ cursor: "pointer", height: 30 }}
                    >
                      <Box color="gray.500" width={20}>
                        {segment.private && <PrivateIcon />}
                      </Box>

                      <Link mx={2}>
                        <Text fontSize="xs">{segment.name}</Text>
                      </Link>
                    </Flex>
                    {segment.segmentableType && (
                      <Flex alignItems="center">
                        <GhostButton
                          onClick={() => {
                            setSegmentId(segment.id);
                            setOpen(true);
                          }}
                        >
                          <EditIcon />
                        </GhostButton>
                        <GhostButton
                          onClick={() => {
                            saveMutation(segment);
                          }}
                        >
                          <Text color={!segment.pinned ? "gray.500" : "yellow"}>
                            {segment.pinned ? <StarIcon /> : <StarOffIcon />}
                          </Text>
                        </GhostButton>
                      </Flex>
                    )}
                  </Flex>
                </>
              );
            })
        ) : (
          <Text variant="muted" textAlign="center" my={3}>
            No segments
          </Text>
        )}
        <Box mt={3} variant="divider" />
        <GhostButton
          leftIcon={<PlusIcon color="brandLight" size={18} />}
          onClick={() => setOpen(true)}
        >
          Add New Segment
        </GhostButton>

        <SegmentEditor
          open={open}
          typeName={typeName}
          onClose={(segment) => {
            setOpen(false);
            setSegmentId(false);

            saveSegmentFilter(segment?.id, segment?.name);
            onChange();
          }}
          segmentId={segmentId}
        />
      </Flex>
    )
  );
};
SegmentDetail.propTypes = {
  showSegment: PropTypes.bool,
  setShowSegment: PropTypes.func,
  selectedSegment: PropTypes.object,
  saveSegmentFilter: PropTypes.func,
  pinned: PropTypes.bool,
  setPinned: PropTypes.func,
  data: PropTypes.array,
  typeName: PropTypes.string,
};
