import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import { useTheme } from "emotion-theming";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
import WidgetWrapper from "./WidgetWrapper";
import Loader from "../../Common/Loader";
import Avatar from "react-avatar";

function HealthBreakdown({ data, loading }) {
  const theme = useTheme();

  const categoryData = data?.paid?.categoryData || [];

  let healthData = [];
  categoryData.find((d) => d.key === "good") &&
    healthData.push({
      value: categoryData.find((d) => d.key === "good").total,
      label: "good",
      color: theme.colors.green,
    });
  categoryData.find((d) => d.key === "ok") &&
    healthData.push({
      value: categoryData.find((d) => d.key === "ok").total,
      label: "ok",
      color: theme.colors.yellow,
    });

  categoryData.find((d) => d.key === "poor") &&
    healthData.push({
      value: categoryData.find((d) => d.key === "poor").total,
      label: "poor",
      color: theme.colors.red,
    });

  return (
    <WidgetWrapper title="Health Breakdown">
      {loading ? (
        <Loader />
      ) : (
        <Flex flex={1} alignItems="center" justifyContent="center">
          {healthData.length > 0 ? (
            <DonutGraph data={healthData} width={120} />
          ) : (
            <Flex flex={1} alignItems="center" justifyContent="center">
              <Image
                src={require("assets/images/HealthEmpty.png")}
                width={120}
              />
            </Flex>
          )}
        </Flex>
      )}
    </WidgetWrapper>
  );
}

export default HealthBreakdown;
