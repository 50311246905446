import React from "react";
import { withRouter } from "react-router-dom";
import { Flex } from "rebass";
import Settings from "../ProductSettings";

function TriggerPage(props) {
  return (
    <Settings>
      <Flex width="100%">
        <Flex p={4} flex={1}>
          {props.children}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(TriggerPage);
