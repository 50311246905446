import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Box, Text, Button } from "rebass";
import "react-contexify/dist/ReactContexify.min.css";
import { MoreIcon } from "Components/Common/Icons";
import GhostButton from "Components/Common/Buttons/GhostButton";
import EntityDeleteMutation from "../../graphql/mutations/EntityDelete.gql";
import Modal from "Components/Common/Modal";
import {
  Menu,
  Item,
  animation,
  MenuProvider,
  Separator,
} from "react-contexify";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import AdminOnly from "../MyAccount/AdminOnly";
import ShowError, { ErrorParams } from "../Common/Notifications/Error";
import { useToasts } from "react-toast-notifications";
import CompanyTreeView from "./Company/CompanyTreeView";
import { CompanyIcon, SaveIcon } from "../Common/Icons";
import { MODEL_TYPES } from "../../consts";
import CustomFormGroup from "../Common/Forms/CustomFormGroup";
import { getData } from "hooks/getData";
import { useDynamicGql } from "../../graphql/DynamicGql";
import PrimaryButton from "../Common/Buttons/PrimaryButton";
import { RiSwitchLine } from "react-icons/ri";
import { TiArrowSync } from "react-icons/ti";

function EntityDetailMenu({ id, parentType, onEdit }) {
  const [open, setOpen] = useState(false);
  const [switchAccountOpen, setSwitchAccountOpen] = useState(false);
  const [destroy] = useMutation(EntityDeleteMutation);
  const history = useHistory();
  return (
    <Flex flex={1} alignItems="center" justifyContent="flex-end">
      <MenuProvider id="company_menu_id" event="onClick">
        <GhostButton>
          <MoreIcon />
        </GhostButton>
      </MenuProvider>
      <Menu id="company_menu_id" animation={animation.zoom}>
        <Item onClick={() => onEdit()}>Edit Profile</Item>

        {parentType == MODEL_TYPES.Company.name && (
          <Item
            onClick={() => {
              setOpen(true);
            }}
          >
            Edit Hierarchy
          </Item>
        )}
        {parentType == MODEL_TYPES.Company.name && (
          <Item
            onClick={() => {
              setSwitchAccountOpen(true);
            }}
          >
            Switch Account Type
          </Item>
        )}
        <AdminOnly>
          <Box>
            <Separator />
            <Item>
              <EntityDeleteButton
                useIcon
                callback={destroy}
                onCancel={() => history.goBack()}
                id={id}
                parentType={parentType}
                refetch={() => {
                  history.push("/contacts/accounts");
                }}
                name={parentType}
              />
            </Item>
          </Box>
        </AdminOnly>
      </Menu>
      <AccountHierarchyModal
        open={open}
        onDismiss={() => setOpen(false)}
        id={id}
        parentType={parentType}
      />
      <SwitchAccountTypeModal
        open={switchAccountOpen}
        onDismiss={() => setSwitchAccountOpen(false)}
        id={id}
        parentType={parentType}
      />
    </Flex>
  );
}

export default EntityDetailMenu;

function SwitchAccountTypeModal(props) {
  const [isParent, setIsParent] = useState(false);

  const { loading, data } = getData(MODEL_TYPES.Company, props.id);
  const { mutation } = useDynamicGql(MODEL_TYPES.Company, false);
  const [saveCompany, { error: saveError }] = useMutation(mutation);

  let company = data?.company;

  const saveCompanyDetail = (isParent) => {
    saveCompany({
      variables: {
        id: company.id,
        fields: company.fields || {},
        startDate: company.details?.startDate,
        renewalDate: company.details?.renewalDate,
        churnReasonId: company.details?.churnReasonId,
        churnReasonUpdatedAt: company.details?.churnReasonUpdatedAt,
        arr: company.details?.arr?.toString(),
        mrr: company.details?.mrr?.toString(),
        name: company.name,
        ownerUserId: company.details?.owner?.id,
        lifeCycleStageId: company.details?.lifeCycleStageId,
        conversionAdoptionScore:
          company.details?.conversionAdoptionScore?.toString(),
        conversionFitScore: company.details?.conversionFitScore?.toString(),
        tagList: company.tagList,
        isParent: isParent,
        timezone: company.timezone,
        locale: company.locale,
      },
      context: {
        hasUpload: false,
      },
    }).then(
      (response) => {
        props.onDismiss();
        /*const result = response.data.company;
                  if (result?.errors) {
                    actions.setErrors([result?.errors]);
                    addToast(<Flex>{result?.errors[0]}</Flex>, ErrorParams);
                  } else {
                    props.onSave && props.onSave(result.company.id);
                    addToast(<Flex>Saved!</Flex>, SuccessParams);
                    if (props.modalAdd) {
                      history.push(`/contacts/accounts/${result.company.id}`);
                      setReadOnly(true);
                    }
                  }*/
      },
      (error) => {
        //addToast(<Flex>Error occured saving</Flex>, ErrorParams);
        console.log(error);
      }
    );
  };
  return (
    <Modal
      title={"Switch Account Type"}
      width={500}
      open={props.open}
      onDismiss={() => {
        props.onDismiss();
      }}
    >
      <Box pb={4} sx={{ minHeight: 500 }}>
        <Text as="h3">
          <CompanyIcon /> {company?.name}
        </Text>

        <Flex p={3} flexDirection={"column"}>
          <Text>
            {company?.isParent ? (
              "Switch account back to STANDARD account"
            ) : (
              <Box>
                <Box>Switch account to PARENT account. </Box>
                <Box bg="redLight" color="red" p={3} my={2} fontSize="xs">
                  Warning: A parent account is for reporting and grouping and
                  has limited features.
                </Box>
              </Box>
            )}
          </Text>

          <Box py={3}>
            {!company?.isParent ? (
              <PrimaryButton
                onClick={() => {
                  saveCompanyDetail(true);
                }}
              >
                <TiArrowSync /> Switch to parent account
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={() => {
                  saveCompanyDetail(false);
                }}
              >
                <TiArrowSync /> Switch to standard account
              </PrimaryButton>
            )}
          </Box>
        </Flex>
      </Box>
    </Modal>
  );
}

function AccountHierarchyModal(props) {
  return (
    <Modal
      title={"Account Hierarchy"}
      width={900}
      open={props.open}
      onDismiss={() => {
        props.onDismiss();
      }}
    >
      <Box pb={4} sx={{ minHeight: 500 }}>
        <Text as="h3">
          <CompanyIcon /> {props.data?.name} Hierarchy
        </Text>

        <Flex>
          <CompanyTreeView {...props} />
        </Flex>
      </Box>
    </Modal>
  );
}

export function EntityDeleteButton(props) {
  const history = useHistory();
  const { addToast, removeAllToasts } = useToasts();
  const handleDelete = () => {
    removeAllToasts();
    props.callback
      ? props
          .callback({
            variables: {
              id: props.id,
              klassType: props.parentType,
            },
          })
          .then(
            (response) => {
              var responseData = response.data[Object.keys(response.data)[0]];
              if (!responseData.errors || responseData.errors.length == 0) {
                props.refetch && props.refetch();
              } else if (responseData.errors && responseData.errors[0]) {
                addToast(
                  <ShowError message={responseData.errors[0]} />,
                  ErrorParams
                );
              }
            },
            (error) => {
              addToast(<ShowError message="Save error!" />, ErrorParams);
            }
          )
      : props.onDelete();
  };

  return props.id === "new" ? (
    <Button
      variant="ghost"
      mx={2}
      bg="red"
      onClick={() => {
        props.onCancel && props.onCancel();
      }}
    >
      <Flex alignItems="center">
        <Text>Cancel</Text>
      </Flex>
    </Button>
  ) : (
    <Flex
      sx={{ cursor: "pointer" }}
      p={2}
      color="red"
      onClick={() => {
        addToast(
          <Flex flexDirection="column">
            <Text my={2}>Are you sure you want to delete?</Text>
            <Flex justifyContent="flex-end">
              <Button variant="error" onClick={handleDelete}>
                Delete
              </Button>
            </Flex>
          </Flex>,
          ErrorParams
        );
      }}
    >
      <Flex alignItems="center">
        <Text>{props.label || "Delete"}</Text>
      </Flex>
    </Flex>
  );
}
EntityDeleteButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  redirectUrl: PropTypes.string,
  useIcon: PropTypes.bool,
  callback: PropTypes.func,
  onCancel: PropTypes.func,
  refetch: PropTypes.func,
  children: PropTypes.node,
};
