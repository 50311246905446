import React from "react";
import { Flex, Image, Box, Text, Link } from "rebass";
import BrandLine from "../Common/Graphs/BrandLine";

function WebsiteWrapper(props) {
  const menuLinkStyles = {
    fontWeight: 600,
    letterSpacing: 2,
    fontSize: 18,
    "&:hover": {
      textDecoration: "underline",
      color: "white",
    },
    "&:visited": {
      color: "white",
    },
  };
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{ width: "100%" }}
    >
      <Flex height="100vh" flex={1} sx={{ width: "100%" }}>
        <Flex
          width={2 / 4}
          bg="brand"
          flexDirection="column"
          alignItems="center"
        >
          <Flex
            width="100%"
            p={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Image src={require("assets/images/LogoWhite.png")} />
            <Box>
              <Link
                color="white"
                href="https://www.userlot.com/features"
                mx={3}
                sx={menuLinkStyles}
              >
                FEATURES
              </Link>
              <Link
                color="white"
                href="https://www.userlot.com/pricing"
                mx={3}
                sx={menuLinkStyles}
              >
                PRICING
              </Link>
              <Link
                color="white"
                href="https://www.userlot.com/userlot-customer-success-blog"
                mx={3}
                sx={menuLinkStyles}
              >
                BLOG
              </Link>
              <Link
                color="white"
                href="https://www.userlot.com/contact-us"
                mx={3}
                sx={menuLinkStyles}
              >
                CONTACT US
              </Link>
            </Box>
            <Box />
          </Flex>
          <Flex
            flex={1}
            flexDirection="column"
            width="70%"
            justifyContent="center"
            alignItems="center"
            mb={6}
          >
            <Text variant="title" color="white" textAlign="center" my={2}>
              Look beyond churn.
            </Text>
            <Text variant="title" color="white" textAlign="center" my={2}>
              Supercharge growth
            </Text>
            <Text color="white" textAlign="center" my={2}>
              The Customer Success Management Platform that helps SaaS companies
              maximise Net Revenue Retention.
            </Text>
            <Flex my={4} justifyContent="center">
              <Image
                src={require("assets/images/LoginSplash.png")}
                width="70%"
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          width={2 / 4}
          bg="white"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Flex justifyContent="center" alignItems="center">
            <Image
              src={require("assets/images/logo.png")}
              sx={{ height: 60 }}
            />
          </Flex>
          {props.children}
        </Flex>
      </Flex>
      <BrandLine />
    </Flex>
  );
}

export default WebsiteWrapper;
