import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import Settings from "../ProductSettings";
import TypeSchemaQuery from "../../../../graphql/queries/TypeSchemas.gql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TypeModel from "./TypeModel";
import Loader from "Components/Common/Loader";
import { useEffect } from "react";
import { Flex, Link, Text } from "rebass";
import ModelHeader from "Components/Common/ModelHeader";
import { MODEL_TYPES } from "consts";
import { DownloadIcon } from "Components/Common/Icons";

function CustomEntity(props) {
  const { addToast } = useToasts();
  const hash = props.location?.hash;
  const [selectedTab, setSelectedTab] = useState(0);
  const { loading, data, error, refetch } = useQuery(TypeSchemaQuery, {});

  if (loading)
    <Settings>
      <Loader />
    </Settings>;

  const models = [
    MODEL_TYPES.Company.name,
    MODEL_TYPES.EndUser.name,
    MODEL_TYPES.Event.name,
    MODEL_TYPES.Ticket.name,
  ];
  const dataModels = data?.typeSchemas
    ?.filter((a) => _.includes(models, a.typeName))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  useEffect(() => {
    if (hash && dataModels) {
      setSelectedTab(
        dataModels.indexOf(
          dataModels.find(
            (m) =>
              m.typeName.toLowerCase() === hash.replace("#", "").toLowerCase()
          )
        )
      );
    }
  }, [dataModels]);

  return (
    <Settings>
      <Tabs selectedIndex={selectedTab} onSelect={setSelectedTab}>
        <TabList>
          {dataModels?.map((schema, i) => (
            <Tab key={schema.id}>
              <ModelHeader name={schema?.displayName} type={schema?.typeName} />
            </Tab>
          ))}
        </TabList>
        {dataModels?.map((schema) => (
          <TabPanel key={schema.id}>
            <Flex bg="white" px={4} flexDirection="column">
              <TypeModel data={schema} />
              <Flex
                fontSize="sm"
                alignItems="center"
                justifyContent="center"
                my={4}
              >
                <DownloadIcon /> <Text ml={1}>Download</Text>
                <Link
                  mx={1}
                  href={`${window.location.origin}/import/download?model=${schema.typeName}`}
                >
                  {schema.typeName}
                </Link>
                import template
              </Flex>
            </Flex>
          </TabPanel>
        ))}
      </Tabs>
    </Settings>
  );
}

export default withRouter(CustomEntity);
