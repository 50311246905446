import React, { useState } from "react";
import Topbar from "Components/Common/Topbar/Topbar";
import { Flex, Text } from "rebass";
import { getCurrentUser } from "hooks/getCurrentUser";
import { HomeIcon, MoneyIcon, TeamIcon, TickIcon } from "../../Common/Icons";
import { setupConfigureItems } from "consts";
import TaskIcon from "../../CommandCenter/Tasks/TaskDefaults";

function HomeTopbar(props) {
  const { currentAccount, loading } = getCurrentUser();

  const config = currentAccount?.setupConfig;
  const keys = config ? Object.keys(config) : [];
  const completedItems = keys?.filter((k) => config[k]).length;

  const remainingItems = setupConfigureItems.length - completedItems;

  let items = [
    { name: "Home", icon: HomeIcon, link: "/home" },
    { name: "Teams", icon: TeamIcon, link: "/teams" },
  ];

  if (!loading && remainingItems > 0) {
    items.push({
      name: (
        <Flex alignItems="center">
          <Text>Getting Started</Text>
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="brandLight"
            color="white"
            fontSize="xxs"
            sx={{
              borderRadius: 99,
              width: 18,
              height: 18,
              marginTop: -10,
              marginLeft: 2,
            }}
          >
            {remainingItems}
          </Flex>
        </Flex>
      ),
      icon: TickIcon,
      link: "/getting_started",
    });
  }
  return <Topbar title="Dashboard" menuItems={items} crumbs={props.crumbs} />;
}

export default HomeTopbar;
