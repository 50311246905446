import React, { useState } from "react";
import { Flex, Text } from "rebass";
import Tasks from "../Data/Tasks";
import ActivityHistory from "./ActivityHistory";
import moment from "moment";
import AddButton from "./AddButton";
import PropTypes from "prop-types";
import TasksQuery from "../../graphql/queries/EntityTasks.gql";
import { useQuery } from "@apollo/client";

const _ = require("lodash");

function Activity(props) {
  const { id, parentType } = props;
  const [forceRefresh, setForceRefresh] = useState(moment());

  const { refetch } = useQuery(TasksQuery, {
    variables: { id: props.id, parentType: props.parentType },
    fetchPolicy: "network-only",
  });
  return (
    <Flex width="100%">
      <Flex width="100%" flexDirection="column">
        <Flex width="100%" flex={1} flexDirection="column">
          <Flex flex={1} alignItems={"center"} justifyItems={"space-between"}>
            <Text variant="h3">Todo</Text>
            <Flex flex={1} justifyContent={"flex-end"}>
              <AddButton
                id={id}
                parentType={parentType}
                onSave={(tab) => {
                  refetch();
                  setForceRefresh(moment());
                }}
              />
            </Flex>
          </Flex>
          <Tasks
            key={forceRefresh.format("YYYYMMDDhhmm")}
            id={id}
            parentType={parentType}
            onSave={() => {
              setForceRefresh(moment());
              refetch();
            }}
            view="summary"
          />
        </Flex>

        <ActivityHistory
          key={forceRefresh.format("YYYYMMDDhhmmss")}
          onSave={() => refetch()}
          parentType={parentType}
          id={id}
          {...props}
        />
      </Flex>
    </Flex>
  );
}

Activity.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
  actionableName: PropTypes.string,
};
export default Activity;
