import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  ColumnFiltersState,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  useReactTable,
} from "@tanstack/react-table";
import { Text, Flex, Image, Box, Link, Button } from "rebass";
import { useTheme } from "emotion-theming";
import { useSkipper } from "./hooks";
import "./table_override.css";
import DebouncedInput from "./components/DebouncedInput";
import ActionButtons from "./components/ActionButtons";
import CustomTable from "./components/CustomTable";
import Loader from "../Loader";
import IndeterminateCheckbox from "./components/IndeterminateCheckbox";
import GhostButton from "../Buttons/GhostButton";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import "./table_override.css";
import SecondaryButton from "../Buttons/SecondaryButton";
import { DownloadIcon } from "../Icons";
import ExportButton from "./ExportButton";

const _ = require("lodash");

const convertColumns = (columns) => {
  const theme = useTheme();
  return columns
    ? _.concat(
        [
          {
            id: "select",
            action: true,
            header: ({ table }) => (
              <Flex>
                <GhostButton onClick={table.getToggleAllRowsExpandedHandler()}>
                  {table.getIsAllRowsExpanded() ? (
                    <RiArrowDownSLine />
                  ) : (
                    <RiArrowRightSLine />
                  )}
                </GhostButton>
                <IndeterminateCheckbox
                  checked={table.getIsAllRowsSelected()}
                  indeterminate={table.getIsSomeRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </Flex>
            ),
            cell: ({ row }) => (
              <Flex px={1}>
                {row.getCanExpand() ? (
                  <GhostButton onClick={row.getToggleExpandedHandler()}>
                    {row.getIsExpanded() ? (
                      <RiArrowDownSLine />
                    ) : (
                      <RiArrowRightSLine />
                    )}
                  </GhostButton>
                ) : (
                  <Box ml={3}></Box>
                )}
                <IndeterminateCheckbox
                  checked={row.getIsSelected()}
                  indeterminate={row.getIsSomeSelected()}
                  onChange={row.getToggleSelectedHandler()}
                />
              </Flex>
            ),
            enablePinning: false,
            enableColumnFilter: false,
          },
        ],
        columns.map((c) => {
          return {
            accessorFn: (row) => c.selector(row),
            id: c.id,
            cell: (info) => c.cell(info.row.original),
            header: c.name,
            enablePinning: false,
            enableColumnFilter: false,
          };
        })
      )
    : [];
};

function ReactTable(props) {
  const rerender = React.useReducer(() => ({}), {})[1];

  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [grouping, setGrouping] = React.useState();
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnPinning, setColumnPinning] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const data = props.data || [];

  const updateRowSelection = (rows) => {
    setRowSelection(rows);
    const ids = Object.keys(rows()).map((k) => k);
    props.onSelectedRowsChange(
      props.rawData?.filter((d) => ids.indexOf(d.id) >= 0)
    );
  };

  const table = useReactTable({
    data: data,
    columns: convertColumns(props.columns),
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onGroupingChange: setGrouping,
    onRowSelectionChange: updateRowSelection,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getRowId: (originalRow, index, parent) => originalRow.id,
    /*getGroupedRowModel: getGroupedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqu=> eValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnPinningChange: setColumnPinning,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,*/
    state: {
      grouping,
      globalFilter,
      columnVisibility,
      rowSelection,
      expanded,
    },
  });

  return (
    <Box width={"100%"}>
      <Flex alignItems="center" justifyContent="flex-end" my={2}>
        <Flex>
          {props.search}

          {props.exportable && (
            <ExportButton columns={props.columns} data={props.data} />
          )}
          <Box mx={1} />
          {props.actions}
        </Flex>
      </Flex>
      <Box width={"100%"}>
        {props.loading ? (
          <Flex flex={1} justifyContent={"center"} alignItems="center">
            <TableLoader />
          </Flex>
        ) : (
          <Box width={"100%"}>
            <CustomTable table={table} onRowClicked={props.onRowClicked} />
            <Box p={2} />
            <ActionButtons
              getSelectedRowModel={table.getSelectedRowModel}
              hasNextPage={table.getCanNextPage()}
              hasPreviousPage={table.getCanPreviousPage()}
              nextPage={table.nextPage}
              pageCount={table.getPageCount()}
              pageIndex={table.getState().pagination.pageIndex}
              pageSize={table.getState().pagination.pageSize}
              previousPage={table.previousPage}
              rerender={rerender}
              rowSelection={rowSelection}
              setPageIndex={table.setPageIndex}
              setPageSize={table.setPageSize}
              totalRows={table.getPrePaginationRowModel().rows.length}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
/*
      <Box p={2} />
      <pre>{JSON.stringify(table.getState(), null, 2)}</pre>*/
ReactTable.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  clearSelectedRows: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  selectedRows: PropTypes.array,
  onSelectedRowsChange: PropTypes.func,
  onRowClicked: PropTypes.func,
  contextActions: PropTypes.object,
  actions: PropTypes.node,
  expandableRowsComponent: PropTypes.any,
  noDataComponent: PropTypes.any,
  noDataText: PropTypes.any,
  pageSize: PropTypes.number,
  onChangePage: PropTypes.func,
  paginationTotalRows: PropTypes.number,
  filter: PropTypes.bool,
  selectableRows: PropTypes.bool,
  sortServer: PropTypes.bool,
  exportable: PropTypes.bool,
  configurable: PropTypes.bool,
  onSort: PropTypes.func,
  search: PropTypes.bool,
  defaultSortField: PropTypes.string,
  defaultSortAsc: PropTypes.bool,
};
export default ReactTable;

const TableLoader = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      sx={{ height: 100 }}
      flexDirection="column"
    >
      <Loader large />
      <Text variant="muted">Loading data</Text>
    </Flex>
  );
};
