import React from "react";
import { Flex, Box } from "rebass";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { SaveIcon } from "Components/Common/Icons";
import { useToasts } from "react-toast-notifications";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { ErrorParams } from "Components/Common/Notifications/Error";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";
import CommentMutation from "../../graphql/mutations/CommentUpdate.gql";

function CommentUpdateForm(props) {
  const { addToast } = useToasts();
  const [save] = useMutation(CommentMutation);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        comment: props.comment || "",
        id: props.id,
      }}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        comment: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        save({
          variables: {
            id: props.id,
            comment: values.comment,
          },
        }).then(
          (response) => {
            if (response.data?.task?.errors) {
              addToast(
                <Flex>{response.data?.task?.errors[0]}</Flex>,
                ErrorParams
              );
            } else {
              addToast(<Flex>Note updated successfully</Flex>, SuccessParams);
              props.onUpdate();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;

        return (
          <Flex
            width="100%"
            as="form"
            onSubmit={handleSubmit}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CustomFormGroup
              placeholder=""
              label=""
              name="comment"
              type="richtext"
              width="100%"
              value={values.comment}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.comment && errors.comment}
            />
            <Flex
              my={2}
              width="100%"
              alignItems="center"
              justifyContent="flex-end"
            >
              {props.onClose && (
                <GhostButton onClick={props.onClose}>Close</GhostButton>
              )}
              <Box mx={2} />
              <PrimaryButton leftIcon={<SaveIcon />}>Save</PrimaryButton>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default CommentUpdateForm;
