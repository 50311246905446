import React, { useState } from "react";
import "../../../../node_modules/react-vis/dist/style.css";

import {
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  HorizontalRectSeries,
  YAxis,
  XAxis,
  LineSeries,
} from "react-vis";
import { Box, Flex, Image, Text } from "rebass";
import numeral from "numeral";

const _ = require("lodash");

export default function StackedBarGraph(props) {
  const [value, setValue] = useState();

  return (
    props.series &&
    props.series.length > 0 && (
      <Flex flexDirection="column">
        <FlexibleWidthXYPlot
          height={props.height || 250}
          onMouseLeave={() => setValue(false)}
          stackBy="y"
          margin={{ bottom: 60 }}
        >
          <YAxis
            tickFormat={(v, i) =>
              v < 1000000 ? numeral(v).format("0a") : numeral(v).format("0.0a")
            }
          />

          <XAxis
            tickLabelAngle={-45}
            tickSize={0}
            position="end"
            orientation="bottom"
            tickFormat={(v, i) => {
              return (
                <tspan x="2" dy="0">
                  {props.series[0] && props.series[0].xLabels
                    ? props.series[0].xLabels[v - 1]
                    : props.series[0] && props.series[0].data[v - 1]?.name}
                </tspan>
              );
            }}
          />

          {props.series?.map((ev, i) => {
            let mydata = ev.data?.map((e, idx) => {
              return { y: e.count, x: idx + 1, name: e.name };
            });

            return (
              <VerticalBarSeries
                key={i}
                color={props.colors[i]}
                data={mydata}
              />
            );
          })}
        </FlexibleWidthXYPlot>
        {!props.noLegend && (
          <Flex ml={4} flexWrap="wrap" mt={2}>
            {props.series.map((d, i) => {
              return i < 8 ? (
                <Flex key={d.name} alignItems="center" my={1}>
                  <Flex
                    m={1}
                    sx={{ width: 5, height: 5, borderRadius: 3 }}
                    bg={props.colors[i]}
                  />
                  <Text mx={2} variant="muted" fontSize="xxs">
                    {d.name}
                  </Text>
                </Flex>
              ) : null;
            })}
          </Flex>
        )}
      </Flex>
    )
  );
}
