import React, { useState } from "react";
import { useHistory } from "react-router";
import { Flex, Box, Link, Button, Text, Image } from "rebass";
import { clearStore } from "consts";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Modal from "Components/Common/Modal";
import { PlusIcon } from "Components/Common/Icons";
import Avatar from "react-avatar";
import Table from "Components/Common/Table";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import Settings from "..";
import ProductForm from "./ProductForm";
import SearchFilterMutation from "../../../../graphql/mutations/SearchFilter";
import { storeDefaultProduct } from "../../../../auth-helper";
import ProductsQuery from "../../../../graphql/queries/Products.gql";

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => {
      return (
        <Flex
          data-tag="allowRowEvents"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            src={row?.logoUrl}
            size="30"
            name={`${row?.name}`}
            email={row?.email}
            round
            textSizeRatio={2}
          />
          <Box mx={2} data-tag="allowRowEvents">
            <Text data-tag="allowRowEvents">{row.name}</Text>
          </Box>
        </Flex>
      );
    },
  },
];
function ProductList(props) {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { loading, data, refetch, client } = useQuery(ProductsQuery, {});
  const [saveFilter] = useMutation(SearchFilterMutation);

  const saveProductFilter = (productId) => {
    saveFilter({
      variables: {
        filters: {
          productId: productId,
        },
      },
    }).then(
      (response) => {
        storeProduct(productId);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const storeProduct = (productId) => {
    clearStore(client);
    storeDefaultProduct(productId);
    //history.push("/settings/product");
    window.location.assign("/settings/product/general");
  };
  return (
    <Settings>
      <Flex flex={1} flexDirection="column" bg="white" p={4}>
        <Flex justifyContent="space-between">
          <Text variant="h2">Products</Text>
          <PrimaryButton onClick={() => setOpenModal(true)}>
            <PlusIcon style={{ marginLeft: "-3px", marginTop: "3px" }} />
          </PrimaryButton>
        </Flex>
        <Table
          title=""
          defaultSortField="name"
          selectableRows={false}
          columns={columns}
          data={data && data.products}
          loading={loading}
          onRowClicked={(row) => saveProductFilter(row.id)}
        />
      </Flex>
      <AddProductForm
        open={openModal}
        onDismiss={() => setOpenModal(false)}
        onSave={() => {
          refetch();
          setOpenModal(false);
        }}
      />
    </Settings>
  );
}

export default ProductList;

const AddProductForm = (props) => {
  return (
    <Modal open={props.open} onDismiss={props.onDismiss}>
      <Box mx={3}>
        <ProductForm product={props.product} onSave={() => props.onSave()} />
      </Box>
    </Modal>
  );
};
