import React, { useState } from "react";
import Page from "Components/Page";
import { Box, Flex, Text } from "rebass";
import ProductTopbar from "./ProductTopbar";
import Filter from "Components/Filter";
import { dataFilterOptions, MODEL_TYPES } from "consts";
import Events from "./Events";
import Select from "Components/Common/Forms/Select";

function ProductHealth(props) {
  const [trigger, setTriggerRefresh] = useState();
  const [daysAgo, setDaysAgo] = useState({ value: 30, label: "Last 30 days" });
  return (
    <Page title="Product Adoption" topBar={<ProductTopbar />}>
      <Filter
        title=""
        includeCoreSegments
        typeName={MODEL_TYPES.Product.name}
        onChange={() => setTriggerRefresh(new Date())}
        menu={
          <Flex flex={1} alignItems="center" justifyContent="space-between">
            <Text variant="h2">Events</Text>
            <Box>
              <Select
                width="180px"
                value={daysAgo}
                options={dataFilterOptions}
                onChange={(name, value) => {
                  setDaysAgo(value);
                }}
              />
            </Box>
          </Flex>
        }
      >
        <Flex flex={1} flexDirection="column">
          <Flex
            mt={2}
            flex={1}
            w="100%"
            style={{
              color: "text",
            }}
            flexDirection="column"
          >
            <Events daysAgo={daysAgo} />
          </Flex>
        </Flex>
      </Filter>
    </Page>
  );
}

export default ProductHealth;
