import React from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { SaveIcon } from "Components/Common/Icons";
import { CloseIcon, TickIcon } from "Components/Common/Icons";
import ActionButton from "Components/Common/Buttons/ActionButton";
import TagsEditor from "../../Common/Tags/TagsEditor";
import SurveyRequestMutation from "../../../graphql/mutations/SurveyResponseAcknowledge.gql";

const _ = require("lodash");

function BulkSurveyForm(props) {
  const [save] = useMutation(SurveyRequestMutation, {});

  const acknowledgeSurveyRequest = (tags) => {
    save({
      variables: {
        ids: props.data.map((d) => d.id),
        tags: tags,
      },
    }).then(
      () => {
        props.onSave && props.onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flexDirection="column" flexGrow={1} flex={1} w="100%">
      <Formik
        enableReinitialize
        initialValues={{}}
        autoComplete={false}
        onSubmit={(values, actions) => {
          acknowledgeSurveyRequest(values.tags);
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, handleBlur, handleSubmit, errors } =
            formProps;
          return (
            <Flex w="100%" flexGrow={1} flexDirection="column" mb={4}>
              <Flex w="100%" flexDirection="column">
                <Flex
                  flex={1}
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Text variant="h2">Bulk Edit</Text>
                  <Button variant="ghost" onClick={props.onDismiss}>
                    <CloseIcon />
                  </Button>
                </Flex>
                <Text variant="muted" color="brandLight" my={2} mb={3}>
                  {props.data.length} surveys selected
                </Text>
                <TagsEditor
                  parentType={"survey"}
                  label="Add Category"
                  value={values.selectedTags}
                  onChange={(t) => {
                    setFieldValue("tags", t);
                  }}
                />
                <Box my={3} />
                <ActionButton leftIcon={<TickIcon />} onClick={handleSubmit}>
                  Review All
                </ActionButton>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default BulkSurveyForm;
