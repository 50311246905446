import React, { useState, useEffect } from "react";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Flex, Button, Text, Box, Link } from "rebass";
import Dropzone from "react-dropzone";
import ImportUploadMutation from "../../../../graphql/mutations/ImportUpload.gql";
import SourcesQuery from "../../../../graphql/queries/Sources.gql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useTheme } from "emotion-theming";
import { CheckCircleIcon, ImportIcon } from "Components/Common/Icons";
import FileUploadTemplate from "Components/Data/FileUploadTemplate";
import Select from "../../../Common/Forms/Select";

function UploadFile(props) {
  const [file, setFile] = useState();
  const [error, setError] = useState();

  const [source, setSource] = useState(undefined);
  const [save, {}] = useMutation(ImportUploadMutation);

  const sourcesQuery = useQuery(SourcesQuery, {
    fetchPolicy: "network-only",
  });
  const theme = useTheme();

  const sources =
    sourcesQuery &&
    sourcesQuery.data?.sources?.map((s, i) => {
      const d = {
        value: s.value,
        label: (
          <Box key={s.value} mx={2}>
            <Text>{s.label}</Text>
            <Text variant="muted">{s.info}</Text>
          </Box>
        ),
        sourceId: s.value,
        sourceType: s.info,
      };
      if (i == 0 && !source) {
        setSource(d);
      }
      return d;
    });
  const uploadFile = () => {
    save({
      variables: {
        file: file,
        sourceId: source.sourceId,
        sourceType: source.sourceType,
      },
      context: {
        hasUpload: true,
      },
    }).then(
      (response) => {
        if (!response.data?.importUpload?.errors) {
          props.onUploadComplete(response.data?.importUpload?.import);
          props.nextStep();
        } else {
          setError(response.data?.importUpload?.errors[0]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex my={3} flexDirection="column">
      <Box>
        <Text variant="h3">Where is your data coming from?</Text>
        <Flex mb={3}>
          <Select
            placeholder="Source"
            label=""
            name="companyId"
            value={source}
            options={sources}
            onChange={(name, option) => setSource(option)}
            width="100%"
          />
        </Flex>
      </Box>
      <Flex mb={2}>
        <Flex width={1 / 2} alignItems="center" justifyContent="center">
          <Dropzone
            accept=".csv"
            onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "100%",
                  width: "100%",
                  borderStyle: "dashed",
                  borderColor: theme.colors.accent,
                  borderWidth: 1,
                  borderRadius: "medium",
                }}
                p={2}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {file ? (
                  <>
                    <Box color="secondaryLight">
                      <ImportIcon size={100} />
                    </Box>
                    <Text>{file.name}</Text>
                    <Text variant="muted" m={2}>
                      Click to change file
                    </Text>
                    {error && (
                      <Text color="red" fontWeight="bold">
                        {error}
                      </Text>
                    )}
                  </>
                ) : (
                  <FileUploadTemplate />
                )}
              </Flex>
            )}
          </Dropzone>
        </Flex>
        <Flex width={1 / 2} p={3} flexDirection="column">
          <Text variant="h3">Before importing data</Text>
          <Text variant="h3" mt={3}>
            <CheckCircleIcon /> Check data structure
          </Text>
          <Text variant="body">
            Download and use our{" "}
            <Link
              href={require("assets/files/userlot_sample_import.csv")}
              download
            >
              sample file
            </Link>{" "}
            to see exactly how we organize data in Userlot.
          </Text>
          <Text variant="h3" mt={3}>
            <CheckCircleIcon /> Custom fields
          </Text>
          <Text variant="body">
            Make sure you{" "}
            <Link as={RouterLink} to="/settings/product/fields">
              create your custom fields
            </Link>{" "}
            before importing your data.
          </Text>
          <Text variant="h3" mt={3}>
            <CheckCircleIcon /> Check mandatory fields
          </Text>
          <Text variant="body">
            Certain fields need to be entered in order for your import to be
            successful. Make sure they are complete before you start importing.
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button
          disabled={!file}
          variant={file ? "action" : "disabled"}
          onClick={() => {
            uploadFile();
          }}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

export default withRouter(UploadFile);
