import React, { useState, useEffect } from "react";
import Page from "Components/Page";
import { Text, Flex, Button, Image, Box } from "rebass";
import { useQuery } from "@apollo/client";
import { useTheme } from "emotion-theming";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
import { SatisfactionChartList, SatisfactionGauge } from "./SatisfactionGauge";
import TeamDashboardQuery from "../../../graphql/queries/TeamDashboard";
import { searchFilters } from "../../../hooks/searchFilters";
import PropTypes from "prop-types";
import { getTeam } from "hooks/getTeam";
import { getTeams } from "hooks/getTeams";
import { getTaskCategories } from "hooks/getTaskCategories";
import HomeDashboard from ".";
import { CustomIcon, HealthIcon } from "../../Common/Icons";
import WidgetWrapper from "./WidgetWrapper";
import TasksQuery from "../../../graphql/queries/TaskSummary.gql";
import TeamAccountBreakdown from "./TeamAccountBreakdown";
import TaskDasboard from "../../CommandCenter/Tasks/TaskDashboard";
import moment from "moment";
import TeamNrrBreakdown from "./TeamNrrBreakdown";
import UserBadge from "../../Common/UserBadge";
import Avatar from "react-avatar";
import numeral from "numeral";
import TaskIcon from "../../CommandCenter/Tasks/TaskDefaults";
import DonutGraphList from "../Widgets/DonutGraphList";
import Loader from "../../Common/Loader";
import HomeTopbar from "./HomeTopbar";
import TeamSelect from "../../Filter/TeamSelect";
import LineGraph from "../../Common/Graphs/LineGraph";
import _ from "lodash";
import theme from "../../../theme";

function TeamDashboard(props) {
  const theme = useTheme();
  const [team, setTeam] = useState();
  const [filterQuery, setFilter] = searchFilters();
  const { data: taskCategories } = getTaskCategories();
  const categories = taskCategories?.taskCategories;
  const filters = filterQuery.data?.filters;
  const { data: teamsData } = getTeams();

  const tasksQuery = useQuery(TasksQuery, {
    variables: {
      teamId: team?.id,
      segmentId: undefined,
      activityTypes: [],
      startDays: 0,
      endDays: filters?.daysAgo,
      includeDone: true,
      includeOverdue: true,
    },
  });
  const { data, loading, refetch } = useQuery(TeamDashboardQuery, {
    variables: {
      userId: undefined,
      teamId: team?.id,
      daysAgo: filters?.daysAgo,
    },
  });

  const teamBreakdownData = data?.teamDashboard;
  return (
    <Page title={"Team"} topBar={<HomeTopbar />} bg="gray.100">
      <Flex alignItems={"flex-end"} justifyContent="flex-end" width="100%">
        <Box>
          <TeamSelect
            onChange={(name, value) =>
              setTeam(teamsData?.teams.find((t) => t.id === value))
            }
            value={team?.id}
          />
        </Box>
      </Flex>
      <Flex>
        {loading ? (
          <Flex
            m={5}
            alignItems="center"
            justifyContent={"center"}
            flexDirection="column"
            flex={1}
          >
            <Loader />
            <Text>Loading team data</Text>
          </Flex>
        ) : !team ? (
          <Flex flexWrap="wrap" width="100%">
            {teamsData?.teams.map((t) => {
              return (
                <Flex
                  key={t.id}
                  width={1 / 3}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setTeam(t)}
                >
                  <TeamSummary
                    data={teamBreakdownData?.filter((d) => d.team?.id === t.id)}
                    team={t}
                    categories={categories}
                    loading={loading}
                  />
                </Flex>
              );
            })}
          </Flex>
        ) : (
          <Flex flex={1} flexDirection="column">
            <Flex flex={1}>
              <Flex width={1 / 3}>
                <TeamSummary
                  data={teamBreakdownData}
                  team={team}
                  categories={categories}
                  loading={loading}
                />
              </Flex>
              <Flex width={2 / 3} flexDirection="column">
                <Flex>
                  <Flex width={1 / 2}>
                    <TeamAccountBreakdown
                      data={ConvertHealthGraphData(teamBreakdownData, team)}
                      loading={loading}
                    />
                  </Flex>
                  <Flex width={1 / 2}>
                    <TeamNrrBreakdown
                      data={ConvertNRRData(teamBreakdownData, team)}
                      loading={loading}
                    />
                  </Flex>
                </Flex>
                <SatisfactionChartList
                  data={ConvertNPSData(data?.teamDashboard, team)}
                  loading={loading}
                />
              </Flex>
            </Flex>
            <WidgetWrapper title="Command Center">
              <Flex flexWrap="wrap" flex={1}>
                <TaskDasboard
                  data={
                    tasksQuery?.data
                      ? tasksQuery?.data?.tasks?.filter(
                          (t) => t.team?.id === team?.id
                        )
                      : []
                  }
                  loading={tasksQuery?.loading}
                />
              </Flex>
              <Flex flexWrap="wrap" flex={1}>
                <DonutGraphList
                  data={ConvertControlCenterData(
                    teamBreakdownData,
                    team,
                    categories
                  )}
                  width={80}
                />
              </Flex>
            </WidgetWrapper>
            <Flex flexGrow={2} />
          </Flex>
        )}
      </Flex>
    </Page>
  );
}
TeamDashboard.propTypes = {
  onChange: PropTypes.func,
};
export default TeamDashboard;

const TeamSummary = ({ data, team, categories, loading }) => {
  return (
    <WidgetWrapper
      title={<UserBadge name={team?.name} avatarUrl={team?.logoUrl} />}
    >
      <Flex flexDirection={"column"} flex={1}>
        <Flex flex={1}>
          <Flex
            flexDirection={"column"}
            bg="gray.100"
            p={3}
            flex={1}
            sx={{
              borderRadius: "medium",
            }}
          >
            <Text variant="muted">NRR</Text>
            <Text as="h2" textAlign={"center"}>
              {numeral(ConvertTeamData("nrr", data, team)?.value).format("0%")}
            </Text>
          </Flex>
          <Flex flexDirection={"column"} bg="gray.100" p={3} mx={2} flex={1}>
            <Text variant="muted"># Accounts</Text>
            <Text as="h2" textAlign={"center"}>
              {ConvertTeamData("accounts", data, team)?.count}
            </Text>
          </Flex>
          <Flex flexDirection={"column"} bg="gray.100" p={3} flex={1}>
            <Text variant="muted">MRR</Text>
            <Text as="h2" textAlign={"center"}>
              $
              {numeral(ConvertTeamData("accounts", data, team)?.value).format(
                "0,0"
              )}
            </Text>
          </Flex>
        </Flex>
        <Flex mt={3}>
          <Flex p={2} mr={3} width={1 / 2} bg="gray.100" flexDirection="column">
            <Text fontWeight="bold" fontSize="xs" mb={2}>
              Team Health
            </Text>
            <DonutGraph
              data={ConvertHealthTeamData(data, team).data}
              width={100}
              value={
                <Flex
                  bg="white"
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: 99 }}
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <HealthIcon size={30} color="brandLight" />
                </Flex>
              }
            />
          </Flex>
          <Flex width={1 / 2} bg="gray.100">
            <SatisfactionGauge
              count={ConvertTeamData("nps", data, team)?.count}
              value={ConvertTeamData("nps", data, team)?.value}
              loading={loading}
              min={-100}
              max={100}
              title={"NPS"}
              avatarUrl={team?.logoUrl}
            />
          </Flex>
        </Flex>
        <Flex mt={3} flexDirection="column">
          <Text fontWeight="bold" fontSize="xs" mb={2}>
            Tasks
          </Text>
          <LineGraph
            days={30}
            series={_.flatten(ConvertTaskTeamData(data, team))}
            height={120}
            ytitle="Count"
          />
        </Flex>
      </Flex>
    </WidgetWrapper>
  );
};

const ConvertTaskTeamData = (data, team) => {
  const theme = useTheme();
  let tasksData = data?.filter(
    (d) =>
      (d.scoreType === `tasks_opened` || d.scoreType === `tasks_completed`) &&
      d.team?.id === team?.id
  );

  let tasksOpened = tasksData
    ?.filter((d) => d.scoreType === `tasks_opened`)
    .map((d) => {
      return { date: d.createdAt, count: d.count };
    });
  let tasksCompleted = tasksData
    ?.filter((d) => d.scoreType === `tasks_completed`)
    .map((d) => {
      return { date: d.createdAt, count: d.count };
    });

  const graphData = [
    {
      name: "Opened",
      data: tasksOpened.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      ),
      color: theme.colors.brandLight,
    },
    {
      name: "Completed",
      data: tasksCompleted.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      ),
      color: theme.colors.green,
    },
  ];
  
  return graphData;
};

const ConvertControlCenterTeamData = (data, team, taskCategories) => {
  const theme = useTheme();
  let healthData = [];
  taskCategories?.map((c, i) => {
    healthData.push({
      value: _.sumBy(
        data?.filter(
          (d) =>
            d.scoreType === `tasks_${c.name.toLowerCase()}` &&
            d.team?.id === team?.id
        ),
        "count"
      ),
      label: c.name,
      icon: (
        <CustomIcon componentName={c.iconName} color={theme.colors.graph[i]} />
      ),
      color: theme.colors.graph[i],
    });
  });

  return {
    name: team?.name,
    id: team?.id,
    logoUrl: team?.logoUrl,
    data: healthData,
  };
};

const ConvertControlCenterData = (data, team, taskCategories) => {
  const theme = useTheme();
  const teamData = team?.memberships?.map((m) => {
    let u = m.user;
    let healthData = [];
    taskCategories?.map((c, i) => {
      healthData.push({
        value: _.sumBy(
          data?.filter(
            (d) =>
              d.scoreType === `tasks_${c.name.toLowerCase()}` &&
              d.user?.id === u.id
          ),
          "count"
        ),
        label: c.name,
        icon: (
          <CustomIcon
            componentName={c.iconName}
            color={theme.colors.graph[i]}
          />
        ),
        color: theme.colors.graph[i],
      });
    });

    return { name: u.name, email: u.email, id: u.id, logoUrl: u.avatarUrl, data: healthData };
  });
  //{id:, name:, logoUrl:, data: [{label:, color:, value:}]}
  return teamData;
};

const ConvertHealthGraphData = (data, team) => {
  const theme = useTheme();

  const getData = (type, user, data, color) => {
    let filteredData = data?.filter(
      (d) => d.scoreType === `health_${type}` && d.user?.id == user.id
    );
    return filteredData?.length > 0
      ? _.last(
          filteredData.map((d) => {
            return {
              count: d.count,
              value: d.value,
              name: type,
              color: color,
            };
          })
        )
      : { value: 0, count: 0, name: type, color: theme.colors.gray["200"] };
  };
  let healthData = [];
  team?.memberships.map((member) => {
    let user = member.user;
    let myData = [];
    myData.push(getData("poor", user, data, theme.colors.red));
    myData.push(getData("ok", user, data, theme.colors.yellow));
    myData.push(getData("good", user, data, theme.colors.green));

    healthData.push({
      total: _.sumBy(myData, "count"),
      totalValue: _.sumBy(myData, "value"),
      data: myData,
      label: user.name,
      name: user.name,
      avatarUrl: user.avatarUrl,
    });
  });

  console.log("healthData2");
  console.log(healthData);
  //{id:, name:, logoUrl:, data: [{label:, color:, value:}]}
  return healthData.sort((a, b) => b.totalValue - a.totalValue);
};

const ConvertNPSData = (data, team) => {
  const theme = useTheme();
  console.log(data);
  const teamData = team?.memberships?.map((m) => {
    let u = m.user;
    let myData = _.last(
      data?.filter((d) => d.scoreType === "nps" && d.user?.id == u.id)
    );
    return {
      name: u.name,
      id: u.id,
      avatarUrl: u.avatarUrl,
      value: myData?.value,
      count: myData?.count,
    };
  });

  //{id: string, name: string, logoUrl: string, data: int }
  return teamData;
};

const ConvertTeamData = (type, data, team) => {
  const theme = useTheme();
  let myData = _.last(
    data?.filter(
      (d) => d.scoreType === type && d.team?.id === team?.id && !d.user
    )
  );

  //{id: string, name: string, logoUrl: string, data: int }
  return {
    name: team?.name,
    id: team?.id,
    avatarUrl: team?.logoUrl,
    value: myData?.value,
    count: myData?.count,
  };
};

const ConvertHealthTeamData = (data, team) => {
  const theme = useTheme();
  let healthGood = _.last(
    data
      ?.filter(
        (d) =>
          d.scoreType === "health_good" && d.team?.id == team?.id && !d.user
      )
      .map((d) => {
        return {
          name: "Good",
          label: "Good",
          value: d.value,
          color: theme.colors.green,
        };
      })
  );
  let healthOk = _.last(
    data
      ?.filter(
        (d) => d.scoreType === "health_ok" && d.team?.id == team?.id && !d.user
      )
      .map((d) => {
        return {
          name: "Ok",
          label: "Ok",
          value: d.value,
          color: theme.colors.yellow,
        };
      })
  );
  let healthPoor = _.last(
    data
      ?.filter(
        (d) =>
          d.scoreType === "health_poor" && d.team?.id == team?.id && !d.user
      )
      .map((d) => {
        return {
          name: "Poor",
          label: "Poor",
          value: d.value,
          color: theme.colors.red,
        };
      })
  );

  //{id: string, name: string, logoUrl: string, data: int }
  console.log({
    name: team?.name,
    id: team?.id,
    avatarUrl: team?.logoUrl,
    data: [healthGood || [], healthOk || [], healthPoor || []],
  });
  return {
    name: team?.name,
    id: team?.id,
    avatarUrl: team?.logoUrl,
    data: [healthGood || [], healthOk || [], healthPoor || []],
  };
};

const ConvertNRRData = (data, team) => {
  const theme = useTheme();
  const teamData = team?.memberships?.map((m) => {
    let u = m.user;
    let myData = _.last(
      data?.filter((d) => d.scoreType === "nrr" && d.user?.id == u.id)
    );
    return {
      name: u.name,
      id: u.id,
      avatarUrl: u.avatarUrl,
      value: myData?.value,
      count: myData?.count,
    };
  });

  //{id: string, name: string, logoUrl: string, data: int }
  return teamData;
};
