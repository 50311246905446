import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import ProductsQuery from "../graphql/queries/Products.gql";

export function getProducts() {
  const productsQuery = useQuery(ProductsQuery, {});

  return {
    ...productsQuery,
  };
}
