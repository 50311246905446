import React, { useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { Flex, Text, Box, Button, Link } from "rebass";
import { Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useDynamicGql } from "../../../graphql/DynamicGql";
import { useDynamicSchema } from "hooks/DynamicSchema";
import DynamicForm from "../DynamicForm";
import { MODEL_TYPES } from "consts";
import Loader from "Components/Common/Loader";
import { getAllData } from "hooks/getAllData";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { getEndUserData } from "hooks/getEndUserData";
import Summary from "../Events/Summary";
import Label from "Components/Common/Forms/Label";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import TagsEditor from "../../Common/Tags/TagsEditor";
import EndUserMutation from "../../../graphql/mutations/EndUser";
import CompaniesQuery from "../../../graphql/queries/CompaniesList.gql";
import Select from "../../Common/Forms/Select";
import LocalisationForm from "../../MyAccount/Settings/Localisation";
import { UserTypeSelect } from "../../Common/UserTypeBadge";

const model = MODEL_TYPES.EndUser;
function EndUserForm(props) {
  const { id, companyId } = props;
  const { readOnly } = props;
  const history = useHistory();
  const { addToast } = useToasts();
  const [expanded, setExpanded] = useState(false);
  const { validations, initialValues } = useDynamicSchema(model);
  const companyQuery = useQuery(CompaniesQuery);
  const [save, {}] = useMutation(EndUserMutation);
  const { loading, data } = getEndUserData(id, companyId);
  const endUser = data && data.endUser;

  const companyItems =
    companyQuery.data?.companiesNameSearch.map((d) => {
      return { value: d.id, label: d.name, type: MODEL_TYPES.Company.name };
    }) || [];
  const options = companyItems.map((item) => {
    return {
      value: `${item.value}`,
      label: item.label,
      type: item.type,
      name: item.label,
      id: item.value,
    };
  });
  if (loading) {
    return <Loader />;
  }
  let formValues = endUser || initialValues;
  if (props.companyId) {
    formValues = { ...formValues, companyId: props.companyId };
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      autoComplete={false}
      validationSchema={validations}
      onSubmit={(values, actions) => {
        save({
          variables: {
            id: values.id,
            fields: values.fields,
            firstName: values.firstName,
            lastName: values.lastName,
            tagList: values.tagList,
            email: values.email,
            externalId: values.externalId,
            companyId: values.companyId || props.companyId,
            timezone: values.timezone,
            locale: values.locale,
            endUserCategoryId: values.endUserCategoryId,
          },
        }).then(
          (response) => {
            const result = response.data.endUser;
            if (result?.errors) {
              actions.setErrors([result?.errors]);
              addToast(<Flex>{result?.errors[0]}</Flex>, ErrorParams);
            } else {
              props.onSave && props.onSave(result.endUser.id);
              addToast(<Flex>Saved!</Flex>, SuccessParams);
              if (props.modalAdd) {
                let ids = result.endUser.id.split(":");
                const route = result.endUser.company
                  ? `/contacts/accounts/${result.endUser.company?.id}/users/${ids[0]}`
                  : `/contacts/users/${ids[0]}`;
                history.push(route);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }}
    >
      {(formProps) => {
        const { values, setFieldValue, handleSubmit, errors } = formProps;

        return (
          <Box width="100%">
            {!props.readOnly && (
              <Flex justifyContent="flex-end">
                <PrimaryButton
                  onClick={handleSubmit}
                  disabled={!id && !values?.companyId}
                >
                  Save
                </PrimaryButton>
              </Flex>
            )}

            {!id && !props.companyId && (
              <Box mb={2}>
                <Select
                  placeholder="Account"
                  label="Account"
                  name="companyId"
                  value={options?.find(
                    (o) => o.value === `${values?.companyId}`
                  )}
                  options={options}
                  onChange={(name, option) =>
                    setFieldValue(name, option?.value)
                  }
                  width="100%"
                />
                {!values.companyId && (
                  <Text variant="muted" color="red">
                    Required
                  </Text>
                )}
              </Box>
            )}
            <DynamicForm
              readOnly={readOnly}
              expanded={expanded}
              detailView={!props.summary}
              typeName={model.name}
              {...formProps}
            />

            {props.summary ? (
              <>
                <Flex flexDirection="column" mt={3}>
                  <Summary id={id} parentType={model.name} />
                </Flex>
                <Box mt={3} />
                <TagsEditor
                  parentType="EndUser"
                  label="Tags"
                  value={values.tagList}
                  onChange={(tags) => {
                    setFieldValue("tagList", tags);
                    handleSubmit();
                  }}
                />
              </>
            ) : (
              <Box>
                <UserTypeSelect {...formProps} />
                <LocalisationForm {...formProps} />
              </Box>
            )}
          </Box>
        );
      }}
    </Formik>
  );
}
EndUserForm.propTypes = {
  id: PropTypes.string,
  companyId: PropTypes.string,
  readOnly: PropTypes.bool,
  onSave: PropTypes.func,
  summary: PropTypes.bool,
};
export default withRouter(EndUserForm);
