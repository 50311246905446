import React, { useEffect, useState } from "react";
import { Flex, Text, Box } from "rebass";
import { useMutation, useQuery } from "@apollo/react-hooks";
import CommandCenter from "Components/Dashboard/CommandCenter";
import InboxFilter, { mailOptionTypes } from "./InboxFilter";
import InboxList from "./InboxList";
import Filter from "Components/Filter";
import { MODEL_TYPES } from "consts";
import MailMessageQuery from "../../../graphql/queries/MailMessage.gql";
import moment from "moment";
import { getSearchFilter } from "../../../hooks/getSearchFilter";
import DatePicker, { defaultDate, getLabel } from "../DatePicker";

function Inbox(props) {
  const { data: searchFilters } = getSearchFilter();
  const [forceRefresh, setForceRefresh] = useState();
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(undefined);
  const [defaultCalendarView, setDefaultCalendarView] = useState("week");
  const [mailType, setMailType] = useState({
    type: "date",
    startDate: defaultDate?.startDate,
    endDate: defaultDate?.endDate,
  });

  const startOffset = moment(mailType.startDate).diff(
    moment({ hours: 0 }),
    "days"
  );
  const endOffset = moment(mailType.endDate).diff(moment(), "days");

  const [mailOptions, setMailOptions] = useState(
    mailOptionTypes.map((o) => o.value)
  );

  const mailMessageQuery = useQuery(MailMessageQuery, {
    variables: {
      mailOptions,
      ownerUserId: searchFilters?.filters?.ownerUserId,
      teamId: searchFilters?.filters?.teamId,
      segmentId: searchFilters?.filters?.segmentId,
      startDays: startOffset || -30,
      endDays: endOffset || 0,
    },
  });
  const { data: mailMessages, loading } = mailMessageQuery;

  const getCalendarView = (startDate, endDate) => {
    const label = getLabel(startDate, endDate);
    let view = "week";
    switch (label) {
      case "Today":
      case "Yesterday":
      case "Tomorrow":
        view = "day";
        break;
      case "Last Week":
      case "This Week":
        view = "week";
        break;
      case "Last Month":
      case "This Month":
        view = "month";
        break;
      default:
        view = "week";
        break;
    }
    setDefaultCalendarView(view);
  };

  useEffect(() => {
    mailMessageQuery.refetch({
      mailOptions,
      startDays: startOffset || 0,
      endDays: endOffset || 0,
      ownerUserId: searchFilters?.filters?.ownerUserId,
      segmentId: searchFilters?.filters?.segmentId,
    });
  }, [open, forceRefresh]);

  return (
    <CommandCenter>
      <Filter
        inlineSegments
        menu={<Text variant="h2">Inbox</Text>}
        onChange={() => {
          setForceRefresh(new Date());
        }}
        typeName={MODEL_TYPES.Company.name}
      >
        <Flex width="100%" flexDirection="column">
          <Flex flex={1} alignItems="center" justifyContent="space-between">
            <Flex flex={1} flexWrap="flex" justifyContent="flex-end">
              <Box mx={1} />
              <DatePicker
                dates={mailType}
                onChange={(type, startDate, endDate) => {
                  getCalendarView(startDate, endDate);
                  setMailType({
                    type,
                    startDate: startDate || mailType.startDate,
                    endDate: endDate || mailType.endDate,
                  });
                }}
              />
            </Flex>
          </Flex>
          <InboxList
            data={mailMessages?.mailMessage}
            loading={loading}
            detailedView
            onSelected={(event) => {
              setEvent(event);
              setOpen(true);
            }}
          />
        </Flex>
      </Filter>
    </CommandCenter>
  );
}

export default Inbox;
