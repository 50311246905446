import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Highlight from "react-highlight.js";
import { Box, Text } from "rebass";

import "highlight.js/styles/docco.css";
function CodePreview(props) {
  const [show, setShow] = useState(false);
  return (
    <Box
      sx={{ position: "relative" }}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      <Highlight language="JavaScript">{props.children}</Highlight>
    </Box>
  );
}

export default withRouter(CodePreview);
