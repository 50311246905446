const DevelopmentOnly = (props) => {
  const developmentMode = process.env.NODE_ENV === "development";
  return developmentMode ? props.children : null;
};
export default DevelopmentOnly;

export const NotDevelopment = (props) => {
  const developmentMode = process.env.NODE_ENV !== "development";
  return developmentMode ? props.children : null;
};
