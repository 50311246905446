import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import { useTheme } from "emotion-theming";
import WidgetWrapper from "./WidgetWrapper";
import CustomBarGraph from "./CustomBarGraph";
import _ from "lodash";

function TeamAccountBreakdown({ data, loading }) {
  const theme = useTheme();
  return data ? (
    <WidgetWrapper title={"Accounts (MRR)"}>
      <Flex flexWrap="wrap">
        <CustomBarGraph
          data={data}
          height={300}
          loading={loading}
          total={_.sumBy(data, "total")}
        />
      </Flex>
    </WidgetWrapper>
  ) : null;
}

export default TeamAccountBreakdown;
