import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Flex, Text, Box, Link, Image, Button } from "rebass";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import moment from "moment";
import { CheckCircleIcon } from "Components/Common/Icons";
import BulkTaskForm from "./BulkTaskForm";
import { storeDefaultProduct } from "../../../auth-helper";
import {
  CriticalIcon,
  TasksIcon,
  TimeIcon,
  UserIcon,
} from "../../Common/Icons";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import TaskCompleteMutation from "../../../graphql/mutations/TaskComplete.gql";
import TaskTable from "./TaskTable";
import { useUrlParams } from "../../../hooks/useUrlParams";
function Tasks({ detailedView, loading, onSelected, onSave, data }) {
  const history = useHistory();
  const [saveFilter] = useMutation(SearchFilterMutation);
  const [selectedRows, setSelectedRows] = useState();
  const [showBulkActions, setShowBulkActions] = useState(false);

  const tabs = ["All", "Priority", "Overdue", "Completed"];

  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const defaultTab = getUrlParameter("tab") || "All";

  const [selectedTab, setSelectedTab] = useState(
    tabs.indexOf(tabs.find((t) => t.toLowerCase() === defaultTab.toLowerCase()))
  );

  const [complete, { loading: saveLoading }] =
    useMutation(TaskCompleteMutation);
  const completeTask = (ids) => {
    complete({
      variables: {
        ids,
      },
    }).then(
      (response) => {
        onSave && onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const saveProductFilter = (productId, route) => {
    storeDefaultProduct(productId);
    saveFilter({
      variables: {
        filters: {
          productId,
        },
      },
    }).then(
      (response) => {
        history.push(route);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flex={1} w="100%" flexDirection="column">
      <Flex mt={1} flex={1} alignItems="flex-start">
        {detailedView ? (
          <Tabs
            width="100%"
            direction="rtl"
            selectedIndex={selectedTab}
            onSelect={setSelectedTab}
          >
            <TabList>
              <Tab key="All">
                <Flex alignItems="center" sx={{ minWidth: 100 }}>
                  <TasksIcon />
                  <Text fontSize="xs" color="text" mx={2}>
                    Open
                  </Text>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    bg="brandHighlight"
                    px={2}
                    py={1}
                    sx={{ borderRadius: "medium" }}
                  >
                    {data?.filter((t) => !t.completedAt).length}
                  </Flex>
                </Flex>
              </Tab>
              <Tab key="Priority">
                <Flex alignItems="center" sx={{ minWidth: 100 }}>
                  <CriticalIcon />
                  <Text fontSize="xs" color="text" mx={2}>
                    Critical
                  </Text>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    bg="redLight"
                    px={2}
                    py={1}
                    sx={{ borderRadius: "medium" }}
                  >
                    <Text color="red">
                      {
                        data
                          ?.filter((t) => !t.completedAt)
                          .filter((t) => t.priority >= 4).length
                      }
                    </Text>
                  </Flex>
                </Flex>
              </Tab>
              <Tab key="Overdue">
                <Flex alignItems="center" sx={{ minWidth: 100 }}>
                  <TimeIcon />
                  <Text fontSize="xs" color="text" mx={2}>
                    Overdue
                  </Text>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    bg="redLight"
                    px={2}
                    py={1}
                    sx={{ borderRadius: "medium" }}
                  >
                    <Text color="red">
                      {
                        data
                          ?.filter((t) => !t.completedAt)
                          .filter((t) => moment(t.dueAt).isBefore(moment()))
                          .length
                      }
                    </Text>
                  </Flex>
                </Flex>
              </Tab>
              <Tab key="Overdue">
                <Flex alignItems="center" sx={{ minWidth: 100 }}>
                  <UserIcon />
                  <Text fontSize="xs" color="text" mx={2}>
                    Unassigned
                  </Text>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    bg="redLight"
                    px={2}
                    py={1}
                    sx={{ borderRadius: "medium" }}
                  >
                    <Text color="red">
                      {data?.filter((t) => !t.user).length}
                    </Text>
                  </Flex>
                </Flex>
              </Tab>
              <Tab key="Completed">
                <Flex alignItems="center" sx={{ minWidth: 100 }}>
                  <CheckCircleIcon />
                  <Text fontSize="xs" color="text" mx={2}>
                    Completed
                  </Text>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    bg="brandHighlight"
                    px={2}
                    py={1}
                    sx={{ borderRadius: "medium" }}
                  >
                    <Text color="brandLight">
                      {data?.filter((t) => t.completedAt).length}
                    </Text>
                  </Flex>
                </Flex>
              </Tab>
            </TabList>
            <TabPanel key="All">
              <TaskTable
                detailedView
                data={data.filter((t) => !t.completedAt)}
                loading={loading}
                selectedRows={selectedRows}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                  setShowBulkActions(rows.length > 0);
                }}
                onSelected={(p) => {
                  onSelected(p);
                }}
                onSave={() => {
                  onSave && onSave();
                }}
                saveProductFilter={saveProductFilter}
              />
            </TabPanel>
            <TabPanel key="Priority">
              <TaskTable
                detailedView
                data={data
                  ?.filter((t) => !t.completedAt)
                  .filter((t) => t.priority >= 4)}
                loading={loading}
                selectedRows={selectedRows}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                  setShowBulkActions(rows.length > 0);
                }}
                onSelected={(p) => {
                  onSelected(p);
                }}
                onSave={() => {
                  onSave && onSave();
                }}
                saveProductFilter={saveProductFilter}
              />
            </TabPanel>
            <TabPanel key="Overdue">
              <TaskTable
                detailedView
                data={data
                  ?.filter((t) => !t.completedAt)
                  .filter((t) => moment(t.dueAt).isBefore(moment()))}
                loading={loading}
                selectedRows={selectedRows}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                  setShowBulkActions(rows.length > 0);
                }}
                onSelected={(p) => {
                  onSelected(p);
                }}
                onSave={() => {
                  onSave && onSave();
                }}
                saveProductFilter={saveProductFilter}
              />
            </TabPanel>
            <TabPanel key="Unassigned">
              <TaskTable
                detailedView
                data={data?.filter((t) => !t.user)}
                loading={loading}
                selectedRows={selectedRows}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                  setShowBulkActions(rows.length > 0);
                }}
                onSelected={(p) => {
                  onSelected(p);
                }}
                onSave={() => {
                  onSave && onSave();
                }}
                saveProductFilter={saveProductFilter}
              />
            </TabPanel>
            <TabPanel key="Completed">
              <TaskTable
                detailedView
                data={data.filter((t) => t.completedAt)}
                loading={loading}
                selectedRows={selectedRows}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                  setShowBulkActions(rows.length > 0);
                }}
                onSelected={(p) => {
                  onSelected(p);
                }}
                onSave={() => {
                  onSave && onSave();
                }}
                saveProductFilter={saveProductFilter}
              />
            </TabPanel>
          </Tabs>
        ) : (
          <TaskTable
            detailedView={false}
            data={data}
            loading={loading}
            selectedRows={selectedRows}
            onSelectedRowsChange={(rows) => {
              setSelectedRows(rows);
              setShowBulkActions(rows.length > 0);
            }}
            onSelected={(p) => {
              onSelected(p);
            }}
            onSave={(p) => {
              onSave && onSave();
            }}
          />
        )}
      </Flex>

      {showBulkActions && (
        <Flex
          bg="white"
          flexGrow={1}
          width={450}
          p={4}
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            top: 0,
            zIndex: 999,
            boxShadow: "large",
          }}
        >
          <BulkTaskForm
            data={selectedRows}
            onDismiss={() => setShowBulkActions(false)}
            onSave={() => {
              onSave();
              setShowBulkActions(false);
              setSelectedRows([]);
            }}
            onComplete={() => {
              onSave();
              completeTask(selectedRows.map((r) => r.id));
              setShowBulkActions(false);
              setSelectedRows([]);
            }}
          />
        </Flex>
      )}
    </Flex>
  );
}

Tasks.propTypes = {
  detailedView: PropTypes.bool,
  loading: PropTypes.bool,
  view: PropTypes.oneOf(["summary"]),
  onSelected: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

Tasks.defaultProps = {
  detailedView: true,
  loading: false,
  view: "summary",
  data: [],
};
export default withRouter(Tasks);
