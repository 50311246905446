import React, { useState } from "react";
import { Button, Flex, Box, Text, Link, Image } from "rebass";
import NotificationQuery from "../../graphql/queries/Notifications.gql";
import NotificationMutation from "../../graphql/mutations/Notification.gql";
import NotificationAllRead from "../../graphql/mutations/NotificationAllRead.gql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { CloseIcon } from "Components/Common/Icons";
import { EventIcon } from "../Common/Icons";
import { useHistory } from "react-router-dom";
import RadioGroupButton from "../Common/RadioGroupButton";
import SecondaryButton from "../Common/Buttons/SecondaryButton";

function Notifications(props) {
  const history = useHistory();
  const [showRead, setShowRead] = useState(false);
  const { data, error, loading, refetch } = useQuery(NotificationQuery, {});
  const [save, {}] = useMutation(NotificationMutation, {
    refetchQueries: [{ query: NotificationQuery }],
  });
  const [markAllRead, {}] = useMutation(NotificationAllRead, {
    refetchQueries: [{ query: NotificationQuery }],
  });
  const notifications = data?.notifications || [];

  return (
    <Flex flex={1} flexDirection="column" p={3}>
      <Text variant="h2">Notifications </Text>
      <Flex justifyContent="center">
        <Flex flex={1} sx={{ maxWidth: "700px" }} flexDirection="column">
          <Flex mb={3}>
            <Button
              mx={1}
              onClick={() => setShowRead(false)}
              variant={!showRead ? "primary" : "secondary"}
              sx={{ borderRadius: 99 }}
            >
              Unread
            </Button>
            <Button
              sx={{ borderRadius: 99 }}
              mx={1}
              onClick={() => setShowRead(true)}
              variant={showRead ? "primary" : "secondary"}
            >
              All
            </Button>
            <Flex flex={1} justifyContent="flex-end">
              <SecondaryButton onClick={() => markAllRead()}>
                Mark all read
              </SecondaryButton>
            </Flex>
          </Flex>
          {notifications.length == 0 ? (
            <Flex
              p={3}
              mt={3}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
            >
              <Image
                src={require("assets/images/empty_document.png")}
                sx={{ width: "120px", objectFit: "contain" }}
              ></Image>
              <Text variant="muted" p={3}>
                You have no notifications yet.
              </Text>
            </Flex>
          ) : (
            !showRead &&
            notifications.filter((n) => !n.readAt).length == 0 && (
              <Flex
                p={3}
                mt={3}
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Image
                  src={require("assets/images/empty_document.png")}
                  sx={{ width: "80px", objectFit: "contain" }}
                ></Image>
                <Text variant="muted" p={3}>
                  You have no unread notifications.
                </Text>
              </Flex>
            )
          )}
          {notifications.map((notification) => (
            <Box
              key={notification.id}
              p={3}
              width="100%"
              sx={{
                transition: "max-height 0.5s linear",
                maxHeight: notification.readAt && !showRead ? "0px" : "250px",
                opacity: notification.readAt && !showRead ? 0 : 1,
                backgroundColor: notification.readAt && "gray.100",
                borderBottomColor: "gray.200",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "brandHighlight",
                },
              }}
            >
              <Flex flex={1} sx={{ position: "relative" }}>
                <Flex alignItems={"center"} justifyContent="center" mr={3}>
                  <Box
                    sx={{ borderRadius: 99, width: 10, height: 10 }}
                    bg={notification.readAt ? "gray.500" : "warning"}
                  ></Box>
                </Flex>
                <Flex
                  flex={1}
                  flexDirection="column"
                  onClick={() => history.push(notification.link)}
                >
                  <Flex flex={1} alignItems="center">
                    <Text fontWeight={"bold"} fontSize="sm">
                      {notification.title}
                    </Text>
                    {notification.companyId && (
                      <Flex mx={1} alignItems="center">
                        (
                        <Link
                          onClick={() => {
                            save({
                              variables: {
                                id: notification.id,
                              },
                            }).then(
                              (response) => {
                                refetch();
                              },
                              (error) => {
                                console.log(error);
                              }
                            );
                          }}
                          fontSize="sm"
                        >
                          {notification.companyName}
                        </Link>
                        )
                      </Flex>
                    )}
                  </Flex>
                  <Text
                    fontSize="sm"
                    my={2}
                    dangerouslySetInnerHTML={{
                      __html: notification.description,
                    }}
                  ></Text>
                  <Flex flex={1} mt={2}>
                    <Text variant="muted">
                      {moment(notification.createdAt).fromNow()}
                    </Text>
                    <Box mx={3} />
                    {notification.triggerName && (
                      <Flex
                        flex={1}
                        alignItems={"center"}
                        justifyContent="flex-end"
                      >
                        <Text color="brandLight">
                          <EventIcon size={12} />
                        </Text>
                        <Text variant="muted" mx={1}>
                          Generated by
                        </Text>
                        <Text variant="muted">
                          <Link
                            href={`settings/product/automations/${notification.triggerId}`}
                          >
                            {notification.triggerName}
                          </Link>
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                {!notification.readAt && (
                  <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        save({
                          variables: {
                            id: notification.id,
                          },
                        }).then(
                          (response) => {
                            refetch();
                          },
                          (error) => {
                            console.log(error);
                          }
                        );
                      }}
                    >
                      <Box sx={{ marginLeft: -1 }}>
                        <CloseIcon />
                      </Box>
                    </Button>
                  </Box>
                )}
              </Flex>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Notifications;
