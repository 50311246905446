import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import EntityScoreDetailQuery from "../graphql/queries/EntityScoreDetail.gql";

export function getEntityScoreDetail(id, parentType, scoreType, days) {
  const query = useQuery(EntityScoreDetailQuery, {
    variables: {
      id: id,
      parentType: parentType,
      scoreType: scoreType,
      days: days,
    },
  });

  return {
    ...query,
  };
}
