import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import { DateRangePicker as DateRange } from "react-date-range";
import { useState } from "react";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from "date-fns";
import {
  ArrowDropDownIcon,
  CalendarIcon,
  CloseIcon,
} from "Components/Common/Icons";
import { useTheme } from "emotion-theming";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import PrimaryButton from "../Common/Buttons/PrimaryButton";
import GhostButton from "../Common/Buttons/GhostButton";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

export const defaultStaticRanges = [
  {
    label: "Today",
    range: {
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    },
  },
  {
    label: "Yesterday",
    range: {
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    },
  },

  {
    label: "This Week",
    range: {
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    },
  },
  {
    label: "Last Week",
    range: {
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    },
  },
  {
    label: "This Month",
    range: {
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    },
  },
  {
    label: "Last Month",
    range: {
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    },
  },
];

export const defaultDate = defaultStaticRanges.find(
  (x) => x.label === "This Week"
).range;
export const getLabel = (startDate, endDate) =>
  defaultStaticRanges.find(
    (d) =>
      isSameDay(d.range.startDate, startDate) &&
      isSameDay(d.range.endDate, endDate)
  )?.label;

function DatePicker(props) {
  const theme = useTheme();

  const [open, setOpen] = useState();

  const selectedRange = defaultStaticRanges.find(
    (d) =>
      isSameDay(d.range.startDate, props.dates?.startDate) &&
      isSameDay(d.range.endDate, props.dates?.endDate)
  );

  const defaultLabel = selectedRange ? selectedRange.label : "Custom";

  const [dateLabel, setDateLabel] = useState(defaultLabel);
  const [selectionRange, setSelectedDate] = useState({
    startDate: props.dates?.startDate,
    endDate: props.dates?.endDate,
    key: "selection",
  });
  const handleChange = (ranges) => {
    setSelectedDate(ranges.selection);
    const selectedRange = defaultStaticRanges.find(
      (d) =>
        isSameDay(d.range.startDate, ranges.selection.startDate) &&
        isSameDay(d.range.endDate, ranges.selection.endDate)
    );

    selectedRange ? setDateLabel(selectedRange.label) : setDateLabel("Custom");
  };
  const handleSelect = () => {
    props.onChange &&
      props.onChange("date", selectionRange.startDate, selectionRange.endDate);

    setOpen(false);
  };

  return (
    <Flex alignItems="flex-end">
      <Box>
        <Flex alignItems="center">
          <SecondaryButton
            onClick={({ target }) => {
              setOpen(!open);
              props.onChange(
                target.value,
                selectedRange?.startDate,
                selectedRange?.endDate
              );
            }}
            leftIcon={<CalendarIcon />}
            rightIcon={<ArrowDropDownIcon />}
          >
            {dateLabel}
          </SecondaryButton>
        </Flex>
      </Box>
      {open && (
        <Flex sx={{ position: "relative" }}>
          <Flex
            p={2}
            bg={theme.colors.gray[200]}
            m={2}
            sx={{ position: "absolute", left: -600, top: -10, zIndex: 99 }}
            flexDirection="column"
          >
            <DateRange ranges={[selectionRange]} onChange={handleChange} />
            <Flex justifyContent="flex-end" mt={2}>
              <GhostButton onClick={() => setOpen(false)}>Cancel</GhostButton>
              <PrimaryButton onClick={handleSelect}>Change</PrimaryButton>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
export default DatePicker;
