import { useQuery } from "@apollo/react-hooks";
import { MODEL_TYPES } from "../consts";
import { useDynamicGql } from "../graphql/DynamicGql";

export function getEndUserData(id, companyId) {
  const { getDataById } = useDynamicGql(MODEL_TYPES.EndUser, false);
  const dataQuery = useQuery(getDataById, {
    variables: { id: id, companyId: companyId },
    skip: !id || id === "new",
  });
  return {
    ...dataQuery,
  };
}
