import React, { Component } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTheme } from "emotion-theming";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function DndContext(props) {
  const theme = useTheme();

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let items = { ...props.items };
    items[result.destination.droppableId] = result.draggableId;
    if (props.defaultPrimaryKeys.includes(result.draggableId)) {
      items.primaryKeys.push(result.destination.droppableId);
    }

    props.onChange(items);
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <DragDropContext onDragEnd={onDragEnd}>{props.children}</DragDropContext>
  );
}

export default DndContext;
