import { flexRender } from "@tanstack/react-table";
import { useTheme } from "emotion-theming";
import React from "react";
import {
  RiArrowDropDownFill,
  RiArrowDropUpFill,
  RiArrowUpDownFill,
} from "react-icons/ri";
import { Box, Flex } from "rebass";
import Filter from "./Filter";
import TablePins from "./TablePins";

function getTableHeaderGroups(table, tg) {
  if (tg === "left") {
    return [table.getLeftHeaderGroups(), table.getLeftFooterGroups()];
  }

  if (tg === "right") {
    return [table.getRightHeaderGroups(), table.getRightFooterGroups()];
  }

  if (tg === "center") {
    return [table.getCenterHeaderGroups(), table.getCenterFooterGroups()];
  }

  return [table.getHeaderGroups(), table.getFooterGroups()];
}

function getRowGroup(row, tg) {
  if (tg === "left") return row.getLeftVisibleCells();
  if (tg === "right") return row.getRightVisibleCells();
  if (tg === "center") return row.getCenterVisibleCells();
  return row.getVisibleCells();
}

export function CustomTable({ table, tableGroup, onRowClicked }) {
  const theme = useTheme();
  const [headerGroups, footerGroup] = getTableHeaderGroups(table, tableGroup);

  return (
    <Box
      as="table"
      width="100%"
      cellPadding="0"
      cellSpacing="0"
      sx={{ display: "block" }}
    >
      {headerGroups.map((headerGroup) => (
        <Flex as="tr" key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <Flex
              as="th"
              className="relative"
              key={header.id}
              style={{
                width: header.getSize(),
              }}
              colSpan={header.colSpan}
            >
              {header.isPlaceholder ? null : (
                <>
                  <Flex
                    onClick={header.column.getToggleSortingHandler()}
                    fontSize="xs"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}{" "}
                    {{
                      asc: (
                        <Flex>
                          <RiArrowDropUpFill />
                        </Flex>
                      ),
                      desc: (
                        <Flex>
                          <RiArrowDropDownFill />
                        </Flex>
                      ),
                    }[header.column.getIsSorted()] ?? <Flex></Flex>}
                  </Flex>
                  {header.column.getCanFilter() ? (
                    <div>
                      <Filter column={header.column} table={table} />
                    </div>
                  ) : null}
                </>
              )}
              <div
                className="absolute right-0 top-0 h-full w-1 bg-blue-300 select-none touch-none hover:bg-blue-500 cursor-col-resize"
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
              />
              {!header.isPlaceholder && header.column.getCanPin() && (
                <TablePins
                  isPinned={header.column.getIsPinned()}
                  pin={header.column.pin}
                />
              )}
            </Flex>
          ))}
        </Flex>
      ))}
      {table.getRowModel().rows.map((row) => (
        <Flex
          as="tr"
          key={row.id}
          style={{
            cursor: "pointer",
            "&:hover": {
              background: theme.colors.brandHighlight,
            },
          }}
        >
          {getRowGroup(row, tableGroup).map((cell) => (
            <Flex
              as="td"
              key={cell.id}
              onClick={() => {
                console.log(cell.column);
                !cell.column.columnDef.action && onRowClicked(row.original);
              }}
              style={{
                backgroundColor:
                  row.getIsSelected() && theme.colors.brandHighlight,
                width: cell.column.getSize(),
                "&:first-of-type": {
                  borderRadius: 99,
                },
                "&:last-child": {
                  borderRadius: 99,
                },
                borderBottomColor: theme.colors.gray[200],
                borderBottomWidth: "thin",
                borderBottomStyle: "solid",
              }}
            >
              <Flex py={2} fontSize="xs" alignItems={"center"}>
                {row.depth > 0 && <Flex mx={2} />}
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Flex>
            </Flex>
          ))}
        </Flex>
      ))}
    </Box>
  );
}

export default CustomTable;
