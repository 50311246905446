import { memo, useEffect } from "react";
import PropTypes from "prop-types";

const WithOnValidationChange = memo(({ values, errors, onError, onValid }) => {
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      onError({ values, errors });
    } else {
      onValid();
    }
  }, [Object.keys(errors).length]);

  return null;
});

WithOnValidationChange.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

WithOnValidationChange.defaultProps = {
  errors: {},
};

export default WithOnValidationChange;
