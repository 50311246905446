import React, { useState } from "react";
import { Text, Flex, Box, Image, Button } from "rebass";
import Loader from "Components/Common/Loader";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useTheme } from "emotion-theming";
import { getData } from "hooks/getData";
import ProgressBar from "@ramonak/react-progress-bar";
import { MODEL_TYPES, TRIGGER_CONFIG } from "consts";
import { CheckCircleOffIcon, WorkflowTypeIcon } from "Components/Common/Icons";
import { RiCheckLine, RiPauseLine, RiPlayLine } from "react-icons/ri";
import Label from "../../Common/Forms/Label";
import EntityWorkflow from "../../../graphql/mutations/EntityWorkflow.gql";
import CompanyMenuModal from "../../Dashboard/CompanyHealth/CompanyMenuModal";
import PropTypes from "prop-types";
import GhostButton from "../../Common/Buttons/GhostButton";
import { ArrowDropDownIcon } from "../../Common/Icons";

const _ = require("lodash");

const actionOptions = TRIGGER_CONFIG.Actions?.map((r) => {
  return {
    value: r.id,
    label: (
      <Flex alignItems="center">
        <Text color="brandLight" mt={1}>
          {r.icon}
        </Text>
        <Box mx={1} />
        <Text>{r.name}</Text>
      </Flex>
    ),
  };
});
function AutomationDashboard(props) {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const {
    data: entityData,
    loading,
    refetch,
  } = getData(MODEL_TYPES[props.parentType], props.id);
  const [save] = useMutation(EntityWorkflow);

  const workflows =
    entityData &&
    entityData[MODEL_TYPES[props.parentType].singular].entityWorkflows?.sort(
      (a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    );

  return (
    <Flex flexGow={1} flex={1}>
      <Flex flex={1} flexGrow={1} bg="white" flexDirection="column">
        <Flex alignItems={"center"} justifyContent="space-between" mb={3}>
          <Text variant="h2">Automation</Text>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Enrol
          </Button>
        </Flex>
        {loading && <Loader />}
        {!loading && workflows?.length > 0 ? (
          workflows.map((workflow, wi) => (
            <AutomationDetail key={workflow.id} workflow={workflow} />
          ))
        ) : (
          <Flex
            p={4}
            flexDirection="column"
            bg="gray.100"
            alignItems="center"
            justifyContent="center"
            sx={{ borderRadius: "medium" }}
          >
            <Flex
              sx={{
                height: "450px",
                boxShadow: "large",
                borderRadius: "medium",
              }}
            >
              <Image
                src={require("assets/images/AutomationEmptyState.png")}
                sx={{ height: "400px", objectFit: "contain" }}
              />
            </Flex>
            <Box my={3} />
            <Text variant="muted">
              No active playbooks, workflows or campaigns assigned.
            </Text>
          </Flex>
        )}
      </Flex>
      <CompanyMenuModal
        selectedRows={[
          {
            id: props.id,
            name: entityData?.company?.name || entityData?.endUser?.name,
          },
        ]}
        parentType={props.parentType}
        open={openModal}
        formType="Playbook"
        moduleType={entityData?.company?.details?.status?.name}
        onSave={() => {
          setOpenModal(false);
          refetch({
            variables: { id: props.id, parentType: props.parentType },
          });
        }}
        onDismiss={() => {
          setOpenModal(false);
        }}
      />
    </Flex>
  );
}
AutomationDashboard.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
};

const AutomationDetail = ({ workflow, idx }) => {
  const theme = useTheme();
  const [show, setShow] = useState(!workflow.completedAt);
  let lastCompletedStepIdx = undefined;

  return (
    <Flex
      key={workflow.id}
      variant="box"
      width="100%"
      alignItems="center"
      p={3}
      m={2}
      flexDirection="column"
    >
      <Flex width="100%" alignItems="center">
        <Flex width={1 / 3} alignItems="center" mx={3}>
          <WorkflowTypeIcon type={workflow.workflowType} />
          <Box mx={2}>
            <Flex alignItems="center">
              <Text fontWeight="bold">{workflow.name}</Text>
              {!workflow.active && !workflow.completedAt && (
                <Text
                  bg="gray.100"
                  sx={{
                    borderColor: "accent",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderRadius: "medium",
                  }}
                  p={1}
                  mx={2}
                  fontSize="xs"
                >
                  Paused
                </Text>
              )}
            </Flex>
          </Box>
        </Flex>

        <Flex width={2 / 3} justifyContent="flex-end" alignItems={"center"}>
          <Flex justifyContent="flex-end">
            <Flex flex={1} mt={1}>
              <ProgressBar
                completed={
                  (workflow.completedSteps / workflow.totalSteps) * 100
                }
                height={10}
                bgColor={theme.colors.brandLight}
                baseBgColor={theme.colors.gray[200]}
                labelColor={theme.colors.brandLight}
                labelSize={0}
                width={"100px"}
              />
            </Flex>
            <Text variant="muted" mx={2} fontSize="xxs">
              {workflow.completedSteps} / {workflow.totalSteps}
            </Text>
          </Flex>
          <Flex width={250} justifyContent="flex-end" alignItems={"center"}>
            {!workflow.completedAt ? (
              <Flex alignItems="flex-start" sx={{ marginLeft: 100 }}>
                <GhostButton
                  leftIcon={!workflow.active ? <RiPlayLine /> : <RiPauseLine />}
                  onClick={() => {
                    save({
                      variables: {
                        id: workflow.id,
                        active: !workflow.active,
                      },
                    }).then(
                      (response) => {
                        refetch({
                          variables: {
                            id: props.id,
                            parentType: props.parentType,
                          },
                        });
                      },
                      (error) => {}
                    );
                  }}
                >
                  {!workflow.active ? "Play" : "Pause"}
                </GhostButton>
              </Flex>
            ) : workflow.completedAt &&
              workflow.completedSteps !== workflow.totalSteps ? (
              <Flex variant="badge" color="white" bg="red" alignItems="center">
                <Box>Exited</Box>
              </Flex>
            ) : (
              <Flex
                variant="badge"
                color="white"
                bg="green"
                alignItems="center"
              >
                <Box>Completed</Box>
              </Flex>
            )}
            <GhostButton onClick={() => setShow(!show)}>
              <ArrowDropDownIcon />
            </GhostButton>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justifyContent={"flex-start"}
        alignItems="center"
        width={"100%"}
        px={3}
      >
        <Text variant="muted" sx={{ whiteSpace: "nowrap" }}>
          {moment(workflow.startedAt).format("DD MMM YY hh:mma")}
        </Text>
        {workflow.completedAt && (
          <Flex alignItems="center">
            <Text mx={2} variant="muted">
              -
            </Text>
            <Text variant="muted" sx={{ whiteSpace: "nowrap" }}>
              {moment(workflow.completedAt).format("DD MMM YY  hh:mma")}
            </Text>
          </Flex>
        )}
      </Flex>
      {show && (
        <Flex px={4} width="100%" flexDirection="column">
          {workflow?.entityWorkflowSteps
            .sort((a, b) => {
              return new Date(a.sequenceNo) - new Date(b.sequenceNo);
            })
            .map((step, idx) => {
              if (!!step.completedAt) {
                lastCompletedStepIdx = idx;
              }
              return (
                <WorkflowStepDetail
                  key={step.id}
                  workflow={workflow}
                  step={step}
                  idx={idx}
                  lastCompletedStepIdx={lastCompletedStepIdx}
                />
              );
            })}
        </Flex>
      )}
    </Flex>
  );
};
const WorkflowStepDetail = ({ workflow, step, idx, lastCompletedStepIdx }) => {
  const theme = useTheme();
  const [show, setShow] = useState(true);
  const action = step.entityWorkflowStepActions[0];

  return (
    show && (
      <Flex
        key={idx}
        mx={2}
        my={1}
        flex={1}
        flexDirection="column"
        alignItems="center"
        sx={{
          "&:not(:last-of-type)": {
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: theme.colors.accent,
          },
        }}
        p={1}
      >
        <Flex flex={1} width="100%" py={1} alignItems="center">
          {step.completedAt && (
            <Box mt={1} mr={2}>
              <Flex
                flex={1}
                bg="brandHighlight"
                sx={{ borderRadius: 99, width: 20, height: 20 }}
                alignItems="center"
                justifyContent="center"
                color="brandLight"
              >
                <RiCheckLine size={18} />
              </Flex>
            </Box>
          )}
          {!step.completedAt && (
            <Text
              mt={1}
              mr={2}
              color={
                lastCompletedStepIdx === idx - 1 ? "brandLight" : "gray.200"
              }
            >
              <CheckCircleOffIcon size={20} />
            </Text>
          )}
          <Flex flex={1} flexDirection="column" alignItems="center">
            <Flex width="100%" alignItems="center">
              <Text mx={3} variant="muted">
                {
                  actionOptions.find((a) => a.value === action.actionType)
                    ?.label
                }
              </Text>
              <Text>{action.config?.title}</Text>
            </Flex>
          </Flex>
          <Flex
            justifyContent="flex-end"
            flexDirection="column"
            alignItems="center"
          >
            <Label>
              {workflow.completedAt && !step.completedAt
                ? "Not Completed"
                : step.completedAt
                ? "Completed"
                : "Due"}
            </Label>
            {workflow.completedAt && step.completedAt ? (
              <Text variant="muted" fontWeight="bold" mx={1}>
                {moment(step.completedAt).format("DD MMM YY hh:mma")}
              </Text>
            ) : (
              <Text
                variant="muted"
                fontWeight="bold"
                mx={1}
                color={
                  moment(step.dueAt).isBefore(moment()) ? "red" : "brandLight"
                }
              >
                {moment(step.dueAt).format("DD MMM YY hh:mma")}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    )
  );
};
WorkflowStepDetail.propTypes = {
  workflow: PropTypes.object,
  step: PropTypes.object,
  idx: PropTypes.number,
  lastCompletedStepIdx: PropTypes.number,
};
export default AutomationDashboard;
