import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Text, Flex } from "rebass";
import { useTheme } from "emotion-theming";

const Link = (props) => {
  const theme = useTheme();
  return (
    <Flex alignItems="center" variant="link">
      {props.disabled ? (
        <Flex
          p={2}
          sx={{
            color: theme.colors.gray[700],
            ":hover": {
              background: theme.colors.gray[200],
              borderTopLeftRadius: "medium",
              borderTopRightRadius: "medium",
            },
          }}
        >
          {props.children}
        </Flex>
      ) : (
        <NavLink
          to={props.to}
          style={{
            color: theme.colors.gray[700],
            textDecoration: "none",
            fontFamily: theme.fonts.body,
            width: "100%",
            "&:hover": {
              background: theme.colors.gray[200],
              borderTopLeftRadius: "medium",
              borderTopRightRadius: "medium",
            },
          }}
          activeStyle={{
            color: theme.colors.brandLight,
            borderRadius: 0,
            borderBottomColor: "brandLight",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            background: "transparent",
          }}
        >
          <Flex
            p={2}
            sx={{
              ":hover": {
                background: theme.colors.gray[200],
                borderTopLeftRadius: "medium",
                borderTopRightRadius: "medium",
              },
            }}
          >
            {props.children}
          </Flex>
        </NavLink>
      )}
    </Flex>
  );
};

Link.propTypes = {
  children: PropTypes.node,
};

export default Link;
