import React, { useState } from "react";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import { Flex, Link, Box, Text } from "rebass";
import { useMutation, useQuery } from "@apollo/client";
import CompanyParentQuery from "../../../graphql/queries/CompanyParent.gql";
import { Tree, TreeNode } from "react-organizational-chart";
import AssignParentCompanyMutation from "../../../graphql/mutations/AssignParentCompany.gql";
import SelectAsync from "../../Common/Forms/SelectAsync";
import CompanyListQuery from "../../../graphql/queries/CompaniesList";
import { useToasts } from "react-toast-notifications";
import GhostButton from "../../Common/Buttons/GhostButton";
import { ExternalLinkIcon, LinkIcon } from "../../Common/Icons";
import theme from "../../../theme";
import { useTheme } from "emotion-theming";

function CompanyTreeView(props) {
  const { id } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const { addToast } = useToasts();
  const [save] = useMutation(AssignParentCompanyMutation);
  const { refetch, data, client } = useQuery(CompanyParentQuery, {
    variables: { id: id },
  });

  async function findCompanies(inputValue, client) {
    let options = [];
    await client
      ?.query({
        query: CompanyListQuery,
        variables: { name: inputValue, parentOnly: true },
      })
      .then(({ error, data }) => {
        if (error) {
          console.log("response error");
          console.log(error);
        }
        if (data) {
          const key = _.first(Object.keys(data));
          options = data[key].map((d) => {
            return {
              id: d.id,
              value: d.id,
              label: d.name,
              name: d.name,
            };
          });
        }
      })
      .catch((error) => {
        console.log("catch company error");
        console.log(error);
      });
    return options;
  }
  function loadCompanyOptions(inputValue, callback) {
    (async () => {
      let options = await findCompanies(inputValue, client);
      callback(options);
    })().catch((err) => console.log("ASYNC ERROR " + err.message));
  }
  const assignParent = (parentId) => {
    save({
      variables: {
        companyId: id,
        parentCompanyId: parentId,
      },
    }).then(
      (response) => {
        const updateResponse = response.data?.assignParentCompany;
        console.log(updateResponse);
        if (!updateResponse?.errors) {
          refetch();
        } else {
          addToast(<Flex>{updateResponse?.errors[0]}</Flex>, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const root = _.cloneDeep(data?.companyHierarchy[0]);
  const child = data?.companyHierarchy.find((c) => c.id == id);
  const hasChildren = data?.companyHierarchy.length > 1; // has children if more than 1 is returned

  let companyHierarchy = _.cloneDeep(data?.companyHierarchy);

  const nodeLabel = (node) => {
    return node.id === child.parentId ? (
      <Flex>
        <SelectAsync
          name="parent"
          width="250px"
          value={{ value: node?.id, label: node?.name }}
          loadOptions={loadCompanyOptions}
          onChange={(name, value) => assignParent(value?.id)}
        />
        <GhostButton
          sx={{ height: 40 }}
          onClick={() => {
            history.push(window.location.pathname.replace(id, node.id));
          }}
        >
          <ExternalLinkIcon />
        </GhostButton>
      </Flex>
    ) : (
      <Flex
        justifyContent="center"
        alignItems={"center"}
        px={3}
        py={2}
        sx={{
          color: node.id == id ? "brand" : "text",
          cursor: "pointer",
          borderColor: node.id == id ? "brandLight" : "gray.200",
          backgroundColor: node.id == id ? "brandHighlight" : "white",
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: theme.radii.medium,
        }}
        onClick={() => {
          history.push(window.location.pathname.replace(id, node.id));
        }}
      >
        <Text mx={2}>{node.name}</Text>
        <ExternalLinkIcon color={theme.colors.brandLight} />
      </Flex>
    );
  };
  function findChildren(r) {
    return r.children?.map((c) => {
      let foundChild = companyHierarchy.find((d) => d.id == c.id);
      if (foundChild) {
        return (
          <TreeNode label={nodeLabel(c)}>{findChildren(foundChild)}</TreeNode>
        );
      } else {
        return <TreeNode label={nodeLabel(c)}></TreeNode>;
      }
    });
  }
  let treeNode =
    root && root.id == child.id && !hasChildren ? (
      <Tree
        lineWidth={"1px"}
        lineColor={theme.colors.accent}
        lineBorderRadius={theme.radii.medium}
        label={
          <Flex alignItems={"flex-start"}>
            <SelectAsync
              name="parent"
              label={"parent"}
              placeholder="Select parent account"
              loadOptions={loadCompanyOptions}
              onChange={(name, value) => assignParent(value?.id)}
            />
          </Flex>
        }
      >
        <TreeNode label={nodeLabel(root)}>{findChildren(root)}</TreeNode>
      </Tree>
    ) : (
      root && (
        <Tree
          lineWidth={"2px"}
          lineColor={theme.colors.gray["200"]}
          lineBorderRadius={"10px"}
          label={nodeLabel(root)}
        >
          {findChildren(root)}
        </Tree>
      )
    );

  return (
    <Flex flex={1} flexDirection="column">
      <Box my={3} />
      <Flex flex={1} justifyContent={"center"}>
        <Box>{root && treeNode}</Box>
      </Flex>
    </Flex>
  );
}

export default CompanyTreeView;
