import React, { useState } from "react";
import { useHistory } from "react-router";
import { Flex, Link, Text, Image, Box } from "rebass";
import Label from "Components/Common/Forms/Label";
import WidgetWrapper from "./WidgetWrapper";
import Loader from "../../Common/Loader";
import PropTypes from "prop-types";

function CommandCenter({ data, loading }) {
  return (
    <WidgetWrapper title="Command Center - Current Week">
      {loading ? (
        <Loader />
      ) : (
        <Flex flexDirection="column" width="100%">
          <Text variant="muted">Tasks</Text>
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" justifyContent="space-between" />
            <TaskCard
              label="Critical"
              value={data?.taskPriorityCount || 0}
              button="ghost"
              link="/tasks?tab=Priority"
            />
            <Flex
              mx={2}
              height="100%"
              sx={{
                borderLeftColor: "accent",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
              }}
            />
            <TaskCard
              label="Overdue"
              value={data?.taskOverdueCount || 0}
              button="ghost"
              color="red"
              link="/tasks?tab=Overdue"
            />
            <Flex
              mx={2}
              height="100%"
              sx={{
                borderLeftColor: "accent",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
              }}
            />
            <TaskCard
              label=" Today"
              value={(data?.taskDueCount || 0) + (data?.taskOverdueCount || 0)}
              button="ghost"
              link="/tasks?tab=All"
            />
            <Flex
              mx={2}
              height="100%"
              sx={{
                borderLeftColor: "accent",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
              }}
            />
            <TaskCard
              label="this week"
              value={data?.taskTotalCount || 0}
              button="ghost"
              link="/tasks?tab=Completed"
            />
            <Flex alignItems="center" justifyContent="space-between" />
          </Flex>
          <Flex height="20px" />
          <Text my={2} variant="muted">
            Support Tickets
          </Text>
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" justifyContent="space-between" />
            <TaskCard
              label="Open"
              value={data?.ticketOpenCount || 0}
              button="ghost"
              link="/support"
            />
            <Flex
              mx={2}
              height="100%"
              sx={{
                borderLeftColor: "accent",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
              }}
            />
            <TaskCard
              label="Overdue"
              value={data?.ticketOverdueCount || 0}
              button="ghost"
              link="/support"
            />
            <Flex
              mx={2}
              height="100%"
              sx={{
                borderLeftColor: "accent",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
              }}
            />
            <TaskCard
              label="Closed"
              value={data?.ticketClosedCount || 0}
              button="ghost"
              link="/support"
            />
            <Flex
              mx={2}
              height="100%"
              sx={{
                borderLeftColor: "accent",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
              }}
            />
            <TaskCard button="ghost" link="/support" />
            <Flex alignItems="center" justifyContent="space-between" />
          </Flex>
        </Flex>
      )}
    </WidgetWrapper>
  );
}
CommandCenter.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
export default CommandCenter;

const TaskCard = (props) => {
  const history = useHistory();
  return (
    <Flex
      mx={2}
      width="105px"
      justifyContent="center"
      alignItems="center"
      sx={{ borderRadius: "medium", cursor: "pointer" }}
      onClick={() => history.push(props.link)}
    >
      <Flex
        p={2}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Label name={props.label}>{props.label}</Label>
        <Text
          variant="h1"
          color={props.color || "brand"}
          textAlign="center"
          mx={2}
        >
          {props.value}
        </Text>
      </Flex>
    </Flex>
  );
};
TaskCard.propTypes = {
  value: PropTypes.object,
  label: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
};
