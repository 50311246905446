import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  RiEmotionLaughLine,
  RiEmotionNormalLine,
  RiEmotionSadLine,
} from "react-icons/ri";
import { Box, Flex, Text } from "rebass";

export const watermarkSeries = (scoreType) => {
  switch (scoreType) {
    case "nps":
      return [
        { min: 40, max: 100, color: "#D9F3CD4f" },
        { min: 0, max: 40, color: "transparent" },
        { min: -100, max: 0, color: "#F8D5D882" },
      ];
    case "csat":
      return [
        { min: 60, max: 100, color: "#D9F3CD4f" },
        { min: 20, max: 60, color: "transparent" },
        { min: 0, max: 20, color: "#F8D5D882" },
      ];
    case "ces":
      return [
        { min: 5, max: 10, color: "#F8D5D882" },
        { min: 2, max: 5, color: "transparent" },
        { min: 0, max: 2, color: "#D9F3CD4f" },
      ];
    default:
      return null;
  }
};

export const CalculationDescription = ({ data, scoreType }) => {
  const lastScore =
    data &&
    _.last(
      data.sort((a, b) => {
        if (moment(a.name).isBefore(moment(b.name))) return 1;
        if (moment(a.name).isAfter(moment(b.name))) return -1;
        return 0;
      })
    );
  switch (scoreType) {
    case "nps":
      return (
        <Flex alignItems="center">
          <Text variant="label" mx={1} fontSize="xs">
            NPS =
          </Text>
          <Flex alignItems="center" flexDirection="column">
            <Flex
              alignItems="center"
              sx={{
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
                borderBottomColor: "body",
              }}
            >
              <Text variant="label" mx={1}>
                (
              </Text>
              <Box>
                <Text variant="h5" textAlign="center">
                  {lastScore?.config?.promoter_count || 0}
                </Text>
                <Text variant="label" fontSize="xs">
                  promoters
                </Text>
              </Box>
              <Text variant="label" mx={1}>
                -
              </Text>
              <Box>
                <Text variant="h5" textAlign="center">
                  {lastScore?.config?.detractors_count || 0}
                </Text>
                <Text variant="label" fontSize="xs">
                  detractors
                </Text>
              </Box>
              <Text variant="label" mx={1}>
                )
              </Text>
            </Flex>
            <Box>
              <Text variant="h5" textAlign="center">
                {lastScore?.config?.total_respondents || 0}
              </Text>
              <Text variant="label" fontSize="xs">
                responses
              </Text>
            </Box>
          </Flex>
        </Flex>
      );
    case "csat":
      return (
        <Flex alignItems="center">
          <Text variant="label" mx={1}>
            CSAT =
          </Text>
          <Flex alignItems="center" flexDirection="column">
            <Flex
              alignItems="center"
              sx={{
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
                borderBottomColor: "body",
              }}
            >
              <Box>
                <Text variant="h4" textAlign="center">
                  {lastScore?.config?.sum_scores || 0}
                </Text>
                <Text variant="label">satisfied responses</Text>
              </Box>
            </Flex>
            <Box>
              <Text variant="h4" textAlign="center">
                {lastScore?.config?.total_respondents || 0}
              </Text>
              <Text variant="label">total responses</Text>
            </Box>
          </Flex>
        </Flex>
      );
    case "ces":
      return (
        <Flex alignItems="center">
          <Text variant="label" mx={1}>
            CES =
          </Text>
          <Flex alignItems="center" flexDirection="column">
            <Flex
              alignItems="center"
              sx={{
                borderBottomStyle: "solid",
                borderBottomWidth: 2,
                borderBottomColor: "body",
              }}
            >
              <Box>
                <Text variant="h4" textAlign="center">
                  {lastScore?.config?.sum_scores || 0}
                </Text>
                <Text variant="label">sum responses</Text>
              </Box>
            </Flex>
            <Box>
              <Text variant="h4" textAlign="center">
                {lastScore?.config?.total_respondents || 0}
              </Text>
              <Text variant="label">total responses</Text>
            </Box>
          </Flex>
        </Flex>
      );
    default:
      return null;
  }
};
export const processSatisfactionData = (rawData, scoreType, responses) => {
  let totalResponses = 0;
  let breakdownData = [...Array(yMax(scoreType)).keys()].reverse().map((i) => {
    let classify = classifyData(scoreType, i);
    const count = rawData?.filter(
      (c) => convertValue(scoreType, c.value) === i
    ).length;

    totalResponses +=
      responses === undefined || classify === responses ? count : 0;

    return {
      value: i,
      total: count,
      classify: classify,
      percentage: 0,
    };
  });

  breakdownData = breakdownData.map((d) => {
    let classify = classifyData(scoreType, d.value);

    let percentage =
      totalResponses <= 0 ? 0 : ((d.total / totalResponses) * 100).toFixed(0);
    return {
      ...d,
      total: responses === undefined || classify === responses ? d.total : 0,
      percentage:
        responses === undefined || classify === responses ? percentage : 0,
    };
  });

  return { processedData: breakdownData, totalResponses: totalResponses };
};
export const convertValue = (type, value) => {
  switch (type) {
    case "csat":
      return value / 2;
    default:
      return value;
  }
};
export const yMax = (type) => {
  switch (type) {
    case "nps":
      return 11;
    case "csat":
      return 6;
    case "ces":
      return 11;
    default:
      return 11;
  }
};
export const labelNeutral = (type) => {
  switch (type) {
    case "nps":
      return "Passive";
    case "csat":
      return "Neutral";
    case "ces":
      return "Neutral";
    default:
      return "Neutral";
  }
};
export const labelNegative = (type) => {
  switch (type) {
    case "nps":
      return "Detractor";
    case "csat":
      return "Dissatisfied";
    case "ces":
      return "Disagree";
    default:
      return "Detractor";
  }
};
export const labelPositive = (type) => {
  switch (type) {
    case "nps":
      return "Promoter";
    case "csat":
      return "Satisfied";
    case "ces":
      return "Agree";
    default:
      return "Promoter";
  }
};

export const classifyData = (type, value) => {
  let convertedValue = value;
  switch (type) {
    case "nps":
      if (convertedValue <= 6) return -1;
      if (convertedValue >= 9) return 1;
      return 0;
    case "csat":
      if (convertedValue <= 2) return -1;
      if (convertedValue >= 4) return 1;
      return 0;
    case "ces":
      if (convertedValue <= 2) return 1;
      if (convertedValue >= 5) return -1;
      return 0;
    default:
      return 0;
  }
};
export const detratorMax = (type) => {
  switch (type) {
    case "nps":
      return 6;
    case "csat":
      return 2;
    case "ces":
      return 2;
    default:
      return 0;
  }
};

export const promoterMin = (type) => {
  switch (type) {
    case "nps":
      return 9;
    case "csat":
      return 4;
    case "ces":
      return 4;
    default:
      return 0;
  }
};

export const icon = (scoreType, value) => {
  const poorIcon = (
    <Box pt={3}>
      <RiEmotionSadLine size={25} />
    </Box>
  );
  const okIcon = (
    <Box pt={3}>
      <RiEmotionNormalLine size={25} />
    </Box>
  );
  const goodIcon = (
    <Box pt={3}>
      <RiEmotionLaughLine size={25} />
    </Box>
  );

  let color = "gray.600";
  let valueObject = value;
  switch (scoreType) {
    case "nps":
      color = value <= 6 ? "red" : value >= 9 ? "green" : "gray.700";
      break;
    case "csat":
      valueObject = value <= 2 ? poorIcon : value >= 4 ? goodIcon : okIcon;
      color = value <= 2 ? "red" : value >= 4 ? "green" : "gray.700";
      break;
    case "ces":
      color = value > 4 ? "red" : value <= 2 ? "green" : "gray.700";
      break;
    default:
      color = "gray.700";
      break;
  }

  return (
    <Flex flex={1} height="100%" alignItems="center" justifyContent="center">
      <Text color={color} fontWeight="bold">
        {valueObject}
      </Text>
    </Flex>
  );
};

export const SurveyQuestionSuggestions = [
  {
    surveyType: "csat",
    responseType: undefined,
    question: "How can we improve our service/product?",
  },
  {
    surveyType: "csat",
    responseType: undefined,
    question: "Did we do anything that stood out in this interaction?",
  },
  {
    surveyType: "csat",
    responseType: "na",
    question:
      "Was the effort spent on this issue in line with your expectations?",
  },
  {
    surveyType: "csat",
    responseType: "positive",
    question:
      "How did this interaction set you up to succeed with our product?",
  },
  {
    surveyType: "csat",
    responseType: "neutral",
    question:
      "If the problem was not solved, were appropriate alternatives or next steps given to you?",
  },
  {
    surveyType: "csat",
    responseType: "negative",
    question:
      "Where did this interaction/feature fail to meet your expectations?",
  },

  {
    surveyType: "ces",
    responseType: undefined,
    question: "How easy was using [product] so far?",
  },
  {
    surveyType: "ces",
    responseType: undefined,
    question: "Overall, how easy was it to solve your problem today?",
  },
  {
    surveyType: "ces",
    responseType: "na",
    question:
      "Was the effort spent on this issue in line with your expectations?",
  },
  {
    surveyType: "ces",
    responseType: "positive",
    question:
      "Were you able to find the information you wanted easily on our website or in our app?",
  },
  {
    surveyType: "ces",
    responseType: "neutral",
    question: "What could we have done to improve your score by one point?",
  },
  {
    surveyType: "ces",
    responseType: "negative",
    question: "What made this experience difficult?",
  },

  {
    surveyType: "nps",
    responseType: undefined,
    question:
      "On a scale of 0 to 10, how likely are you to recommend our business to a friend or colleague?",
  },
  {
    surveyType: "nps",
    responseType: "na",
    question:
      "We appreciate your feedback. Please share the reason behind your score.",
  },
  {
    surveyType: "nps",
    responseType: "positive",
    question:
      "Thank you for your feedback. Would you like to tell us why you would score us that way?",
  },
  {
    surveyType: "nps",
    responseType: "positive",
    question:
      "We appreciate your feedback. Please share the reason behind your score.",
  },
  {
    surveyType: "nps",
    responseType: "neutral",
    question:
      "We appreciate your feedback. Please share the reason behind your score.",
  },
  {
    surveyType: "nps",
    responseType: "neutral",
    question:
      "Thank you for your feedback. Would you like to tell us why you would score us that way?.",
  },
  {
    surveyType: "nps",
    responseType: "negative",
    question:
      "Thank you for your feedback. How can we improve your experience?",
  },
  {
    surveyType: "nps",
    responseType: "negative",
    question: "We’re sorry to hear that. What would you like us to improve on?",
  },
];
