import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import ProductsQuery from "../graphql/queries/CurrentProduct.gql";

export function getProduct() {
  const productsQuery = useQuery(ProductsQuery, {});

  return {
    ...productsQuery,
  };
}
