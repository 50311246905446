import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text, Box } from "rebass";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "Components/Common/Loader";
import AssignPlaybookMutation from "../../../graphql/mutations/AssignWorkflow.gql";
import Workflow from "../../../graphql/queries/Workflows.gql";
import PropTypes from "prop-types";
import Tags from "../../Common/Tags";

function AssignPlaybook(props) {
  const { addToast } = useToasts();
  const [workflowId, setWorkflow] = useState();
  const { data: workflowData } = useQuery(Workflow, {});

  const [save, { loading }] = useMutation(AssignPlaybookMutation);

  const workflows = workflowData?.workflows || [];

  const assignWorkflow = (id) => {
    save({
      variables: {
        ids: props.ids,
        parentType: props.parentType,
        workflowId,
      },
    }).then(
      (response) => {
        if (!response.data?.assignCompanyWorkflow?.errors) {
          props.onSave();
        } else {
          addToast(
            <Flex>{response.data?.assignCompanyWorkflow?.errors[0]}</Flex>,
            {
              appearance: "error",
              autoDismiss: false,
            }
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flexDirection="column" px={3} pb={4}>
      {loading ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Loader />
          <Text mx={2}>
            Please wait we are updating your accounts workflows
          </Text>
        </Flex>
      ) : (
        <>
          <Flex
            sx={{ maxHeight: 500, overflowY: "scroll" }}
            flexDirection="column"
          >
            {workflows
              .filter((w) => w.enabled)
              .map((workflow) => (
                <Flex
                  key={workflow.id}
                  p={3}
                  variant="box"
                  m={2}
                  bg={workflowId === workflow.id && "brandHighlight"}
                  sx={{ cursor: "pointer" }}
                  onClick={({ target }) => {
                    setWorkflow(workflow.id);
                  }}
                >
                  <Flex
                    flex={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex flex={1} mx={2} flexDirection="column">
                      <Text variant="h4">{workflow.name}</Text>
                      <Text mt={1} variant="muted">
                        {workflow.description}
                      </Text>
                    </Flex>
                    <Flex flex={1} justifyContent="flex-end">
                      <Tags value={workflow.tags} />
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            <Box my={2} />
          </Flex>
          <Flex mt={3} justifyContent="flex-end">
            <Button
              variant={workflowId ? "primary" : "disabled"}
              disabled={!workflowId}
              onClick={() => assignWorkflow()}
            >
              Update
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
}
AssignPlaybook.propTypes = {
  companyIds: PropTypes.array,
  onSave: PropTypes.func,
};
export default AssignPlaybook;
