import React from "react";
import { withRouter } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { Flex, Text, Box } from "rebass";
import "react-tabs/style/react-tabs.css";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import { Formik, FieldArray } from "formik";
import { useToasts } from "react-toast-notifications";
import ShowError, { ErrorParams } from "Components/Common/Notifications/Error";
import { PlusIcon } from "Components/Common/Icons";
import ShowSuccess, {
  SuccessParams,
} from "Components/Common/Notifications/Success";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import TypeModelItem from "./TypeModelItem";
import DndList from "./DND";
import DraggableItem from "./DraggableItem";
import { getDefaultProduct } from "../../../../auth-helper";
import SchemaMutation from "../../../../graphql/mutations/TypeSchema.gql";
import TypeSchemaQuery from "../../../../graphql/queries/TypeSchemas.gql";

function TypeModel(props) {
  const { addToast } = useToasts();
  const selectedProduct = getDefaultProduct();
  const [save] = useMutation(SchemaMutation);

  const [refreshSchema] = useLazyQuery(TypeSchemaQuery, {
    fetchPolicy: "network-only",
  });

  const model = {
    ...props.data,
    attributes: props.data?.attributes
      .filter((f) => !f.productId || f.productId === selectedProduct)
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      }),
  };

  const saveFields = (id, attributes) => {
    save({
      variables: {
        id: id || null,
        fields: attributes,
      },
    }).then(
      (response) => {
        const result = response.data?.typeSchema;
        if (result?.errors) {
          addToast(
            <ShowError message={result?.errors && result?.errors[0]} />,
            ErrorParams
          );
        } else {
          addToast(<ShowSuccess message="Saved!" />, SuccessParams);
          refreshSchema();
        }
      },
      (error) => {
        addToast(<ShowError message="Save error!" />, ErrorParams);
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={model || {}}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        id: Yup.string().required("Required"),
      })}
      onSubmit={(values) => {
        saveFields(values.id, values.attributes);
      }}
    >
      {(formProps) => {
        const { values, handleSubmit } = formProps;
        return (
          <Flex flex={1} flexDirection="column">
            <Flex w="100%" flexDirection="column">
              <Flex flex={1} flexDirection="column">
                <FieldArray
                  name="attributes"
                  render={(arrayHelpers) => (
                    <Flex flexDirection="column">
                      <Flex
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text variant="h3" my={2}>
                          {model.displayName} Fields
                        </Text>
                        <Box>
                          <PrimaryButton
                            onClick={() => {
                              arrayHelpers.push({
                                type: "string",
                                name: undefined,
                                required: false,
                                storage: "custom",
                                access: "readwrite",
                                sortOrder: 9999,
                              });
                            }}
                            leftIcon={<PlusIcon />}
                          >
                            Add
                          </PrimaryButton>
                        </Box>
                      </Flex>
                      <Box width="100%">
                        <Flex my={3} alignItems="center">
                          <Box sx={{ width: 55 }} />
                          <Flex width={1 / 4}>
                            <Text
                              variant="body"
                              fontSize="xs"
                              fontWeight="bold"
                            >
                              Name
                            </Text>
                          </Flex>
                          <Flex width={1 / 4}>
                            <Text
                              variant="body"
                              fontSize="xs"
                              fontWeight="bold"
                            >
                              Type
                            </Text>
                          </Flex>
                          <Flex width={1 / 4}>
                            <Text
                              variant="body"
                              fontSize="xs"
                              fontWeight="bold"
                            >
                              Product
                            </Text>
                          </Flex>
                          <Flex width={1 / 4}>
                            <Text
                              variant="body"
                              fontSize="xs"
                              fontWeight="bold"
                            >
                              Features
                            </Text>
                          </Flex>
                        </Flex>
                        <DndList
                          items={values.attributes}
                          onChange={(data) => {
                            const data2 = data.map((field, index) => {
                              return { ...field, sortOrder: index };
                            });
                            saveFields(values.id, data2);
                          }}
                        >
                          {values.attributes &&
                            values.attributes
                              .filter((a) => !a.deleted)
                              .map((child, index) => {
                                return (
                                  <DraggableItem
                                    key={`DraggableItem-${index}`}
                                    item={child}
                                    index={index}
                                  >
                                    <TypeModelItem
                                      child={child}
                                      index={index}
                                      new={!child.name}
                                      onSave={handleSubmit}
                                      onDelete={(idx) => {
                                        if (!child.name) {
                                          arrayHelpers.remove(idx);
                                        } else {
                                          handleSubmit();
                                        }
                                      }}
                                      {...formProps}
                                    />
                                  </DraggableItem>
                                );
                              })}
                        </DndList>
                      </Box>
                    </Flex>
                  )}
                />
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default withRouter(TypeModel);
