import React from "react";
import { Flex, Box, Text } from "rebass";
import { useTheme } from "emotion-theming";
import { TickIcon } from "../Icons";

const WizardNav = (props) => {
  const theme = useTheme();
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push(
      <Flex
        as="li"
        my={3}
        width={1 / props.totalSteps}
        key={`step-${i}`}
        justifyContent="center"
        flexDirection="column"
        sx={{
          position: "relative",
          float: "left",
          "::after": i > 1 && {
            content: `''`,
            display: "block",
            backgroundColor:
              i <= props.currentStep
                ? theme.colors.brandHighlight
                : theme.colors.gray[200],
            border: `2px solid ${
              i <= props.currentStep
                ? theme.colors.brandHighlight
                : theme.colors.gray[200]
            }`,
            position: "absolute",
            top: "12px",
            left: "-50%",
            width: "100%",
            zIndex: 1,
          },
        }}
      >
        <Flex justifyContent="center" textAlign="center">
          <Flex
            variant="circle"
            bg={
              i <= props.currentStep
                ? theme.colors.secondaryLight
                : theme.colors.gray[200]
            }
            justifyContent="center"
            alignItems="center"
            color="white"
            sx={{ zIndex: 2 }}
          >
            {i <= props.currentStep && <TickIcon />}
          </Flex>
        </Flex>
        <Text
          color="brandLight"
          textAlign="center"
          width="100%"
          fontSize="xs"
          my={1}
        >
          {props.stepLabels.length > i - 1 && props.stepLabels[i - 1]}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex flex={1} as="ol" justifyContent="center" bg="white">
      {dots}
    </Flex>
  );
};

export default WizardNav;
