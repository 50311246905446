import { useMutation } from "@apollo/react-hooks";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text, Box } from "rebass";
import { getChurnReasons } from "hooks/getChurnReasons";
import { getCompanyStatus } from "hooks/getCompanyStatuses";
import { getLifeCycles } from "hooks/getLifeCycles";
import RadioGroup from "Components/Common/RadioGroup";
import Loader from "Components/Common/Loader";
import Select from "Components/Common/Forms/Select";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import AssignCompanyStatusMutation from "../../../graphql/mutations/AssignCompanyStatus.gql";
import { useTheme } from "emotion-theming";

function AssignCompanyStatus(props) {
  const theme = useTheme();
  const { addToast } = useToasts();
  const [statusId, setStatus] = useState();
  const [lifeCycleStageId, setLifeCycleStageId] = useState();
  const [churnReason, setChurnReason] = useState();
  const [date, setDate] = useState();
  const [save, { loading }] = useMutation(AssignCompanyStatusMutation);
  const { data } = getChurnReasons();
  const { data: statuses } = getCompanyStatus();
  const { data: lcData } = getLifeCycles(statusId);
  const lifeCycles =
    lcData?.lifeCycles?.find((l) => l.statusId === statusId)?.stages || [];

  const reasons = data?.churnReasons || [];
  const assignChurnReason = (id) => {
    save({
      variables: {
        companyIds: props.companyIds,
        churnReasonId: churnReason,
        statusId,
        lifeCycleStageId,
        churnReasonUpdatedAt: date,
      },
    }).then(
      (response) => {
        if (!response.data?.assignOwner?.errors) {
          props.onSave();
        } else {
          addToast(<Flex>{response.data?.relationship?.errors[0]}</Flex>, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flexDirection="column" px={3} py={4}>
      {loading ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Loader />
          <Text mx={2}>Please wait we are updating your accounts status</Text>
        </Flex>
      ) : (
        <>
          <RadioGroup
            name="customerType"
            selectedOption={statusId}
            options={statuses?.companyStatuses.map((s) => {
              return {
                label: <Text fontSize="xs">{s.name}</Text>,
                value: s.id,
              };
            })}
            onChange={(name, target) => {
              setStatus(target?.value);
            }}
          />
          <Box my={2} />
          {lifeCycles.length > 0 && (
            <Flex flexDirection={"column"} my={3}>
              <Text variant="h3" mr={3} color="muted">
                Lifecycle Journey
              </Text>
              <Flex alignItems="center" my={3}>
                <Flex alignItems="center">
                  <Button
                    variant="default"
                    mx={1}
                    bg={!lifeCycleStageId ? "brand" : "brandHighlight"}
                    color={!lifeCycleStageId ? "white" : "brandLight"}
                    ml={0}
                    mr={1}
                    py={0}
                    my={0}
                    onClick={() => {
                      setLifeCycleStageId(undefined);
                    }}
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      height: 30,
                      marginLeft: 0,
                      marginRight: 0,
                      paddingLeft: 20,
                      paddingRight: 20,
                      position: "relative",
                      borderRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      "&:before": {
                        // triangle hover color
                        borderLeft: 0,
                        // the triangle
                        content: `''`,
                        position: "absolute",
                        borderBottom: "15px solid transparent",
                        borderTop: "15px solid transparent",
                        height: 0,
                        width: 0,
                        marginTop: 0,
                        left: 0,
                        top: 0,
                        zIndex: 1,
                      },
                      "&:after": {
                        // triangle hover color
                        borderLeft: `15px solid ${
                          !lifeCycleStageId
                            ? theme.colors.brand
                            : theme.colors.brandHighlight
                        }`,
                        // the triangle
                        content: `''`,
                        position: "absolute",
                        borderBottom: "15px solid transparent",
                        borderTop: "15px solid transparent",
                        height: 0,
                        width: 0,
                        marginRight: -15,
                        marginTop: 0,
                        top: 0,
                        right: 0,
                        zIndex: 2,
                      },
                      "&:hover": {
                        color: "white",
                        backgroundColor: "brandLight",
                        "&:after": {
                          // triangle hover
                          borderLeft: `15px solid ${theme.colors.brandLight}`,
                        },
                      },
                    }}
                  >
                    Automated
                  </Button>
                </Flex>
                {lifeCycles?.map((lc, i) => (
                  <Flex key={i} alignItems="center">
                    <Button
                      variant="default"
                      mx={1}
                      bg={
                        lifeCycleStageId === lc.id ? "brand" : "brandHighlight"
                      }
                      color={
                        lifeCycleStageId === lc.id ? "white" : "brandLight"
                      }
                      ml={0}
                      mr={1}
                      py={0}
                      my={0}
                      key={lc.id}
                      onClick={() => {
                        if (lifeCycleStageId === lc.id) {
                          setLifeCycleStageId(undefined);
                        } else {
                          setLifeCycleStageId(lc.id);
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        display: "inline-block",
                        height: 30,
                        marginLeft: 30,
                        marginRight: 30,
                        paddingLeft: 30,
                        paddingRight: i === lifeCycles.length - 1 ? 20 : 0,
                        position: "relative",
                        borderRadius: 0,
                        borderTopRightRadius:
                          i === lifeCycles.length - 1 ? 99 : 0,
                        borderBottomRightRadius:
                          i === lifeCycles.length - 1 ? 99 : 0,
                        "&:before": {
                          // triangle hover color
                          borderLeft: `15px solid white`,
                          // the triangle
                          content: `''`,
                          position: "absolute",
                          borderBottom: "15px solid transparent",
                          borderTop: "15px solid transparent",
                          height: 0,
                          width: 0,
                          marginTop: 0,
                          left: 0,
                          top: 0,
                          zIndex: 1,
                        },
                        "&:after": {
                          // triangle hover color
                          borderLeft:
                            i < lifeCycles.length - 1
                              ? `15px solid ${
                                  lifeCycleStageId === lc.id
                                    ? theme.colors.brand
                                    : theme.colors.brandHighlight
                                }`
                              : 0,
                          // the triangle
                          content: `''`,
                          position: "absolute",
                          borderBottom: "15px solid transparent",
                          borderTop: "15px solid transparent",
                          height: 0,
                          width: 0,
                          marginRight: -15,
                          marginTop: 0,
                          top: 0,
                          right: 0,
                          zIndex: 2,
                        },
                        "&:hover": {
                          color: "white",
                          backgroundColor: "brandLight",
                          "&:after": {
                            // triangle hover
                            borderLeft:
                              i < lifeCycles.length - 1
                                ? `15px solid ${theme.colors.brandLight}`
                                : 0,
                          },
                        },
                      }}
                    >
                      {lc.name}
                    </Button>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
          {statuses?.companyStatuses.find((s) => s.id === statusId)?.name ===
            "Churned" && (
            <Flex>
              <Box mt={3}>
                <CustomFormGroup
                  type="list"
                  width="250px"
                  placeholder="Churn Reason"
                  name="Churn Reason"
                  label="Churn Reason"
                  onChange={(name, value) => setChurnReason(value?.value)}
                  options={reasons?.map((c) => {
                    return { value: c.id, label: c.name };
                  })}
                />
              </Box>
              <Box m={3}>
                <CustomFormGroup
                  label="Date"
                  name="date"
                  type="date"
                  value={date}
                  onChange={(name, value) => setDate(value)}
                />
              </Box>
            </Flex>
          )}
          <Flex mt={3} justifyContent="flex-end">
            <Button
              variant={statusId ? "primary" : "disabled"}
              disabled={!statusId}
              onClick={() => assignChurnReason()}
            >
              Update
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default AssignCompanyStatus;
