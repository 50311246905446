import React from "react";
import { Flex, Text } from "rebass";
import { useTheme } from "emotion-theming";
import { RiArrowUpCircleLine, RiImageLine } from "react-icons/ri";

function FileUploadTemplate({ logo }) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column">
      <Text color="gray.200">
        {logo ? <RiImageLine size={40} /> : <RiArrowUpCircleLine size={40} />}
      </Text>
      <Text fontWeight="bold" fontSize="sm" my={2} color="muted">
        {logo ? "Add logo" : "Add File(s)"}
      </Text>
      {!logo && (
        <Flex flexWrap="wrap">
          <Text variant="muted">Drag &amp; drop files or</Text>
          <Text variant="muted" color="brandLight" mx={1}>
            browse
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

export default FileUploadTemplate;
