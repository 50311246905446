import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import SegmentsQuery from "../graphql/queries/Segments.gql";
import CoreSegmentsQuery from "../graphql/queries/CoreSegments.gql";

export function getSegments(segmentableType) {
  const query = useQuery(SegmentsQuery, {
    variables: { segmentableType: segmentableType },
  });

  return {
    ...query,
  };
}

export function getCoreSegments() {
  const query = useQuery(CoreSegmentsQuery, {});

  return {
    ...query,
  };
}
