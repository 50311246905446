import { useQuery } from "@apollo/react-hooks";
import EventsQuery from "../graphql/queries/Events.gql";
import EventsGlobalQuery from "../graphql/queries/EventsGlobal.gql";
import EventGroupSummaryQuery from "../graphql/queries/EventGroupSummary.gql";

export function getEvents(id, parentType) {
  const eventsQuery = useQuery(EventsQuery, {
    variables: { id, parentType },
  });

  return {
    ...eventsQuery,
  };
}

export function getEventsGlobal() {
  const eventsQuery = useQuery(EventsGlobalQuery);

  return {
    ...eventsQuery,
  };
}

export function getEventGroupSummary(id, daysAgo, parentId, parentType) {
  const eventsQuery = useQuery(EventGroupSummaryQuery, {
    variables: {
      eventGroupId: id,
      daysAgo,
      parentId,
      parentType,
    },
  });

  return {
    ...eventsQuery,
  };
}
