import React from "react";
import DoubleStatCard from "Components/Dashboard/Widgets/DoubleStatCard";
import WidgetWrapper from "./WidgetWrapper";
import Loader from "../../Common/Loader";

const numeral = require("numeral");

const CalcIncrease = (a, b) => {
  const value = b > 0 ? ((a - b) / b) * 100 : 0;
  return value.toFixed(1);
};

function PaidSummary({ data, loading }) {
  return (
    <WidgetWrapper title="Paid Accounts">
      {loading ? (
        <Loader />
      ) : (
        <DoubleStatCard
          value={numeral(data?.paid?.mrr).format("0.00a")}
          value2={data?.paid?.total || 0}
          label="MRR"
          label2="Total"
          increase={CalcIncrease(data?.paid?.total, data?.paid?.prevTotal)}
        />
      )}
    </WidgetWrapper>
  );
}

export default PaidSummary;
