import React, { useEffect, useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import HealthScoreIcon from "Components/Data/Company/HealthScoreIcon";
import Table from "Components/Common/Table";
import moment from "moment";
import WidgetWrapper from "./WidgetWrapper";
import CompanyRenewalsQuery from "../../../graphql/queries/CompanyRenewals";
import { getProduct } from "hooks/getProduct";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";

const _ = require("lodash");
const numeral = require("numeral");

function RenewalSummary(props) {
  const { data: currentProduct } = getProduct();
  const history = useHistory();
  const [topData, setTopData] = useState([]);
  const { loading, data, error, refetch } = useQuery(CompanyRenewalsQuery, {
    variables: {
      searchFilters: {
        ownerUserId: props.filters?.ownerUserId,
        productId: props.filters?.productId,
      },
      priorityOnly: true,
    },
  });

  const columns = [
    {
      name: "Health",
      selector: (row) => row.details?.healthScore,
      sortable: true,
      width: "100px",
      cell: (row) => {
        return (
          <HealthScoreIcon
            status={row?.detail?.status}
            simple
            data-tag="allowRowEvents"
            value={row?.detail?.healthScore}
            category={row?.detail?.healthCategory}
            date={row?.detail?.healthScoreUpdatedAt}
          />
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Renewal Date",
      selector: (row) => row.detail?.renewalDate,
      sortable: true,
      cell: (row) => {
        return (
          <Box data-tag="allowRowEvents">
            <Text
              data-tag="allowRowEvents"
              fontWeight="bold"
              color={
                moment(row?.detail?.renewalDate).isSameOrBefore(
                  moment().add(2, "months")
                )
                  ? "red"
                  : ""
              }
            >
              {row?.detail?.renewalDate &&
                moment().to(moment(row?.detail?.renewalDate))}
            </Text>
            {row?.detail?.renewalDate && (
              <Text variant="muted" data-tag="allowRowEvents">
                renews {moment(row?.detail?.renewalDate).format("DD MMM")}
              </Text>
            )}
          </Box>
        );
      },
    },
    {
      name: "MRR",
      selector: (row) => row.mrr,
      sortable: true,
      cell: (row) => {
        return (
          <Text data-tag="allowRowEvents">
            {row?.detail?.mrr
              ? Number(row?.detail?.mrr).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : Number("0").toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
          </Text>
        );
      },
    },
  ];
  useEffect(() => {
    const flattenData = [];

    data?.companyRenewals?.forEach((company) => {
      company.companyDetails
        .filter((p) => p.product.id == currentProduct?.currentProduct?.id)
        .filter((p) => p.healthCategory !== "good")
        .forEach((p) => {
          flattenData.push({
            ...company,
            companyId: company.id,
            id: p.id,
            detail: { ...p },
            revenue: p.mrr,
            renewalDate: p.renewalDate,
            value: p.mrr,
            date: moment(p.renewalDate).format("MMM YY"),
            health: p.healthCategory || "ok",
            healthScore: p.healthScore,
          });
        });
    });

    setTopData(flattenData);
  }, [loading, currentProduct]);

  return (
    <WidgetWrapper title="At Risk Renewals">
      <Flex flex={1} flexDirection="column">
        <Flex flex={1} justifyContent="space-between">
          <Box>
            <Text variant="label">Total</Text>
            <Text variant="h1" color="brand" textAlign="right">
              {props.data?.renewalsCount}
            </Text>
          </Box>
          <Box>
            <Text variant="label">MRR</Text>
            <Text variant="h1" color="brand" textAlign="right">
              ${numeral(props.data?.renewalsValue).format("0.00a")}
            </Text>
          </Box>
        </Flex>
        <Table
          title=""
          selectableRows={false}
          columns={columns}
          data={topData}
          loading={loading}
          onRowClicked={(p) => {
            history.push(`/home/accounts/${p.companyId}`);
          }}
          noDataComponent={
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Image src={require("assets/images/Tables.png")} width={80} />
              <Text my={4} variant="muted">
                No account renewals coming up.
              </Text>
            </Flex>
          }
        />
        <Flex justifyContent={"space-between"}>
          <Text variant="muted" my={2}>
            Accounts renewing in the next 60 days including already expired
            accounts.
          </Text>
          <SecondaryButton
            onClick={() => {
              history.push("/reports/renewals");
            }}
          >
            View all
          </SecondaryButton>
        </Flex>
      </Flex>
    </WidgetWrapper>
  );
}

export default RenewalSummary;
