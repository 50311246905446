import React, { useState } from "react";
import Page from "Components/Page";
import { Flex } from "rebass";
import ReportTopbar from "./ReportTopbar";
import Filter from "Components/Filter";
import { MODEL_TYPES } from "consts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AllCompanies from "./AllCompanies";

function Reports(props) {
  const [trigger, setTriggerRefresh] = useState();
  return <AllCompanies />;
}

export default Reports;
