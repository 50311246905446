import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Flex, Text, Box, Link, Image } from "rebass";
import PropTypes from "prop-types";
import Table from "Components/Common/Table";
import ModelHeader from "Components/Common/ModelHeader";
import { MODEL_TYPES } from "consts";
import { useTheme } from "emotion-theming";
import moment from "moment";
import SurveyRequestMutation from "../../../graphql/mutations/SurveyResponseAcknowledge.gql";
import {
  convertValue,
  icon,
} from "../../Common/Satisfaction/SatisfactionDefaults";
import { TickIcon } from "../../Common/Icons";
import GhostButton from "../../Common/Buttons/GhostButton";
import Tags from "../../Common/Tags";
import TagsEditor from "../../Common/Tags/TagsEditor";

function SurveyTable({
  loading,
  data,
  onSave,
  selectedRows,
  onSelectedRowsChange,
}) {
  const [save] = useMutation(SurveyRequestMutation, {});
  const theme = useTheme();

  const acknowledgeSurveyRequest = (row, tags) => {
    save({
      variables: {
        ids: [row.id],
        tags: tags,
      },
    }).then(
      () => {
        onSave && onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const columns = [
    {
      name: " ",
      width: "50px",
      cell: (row) => {
        const score = _.first(
          row.surveyResponses.sort((a, b) => b.score - a.score)
        ).score;
        return (
          <Flex justifyContent="center" px={1} alignItems="center">
            {icon(
              row.survey?.surveyType,
              convertValue(row.survey?.surveyType, score)
            )}
          </Flex>
        );
      },
    },
    {
      name: "Type",
      width: "80px",
      cell: (row) => {
        return (
          <Flex data-tag="allowRowEvents" alignItems="center">
            <Text>{row.survey?.surveyType}</Text>
          </Flex>
        );
      },
    },
    {
      name: "Account",
      cell: (row) => {
        return (
          <Flex alignItems="center">
            <Link href={`/data/accounts/${row.company?.id}`}>
              <ModelHeader
                data-tag="allowRowEvents"
                type={MODEL_TYPES.Company.name}
                name={row.company?.name}
              />
            </Link>
          </Flex>
        );
      },
    },
    {
      name: "User",
      cell: (row) => {
        const name = row.endUser?.name;
        const id = row.endUser?.id;
        return (
          row.endUser && (
            <Flex flexDirection="column">
              <Link href={`/data/${id}`}>
                <ModelHeader
                  data-tag="allowRowEvents"
                  type={MODEL_TYPES.EndUser.name}
                  name={name}
                />
              </Link>
              <Text variant="muted" fontSize={"xxs"}>
                {row.endUser?.email}
              </Text>
            </Flex>
          )
        );
      },
    },
    {
      name: "Survey Name",
      cell: (row) => {
        //this will need to be fixed so that if theres more than one name it adds a space or comma or something

        return (
          <Flex data-tag="allowRowEvents" alignItems="center">
            <Text variant="muted">{row.survey?.name}</Text>
          </Flex>
        );
      },
    },
    {
      name: "Date Received",
      selector: (row) => row.receivedAt,
      sortable: true,
      width: "120px",
      cell: (row) => {
        const date = row.surveyResponses.find((r) => r.score >= 0)?.updatedAt;

        return (
          <Flex>
            <Text variant="muted">
              {date && moment(date).format("DD MMM YYYY")}
            </Text>
          </Flex>
        );
      },
    },
    {
      name: "Category",
      width: "300px",
      cell: (row) => {
        return (
          row.acknowledged && (
            <Flex justifyContent="center" px={1} alignItems="center">
              <TagsEditor
                value={row.categoryList}
                parentType={"survey"}
                onChange={(t) => {
                  acknowledgeSurveyRequest(row, t);
                }}
              />
            </Flex>
          )
        );
      },
    },
    {
      name: "",
      width: "90px",
      cell: (row) => {
        return (
          !row.acknowledged && (
            <Flex justifyContent="center" px={1} alignItems="center">
              <GhostButton onClick={() => acknowledgeSurveyRequest(row)}>
                <Text color="green">
                  <TickIcon size={20} />
                </Text>
              </GhostButton>
            </Flex>
          )
        );
      },
    },
  ];

  const SubComponent = (row) => {
    let responses = row.data.surveyResponses;
    responses.sort(
      (a, b) => a.surveyQuestion.orderSeq - b.surveyQuestion.orderSeq
    );
    const survey = row.data.survey;
    return (
      <Flex flexDirection={"column"} px={5} py={3} bg="gray.100">
        {responses &&
          responses.map((r) => (
            <Box key={r.id} my={2}>
              {(r.score || r.message) && (
                <Flex flex={1} alignItems="center">
                  <Text variant="muted">{r.surveyQuestion.question}</Text>{" "}
                  <Box mx={2}>
                    {r.score &&
                      icon(
                        survey?.surveyType,
                        convertValue(survey?.surveyType, r.score)
                      )}
                  </Box>
                </Flex>
              )}
              {r.message && (
                <Flex flex={1} flexDirection={"column"}>
                  {r.message}
                </Flex>
              )}
            </Box>
          ))}
      </Flex>
    );
  };

  return (
    <Table
      title=""
      defaultSortField="updatedAt"
      columns={columns.filter((c) => !!c)}
      data={data}
      loading={loading}
      selectableRows
      selectedRows={selectedRows}
      onSelectedRowsChange={(rows) => {
        onSelectedRowsChange(rows);
      }}
      expandableRowsComponent={SubComponent}
      noDataComponent={<NoSurveyData />}
    />
  );
}

const NoSurveyData = () => {
  return (
    <Flex
      flex={1}
      py={3}
      bg="gray.100"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ borderRadius: "medium" }}
    >
      <Flex flex={1} p={4} flexDirection="column" justifyContent="center">
        <Image
          src={require("assets/images/TaskEmptyState.png")}
          sx={{ height: "120px", objectFit: "contain" }}
        />

        <Text variant="muted" my={3}>
          You have nothing left to do here!
        </Text>
      </Flex>
    </Flex>
  );
};

SurveyTable.propTypes = {
  detailedView: PropTypes.bool,
  loading: PropTypes.bool,
  onSelected: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  onSelectedRowsChange: PropTypes.func.isRequired,
  saveProductFilter: PropTypes.func,
};

SurveyTable.defaultProps = {
  detailedView: true,
  loading: false,
  data: [],
};
export default withRouter(SurveyTable);
