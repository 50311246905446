import React, { useState } from "react";
import { Box, Flex, Image, Text } from "rebass";
import { MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import ListEditor from "../../Common/ListEditor";
import CompanyDomainMutation from "../../../graphql/mutations/CompanyDomain";
import { useMutation } from "@apollo/client";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { useToasts } from "react-toast-notifications";

function CompanyDomains(props) {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState();
  const { loading, data, refetch } = getData(MODEL_TYPES.Company, props.id);
  const domains = data?.company?.details?.domains || [];
  const [saveCompany, { error: saveError }] = useMutation(
    CompanyDomainMutation
  );

  const values = data?.company;
  const save = (domains) => {
    let valid = true;
    setErrors(undefined);
    var pattern = new RegExp("(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}");
    domains.forEach((domain) => {
      if (!pattern.test(domain)) {
        setErrors(`${domain} not a valid domain`);
        valid = false;
      }
    });

    if (valid) {
      saveCompany({
        variables: {
          id: values.id,
          domains: domains,
        },
      }).then(
        (response) => {
          const result = response.data.companyDomains;
          if (result?.errors) {
            addToast(<Flex>{result?.errors[0]}</Flex>, ErrorParams);
          } else {
            props.onSave && props.onSave(result.company.id);
            addToast(<Flex>Saved!</Flex>, SuccessParams);
          }
        },
        (error) => {
          addToast(<Flex>Error occured saving</Flex>, ErrorParams);
          console.log(error);
        }
      );
    }
  };
  return (
    <Flex flex={1} flexDirection="column">
      <Flex my={2} justifyContent="space-between" alignItems="center">
        <Text variant="h3">Domains</Text>
      </Flex>
      <Box bg="gray.100" p={3} my={2}>
        <Text variant="muted">
          Add the domains connected to this account. This helps with identifying
          accounts through APIs
        </Text>
      </Box>
      <ListEditor
        parentType={props.parentType}
        label=""
        value={domains}
        onChange={(t) => {
          save(t);
        }}
      />
      <Text my={2} variant="muted" color="red">
        {errors}
      </Text>
    </Flex>
  );
}

export default CompanyDomains;
