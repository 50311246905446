import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import Page from "Components/Page";
import { Flex, Button, Box } from "rebass";
import Sidebar from "./Sidebar";
import AdminTopbar from "./AdminTopbar";
import { useTheme } from "emotion-theming";
function Settings(props) {
  let history = useHistory();
  const theme = useTheme();
  return (
    <Page title="Settings" topBar={<AdminTopbar />}>
      <Flex
        flex={1}
        w="100%"
        justifyContent="center"
        style={{
          color: "text",
        }}
      >
        <Flex
          justifyContent="flex-end"
          sx={{
            borderRightColor: "accent",
            borderRightWidth: 1,
            borderRightStyle: "solid",
            minWidth: 250,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[200]}`,
          }}
        >
          <Sidebar />
        </Flex>
        <Flex flexGrow={1} bg="white">
          {props.children}
        </Flex>
      </Flex>
    </Page>
  );
}

export default withRouter(Settings);
