import React, { useState } from "react";
import { Flex, Box, Link, Text, Button } from "rebass";
import { FieldArray } from "formik";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useDynamicSchemas } from "hooks/DynamicSchema";
import { dateOffsetOptions, ModelTypeIcon, MODEL_TYPES } from "consts";
import { getUsers } from "hooks/getUsers";
import { getCompanyStatus } from "hooks/getCompanyStatuses";
import { getTags } from "hooks/getTags";
import { getEventGroups } from "hooks/getEventGroups";
import { MinusIcon, PlusIcon } from "Components/Common/Icons";
import { getProduct } from "hooks/getProduct";
import Label from "Components/Common/Forms/Label";
import { getLifeCycles } from "hooks/getLifeCycles";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { options } from "numeral";
import { UserBadgeTypes } from "../../Common/UserTypeBadge";
import EndUserCategoryQuery from "../../../graphql/queries/EndUserCategory.gql";
import { useQuery } from "@apollo/client";
const _ = require("lodash");

export const matchesOptions = [
  { value: "BLANK", label: "is blank", types: ["url", "string"] },
  {
    value: "EQUALS",
    label: "equals",
    types: ["url", "string", "number", "date", "currency", "calculated"],
  },
  {
    value: "NOT_EQUALS",
    label: "NOT equals",
    types: ["url", "string", "calculated", "number", "currency"],
  },
  { value: "CONTAINS", label: "contains", types: ["url", "string"] },
  {
    value: "NOT_CONTAINS",
    label: "does not contain",
    types: ["url", "string"],
  },
  { value: "EXISTS", label: "exists", types: ["url", "string"] },
  {
    value: "GT",
    label: "greater than",
    types: ["number", "date", "currency", "calculated"],
  },
  {
    value: "LT",
    label: "less than",
    types: ["number", "date", "currency", "calculated"],
  },
  {
    value: "GTE",
    label: "greater than or equal to",
    types: ["number", "date", "currency", "calculated"],
  },
  {
    value: "LTE",
    label: "less than or equal to",
    types: ["number", "date", "currency", "calculated"],
  },
];

function SegmentSubForm(props) {
  const { values, setFieldValue, field, fieldName } = props;

  return (
    <Flex pb={3} alignItems="flex-start" flexDirection="column">
      <FieldArray
        name={`${fieldName}.andFields`}
        render={(arrayHelpers) => (
          <Box my={2}>
            <Flex alignItems="center">
              <Text variant="muted" mr={2}>
                Data that matches ALL of these conditions
              </Text>
              {(!field?.andFields || field?.andFields?.length === 0) && (
                <PrimaryButton
                  onClick={() =>
                    arrayHelpers.push({
                      field: "",
                      operator: "",
                      value: "",
                    })
                  }
                  leftIcon={<PlusIcon size="16" />}
                >
                  Add
                </PrimaryButton>
              )}
            </Flex>
            {field?.andFields && field.andFields.length > 0
              ? field.andFields.map((row, index) => (
                  <Flex
                    key={index}
                    alignItems="center"
                    justifyContent="center"
                    my={2}
                  >
                    <SegmentItemForm
                      {...props}
                      index={index}
                      fieldName={`${fieldName}.andFields`}
                      fields={field.andFields}
                      setFieldValue={setFieldValue}
                    />
                    <Flex>
                      <PrimaryButton
                        onClick={() =>
                          arrayHelpers.push({
                            field: "",
                            operator: "",
                            value: "",
                          })
                        }
                      >
                        <Box mr={2}>
                          <PlusIcon size="16" />
                        </Box>
                      </PrimaryButton>
                      <Box px={1}></Box>
                      <SecondaryButton
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <Box mr={2}>
                          <MinusIcon size="16" />
                        </Box>
                      </SecondaryButton>
                    </Flex>
                  </Flex>
                ))
              : undefined}
          </Box>
        )}
      />

      <FieldArray
        name={`${fieldName}.orFields`}
        render={(arrayHelpers) => (
          <Box my={2}>
            <Flex alignItems="center">
              <Text fontWeight="bold" variant="muted">
                AND
              </Text>
              <Text ml={1} mr={2} variant="muted">
                matches ANY of these conditions
              </Text>
              {(!field?.orFields || field?.orFields?.length === 0) && (
                <PrimaryButton
                  onClick={() =>
                    arrayHelpers.push({
                      field: "",
                      operator: "",
                      value: "",
                    })
                  }
                  leftIcon={<PlusIcon size="16" />}
                >
                  Add
                </PrimaryButton>
              )}
            </Flex>
            {field?.orFields && field.orFields.length > 0
              ? field.orFields.map((row, index) => (
                  <>
                    <Flex
                      key={index}
                      alignItems="center"
                      justifyContent="center"
                      my={2}
                    >
                      <SegmentItemForm
                        {...props}
                        index={index}
                        fieldName={`${fieldName}.orFields`}
                        fields={field.orFields}
                        setFieldValue={setFieldValue}
                      />
                      <Flex>
                        <PrimaryButton
                          onClick={() =>
                            arrayHelpers.push({
                              field: "",
                              operator: "",
                              value: "",
                            })
                          }
                        >
                          <Box mr={2}>
                            <PlusIcon size="16" />
                          </Box>
                        </PrimaryButton>
                        <Box px={1}></Box>
                        <SecondaryButton
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <Box mr={2}>
                            <MinusIcon size="16" />
                          </Box>
                        </SecondaryButton>
                      </Flex>
                    </Flex>
                  </>
                ))
              : undefined}
          </Box>
        )}
      />
    </Flex>
  );
}

export default SegmentSubForm;

export const GetSegmentOptions = (selectedField) => {
  const { data: currentProduct } = getProduct();
  let dataModels = useDynamicSchemas();
  let userQuery = getUsers();
  let statusQuery = getCompanyStatus();
  let lifecyclesQuery = getLifeCycles();
  let eventGroupsQuery = getEventGroups();
  const { data: userTypes } = useQuery(EndUserCategoryQuery);
  const { data: tagsData } = getTags("tags");
  const eventGroupField = selectedField.modelName === "EventGroup";

  let options = undefined;
  if (selectedField.field === "tagList") {
    const tags = tagsData?.tags;
    options =
      tags?.map((m) => {
        return { value: m, label: m };
      }) || undefined;
  } else if (selectedField.field === "endUserCategoryId") {
    options =
      (userTypes &&
        userTypes?.endUserCategories?.map((m) => {
          return { value: m.value.toString(), label: m.label };
        })) ||
      undefined;
  } else if (selectedField.field === "lifeCycleStageId") {
    options =
      (lifecyclesQuery &&
        lifecyclesQuery.data &&
        lifecyclesQuery.data.lifeCycles[0].stages?.map((m) => {
          return { value: m.id, label: m.name };
        })) ||
      undefined;
  } else if (selectedField.field === "statusId") {
    options =
      (statusQuery &&
        statusQuery.data &&
        statusQuery.data.companyStatuses?.map((m) => {
          return { value: m.id, label: m.name };
        })) ||
      undefined;
  } else if (selectedField.field === "scoreType") {
    options = [
      { value: "nps", label: "NPS" },
      { value: "ces", label: "CES" },
      { value: "csat", label: "CSAT" },
      { value: "sentiment", label: "Sentiment" },
    ];
  } else if (selectedField.field === "ownerUserId") {
    options =
      userQuery &&
      userQuery.data &&
      userQuery.data.users?.map((m) => {
        return { value: m.id, label: m.name };
      });
  } else if (selectedField.field === "healthCategory") {
    options = [
      { value: "good", label: "Good" },
      { value: "ok", label: "Ok" },
      { value: "poor", label: "Poor" },
    ];
  } else if (!!eventGroupField) {
    options =
      eventGroupsQuery &&
      eventGroupsQuery.data &&
      eventGroupsQuery.data.eventGroups?.map((m) => {
        return { value: m.name, label: m.name };
      });
  }

  options = options && [{ value: "", label: "is blank" }].concat(options);

  return options;
};
export function SegmentItemForm(props) {
  const selectedField = props.fields && props.fields[props.index];
  const { data: currentProduct } = getProduct();
  let dataModels = useDynamicSchemas();

  dataModels = dataModels?.filter(
    (m) =>
      m.typeName !== MODEL_TYPES.Comment.name &&
      m.typeName !== MODEL_TYPES.Product.name
  );

  const fields =
    _.flatten(
      dataModels?.map((model) =>
        model.attributes
          .filter(
            (a) =>
              a.productId === currentProduct?.currentProduct?.id || !a.productId
          )
          .map((m) => {
            return {
              value: `${m.name}-${model.id}`,
              label: (
                <Flex alignItems="center">
                  <Text mx={2}>{ModelTypeIcon(model.typeName, 16)}</Text>
                  {m.displayName}
                </Flex>
              ),
              field: m.name,
              type:
                m.calculatedType && m.calculatedType === "exists"
                  ? "boolean"
                  : m.type,
              modelId: model.id,
              modelName: m.modelName,
            };
          })
      )
    ) || [];

  const fieldDetails = fields.find(
    (o) =>
      o.modelId === selectedField?.entity && o.field === selectedField?.field
  );
  selectedField.type = fieldDetails?.type;
  selectedField.modelName = fieldDetails?.modelName;

  let options = GetSegmentOptions(selectedField);

  const showOperators = !options && selectedField.type !== "boolean";
  const showTextField =
    selectedField?.operator !== "EXISTS" && selectedField?.operator !== "BLANK";

  const HelperText = ({ field, ...rest }) => {
    let helperText = field?.type === "date" ? "days" : "";
    return (
      <Text variant="label" mx={1}>
        {helperText}
      </Text>
    );
  };
  const getValueType = (fieldType) => {
    let type = undefined;
    switch (fieldType) {
      case "list":
        type = "list";
        break;
      case "boolean":
        type = "checkbox";
        break;
      default:
        type = "string";
        break;
    }
    return type;
  };

  return (
    <Flex width="100%" alignItems="center">
      <CustomFormGroup
        type="list"
        label=""
        width={225}
        noMargin
        name={`${props.fieldName}[${props.index}].field`}
        value={
          fields.find(
            (o) =>
              o.field === selectedField?.field &&
              o.modelId === selectedField?.entity
          )?.value
        }
        onChange={(name, value) => {
          props.setFieldValue(`${props.fieldName}[${props.index}]`, {
            entity: value?.modelId,
            field: value?.field,
            value: "",
            operator: selectedField.operator,
          });
        }}
        options={fields}
      />
      <Box pr={1} />

      {showOperators && (
        <CustomFormGroup
          noMargin
          type="list"
          width={225}
          name={`${props.fieldName}[${props.index}].operator`}
          value={selectedField?.operator}
          onChange={(name, value) => {
            props.setFieldValue(name, value?.value);
          }}
          options={matchesOptions.filter((o) =>
            o.types.includes(selectedField.type)
          )}
        />
      )}
      <Box pr={1} />

      <Flex alignItems="flex-end">
        {options ? (
          <CustomFormGroup
            noMargin
            key={`${props.fieldName}[${props.index}].field-options`}
            type={"list"}
            options={options}
            width={200}
            name={`${props.fieldName}[${props.index}].value`}
            value={selectedField.value}
            onChange={(name, value) => {
              props.setFieldValue(name, value.value);
            }}
          />
        ) : (
          showTextField && (
            <Flex alignItems="center">
              <>
                <CustomFormGroup
                  noMargin
                  type={getValueType(selectedField?.type)}
                  width={selectedField?.type === "string" ? 250 : 120}
                  name={`${props.fieldName}[${props.index}].value`}
                  value={selectedField.value}
                  onChange={(name, value) =>
                    props.setFieldValue(name, value.toString())
                  }
                />
                <HelperText
                  field={fields.find((o) => o.value === selectedField?.field)}
                />
              </>
              <>
                {selectedField?.type === "date" && (
                  <>
                    <Box mr={1} />
                    <CustomFormGroup
                      noMargin
                      label=""
                      type="list"
                      options={dateOffsetOptions}
                      width={190}
                      name={`${props.fieldName}[${props.index}].dateOffset`}
                      value={selectedField.dateOffset}
                      onBlur={props.handleBlur}
                      onChange={(name, value) => {
                        props.setFieldValue(name, value?.value);
                      }}
                    />
                  </>
                )}
              </>
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  );
}
