import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Flex, Box, Text, Button } from "rebass";
import {
  UserIcon,
  AutomationIcon,
  EventIcon,
  HealthIcon,
} from "Components/Common/Icons";
import { RiArticleLine, RiDatabase2Line } from "react-icons/ri";
import { getEndUserData } from "hooks/getEndUserData";
import { getProduct } from "hooks/getProduct";
import { MODEL_TYPES } from "consts";
import Loader from "Components/Common/Loader";
import EndUserPage from ".";
import DataEmpty from "../DataEmpty";
import EntityDetail from "../EntityDetail";
import MenuButton from "../MenuButton";
import { EditIcon, SummaryIcon } from "../../Common/Icons";
import { useUrlParams } from "../../../hooks/useUrlParams";
import EntityAdminMenu from "../EntityAdminMenu";
import EntityDetailMenu from "../EntityDetailMenu";
import ExternalId from "../ExternalId";
import UserTypeBadge from "../../Common/UserTypeBadge";
import Avatar from "react-avatar";

const tabs = [
  {
    label: "Summary",
    icon: <SummaryIcon size={16} />,
  },
  {
    label: "Health",
    icon: <HealthIcon size={16} />,
  },
  {
    label: "Automation",
    icon: <AutomationIcon size={16} />,
  },
  { label: "Profile", icon: <RiDatabase2Line size={16} /> },
  {
    label: "Events",
    icon: <EventIcon size={16} />,
  },
];
function EndUsers(props) {
  const { id, companyId } = useParams();
  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const defaultTab = getUrlParameter("tab") || "Summary";

  const isNew = id === "new";

  const [selectedTab, setSelectedTab] = useState(
    tabs.indexOf(
      tabs.find((t) => t.label.toLowerCase() === defaultTab.toLowerCase())
    )
  );
  const { loading, data } = getEndUserData(id, companyId);

  if (loading) {
    return (
      <EndUserPage {...props}>
        <Loader />
      </EndUserPage>
    );
  }

  const externalId = data?.endUser?.externalId;
  return (
    <EndUserPage {...props}>
      <Flex flex={1} mt={3} flexDirection="column">
        <Flex p={2}>
          <Flex>
            <Flex
              flexDirection="column"
              color="primary"
              mx={2}
              sx={{
                width: "270px",
              }}
            >
              <Flex justifyContent="flex-start">
                <Box color="brand" mr={2}>
                  <Avatar
                    size={40}
                    round
                    name={`${data?.endUser?.firstName || ""} ${
                      data?.endUser?.lastName || ""
                    }`}
                    email={data?.endUser?.email}
                  />
                </Box>
                <Flex flexDirection="column">
                  <Text variant="h2" px={1} sx={{ wordBreak: "break-word" }}>
                    {data?.endUser?.firstName} {data?.endUser?.lastName}
                  </Text>
                  <ExternalId value={externalId} />
                </Flex>
              </Flex>
            </Flex>
            <EntityDetailMenu
              id={id}
              parentType={MODEL_TYPES.EndUser.name}
              data={data?.endUser}
              onEdit={() => {
                setSelectedTab(
                  tabs.indexOf(tabs.find((t) => t.label == "Profile"))
                );
                window.history.replaceState(
                  {},
                  document.title,
                  `${window.location.pathname}?tab=Profile`
                );
              }}
            ></EntityDetailMenu>
          </Flex>

          <Flex flex={1} alignItems="flex-start">
            <Box>
              {tabs.map((tab, index) => (
                <MenuButton
                  key={tab.label}
                  label={tab.label}
                  icon={tab.icon}
                  onClick={() => {
                    setSelectedTab(index);
                    window.history.replaceState(
                      {},
                      document.title,
                      `${window.location.pathname}?tab=${tab.label}`
                    );
                  }}
                  selected={selectedTab === index}
                />
              ))}
            </Box>
            <Flex flex={1} justifyContent="flex-end">
              <Flex mx={1}>
                <EntityAdminMenu
                  id={id}
                  parentType={MODEL_TYPES.EndUser.name}
                  companyId={companyId}
                  onDelete={() => {}}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} width="100%">
          {!isNew && data?.endUser && (
            <EntityDetail
              id={id}
              companyId={companyId}
              parentType={MODEL_TYPES.EndUser.name}
              selected={selectedTab}
              onSelect={(i) => setSelectedTab(i)}
            />
          )}
          {isNew && <DataEmpty />}
        </Flex>
      </Flex>
    </EndUserPage>
  );
}

export default withRouter(EndUsers);
