import React from "react";
import { Flex, Text } from "rebass";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";

function StepThree(props) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      width="100%"
      sx={{
        minWidth: 350,
      }}
    >
      <Text as="h4" variant="h3">
        Confirmation
      </Text>
      You are subscribing to {props.data?.name} for{" "}
      {props.data?.price ? props.data?.price / 100 : 0}/month
      <Flex mt={2} justifyContent="space-between">
        <SecondaryButton onClick={() => props.goToStep(1)}>
          Back
        </SecondaryButton>
        <PrimaryButton onClick={props.onUpdate}>Confirm Payment</PrimaryButton>
      </Flex>
    </Flex>
  );
}

export default StepThree;
