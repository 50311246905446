import React, { useState } from "react";
import { Box, Link, Flex, Button, Text } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  CloseIcon,
  ImportIcon,
  IntegrationIcon,
  SearchIcon,
} from "../../../Common/Icons";
import { getIntegrationDefinitions } from "../../../../hooks/getIntegrationDefinitions";
import CustomFormGroup from "../../../Common/Forms/CustomFormGroup";
import { Input } from "@rebass/forms";

function SelectAppStep(props) {
  const definitions = getIntegrationDefinitions();
  const [searchText, setSearchText] = useState();

  const apps = definitions?.data?.integrationDefinitions
    .filter((d) => d.state == "ENABLED")
    .filter(
      (d) =>
        !searchText ||
        searchText == "" ||
        d.name.toLowerCase().includes(searchText.toLowerCase())
    );
  return (
    <Box mx={3} mt={3}>
      <Flex sx={{ position: "relative" }} alignItems="center" mx={3}>
        <Input
          bg="white"
          sx={{
            zIndex: 1,
          }}
          placeholder=""
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Flex
          sx={{
            position: "absolute",
            right: 4,
            top: 0,
            bottom: 0,
            zIndex: 2,
          }}
          width={35}
          alignItems="center"
          justifyContent="center"
          color="line"
          p={2}
          onClick={() => {
            setSearchText("");
          }}
        >
          <CloseIcon size={20} />
        </Flex>
        <Flex
          sx={{
            position: "absolute",
            right: 1,
            top: 0,
            bottom: 0,
            zIndex: 2,
          }}
          width={35}
          alignItems="center"
          justifyContent="center"
          color="brandLight"
          p={2}
        >
          <SearchIcon size={20} />
        </Flex>
      </Flex>
      <Flex flexWrap={"wrap"} flex={1} minWidth={600} mt={3}>
        {apps?.length == 0 && (
          <Flex p={4} alignItems="center" justifyContent={"center"}>
            <Text>No apps found</Text>
          </Flex>
        )}
        {apps?.map((d) => (
          <Flex
            onClick={() => {
              props.onSelect(d);
              props.nextStep();
            }}
            key={d.id}
            variant="box"
            p={2}
            m={2}
            width={200}
            flexDirection="column"
            sx={{
              cursor: "pointer",
              backgroundColor: d.state != "ENABLED" && "gray.200",
              "&:hover": {
                borderColor: d.state == "ENABLED" && "brandLight",
                backgroundColor: d.state == "ENABLED" && "brandHighlight",
              },
            }}
          >
            <Flex>
              <IntegrationIcon logoUrl={d.icon} />
              <Box mx={2}>
                <Text>{d.name}</Text>

                <Text fontSize={"xxs"}>{d.website}</Text>
              </Box>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}

export default SelectAppStep;
