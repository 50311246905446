import React, { useState, useEffect } from "react";
import { Input } from "@rebass/forms";
import { Text, Flex, Box, Link } from "rebass";
import { CloseIcon, PlusIcon } from "../Icons";
import "./tags.css";
import { getTags } from "../../../hooks/getTags";
import AssignTagMutation from "../../../graphql/mutations/AssignTags.gql";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

TagsEditor.propTypes = {
  parentType: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

const _ = require("lodash");

export default function TagsEditor({ id, parentType, value, label, onChange }) {
  const [editMode, setEditMode] = useState(false);
  const { data } = getTags(
    parentType == "Company" || parentType == "EndUser" ? "tags" : "categories"
  );
  const [tags, setTags] = useState(value || []);
  const [tag, setTag] = useState(undefined);
  const [save, { loading }] = useMutation(AssignTagMutation);

  const [showList, setShowList] = useState(true);

  useEffect(() => {
    if (value && tags.length !== value.length) {
      setTags(value);
    }
  }, [value]);

  useEffect(() => {
    if (!_.isEqual((value || []).sort(), (tags || []).sort())) {
      if (onChange !== undefined) {
        onChange(tags);
      } else {
        assignTag(tags);
      }
    }
  }, [tags]);

  const referenceTags = _.difference(data?.tags || [], tags);

  const assignTag = (tags) => {
    save({
      variables: {
        ids: id,
        parentType: parentType,
        tagList: tags,
        delete: false,
        replace: true,
      },
    }).then(
      (response) => {
        if (!response.data?.assignTags?.errors) {
        } else {
          addToast(<Flex>{response.data?.assignTags?.errors[0]}</Flex>, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flexDirection="column" justifyContent="center">
      <Text variant="label">{label}</Text>
      <Flex flexWrap="wrap" alignItems="center" justfiyContent="center">
        {tags.map((t) => (
          <Flex
            height={30}
            key={t}
            bg="brandHighlight"
            mx={1}
            mt={2}
            px={1}
            sx={{ borderRadius: 10 }}
            alignItems="center"
            justfiyContent="center"
          >
            <Flex alignItems="center" justfiyContent="space-between" pl={1}>
              <Flex alignItems="center">
                <Text mx={1} color="brandLight" variant="muted">
                  {t}
                </Text>
              </Flex>
              <Flex
                width={30}
                alignItems="center"
                justifyContent="center"
                color="line"
                onClick={() => {
                  setTags(
                    _.reject(tags, (o) => {
                      return o === t;
                    })
                  );
                  setEditMode(undefined);
                }}
              >
                <CloseIcon size={20} />
              </Flex>
            </Flex>
          </Flex>
        ))}
        {editMode && (
          <Flex flexDirection={"column"} sx={{ position: "relative" }}>
            <Flex alignItems={"center"}>
              <Flex
                pl={2}
                pt={2}
                alignItems="center"
                sx={{ position: "relative", width: editMode ? 200 : 0 }}
              >
                <Input
                  bg="white"
                  sx={{
                    zIndex: 1,
                  }}
                  placeholder=""
                  value={tag}
                  onChange={(e) => {
                    setTag(_.trimStart(e.target.value));
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13 || e.key === "Enter") {
                      if (tag !== "") {
                        setTags(_.union(tags, [tag]));
                      }
                      setTag("");
                      setEditMode(false);
                    }
                  }}
                />
                <Flex
                  sx={{
                    position: "absolute",
                    right: 1,
                    top: 1,
                    bottom: 0,
                    zIndex: 2,
                  }}
                  width={35}
                  alignItems="center"
                  justifyContent="center"
                  color="line"
                  p={2}
                  onClick={() => {
                    setTag("");
                    setEditMode(false);
                  }}
                >
                  <CloseIcon size={20} />
                </Flex>
              </Flex>
            </Flex>
            {showList && (
              <Flex mx={2} bg="white">
                <Flex
                  flexDirection="column"
                  bg="white"
                  variant="box"
                  sx={{
                    position: "absolute",
                    top: 45,
                    overflowY: "scroll",
                    maxHeight: 150,
                    zIndex: 99,
                  }}
                >
                  {tag !== undefined && tag !== "" && tag !== " " && (
                    <Text
                      bg="white"
                      width={150}
                      py={1}
                      px={2}
                      sx={{
                        cursor: "pointer",
                        wordBreak: "keep-all",
                        "&:hover": {
                          backgroundColor: "brandHighlight",
                        },
                      }}
                      onClick={() => {
                        if (tag !== "") {
                          setTags(_.union(tags, [tag]));
                          setTag("");
                          setEditMode(false);
                        }
                      }}
                    >
                      Create
                      <Link mx={2} sx={{ cursor: "pointer" }}>
                        {tag}
                      </Link>
                    </Text>
                  )}
                  {referenceTags
                    .filter(
                      (t) =>
                        tag == undefined ||
                        tag === "" ||
                        tag === " " ||
                        _.startsWith(t, tag)
                    )
                    .map((t) => (
                      <Text
                        bg="white"
                        width={150}
                        key={t}
                        py={1}
                        px={2}
                        sx={{
                          cursor: "pointer",
                          wordBreak: "keep-all",
                          "&:hover": {
                            backgroundColor: "brandHighlight",
                          },
                        }}
                        onClick={() => {
                          setTags(_.union(tags, [t]));
                          setTag("");
                          setEditMode(false);
                        }}
                      >
                        {t}
                      </Text>
                    ))}
                </Flex>
              </Flex>
            )}
          </Flex>
        )}

        {!editMode && (
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="brandLight"
            color="white"
            mx={1}
            mt={2}
            onClick={() => {
              setEditMode(true);
            }}
            sx={{
              borderRadius: 999,
              width: 30,
              height: 30,
              cursor: "pointer",
              "&:hover": { backgroundColor: "brand" },
            }}
          >
            <PlusIcon />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
