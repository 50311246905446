import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Flex, Button, Text } from "rebass";

function MenuButton({ label, icon, onClick, selected }) {
  const history = useHistory();
  return (
    <Button
      className={label}
      variant="tab"
      px={2}
      py={1}
      mx={2}
      key={label}
      onClick={onClick}
      sx={{
        borderRadius: 0,
        borderBottomColor: "brandLight",
        borderBottomWidth: selected ? 2 : 0,
        borderBottomStyle: "solid",
        background: "transparent",
      }}
    >
      <Flex alignItems="center">
        <Text color={selected && "brandLight"}>{icon}</Text>
        <Text fontSize="sm" mx={1} pb={1}>
          {label}
        </Text>
      </Flex>
    </Button>
  );
}

export default withRouter(MenuButton);
