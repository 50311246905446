import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Label } from "@rebass/forms";
import _uniqueId from "lodash/uniqueId";
import { Box, Flex, Text } from "rebass";
import Switch from "react-switch";
import { useTheme } from "emotion-theming";

const CheckboxGroupSwitch = ({
  title,
  labelSx,
  options = [],
  selectedOptions,
  onChange,
  ...otherProps
}) => {
  const theme = useTheme();
  // useMemo ensures the groupId prop doesn't needlessly change on re-render
  const groupId = useMemo(() => _uniqueId("checkbox-group-"), []);

  return (
    <Flex
      role="checkboxgroup"
      aria-labelledby={groupId}
      {...otherProps}
      flexWrap="wrap"
    >
      {options.map(({ label, value, cyName }, index) => (
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          my={1}
        >
          <Text variant="muted" mx={1} color="body">
            {label}
          </Text>
          <Switch
            name={cyName}
            checked={selectedOptions.find((o) => o === value)}
            onChange={() => onChange(cyName, value)}
            offColor={theme.colors.gray[200]}
            offHandleColor={theme.colors.gray[500]}
            onColor={theme.colors.brandLight}
            onHandleColor={theme.colors.white}
            height={20}
            width={40}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </Flex>
      ))}
    </Flex>
  );
};

CheckboxGroupSwitch.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.string,
    })
  ),
  selectedOption: PropTypes.string,
  title: PropTypes.string,
  labelSx: PropTypes.object,
};

export default CheckboxGroupSwitch;
