import React from "react";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { Flex, Text, Box } from "rebass";
import "react-tabs/style/react-tabs.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { useMutation } from "@apollo/react-hooks";
import { getHealthscore } from "hooks/getHealthscore";
import Loader from "Components/Common/Loader";
import HealthScoreSlider from "./HealthScoreSlider";
import HealthScoreInputSlider from "./HealthScoreInputSlider";
import SatisfactionInputSlider from "./SatisfactionInputSlider";
import FactorForm from "./FactorForm";
import HealthscoreMutation from "../../../../graphql/mutations/Healthscore.gql";
import HealthscoreDeleteMutation from "../../../../graphql/mutations/HealthscoreDelete.gql";
import CustomFormGroup from "../../../Common/Forms/CustomFormGroup";
import { getSegments, getCoreSegments } from "hooks/getSegments";
import HealthscoresQuery from "../../../../graphql/queries/Healthscores.gql";
import { MODEL_TYPES } from "../../../../consts";
import DeleteButton from "../Common/DeleteButton";

function HealthScoreForm(props) {
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const { data, loading, refetch } = getHealthscore(id);
  const [save] = useMutation(HealthscoreMutation, {
    refetchQueries: [
      {
        query: HealthscoresQuery,
        fetchPolicy: "network-only",
      },
    ],
  });
  const { data: segmentData } = getSegments(MODEL_TYPES.Company.name);
  const { data: segmentCoreData } = getCoreSegments();
  const [destroy] = useMutation(HealthscoreDeleteMutation, {
    refetchQueries: [
      {
        query: HealthscoresQuery,
        fetchPolicy: "network-only",
      },
    ],
  });
  if (loading) return <Loader />;

  let segmentOptions = [];

  segmentCoreData?.coreSegments
    ?.filter((r) => r.name == "Paid")
    .forEach((r) => {
      segmentOptions.push({ value: r.id, label: r.name });
    });
  segmentData?.segments?.forEach((r) => {
    segmentOptions.push({ value: r.id, label: r.name });
  });

  const healthscore = {
    ...data?.healthscore,
    segmentId: data?.healthscore?.segment?.id,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        data?.healthscore
          ? healthscore
          : {
              name: "Default",
              config: {
                healthScoreSplit: [30, 70],
                healthScoreInputSplit: [50, 75],
                productFactors: [],
              },
            }
      }
      autoComplete={false}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        config: Yup.object().shape({
          healthScoreSplit: Yup.array().required("Required"),
          healthScoreInputSplit: Yup.array().required("Required"),
          productFactors: Yup.array().required("Required"),
        }),
      })}
      onSubmit={(values, actions) => {
        save({
          variables: {
            id: values.id,
            name: values.name,
            segmentId: values.segmentId,
            config: {
              ...values.config,
              productTotal: values.config.healthScoreInputSplit[0],
              satisfactionTotal:
                values.config.healthScoreInputSplit[1] -
                values.config.healthScoreInputSplit[0],
              sentimentTotal: 100 - values.config.healthScoreInputSplit[1],
            },
          },
        }).then(
          (response) => {
            if (response.data?.healthscore?.errors) {
              addToast(
                <Flex>{response.data?.healthscore?.errors[0]}</Flex>,
                ErrorParams
              );
            } else {
              actions.resetForm({
                values: response.data?.healthscore?.healthscore,
              });
              addToast(<Flex>Saved!</Flex>, SuccessParams);
              props.onComplete && props.onComplete();
              history.push("/settings/product/healthscores");
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }}
    >
      {(formProps) => {
        const {
          values,
          errors,
          setFieldValue,
          dirty,
          handleSubmit,
          handleBlur,
        } = formProps;

        return (
          <Flex width="100%" flexDirection="column" bg="white">
            <Prompt
              when={dirty}
              message="You have unsaved changes. Are you sure you want to leave?"
            />
            <Flex>
              <Flex flex={1} flexDirection="column">
                <Flex justifyContent="space-between">
                  <Box>
                    <Text variant="h2">Account Health Score</Text>
                  </Box>
                  <Box>
                    <DeleteButton
                      callback={destroy}
                      onCancel={() => history.goBack()}
                      refetch={() =>
                        history.push("/settings/product/healthscores")
                      }
                      id={id}
                      name="Healthscore"
                    />
                    <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                  </Box>
                </Flex>
                <Flex>
                  <Text>
                    A health score is a calculated number that measures the
                    current status of your relationship with your account.
                  </Text>
                </Flex>
                <Flex>
                  <CustomFormGroup
                    placeholder="Name"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.name && errors.name}
                  />
                  <Box mx={2} />
                  <CustomFormGroup
                    type="list"
                    placeholder="Segment"
                    label="Segment"
                    name="segmentId"
                    options={segmentOptions}
                    value={values.segmentId}
                    onChange={(name, value) => {
                      setFieldValue(name, value?.value);
                    }}
                    onBlur={handleBlur}
                    helperText={errors.segmentId && errors.segmentId}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Box mt={2}>
              <Text variant="h3">Account Health Score Ratings</Text>
              <Text>
                Your Account Health Score will be a value out of 100. Configure
                how we should interpret healthy, ok and and risk accounts.
              </Text>
            </Box>
            <Box px={4} pt={2}>
              <HealthScoreSlider
                value={values.config?.healthScoreSplit || [30, 70]}
                domain={[0, 100]}
                onChange={(values) =>
                  setFieldValue("config.healthScoreSplit", values)
                }
              />
            </Box>
            <Box mt={4}>
              <Text variant="h3">Account Health Score Inputs</Text>
              <Text>
                Select the data sources for your health scoring, and adjust
                their maximum contribution towards the total score.
              </Text>
            </Box>

            <Box px={4} pt={2}>
              <HealthScoreInputSlider
                value={values.config?.healthScoreInputSplit || [50, 75]}
                domain={[0, 100]}
                onChange={(values) =>
                  setFieldValue("config.healthScoreInputSplit", values)
                }
              />
            </Box>
            <Box mt={4}>
              <Text variant="h3">Satisfaction Breakdown</Text>
              <Text>
                Select the weighting for the methods your product uses to guage
                satisfaction.
              </Text>
            </Box>

            <Box px={4} pt={2}>
              <SatisfactionInputSlider
                value={values.config?.satisfactionInputSplit || [40, 70]}
                domain={[0, 100]}
                onChange={(values) =>
                  setFieldValue("config.satisfactionInputSplit", values)
                }
              />
            </Box>
            <Box mt={4}>
              <Text variant="h3">Product Usage and Adoption Configuration</Text>
              <Text>Configure each health score input as required.</Text>
            </Box>
            <Flex width="100%" flexDirection="column" my={2}>
              <FactorForm {...formProps} />
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default HealthScoreForm;
