import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text } from "rebass";
import Collapsible from "react-collapsible";
import { ArrowDropDownIcon } from "../Icons";

function CollapsibleGroup(props) {
  const theme = useTheme();
  return props.options?.map((item, i) => (
    <Collapsible
      key={`item-${i}`}
      accordionPosition={i}
      onTriggerOpening={() => props.setSelected(i)}
      open={props.selected === i}
      trigger={
        <Flex
          key={`item2-${i}`}
          mx={2}
          p={2}
          my={props.selected === i ? 0 : 2}
          bg={"gray.100"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="label">{item.title}</Text>
          <ArrowDropDownIcon size={20} />
        </Flex>
      }
    >
      <Flex variant="box" mx={2}>
        <Flex p={2} flex={1}>
          {item.content}
        </Flex>
      </Flex>
    </Collapsible>
  ));
}

export default CollapsibleGroup;
