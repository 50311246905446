import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
import { useTheme } from "emotion-theming";
import moment from "moment";
import Avatar from "react-avatar";
import numeral from "numeral";
import ReactTooltip from "react-tooltip";

function CustomBarGraph({ data, loading, onClick, height, total }) {
  const theme = useTheme();

  const totalDefault = total || 0;
  return (
    <Flex flex={1} alignItems={"flex-start"}>
      <Flex alignItems={"flex-start"}>
        <Flex flex={1} alignItems="flex-start" flexDirection="column">
          {!loading &&
            data.map((m) => {
              const maxCalc = 100 / ((data[0].total / totalDefault) * height);

              return (
                <Flex
                  key={m.id}
                  mx={3}
                  my={2}
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ cursor: onClick && "pointer" }}
                  onClick={() =>
                    onClick &&
                    onClick({
                      field: "userId",
                      id: m.id,
                      label: m.name,
                    })
                  }
                >
                  <Flex
                    flexDirection="column"
                    sx={{ cursor: onClick && "pointer" }}
                    onClick={() =>
                      onClick &&
                      onClick({
                        field: "userId",
                        id: m.id,
                        label: m.name,
                      })
                    }
                    width={100}
                  >
                    <Flex alignItems="left" justifyContent="flex-start">
                      <Box mx={2}>
                        <Text fontSize="xs">{m.name}</Text>
                        <Text variant="muted" fontWeight="bold" fontSize="xs">
                          ${numeral(m.totalValue).format("0,0")}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex justifyContent="flex-start" alignItems={"flex-start"}>
                    {m.data.map((d, i) => {
                      return (
                        <Box
                          key={i}
                          data-for={`${d.color}-${d.name}`}
                          data-tip={`$${numeral(d.value).format("0,0")} (${
                            d.count
                          })`}
                        >
                          <ReactTooltip
                            id={`${d.color}-${d.name}`}
                            place="top"
                            effect="solid"
                            getContent={(dataTip) => dataTip}
                          />
                          <Flex
                            width={(d.count / totalDefault) * height * maxCalc}
                            height={30}
                            bg={d.color}
                          ></Flex>
                        </Box>
                      );
                    })}
                  </Flex>
                  <Text variant="muted" mx={2}>
                    {m.total}
                  </Text>
                </Flex>
              );
            })}
        </Flex>
      </Flex>
      <Flex ml={4} flexDirection={"column"}>
        <Flex
          flex={1}
          height="100%"
          alignItems={"flex-start"}
          flexDirection={"column"}
        >
          <Flex ml={3} flex={1} flexDirection={"column"}>
            {data &&
              data[0]?.data.map((d) => (
                <Flex key={d.label} my={1} alignItems="center">
                  <Box
                    m={1}
                    sx={{ width: 10, height: 10, borderRadius: 10 }}
                    bg={d.color}
                  />
                  <Text mx={1} variant="muted" fontSize="xxs">
                    {d.name}
                  </Text>
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CustomBarGraph;
