import React, { useState } from "react";
import { Flex, Box, Button, Link } from "rebass";
import { Formik } from "formik";
import Modal from "Components/Common/Modal";
import { getSegment } from "hooks/getSegment";
import Select from "Components/Common/Forms/Select";
import SegmentForm from "./SegmentForm";
import { getSegments } from "../../../hooks/getSegments";

const _ = require("lodash");
function SegmentSelect(props) {
  const [open, setOpen] = useState(false);
  const { data: segmentData } = getSegments(props.typeName);

  const segmentOptions =
    segmentData?.segments?.map((r) => {
      return { value: r.id, label: r.name };
    }) || [];
  return (
    <Flex alignItems={"center"}>
      <Box>
        <Select
          placeholder="Segment"
          width={250}
          name="segmentId"
          value={segmentOptions.find((obj) => obj.value === props.segmentId)}
          onChange={(name, value) => {
            props.onChange(name, value?.value);
          }}
          options={segmentOptions}
        />
      </Box>
      <Link sx={{ cursor: "pointer" }} onClick={() => setOpen(true)} mx={2}>
        Create New Segment
      </Link>
      <ShowSegmentModal
        typeName={props.typeName}
        segmentId={props.segmentId}
        open={open}
        onClose={() => setOpen(false)}
      />
    </Flex>
  );
}
const ShowSegmentModal = ({ open, onClose, typeName, segmentId }) => {
  return (
    <SegmentForm
      key={segmentId}
      open={open}
      typeName={typeName}
      onClose={onClose}
    />
  );
};

export default SegmentSelect;
