import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "rebass";
import Avatar from "react-avatar";
import PropTypes from "prop-types";

const UserBadge = (props) => {
  const { name, avatarUrl, subtitle, height, secondary, email } = props;
  return (
    <Flex alignItems="center">
      <Box width={40}>
        <Avatar
          size={height || 40}
          round
          src={avatarUrl}
          name={name}
          email={email}
        />
      </Box>
      <Box ml={2} textAlign="start">
        <Text fontWeight="bold" color={secondary ? "brandLight" : "brand"}>
          {name}
        </Text>
        <Text mt={1} variant="label" fontSize="xxs">
          {subtitle}
        </Text>
      </Box>
    </Flex>
  );
};
UserBadge.propTypes = {
  avatarUrl: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  height: PropTypes.number,
};

export default UserBadge;
