import React from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Box, Flex, Image, Text } from "rebass";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import Settings from "../ProductSettings";
import PlanForm from "./PlanForm";
import PlanList from "./PlanList";
import { PlusIcon, TickIcon } from "../../../Common/Icons";

function Plan(props) {
  const { id } = useParams();
  return (
    <Settings>
      <Flex width="100%">
        <Flex
          p={2}
          bg="gray.100"
          sx={{
            height: "100%",
            maxWidth: "300px",
          }}
          flex={1}
        >
          <PlanList />
        </Flex>
        <Flex flex={1} p={4} bg="white">
          {id && <PlanForm {...props} />}
          {!id && <EmptyState />}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(Plan);

const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex p={5} justifyContent="center">
      <Flex flex={1} alignItems="flex-start" flexDirection="column">
        <Text variant="h2">Journeys</Text>
        <Text my={3}>
          Create a journey per product tier to enable you to track the progress
          each account is through their journey in your product.
        </Text>
        <Text my={3}>To set up your plans you will need to:</Text>
        <Box mx={1}>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Check your Status &amp; Lifecycle settings
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Set up your core Playbooks
          </Flex>
        </Box>
        <Flex my={3} justifyContent="space-between">
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/plans/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Journey
          </PrimaryButton>
          <Box mx={3} />
          <SecondaryButton
            onClick={() => {
              history.push("/settings/product/playbooks/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Playbook
          </SecondaryButton>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ marginTop: "-10%", height: "150px", objectFit: "contain" }}
        />
      </Flex>
    </Flex>
  );
};
