import React, { useState } from "react";
import { Box, Flex, Image, Text } from "rebass";
import { MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import Table from "../Common/Table";
import PrimaryButton from "../Common/Buttons/PrimaryButton";
import { EditIcon, PlusIcon, TrashIcon } from "../Common/Icons";
import SecondaryButton from "../Common/Buttons/SecondaryButton";
import ExternalIdForm from "./Company/ExternalIdForm";
import GhostButton from "../Common/Buttons/GhostButton";

function ExternalIds(props) {
  const { loading, data, refetch } = getData(
    MODEL_TYPES[props.parentType],
    props.id
  );
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const dataTable = data
    ? data[MODEL_TYPES[props.parentType].singular]?.externalIds || []
    : [];
  const columns = [
    {
      name: "Source",
      selector: (row) => row.sourceType,
      width: "120px",
      sortable: true,
      cell: (row) => {
        return (
          <Flex flex={1} flexDirection="column">
            <Box>
              {row.sourceType}
              <Text variant="muted">{row.name}</Text>
            </Box>
          </Flex>
        );
      },
    },
    {
      name: "External Id",
      selector: (row) => row.externalId,
      sortable: true,
      cell: (row) => {
        return (
          <Flex flex={1} flexDirection="column">
            {row.externalId}
          </Flex>
        );
      },
    },
    {
      name: "",
      width: "80px",
      cell: (row) => {
        return (
          <GhostButton
            onClick={() => {
              setSelectedItem(row);
              setOpen(true);
            }}
          >
            <TrashIcon />
          </GhostButton>
        );
      },
    },
  ];
  return (
    <Flex flex={1} flexDirection="column">
      <Flex my={2} justifyContent="space-between" alignItems="center">
        <Text variant="h3">API Mapping</Text>
        <PrimaryButton
          leftIcon={<PlusIcon />}
          onClick={() => {
            setSelectedItem({});
            setOpen(true);
          }}
        >
          Add
        </PrimaryButton>
      </Flex>
      <Box bg="gray.100" p={3} mb={2}>
        <Text variant="muted">
          WARNING: These are set through the APIs. Only update if required.
          Changing an ID can impact already connected APIs.
        </Text>
      </Box>
      <Flex flexDirection="column">
        <Table
          title={""}
          defaultSortField={"name"}
          selectableRows={false}
          columns={columns}
          data={dataTable}
          loading={loading}
        />
        <ExternalIdForm
          key={selectedItem?.id}
          item={selectedItem}
          id={props.id}
          typeName={props.parentType}
          open={open}
          onDismiss={() => setOpen(false)}
          onSave={() => {
            setOpen(false);
            refetch();
          }}
        />
      </Flex>
    </Flex>
  );
}

export default ExternalIds;
