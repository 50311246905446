import React from "react";
import { Box, Link, Flex, Button, Text } from "rebass";
import Plan from "./Plan";

function StepOne(props) {
  const handleSubmit = (values) => {
    props.onUpdate(values);
    if (!values.price || values.price === 0) {
      props.goToStep(3);
    } else {
      props.nextStep();
    }
  };
  return (
    <Box flexDirection="column" noValidate width="100%">
      <Flex justifyContent="space-around" my={3}>
        {props.plans?.map((plan) => (
          <Plan
            key={plan.id}
            name={plan.name}
            items={plan.coreFeatures?.map((f) => f.name)}
            description={plan.description}
            button={plan.price ? `$${plan.price / 100}/month` : "Free"}
            selected={props.currentPlan?.id === plan.id}
            onSelected={() => {
              handleSubmit({ ...plan });
            }}
          />
        ))}
      </Flex>
    </Box>
  );
}

export default StepOne;
