import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text, Box } from "rebass";
import AddScoreMutation from "../../../graphql/mutations/AddScore.gql";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { SaveIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";
const _ = require("lodash");
function AddCompanySentiment(props) {
  const { addToast } = useToasts();
  const [save, {}] = useMutation(AddScoreMutation);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        comment: "",
        ids: props.ids,
        parentType: props.parentType,
      }}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        value: Yup.string().required("Required"),
        comment: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        save({
          variables: {
            ids: props.ids,
            parentType: props.parentType,
            value: parseInt(values.value),
            comment: values.comment,
            scoreType: "sentiment",
          },
        }).then(
          (response) => {
            if (!response.data?.addScore?.errors) {
              props.onSave();
            } else {
              addToast(<Flex>{response.data?.addScore?.errors[0]}</Flex>, {
                appearance: "error",
                autoDismiss: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;

        return (
          <Flex flex={1} flexDirection="column">
            <Flex alignItems="center" justifyContent="space-between" my={2}>
              <Text variant="muted">
                Select a value that indicates the sentiment of the account at
                the current time.
              </Text>
            </Flex>
            <Box>
              <RangeSelector
                onChange={(values) => setFieldValue("value", values)}
                value={values.value}
              />
              {errors && errors.value && (
                <Text variant="label" color="red">
                  {errors.value}
                </Text>
              )}
            </Box>
            <CustomFormGroup
              type="textarea"
              placeholder={"Comment"}
              label={"Comment"}
              name={"comment"}
              value={values.comment}
              onChange={setFieldValue}
              helperText={errors && errors.comment && errors.comment}
            />
            <Box my={1} />
            <Flex alignItems="center" justifyContent="flex-end">
              {props.onClose && (
                <GhostButton onClick={props.onClose}>Close</GhostButton>
              )}

              <PrimaryButton
                leftIcon={<SaveIcon />}
                onClick={() => handleSubmit()}
              >
                Save
              </PrimaryButton>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default AddCompanySentiment;

const RangeSelector = (props) => {
  const colors = [
    "#d62839",
    "#d62839",
    "#d62839",
    "#d62839",
    "#d62839",
    "#FFA62B",
    "#FFA62B",
    "#FFA62B",
    "#3EC300",
    "#3EC300",
  ];
  return (
    <Flex>
      {colors.map((c, i) => {
        let selected = props.value === i + 1;
        return (
          <Button
            key={i}
            variant="secondary"
            bg={selected && "brandLight"}
            mr={2}
            color={c}
            fontWeight="bold"
            onClick={() => props.onChange(i + 1)}
          >
            {i + 1}
          </Button>
        );
      })}
    </Flex>
  );
};
