import React, { useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Flex, Button, Text, Box, Link } from "rebass";
import { getAllTags } from "hooks/getAllTags";
import Loader from "Components/Common/Loader";
import { HelpIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";

function Tags(props) {
  const { id } = useParams();
  const history = useHistory();
  const { loading, data } = getAllTags();

  return (
    <>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={1} justifyContent="space-between">
            <Text variant="h2">Tags</Text>
            <PrimaryButton
              onClick={() => {
                history.push("/settings/account/tags/new");
              }}
            >
              <PlusIcon />
            </PrimaryButton>
          </Flex>

          {(!data || data.allTags?.length === 0) && (
            <Flex p={2} my={2} flexDirection="column">
              <Text variant="muted">
                Create tags to assign to accounts, users.
              </Text>
              <Link href="#" my={2}>
                <Flex alignItems="center">
                  <HelpIcon /> <Text mx={2}>Help</Text>
                </Flex>
              </Link>
            </Flex>
          )}

          {loading && <Loader />}
          {data &&
            data.allTags?.map((tag) => (
              <Flex
                key={tag.id}
                variant="box"
                bg={tag.id === id ? "brandHighlight" : "white"}
                sx={{ cursor: "pointer" }}
                p={2}
                my={2}
                justifyContent="space-between"
                flexDirection={"column"}
                onClick={() => history.push(`/settings/account/tags/${tag.id}`)}
              >
                <Text variant="h4" color="text">
                  {tag.name}
                </Text>
                <Flex my={2}>
                  {tag.taggings &&
                    Object.keys(tag.taggings).map((key) => {
                      return (
                        <Flex
                          key={key}
                          variant="badge"
                          bg="gray.200"
                          alignItems={"center"}
                          mr={2}
                        >
                          <Text mx={2}>{key}</Text>
                          <Flex
                            variant="badge"
                            bg="brandLight"
                            color="white"
                            mx={0}
                            alignItems={"center"}
                            justifyContent="center"
                            sx={{ minWidth: 20 }}
                          >
                            {tag.taggings[key]}
                          </Flex>
                        </Flex>
                      );
                    })}
                </Flex>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </>
  );
}

export default withRouter(Tags);
