import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Box, Text, Link } from "rebass";
import StepWizard from "react-step-wizard";
import StepOne from "./Step1";
import StepTwo from "../Register/Step2";
import { useMutation } from "@apollo/react-hooks";
import ResetPasswordMutation from "../../../graphql/mutations/UserResetWithToken.gql";
import WizardNav from "Components/Common/WizardNav";
import WebsiteWrapper from "../WebsiteWrapper";

function ResetPassword(props) {
  const { key } = useParams();
  const [resetPassword, {}] = useMutation(ResetPasswordMutation);

  const handleSubmit = (values) => {
    resetPassword({
      variables: {
        resetToken: key,
        password: values.password,
      },
    }).then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <WebsiteWrapper>
      <Box width={1 / 2}>
        <StepWizard
          nav={<WizardNav stepLabels={["Email", "Confirmation", "Reset"]} />}
        >
          {!key && <StepOne />}
          {!key && (
            <Box
              as="form"
              flexDirection="column"
              noValidate
              width="100%"
              sx={{
                minWidth: 350,
              }}
            >
              <Text variant="h2" color="brandLight" mt={4}>
                Please check your email!
              </Text>

              <Text my={2} as="body" variant="body">
                We have sent you an email to reset your password
              </Text>
            </Box>
          )}
          {key && (
            <StepTwo
              onUpdate={(data) => {
                handleSubmit(data);
              }}
            />
          )}
          {key && (
            <Box
              as="form"
              flexDirection="column"
              noValidate
              width="100%"
              sx={{
                minWidth: 350,
              }}
            >
              <Text
                as="h3"
                variant="h3"
                textAlign="center"
                my={2}
                color="brandLight"
              >
                Your password has been reset!
              </Text>
              <Text textAlign="center">
                <Link href="/login">Login </Link>with your password
              </Text>
            </Box>
          )}
        </StepWizard>
      </Box>
    </WebsiteWrapper>
  );
}

export default withRouter(ResetPassword);
