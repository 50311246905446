import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import { Flex, Image, Text, Button, Box } from "rebass";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import CheckboxGroupSwitch from "Components/Common/CheckboxGroupSwitch";
import {
  ArrowDropDownIcon,
  FilterOffIcon,
  FilterOnIcon,
} from "Components/Common/Icons";
import { TaskCategories } from "./TaskDefaults";
import CustomFormGroup from "../../Common/Forms/CustomFormGroup";

function TaskFilter(props) {
  const theme = useTheme();
  const categories = TaskCategories();
  const [showMenu, setShowMenu] = useState(false);
  const [activityTypes, setActivityType] = useState(props.types);
  return (
    <Flex sx={{ position: "relative" }}>
      <SecondaryButton
        leftIcon={
          activityTypes?.length === 0 ? (
            <Text color="gray.400">
              <FilterOffIcon
                size={12}
                onClick={() => {
                  setActivityType(TaskCategories().map((i) => i.value));
                }}
              />
            </Text>
          ) : (
            <Text color="brandLight">
              <FilterOnIcon size={12} onClick={() => setActivityType([])} />
            </Text>
          )
        }
        rightIcon={<ArrowDropDownIcon color="brandLight" />}
        dropdownToggle
        onClick={() => setShowMenu(!showMenu)}
      >
        Filter
      </SecondaryButton>
      {showMenu && (
        <Flex
          p={3}
          bg="white"
          sx={{
            borderColor: "gray.100",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: 99,
            position: "absolute",
            top: 40,
            width: 200,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[500]}`,
          }}
          flexDirection="column"
        >
          <CheckboxGroupSwitch
            name="activityTypes"
            selectedOptions={activityTypes}
            options={categories
              .filter((c) => c.active)
              .map((c) => {
                return { value: c.value, label: c.description };
              })}
            onChange={(name, value) => {
              let types = _.cloneDeep(activityTypes);
              let idx = types.indexOf(value);
              if (idx < 0) {
                types.push(value);
              } else {
                types.splice(idx, 1);
              }
              console.log("activity types");
              setActivityType(types);
              props.onChange(types);
            }}
          />
          <Box variant="divider" />
          <Flex alignItems="center" justifyContent="space-between">
            <Text variant="muted" mr={2}>
              Completed
            </Text>
            <Box>
              <CustomFormGroup
                noMargin
                type="switch"
                name="includeDone"
                value={props.includeDone}
                onChange={() => props.onIncludeDone()}
              />
            </Box>
          </Flex>
          <Flex alignItems="center" mt={2} justifyContent="space-between">
            <Text variant="muted" mr={2}>
              Overdue
            </Text>
            <Box>
              <CustomFormGroup
                noMargin
                type="switch"
                name="includeOverdue"
                value={props.includeOverdue}
                onChange={() => props.onIncludeOverdue()}
              />
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default TaskFilter;
