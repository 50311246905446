import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import TeamsQuery from "../graphql/queries/Teams.gql";

export function getTeams(id, parentType) {
  const teamsQuery = useQuery(TeamsQuery, {
    variables: { id: id, parentType: parentType },
  });

  return {
    ...teamsQuery,
  };
}
