import React, { useState } from "react";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { Box, Flex, Link, Text } from "rebass";
import { HelpIcon, UserlotIcon } from "../../../Common/Icons";

import { Formik } from "formik";
import * as Yup from "yup";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";

function SlackForm(props) {
  const { field, fieldName, setFieldValue, handleBlur } = props;

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Required"),
        url: Yup.string().required("Required"),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        return (
          <>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <CustomFormGroup
              placeholder="Slack Url"
              label="Slack Url"
              noMargin
              name={`${defaultFieldName}.url`}
              value={field.config?.url}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.url && errors.url}
            />
            <Box mt={1} mb={3}>
              <Link href="https://api.slack.com/messaging/webhooks">
                <Flex alignItems={"center"}>
                  <HelpIcon />
                  <Text mx={1}>Sending messages using Incoming Webhooks</Text>
                </Flex>
              </Link>
            </Box>
            <CustomFormGroup
              placeholder="Title"
              label="Title"
              name={`${defaultFieldName}.title`}
              value={field.config?.title}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.title && errors.title}
            />

            <Text variant="label">Example</Text>
            <Flex variant="box" p={2}>
              <UserlotIcon height={30} />
              <Flex flexDirection={"column"}>
                <Flex my={1} alignItems={"center"}>
                  <Text fontSize="lg" fontWeight={"bold"}>
                    Your webhook name
                  </Text>

                  <Box
                    mx={2}
                    p={1}
                    variant="muted"
                    fontSize={"xs"}
                    bg="gray.200"
                    sx={{ borderRadius: 3 }}
                  >
                    APP
                  </Box>
                </Flex>
                <Text fontWeight={"bold"}>
                  {field.config?.title || "Title"}
                </Text>
                <Text my={1}>
                  <Link>Company Name</Link> (Product)
                </Text>
                <Text my={1}>Automation: {props.values.name}</Text>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Formik>
  );
}

export default SlackForm;
