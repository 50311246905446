import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Label } from "@rebass/forms";
import _uniqueId from "lodash/uniqueId";
import { Flex, Text } from "rebass";

const RadioGroupButton = ({
  title,
  labelSx,
  options = [],
  value,
  onChange,
  name,
  ...otherProps
}) => {
  // useMemo ensures the groupId prop doesn't needlessly change on re-render
  const groupId = useMemo(() => _uniqueId("radio-group-"), []);

  const groupPillLabelStyle = {
    label: {
      display: "flex",
      fontWeight: "body",
      borderRadius: 99,
      'input[type="radio"]': {
        opacity: "0",
        marginLeft: -15,
        "&:checked + span": {
          borderRadius: "inherit",
          color: "secondaryLight",
        },
      },
    },
  };

  return (
    <Flex
      role="radiogroup"
      aria-labelledby={groupId}
      display="flex"
      sx={groupPillLabelStyle}
      {...otherProps}
    >
      {options.map(
        ({ label, description, value: v, cyName, ...rest }, index) => (
          <Flex
            as="label"
            key={index}
            mr={2}
            variant="buttons.secondary"
            alignItems={"center"}
            justifyContent="center"
            sx={{
              borderColor: "brandLight",
              backgroundColor: v === value ? "white" : "brandLight",
              color: v === value ? "brandLight" : "white",
              height: 35,
              "&:hover:not(:disabled)": {
                backgroundColor: "brandHighlight",
                color: "brandLight",
              },
            }}
          >
            <input
              checked={v === value}
              name={name}
              onChange={({ target }) => {
                onChange(name, { label: label, value: target.value, ...rest });
              }}
              type="radio"
              value={v}
              data-cy={cyName}
            />
            <Text p={2} fontSize="sm" role="presentation">
              {v === value ? description || label : label}
            </Text>
          </Flex>
        )
      )}
    </Flex>
  );
};

RadioGroupButton.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  title: PropTypes.string,
  labelSx: PropTypes.object,
};

export default RadioGroupButton;
