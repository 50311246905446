import React, { useState } from "react";
import { Box, Button, Flex, Image, Text } from "rebass";
import EmailDomain from "./EmailDomain";
import Modal from "Components/Common/Modal/index";
import { PlusIcon } from "Components/Common/Icons";
import EmailDomainForm from "./EmailDomainForm";
import getEmailDomains from "hooks/getEmailDomains";

function EmailTemplatesList(props) {
  const { refetch } = getEmailDomains();
  const [openModal, setOpenModal] = useState(false);
  const { domains } = props;

  const emailDomains = domains?.map((domain) => (
    <EmailDomain domain={domain} key={domain.id} refetch={refetch} />
  ));

  return (
    <>
      <Flex flex={1} flexDirection="column" bg="white" p={4}>
        <Flex justifyContent="space-between">
          <Text variant="h2">Email Domains</Text>
          <Button variant="action" onClick={() => setOpenModal(true)}>
            <PlusIcon style={{ marginLeft: "-3px", marginTop: "3px" }} />
          </Button>
        </Flex>
        {domains.length > 0 ? emailDomains : <EmptyState />}
      </Flex>
      <AddEmailDomainForm
        open={openModal}
        onDismiss={() => setOpenModal(false)}
        onSave={() => {
          refetch();
          setOpenModal(false);
        }}
      />
    </>
  );
}

export default EmailTemplatesList;

const AddEmailDomainForm = (props) => {
  return (
    <Modal open={props.open} onDismiss={props.onDismiss}>
      <Box mx={3}>
        <EmailDomainForm onSave={() => props.onSave()} />
      </Box>
    </Modal>
  );
};

const EmptyState = (props) => {
  return (
    <Flex
      p={5}
      m={2}
      flex={1}
      justifyContent="center"
      bg="gray.100"
      sx={{
        borderColor: "accent",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        minHeight: 400,
      }}
    >
      <Flex m={4} flex={1} justifyContent="center" flexDirection="column">
        <Text my={3} fontWeight="bold">
          Add domains to be validated for email campaigns
        </Text>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ marginTop: "-10%", height: "150px", objectFit: "contain" }}
        />
      </Flex>
    </Flex>
  );
};
