import React from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text } from "rebass";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { TrashIcon } from "Components/Common/Icons";
import { ErrorParams } from "Components/Common/Notifications/Error";

function PromptButton(props) {
  const history = useHistory();
  const { addToast, removeAllToasts } = useToasts();
  return (
    <SecondaryButton
      onClick={() => {
        addToast(
          <Flex flexDirection="column">
            <Text my={2}>{props.message}?</Text>
            <Flex justifyContent="flex-end">
              <Button
                variant="error"
                onClick={() => {
                  props.onConfirm();
                  removeAllToasts();
                }}
              >
                {props.buttonLabel}
              </Button>
            </Flex>
          </Flex>,
          ErrorParams
        );
      }}
    >
      {props.buttonLabel}
    </SecondaryButton>
  );
}

export default PromptButton;
