import React, { useState } from "react";
import Page from "Components/Page";
import { Flex } from "rebass";
import SegmentSummary from "Components/Data/SegmentSummary";
import ReportTopbar from "./ReportTopbar";

function Segments(props) {
  return (
    <Page title="Product Adoption" topBar={<ReportTopbar />}>
      <Flex flexDirection="column" flexGow={1} flex={1} p={4}>
        <Flex flex={1}>
          <SegmentSummary />
        </Flex>
      </Flex>
    </Page>
  );
}

export default Segments;
