import React, { useState } from "react";
import { useErrorBoundary } from "use-error-boundary";

const ErrorBoundary = (props) => {
  const { ErrorBoundary: Boundary, didCatch, error } = useErrorBoundary();

  if (didCatch) {
    console.log(error.message);
  }
  return <Boundary>{props.children}</Boundary>;
};

export default ErrorBoundary;
