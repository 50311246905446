import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import DashboardQuery from "../graphql/queries/Dashboard.gql";

export function getDashboard(filters) {
  const query = useQuery(DashboardQuery, {
    variables: {
      searchFilters: {
        ownerUserId: filters?.ownerUserId,
        productId: filters?.productId,
        daysOffset: filters?.daysAgo,
      },
    },
  });

  return {
    ...query,
  };
}
