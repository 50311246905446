import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Box, Button, Flex, Text } from "rebass";
import { useToasts } from "react-toast-notifications";
import { Formik } from "formik";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import EmailDomainMutation from "../../../../graphql/mutations/EmailDomain.gql";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { ErrorParams } from "Components/Common/Notifications/Error";

const EmailDomainForm = (props) => {
  const [save, {}] = useMutation(EmailDomainMutation);
  const { addToast } = useToasts();

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        initialValues={{
          domain: "",
        }}
        onSubmit={(values, actions) => {
          save({
            variables: { emailDomain: values.domain },
          }).then(
            (response) => {
              console.log(response);
              if (response.data?.emailDomain?.errors) {
                addToast(
                  <Flex>{response.data?.emailDomain?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                props.onComplete && props.onComplete();
                props.onSave && props.onSave();
                addToast(<Flex>Saved!</Flex>, SuccessParams);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, handleBlur, handleSubmit, errors } =
            formProps;

          return (
            <>
              <Box my={2}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text variant="h2">New Email Domain</Text>
                </Flex>
                <CustomFormGroup
                  label="Email Domain"
                  placeholder="yourdomain.com"
                  name="domain"
                  onChange={(name, value) => setFieldValue(name, value)}
                />
                <Flex justifyContent="flex-end" my={2}>
                  <Button variant="action" onClick={handleSubmit}>
                    Save
                  </Button>
                </Flex>
              </Box>
            </>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default EmailDomainForm;
