import React, { useState } from "react";
import { Flex, Button, Box } from "rebass";
import { useMutation } from "@apollo/react-hooks";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import DeleteButton from "../MyAccount/Settings/Common/DeleteButton";
import ActivityDeleteMutation from "../../graphql/mutations/ActivityDelete.gql";
import MenuModal from "./MenuModal";
import "react-contexify/dist/ReactContexify.min.css";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

function ActivityMenu({
  actionable,
  type,
  refetch,
  canEdit,
  parentType,
  onSave,
}) {
  const [open, setOpen] = useState(false);
  const [destroy] = useMutation(ActivityDeleteMutation);
  const history = useHistory();
  const menuButtons = [];
  window.scrollTo(0, 0);
  if (type === "updateComment" && canEdit) {
    menuButtons.push(
      <SecondaryButton onClick={() => setOpen(true)}>Edit</SecondaryButton>
    );
    menuButtons.push(
      <DeleteButton
        useIcon
        callback={destroy}
        onCancel={() => history.back()}
        id={actionable?.id}
        refetch={refetch}
        name="note"
      />
    );
  }
  if (type === "task") {
    menuButtons.push(
      <SecondaryButton onClick={() => setOpen(true)}>View</SecondaryButton>
    );
  }
  return (
    <Flex flexGrow={1} alignItems="center" justifyContent="flex-end">
      {menuButtons.map((m) => m)}
      <MenuModal
        values={actionable}
        formType={type}
        parentType={parentType}
        id={actionable?.id}
        open={open}
        onSave={() => {
          onSave && onSave();
          setOpen(false);
        }}
        onDismiss={() => setOpen(false)}
      />
    </Flex>
  );
}
ActivityMenu.propTypes = {
  actionable: PropTypes.object,
  parentType: PropTypes.string,
  type: PropTypes.string,
  canEdit: PropTypes.bool,
  refetch: PropTypes.func,
  onSave: PropTypes.func,
};
export default ActivityMenu;

//
