import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import TicketTable from "./TicketTable";
import { Flex, Text } from "rebass";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  CriticalIcon,
  TasksIcon,
  TimeIcon,
  CheckCircleIcon,
} from "Components/Common/Icons";
import moment from "moment";
import { useUrlParams } from "../../../hooks/useUrlParams";
import PropTypes from "prop-types";

function Tickets({ detailedView, loading, onSelected, onSave, data }) {
  const [selectedRows, setSelectedRows] = useState();
  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const tabs = ["All", "Priority", "Overdue", "Completed"];
  const defaultTab = getUrlParameter("tab") || "All";
  const [selectedTab, setSelectedTab] = useState(
    tabs.indexOf(tabs.find((t) => t.toLowerCase() === defaultTab.toLowerCase()))
  );

  const openData = data?.filter(
    (t) => t.status !== "Resolved" && t.status !== "Closed"
  );
  const overdueData = openData?.filter((t) =>
    moment(t.dueAt).isBefore(moment())
  );
  const priorityData = openData?.filter((t) => t.priority >= 4);
  const completedData = data?.filter(
    (t) => t.status == "Resolved" || t.status == "Closed"
  );
  return (
    <Flex flex={1} w="100%" flexDirection="column">
      <Flex mt={3} flex={1} alignItems="flex-start">
        <Tabs
          width="100%"
          direction="rtl"
          selectedIndex={selectedTab}
          onSelect={setSelectedTab}
        >
          <TabList>
            <Tab key="All">
              <Flex alignItems="center" sx={{ minWidth: 100 }}>
                <TasksIcon />
                <Text fontSize="xs" color="text" mx={2}>
                  Open
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="brandHighlight"
                  px={2}
                  py={1}
                  sx={{ borderRadius: "medium" }}
                >
                  {openData.length}
                </Flex>
              </Flex>
            </Tab>
            <Tab key="Priority">
              <Flex alignItems="center" sx={{ minWidth: 100 }}>
                <CriticalIcon />
                <Text fontSize="xs" color="text" mx={2}>
                  Priority
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="redLight"
                  px={2}
                  py={1}
                  sx={{ borderRadius: "medium" }}
                >
                  <Text color="red">{priorityData.length}</Text>
                </Flex>
              </Flex>
            </Tab>
            <Tab key="Overdue">
              <Flex alignItems="center" sx={{ minWidth: 100 }}>
                <TimeIcon />
                <Text fontSize="xs" color="text" mx={2}>
                  Overdue
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="redLight"
                  px={2}
                  py={1}
                  sx={{ borderRadius: "medium" }}
                >
                  <Text color="red">{overdueData.length}</Text>
                </Flex>
              </Flex>
            </Tab>
            <Tab key="Completed">
              <Flex alignItems="center" sx={{ minWidth: 100 }}>
                <CheckCircleIcon />
                <Text fontSize="xs" color="text" mx={2}>
                  Completed
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="brandHighlight"
                  px={2}
                  py={1}
                  sx={{ borderRadius: "medium" }}
                >
                  <Text color="brandLight">{completedData.length}</Text>
                </Flex>
              </Flex>
            </Tab>
          </TabList>
          <TabPanel key="All">
            <TicketTable
              detailedView
              data={openData}
              loading={loading}
              selectedRows={selectedRows}
              onSelectedRowsChange={(rows) => {
                setSelectedRows(rows);
              }}
              onSelected={(p) => {
                onSelected(p);
              }}
            />
          </TabPanel>
          <TabPanel key="Priority">
            <TicketTable
              detailedView
              data={priorityData}
              loading={loading}
              selectedRows={selectedRows}
              onSelectedRowsChange={(rows) => {
                setSelectedRows(rows);
              }}
              onSelected={(p) => {
                onSelected(p);
              }}
            />
          </TabPanel>
          <TabPanel key="Overdue">
            <TicketTable
              detailedView
              data={overdueData}
              loading={loading}
              selectedRows={selectedRows}
              onSelectedRowsChange={(rows) => {
                setSelectedRows(rows);
              }}
              onSelected={(p) => {
                onSelected(p);
              }}
            />
          </TabPanel>
          <TabPanel key="Completed">
            <TicketTable
              detailedView
              data={completedData}
              loading={loading}
              selectedRows={selectedRows}
              onSelectedRowsChange={(rows) => {
                setSelectedRows(rows);
              }}
              onSelected={(p) => {
                onSelected(p);
              }}
            />
          </TabPanel>
        </Tabs>
      </Flex>
    </Flex>
  );
}

Tickets.propTypes = {
  detailedView: PropTypes.bool,
  loading: PropTypes.bool,
  view: PropTypes.oneOf(["summary"]),
  onSelected: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

Tickets.defaultProps = {
  detailedView: true,
  loading: false,
  view: "summary",
  data: [],
};
export default withRouter(Tickets);
