import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "rebass";
import { MODEL_TYPES } from "consts";
import {
  CompanyIcon,
  MapIcon,
  MessageIcon,
  ProductIcon,
  TicketIcon,
  TimeIcon,
  UserIcon,
} from "./Icons";
import Avatar from "react-avatar";

const ModelHeader = (props) => {
  const { name, type, size, logoUrl } = props;
  return (
    <Flex sx={{ cursor: "pointer" }}>
      <Box>
        {type === MODEL_TYPES.Company.name && (
          <>
            {logoUrl ? (
              <Box width={18} mr={2}>
                <Avatar size={18} src={logoUrl} />
              </Box>
            ) : (
              <CompanyIcon size={size} />
            )}
          </>
        )}
        {type === MODEL_TYPES.Comment.name && <MessageIcon size={size} />}
        {type === MODEL_TYPES.Address.name && <MapIcon size={size} />}
        {type === MODEL_TYPES.EndUser.name && <UserIcon size={size} />}
        {type === MODEL_TYPES.Event.name && <TimeIcon size={size} />}
        {type === MODEL_TYPES.Product.name && <ProductIcon size={size} />}
        {type === MODEL_TYPES.Ticket.name && <TicketIcon size={size} />}
      </Box>
      <Text
        ml={1}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </Text>
    </Flex>
  );
};

export default ModelHeader;
