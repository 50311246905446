import { useQuery } from "@apollo/client";
import { onAdminDomain } from "../../auth-helper";
import CurrentUserQuery from "../../graphql/queries/CurrentUser.gql";

const AdminOnly = (props) => {
  const { data } = useQuery(CurrentUserQuery, {
    fetchPolicy: "no-cache",
  });
  return onAdminDomain() || data?.currentUser?.impersonating
    ? props.children
    : null;
};
export default AdminOnly;
