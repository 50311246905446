import React from "react";
import { Flex, Box, Text } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import TimezonesQuery from "../../../graphql/queries/Timezones.gql";
import LocalesQuery from "../../../graphql/queries/Locales.gql";
import { useQuery } from "@apollo/react-hooks";

function LocalisationForm({
  hideWarning,
  values,
  setFieldValue,
  handleBlur,
  errors,
}) {
  const { data: timezones } = useQuery(TimezonesQuery);
  const { data: locales } = useQuery(LocalesQuery);

  const defaultTimezone = timezones?.timezones?.find(
    (t) => t.value == values?.defaultTimezone
  )?.label;
  const defaultLocale = locales?.locales?.find(
    (t) => t.value == values?.defaultLocale
  )?.label;
  return (
    <Box>
      <Flex>
        <CustomFormGroup
          placeholder={defaultTimezone}
          label="Timezone"
          name="timezone"
          value={values?.timezone}
          onChange={(name, value) => setFieldValue(name, value?.value)}
          type="list"
          clearable
          options={timezones?.timezones}
          onBlur={handleBlur}
          noMargin
          helperText={errors?.timezone && errors.timezone}
        />
        <Box mx={2} />
        <CustomFormGroup
          placeholder={defaultLocale}
          label="Locale"
          name="locale"
          value={values?.locale}
          onChange={(name, value) => setFieldValue(name, value?.value)}
          type="list"
          clearable
          noMargin
          options={locales?.locales}
          onBlur={handleBlur}
          helperText={errors?.locale && errors.locale}
        />
      </Flex>
      {!hideWarning && (!values?.timezone || !values?.locale) && (
        <Text mb={2} variant="muted" sx={{ fontStyle: "italic" }}>
          Note: Timezone and locale will default to the account settings if not
          explicitily set.
        </Text>
      )}
    </Box>
  );
}

export default LocalisationForm;
