import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import { useTheme } from "emotion-theming";
import WidgetWrapper from "./WidgetWrapper";
import CustomBarGraph from "./CustomBarGraph";
import _ from "lodash";
import Avatar from "react-avatar";
import Loader from "../../Common/Loader";
import numeral from "numeral";

function TeamNrrBreakdown({ data, loading }) {
  const theme = useTheme();
  return data ? (
    <WidgetWrapper title={"Net Revenue Retention"}>
      <Flex flexWrap="wrap">
        {data.map((t) => (
          <Flex key={t.id} width={1 / 2}>
            <Flex
              m={2}
              bg="gray.100"
              flex={1}
              width="100%"
              sx={{ borderRadius: "medium" }}
            >
              <NrrBadge
                count={t.count}
                value={t.value}
                loading={loading}
                title={t.name}
                avatarUrl={t.avatarUrl}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </WidgetWrapper>
  ) : null;
}

export default TeamNrrBreakdown;

export function NrrBadge({ value, loading, title, avatarUrl, count }) {
  return (
    <Flex flex={1} flexDirection="column" p={2}>
      <Flex alignItems="center" mb={2}>
        <Avatar size={30} round name={title} src={avatarUrl} />
        <Box ml={1}>
          <Text
            textAlign={"left"}
            fontSize={"xs"}
            sx={{ whiteSpace: "break-spaces" }}
          >
            {title}
          </Text>
        </Box>
      </Flex>
      {loading ? (
        <Loader />
      ) : value ? (
        <>
          <Text as="h2" textAlign={"center"}>
            {numeral(value).format("0%")}
          </Text>
        </>
      ) : (
        <Flex flex={1} alignItems="center" justifyContent="center" p={2}>
          <Image
            src={require("assets/images/SatisfactionEmpty.png")}
            width={40}
          />
        </Flex>
      )}
    </Flex>
  );
}
