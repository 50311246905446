import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import TaskCategoriesQuery from "../graphql/queries/TaskCategory.gql";

export function getTaskCategories(id) {
  const query = useQuery(TaskCategoriesQuery);

  return {
    ...query,
  };
}
