import React from "react";
import { Flex, Text, Box, Link } from "rebass";
import { useQuery } from "@apollo/react-hooks";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { getSearchFilter } from "hooks/getSearchFilter";
import LineGraph from "Components/Common/Graphs/LineGraph";
import Loader from "Components/Common/Loader";
import TimeSpentDashboardQuery from "../../../graphql/queries/TimeSpentDashboard";
import DonutGraph from "../../Common/Graphs/DonutGraph";
import { Label } from "@rebass/forms";
import { useTheme } from "emotion-theming";
import Table from "Components/Common/Table";
import numeral from "numeral";
import TableEmpty from "../../Data/TableEmpty";

const _ = require("lodash");

const columns = [
  {
    name: "Account",
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => <Flex>{row.name}</Flex>,
  },
  {
    name: "Time (Minutes)",
    selector: (row) => row.timeSpent,
    sortable: true,
    cell: (row) => <Flex>{(row.timeSpent / 1000 / 60).toFixed(0)}</Flex>,
  },
  {
    name: "Value (ARR)",
    selector: (row) => row.value,
    sortable: true,
    cell: (row) => <Flex>{numeral(row.value).format("$0a")}</Flex>,
  },
];
function TimeSpent(props) {
  const theme = useTheme();
  const history = useHistory();
  const { data: searchFilters } = getSearchFilter();

  const filters = {
    segmentId: searchFilters?.filters?.segmentId,
    ownerUserId: searchFilters?.filters?.ownerUserId,
    daysOffset: props.daysAgo?.value,
  };

  const eventDashboard = useQuery(TimeSpentDashboardQuery, {
    variables: {
      searchFilters: {
        ...filters,
      },
    },
    fetchPolicy: "network-only",
  });

  if (eventDashboard.loading) return <Loader />;
  const timeSpent = eventDashboard?.data?.timeSpentDashboard?.timeSpent;
  const accountSummary =
    eventDashboard?.data?.timeSpentDashboard?.accountSummary;

  const graphData = [
    {
      name: "time spent",
      data: timeSpent?.map((d) => {
        let timeSpent = d.timeSpent / 1000 / 60;

        return {
          date: d.key,
          count: d ? timeSpent : 0,
        };
      }),
    },
  ];

  const activeAccounts = accountSummary.find((a) => a.key == "active");
  const remainingAccounts = accountSummary.find((a) => a.key == "remaining");

  let totalAccounts =
    (activeAccounts?.count || 0) + (remainingAccounts?.count || 0);
  let totalAccountsInApp = activeAccounts?.count || 0;

  let totalAccountsValue =
    (activeAccounts?.value || 0) + (remainingAccounts?.value || 0);
  let totalAccountsValueInApp = activeAccounts?.value || 0;

  const timeSpentData = accountSummary
    .filter((a) => a.key == "active" || a.key == "remaining")
    .map((a) => {
      return {
        value: a.count,
        label: a.label,
        color: a.timeSpent > 0 ? theme.colors.brand : theme.colors.gray["200"],
      };
    });

  const revenueData = accountSummary
    .filter((a) => a.key == "active" || a.key == "remaining")
    .map((a) => {
      return {
        value: a.value,
        label: a.label,
        color: a.timeSpent > 0 ? theme.colors.brand : theme.colors.gray["200"],
      };
    });

  return (
    <Flex flex={1} mt={4} p={3} flexDirection="column" bg="gray.100">
      <Flex flexDirection="column">
        <Box width="100%">
          <Text variant="h4" color="brandLight">
            Time in App
          </Text>
          <Flex justifyContent={"center"}>
            <LineGraph
              days={props.daysAgo?.value}
              series={_.flatten(graphData)}
              height={200}
              ytitle="Min"
              noLegend
            />
          </Flex>
        </Box>
        <Flex p={4} justifyContent="space-between">
          <Box>
            <DonutGraph
              data={timeSpentData}
              width={100}
              noLegend
              value={
                <Flex
                  color="gray.500"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Text fontSize="sm">
                    {numeral(totalAccountsInApp / totalAccounts).format("0%")}
                  </Text>
                </Flex>
              }
            />

            <Label>
              <Text fontSize="xxs" mt={1}>
                % of accounts
              </Text>
            </Label>
          </Box>
          <Box>
            <DonutGraph
              data={revenueData}
              width={100}
              noLegend
              value={
                <Flex
                  color="gray.500"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Text fontSize="sm">
                    {numeral(
                      totalAccountsValueInApp / totalAccountsValue
                    ).format("0%")}
                  </Text>
                </Flex>
              }
            />
            <Label>
              <Text fontSize="xxs" mt={1}>
                % of contract amount
              </Text>
            </Label>
          </Box>
        </Flex>
      </Flex>
      <Box px={4}>
        <Text variant="h5" color="brandLight">
          Most Active Accounts
        </Text>
        <Box>
          <Flex px={3} variant="divider">
            <Box width={3 / 4}>
              <Text fontSize={"xxs"} color="gray.700">
                Account
              </Text>
            </Box>
            <Box width={1 / 4}>
              <Text fontSize={"xxs"} color="gray.700">
                ARR
              </Text>
            </Box>
          </Flex>
          {accountSummary
            ?.filter(
              (a) =>
                a.timeSpent > 0 && a.key != "active" && a.key != "remaining"
            )
            .sort((a, b) => b.timeSpent - a.timeSpent)
            .slice(0, 10)
            .map((account) => (
              <Flex key={account.key} px={3} pb={3} variant="divider">
                <Box width={3 / 4}>
                  <Link href={`/adoption/accounts/${account.key}`}>
                    {account.name}
                  </Link>
                </Box>
                <Box width={1 / 4}>
                  <Text fontSize={"xs"} color="gray.800">
                    {numeral(account.value).format("$0,0")}
                  </Text>
                </Box>
              </Flex>
            ))}
        </Box>
      </Box>
    </Flex>
  );
}

export default TimeSpent;
