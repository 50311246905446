import React, { useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import { Prompt } from "react-router-dom";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useMutation } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import { getCompanyStatus } from "hooks/getCompanyStatuses";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { TwitterPicker } from "react-color";
import SegmentConfigForm from "Components/Filter/Segment/SegmentConfigForm";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import Label from "Components/Common/Forms/Label";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import LifeCycleForm from "../LifeCycles/LifeCycleForm";
import CompanyStatusMutation from "../../../../graphql/mutations/CompanyStatus.gql";

function CompanyStatusForm(props) {
  const { data, refetch } = getCompanyStatus();
  const [triggerSegmentOpen, setTriggerSegmentOpen] = useState(false);
  const { addToast } = useToasts();
  const [showColor, setShowColor] = useState(-1);
  const [save, {}] = useMutation(CompanyStatusMutation);

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={{
          companyStatuses: data?.companyStatuses || [{ name: "" }],
        }}
        autoComplete={false}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values, actions) => {
          save({
            variables: {
              companyStatuses: values.companyStatuses.map((r) => {
                return {
                  id: r.id || null,
                  name: r.name,
                  color: r.color,
                  default: r.default,
                  config: r.config,
                };
              }),
            },
          }).then(
            (response) => {
              if (response.data?.companyStatus?.errors) {
                addToast(
                  <Flex>{response.data?.companyStatus?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                actions.resetForm({
                  values: response.data?.companyStatus?.companyStatuses,
                });
                props.onComplete && props.onComplete();
                addToast(<Flex>Saved!</Flex>, SuccessParams);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, dirty, handleSubmit, errors } =
            formProps;

          const trialStatusId =
            values.companyStatuses &&
            values.companyStatuses.find((f) => f.name === "Trial")?.id;

          const paidStatusId =
            values.companyStatuses &&
            values.companyStatuses.find((f) => f.name === "Paid")?.id;

          const trialStatusIndex =
            values.companyStatuses &&
            values.companyStatuses.indexOf(
              values.companyStatuses.find((f) => f.name === "Trial")
            );

          const paidStatusIndex =
            values.companyStatuses &&
            values.companyStatuses.indexOf(
              values.companyStatuses.find((f) => f.name === "Paid")
            );

          const churnedStatusIndex =
            values.companyStatuses &&
            values.companyStatuses.indexOf(
              values.companyStatuses.find((f) => f.name === "Churned")
            );

          const lostStatusIndex =
            values.companyStatuses &&
            values.companyStatuses.indexOf(
              values.companyStatuses.find((f) => f.name === "Lost")
            );

          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <FieldArray
                name="companyStatuses"
                render={(arrayHelpers) => (
                  <Box my={2}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text variant="h2">Account Status &amp; Lifecycle</Text>
                      <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                    </Flex>
                    <Box pt={2}>
                      Define your accounts status lifecycle within your product.
                    </Box>
                    <Box pt={2}>
                      Your accounts will default to trial if you have that
                      module enabled otherwise all accounts default to paid
                      status.
                    </Box>
                    <Flex
                      justifyContent="flex-end"
                      alignItems="center"
                      width="100%"
                    >
                      <Label mb={2}>Enable Conversion Module</Label>
                      <Box>
                        <CustomFormGroup
                          type="switch"
                          name="default"
                          value={
                            values.companyStatuses &&
                            values.companyStatuses[trialStatusIndex]?.default
                          }
                          onChange={(name, value) => {
                            setFieldValue(
                              `companyStatuses[${trialStatusIndex}].default`,
                              value
                            );
                            setFieldValue(
                              `companyStatuses[${paidStatusIndex}].default`,
                              !value
                            );
                          }}
                        />
                      </Box>
                    </Flex>
                    <Flex flexDirection="column" flex={1} mt={3}>
                      {values.companyStatuses &&
                        values.companyStatuses[trialStatusIndex]?.default && (
                          <>
                            <Flex
                              alignItems="center"
                              variant="box"
                              flexDirection="column"
                              flex={1}
                            >
                              <Text variant="h3" mt={2}>
                                Trial Accounts
                              </Text>
                              <Text variant="muted">
                                The trial module enables you to track and verify
                                your trial accounts. Trial accounts can
                                transition to paid or lost.
                              </Text>
                              <Flex alignItems="center" width="100%" p={2}>
                                <Flex
                                  flexDirection="column"
                                  my={3}
                                  variant="box"
                                  width={2 / 5}
                                >
                                  <Flex
                                    color="white"
                                    bg={
                                      (values.companyStatuses &&
                                        values.companyStatuses[trialStatusIndex]
                                          ?.color) ||
                                      "secondary"
                                    }
                                    onClick={() => {
                                      if (showColor === trialStatusIndex) {
                                        setShowColor(-1);
                                      } else {
                                        setShowColor(trialStatusIndex);
                                      }
                                    }}
                                    p={2}
                                    justifyContent="space-between"
                                  >
                                    <Text fontWeight="bold">Trial</Text>
                                    <ColorEditor
                                      index={trialStatusIndex}
                                      {...formProps}
                                      showColor={showColor}
                                      setShowColor={setShowColor}
                                    />
                                    {values.companyStatuses[trialStatusIndex]
                                      ?.default && (
                                      <Box
                                        mx={2}
                                        bg="white"
                                        py={1}
                                        px={2}
                                        sx={{ borderRadius: 99 }}
                                      >
                                        <Text variant="muted">Default</Text>
                                      </Box>
                                    )}
                                  </Flex>
                                  <Flex flex={1} p={2}>
                                    {trialStatusId && (
                                      <LifeCycleForm statusId={trialStatusId} />
                                    )}
                                  </Flex>
                                </Flex>
                                <Flex
                                  width={1 / 5}
                                  alignItems="center"
                                  flexDirection="column"
                                  justifyContent="center"
                                >
                                  <TransitionEditor
                                    arrow={<RiArrowRightSLine size={25} />}
                                    index={lostStatusIndex}
                                    {...formProps}
                                    triggerSegmentOpen={triggerSegmentOpen}
                                    setTriggerSegmentOpen={
                                      setTriggerSegmentOpen
                                    }
                                  />
                                </Flex>
                                <Flex
                                  flexDirection="column"
                                  my={3}
                                  variant="box"
                                  width={2 / 5}
                                >
                                  <Box
                                    color="white"
                                    bg={
                                      (values.companyStatuses &&
                                        values.companyStatuses[lostStatusIndex]
                                          ?.color) ||
                                      "secondary"
                                    }
                                    onClick={() => {
                                      if (showColor === lostStatusIndex) {
                                        setShowColor(-1);
                                      } else {
                                        setShowColor(lostStatusIndex);
                                      }
                                    }}
                                    p={2}
                                  >
                                    <ColorEditor
                                      index={lostStatusIndex}
                                      {...formProps}
                                      showColor={showColor}
                                      setShowColor={setShowColor}
                                    />
                                    <Text fontWeight="bold">Lost</Text>
                                  </Box>
                                </Flex>
                              </Flex>
                            </Flex>
                            <Flex
                              my={3}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <TransitionEditor
                                index={paidStatusIndex}
                                arrow={<RiArrowDownSLine size={25} />}
                                {...formProps}
                                triggerSegmentOpen={triggerSegmentOpen}
                                setTriggerSegmentOpen={setTriggerSegmentOpen}
                              />
                            </Flex>
                          </>
                        )}
                      <Flex
                        variant="box"
                        alignItems="center"
                        flexDirection="column"
                        width="100%"
                      >
                        <Text variant="h3" mt={2}>
                          Paid Accounts
                        </Text>
                        <Text variant="muted" px={3}>
                          This is the primary module in Userlot it will track
                          all accounts using your product. From Paid an account
                          can transition to Churned manually or if certain
                          conditions are met.
                        </Text>
                        <Flex
                          alignItems="center"
                          width="100%"
                          p={2}
                          flex={1}
                          my={3}
                        >
                          <Flex
                            flexDirection="column"
                            variant="box"
                            width={2 / 5}
                            flex={1}
                          >
                            <Flex
                              color="white"
                              bg={
                                (values.companyStatuses &&
                                  values.companyStatuses[paidStatusIndex]
                                    ?.color) ||
                                "secondary"
                              }
                              onClick={() => {
                                if (showColor === paidStatusIndex) {
                                  setShowColor(-1);
                                } else {
                                  setShowColor(paidStatusIndex);
                                }
                              }}
                              p={2}
                              justifyContent="space-between"
                            >
                              <Text fontWeight="bold">Paid</Text>
                              <ColorEditor
                                index={paidStatusIndex}
                                {...formProps}
                                showColor={showColor}
                                setShowColor={setShowColor}
                              />
                              {values.companyStatuses[paidStatusIndex]
                                ?.default && (
                                <Box
                                  mx={2}
                                  bg="white"
                                  py={1}
                                  px={2}
                                  sx={{ borderRadius: 99 }}
                                >
                                  <Text variant="muted">Default</Text>
                                </Box>
                              )}
                            </Flex>
                            <Flex flex={1} p={2}>
                              {paidStatusId && (
                                <LifeCycleForm statusId={paidStatusId} />
                              )}
                            </Flex>
                          </Flex>
                          <Flex
                            width={1 / 5}
                            alignItems="center"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <TransitionEditor
                              arrow={<RiArrowRightSLine size={25} />}
                              index={churnedStatusIndex}
                              {...formProps}
                              triggerSegmentOpen={triggerSegmentOpen}
                              setTriggerSegmentOpen={setTriggerSegmentOpen}
                            />
                          </Flex>
                          <Flex
                            flexDirection="column"
                            flex={1}
                            variant="box"
                            width={2 / 5}
                          >
                            <Box
                              p={2}
                              color="white"
                              bg={
                                (values.companyStatuses &&
                                  values.companyStatuses[churnedStatusIndex]
                                    ?.color) ||
                                "secondary"
                              }
                              onClick={() => {
                                if (showColor === churnedStatusIndex) {
                                  setShowColor(-1);
                                } else {
                                  setShowColor(churnedStatusIndex);
                                }
                              }}
                            >
                              <Text fontWeight="bold">Churned</Text>
                            </Box>
                            <Flex flex={1} p={2}>
                              <Text p={2}>
                                Capture the reasons why by setting up your
                                <Link
                                  mx={2}
                                  href="/settings/account/churnReasons"
                                >
                                  churn reasons
                                </Link>
                              </Text>
                            </Flex>
                            <ColorEditor
                              index={churnedStatusIndex}
                              {...formProps}
                              showColor={showColor}
                              setShowColor={setShowColor}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>
                )}
              />
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default CompanyStatusForm;

const TransitionEditor = ({
  index,
  values,
  setTriggerSegmentOpen,
  triggerSegmentOpen,
  arrow,
  setFieldValue,
  ...restProps
}) => {
  let transitionCount =
    (values.companyStatuses[index] &&
      values.companyStatuses[index].config?.andFields?.length) ||
    0;
  return (
    <Flex>
      <Button
        variant="ghost"
        mx={2}
        onClick={() => setTriggerSegmentOpen(index)}
      >
        <Text color="brandLight" fontSize="sm">
          {arrow}
        </Text>
        {transitionCount > 0 ? (
          <Text>{transitionCount} transition condition(s)</Text>
        ) : (
          <Text mx={1} fontSize="sm">
            add transition condition(s)
          </Text>
        )}
      </Button>
      {triggerSegmentOpen > 0 && (
        <SegmentConfigForm
          open={index === triggerSegmentOpen}
          field={values.companyStatuses[index].config || {}}
          fieldName={`companyStatuses[${index}].config`}
          onClose={() => {
            setTriggerSegmentOpen(-1);
          }}
          onSave={(newValues) => {
            setTriggerSegmentOpen(-1);
            setFieldValue(`companyStatuses[${index}].config`, newValues);
          }}
          {...restProps}
        />
      )}
    </Flex>
  );
};

const ColorEditor = ({ index, setShowColor, showColor, setFieldValue }) => {
  return showColor >= 0 && showColor === index ? (
    <Flex
      sx={{
        position: "relative",
      }}
      flex={1}
    >
      <Flex
        sx={{
          position: "absolute",
          top: 30,
          left: -10,
          zIndex: 2,
        }}
      >
        <TwitterPicker
          color={"#ff0000"}
          onChange={(color) => {
            setFieldValue(`companyStatuses[${showColor}].color`, color?.hex);
            setShowColor(-1);
          }}
        />
      </Flex>
    </Flex>
  ) : null;
};
