import React, { useState } from "react";
import { format } from "date-fns";
import { withRouter } from "react-router-dom";
import Avatar from "react-avatar";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Flex, Text, Box, Link, Image } from "rebass";
import PropTypes from "prop-types";
import Table from "Components/Common/Table";
import ModelHeader from "Components/Common/ModelHeader";
import { MODEL_TYPES } from "consts";
import { MailIcon, OpenMailIcon } from "Components/Common/Icons";
import { useTheme } from "emotion-theming";
import moment from "moment";
import MailMessageMutation from "../../../graphql/mutations/MailMessage.gql";
import AssignedTo from "Components/CommandCenter/Tasks/AssignedTo";

function InboxTable({
  detailedView,
  loading,
  data,
  onSelected,
  onSelectedRowsChange,
  selectedRows,
  onSave,
}) {
  const [save] = useMutation(MailMessageMutation, {});
  const theme = useTheme();

  const updateMailMessage = (row) => {
    save({
      variables: {
        id: row.id,
        mailRead: row.mailRead,
      },
    }).then(
      () => {
        onSave && onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const columns = [
    {
      name: " ",
      width: "30px",
      cell: (row) => {
        return (
          <Flex
            justifyContent="center"
            onClick={() => updateMailMessage(row)}
            px={1}
            alignItems="center"
            sx={{ "&:hover": { backgroundColor: "brandHighlight" } }}
            color="brandLight"
          >
            {row.mailRead && <OpenMailIcon />}
            {!row.mailRead && <MailIcon />}
          </Flex>
        );
      },
    },
    {
      name: "Subject",
      cell: (row) => {
        return (
          <Flex data-tag="allowRowEvents" alignItems="center">
            <Text>{row.subject}</Text>
          </Flex>
        );
      },
    },
    {
      name: "To",
      cell: (row) => {
        //this will need to be fixed so that if theres more than one name it adds a space or comma or something

        return (
          <Flex>
            {row.endUsers.map((user) => {
              let id = user.id;
              return (
                <Link key={user.id} href={`/inbox/accounts/${id}`} mx={2}>
                  <Box>
                    <ModelHeader
                      data-tag="allowRowEvents"
                      type={MODEL_TYPES.EndUser.name}
                      name={user.name}
                    />
                    <Text variant="muted" fontSize={"xxs"}>
                      {user?.email}
                    </Text>
                  </Box>
                </Link>
              );
            })}
            {row.companies.map((company) => {
              let id = company.id;
              return (
                <Link key={company.id} href={`/inbox/accounts/${id}`} mx={2}>
                  <Box>
                    <ModelHeader
                      data-tag="allowRowEvents"
                      type={MODEL_TYPES.Company.name}
                      name={company.name}
                    />
                  </Box>
                </Link>
              );
            })}
          </Flex>
        );
      },
    },
    {
      name: "From",
      selector: (row) => row.fromUser?.name,
      sortable: true,
      width: "20%",
      cell: (row) => {
        return <AssignedTo user={row.fromUser} detailed />;
      },
    },
    {
      name: "Date Received",
      selector: (row) => row.receivedAt,
      sortable: true,
      width: "120px",
      cell: (row) => {
        return (
          <Flex>
            <Text>
              {row.receivedAt && moment(row.receivedAt).format("DD MMM YYYY")}
            </Text>
          </Flex>
        );
      },
    },
  ];

  const SubComponent = (row) => {
    //This makes too many requests at the moment, needs to be adjusted
    if (row?.data.mailRead === false) {
      updateMailMessage(row.data);
    }

    return (
      <Flex flexDirection={"column"} px={3}>
        <Box px={5} bg="gray.100">
          <Flex my={3} flex={1} flexDirection={"column"}>
            {row?.data.bodyHtml ? (
              <Text
                bg="gray.100"
                dangerouslySetInnerHTML={{
                  __html: row?.data.bodyHtml,
                }}
              />
            ) : (
              <Text
                bg="gray.100"
                dangerouslySetInnerHTML={{
                  __html: row?.data.bodyText,
                }}
              />
            )}
          </Flex>
        </Box>
      </Flex>
    );
  };

  return (
    <Table
      title=""
      defaultSortField="dueAt"
      columns={columns.filter((c) => !!c)}
      data={data}
      loading={loading}
      onSelectedRowsChange={onSelectedRowsChange}
      selectableRows
      selectedRows={selectedRows}
      expandableRowsComponent={SubComponent}
      noDataComponent={<NoInboxData />}
    />
  );
}

const NoInboxData = () => {
  return (
    <Flex
      flex={1}
      py={3}
      bg="gray.100"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ borderRadius: "medium" }}
    >
      <Flex flex={1} p={4} flexDirection="column" justifyContent="center">
        <Image
          src={require("assets/images/TaskEmptyState.png")}
          sx={{ height: "120px", objectFit: "contain" }}
        />

        <Text variant="muted" my={3}>
          You have nothing left to do here!
        </Text>
      </Flex>
    </Flex>
  );
};

InboxTable.propTypes = {
  detailedView: PropTypes.bool,
  loading: PropTypes.bool,
  onSelected: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  onSelectedRowsChange: PropTypes.func.isRequired,
  saveProductFilter: PropTypes.func,
};

InboxTable.defaultProps = {
  detailedView: true,
  loading: false,
  data: [],
};
export default withRouter(InboxTable);
