import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import { Prompt } from "react-router-dom";
import Modal from "Components/Common/Modal";
import PropTypes from "prop-types";
import CreatableSelect from "Components/Common/Forms/CreatableSelect";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import ModelHeader from "Components/Common/ModelHeader";
import { RESET_FIELDS, TYPES } from "consts";
import { Formik } from "formik";
import * as Yup from "yup";
import { getProduct } from "hooks/getProduct";
import { SaveIcon, TrashIcon } from "Components/Common/Icons";
import SegmentSubForm from "Components/Filter/Segment/SegmentSubForm";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import TagsEditor from "../../../Common/Tags/TagsEditor";

const typeOptions = TYPES?.map((r) => {
  return { value: r.id, label: r.name };
});
const resetOptions = RESET_FIELDS?.map((r) => {
  return { value: r.id, label: r.name };
});
function EditField(props) {
  const { index, child, typeName } = props;
  const { data } = getProduct();

  return (
    <Modal
      title={
        <Flex>
          <ModelHeader type={typeName} /> Field
        </Flex>
      }
      open={props.open}
      onDismiss={() => {
        props.onDismiss();
      }}
      width="950px"
    >
      <Formik
        enableReinitialize
        initialValues={child || {}}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          displayName: Yup.string().required("Required"),
          type: Yup.string().required("Required"),
          default: Yup.string().when("required", {
            is: true,
            then: Yup.string().required("Required"),
          }),
        })}
        onSubmit={(values) => {
          props.onSave({ ...values, productId: data.currentProduct.id });
        }}
      >
        {(formProps) => {
          const {
            values,
            errors,
            setFieldValue,
            handleBlur,
            handleSubmit,
            dirty,
          } = formProps;
          let optionArray = values.options ? values.options?.split(",") : [];
          let options = optionArray.map((o) => {
            return o;
          });

          return (
            <Flex flexDirection="column" px={3} pb={3}>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex mb={2}>
                <Box pr={2} width={1 / 3}>
                  <CustomFormGroup
                    placeholder="Field Name"
                    label="Field Name"
                    fieldHelpText="The attribute name that is displayed in Userlot"
                    name={`displayName`}
                    value={values.displayName}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    readOnly={child.storage === "model"}
                    disabled={child.storage === "model"}
                    fieldType={"string"}
                    helperText={
                      errors.attributes && errors && errors.displayName
                    }
                  />
                </Box>
                <Flex width={2 / 3}>
                  <CustomFormGroup
                    placeholder="Type"
                    label="Type"
                    name={`type`}
                    type="list"
                    fieldHelpText={`The type of data. ${
                      !!child.apiDisplayName &&
                      "Cannot change data type after save."
                    }`}
                    readOnly={
                      !!child.apiDisplayName || values.access == "calculated"
                    }
                    options={typeOptions}
                    fieldType={"list"}
                    value={values.type}
                    onChange={(name, value) => {
                      setFieldValue(name, value?.value);
                      if (value?.value === "calculated") {
                        setFieldValue("readOnly", true);
                        setFieldValue("calculatedType", "count");
                      } else {
                        setFieldValue("readOnly", false);
                      }
                    }}
                    onBlur={handleBlur}
                    helperText={errors.attributes && errors && errors.type}
                  />
                </Flex>
              </Flex>
              <Flex justifyContent="space-evenly">
                <Box width={1 / 3}>
                  <CustomFormGroup
                    placeholder=""
                    label="Featured"
                    fieldHelpText="This will be displayed in summary and tables"
                    name={`featured`}
                    type="switch"
                    fieldType={"switch"}
                    value={values.featured || false}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.featured && errors.featured}
                  />
                </Box>
                <Box width={1 / 3}>
                  <CustomFormGroup
                    placeholder=""
                    label="Required"
                    fieldHelpText="This attribute must have a value"
                    name={`required`}
                    type="switch"
                    fieldType={"switch"}
                    value={values.required || false}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    readOnly={child.storage === "model"}
                    helperText={errors.required && errors.required}
                  />
                </Box>
                <Box width={1 / 3}>
                  {child.storage === "custom" ? (
                    <CustomFormGroup
                      placeholder=""
                      label="Calculated"
                      fieldHelpText="Calculated field"
                      name={`calculated`}
                      type="switch"
                      fieldType={"switch"}
                      value={values.access === "calculated"}
                      onChange={(name, value) => {
                        setFieldValue("storage", "custom");
                        setFieldValue(
                          "access",
                          value ? "calculated" : "readwrite"
                        );
                        setFieldValue("type", value ? "number" : values.type);
                      }}
                      onBlur={handleBlur}
                      readOnly={!!child.apiDisplayName}
                    />
                  ) : (
                    <CustomFormGroup
                      placeholder=""
                      label="Access"
                      fieldHelpText="This tells you if the data is read|readwrite|write."
                      name={`access`}
                      value={values.access}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      type={"string"}
                      readOnly={true}
                    />
                  )}
                </Box>
              </Flex>

              <Flex mt={2}>
                <Box width={1 / 3}>
                  {values.type === "list" ? (
                    <Flex flex={1} flexDirection="column">
                      <TagsEditor
                        label="List Options"
                        value={options}
                        onChange={(tags) => {
                          setFieldValue("options", tags.join(","));
                        }}
                      />
                      <Text variant="muted">
                        If you remove data from this list you data on your
                        accounts will not be removed. Update your data.
                      </Text>
                    </Flex>
                  ) : (
                    values.calculatedType != "exists" && (
                      <CustomFormGroup
                        placeholder="Default Value"
                        label="Default Value"
                        name="default"
                        type={values.type}
                        fieldType={values.type}
                        fieldHelpText="This attribute will be set to this value"
                        value={values.default}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        helperText={errors.default && errors.default}
                      />
                    )
                  )}
                </Box>

                <Box width={1 / 3}>
                  <Box mx={1} />
                  {values.access === "calculated" && (
                    <CustomFormGroup
                      placeholder="  Frequency"
                      fieldHelpText="Reset this attribute to track stats by period"
                      label="Reset Frequency"
                      name={`resetFrequency`}
                      type="list"
                      fieldType={"list"}
                      options={resetOptions}
                      value={values.resetFrequency}
                      onChange={(name, value) => {
                        setFieldValue(name, value?.value);
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.resetFrequency && errors.resetFrequency
                      }
                    />
                  )}
                </Box>
              </Flex>
              {values.access === "calculated" && (
                <>
                  <Flex flex={1} width="100%">
                    <Button
                      variant={
                        values.calculatedType === "count"
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() => {
                        setFieldValue("default", "0");
                        setFieldValue("calculatedType", "count");
                        setFieldValue("type", "number");
                      }}
                      sx={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      Count
                    </Button>
                    <Button
                      variant={
                        values.calculatedType === "exists"
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() => {
                        setFieldValue("type", "boolean");
                        setFieldValue("calculatedType", "exists");
                      }}
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    >
                      Exists
                    </Button>
                  </Flex>
                  <SegmentSubForm
                    {...formProps}
                    field={values?.config}
                    fieldName="config"
                  />
                </>
              )}
              <Flex my={2} justifyContent="space-between">
                {(child.storage !== "model" || !child.storage) && (
                  <Button
                    variant="ghost"
                    mx={2}
                    onClick={() => props.onDelete(index)}
                  >
                    <Flex alignItems="center">
                      <TrashIcon />
                      <Text ml={2}>Delete</Text>
                    </Flex>
                  </Button>
                )}
                <Flex flexGrow={1} justifyContent="flex-end">
                  <PrimaryButton leftIcon={<SaveIcon />} onClick={handleSubmit}>
                    Save
                  </PrimaryButton>
                </Flex>
              </Flex>
              {child.apiDisplayName && (
                <Text variant="muted">
                  api reference: {child.apiDisplayName}
                </Text>
              )}
            </Flex>
          );
        }}
      </Formik>
    </Modal>
  );
}

EditField.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.array,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
};
export default EditField;
