import React from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text, Box } from "rebass";
import { TrashIcon } from "Components/Common/Icons";
import { ErrorParams } from "Components/Common/Notifications/Error";
import ShowError from "../../../Common/Notifications/Error";
import PropTypes from "prop-types";

function DeleteButton(props) {
  const history = useHistory();
  const { addToast, removeAllToasts } = useToasts();
  const handleDelete = () => {
    removeAllToasts();
    props
      .callback({
        variables: {
          id: props.id,
        },
      })
      .then(
        (response) => {
          var responseData = response.data[Object.keys(response.data)[0]];
          if (!responseData.errors || responseData.errors.length == 0) {
            props.refetch && props.refetch();
            setTimeout(
              () => props.redirectUrl && history.push(props.redirectUrl),
              1000
            );
          } else if (responseData.errors && responseData.errors[0]) {
            addToast(
              <ShowError message={responseData.errors[0]} />,
              ErrorParams
            );
          }
        },
        (error) => {
          addToast(<ShowError message="Save error!" />, ErrorParams);
        }
      );
  };

  return props.id === "new" ? (
    <Button
      variant="ghost"
      mx={2}
      onClick={() => {
        props.onCancel && props.onCancel();
      }}
    >
      <Flex alignItems="center">
        <Text>Cancel</Text>
      </Flex>
    </Button>
  ) : (
    <Button
      variant={"ghost"}
      mx={2}
      color="red"
      bg="white"
      sx={{
        "&:hover": {
          backgroundColor: "redLight",
          color: "red",
        },
      }}
      onClick={() => {
        addToast(
          <Flex flexDirection="column">
            <Text my={2}>
              Are you sure you want to delete this {props.name}?
            </Text>
            <Flex justifyContent="flex-end">
              <Button variant="error" onClick={handleDelete}>
                Delete
              </Button>
            </Flex>
          </Flex>,
          ErrorParams
        );
      }}
    >
      <Flex alignItems="center">
        {props.useIcon && (
          <Box mr={2}>
            <TrashIcon />
          </Box>
        )}
        <Text>{props.children || "Delete"}</Text>
      </Flex>
    </Button>
  );
}
DeleteButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  redirectUrl: PropTypes.string,
  useIcon: PropTypes.bool,
  callback: PropTypes.func,
  onCancel: PropTypes.func,
  refetch: PropTypes.func,
  children: PropTypes.node,
};
export default DeleteButton;
