import React, { useEffect, useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Flex, Button, Text, Box, Link, Image } from "rebass";
import SurveyQuery from "../../../../graphql/queries/Surveys.gql";
import Loader from "../../../Common/Loader";
import { useQuery } from "@apollo/react-hooks";
import { HelpIcon, MailIcon, PlusIcon, TickIcon } from "../../../Common/Icons";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import { EmailDeliveryType } from "./SurveyQuestionForm";
import { RiGlobalLine } from "react-icons/ri";
import { getCurrentUser } from "hooks/getCurrentUser";
import RadioGroup from "../../../Common/RadioGroup";
import moment from "moment";

function Surveys(props) {
  const history = useHistory();
  const { id } = useParams();
  const { currentUser } = getCurrentUser();
  const [enabledState, setEnabledState] = useState("all");

  const { data, loading, error } = useQuery(SurveyQuery);
  let surveys = data?.surveys;

  surveys =
    enabledState == "active" ? data?.surveys.filter((w) => w.enabled) : surveys;

  surveys =
    enabledState == "mine"
      ? data?.surveys.filter((w) => w.createdBy?.id === currentUser?.id)
      : surveys;
  return (
    <>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={1} justifyContent="space-between">
            <Flex flex={1}>
              <Text variant="h2">Surveys</Text>
            </Flex>
            <Box mx={2} width={1 / 3}>
              <RadioGroup
                name={`enabledState`}
                selectedOption={enabledState}
                noFlex
                options={[
                  { value: "all", label: "All" },
                  { value: "mine", label: "Show only mine" },
                ]}
                onChange={(name, value) => {
                  setEnabledState(value?.value);
                }}
              />
            </Box>
            <Button
              variant="action"
              onClick={() => {
                history.push("/settings/product/surveys/new");
              }}
            >
              <Flex>
                <PlusIcon /> Add Survey
              </Flex>
            </Button>
          </Flex>

          {loading && <Loader />}
          {surveys?.map((item) => (
            <Flex
              key={item.id}
              variant="box"
              bg={item.id === id ? "brandHighlight" : "white"}
              sx={{ cursor: "pointer", minHeight: "60px" }}
              p={2}
              my={2}
              px={4}
              alignItems="center"
              onClick={() =>
                history.push(`/settings/product/surveys/${item.id}`)
              }
            >
              <Text color="brandLight" mr={2}>
                {item.deliveryType == EmailDeliveryType ? (
                  <MailIcon size={20} />
                ) : (
                  <RiGlobalLine size={20} />
                )}
              </Text>
              <Flex flex={1} flexDirection={"column"}>
                <Text variant="h4">{item.name}</Text>
                <Flex justifyContent="space-between">
                  <Text variant="muted">{item.surveyType?.toUpperCase()}</Text>
                </Flex>
              </Flex>
              <Flex width={1 / 6} flexDirection="column">
                <Flex>
                  <Text variant="label">Sent / Recieved</Text>
                </Flex>
                <Flex>
                  <Flex>{item.requestCount}</Flex>/
                  <Flex>{item.responseCount}</Flex>
                </Flex>
              </Flex>
              <Flex width={1 / 6} flexDirection="column">
                <Text variant="label">Created By</Text>
                <Text>{item.createdBy?.name}</Text>
              </Flex>
              <Flex width={1 / 6} flexDirection="column">
                <Text variant="label">Last Updated</Text>
                <Text>{moment(item.updatedAt).format("DD MMM YYYY")}</Text>
              </Flex>
            </Flex>
          ))}
          {data?.surveys.length > 0 && surveys.length === 0 && (
            <Flex m={5} alignItems={"center"} justifyContent="center">
              <Text>No surveys matching your filters</Text>
            </Flex>
          )}
          {(!data || data.surveys?.length === 0) && <EmptyState />}
        </Flex>
      </Flex>
    </>
  );
}

export default withRouter(Surveys);

const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex
      p={5}
      flex={1}
      justifyContent="center"
      bg="gray.100"
      sx={{
        borderColor: "accent",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        minHeight: 400,
      }}
    >
      <Flex m={4} flex={1} justifyContent="center" flexDirection="column">
        <Text my={3} fontWeight="bold">
          Surveys help collect valuable data from your customers about their
          satisfaction.
        </Text>

        <Flex my={3}>
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/surveys/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Surveys
          </PrimaryButton>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ marginTop: "-10%", height: "150px", objectFit: "contain" }}
        />
      </Flex>
    </Flex>
  );
};
