import React, { useState } from "react";
import { Flex, Image, Text } from "rebass";
import Table from "Components/Common/Table";
import { useHistory } from "react-router-dom";
import { useDynamicSchema } from "hooks/DynamicSchema";
import { MODEL_TYPES } from "consts";
import CompanyFilter from "../../Dashboard/CompanyHealth/CompanyFilter";
import CompanyMenu from "../../Dashboard/CompanyHealth/CompanyMenu";
import PropTypes from "prop-types";
import { getPaidFields } from "../../../hooks/DynamicSchema";

function Companies(props) {
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);

  const { columns } = useDynamicSchema(
    MODEL_TYPES.Company,
    getPaidFields(MODEL_TYPES.Company)
  );

  const dataTable = props.error ? [] : props.data && props.data;
  return (
    <Flex flex={1} flexDirection="column">
      {props.title && (
        <Flex my={2} justifyContent="space-between">
          <Text variant="h3">{props.title}</Text>
        </Flex>
      )}
      <Table
        title=""
        defaultSortField="name"
        columns={columns}
        data={dataTable}
        selectableRows
        exportable
        configurable
        selectedRows={selectedRows}
        onSelectedRowsChange={(rows) => {
          setSelectedRows(rows);
          props.onSelectedRowsChange && props.onSelectedRowsChange(rows);
        }}
        onSort={props.onSort}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        paginationTotalRows={props.paginationTotalRows}
        search={
          <CompanyFilter
            module={props.module}
            onChange={() => {
              setSelectedRows([]);
              props.refetch();
            }}
          />
        }
        actions={
          <CompanyMenu
            moduleType="Paid"
            selectedRows={selectedRows}
            parentType="Company"
            onChange={() => {
              setSelectedRows([]);
              props.refetch();
            }}
          />
        }
        loading={props.loading}
        onRowClicked={(p) => {
          const route = window.location.pathname.endsWith(
            MODEL_TYPES.Company.route
          )
            ? window.location.pathname.replace("/", "")
            : `${window.location.pathname.replace("/", "")}${
                MODEL_TYPES.Company.route
              }`;
          history.push(`/${route}/${p.id}`);
        }}
        noDataComponent={
          props.error ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              m={4}
            >
              <Image
                src={require("assets/images/AccountsEmpty.png")}
                width={80}
              />
              <Text my={2} variant="muted">
                No accounts returned due to server error -
              </Text>
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              m={4}
            >
              <Image
                src={require("assets/images/AccountsEmpty.png")}
                width={80}
              />
              <Text my={2} variant="muted">
                No accounts returned. Connect accounts or check your filters.
              </Text>
            </Flex>
          )
        }
      />
    </Flex>
  );
}

Companies.propTypes = {
  title: PropTypes.string,
  module: PropTypes.string,
  parentType: PropTypes.oneOf(["EndUser", "Company"]),
  data: PropTypes.array,
  error: PropTypes.array,
  loading: PropTypes.bool,
  onSelectedRowsChange: PropTypes.func,
  refetch: PropTypes.func,
};

export default Companies;
