import React, { useState } from "react";
import DoubleStatCard from "Components/Dashboard/Widgets/DoubleStatCard";
import WidgetWrapper from "./WidgetWrapper";
import Loader from "../../Common/Loader";

const CalcIncrease = (a, b) => {
  let value = b > 0 ? ((a - b) / b) * 100 : 100;
  return value.toFixed(0);
};
function ConversionSummary({ data, loading }) {
  return (
    <WidgetWrapper title="Conversion Accounts">
      {loading ? (
        <Loader />
      ) : (
        <DoubleStatCard
          value={data?.trial?.total || 0}
          label="Total"
          increase={CalcIncrease(data?.trial?.total, data?.trial?.prevTotal)}
        />
      )}
    </WidgetWrapper>
  );
}

export default ConversionSummary;
