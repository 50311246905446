import { Input } from "@rebass/forms";
import React from "react";
import { Flex } from "rebass";
import { CloseIcon, SearchIcon } from "../../Icons";

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  onClear,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = React.useState(initialValue);

  const handleInputChange = (event) => setValue(event.target.value);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Flex sx={{ position: "relative" }} alignItems="center" mx={2}>
      <Input
        {...props}
        value={value}
        onChange={handleInputChange}
        rounded="large"
        sx={{
          backgroundColor: "white",
        }}
      />
      <Flex
        sx={{
          position: "absolute",
          right: 4,
          top: 0,
          bottom: 0,
          zIndex: 2,
        }}
        width={35}
        alignItems="center"
        justifyContent="center"
        color="line"
        p={2}
        onClick={onClear}
      >
        <CloseIcon size={20} />
      </Flex>
      <Flex
        sx={{
          position: "absolute",
          right: 1,
          top: 0,
          bottom: 0,
          zIndex: 2,
        }}
        width={35}
        alignItems="center"
        justifyContent="center"
        color="brandLight"
        p={2}
      >
        <SearchIcon size={20} />
      </Flex>
    </Flex>
  );
};

export default DebouncedInput;
