import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text, Button, Box, Link } from "rebass";
import PropTypes from "prop-types";

const activityType = (type) => {
  switch (type) {
    case "company_lifecycle_changed":
      return "Lifecycle Changed";
    case "company_owner_changed":
      return "Owner Changed";
    default:
      return _.capitalize(_.snakeCase(type).replaceAll("_", " "));
  }
};
export const ActivityItemTitle = ({ activity }) => {
  return (
    <Flex>
      <Text fontWeight={"bold"} mr={1}>
        {activityType(activity.activityType)}
      </Text>
    </Flex>
  );
};
function ActivityItem({ activity, refetch }) {
  const [open, setOpen] = useState(false);

  return (
    <Flex flexDirection={"column"} bg="gray.100" p={3}>
      <Flex alignItems={"center"}>
        Changed from
        <Text px={1} fontWeight={"bold"}>
          {activity.fields.old_value || "not set"}
        </Text>
        to
        <Text px={1} fontWeight={"bold"}>
          {activity.fields.value}
        </Text>
      </Flex>
    </Flex>
  );
}
ActivityItem.propTypes = {
  activity: PropTypes.object,
};
export default ActivityItem;
