import React from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Box, Flex, Image, Text } from "rebass";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { PlusIcon } from "../../../Common/Icons";
import TagForm from "./TagForm";
import TagList from "./TagList";
import Settings from "../";

function Tag(props) {
  const { id } = useParams();
  return (
    <Settings>
      <Flex width="100%">
        <Flex
          p={2}
          bg="gray.100"
          sx={{
            height: "100%",
            maxWidth: "300px",
          }}
          flex={1}
        >
          <TagList />
        </Flex>
        <Flex flex={1} p={4} bg="white">
          {id && <TagForm {...props} />}
          {!id && <EmptyState />}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(Tag);

const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex flex={1} justifyContent="center" mt={6}>
      <Flex justifyContent="center" mx={3}>
        <Image
          src={require("assets/images/Support.png")}
          sx={{ height: "200px", objectFit: "contain" }}
        />
      </Flex>
      <Flex alignItems="flex-start" flexDirection="column">
        <Text variant="h3">Tags</Text>
        <Text my={3}>
          Set up all your Tags that are expected from your product. These can be
          added here or in the account, user modules.
        </Text>
        <Flex my={3} justifyContent="space-between">
          <PrimaryButton
            onClick={() => {
              history.push("/settings/account/tags/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Tag
          </PrimaryButton>
          <Box mx={3} />
        </Flex>
      </Flex>
    </Flex>
  );
};
