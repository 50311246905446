import React, { useState } from "react";
import { useTheme } from "emotion-theming";
import { Text, Flex } from "rebass";
import Label from "./Label";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const SelectAsync = (props) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: theme.fontSizes.sm,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: theme.colors.gray["200"],
      borderWidth: "thin",
      backgroundColor: "white",
      boxShadow: "none",
      padding: 0,
      paddingLeft: "0px",
      width: props.width ? props.width : "200px",
      borderRadius: theme.radii.medium,
      fontFamily: theme.fonts.body,
      fontSize: theme.fontSizes.sm,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const handleChange = (value) => {
    // this is going to call setFieldValue and manually update values
    props.onChange(props.name, value);
  };

  const handleInputChange = (value) => {
    // this is going to call setFieldTouched and manually update touched
    setSearchText(value);
    return value;
  };
  return (
    <Flex flex={1} flexDirection="column" alignItems={"flex-start"}>
      {props.label && <Label name={props.name}>{props.label}</Label>}

      {props.readOnly && <Text>{props.value?.label}</Text>}
      {!props.readOnly && (
        <AsyncSelect
          defaultOptions
          loadOptions={props.loadOptions}
          value={props.value}
          onInputChange={handleInputChange}
          onChange={handleChange}
          name={props.name}
          placeholder={props.placeholder}
          styles={customStyles}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          isClearable={true}
          noOptionsMessage={props.noOptionsMessage}
        />
      )}
      {props.helperText && (
        <Text m={0} px={1} variant="muted" color="red" htmlFor={props.name}>
          {props.helperText}
        </Text>
      )}
    </Flex>
  );
};

SelectAsync.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  noOptionsMessage: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  isMulti: PropTypes.bool,
  width: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default SelectAsync;
