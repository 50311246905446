import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Flex, Text, Box, Link, Image, Button } from "rebass";
import PropTypes from "prop-types";
import TaskStatusQuery from "../../../graphql/queries/TaskStatus.gql";
import DndContext from "./Kanban/DndContext";
import DroppableSection from "./Kanban/DroppableSection";
import DraggableItem from "./Kanban/DraggableItem";
import TasksQuery from "../../../graphql/queries/Tasks.gql";
import Avatar from "react-avatar";
import { CalendarIcon, PriorityIcon, UserIcon } from "Components/Common/Icons";
import ModelHeader from "Components/Common/ModelHeader";
import { MODEL_TYPES } from "../../../consts";
import TaskMutation from "../../../graphql/mutations/Task.gql";
import { RiUser2Fill, RiUserFill } from "react-icons/ri";
import TaskTimeline from "./TaskTimeline";
import _ from "lodash";

function TaskKanban({ detailedView, loading, view, onSelected, onSave, data }) {
  const history = useHistory();
  const { data: statuses } = useQuery(TaskStatusQuery);

  const [save] = useMutation(TaskMutation);

  const updateStatus = (task) => {
    save({
      variables: {
        tasks: [
          {
            id: task.id,
            actionableId: task.actionableId,
            actionableType: task.actionableType,
            recurrenceCode: task.recurrenceCode,
            title: task.title,
            description: task.description,
            taskCategoryId: task.taskCategoryId,
            priority: task.priority,
            dueAt: task.dueAt,
            endAt: task.endAt,
            userId: task.user?.id,
            teamId: task.team?.id,
            taskStatusId: task.taskStatusId,
            taskStatusName: task.taskStatusName,
            busy: task.busy,
          },
        ],
      },
      optimisticResponse: {
        __typename: "Mutation",
        task: {
          tasks: [task],
        },
      },
    }).then(
      (response) => {
        //onSave && onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <DndContext
      items={data}
      onChange={(d) => {
        const item = d;

        const task = item.items.find((t) => t.id === item.draggableId);
        const status = statuses.taskStatuses?.find(
          (s) => s.id == item.droppableId
        );
        const taskStatusId = item.droppableId;
        const taskStatusName = status?.label;
        updateStatus({
          ...task,
          taskStatusId: taskStatusId,
          taskStatusName: taskStatusName,
        });
      }}
    >
      <Flex flex={1} w="100%" bg="gray.100">
        {statuses?.taskStatuses?.map((s) => (
          <DroppableSection key={s.id} droppableId={`${s.id}`}>
            <Flex flex={1} flexDirection="column" mx={3} my={3}>
              <Flex alignItems="center">
                <Flex>
                  <Box
                    sx={{
                      height: 18,
                      width: 18,
                      borderRadius: 99,
                    }}
                    bg={s.info || "gray.100"}
                  />
                  <Text variant="h3" color="brandLight" mx={2}>
                    {s.label}
                  </Text>
                </Flex>
                <Flex
                  ml={2}
                  alignItems="center"
                  justifyContent="center"
                  bg="brandHighlight"
                  color="brandLight"
                  fontSize="xs"
                  px={2}
                  py={1}
                  sx={{ borderRadius: "medium" }}
                >
                  {data.filter((task) => task.taskStatusId === s.id).length}
                </Flex>
              </Flex>
              {data.filter((task) => task.taskStatusId === s.id).length ==
                0 && (
                <Flex p={2} m={2}>
                  <Text variant="muted">No tasks</Text>
                </Flex>
              )}
              {data
                .filter((task) => task.taskStatusId === s.id)
                .sort((a, b) => new Date(a.dueAt) - new Date(b.dueAt))
                .map((task, index) => (
                  <DraggableItem key={task.id} item={task} index={index}>
                    <Flex
                      flex={1}
                      flexDirection="column"
                      p={2}
                      m={2}
                      fontSize="xs"
                      onClick={() => onSelected(task)}
                    >
                      <Flex alignItems="center" sx={{ cursor: "pointer" }}>
                        <Text
                          variant="h3"
                          color="text"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {task.title}
                        </Text>
                        <Text mr={2}>
                          <PriorityIcon value={task.priority} size={25} />
                        </Text>
                      </Flex>
                      <Flex my={2} alignItems="center">
                        <Flex>
                          <CompanyDetail
                            row={task}
                            onClick={(route) => history.push(route)}
                          />
                        </Flex>
                      </Flex>
                      {task.user && (
                        <Flex my={2} alignItems="center">
                          <UserDetail
                            row={task}
                            onClick={(route) => history.push(route)}
                          />
                        </Flex>
                      )}
                      <Flex
                        flex={1}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Flex flex={1}>
                          <AssignedTo row={task} />
                        </Flex>
                        <Flex flex={1}>
                          <TaskTimeline row={task} />
                        </Flex>
                      </Flex>
                    </Flex>
                  </DraggableItem>
                ))}
            </Flex>
          </DroppableSection>
        ))}
      </Flex>
    </DndContext>
  );
}

const UserDetail = ({ row, onClick }) => {
  const name = row.actionable?.name;
  const id = row.actionable?.id;
  return (
    row.actionableType === MODEL_TYPES.EndUser.name && (
      <Flex
        flexDirection="column"
        onClick={() => {
          onClick(`/tasks/accounts/${row.actionable?.company?.id}/users/${id}`);
        }}
      >
        <Link>
          <ModelHeader
            data-tag="allowRowEvents"
            type={MODEL_TYPES.EndUser.name}
            name={name}
          />
        </Link>
        <Text
          variant="muted"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.actionable.email}
        </Text>
      </Flex>
    )
  );
};
const CompanyDetail = ({ row, onClick }) => {
  const name = row.actionable?.company?.name || row.actionable?.name;
  const id = row.actionable?.company?.id || row.actionable?.id;
  return (
    row.actionableType && (
      <Flex
        color="brandLight"
        alignItems="center"
        flex={1}
        data-tag="allowRowEvents"
        onClick={() => {
          onClick(`/tasks/accounts/${id}`);
        }}
      >
        <ModelHeader
          data-tag="allowRowEvents"
          type={MODEL_TYPES.Company.name}
          name={name}
        />
      </Flex>
    )
  );
};
const AssignedTo = ({ row }) => {
  return row.user ? (
    <Flex data-tag="allowRowEvents" alignItems="center">
      <Avatar
        src={row.user?.avatarUrl}
        name={`${row.user?.firstName} ${row.user?.lastName}`}
        email={row.user?.email}
        size="20"
        round
        textSizeRatio={2}
      />
      <Text data-tag="allowRowEvents" mx={2} fontSize="xxs">
        {row.user?.name}
      </Text>
    </Flex>
  ) : (
    <Flex
      justifyContent="center"
      data-tag="allowRowEvents"
      alignItems="flex-end"
      sx={{
        backgroundColor: "gray.200",
        borderRadius: 99,
        width: 20,
        height: 20,
      }}
      color="white"
    >
      <RiUser2Fill size="16" />
    </Flex>
  );
};

TaskKanban.propTypes = {
  detailedView: PropTypes.bool,
  loading: PropTypes.bool,
  view: PropTypes.oneOf(["summary"]),
  onSelected: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

TaskKanban.defaultProps = {
  detailedView: true,
  loading: false,
  view: "summary",
  data: [],
};
export default withRouter(TaskKanban);
