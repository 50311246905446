import React from "react";
import { Flex, Text } from "rebass";
import { ReportIcon } from "Components/Common/Icons";
import Topbar from "Components/Common/Topbar/Topbar";
import { useQuery } from "@apollo/client";
import { TaskCategories } from "Components/CommandCenter/Tasks/TaskDefaults";
import {
  InboxIcon,
  SatisfactionIcon,
  SupportIcon,
  TasksIcon,
} from "../../Common/Icons";
import TasksQuery from "../../../graphql/queries/Tasks.gql";
import { getSearchFilter } from "../../../hooks/getSearchFilter";
import PropTypes from "prop-types";
import TicketQuery from "../../../graphql/queries/Tickets.gql";
import MailMessageQuery from "../../../graphql/queries/MailMessage.gql";
import { defaultDate } from "Components/CommandCenter/DatePicker";
import moment from "moment";
import { ticketOptionTypes } from "Components/CommandCenter/Tickets/TicketFilter";
import { mailOptionTypes } from "../../CommandCenter/Inbox/InboxFilter";
import SurveyRequestQuery from "../../../graphql/queries/SurveyRequests.gql";

function CommandCenterTopbar(props) {
  const { data: searchFilters } = getSearchFilter();
  const ticketOptions = ticketOptionTypes.map((o) => o.value);
  const startOffset = moment(defaultDate?.startDate).diff(
    moment({ hours: 0 }),
    "days"
  );
  const endOffset = moment(defaultDate?.endDate).diff(moment(), "days");

  const tasksQuery = useQuery(TasksQuery, {
    variables: {
      ownerUserId: searchFilters?.filters?.ownerUserId,
      activityTypes: TaskCategories().map((o) => o.value),
      startDays: 0,
      endDays: 0,
      includeDone: false,
      includeOverdue: true,
    },
  });
  const { data: tasks } = tasksQuery;
  const ticketsQuery = useQuery(TicketQuery, {
    variables: {
      ticketOptions,
      startDays: startOffset,
      endDays: endOffset,
      ownerUserId: searchFilters?.filters?.ownerUserId,
      segmentId: searchFilters?.filters?.segmentId,
    },
  });
  const { data: tickets } = ticketsQuery;
  const { data: mailMessage } = useQuery(MailMessageQuery, {
    variables: {
      mailOptions: mailOptionTypes.map((o) => o.value),
      ownerUserId: searchFilters?.filters?.ownerUserId,
      teamId: searchFilters?.filters?.teamId,
      segmentId: searchFilters?.filters?.segmentId,
      startDays: startOffset || -30,
      endDays: endOffset || 0,
    },
  });
  const surveyRequestQuery = useQuery(SurveyRequestQuery, {
    variables: {
      ownerUserId: searchFilters?.filters?.ownerUserId,
      teamId: searchFilters?.filters?.teamId,
      segmentId: searchFilters?.filters?.segmentId,
      startDays: startOffset || -30,
      endDays: endOffset || 0,
    },
  });
  const { data: surveyRequests } = surveyRequestQuery;

  const taskCount = tasks?.tasks.length || 0;
  const ticketCount = tickets?.tickets.filter(
    (t) => t.status == "Open" || t.status == "Pending"
  ).length;
  const mailMessagesCount = mailMessage?.mailMessage.length;
  const surveyResponseCount = surveyRequests?.surveyResponses.filter(
    (s) => !s.acknowledged
  ).length;

  return (
    <Topbar
      title="Command Center"
      menuItems={[
        {
          name: (
            <Flex alignItems="center">
              <Text>Activities</Text>
              <Flex
                ml={2}
                alignItems="center"
                justifyContent="center"
                bg="brandHighlight"
                px={2}
                py={1}
                sx={{ borderRadius: "medium" }}
              >
                {taskCount || 0}
              </Flex>
            </Flex>
          ),
          icon: TasksIcon,
          link: "/tasks",
        },
        {
          name: (
            <Flex alignItems="center">
              <Text>Support</Text>
              <Flex
                ml={2}
                alignItems="center"
                justifyContent="center"
                bg="brandHighlight"
                px={2}
                py={1}
                sx={{ borderRadius: "medium" }}
              >
                {ticketCount || 0}
              </Flex>
            </Flex>
          ),
          icon: SupportIcon,
          link: "/support",
        },
        {
          name: (
            <Flex alignItems="center">
              <Text>Inbox</Text>
              <Flex
                ml={2}
                alignItems="center"
                justifyContent="center"
                bg="brandHighlight"
                px={2}
                py={1}
                sx={{ borderRadius: "medium" }}
              >
                {mailMessagesCount || 0}
              </Flex>
            </Flex>
          ),
          icon: InboxIcon,
          link: "/inbox",
        },
        {
          name: (
            <Flex alignItems="center">
              <Text>Satisfaction</Text>
              <Flex
                ml={2}
                alignItems="center"
                justifyContent="center"
                bg="brandHighlight"
                px={2}
                py={1}
                sx={{ borderRadius: "medium" }}
              >
                {surveyResponseCount || 0}
              </Flex>
            </Flex>
          ),
          icon: SatisfactionIcon,
          link: "/surveys",
        },
        {
          name: "Reports",
          icon: ReportIcon,
          link: "/commandcenter/report",
          disabled: true,
        },
      ]}
      crumbs={props.crumbs}
    />
  );
}

CommandCenterTopbar.propTypes = {
  crumbs: PropTypes.object,
};
export default CommandCenterTopbar;
