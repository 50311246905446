import React from "react";
import { Box, Flex, Text } from "rebass";
import { MODEL_TYPES } from "consts";
import Modal from "Components/Common/Modal";
import PropTypes from "prop-types";
import AddCompanySentiment from "Components/Data/Company/AddCompanySentiment";
import AssignOwner from "Components/Data/Company/OwnerForm";
import AssignCompanyStatus from "Components/Data/Company/AssignCompanyStatus";
import TaskForm from "Components/CommandCenter/Tasks/TaskForm";
import CommentForm from "../../Activity/CommentForm";
import AssignCompanyPlan from "../../Data/Company/AssignCompanyPlan";
import AssignPlaybook from "../../Data/Company/AssignPlaybook";
import Avatar from "react-avatar";
import { RiArrowRightCircleLine } from "react-icons/ri";
import StatusBadge from "../../Data/Company/StatusBadge";
import { CompanyIcon, UserIcon } from "../../Common/Icons";
import AssignTags from "../../Data/Company/AssignTags";
import AddCompanySatisfaction from "../../Data/Company/AddCompanySatisfaction";

const ActionForm = (props) => {
  const ids = (props.selectedRows && props.selectedRows.map((r) => r.id)) || [];
  const parentType = props.parentType;
  switch (props.formType) {
    case "Comment":
      return (
        <CommentForm
          actionableIds={ids}
          parentType={parentType}
          onUpdate={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    case "Activity":
      return (
        <TaskForm
          actionableIds={ids}
          activityOnly
          hideCalendar
          parentType={parentType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
          open
        />
      );
    case "Task":
      return (
        <TaskForm
          actionableIds={ids}
          hideCalendar
          parentType={parentType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
          open
        />
      );
    case "Owner":
      return (
        <AssignOwner
          companyIds={ids}
          parentType={parentType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    case "ShadowOwner":
      return (
        <AssignOwner
          companyIds={ids}
          parentType={parentType}
          shadow
          onSave={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    case "Status":
      return (
        <AssignCompanyStatus
          companyIds={ids}
          parentType={parentType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    case "Feedback":
      return (
        <AddCompanySentiment
          ids={ids}
          parentType={parentType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    case "Satisfaction":
      return (
        <AddCompanySatisfaction
          ids={ids}
          parentType={parentType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    case "Plan":
      return (
        <AssignCompanyPlan
          companyIds={ids}
          parentType={parentType}
          moduleType={props.moduleType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    case "Playbook":
      return (
        <AssignPlaybook
          ids={ids}
          parentType={parentType}
          moduleType={props.moduleType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    case "Tag":
      return (
        <AssignTags
          ids={ids}
          parentType={parentType}
          moduleType={props.moduleType}
          onSave={() => {
            props.onSave && props.onSave();
          }}
        />
      );
    default:
      return "No input";
  }
};

ActionForm.propTypes = {
  onSave: PropTypes.func,
  selectedRows: PropTypes.array,
  formType: PropTypes.string,
  moduleType: PropTypes.string,
};

const ActionSubtitle = (formType, data) => {
  let title = "";
  switch (formType) {
    case "Owner":
      title = data.details?.owner?.name;
      break;
    case "Status":
      title = data.details?.status?.name;
      break;
    case "Plan":
      title = data.details?.companyPlan?.name;
      break;
  }
  return title;
};
const ActionTitle = (formType) => {
  let title = "";
  switch (formType) {
    case "Comment":
      title = "Add Note";
      break;
    case "Activity":
      title = "Add Activity";
      break;
    case "Task":
      title = "Add Activity";
      break;
    case "Owner":
      title = "Update Account Owner";
      break;
    case "ShadowOwner":
      title = "Update Account Shadow Owner";
      break;
    case "Status":
      title = "Change Account Status";
      break;
    case "Plan":
      title = "Assign Journey";
      break;
    case "Feedback":
      title = "Update Sentiment";
      break;
    case "Satisfaction":
      title = "Update Satisfaction";
      break;
    case "Playbook":
      title = "Assign Playbook";
      break;
    case "Tag":
      title = "Assign Tags";
      break;
    default:
      title = "No input";
      break;
  }
  return title;
};

function CompanyMenuModal(props) {
  return (
    <Modal
      title={ActionTitle(props.formType)}
      width={1200}
      open={props.open}
      onDismiss={() => {
        props.onDismiss();
      }}
    >
      <Flex pb={4} sx={{ minHeight: 500 }}>
        <Flex flexDirection="column" px={2}>
          <Flex mb={2}>
            <Text variant="label" mr={1}>
              Selected
            </Text>
            <Text variant="label" mr={1}>
              {props.parentType == MODEL_TYPES.Company.name
                ? "Accounts"
                : "Users"}
            </Text>
          </Flex>
          <Flex
            flexDirection={"column"}
            sx={{ overflowY: "auto", maxHeight: 500, maxWidth: 250 }}
          >
            {props.selectedRows.map((c) => (
              <Flex key={c.id} flexDirection={"column"} mx={1} px={3}>
                <Flex alignItems={"center"} my={2} sx={{}}>
                  <Flex
                    width={30}
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    {props.parentType == MODEL_TYPES.Company.name ? (
                      <CompanyIcon size={20} logo={c.logoUrl} />
                    ) : (
                      <UserIcon size={20} />
                    )}
                  </Flex>
                  <Box mx={2}>
                    <Text>{c.name}</Text>
                    <Text variant="muted">
                      {ActionSubtitle(props.formType, c)}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          px={2}
          alignItems="center"
          justifyContent={"center"}
          mx={3}
        >
          <RiArrowRightCircleLine size={30} />
        </Flex>
        <Flex flex={1} flexDirection="column" px={2}>
          <Text variant="label" mb={2}>
            {ActionTitle(props.formType)}
          </Text>
          <ActionForm {...props} />
        </Flex>
      </Flex>
    </Modal>
  );
  å;
}

CompanyMenuModal.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSave: PropTypes.func,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      parentType: PropTypes.oneOf(["Company", "EndUser"]),
    })
  ),
  formType: PropTypes.oneOf([
    "Task",
    "Activity",
    "Comment",
    "Owner",
    "Edit",
    "Churn",
    "Plan",
    "Playbook",
    "Satisfaction",
    "Tag",
  ]),
};
export default CompanyMenuModal;
