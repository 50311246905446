import React, { useState } from "react";
import { Flex, Box, Button } from "rebass";
import { Formik } from "formik";
import Modal from "Components/Common/Modal";
import { getSegment } from "hooks/getSegment";
import * as Yup from "yup";
import SegmentSubForm from "./SegmentSubForm";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";

const _ = require("lodash");
function SegmentConfigForm(props) {
  return (
    <Modal open={props.open} onDismiss={props.onClose} width="1000px">
      <Formik
        initialValues={
          { config: { ...props.field } } || { config: { andFields: [] } }
        }
        autoComplete={false}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          props.onSave(values?.config);
        }}
      >
        {(formProps) => {
          const { values, handleSubmit } = formProps;
          return (
            <Flex px={3} pb={3} alignItems="flex-start" flexDirection="column">
              <SegmentSubForm
                field={values?.config}
                fieldName={"config"}
                {...formProps}
              />
              <Flex width="100%" justifyContent="flex-end" mt={3}>
                <GhostButton onClick={props.onClose}>Close</GhostButton>
                <Box mx={2} />
                <PrimaryButton
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </PrimaryButton>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default SegmentConfigForm;
