import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import SearchFilter from "../graphql/queries/SearchFilter";

export function getSearchFilter() {
  const dataQuery = useQuery(SearchFilter, { fetchPolicy: "network-only" });
  return {
    ...dataQuery,
  };
}
