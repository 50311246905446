import React from "react";
import { Flex, Text, Box, Link, Image } from "rebass";
import { useQuery } from "@apollo/react-hooks";
import { useHistory, Link as RouterLink } from "react-router-dom";
import Table from "Components/Common/Table";
import Loader from "Components/Common/Loader";
import { convertNumber } from "consts";
import WidgetWrapper from "./WidgetWrapper";
import EventsDashboard from "../../../graphql/queries/EventsDashboard";
import numeral from "numeral";

const _ = require("lodash");

function AccountEventSummary(props) {
  const { data, loading } = useQuery(EventsDashboard, {
    variables: {
      searchFilters: {
        ownerUserId: props.filters?.ownerUserId,
        productId: props.filters?.productId,
        daysOffset: 30,
      },
    },
  });

  const topAccounts = _.slice(data?.eventsDashboard?.accountSummary, 0, 5);
  return (
    <WidgetWrapper title="Top 5 Accounts (Usage)">
      {loading && <Loader />}
      <Box width="100%">
        {topAccounts.length > 0
          ? topAccounts.map((account) => {
              return (
                <Flex
                  key={account.key}
                  flex={1}
                  p={2}
                  m={2}
                  justifyContent="space-between"
                  sx={{
                    borderBottomWidth: 1,
                    borderBottomColor: "gray.200",
                    borderBottomStyle: "solid",
                  }}
                >
                  <Text fontSize="xs">
                    <RouterLink
                      data-tag="allowRowAccountEventSummary"
                      to={`/contacts/accounts/${account.key}?Events`}
                      component={Link}
                    >
                      {account.name}
                    </RouterLink>
                  </Text>
                  <Text fontSize="xs" color="brandLight">
                    {`${numeral(account.value).format("0.0")}%`}
                  </Text>
                </Flex>
              );
            })
          : !loading && (
              <Flex flex={1} alignItems="center" justifyContent="center" p={3}>
                <Image
                  src={require("assets/images/AccountsEmpty.png")}
                  width={80}
                />
              </Flex>
            )}
      </Box>
    </WidgetWrapper>
  );
}

export default AccountEventSummary;
