import React, { useState } from "react";
import { Box, Flex, Text } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import TagsEditor from "../../../Common/Tags/TagsEditor";
import RadioGroup from "../../../Common/RadioGroup";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";

function TagForm(props) {
  const { field, setFieldValue, fieldName, values } = props;

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        tags: Yup.array().required("Required"),
        remove: Yup.string().required("Required"),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        return (
          <>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <Flex m={2}>
              <Box width={1 / 3}>
                <RadioGroup
                  name={`${defaultFieldName}.remove`}
                  selectedOption={field?.config?.remove}
                  noFlex
                  options={[
                    { value: "false", label: "Add tags" },
                    { value: "true", label: "Remove tags" },
                  ]}
                  onChange={(name, value) => {
                    setFieldValue(name, value?.value);
                    setFieldValue(
                      `${defaultFieldName}.title`,
                      `Removing tags ${values.config?.tags?.join(",")}`
                    );
                  }}
                />
                {errors?.remove && (
                  <Text variant="muted" color="red">
                    {errors.remove}
                  </Text>
                )}
              </Box>
            </Flex>
            <TagsEditor
              parentType={props.parentType}
              label=""
              value={field?.config?.tags}
              onChange={(t) => {
                setFieldValue(`${defaultFieldName}.tags`, t);
                setFieldValue(
                  `${defaultFieldName}.title`,
                  `Adding Tags ${t.join(",")}`
                );
              }}
            />
            {errors?.tags && (
              <Text variant="muted" color="red">
                {errors.tags}
              </Text>
            )}
          </>
        );
      }}
    </Formik>
  );
}

export default TagForm;
