import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import Page from "Components/Page";
import { Flex, Button, Box } from "rebass";
import CompanyTopbar from "../../Dashboard/CompanyHealth/CompanyTopbar";
import ConversionTopbar from "../../Dashboard/ConversionHealth/ConversionTopbar";
import ProductTopbar from "../../Dashboard/ProductHealth/ProductTopbar";
import ReportTopbar from "../../Dashboard/Reports/ReportTopbar";
import HomeTopbar from "../../Dashboard/Home/HomeTopbar";
import ContactsTopbar from "../../Dashboard/Contacts/ContactsTopbar";
import CommandCenterTopbar from "../../Dashboard/CommandCenter/CommandCenterTopbar";

function EndUserPage(props) {
  let topBar = <CompanyTopbar crumbs={props.crumbs} />;
  if (window.location.pathname.startsWith("/tasks")) {
    topBar = <CommandCenterTopbar crumbs={props.crumbs} />;
  }
  if (window.location.pathname.startsWith("/conversion")) {
    topBar = <ConversionTopbar crumbs={props.crumbs} />;
  }
  if (window.location.pathname.startsWith("/adoption")) {
    topBar = <ProductTopbar crumbs={props.crumbs} />;
  }
  if (window.location.pathname.startsWith("/reports")) {
    topBar = <ReportTopbar crumbs={props.crumbs} />;
  }
  if (window.location.pathname.startsWith("/home")) {
    topBar = <HomeTopbar crumbs={props.crumbs} />;
  }
  if (window.location.pathname.startsWith("/contacts")) {
    topBar = <ContactsTopbar crumbs={props.crumbs} />;
  }
  return (
    <Page title="User" {...props} topBar={topBar}>
      <Flex
        flex={1}
        w="100%"
        style={{
          color: "text",
        }}
      >
        {props.children}
      </Flex>
    </Page>
  );
}

export default withRouter(EndUserPage);
