import React from "react";
import { Text, Flex, Card } from "rebass";
import { RainbowSpinner, GooSpinner } from "react-spinners-kit";
import { useTheme } from "emotion-theming";

function LoadingPage(props) {
  const theme = useTheme();
  return (
    <Flex
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex m={4} alignItems="center" justifyContent="center" color="primary">
        <GooSpinner size={60} />
      </Flex>
    </Flex>
  );
}

export default LoadingPage;
