import { useQuery } from "@apollo/react-hooks";
import EventCategoriesQuery from "../graphql/queries/EventCategories.gql";

export function getEventCategories() {
  const eventCategoriesQuery = useQuery(EventCategoriesQuery, {});

  return {
    ...eventCategoriesQuery,
  };
}
