import React from "react";
import { Flex, Box, Link, Button, Text, Image } from "rebass";
import { useQuery } from "@apollo/react-hooks";
import Avatar from "react-avatar";
import Table from "Components/Common/Table";
import { PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import Settings from "./ProductSettings";
import TeamsQuery from "../../../graphql/queries/Teams.gql";
import { useHistory } from "react-router-dom";

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => {
      return (
        <Flex
          data-tag="allowRowEvents"
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            data-tag="allowRowEvents"
            variant="avatar"
            bg="gray.100"
            color="primary"
            style={{ backgroundColor: row.avatarColor || "primary" }}
            alignItems="center"
            justifyContent="center"
            my={2}
          >
            <Image src={row.logoUrl}></Image>
          </Flex>
          <Box mx={2} data-tag="allowRowEvents">
            <Text data-tag="allowRowEvents">{row.name}</Text>
            <Text data-tag="allowRowEvents" variant="muted">
              {row.description}
            </Text>
          </Box>
        </Flex>
      );
    },
  },
  {
    name: "Users",
    selector: (row) => row.userCount,
    sortable: true,
  },
];
function Teams(props) {
  const history = useHistory();
  const { loading, data } = useQuery(TeamsQuery, {});
  return (
    <Settings>
      <Flex flex={1} flexDirection="column" bg="white" p={4}>
        <Flex justifyContent="space-between">
          <Text variant="h2">Teams</Text>
          <PrimaryButton onClick={() => history.push("teams/new")}>
            <PlusIcon style={{ marginLeft: "-3px", marginTop: "3px" }} />
          </PrimaryButton>
        </Flex>
        <Table
          title={""}
          defaultSortField={"name"}
          selectableRows={false}
          columns={columns}
          data={data && data.teams}
          loading={loading}
          onRowClicked={(p) => {
            history.push(`/settings/product/teams/${p.id}`);
          }}
        />
      </Flex>
    </Settings>
  );
}

export default Teams;
