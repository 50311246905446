import React from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { useState } from "react";
import { Box, Flex, Text } from "rebass";
import { useTheme } from "emotion-theming";

const sliderStyle = {
  position: "relative",
};

const railStyle = {
  width: "100%",
  height: 20,
  borderRadius: 7,
  cursor: "pointer",
};

const Track = ({ source, target, getTrackProps, disabled = false }) => {
  const theme = useTheme();
  return <div {...getTrackProps()} />;
};
const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
}) => {
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
          zIndex: 5,
          width: 28,
          height: 42,
          cursor: "pointer",
          backgroundColor: "none",
        }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          marginTop: -10,
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          width: 24,
          height: 24,
          borderRadius: "50%",
          boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
          backgroundColor: "white",
        }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 24,
            height: 24,
          }}
        />
      </div>
    </>
  );
};

function DynamicSlider(props) {
  const theme = useTheme();
  const { domain, onChange, data } = props;

  let startValue = 0;
  const newValues = data
    .map((d, i) => {
      let value = Number(d.value) + startValue;
      startValue = value;
      return value;
    })
    .slice(0, data.length - 1);

  return (
    <div style={{ width: "100%" }}>
      <Slider
        mode={1}
        step={1}
        domain={domain}
        rootStyle={sliderStyle}
        onUpdate={(ranges) => data?.length > 1 && onChange(ranges)}
        onChange={(ranges) => data?.length > 1 && onChange(ranges)}
        values={newValues}
      >
        <Rail>
          {({ getRailProps }) => {
            return (
              <>
                <Flex mt={2} sx={railStyle} {...getRailProps()}>
                  {data?.map((d, i) => (
                    <Box
                      key={d.label}
                      width={d.value / 100}
                      bg={theme.colors.graph[i]}
                    >
                      <Flex
                        flex={1}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text fontSize="xs" textAlign="center">
                          {d.label} {isNaN(d.value) ? 100 : d.value}%
                        </Text>
                      </Flex>
                    </Box>
                  ))}
                </Flex>
              </>
            );
          }}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  );
}

export default DynamicSlider;
