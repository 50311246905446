import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Flex, Text, Box, Link, Image } from "rebass";
import Dropzone from "react-dropzone";
import AttachmentUploadMutation from "../../graphql/mutations/AttachmentUpload.gql";
import AttachmentsQuery from "../../graphql/queries/Attachments.gql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Table from "Components/Common/Table";
import FileUploadTemplate from "./FileUploadTemplate";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";
import { useTheme } from "emotion-theming";
import moment from "moment";

const columns = [
  {
    name: "Uploaded Files",
    selector: (row) => row.filename,
    sortable: true,
    cell: (row) => {
      return (
        <Flex flex={1} flexDirection="column">
          <Link data-tag="allowRowEvents" target="_blank" href={row.url}>
            {row.filename}
          </Link>
          <Flex justifyContent="space-between">
            <Text variant="muted" data-tag="allowRowEvents">{`${(
              row.byteSize / 1024
            ).toFixed(0)}kb`}</Text>
            <Text variant="muted" data-tag="allowRowEvents">
              {moment(row.createdAt).format("DD/MM/yyyy")}
            </Text>
          </Flex>
        </Flex>
      );
    },
  },
];

function Attachments(props) {
  const theme = useTheme();
  const { id, parentType } = props;

  const { loading, data, refetch } = useQuery(AttachmentsQuery, {
    variables: {
      id: props.id,
      parentType: props.parentType,
    },
  });
  const [file, setFile] = useState();
  const [save, {}] = useMutation(AttachmentUploadMutation);

  const dataTable = data?.attachments;
  const uploadFile = () => {
    save({
      variables: {
        id: id,
        parentType: parentType,
        file: file,
      },
      context: {
        hasUpload: true,
      },
    }).then(
      (response) => {
        setFile(undefined);
        refetch();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flexDirection="column" width="100%">
      <Box my={3}>
        <Dropzone
          onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
          multiple={false}
          disabled={!!file}
        >
          {({ getRootProps, getInputProps }) => (
            <Flex
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                width: "100%",
                borderStyle: "dashed",
                borderColor: theme.colors.accent,
                borderWidth: 1,
                borderRadius: "medium",
              }}
              p={4}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {file ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  sx={{ cursor: "pointer" }}
                  flexDirection="column"
                >
                  <Link>{file.name}</Link>
                  <Flex mt={3} flexDirection="column">
                    <PrimaryButton
                      sx={{ minWidth: "80px" }}
                      onClick={() => {
                        uploadFile();
                      }}
                    >
                      Upload File(s)
                    </PrimaryButton>
                    <Box mt={2} />
                    <GhostButton
                      onClick={() => {
                        setFile(undefined);
                      }}
                    >
                      Cancel
                    </GhostButton>
                  </Flex>
                </Flex>
              ) : (
                <FileUploadTemplate />
              )}
            </Flex>
          )}
        </Dropzone>
      </Box>
      <Flex flex={2}>
        <Table
          title={""}
          defaultSortField={"name"}
          selectableRows={false}
          columns={columns}
          data={dataTable}
          loading={loading}
          noDataComponent={
            <Flex
              flexDirection="column"
              flexGrow={1}
              alignItems="center"
              justifyContent="center"
              my={2}
            >
              <Image
                src={require("assets/images/Tables.png")}
                sx={{ height: "50px", objectFit: "contain" }}
              ></Image>
              <Flex p={2} flexDirection="column">
                <Text variant="muted">No files attached</Text>
              </Flex>
            </Flex>
          }
        />
      </Flex>
    </Flex>
  );
}

export default withRouter(Attachments);
