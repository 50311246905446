import { useQuery } from "@apollo/react-hooks";
import { useDynamicGql } from "../graphql/DynamicGql";

export function getAllData(model, filters) {
  const { getAllData } = useDynamicGql(model, true);
  const dataQuery = useQuery(getAllData, {
    variables: { searchFilters: filters },
  });
  return {
    ...dataQuery,
  };
}
