import React, { useState } from "react";
import { Flex, Text, Box, Button } from "rebass";
import "react-tabs/style/react-tabs.css";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { FieldArray } from "formik";
import { useDynamicSchemas } from "hooks/DynamicSchema";
import { ModelTypeIcon, MODEL_TYPES } from "consts";
import { CloseIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { useTheme } from "emotion-theming";
import { ArrowDownIcon, ArrowUpIcon } from "../../../Common/Icons";
import { GetSegmentOptions } from "../../../Filter/Segment/SegmentSubForm";
import { GetFieldEditor } from "../Healthscore/FactorForm";
const _ = require("lodash");

const DEFAULT_SCORE = 100;
function TrialFactorForm(props) {
  const { values, errors, setFieldValue, fieldName } = props;

  let dataModels = useDynamicSchemas();
  dataModels = dataModels?.filter(
    (m) => m.typeName !== MODEL_TYPES.Comment.name
  );

  let fields = [];
  dataModels?.map((entity) => {
    entity?.attributes.map((m) => {
      fields.push({
        value: `${entity.id}.${m.name}`,
        label: (
          <Flex alignItems="center">
            <Text mx={2}>{ModelTypeIcon(entity.typeName, 16)}</Text>
            {m.displayName}
          </Flex>
        ),
        field: m.name,
        entity: entity.id,
        typeName: entity.typeName,
        type:
          m.calculatedType && m.calculatedType === "exists"
            ? "boolean"
            : m.type,
      });
    });
  });

  return (
    <Flex flex={1} flexDirection="column">
      <Flex flexDirection="column" w="100%">
        <>
          <FieldArray
            name={`config.${fieldName}`}
            render={(factorArrayHelpers) => (
              <>
                <Flex justifyContent="flex-end">
                  <Box mx={2}>
                    <PrimaryButton
                      onClick={() => {
                        const itemCount = values.config[fieldName]
                          ? values.config[fieldName].length + 1
                          : 1;
                        const score = (100 / itemCount).toFixed(0);
                        let items = [];
                        values.config &&
                          values.config[fieldName].map((p, i) => {
                            const newValue = {
                              ...p,
                              score: score,
                            };
                            items.push({ label: p.name, value: score });
                            factorArrayHelpers.replace(i, newValue);
                          });

                        items.push({
                          label: "",
                          value: 100 - score * (itemCount - 1),
                        });
                        factorArrayHelpers.push({
                          name: "",
                          score: 100 - score * (itemCount - 1),
                          criteria: [
                            {
                              score: DEFAULT_SCORE,
                              items: [
                                { type: "poor" },
                                { type: "average" },
                                { type: "good" },
                                { type: "perfect" },
                              ],
                            },
                          ],
                          type: "fixed",
                        });
                        props.onInsert(items);
                      }}
                      leftIcon={<PlusIcon />}
                    >
                      Add
                    </PrimaryButton>
                  </Box>
                </Flex>
                {values?.config[fieldName] &&
                values?.config[fieldName].length > 0 ? (
                  values?.config[fieldName].map((field, idex) => {
                    return (
                      <ItemDetail
                        key={idex}
                        field={field}
                        idx={idex}
                        fieldName={fieldName}
                        factorArrayHelpers={factorArrayHelpers}
                        handleBlur={props.handleBlur}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        onDelete={(idx) => {
                          let items = [];
                          const itemCount = values.config[fieldName]
                            ? values.config[fieldName].length - 1
                            : 1;
                          const score = (100 / itemCount).toFixed(0);
                          values.config &&
                            values.config[fieldName].map((p, i) => {
                              if (i !== idx) {
                                items.push({ label: p.name, value: score });
                              }
                            });
                          factorArrayHelpers.remove(idx);
                          props.onDelete(items);
                        }}
                      />
                    );
                  })
                ) : (
                  <Flex
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                    m={4}
                  >
                    Not currently configured. Conversion score will be based on
                    uploaded data.
                  </Flex>
                )}
              </>
            )}
          />
        </>
      </Flex>
    </Flex>
  );
}

export default TrialFactorForm;

const ItemDetail = (props) => {
  const {
    field,
    idx,
    fieldName,
    factorArrayHelpers,
    handleBlur,
    setFieldValue,
    values,
    errors,
    onDelete,
  } = props;
  const [hide, setHide] = useState(false);
  return (
    <Flex
      key={idx}
      my={2}
      bg="gray.100"
      p={3}
      sx={{ position: "relative" }}
      flexDirection="column"
    >
      <Flex
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <Button variant="ghost" onClick={() => setHide(!hide)}>
          <Flex alignItems="center" justifyContent="center">
            {!hide ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Flex>
        </Button>

        <Button
          variant="circle"
          bg="gray.300"
          sx={{
            width: 25,
            height: 25,
            padding: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            onDelete(idx);
          }}
        >
          <Flex
            color="primary"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: 25,
              height: 25,
            }}
          >
            <CloseIcon size={16} />
          </Flex>
        </Button>
      </Flex>
      <Flex mx={2}>
        <CustomFormGroup
          label="Name"
          name={`config[${fieldName}][${idx}].name`}
          value={field?.name}
          onBlur={handleBlur}
          onChange={setFieldValue}
          helperText={
            errors?.config &&
            errors.config[fieldName] &&
            errors.config[fieldName][idx] &&
            errors.config[fieldName][idx].name &&
            errors.config[fieldName][idx].name
          }
        />
      </Flex>
      {!hide && (
        <Box my={2}>
          <FieldArray
            name={`config[${fieldName}][${idx}].criteria`}
            render={(arrayHelpers) => (
              <Box>
                {values?.config[fieldName][idx]?.criteria &&
                values?.config[fieldName][idx]?.criteria.length > 0
                  ? values?.config[fieldName][idx]?.criteria.map(
                      (item, itemidx) => (
                        <Flex
                          key={idx}
                          alignItems="flex-end"
                          justifyContent="center"
                        >
                          <SearchSubForm
                            index={itemidx}
                            sectionName={fieldName}
                            fieldName={`config[${fieldName}][${idx}].criteria`}
                            field={item}
                            factor={
                              values?.config[fieldName] &&
                              values.config[fieldName][idx]
                            }
                            factorType={field.type}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={
                              errors?.config &&
                              errors?.config[fieldName] &&
                              errors?.config[fieldName][idx] &&
                              errors?.config[fieldName][idx].criteria &&
                              errors?.config[fieldName][idx].criteria[
                                itemidx
                              ] &&
                              errors?.config[fieldName][idx].criteria[itemidx]
                            }
                            onBlur={handleBlur}
                            onDelete={() => arrayHelpers.remove(idx)}
                          />
                        </Flex>
                      )
                    )
                  : undefined}
                <PrimaryButton
                  onClick={() =>
                    arrayHelpers.push({
                      score: DEFAULT_SCORE,
                      items: [
                        { type: "poor" },
                        { type: "average" },
                        { type: "good" },
                        { type: "perfect" },
                      ],
                    })
                  }
                  leftIcon={<PlusIcon size="12" />}
                >
                  Add
                </PrimaryButton>
              </Box>
            )}
          />
        </Box>
      )}
    </Flex>
  );
};

const TrialBadge = ({ type, customerFit }) => {
  const theme = useTheme();
  let color = "";
  let label = "";

  switch (type) {
    case "poor":
      color = theme.colors.red + "1A";
      label = customerFit ? "Poor" : "Low";
      break;
    case "average":
      color = theme.colors.yellow + "1A";
      label = customerFit ? "Partial" : "Average";
      break;
    case "good":
      color = theme.colors.green + "1A";
      label = customerFit ? "Good" : "High";
      break;
    case "perfect":
      color = theme.colors.green + "40";
      label = customerFit ? "Perfect" : "Very High";
      break;
  }
  return (
    <Flex mt={2}>
      <Flex
        variant="badge"
        bg={color}
        justifyContent="center"
        alignItems="center"
        py={1}
        mx={2}
        width={80}
      >
        <Text textAlign="center">{label}</Text>
      </Flex>
    </Flex>
  );
};

export function SearchSubForm(props) {
  const { factorType, values, errors, sectionName } = props;

  const selectedField = props.field;
  let dataModels = useDynamicSchemas();
  dataModels = dataModels?.filter(
    (m) => m.typeName !== MODEL_TYPES.Comment.name
  );

  let fields = [];
  dataModels?.map((entity) => {
    entity?.attributes.map((m) => {
      fields.push({
        value: `${entity.id}.${m.name}`,
        label: (
          <Flex alignItems="center">
            <Text mx={2}>{ModelTypeIcon(entity.typeName, 16)}</Text>
            {m.displayName}
          </Flex>
        ),
        field: m.name,
        entity: entity.id,
        type: m.type,
      });
    });
  });

  const fieldType = fields.find(
    (o) =>
      o.field === selectedField?.field && o.entity === selectedField?.entity
  )?.type;

  let options = GetSegmentOptions(selectedField);

  const showOperators = !options && selectedField.type !== "boolean";
  const showTextField =
    selectedField?.operator !== "EXISTS" && selectedField?.operator !== "BLANK";

  return (
    <Flex width="100%" variant="box" m={2} flexDirection="column">
      <Flex width="100%" flexDirection="column" p={2}>
        <Flex alignItems="center" sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <Button variant="ghost" onClick={props.onDelete}>
              <Flex alignItems="center" justifyContent="center">
                <CloseIcon />
              </Flex>
            </Button>
          </Box>
          <CustomFormGroup
            type="list"
            label="Field"
            width={300}
            name={`${props.fieldName}[${props.index}].field`}
            value={
              fields.find(
                (o) =>
                  o.field === selectedField?.field &&
                  o.entity === selectedField?.entity
              )?.value
            }
            onBlur={props.handleBlur}
            onChange={(name, value) => {
              props.setFieldValue(`${props.fieldName}[${props.index}]`, {
                ...selectedField,
                entity: value?.entity,
                field: value?.field,
              });
            }}
            options={fields}
            helperText={errors?.field && errors.field}
          />
          {fieldType == "string" && (
            <Text variant="muted" mx={2}>
              eg. "test" or a list "test1,test2,test3"
            </Text>
          )}
        </Flex>
        <Flex flexDirection="column">
          {selectedField.items?.map((item, itemidx) => (
            <Flex key={`selectedField-${itemidx}`} alignItems="flex-start">
              <TrialBadge
                type={item.type}
                customerFit={sectionName === "customerFit"}
              />

              <GetFieldEditor {...props} itemidx={itemidx} />
            </Flex>
          ))}
          <Flex my={2} alignItems="center">
            <TrialBadge
              type="poor"
              customerFit={sectionName === "customerFit"}
            />
            <Text variant="muted" m={2}>
              Will default to <strong>Low</strong> if conditions not met
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
