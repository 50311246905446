import React, { useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { MODEL_TYPES } from "consts";
import { useHistory, useParams, Prompt } from "react-router-dom";
import { getEventGroups } from "hooks/getEventGroups";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import CodePreview from "../ApplicationKeys/CodePreview";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import { SaveIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { CloseIcon, PlusIcon } from "../../../Common/Icons";
import EventGroupMutation from "../../../../graphql/mutations/EventGroup.gql";
import EventGroupDeleteMutation from "../../../../graphql/mutations/EventGroupDelete.gql";
import EventGroupQuery from "../../../../graphql/queries/EventGroup.gql";
import EventCategoriesQuery from "../../../../graphql/queries/EventCategories.gql";

function EventGroupForm(props) {
  const { id } = useParams();
  const history = useHistory();
  const { refetch } = getEventGroups();
  const { addToast } = useToasts();
  const [showCategories, setShowCategories] = useState(true);
  const [save] = useMutation(EventGroupMutation, {
    refetchQueries: [{ query: EventCategoriesQuery }],
  });
  const [destroy, {}] = useMutation(EventGroupDeleteMutation);
  const { data, loading, error } = useQuery(EventGroupQuery, {
    variables: { id: id },
    skip: !id || id === "new",
  });
  const { data: categories } = useQuery(EventCategoriesQuery);
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={
          data?.eventGroup || {
            name: "",
            aliasKey: "",
          }
        }
        autoComplete={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          save({
            variables: {
              id: values.id ? values.id : null,
              name: values.name,
              aliasKey: values.aliasKey,
              eventCategoryName: values.eventCategoryName,
              eventCategoryId: values.eventCategoryId,
            },
          }).then(
            (response) => {
              if (response.data?.eventGroup?.errors) {
                addToast(
                  <Flex>{response.data?.eventGroup?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                addToast(<Flex>Saved!</Flex>, SuccessParams);
                refetch();
                setShowCategories(true);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const {
            values,
            setFieldValue,
            handleBlur,
            handleSubmit,
            errors,
            dirty,
          } = formProps;
          const route =
            values.actionableType && MODEL_TYPES[values.actionableType].plural;
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex flexDirection="column">
                <Flex alignItems="center" justifyContent="flex-end">
                  <DeleteButton
                    callback={destroy}
                    refetch={refetch}
                    onCancel={() => {
                      history.goBack();
                    }}
                    id={id}
                    name="event type"
                  />
                  <PrimaryButton leftIcon={<SaveIcon />} onClick={handleSubmit}>
                    Save
                  </PrimaryButton>
                </Flex>
                <CustomFormGroup
                  placeholder="Name"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  helperText={errors && errors.name && errors.name}
                />
                <CustomFormGroup
                  placeholder="Alias"
                  label="Alias"
                  name="aliasKey"
                  value={values.aliasKey}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  helperText={errors && errors.aliasKey && errors.aliasKey}
                />
                <Box>
                  {showCategories ? (
                    <Flex>
                      <CustomFormGroup
                        noMargin
                        placeholder="Category"
                        label="Category"
                        type="list"
                        options={categories?.eventCategories}
                        name="eventCategoryId"
                        value={values.eventCategoryId}
                        onChange={(name, value) =>
                          setFieldValue(name, value?.value)
                        }
                        onBlur={handleBlur}
                        width="250px"
                        helperText={
                          errors &&
                          errors.eventCategoryId &&
                          errors.eventCategoryId
                        }
                      />
                      <Box marginTop={24} mx={2}>
                        <SecondaryButton
                          onClick={() => {
                            setShowCategories(false);
                            setFieldValue("eventCategoryId", undefined);
                          }}
                        >
                          <PlusIcon />
                        </SecondaryButton>
                      </Box>
                    </Flex>
                  ) : (
                    <Flex>
                      <CustomFormGroup
                        noMargin
                        placeholder="Enter new category"
                        label="Category"
                        name="eventCategoryName"
                        value={values.eventCategoryName}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        width="250px"
                        helperText={
                          errors &&
                          errors.eventCategoryName &&
                          errors.eventCategoryName
                        }
                      />
                      <Box marginTop={24} mx={2}>
                        <SecondaryButton
                          onClick={() => {
                            setShowCategories(true);
                            setFieldValue("eventCategoryName", undefined);
                          }}
                        >
                          <CloseIcon />
                        </SecondaryButton>
                      </Box>
                    </Flex>
                  )}
                </Box>
              </Flex>
              <Text mt={4} variant="h4">
                JavaScript Integration Snippet
              </Text>
              <Box>
                <CodePreview>
                  {`Userlot.push(['addEvent', '${values.aliasKey}', /*Insert ExternalAccountId Here*/, /*Insert ExternalUserId Here*/]);

`}
                </CodePreview>
              </Box>
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default EventGroupForm;
