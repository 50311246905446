import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import CurrentUserQuery from "./graphql/queries/CurrentUser.gql";
import LoadingPage from "./components/Page/LoadingPage";
import Login from "./components/MyAccount/Login";
import Register from "./components/MyAccount/Register";
import Account from "./components/MyAccount/Settings/Account";
import Users from "./components/MyAccount/Settings/Users";
import Teams from "./components/MyAccount/Settings/Teams";
import RegisterUser from "./components/MyAccount/RegisterUser";
import ResetPassword from "./components/MyAccount/ResetPassword";
import Team from "./components/MyAccount/Settings/Team";
import User from "./components/MyAccount/Settings/User";
import EmailDomains from "./components/MyAccount/Settings/EmailDomains";
import Subscribe from "./components/MyAccount/Settings/Subscribe";
import Reports from "./components/Dashboard/Reports";
import AllCompanies from "./components/Dashboard/Reports/AllCompanies";
import ProductHealth from "./components/Dashboard/ProductHealth";
import ConversionHealth from "./components/Dashboard/ConversionHealth";
import ProductHealthSegments from "./components/Dashboard/Reports/Segments";
import Renewals from "./components/Dashboard/Reports/Renewals";
import DataModel from "./components/MyAccount/Settings/DataModel";
import Company from "./components/Data/Company/Company";
import EndUser from "./components/Data/EndUser/EndUser";
import Data from "./components/Data/";
import Notifications from "./components/Notifications";
import Import from "./components/MyAccount/Settings/Import";
import Profile from "./components/MyAccount/Settings/Profile";
import Workflow from "./components/MyAccount/Settings/Workflow";
import Playbook from "./components/MyAccount/Settings/Playbook";
import Healthscore from "./components/MyAccount/Settings/Healthscore";
import HealthscoreIndex from "./components/MyAccount/Settings/Healthscore/HealthScore";
import Events from "./components/MyAccount/Settings/Events";
import GlobalSearch from "./components/GlobalSearch";
import ApplicationKeys from "./components/MyAccount/Settings/ApplicationKeys";
import Event from "./components/Data/Event/Event";
import Home from "./components/Dashboard/Home/Home";
import SetupAccounts from "./components/MyAccount/Setup/Accounts";
import ChurnReasons from "./components/MyAccount/Settings/ChurnReasons";
import EmailTemplates from "./components/MyAccount/Settings/EmailTemplate";

import CompanyHealth from "./components/Dashboard/CompanyHealth";
import CompaniesHealth from "./components/Dashboard/CompanyHealth";
import CompanyStatuses from "./components/MyAccount/Settings/CompanyStatuses";
import { getCompanyStatus } from "./hooks/getCompanyStatuses";
import ProductSettings from "./components/MyAccount/Settings/Products/Product";
import Products from "./components/MyAccount/Settings/Products";
import LifeCycles from "./components/MyAccount/Settings/LifeCycles";
import IntegrationLogs from "./components/MyAccount/Settings/IntegrationLogs";
import AcceptInvite from "./components/MyAccount/AcceptInvite";
import ConversionReport from "./components/Dashboard/ConversionHealth/ConversionReport";
import CompanyReport from "./components/Dashboard/CompanyHealth/CompanyReport";
import ProductReport from "./components/Dashboard/ProductHealth/ProductReport";
import TaskReport from "Components/CommandCenter/Tasks/TaskReport";
import CompanyAutomation from "./components/Dashboard/CompanyHealth/CompanyAutomation";
import ConversionAutomation from "./components/Dashboard/ConversionHealth/ConversionAutomation";
import NoRoute from "./components/Page/NoRoute";
import Segments from "./components/Dashboard/Reports/Segments";
import CompanyContacts from "./components/Dashboard/Contacts/CompanyContacts";
import CompanyContactsHierarchy from "./components/Dashboard/Contacts/CompanyContactHierarchy";

import UserContacts from "./components/Dashboard/Contacts/UserContacts";
import Plans from "./components/MyAccount/Settings/Plans";
import Library from "./components/MyAccount/Settings/Library";
import TriggerList from "./components/MyAccount/Settings/Triggers/TriggerList";
import TriggerForm from "./components/MyAccount/Settings/Triggers/TriggerForm";
import SurveyQuestions from "./components/MyAccount/Settings/SurveyQuestions";
import GettingStarted from "./components/Dashboard/GettingStarted";
import Satisfaction from "./components/Dashboard/Reports/Satisfaction";
import SurveyResponseForm from "./components/MyAccount/Settings/SurveyQuestions/SurveyResponseForm";
import AccountIndex from "./components/MyAccount/Settings/AccountIndex";
import TrialConversionForm from "./components/MyAccount/Settings/TrialConversion";
import Tags from "./components/MyAccount/Settings/Tags";
import ConfirmEmail from "./components/MyAccount/ConfirmEmail";
import Tickets from "./components/CommandCenter/Tickets";
import Inbox from "./components/CommandCenter/Inbox";
import Survey from "./components/CommandCenter/Survey";
import Tasks from "./components/CommandCenter/Tasks";
import Integrations from "./components/MyAccount/Settings/Integrations";
import DevLogs from "./components/MyAccount/Settings/DevLogs";
import { getCurrentUser } from "./hooks/getCurrentUser";
import TaskCategories from "./components/MyAccount/Settings/TaskCategories";
import TeamDashboard from "./components/Dashboard/Home/TeamDashboard";

const routes = [
  { name: "Login", path: "/login", Component: Login },
  { name: "Accept Invite", path: "/accept/:key", Component: AcceptInvite },
  { name: "Confirm Email", path: "/confirm/:key", Component: ConfirmEmail },
  { name: "Reset Password", path: "/reset/:key", Component: ResetPassword },
  { name: "Reset Password", path: "/reset/", Component: ResetPassword },
  { name: "Register", path: "/register/:key", Component: Register },
  { name: "Register User", path: "/register", Component: RegisterUser },
  { name: "Home", path: "/home", Component: Home },
  { name: "Team", path: "/teams", Component: TeamDashboard },
  { name: "Home", path: "/getting_started", Component: GettingStarted },
  {
    name: "Accounts",
    path: "/home/accounts/:id",
    Component: Company,
  },
  {
    name: "Person",
    path: "/home/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  { name: "User", path: "/home/users/:id", Component: EndUser },
  { name: "Product Health", path: "/adoption", Component: ProductHealth },
  {
    name: "Product Health",
    path: "/adoption/report",
    Component: ProductReport,
  },
  {
    name: "Product Health",
    path: "/adoption/segments",
    Component: ProductHealthSegments,
  },
  {
    name: "Accounts",
    path: "/adoption/accounts/:id",
    Component: Company,
  },
  {
    name: "Person",
    path: "/adoption/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  { name: "Events", path: "/adoption/events/:id", Component: Event },
  { name: "Events", path: "/data/events/:id", Component: Event },
  { name: "Reports", path: "/reports", Component: Reports },
  { name: "Reports", path: "/reports/accounts", Component: AllCompanies },
  {
    name: "satisfaction",
    path: "/reports/satisfaction",
    Component: Satisfaction,
  },
  {
    name: "Accounts",
    path: "/reports/accounts/:id",
    Component: Company,
  },
  {
    name: "Person",
    path: "/reports/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  { name: "User", path: "/reports/users/:id", Component: EndUser },
  { name: "Reports", path: "/reports/segments", Component: Segments },
  {
    name: "Reports",
    path: "/reports/renewals/",
    Component: Renewals,
  },
  { name: "Company Health", path: "/health", Component: CompanyHealth },
  { name: "Company Health", path: "/health/report", Component: CompanyReport },
  {
    name: "Company Health",
    path: "/health/automation",
    Component: CompanyAutomation,
  },
  {
    name: "Accounts",
    path: "/health/accounts/:id",
    Component: Company,
  },
  {
    name: "Person",
    path: "/health/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  { name: "User", path: "/health/users/:id", Component: EndUser },
  {
    name: "Conversion Health",
    path: "/conversion",
    Component: ConversionHealth,
  },
  {
    name: "Accounts",
    path: "/conversion/accounts/:id",
    Component: Company,
  },
  {
    name: "Person",
    path: "/conversion/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  { name: "User", path: "/conversion/users/:id", Component: EndUser },
  {
    name: "Conversion Health",
    path: "/conversion/report",
    Component: ConversionReport,
  },
  {
    name: "Conversion Health",
    path: "/conversion/automation",
    Component: ConversionAutomation,
  },
  { name: "Tasks", path: "/tasks", Component: Tasks },
  { name: "Support", path: "/support", Component: Tickets },
  { name: "Inbox", path: "/inbox", Component: Inbox },
  { name: "Satisfaction", path: "/surveys", Component: Survey },
  {
    name: "Accounts",
    path: "/inbox/accounts/:id",
    Component: Company,
  },
  {
    name: "Person",
    path: "/inbox/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  {
    name: "Accounts",
    path: "/tasks/accounts/:id",
    Component: Company,
  },
  {
    name: "Person",
    path: "/tasks/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  { name: "Tasks", path: "/tasks/report", Component: TaskReport },
  { name: "Edit Tasks", path: "/tasks/:id", Component: Tasks },
  { name: "Data", path: "/data", Component: Data },
  {
    name: "Edit Account",
    path: "/data/accounts/:id",
    Component: Company,
  },
  {
    name: "Users",
    path: "/data/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  {
    name: "Accounts",
    path: "/contacts/accounts",
    Component: CompanyContacts,
  },
  {
    name: "Account Hierarchy",
    path: "/contacts/hierarchy",
    Component: CompanyContactsHierarchy,
  },
  {
    name: "Account",
    path: "/contacts/hierarchy/:id",
    Component: Company,
  },
  { name: "Tasks", path: "/tasks", Component: Tasks },
  {
    name: "Account",
    path: "/contacts/accounts/:id",
    Component: Company,
  },
  {
    name: "Person",
    path: "/data/:id",
    Component: EndUser,
  },
  {
    name: "Person",
    path: "/contacts/accounts/:companyId/users/:id",
    Component: EndUser,
  },
  {
    name: "Person",
    path: "/contacts/users/:id",
    Component: EndUser,
  },
  {
    name: "People",
    path: "/contacts/users/",
    Component: UserContacts,
  },
  { name: "Person", path: "/contacts/users/:id", Component: EndUser },

  { name: "Notifications", path: "/notifications", Component: Notifications },
  { name: "Account", path: "/settings/account", Component: AccountIndex },
  { name: "Account", path: "/settings/account/general", Component: Account },
  {
    name: "Product",
    path: "/settings/product/general",
    Component: ProductSettings,
  },
  { name: "Product", path: "/settings/account/products", Component: Products },
  { name: "Users", path: "/settings/account/users", Component: Users },
  { name: "User", path: "/settings/account/users/:id", Component: User },
  {
    name: "Email Domains",
    path: "/settings/account/domains",
    Component: EmailDomains,
  },
  { name: "Teams", path: "/settings/product/teams", Component: Teams },
  { name: "Teams", path: "/settings/product/teams/:id", Component: Team },
  {
    name: "Activity Types",
    path: "/settings/product/tasks",
    Component: TaskCategories,
  },
  {
    name: "Subscribe",
    path: "/settings/account/subscription",
    Component: Subscribe,
  },
  {
    name: "Custom Fields",
    path: "/settings/product/fields",
    Component: DataModel,
  },
  { name: "Profile", path: "/settings/account/profile", Component: Profile },
  { name: "Import", path: "/settings/product/import", Component: Import },
  {
    name: "Workflow",
    path: "/settings/product/workflows",
    Component: Workflow,
  },
  {
    name: "Workflow",
    path: "/settings/product/workflows/:id",
    Component: Workflow,
  },
  { name: "Plan", path: "/settings/product/plans", Component: Plans },
  { name: "Plan", path: "/settings/product/plans/:id", Component: Plans },
  { name: "Library", path: "/settings/product/library", Component: Library },
  {
    name: "Playbook",
    path: "/settings/product/playbooks",
    Component: Playbook,
  },
  {
    name: "Playbook",
    path: "/settings/product/playbooks/:id",
    Component: Playbook,
  },
  { name: "Events", path: "/settings/product/events", Component: Events },
  { name: "Events", path: "/settings/product/events/:id", Component: Events },
  { name: "Tags", path: "/settings/account/tags", Component: Tags },
  { name: "Tags", path: "/settings/account/tags/:id", Component: Tags },
  {
    name: "Triggers",
    path: "/settings/product/automations",
    Component: TriggerList,
  },
  {
    name: "Triggers",
    path: "/settings/product/automations/:id",
    Component: TriggerForm,
  },
  {
    name: "Churn Reasons",
    path: "/settings/account/churnReasons",
    Component: ChurnReasons,
  },
  {
    name: "Status",
    path: "/settings/product/statuses",
    Component: CompanyStatuses,
  },
  {
    name: "Lifecycle",
    path: "/settings/product/lifecycle",
    Component: LifeCycles,
  },
  {
    name: "Setup Accounts",
    path: "/settings/account/setup/datasource",
    Component: SetupAccounts,
  },
  {
    name: "Healthscore",
    path: "/settings/product/healthscores",
    Component: Healthscore,
  },
  {
    name: "Trail Conversion",
    path: "/settings/product/trialconversion",
    Component: TrialConversionForm,
  },
  {
    name: "Healthscores",
    path: "/settings/product/healthscores/:id",
    Component: HealthscoreIndex,
  },
  {
    name: "Search",
    path: "/global-search/:searchText",
    Component: GlobalSearch,
  },
  {
    name: "Logs",
    path: "/settings/product/logs",
    Component: IntegrationLogs,
  },
  {
    name: "Dev Logs",
    path: "/settings/product/dev-logs",
    Component: DevLogs,
  },
  {
    name: "Application Keys",
    path: "/settings/product/api",
    Component: ApplicationKeys,
  },
  {
    name: "Application Key",
    path: "/settings/product/api/:id",
    Component: ApplicationKeys,
  },
  {
    name: "Integrations",
    path: "/settings/product/integrations",
    Component: Integrations,
  },
  {
    name: "Integrations",
    path: "/settings/product/integrations/:id",
    Component: Integrations,
  },
  {
    name: "Email Templates",
    path: "/settings/product/emailtemplates",
    Component: EmailTemplates,
  },
  {
    name: "Email Template",
    path: "/settings/product/emailtemplates/:id",
    Component: EmailTemplates,
  },
  {
    name: "Survey Questions",
    path: "/settings/product/surveys",
    Component: SurveyQuestions,
  },
  {
    name: "Survey Question",
    path: "/settings/product/surveys/:id",
    Component: SurveyQuestions,
  },
  {
    name: "Survey Response",
    path: "/surveys/response/:id",
    Component: SurveyResponseForm,
  },
];

const Routes = (props) => {
  let statusRoutes = [];
  try {
    const { currentAccount, currentUser } = getCurrentUser();
    const status = getCompanyStatus();

    if (!window.UserlotConfig.helpscoutIdentified && window.Beacon) {
      //Identify with Helpscout
      Beacon("identify", {
        name: currentUser?.name,
        email: currentUser?.email,
        company: currentAccount?.name,
        avatar: currentUser?.avatarUrl,
      });

      window.UserlotConfig.helpscoutIdentified = true;
    }

    statusRoutes =
      (currentUser &&
        status?.data?.companyStatuses.map((s) => {
          return {
            name: "Accounts",
            path: `/health/${s.name.toLowerCase()}`,
            Component: CompaniesHealth,
          };
        })) ||
      [];
  } catch (error) {
    console.log(error);
  }

  let customRoutes = [...routes, ...statusRoutes];
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={1000}>
        <Switch>
          <InitialRoute exact path="/" />
          {customRoutes.map(({ path, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              render={(props) => {
                const crumbs = routes
                  // Get all routes that contain the current one.
                  .filter(({ path }) => props.match.path.includes(path))
                  // Swap out any dynamic routes with their param values.
                  // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                          (path, param) =>
                            path.replace(
                              `:${param}`,
                              props.match.params[param]
                            ),
                          path
                        )
                      : path,
                    ...rest,
                  }));
                return (
                  <div className="p-8">
                    <Component {...props} crumbs={crumbs} />
                  </div>
                );
              }}
            />
          ))}
          <NoMatchRoute />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
Routes.propTypes = {
  match: PropTypes.object,
};
function InitialRoute({ component: Component, ...rest }) {
  const { loading, data, error } = useQuery(CurrentUserQuery);
  if (error) console.log(error);

  let currentUser = data && data.currentUser;

  return loading ? (
    <Route {...rest} component={LoadingPage} />
  ) : !error && currentUser ? (
    <Route {...rest} component={Home} />
  ) : (
    <Route {...rest} component={Login} />
  );
}
InitialRoute.propTypes = {
  component: PropTypes.object,
};
function NoMatchRoute({ component: Component, ...rest }) {
  return <Route {...rest} component={NoRoute} />;
}
NoMatchRoute.propTypes = {
  component: PropTypes.object,
};
export default Routes;
