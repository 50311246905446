import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import * as Yup from "yup";
import { MODEL_TYPES } from "consts";
import { Box, Flex, Link, Text } from "rebass";
import Avatar from "react-avatar";
import moment from "moment";
import ProgressBar from "@ramonak/react-progress-bar";
import { useTheme } from "emotion-theming";
import HealthScoreIcon from "../components/Data/Company/HealthScoreIcon";
import SatIcon from "../components/Dashboard/Widgets/SatIcon";
import { getDefaultProduct } from "../auth-helper";
import TypeSchemaQuery from "../graphql/queries/TypeSchemas.gql";
import { HealthIcon, StarIcon, TickIcon } from "../components/Common/Icons";
import { getSearchFilter } from "./getSearchFilter";
import { RiShieldStarFill } from "react-icons/ri";
import ConversionScoreIcon from "../components/Data/Company/ConversionScoreIcon";
import UserTypeBadge from "../components/Common/UserTypeBadge";

const _ = require("lodash");

const createYupSchema = (fields) => {
  const schema = fields.reduce((schema, field) => {
    const {
      name,
      validationType,
      validationTypeError,
      validations = [],
    } = field;
    const isObject = name.indexOf(".") >= 0;

    if (!Yup[validationType]) {
      return schema;
    }
    let validator = Yup[validationType]().typeError(validationTypeError || "");
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });

    if (!isObject) {
      return schema.concat(Yup.object().shape({ [name]: validator }));
    }

    const reversePath = name.split(".").reverse();
    const currNestedObject = reversePath.slice(1).reduce(
      (yupObj, path, index, source) => {
        if (!isNaN(path)) {
          return { array: Yup.array().of(Yup.object().shape(yupObj)) };
        }
        if (yupObj.array) {
          return { [path]: yupObj.array };
        }
        return { [path]: Yup.object().shape(yupObj) };
      },
      { [reversePath[0]]: validator }
    );

    const newSchema = Yup.object().shape(currNestedObject);
    return schema.concat(newSchema);
  }, Yup.object().shape({}));

  return schema;
};

const urlTruncate = (str) => {
  const n = 15;
  str = str.replace(/https:\/\//g, "").replace(/http:\/\//g, "");
  return str?.length > n ? str.substring(0, n - 1) + "..." : str;
};

const ColumnRender = (field, selectedColumns, schemaName) => {
  const theme = useTheme();
  const order =
    selectedColumns?.find((c) => c.id === field.name)?.sortOrder || 999;

  let convertedTypeName = field.typeName;
  switch (convertedTypeName) {
    case "company_details":
      convertedTypeName = "details";
      break;
  }
  const getValue = (row) => {
    let value = convertedTypeName
      ? row[convertedTypeName][field.name]
      : field.storage === "custom"
      ? row.fields[field.name]
      : row[field.name];

    return value;
  };

  if (field.name === "nps") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: <HealthIcon />,
      selector: (row) => row.nps,
      sortable: true,
      width: "50px",
      cell: (row) => {
        return (
          <Text data-tag="allowRowEvents">
            <SatIcon scores={row?.currentScores} simple />
          </Text>
        );
      },
    };
  }
  if (field.name === "online") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: field.displayName,
      selector: (row) => row.online,
      sortable: true,
      width: "40px",
      cell: (row) => {
        return (
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              width: 30,
              height: 30,
              minWidth: 30,
              maxHeight: 30,
              borderRadius: 99,
              backgroundColor: row.online ? "green" : "black",
            }}
          >
            <Avatar
              size={25}
              round
              name={`${row.firstName || ""} ${row.lastName || ""}`}
              email={row.email}
            />
          </Flex>
        );
      },
    };
  }
  if (field.name === "endUserCategoryId") {
    return {
      id: field.name,
      name: "Category",
      schemaName: schemaName,
      order: order,
      selector: (row) => row.endUserCategoryId,
      sortable: true,
      width: "110px",
      cell: (row) => {
        return <UserTypeBadge categoryId={row?.endUserCategoryId} />;
      },
    };
  }

  if (field.name === "conversionQualified") {
    return {
      id: field.name,
      schemaName: schemaName,

      order: order,
      name: "Score",
      width: "60px",
      selector: (row) => row.details.conversionQualified,
      sortable: false,
      cell: (row) => {
        return (
          !row.isParent && (
            <ConversionScoreIcon
              status={row.details?.status}
              conversionAdoptionScore={row.details?.conversionAdoptionScore}
              conversionFitScore={row.details?.conversionFitScore}
              conversionQualified={row.details?.conversionQualified}
            />
          )
        );
      },
    };
  }
  if (field.name === "conversionAdoptionScore") {
    return {
      id: field.name,
      schemaName: schemaName,

      order: order,
      name: <Text>Adoption Score</Text>,
      width: "80px",
      selector: (row) => row.details?.conversionAdoptionScore,
      sortable: true,
      cell: (row) => {
        return row.details?.conversionAdoptionScore
          ? `${row.details?.conversionAdoptionScore}%`
          : "0%";
      },
    };
  }
  if (field.name === "conversionFitScore") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: <Text>Fit Score</Text>,
      width: "80px",
      selector: (row) => row.details?.conversionFitScore,
      sortable: true,
      cell: (row) => {
        return row.details?.conversionFitScore
          ? `${row.details?.conversionFitScore}%`
          : "0%";
      },
    };
  }
  if (field.name === "details.healthScore" || field.name === "healthScore") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: <HealthIcon />,
      selector: (row) => row.details.healthScore,
      sortable: true,
      width: "60px",
      cell: (row) => {
        return (
          <HealthScoreIcon
            simple
            status={row.details?.status}
            data-tag="allowRowEvents"
            value={row.details?.healthScore}
            category={row.details?.healthCategory}
            name={row.details?.healthscoreName}
            date={row.details?.healthScoreUpdatedAt}
          />
        );
      },
    };
  }
  if (field.name === "statusId") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: field.displayName,
      selector: (row) => row.details?.status?.name,
      sortable: false,
      cell: (row) => {
        return (
          !row.isParent && (
            <Text color="gray.800">{row.details?.status?.name}</Text>
          )
        );
      },
    };
  }
  if (field.name === "lifeCycleStageId") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: field.displayName,
      selector: (row) => row.details?.lifeCycleStage?.name,
      sortable: false,
      cell: (row) => {
        return (
          !row.isParent && (
            <Box>
              <Text color="gray.800">{row.details?.lifeCycleStage?.name}</Text>
              {row.details?.lifeCycleStageOverride && (
                <Text fontSize={"xxs"} variant="muted">
                  Manual
                </Text>
              )}
            </Box>
          )
        );
      },
    };
  }
  if (field.name === "planDue") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: "Days",
      selector: (row) => row.details?.companyPlan?.name,
      sortable: true,
      width: "100px",
      cell: (row) => {
        const plan = row.details?.companyPlan;
        const expectedCompletedAt = plan?.expectedCompletedAt;
        const completed =
          plan &&
          plan.totalSteps === plan.completedSteps &&
          plan.totalSteps > 0;

        return (
          plan &&
          !completed &&
          !row.isParent && (
            <Box
              color={
                moment(expectedCompletedAt).isBefore(moment()) ? "red" : "black"
              }
              fontSize="xs"
              sx={{ whiteSpace: "nowrap" }}
              data-tag="allowRowEvents"
            >
              {moment(expectedCompletedAt).fromNow()}
            </Box>
          )
        );
      },
    };
  }

  if (field.name === "plan" || field.name === "planName") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: "Journey",
      selector: (row) => row.details?.companyPlan?.name,
      sortable: false,
      width: "175px",
      cell: (row) => {
        const plan = row.details?.companyPlan;

        let percentage = plan
          ? (plan.completedSteps / plan.totalSteps).toFixed(2) * 100
          : 0;

        const planType = row.details?.planType?.name;
        const isLoyal = row.details?.lifeCycleStage?.finalStep;

        return (
          !row.isParent &&
          (planType && !plan ? (
            <Box data-tag="allowRowEvents">
              <Text fontSize="xs" data-tag="allowRowEvents">
                {planType}
              </Text>
              <Text fontSize="xxs" variant="muted" data-tag="allowRowEvents">
                Journey not started
              </Text>
            </Box>
          ) : plan && !isLoyal ? (
            <Box data-tag="allowRowEvents">
              <Box data-tag="allowRowEvents">
                <Flex alignItems={"center"}>
                  <Text fontSize="xs" data-tag="allowRowEvents">
                    {planType}
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  data-tag="allowRowEvents"
                  sx={{ height: 15 }}
                >
                  <ProgressBar
                    completed={percentage}
                    height={5}
                    width={100}
                    bgColor={
                      plan && plan.completedSteps === plan.totalSteps
                        ? theme.colors.brandLight
                        : moment(plan?.expectedCompletedAt).isBefore(moment())
                        ? theme.colors.red
                        : theme.colors.brandLight
                    }
                    baseBgColor={theme.colors.gray[200]}
                    isLabelVisible={false}
                  />
                  {plan && plan.completedSteps === plan.totalSteps ? (
                    <Flex
                      bg="brandLight"
                      color="white"
                      alignItems="center"
                      justifyContent="center"
                      mx={2}
                      sx={{ borderRadius: 99, width: 15, height: 15 }}
                    >
                      <TickIcon size={12} />
                    </Flex>
                  ) : (
                    <Text
                      mx={2}
                      fontSize="xxs"
                      data-tag="allowRowEvents"
                      color={
                        moment(plan?.expectedCompletedAt).isBefore(moment())
                          ? theme.colors.red
                          : theme.colors.black
                      }
                    >
                      {percentage.toFixed(0)}%
                    </Text>
                  )}
                </Flex>
                <Text fontSize="xxs" variant="muted" data-tag="allowRowEvents">
                  {plan?.name}
                </Text>
              </Box>
            </Box>
          ) : (
            isLoyal && (
              <Box data-tag="allowRowEvents">
                <Box data-tag="allowRowEvents">
                  <Flex alignItems="center" data-tag="allowRowEvents">
                    <Flex
                      color="yellow"
                      alignItems="center"
                      justifyContent="center"
                      mx={2}
                      flexDirection="column"
                    >
                      <RiShieldStarFill size={24} />
                      <Text
                        fontSize="xxs"
                        data-tag="allowRowEvents"
                        color="text"
                      >
                        Loyal
                      </Text>
                    </Flex>
                    <Box>
                      <Box
                        fontSize="xs"
                        sx={{ whiteSpace: "nowrap" }}
                        data-tag="allowRowEvents"
                      >
                        <Text fontSize="xs" data-tag="allowRowEvents">
                          {planType}
                        </Text>
                      </Box>
                      <Box
                        fontSize="xxs"
                        sx={{ whiteSpace: "nowrap" }}
                        data-tag="allowRowEvents"
                      >
                        since {moment(row.details?.startDate).fromNow()}
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            )
          ))
        );
      },
    };
  }
  if (field.name === "ownerUserId") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: field.displayName,
      selector: (row) => row.details?.owner?.name,
      sortable: true,
      width: "175px",
      cell: (row) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            data-tag="allowRowEvents"
          >
            {row.details?.owner && (
              <Box width={25} data-tag="allowRowEvents">
                <Avatar
                  size={25}
                  round
                  name={row.details?.owner?.name}
                  email={row.details?.owner?.email}
                  src={row.details?.owner?.avatarUrl}
                />
              </Box>
            )}
            <Text
              px={1}
              fontSize="xs"
              data-tag="allowRowEvents"
              sx={{ wordBreak: "break-word" }}
            >
              {row.details?.owner?.name}
            </Text>
          </Flex>
        );
      },
    };
  }
  if (field.name === "ownerUserEmail") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: field.displayName,
      selector: (row) => row.details?.owner?.email,
      sortable: true,
      cell: (row) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            data-tag="allowRowEvents"
          >
            {row.details?.owner && (
              <Box width={25}>
                <Avatar
                  size={25}
                  round
                  name={row.details?.owner?.name}
                  email={row.details?.owner?.email}
                  src={row.details?.owner?.avatarUrl}
                />
              </Box>
            )}
            <Text mx={1} data-tag="allowRowEvents">
              {row.details?.owner?.name}
              <Text variant="muted" mx={1} data-tag="allowRowEvents">
                {row.details?.owner?.email}
              </Text>
            </Text>
          </Flex>
        );
      },
    };
  }

  if (field.name === "lastSeenAt") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: field.displayName,
      selector: (row) => {
        const lastSeen = !!row.lastSeenAt
          ? row.lastSeenAt
          : row.details?.lastSeenAt;
        return moment(lastSeen).diff(moment());
      },
      sortable: true,
      width: "120px",
      cell: (row) => {
        const lastSeen = !!row.lastSeenAt
          ? row.lastSeenAt
          : row.details?.lastSeenAt;
        return (
          !row.isParent &&
          !!lastSeen && (
            <Text data-tag="allowRowEvents" color="gray.800">
              {moment(lastSeen).format("DD/MM/YYYY")}

              <Text data-tag="allowRowEvents" variant="muted">
                {moment(lastSeen).fromNow()}
              </Text>
            </Text>
          )
        );
      },
    };
  }

  if (field.name === "name") {
    return {
      id: field.name,
      schemaName: schemaName,
      order: order,
      name: field.displayName,
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => {
        return (
          <Flex alignItems="center">
            <Box width={18} minWidth={18} mr={2}>
              {row.logoUrl && <Avatar size={18} src={row.logoUrl} />}
            </Box>
            <Text data-tag="allowRowEvents">{row.name}</Text>
          </Flex>
        );
      },
    };
  }

  switch (field.type) {
    case "url":
      return {
        id: field.name,
        schemaName: schemaName,
        order: order,
        name: field.displayName,
        selector: (row) => getValue(row),
        sortable: true,
        cell: (row) => {
          let value = getValue(row);
          return value ? (
            <Link href={value} target="_blank">
              {urlTruncate(value)}
            </Link>
          ) : (
            ""
          );
        },
      };
    case "date":
      return {
        id: field.name,
        schemaName: schemaName,
        order: order,
        name: field.displayName,
        selector: (row) => getValue(row),
        sortable: true,
        width: "120px",
        cell: (row) => {
          let value = getValue(row);
          return (
            <Text data-tag="allowRowEvents" color="gray.800">
              {value ? moment(value).format("DD/MM/YYYY") : ""}
            </Text>
          );
        },
      };
    case "currency":
      return {
        id: field.name,
        schemaName: schemaName,
        order: order,
        name: field.displayName,
        selector: (row) => Number(getValue(row)),
        sortable: true,
        cell: (row) => {
          let value = getValue(row);
          return (
            <Text data-tag="allowRowEvents" color="gray.800">
              {value
                ? `${Number(value || "0").toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`
                : ""}
            </Text>
          );
        },
      };
    default:
      return {
        id: field.name,
        schemaName: schemaName,
        order: order,
        name: field.displayName,
        selector: (row) => getValue(row),
        sortable: field.storage !== "custom", // do not allow sortable to custom fields as they are not indexed for it
        cell: (row) => {
          let value = getValue(row);
          return (
            <Text data-tag="allowRowEvents" color={field.greyOut && "gray.800"}>
              {value}
            </Text>
          );
        },
      };
  }
};

const getValidationType = (type) => {
  switch (type) {
    case "integer":
      return "number";
    default:
      return "string";
  }
};

const getValidations = (field) => {
  let validations = [];
  if (field.required || field.name == "name") {
    validations.push({ type: "required", params: [`Required`] });
  }
  if (field.type === "email") {
    validations.push({ type: "email", params: [`Invalid Email`] });
  }
  if (field.type === "url") {
    validations.push({ type: "url", params: [`Invalid Url`] });
  }
  return validations;
};

export function getConversionFields(schema) {
  const [fields, setFields] = useState();
  const { data } = useQuery(TypeSchemaQuery);
  const productId = getDefaultProduct();

  useEffect(() => {
    const typeSchemas = data && data?.typeSchemas;
    const typeSchema = typeSchemas?.find((s) => s.typeName === schema?.name);
    setFields(typeSchema?.attributes);
  }, [data]);

  let columns =
    (fields &&
      fields
        ?.filter((field) => field.featured)
        .filter((field) => field.productId === productId || !field.productId)
        .filter((field) => field.name !== "ownerUserId")
        .filter((field) => field.name !== "mrr")
        .filter((field) => field.name !== "arr")
        .filter((field) => field.name !== "healthscore")
        .sort((a, b) => {
          if (a.sortOrder > b.sortOrder) return 1;
          if (a.sortOrder < b.sortOrder) return -1;
          return 0;
        })
        .map((c, i) => {
          return { ...c, id: c.name, sortOrder: i + 10, greyOut: true };
        })) ||
    [];

  const preDefaultColumns = [
    {
      id: "conversionQualified",
      name: "conversionQualified",
      displayName: "Score",
      sortOrder: 1,
    },
    {
      id: "conversionFitScore",
      name: "conversionFitScore",
      displayName: "Fit",
      sortOrder: 2,
    },
    {
      id: "conversionAdoptionScore",
      name: "conversionAdoptionScore",
      displayName: "Adoption",
      sortOrder: 3,
    },
    {
      id: "lifeCycleStageId",
      name: "lifeCycleStageId",
      displayName: "Status",
      sortOrder: 60,
    },
  ];

  const postDefaultColumns =
    [
      { id: "plan", name: "plan", displayName: "Journey", sortOrder: 77 },
      {
        id: "ownerUserId",
        name: "ownerUserId",
        displayName: "Owner",
        sortOrder: 80,
      },
      {
        id: "lastSeenAt",
        name: "lastSeenAt",
        displayName: "Last Seen",
        sortOrder: 90,
      },
    ] || [];
  columns = preDefaultColumns.concat(columns);
  columns = columns.concat(postDefaultColumns);
  return columns;
}

export function getContactFields(schema) {
  const [fields, setFields] = useState();
  const { data } = useQuery(TypeSchemaQuery);
  const productId = getDefaultProduct();

  useEffect(() => {
    const typeSchemas = data && data?.typeSchemas;
    const typeSchema = typeSchemas?.find((s) => s.typeName === schema?.name);
    setFields(typeSchema?.attributes);
  }, [data]);

  let columns =
    (fields &&
      fields
        ?.filter((field) => field.featured)
        .filter((field) => field.productId === productId || !field.productId)
        .filter((field) => field.name !== "ownerUserId")
        .sort((a, b) => {
          if (a.sortOrder > b.sortOrder) return 1;
          if (a.sortOrder < b.sortOrder) return -1;
          return 0;
        })
        .map((c, i) => {
          return { ...c, id: c.name, sortOrder: i + 10, greyOut: true };
        })) ||
    [];

  const preDefaultColumns = [];

  const postDefaultColumns =
    [
      {
        id: "statusId",
        name: "statusId",
        displayName: "Status",
        sortOrder: 60,
      },
      {
        id: "lifeCycleStageId",
        name: "lifeCycleStageId",
        displayName: "Lifecycle",
        sortOrder: 70,
      },
      {
        id: "planName",
        name: "planName",
        displayName: "Journey",
        sortOrder: 80,
      },
      {
        id: "ownerUserId",
        name: "ownerUserId",
        displayName: "Owner",
        sortOrder: 90,
      },
      {
        id: "lastSeenAt",
        name: "lastSeenAt",
        displayName: "Last Seen",
        sortOrder: 90,
      },
    ] || [];
  columns = preDefaultColumns.concat(columns);
  columns = columns.concat(postDefaultColumns);

  return columns;
}
export function getPaidFields(schema) {
  const [fields, setFields] = useState();
  const { data } = useQuery(TypeSchemaQuery);
  const productId = getDefaultProduct();

  useEffect(() => {
    const typeSchemas = data && data?.typeSchemas;
    const typeSchema = typeSchemas?.find((s) => s.typeName === schema?.name);
    setFields(typeSchema?.attributes);
  }, [data]);

  let columns =
    (fields &&
      fields
        ?.filter((field) => field.featured)
        .filter((field) => field.productId === productId || !field.productId)
        .filter((field) => field.name !== "ownerUserId")
        .sort((a, b) => {
          if (a.sortOrder > b.sortOrder) return 1;
          if (a.sortOrder < b.sortOrder) return -1;
          return 0;
        })
        .map((c, i) => {
          return { ...c, id: c.name, sortOrder: i + 10, greyOut: true };
        })) ||
    [];

  const preDefaultColumns = [
    {
      id: "healthScore",
      name: "healthScore",
      displayName: "Health",
      sortOrder: 1,
    },
    {
      id: "lifeCycleStageId",
      name: "lifeCycleStageId",
      displayName: "Status",
      sortOrder: 60,
    },
  ];

  const postDefaultColumns =
    [
      { id: "plan", name: "plan", displayName: "Journey", sortOrder: 77 },
      {
        id: "ownerUserId",
        name: "ownerUserId",
        displayName: "Owner",
        sortOrder: 80,
      },
      {
        id: "lastSeenAt",
        name: "lastSeenAt",
        displayName: "Last Seen",
        sortOrder: 90,
      },
    ] || [];
  columns = preDefaultColumns.concat(columns);
  columns = columns.concat(postDefaultColumns);

  return columns;
}
export function getContactPeopleFields(schema) {
  const [fields, setFields] = useState();
  const { data } = useQuery(TypeSchemaQuery);
  const productId = getDefaultProduct();

  useEffect(() => {
    const typeSchemas = data && data?.typeSchemas;
    const typeSchema = typeSchemas?.find((s) => s.typeName === schema?.name);
    setFields(typeSchema?.attributes);
  }, [data]);

  let columns =
    (fields &&
      fields
        ?.filter((field) => field.featured)
        .filter((field) => field.productId === productId || !field.productId)
        .filter((field) => field.name !== "ownerUserId")
        .sort((a, b) => {
          if (a.sortOrder > b.sortOrder) return 1;
          if (a.sortOrder < b.sortOrder) return -1;
          return 0;
        })
        .map((c, i) => {
          return { ...c, id: c.name, sortOrder: i + 10, greyOut: false };
        })) ||
    [];

  const preDefaultColumns = [
    {
      id: "nps",
      name: "nps",
      displayName: "",
      sortOrder: 1,
    },
    {
      id: "online",
      name: "online",
      displayName: "",
      sortOrder: 2,
    },
  ];

  const postDefaultColumns = [
    {
      id: "endUserCategoryId",
      name: "endUserCategoryId",
      displayName: "",
      sortOrder: 99,
    },
  ];
  columns = preDefaultColumns.concat(columns);
  columns = columns.concat(postDefaultColumns);

  return columns;
}

export function useDynamicSchema(schema, selectedColumns) {
  const [fields, setFields] = useState();
  const { data, error } = useQuery(TypeSchemaQuery);
  const { data: filters } = getSearchFilter();
  const productId = filters?.filters?.productId;

  useEffect(() => {
    const typeSchemas = data && data?.typeSchemas;
    const typeSchema = typeSchemas?.find((s) => s.typeName === schema?.name);
    setFields(typeSchema?.attributes);
  }, [data]);

  const validations = fields
    ?.filter((field) => field.productId === productId || !field.productId)
    ?.filter((field) => field.required)
    .map((field) => {
      let fieldName =
        field.storage == "custom" ? `fields.${field.name}` : field.name;
      let required = field.required;
      return {
        name: fieldName,
        validationType: getValidationType(field.type),
        required: required,
        validations: getValidations(field),
      };
    });

  const initialValues = {};
  if (fields) {
    fields.forEach((item) => {
      initialValues[item.name] = item.defaultValue || undefined;
    });
  }

  const validateSchema = validations && createYupSchema(validations);

  let columns =
    (fields &&
      fields
        .filter((field) => field.productId === productId || !field.productId)
        .filter((field) => field.name !== "ownerUserId")
        .sort((a, b) => {
          if (a.sortOrder > b.sortOrder) return 1;
          if (a.sortOrder < b.sortOrder) return -1;
          return 0;
        })
        .map((field) => {
          return ColumnRender(field, selectedColumns, schema?.name);
        })) ||
    [];

  let defaultColumns =
    schema?.name === "Company"
      ? []
      : [
          { name: "nps", displayName: "Health", order: 0 },
          { name: "online", displayName: "", order: 1 },
          { name: "endUserCategoryId", displayName: "", order: 99 },
        ];

  defaultColumns =
    defaultColumns.map((field) => {
      return ColumnRender(field, selectedColumns, schema?.name);
    }) || [];

  let postDefaultColumns =
    [
      {
        name: "plan",
        displayName: "Journey",
        order: 77,
        typeName: "company_details",
      },
      {
        name: "planName",
        displayName: "Journey",
        order: 78,
        typeName: "company_details",
      },
      {
        name: "ownerUserId",
        displayName: "Owner",
        order: 80,
        typeName: "company_details",
      },
    ].map((field) => {
      return ColumnRender(field, selectedColumns, schema?.name);
    }) || [];
  columns = defaultColumns.concat(columns);
  columns = columns.concat(postDefaultColumns);
  columns = _.intersectionBy(columns, selectedColumns, "id");

  if (schema.name == MODEL_TYPES.EndUser.name) {
    columns = columns.concat({
      name: "Account",
      selector: (row) => row.company?.name,
      sortable: true,
      cell: (row) => {
        return (
          <Link mx={1} href={`/data/accounts/${row.company?.id}`}>
            {row.company?.name}
          </Link>
        );
      },
    });
  }

  return {
    fields: fields,
    validations: validateSchema,
    initialValues: initialValues,
    columns: columns.sort((a, b) => a.order - b.order),
  };
}

export function useDynamicSchemas() {
  const { data, error } = useQuery(TypeSchemaQuery);

  const dataModels = data?.typeSchemas?.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );
  return dataModels;
}
