import React from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Box, Flex, Image, Text } from "rebass";
import EmailTemplateForm from "./EmailTemplateForm";
import Settings from "../ProductSettings";
import GhostButton from "../../../Common/Buttons/GhostButton";
import { RiArrowGoBackLine } from "react-icons/ri";

function EmailTemplate(props) {
  const history = useHistory();
  const { id } = useParams();
  return (
    <Settings>
      <Flex flex={1} flexDirection="column">
        <Box>
          <GhostButton
            onClick={() => history.push("/settings/product/library?tab=emails")}
          >
            <RiArrowGoBackLine /> Back to Library
          </GhostButton>
        </Box>
        <Flex flex={1} p={4} bg="white">
          {id && <EmailTemplateForm {...props} />}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(EmailTemplate);
