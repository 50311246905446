import React, { useEffect, useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import Modal from "Components/Common/Modal";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import { SaveIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import IntegrationVerifyMutation from "../../../../graphql/mutations/IntegrationVerify.gql";
import IntegrationImportMutation from "../../../../graphql/mutations/IntegrationImport.gql";
import IntegrationDeleteMutation from "../../../../graphql/mutations/IntegrationDelete.gql";
import IntegrationQuery from "../../../../graphql/queries/Integration.gql";
import IntegrationsQuery from "../../../../graphql/queries/Integrations.gql";
import MapFields from "../Common/FieldMapping/MapFields";
import { ImportIcon, IntegrationIcon } from "../../../Common/Icons";
import GhostButton from "../../../Common/Buttons/GhostButton";
import Loader from "../../../Common/Loader";
import { deepOmit } from "../../../../consts";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import moment from "moment";
import { getIntegrationDefinitions } from "../../../../hooks/getIntegrationDefinitions";
import AuthenticationForm from "./Authentication/AuthenticationForm";

function IntegrationsForm(props) {
  const { addToast } = useToasts();
  const { id } = useParams();
  const history = useHistory();
  const [open, setOpen] = useState(undefined);
  const [endpoint, setEndpoint] = useState(undefined);

  const [verifyIntegration, { loading, error }] = useMutation(
    IntegrationVerifyMutation,
    {
      refetchQueries: [{ query: IntegrationsQuery }],
    }
  );

  const [importIntegration, { loading: importing, error: importError }] =
    useMutation(IntegrationImportMutation);

  const [destroy, {}] = useMutation(IntegrationDeleteMutation, {
    refetchQueries: [{ query: IntegrationsQuery }],
  });

  const { data, refetch } = useQuery(IntegrationQuery, {
    variables: { id: id },
    skip: !id || id === "new",
  });

  const apiData = {
    ...data?.integration,
  };

  const importData = () => {
    importIntegration({
      variables: {
        id: id,
      },
    }).then(
      (response) => {
        if (response.data?.importIntegration.errors?.length > 0) {
          addToast(
            <Flex>{response.data?.importIntegration.errors[0]}</Flex>,
            ErrorParams
          );
        } else {
          addToast(
            <Flex>Importing data - this may take a minute.</Flex>,
            SuccessParams
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={apiData}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          const newValues = deepOmit(values, "__typename");
          verifyIntegration({
            variables: {
              ...newValues,
              authId: newValues.integrationAuthentication.id,
              id: newValues.id ? newValues.id : null,
            },
          }).then(
            (response) => {
              if (response.data?.verifyIntegration.errors) {
                addToast(
                  <Flex>{response.data?.verifyIntegration.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                addToast(<Flex>Saved!</Flex>, SuccessParams);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const {
            values,
            setFieldValue,
            handleBlur,
            handleSubmit,
            dirty,
            errors,
            loading,
          } = formProps;

          const fieldMapping =
            values &&
            endpoint &&
            values.endpointConfigs &&
            values.endpointConfigs.find((o) => o.endpointId === endpoint?.id)
              ?.fieldMapping;
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex flexGrow={1} flexDirection="column">
                <Flex alignItems="center" justifyContent="flex-end">
                  {values.verified && (
                    <Box>
                      <Flex
                        variant="badge"
                        color="white"
                        bg="green"
                        alignItems={"center"}
                      >
                        Verified
                      </Flex>
                    </Box>
                  )}
                  <Flex width={100} alignItems="center" mx={2}>
                    <Box>
                      <CustomFormGroup
                        noMargin
                        placeholder="Active"
                        type="switch"
                        label=""
                        name="active"
                        value={values.active}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Text mx={1} variant={"label"}>
                      Active
                    </Text>
                  </Flex>
                  <Box>
                    <SecondaryButton
                      leftIcon={<ImportIcon />}
                      onClick={importData}
                      disabled={values.verified && values.active}
                    >
                      Import Data
                    </SecondaryButton>
                  </Box>
                  <DeleteButton
                    callback={destroy}
                    onCancel={() => history.goBack()}
                    refetch={refetch}
                    id={id}
                    name="Integration"
                    redirectUrl="/settings/product/integrations"
                    disabled={!values.verified || loading}
                  />
                  <PrimaryButton
                    leftIcon={<SaveIcon />}
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Save
                  </PrimaryButton>
                </Flex>
                <Box mt={3} />
                {values.integrationDefinition && (
                  <Flex flexDirection={"column"} mb={3}>
                    <Flex>
                      <IntegrationIcon
                        logoUrl={values.integrationDefinition?.icon}
                      />
                      <Box mx={2}>
                        <Text>{values.integrationDefinition?.name}</Text>
                        <Flex alignItems={"center"}>
                          <Link
                            fontSize={"xxs"}
                            href={values.integrationDefinition?.website}
                          >
                            {values.integrationDefinition?.website}
                          </Link>
                          <Text mx={2} fontSize={"xxs"}>
                            |
                          </Text>
                          <Link
                            fontSize={"xxs"}
                            href={values.integrationDefinition?.documentation}
                          >
                            {values.integrationDefinition?.documentation}
                          </Link>
                        </Flex>

                        <Flex
                          my={2}
                          variant="badge"
                          fontSize={"xxs"}
                          bg="gray.300"
                          alignItems="center"
                        >
                          Connection: {values.integrationAuthentication?.name}
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                )}
                <CustomFormGroup
                  placeholder="Name"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  helperText={errors && errors.name && errors.name}
                />

                <>
                  <Text variant="h3" my={2}>
                    Mapping
                  </Text>
                  {values.endpoints?.map((e, i) => {
                    const fm = values.endpointConfigs?.find(
                      (o) => o.endpointId === e.id
                    );
                    return (
                      <Flex key={e.id} my={2} alignItems="center">
                        <Flex width={40} alignItems="center" mx={2}>
                          <Box>
                            <CustomFormGroup
                              noMargin
                              placeholder="Active"
                              type="switch"
                              label=""
                              name={`endpointConfigs[${i}].active`}
                              value={fm.active}
                              onChange={setFieldValue}
                            />
                          </Box>
                        </Flex>
                        <Flex mx={2} width={1 / 4}>
                          {e.name}
                        </Flex>
                        {fm ? (
                          <PrimaryButton
                            onClick={() => {
                              setEndpoint(e);
                              setOpen(true);
                            }}
                          >
                            Edit Mapping
                          </PrimaryButton>
                        ) : (
                          <GhostButton
                            onClick={() => {
                              setOpen(true);
                              setEndpoint(e);
                            }}
                          >
                            Add Mapping
                          </GhostButton>
                        )}
                      </Flex>
                    );
                  })}
                </>
                {values.lastRunAt && (
                  <Flex alignItems="center" mt={3}>
                    <Text variant="muted" mx={1}>
                      Last Imported:
                    </Text>
                    <Text variant="muted" mx={1}>
                      {moment(values.lastRunAt).format("DD MMM YYYY hh:mma")}
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Modal
                width={900}
                open={open}
                title={
                  <Flex>
                    <Flex>
                      <IntegrationIcon
                        logoUrl={values.integrationDefinition?.icon}
                      />
                      <Box mx={2}>
                        <Text>{values.integrationDefinition?.name}</Text>
                      </Box>
                    </Flex>
                    <Text mx={1}>Configuration</Text>
                  </Flex>
                }
                onDismiss={() => {
                  setOpen(false);
                }}
              >
                <Flex flexDirection="column" px={3} pb={4}>
                  <MapFields
                    title={endpoint?.name}
                    fieldDefinition={endpoint?.fieldDefinition}
                    fieldMapping={fieldMapping}
                    onChange={(mapping) => {
                      console.log(endpoint);
                      let index =
                        values.endpointConfigs &&
                        values.endpointConfigs.length > 0
                          ? values.endpointConfigs?.indexOf(
                              values.endpointConfigs?.find(
                                (e) => e.endpointId === endpoint.id
                              )
                            )
                          : 0;
                      index = index < 0 ? values.endpointConfigs.length : index;
                      setFieldValue(`endpointConfigs[${index}]`, {
                        endpointId: endpoint.id,
                        active: endpoint.active || true,
                        name: endpoint.name,
                        fieldMapping: mapping,
                      });
                    }}
                  />
                </Flex>
              </Modal>
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default IntegrationsForm;

// const SubFormByApiType = ({ values, setFieldValue, handleBlur, errors }) => {
//
//   if (values.apiType === "freshdesk") {
//     return (
//       <>
//         <CustomFormGroup
//           placeholder="API KEY"
//           label={
//             <Flex>
//               Api Key{" "}
//               <Text mx={2} fontSize={"xs"} sx={{ textTransform: "none" }}>
//                 (Your API KEY from FreshDesk under your profile.)
//               </Text>
//             </Flex>
//           }
//           name="username"
//           value={values.username}
//           onChange={setFieldValue}
//           onBlur={handleBlur}
//           helperText={errors && errors.username && errors.username}
//         />
//         <CustomFormGroup
//           placeholder="Subdomain"
//           label="Subdomain"
//           name="settings.subdomain"
//           value={values.settings?.subdomain}
//           onChange={setFieldValue}
//           onBlur={handleBlur}
//           helperText={
//             errors &&
//             errors.settings &&
//             errors.settings.subdomain &&
//             errors.settings.subdomain
//           }
//         />
//       </>
//     );
//   }
//   else {
//     return (
//       <>
//         <CustomFormGroup
//           placeholder="Username"
//           label="Username"
//           name="username"
//           value={values.username}
//           onChange={setFieldValue}
//           onBlur={handleBlur}
//           helperText={errors && errors.username && errors.username}
//         />
//         {!values.verified && (
//           <CustomFormGroup
//             placeholder="Password"
//             type="password"
//             label="Password"
//             name="password"
//             value={values.password}
//             onChange={setFieldValue}
//             onBlur={handleBlur}
//             helperText={errors && errors.password && errors.password}
//           />
//         )}
//       </>
//     );
//   }
// };
