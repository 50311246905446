import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Box, Text } from "rebass";
import { useTheme } from "emotion-theming";
import { useQuery } from "@apollo/client";
import Page from "Components/Page";
import Loader from "Components/Common/Loader";
import ProgressBar from "@ramonak/react-progress-bar";
import { CheckCircleOffIcon, TickIcon } from "../../Common/Icons";
import HomeTopbar from "../Home/HomeTopbar";
import { setupConfigureItems } from "../../../consts";
import CurrentAccountQuery from "../../../graphql/queries/CurrentAccount.gql";

function GettingStarted(props) {
  const theme = useTheme();
  const {
    loading,
    data: currentAccount,
    error,
    refetch,
  } = useQuery(CurrentAccountQuery, { fetchPolicy: "network-only" });

  const config = currentAccount?.currentAccount?.setupConfig;
  const items = config ? Object.keys(config) : [];
  const completedItems = items?.filter((k) => config[k]).length;

  const percentComplete =
    items.length > 0 ? ((completedItems / items.length) * 100).toFixed(0) : 0;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Page title="Dashboard" topBar={<HomeTopbar />}>
      {loading && <Loader />}
      <Flex flex={1} justifyContent="center" alignItems="center" bg="gray.100">
        <Flex
          variant="box"
          p={4}
          bg="white"
          justifyContent="center"
          flexDirection="column"
        >
          <Text variant="h1" my={2} textAlign="center">
            Getting Started
          </Text>
          <Text fontSize="sm" my={2} textAlign="center">
            Complete these activities to get the most out of Userlot
          </Text>
          <Flex width="100%" my={3} alignItems="center">
            <Text mr={4} textAlign="center" fontSize="xs">
              {percentComplete}%
            </Text>
            <Box width="100%">
              <ProgressBar
                completed={percentComplete}
                height={10}
                bgColor={theme.colors.brandLight}
                baseBgColor={theme.colors.gray[200]}
                isLabelVisible={false}
              />
            </Box>
          </Flex>

          {setupConfigureItems.map((item) => {
            return <ListItem {...item} complete={config && config[item.id]} />;
          })}
        </Flex>
      </Flex>
    </Page>
  );
}

export default GettingStarted;

const ListItem = ({ complete, title, link }) => {
  const history = useHistory();
  return (
    <Flex
      alignItems="center"
      py={2}
      px={2}
      sx={{
        cursor: "pointer",
        ":hover": { borderRadius: 99, backgroundColor: "brandHighlight" },
      }}
      onClick={() => link && history.push(link)}
    >
      {complete ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          width={20}
          height={20}
          sx={{ borderRadius: 99 }}
          bg="brandLight"
          color="white"
        >
          <TickIcon />
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          width={20}
          height={20}
          color="accent"
        >
          <CheckCircleOffIcon />
        </Flex>
      )}
      <Text
        fontSize="sm"
        fontWeight={complete ? "500" : "bold"}
        mx={2}
        color={complete ? "gray.700" : "text"}
      >
        {title}
      </Text>
    </Flex>
  );
};
