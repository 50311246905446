import React, { useState } from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import { curveMonotoneX } from "d3-shape";

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  DiscreteColorLegend,
  Highlight,
} from "react-vis";

import moment from "moment";
import { useTheme } from "emotion-theming";
import { Box, Flex, Image, Text } from "rebass";
import numeral from "numeral";

const _ = require("lodash");

export default function Legend(props) {
  const theme = useTheme();

  return (
    <Flex flexWrap={"wrap"}>
      {props.series.map((d, i) => {
        return i < 8 ? (
          <Flex key={i} alignItems="center" m={1}>
            <Flex
              m={1}
              sx={{ width: 5, height: 5, borderRadius: 3 }}
              bg={theme.colors.graph[i]}
            />
            <Text mx={2} variant="muted" fontSize="xs">
              {d.name}
            </Text>
          </Flex>
        ) : null;
      })}
    </Flex>
  );
}
