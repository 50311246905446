import React, { useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Flex, Image, Box, Text, Link, Heading } from "rebass";
import StepWizard from "react-step-wizard";
import StepOne from "./Step1";
import StepThree from "./Step3";
import StepTwo from "./Step2";
import { StripeProvider } from "react-stripe-elements";
import Settings from "..";
import CurrentAccountQuery from "../../../../graphql/queries/CurrentAccount.gql";
import PlanQuery from "../../../../graphql/queries/Plans.gql";
import AccountSubscriptionMutation from "../../../../graphql/mutations/AccountSubscription.gql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";

function Subscribe(props) {
  const history = useHistory();
  const { addToast } = useToasts();
  const [accountData, setAccountData] = useState();

  const { loading, data, error, refetch } = useQuery(CurrentAccountQuery, {});
  const planQuery = useQuery(PlanQuery, {});
  const [accountSubscription, {}] = useMutation(AccountSubscriptionMutation);

  const billingName = `${data?.currentAccount?.firstName} ${data?.currentAccount?.lastName}`;

  const handleSubmit = (values) => {
    accountSubscription({
      variables: {
        token: values.token,
        planName: values.name,
      },
    }).then(
      (response) => {
        let result = response.data.accountSubscribeMutation;
        if (result?.account) {
          history.push("/account");
        } else {
          addToast(<Flex>{result?.errors[0]}</Flex>, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Settings>
      <StripeProvider apiKey={"pk_test_cqbSjdcXYPaed5qFtiHQdYJz00t9hZrs7l"}>
        <Flex flex={1} flexDirection="column" m={4}>
          <Flex my={2} justifyContent="space-between">
            <Text variant="h2">My Subscription</Text>
          </Flex>
          <StepWizard>
            <StepOne
              currentPlan={data?.currentAccount?.plan}
              data={accountData}
              plans={
                planQuery &&
                planQuery.data?.plans.sort((a, b) => a.price - b.price)
              }
              onUpdate={(data) => {
                setAccountData({ ...accountData, ...data });
              }}
            />
            <StepTwo
              billingName={billingName}
              data={accountData}
              onUpdate={(data) => {
                setAccountData({ ...accountData, ...data });
              }}
            />
            <StepThree
              data={accountData}
              onUpdate={(data) => {
                handleSubmit({ ...accountData, ...data });
              }}
            />
          </StepWizard>
        </Flex>
      </StripeProvider>
    </Settings>
  );
}

export default withRouter(Subscribe);
