import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { FiX } from "react-icons/fi";
import { Text, Button, Box, Flex, Image } from "rebass";
import GhostButton from "../Buttons/GhostButton";
import SecondaryButton from "../Buttons/SecondaryButton";

const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  isLastStep,
}) => {
  return (
    <Box
      {...tooltipProps}
      variant="onboarding"
      sx={{
        backgroundColor: "#fff",
        minWidth: "400px",
        maxWidth: "400px",
        position: "relative",
        borderRadius: "medium",
        textAlign: "center",
      }}
      padding={3}
    >
      <Flex justifyContent="flex-end" alignItems="center">
        <GhostButton {...closeProps}>
          <FiX />
        </GhostButton>
      </Flex>
      {step.image && (
        <Flex my={3} alignItems="center" justifyContent="center">
          <Image src={require("assets/images/Welcome.png")} width={250} />
        </Flex>
      )}
      {step.title && (
        <Text mb={3} variant="h2" color="brand">
          {step.title}
        </Text>
      )}
      <Flex my={2} fontSize="xs" textAlign="left">
        {step.content}
      </Flex>
      <Flex
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
          padding: "5px",
        }}
      >
        <Box>
          <GhostButton {...skipProps}>Hide Tips</GhostButton>
        </Box>
        <Flex justifyContent="flex-end">
          {index > 0 && (
            <SecondaryButton {...backProps}>
              {/* <FormattedMessage id="back" /> */}
              Back
            </SecondaryButton>
          )}
          <Box mx={2} />
          {continuous && (
            <Button {...primaryProps} variant="primary">
              {/* <FormattedMessage id="next" /> */}
              {isLastStep ? "Close" : "Next"}
            </Button>
          )}
          {!continuous && (
            <Button {...closeProps} variant="primary">
              {/* <FormattedMessage id="close" /> */}
              Close
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default function Onboarding(props) {
  const theme = useTheme();
  const [onboardingState, setOnboardingState] = useState(
    localStorage.getItem("onboarding-state")
      ? JSON.parse(localStorage.getItem("onboarding-state"))
      : {
          onboardingStepsComplete: { [props.stepName]: false },
        }
  );
  const [showOnboardingTour, setShowOnboardingTour] = useState(true);

  useEffect(() => {
    // update localStorage if we change onboarding state
    localStorage.setItem("onboarding-state", JSON.stringify(onboardingState));
  }, [onboardingState]);

  useEffect(() => {
    if (
      onboardingState.skipOnboarding ||
      onboardingState.onboardingStepsComplete[props.stepName]
    ) {
      setShowOnboardingTour(false);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    
    if ([EVENTS.STEP_AFTER].includes(type)) {
      if ([ACTIONS.NEXT].includes(action)) {
        props.onChange && props.onChange(index);
      }

      if ([ACTIONS.CLOSE].includes(action)) {
        props.onChange && props.onChange(index);
        setShowOnboardingTour(false);
      }
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
    } else if ([STATUS.FINISHED].includes(status)) {
      console.log("finished");
      // Need to set our running state to false, so we can restart if we click start again.
      setOnboardingState({
        ...onboardingState,
        onboardingStepsComplete: {
          ...onboardingState.onboardingStepsComplete,
          [props.stepName]: true,
        },
      });
      setShowOnboardingTour(false);
    } else if ([STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setOnboardingState({
        ...onboardingState,
        skipOnboarding: true,
      });
      setShowOnboardingTour(false);
    }
  };

  return (
    <Joyride
      tooltipComponent={Tooltip}
      steps={props.steps}
      run={showOnboardingTour}
      callback={handleJoyrideCallback}
      continuous={props.continuous}
      disableScrolling={props.disableScrolling}
      styles={{
        options: {
          arrowColor: "#fff",
          overlayColor: "rgba(0, 0, 0, 0.4)",
          primaryColor: theme.colors.brand,
          textColor: theme.colors.text,
          width: 900,
          zIndex: 1000,
        },
        buttonNext: {
          backgroundColor: theme.colors.brandLight,
          color: "#fff",
        },
        buttonBack: {
          color: theme.colors.brand,
          marginLeft: "auto",
          marginRight: 5,
        },
        buttonClose: {
          color: theme.colors.text,
          height: 14,
          padding: 15,
          position: "absolute",
          right: 0,
          top: 0,
          width: 14,
        },
        buttonSkip: {
          color: theme.colors.text,
          fontSize: 14,
        },
        tooltipTitle: {
          primaryColor: theme.colors.brandLight,
          fontSize: "48px",
          margin: "0 0 10px 0",
        },
      }}
    />
  );
}

Onboarding.propTypes = {
  steps: PropTypes.array.isRequired,
  stepName: PropTypes.string.isRequired,
  disableScrolling: PropTypes.bool,
};
