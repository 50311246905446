import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text, Button, Box, Link } from "rebass";
import PropTypes from "prop-types";
import GaugeChart from "react-advanced-gauge-chart";

function SatisfactionItem({ activity, refetch }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  console.log(activity);
  return (
    <Flex flexDirection={"column"} bg="gray.100" p={3}>
      <Flex alignItems={"center"}>
        Updated to
        <Text px={1} fontWeight={"bold"}>
          {activity.fields.value || "not set"}
        </Text>
        {activity.fields.value_diff !== 0 && (
          <>
            changed from
            <Text px={1} fontWeight={"bold"}>
              {activity.fields.value - activity.fields.value_diff}
            </Text>
          </>
        )}
      </Flex>
      <Text px={1}>{activity.fields.comment}</Text>
    </Flex>
  );
}
SatisfactionItem.propTypes = {
  activity: PropTypes.object,
};
export default SatisfactionItem;
