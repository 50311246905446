import { useTheme } from "emotion-theming";
import React from "react";
import { RiQuestionLine } from "react-icons/ri";
import ReactTooltip from "react-tooltip";
import { Box, Flex, Text } from "rebass";

function WidgetWrapper(props) {
  const theme = useTheme();
  return (
    <Flex variant="box" m={2} p={3} flexDirection="column" flex={1} bg="white">
      <Flex mb={2}>
        <Flex flex={1} flexDirection={"column"}>
          <Text width="100%" color="brandLight" fontSize="sm" fontWeight="bold">
            {props.title}
          </Text>
          {props.subtitle && (
            <Text variant="muted" fontSize="xxs">
              {props.subtitle}
            </Text>
          )}
        </Flex>
        {props.menu}
        {props.help && (
          <Flex
            justifyContent={"flex-end"}
            alignItems="center"
            data-for={props.title}
            data-tip={props.help}
          >
            <ReactTooltip
              id={props.title}
              place="top"
              effect="solid"
              getContent={(dataTip) => dataTip}
            />
            <RiQuestionLine color={theme.colors.line} />
          </Flex>
        )}
      </Flex>

      <Flex m={2} flex={1}>
        {props.children}
      </Flex>
    </Flex>
  );
}

export default WidgetWrapper;
