import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import HealthscoresQuery from "../graphql/queries/Healthscore.gql";

export function getHealthscore(id) {
  const query = useQuery(HealthscoresQuery, {
    variables: {
      id: id,
    },
    skip: !id || id === "new",
  });

  return {
    ...query,
  };
}
