import React from "react";

import {
  AutomationIcon,
  HomeIcon,
  ReportIcon,
  UserIcon,
} from "Components/Common/Icons";
import Topbar from "Components/Common/Topbar/Topbar";
import { CompanyIcon } from "../../Common/Icons";

function ConversionTopbar(props) {
  return (
    <Topbar
      title="Conversion"
      menuItems={[
        { name: "Accounts", icon: CompanyIcon, link: "/conversion" },
        {
          name: "Reports",
          icon: ReportIcon,
          link: "/conversion/report",
          disabled: true,
        },
        {
          name: "Automation",
          icon: AutomationIcon,
          link: "/conversion/automation",
          disabled: true,
        },
      ]}
      crumbs={props.crumbs}
    />
  );
}

export default ConversionTopbar;
