import React, { useState } from "react";
import Page from "Components/Page";
import { Flex, Button, Text, Box } from "rebass";
import Filter from "Components/Filter";
import { MODEL_TYPES } from "consts";
import ConversionTopbar from "./CommandCenterTopbar";

function CommandCenter(props) {
  return (
    <Page title="Command Center" topBar={<ConversionTopbar />}>
      <Flex width="100%" alignItems="flex-start">
        {props.children}
      </Flex>
    </Page>
  );
}

export default CommandCenter;
