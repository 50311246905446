import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import { Flex, Text, Box } from "rebass";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { ArrowDropDownIcon } from "Components/Common/Icons";
import { HelpIcon } from "../Icons";

function MenuSelect(props) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);
  const selectedOption = props.options?.find((o) => o.value === props.value);
  return (
    <Box sx={{ position: "relative" }}>
      {props.label && (
        <Text px={1} pb={2} variant="label" htmlFor={props.name}>
          {props.label}
        </Text>
      )}
      <SecondaryButton
        rightIcon={<ArrowDropDownIcon color="brandLight" />}
        onClick={() => setShowMenu(!showMenu)}
      >
        <Flex alignItems="center">
          {selectedOption ? (
            selectedOption?.icon && <selectedOption.icon size={20} />
          ) : (
            <HelpIcon size={20} />
          )}
          <Box ml={2}>
            <Text>{selectedOption?.title || "Not Selected"}</Text>
          </Box>
        </Flex>
      </SecondaryButton>
      {showMenu && (
        <Flex
          bg="white"
          flexDirection="column"
          sx={{
            borderColor: "gray.100",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: 99,
            position: "absolute",
            top: 40,
            width: 250,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[500]}`,
          }}
        >
          {props.options.length === 0 && (
            <Flex
              flex={1}
              px={3}
              py={2}
              onClick={() => {
                setShowMenu(false);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "brandHighlight",
                  borderRadius: 10,
                },
              }}
            >
              {props.noOptions ? (
                props.noOptions
              ) : (
                <Box ml={2}>
                  <Text>No options</Text>
                </Box>
              )}
            </Flex>
          )}
          {props.options.map((o) => (
            <Flex
              flex={1}
              px={3}
              py={2}
              onClick={() => {
                props.onChange(props.name, o);
                setShowMenu(false);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "brandHighlight",
                  borderRadius: 10,
                },
              }}
            >
              <Box>
                {o.icon && <o.icon size={20} color={theme.colors.brandLight} />}
              </Box>
              <Box ml={2}>
                <Text>{o.title}</Text>
                <Text variant="muted">{o.description}</Text>
              </Box>
            </Flex>
          ))}
        </Flex>
      )}
    </Box>
  );
}

export default MenuSelect;
