import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Flex, Text } from "rebass";
import Modal from "Components/Common/Modal";
import { PlusIcon } from "Components/Common/Icons";
import EndUserForm from "Components/Data/EndUser/EndUserForm";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";

function AddEndUser(props) {
  const [open, setOpen] = useState(false);

  return (
    <Flex justifyContent="center" sx={{ zIndex: 3 }}>
      <Modal
        title={`Add User`}
        open={open}
        width={800}
        onDismiss={() => {
          setOpen(false);
        }}
      >
        <Flex flexDirection="column" px={3} pb={3}>
          <EndUserForm
            id={undefined}
            companyId={props.companyId}
            modalAdd
            onSave={() => {
              setOpen(false);
            }}
          />
        </Flex>
      </Modal>
      <PrimaryButton
        leftIcon={PlusIcon}
        onClick={() => {
          setOpen(true);
        }}
      >
        Add
      </PrimaryButton>
    </Flex>
  );
}

export default withRouter(AddEndUser);
