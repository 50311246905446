import React, { useEffect, useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import {
  RiDatabase2Line,
  RiGitBranchLine,
  RiGitMergeLine,
  RiParentLine,
  RiRepeat2Line,
} from "react-icons/ri";
import {
  AutomationIcon,
  CompanyIcon,
  EventIcon,
  HealthIcon,
  UsersIcon,
} from "Components/Common/Icons";
import Loader from "Components/Common/Loader";
import { MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import MenuButton from "../MenuButton";
import CompanyPage from ".";
import DataEmpty from "../DataEmpty";
import { EditIcon, MoreIcon, SummaryIcon } from "../../Common/Icons";
import Onboarding from "../../Common/Onboarding";
import ConversionScoreIcon from "./ConversionScoreIcon";
import { useUrlParams } from "../../../hooks/useUrlParams";
import EntityAdminMenu from "../EntityAdminMenu";
import EntityDetailMenu from "../EntityDetailMenu";
import CompanyDetail from "./CompanyDetail";
import CompanyParentDetail from "./CompanyParentDetail";
import ExternalId from "../ExternalId";

const tabs = [
  {
    label: "Summary",
    icon: <SummaryIcon size={16} />,
  },
  {
    label: "Lifecycle",
    icon: <RiRepeat2Line size={16} />,
  },
  {
    label: "Health",
    icon: <HealthIcon size={16} />,
  },
  {
    label: "Automation",
    icon: <AutomationIcon size={16} />,
  },
  { label: "Profile", icon: <RiDatabase2Line size={16} /> },
  {
    label: "Users",
    icon: <UsersIcon size={16} />,
  },
  {
    label: "Events",
    icon: <EventIcon size={16} />,
  },
];
const parentTabs = [
  {
    label: "Summary",
    icon: <SummaryIcon size={16} />,
  },
  {
    label: "Children",
    icon: <RiGitMergeLine size={16} />,
  },
  { label: "Profile", icon: <RiDatabase2Line size={16} /> },
  {
    label: "Users",
    icon: <UsersIcon size={16} />,
  },
];
const tourSteps = [
  {
    target: ".SummaryDetail",
    title: "Summary",
    content:
      "The Userlot Account Summary provides a snapshot of account tasks, active \
      Playbooks and a history of interactions with your accounts. Let’s create a \
      task to arrange a call with your account.",
    placement: "bottom",
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: ".LifecycleDetail",
    title: "Customer Lifecycle Journey",
    content:
      "Userlot allows to to create optimised, automated plans to guide your customers \
      through strategic Lifecycle Journeys. Know exactly where your customers sit in their \
      lifecycle, see who is excelling and who needs extra help. ",
    placement: "bottom",
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: ".HealthDetail",
    title: "Health",
    content:
      "Userlot allows you to configure and track customer health score information using a \
      combination of optional Product Usage and Adoption events, Customer Sentiment and Customer \
      Satisfaction Inputs. We’ll configure this later.",
    placement: "bottom",
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: ".AutomationDetail",
    title: "Automation",
    content:
      "Userlot allows you to configure and track customer health score information using a \
      combination of optional Product Usage and Adoption events, Customer Sentiment and Customer \
      Satisfaction Inputs. We’ll configure this later.",
    placement: "bottom",
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: ".profile",
    title: "Profile",
    content:
      "The account profile saves important information about your customers. You can add/ \
      update custom fields, start dates, renewal dates and more. Update the MRR value field with a \
      monthly revenue figure. ",
    placement: "bottom",
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: ".UserDetail",
    title: "Users",
    content:
      "Userlot helps you store activity against your account users including logins and recent \
      activity. Users can be automatically added from an integration, imported from csv or manually added.",
    placement: "bottom",
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: ".EventDetail",
    title: "Events",
    content:
      "Event data is imported automatically using on of Userlots integration methods. Let’s begin by generating some sample login event data",
    placement: "bottom",
    disableBeacon: true,
    disableScrolling: true,
  },
];

function Company(props) {
  const { id } = useParams();
  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const defaultTab = getUrlParameter("tab") || "Summary";
  const isNew = id === "new";
  const { loading, data, error } = getData(MODEL_TYPES.Company, id);
  const isParent = data?.company?.isParent;
  const currentTabs = isParent ? parentTabs : tabs;

  const [selectedTab, setSelectedTab] = useState(
    currentTabs.indexOf(
      currentTabs.find(
        (t) => t.label.toLowerCase() === defaultTab.toLowerCase()
      )
    )
  );
  const details = data?.company?.details;

  console.log(error);
  useEffect(() => {
    setSelectedTab(
      currentTabs.indexOf(
        currentTabs.find(
          (t) => t.label.toLowerCase() === defaultTab.toLowerCase()
        )
      )
    );
  }, [isParent]);
  if (loading) {
    return (
      <CompanyPage {...props}>
        <Loader />
      </CompanyPage>
    );
  }
  return (
    <CompanyPage {...props}>
      <Flex m={2} mt={3} flex={1} flexDirection="column">
        <Flex p={2}>
          <Flex
            flexDirection="column"
            color="primary"
            mx={1}
            sx={{
              width: "330px",
            }}
          >
            <Flex alignItems="top">
              <Box color="brand" mr={1}>
                <CompanyIcon size={20} logo={data?.company?.logoUrl} />
              </Box>
              <Flex flex={1}>
                <Flex flex={1} flexDirection="column" px={2}>
                  <Flex alignItems={"center"}>
                    <Text variant="h2" sx={{ wordBreak: "break-word" }}>
                      {data?.company?.name}
                    </Text>
                    <Box mx={2}>
                      <ConversionScoreIcon
                        status={details?.status}
                        conversionQualified={details?.conversionQualified}
                      />
                    </Box>
                  </Flex>
                  {isParent ? (
                    <Flex
                      variant="badge"
                      bg="gray.200"
                      py={1}
                      px={3}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      Parent Account
                    </Flex>
                  ) : (
                    <ExternalId value={data?.company?.externalId} />
                  )}
                </Flex>
                <Flex alignItems={"flex-start"} justifyContent="flex-end">
                  <EntityDetailMenu
                    id={id}
                    parentType={MODEL_TYPES.Company.name}
                    data={data?.company}
                    onEdit={() => {
                      window.history.replaceState(
                        {},
                        document.title,
                        `${window.location.pathname}?tab=Profile`
                      );
                      setSelectedTab(
                        currentTabs.indexOf(
                          currentTabs.find(
                            (t) => t.label.toLowerCase() === "profile"
                          )
                        )
                      );
                    }}
                  ></EntityDetailMenu>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex flex={1} alignItems="flex-start">
            <Box
              sx={{
                borderBottomColor: "gray.200",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
              }}
            >
              {currentTabs.map((tab, index) => (
                <MenuButton
                  key={tab.label}
                  label={tab.label}
                  icon={tab.icon}
                  onClick={() => {
                    setSelectedTab(index);
                    window.history.replaceState(
                      {},
                      document.title,
                      `${window.location.pathname}?tab=${tab.label}`
                    );
                  }}
                  selected={selectedTab === index}
                />
              ))}
            </Box>
            <Flex flex={1} justifyContent="flex-end">
              <EntityAdminMenu
                id={id}
                parentType={MODEL_TYPES.Company.name}
                onDelete={() => {}}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} width="100%">
          {!isNew &&
            data?.company &&
            (isParent ? (
              <CompanyParentDetail
                id={id}
                parentType={MODEL_TYPES.Company.name}
                selected={selectedTab}
                onSelect={(i) => {
                  setSelectedTab(i);
                }}
              />
            ) : (
              <CompanyDetail
                id={id}
                parentType={MODEL_TYPES.Company.name}
                selected={selectedTab}
                onSelect={(i) => {
                  setSelectedTab(i);
                }}
              />
            ))}
          {isNew && <DataEmpty />}
        </Flex>
      </Flex>
      {data?.company && (
        <Onboarding
          key={selectedTab}
          disableScrolling
          steps={tourSteps}
          stepName="company"
          onChange={(step) => {
            // Move to the next tab
            const tab = currentTabs[step + 1];
            if (tab) {
              setSelectedTab(step + 1);

              window.history.replaceState(
                {},
                document.title,
                `${window.location.pathname}?tab=${tab.label}`
              );
            }
          }}
        />
      )}
    </CompanyPage>
  );
}

export default withRouter(Company);
