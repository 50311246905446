import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Flex, Button, Text, Box, Link } from "rebass";
import "react-vertical-timeline-component/style.min.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "assets/styles/react-tabs.css";
import Attachments from "./Attachments";
import EndUsers from "./EndUsers";
import { MODEL_TYPES } from "consts";
import Events from "./Events";
import { getData } from "hooks/getData";
import { getEndUserData } from "hooks/getEndUserData";
import Activity from "../Activity";
import CompanyForm from "./Company/CompanyForm";
import EndUserForm from "./EndUser/EndUserForm";
import HealthDashboard from "./Health/";
import AutomationDashboard from "./Automation";
import HealthScoreIcon from "./Company/HealthScoreIcon";
import StatusBadge from "./Company/StatusBadge";
import CompanyLogo from "./Company/CompanyLogo";
import LifeCycle from "./Lifecycle";
import Label from "../Common/Forms/Label";
import GhostButton from "../Common/Buttons/GhostButton";
import {
  ArrowDropDownIcon,
  InfoIcon,
  JourneyIcon,
  OwnerChangeIcon,
} from "../Common/Icons";
import CompanyMenuModal from "../Dashboard/CompanyHealth/CompanyMenuModal";
import CompanyRevenueEntries from "./Company/CompanyRevenueEntries";
import UserBadge from "../Common/UserBadge";
import PropTypes from "prop-types";
import BccAddress from "./BccAddress";
import { useHistory } from "react-router-dom";
import AssignAccount from "./EndUser/AssignAccount";
import {
  Ri4KLine,
  RiAttachmentLine,
  RiGitMergeLine,
  RiGlobalLine,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import CompanyDomains from "./Company/CompanyDomains";
import { useQuery } from "@apollo/client";
import HealthByEntity from "../../graphql/queries/HealthByEntity.gql";
import HealthBadge from "./Company/HealthBadge";
import numeral from "numeral";
import moment from "moment";
import CompanyProductList from "./Company/CompanyProductList";
import Tags from "../Common/Tags";
import TagsEditor from "../Common/Tags/TagsEditor";
import EventSummary from "./Events/Summary";
import ReactTooltip from "react-tooltip";
import ExternalIds from "./ExternalIds";
import UserTypeBadge from "../Common/UserTypeBadge";

function EntityDetail(props) {
  const { id, parentType } = props;
  const { companyId } = useParams();
  const history = useHistory();
  const isNew = id === "new";
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(moment());
  let dataQuery = undefined;
  if (parentType == "Company") {
    dataQuery = getData(MODEL_TYPES[parentType], id);
  } else {
    dataQuery = getEndUserData(id, companyId);
  }
  const { data: healthData } = useQuery(HealthByEntity, {
    variables: {
      id,
      parentType,
      days: 30,
    },
  });

  const data =
    dataQuery?.data && dataQuery.data[MODEL_TYPES[parentType].singular];

  const details = data?.details;
  const planType = details?.planType;

  return (
    <Flex flex={1} w="100%" flexDirection="column">
      <Flex flex={1} bg="white" flexDirection="row" justifyContent="center">
        <Flex
          flexDirection="column"
          sx={{
            borderRightColor: "gray.200",
            borderRightStyle: "solid",
            borderRightWidth: 1,
            maxWidth: "320px",
          }}
          my={2}
        >
          {parentType === MODEL_TYPES.Company.name ? (
            <Box>
              <Box>
                <Flex flex={1} alignItems={"center"}>
                  {details?.status && (
                    <Flex
                      flex={1}
                      justifyContent="space-between"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenModal("Status");
                      }}
                    >
                      <StatusBadge
                        status={details?.status}
                        lifecycle={details?.lifeCycleStage}
                      />
                    </Flex>
                  )}
                  {planType && (
                    <Flex flexDirection="column" flex={1}>
                      <Box>
                        <ReactTooltip
                          id="planType"
                          place="top"
                          effect="solid"
                          getContent={(dataTip) => dataTip}
                        />
                        <Button
                          data-for="planType"
                          data-tip="Lifecycle Journey"
                          variant="default"
                          bg="brandHighlight"
                          color="brandLight"
                          ml={0}
                          mr={1}
                          py={0}
                          onClick={() => {
                            setOpenModal("Plan");
                          }}
                          sx={{
                            cursor: "pointer",
                            display: "inline-block",
                            height: 30,
                            marginLeft: 30,
                            marginRight: 30,
                            paddingLeft: 25,
                            position: "relative",
                            borderRadius: 99,
                          }}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <JourneyIcon />
                            <Text mx={2} fontSize="xs">
                              {planType?.name}
                            </Text>
                            <ArrowDropDownIcon />
                          </Flex>
                        </Button>
                      </Box>
                    </Flex>
                  )}
                </Flex>

                <Flex flex={1} mr={1} mt={2}>
                  {details?.status?.name == "Paid" && (
                    <Flex
                      flex={1}
                      onClick={() => {
                        props.onSelect(2);
                        history.push(`${window.location.pathname}?tab=Health`);
                      }}
                      mx={1}
                      sx={{ cursor: "pointer" }}
                    >
                      <HealthScoreIcon
                        status={details?.status}
                        category={details?.healthCategory}
                        value={healthData?.healthByEntity?.value}
                        createdAt={healthData?.healthByEntity?.createdAt}
                        valueDiff={healthData?.healthByEntity?.valueDiff}
                        trend={healthData?.healthByEntity?.trend}
                      />
                    </Flex>
                  )}
                  <HealthBadge
                    key={`sentiment-${refresh.format("hhmmss")}`}
                    name="Sentiment"
                    id={id}
                    parentType={parentType}
                    required
                    onClick={() => setOpenModal("Feedback")}
                  />
                </Flex>
                <Box mt={2}>
                  <GhostButton onClick={() => setOpenModal("Owner")}>
                    {details?.owner ? (
                      <UserBadge
                        name={details.owner.name}
                        email={details.owner.email}
                        subtitle="Owner"
                        avatarUrl={details.owner.avatarUrl}
                      />
                    ) : (
                      <Flex
                        alignItems="center"
                        justifyContent="flex-start"
                        fontSize="xs"
                      >
                        <Flex
                          bg="accent"
                          justifyContent="center"
                          alignItems="flex-end"
                          sx={{ borderRadius: 999, width: 25, height: 25 }}
                        >
                          <OwnerChangeIcon />
                        </Flex>
                        <Text mx={2} fontWeight="bold">
                          Assign Owner
                        </Text>
                      </Flex>
                    )}
                  </GhostButton>
                </Box>
                {details?.owner && (
                  <Box>
                    <GhostButton onClick={() => setOpenModal("ShadowOwner")}>
                      {details?.shadowOwner ? (
                        <UserBadge
                          name={details.shadowOwner.name}
                          email={details.shadowOwner.email}
                          subtitle="Shadow Owner"
                          avatarUrl={details.shadowOwner.avatarUrl}
                        />
                      ) : (
                        <Flex
                          alignItems="center"
                          justifyContent="flex-start"
                          fontSize="xs"
                        >
                          <Flex
                            bg="accent"
                            justifyContent="center"
                            alignItems="flex-end"
                            sx={{ borderRadius: 999, width: 25, height: 25 }}
                          >
                            <OwnerChangeIcon />
                          </Flex>
                          <Text mx={2} fontWeight="bold">
                            Assign Shadow Owner
                          </Text>
                        </Flex>
                      )}
                    </GhostButton>
                  </Box>
                )}
              </Box>

              <Box my={3} mr={2} bg="gray.100" p={3}>
                <Flex alignItems={"center"} flex={1}>
                  <Box width={1 / 2}>
                    <Label>ARR</Label>
                    <Text>${numeral(details?.arr).format(`0,0.00`)}</Text>
                  </Box>
                  <Box width={1 / 2}>
                    <Label>MRR</Label>
                    <Text>${numeral(details?.mrr).format(`0,0.00`)}</Text>
                  </Box>
                </Flex>
                <Flex alignItems={"center"} flex={1} mt={3}>
                  <Box width={1 / 2}>
                    <Label>Start Date</Label>
                    <Text>
                      {details?.startDate ? (
                        moment(details?.startDate).format("DD MMM YYYY")
                      ) : (
                        <Text variant="muted">No set</Text>
                      )}
                    </Text>
                  </Box>
                  <Box width={1 / 2}>
                    <Label>Renewal Date</Label>
                    <Text
                      color={
                        moment(details?.renewalDate) < moment() ? "red" : "text"
                      }
                    >
                      {details?.renewalDate ? (
                        moment(details?.renewalDate).format("DD MMM YYYY")
                      ) : (
                        <Text variant="muted">No set</Text>
                      )}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <>
                {details?.churnReason && (
                  <Box mb={2}>
                    <Label>Churn Reason</Label>
                    <Text my={1} mt={2}>
                      {details?.churnReason?.name}
                    </Text>
                    <Text variant="muted">
                      {moment(details?.churnReasonUpdatedAt).format(
                        "DD MMM YYYY"
                      )}
                    </Text>
                  </Box>
                )}
                <BccAddress address={data?.bccAddress} />
                <Flex flexDirection="column" mt={2}>
                  <EventSummary id={id} parentType={parentType} />
                </Flex>
                <Box mt={3} />
                <TagsEditor
                  id={id}
                  parentType="Company"
                  label="Tags"
                  value={data?.tagList}
                />
                <Flex flexDirection="column" mt={3}>
                  <Tags
                    id={id}
                    parentType={parentType}
                    label="Segments"
                    value={data?.segmentList}
                  />
                </Flex>
                <Flex flexDirection="column">
                  <CompanyProductList products={data?.products} />
                </Flex>
              </>
            </Box>
          ) : (
            <Box mx={2}>
              <Flex mb={2} flexShrink={1}>
                <UserTypeBadge
                  minimise={true}
                  categoryId={data?.endUserCategoryId}
                  online={data?.online}
                />
              </Flex>
              <Flex>
                <HealthBadge
                  key={`nps-${refresh.format("hhmmss")}`}
                  name="Nps"
                  id={id}
                  parentType={parentType}
                  required
                  onClick={() => setOpenModal("Satisfaction")}
                />
                <HealthBadge
                  key={`csat-${refresh.format("hhmmss")}`}
                  name="csat"
                  id={id}
                  parentType={parentType}
                  onClick={() => setOpenModal("Satisfaction")}
                />
                <HealthBadge
                  key={`ces-${refresh.format("hhmmss")}`}
                  name="ces"
                  id={id}
                  parentType={parentType}
                  onClick={() => setOpenModal("Satisfaction")}
                />
              </Flex>

              <AssignAccount id={id} />
              <BccAddress address={data?.bccAddress} />
              <Box mt={2}>
                <EndUserForm id={id} open={isNew} readOnly summary />
              </Box>
            </Box>
          )}
          <CompanyMenuModal
            moduleType={details?.status?.name}
            selectedRows={[{ ...data }]}
            parentType={props.parentType}
            open={!!openModal}
            formType={openModal ? openModal : undefined}
            onSave={() => {
              setOpenModal(undefined);
              setRefresh(moment());
              dataQuery.refetch({ variables: { id, parentType } });
            }}
            onDismiss={() => {
              setOpenModal(undefined);
            }}
          />
        </Flex>
        <Flex flex={1} bg="white">
          <Tabs
            selectedIndex={props.selected}
            onSelect={props.onSelect}
            className="primary-tabs"
          >
            <TabPanel key="Summary">
              <Flex
                className="SummaryDetail"
                flex={1}
                px={4}
                py={3}
                g="white"
                flexDirection="column"
              >
                <Activity
                  id={id}
                  parentType={parentType}
                  actionableName={
                    dataQuery?.data &&
                    (dataQuery?.data[MODEL_TYPES[parentType].name]?.name ||
                      `${
                        dataQuery?.data[MODEL_TYPES[parentType]?.name]
                          ?.firstName
                      } ${
                        dataQuery?.data[MODEL_TYPES[parentType]?.name]?.lastName
                      }`)
                  }
                />
              </Flex>
            </TabPanel>

            {parentType === MODEL_TYPES.Company.name && (
              <TabPanel key="Lifecycle">
                <Flex
                  className="LifecycleDetail"
                  flex={1}
                  px={4}
                  py={3}
                  g="white"
                  flexDirection="column"
                >
                  <LifeCycle id={id} parentType={parentType} />
                </Flex>
              </TabPanel>
            )}
            <TabPanel key="Health">
              <Flex
                className="HealthDetail"
                flex={1}
                px={4}
                py={3}
                g="white"
                flexDirection="column"
              >
                <HealthDashboard id={id} parentType={parentType} />
              </Flex>
            </TabPanel>
            <TabPanel key="Automation">
              <Flex
                className="AutomationDetail"
                flex={1}
                px={4}
                py={3}
                bg="white"
                flexDirection="column"
              >
                <AutomationDashboard id={id} parentType={parentType} />
              </Flex>
            </TabPanel>
            <TabPanel key="Profile">
              <Flex flex={1} bg="white" px={4} py={3} flexDirection="column">
                <Flex flex={1} className="profile">
                  <Flex pr={4} flex={1}>
                    {parentType === MODEL_TYPES.Company.name ? (
                      <Flex flexDirection="column" width="100%">
                        <Flex flex={1}>
                          <Flex pl={2}>
                            <Tabs className="primary-tabs">
                              <TabList>
                                <Tab>
                                  <Flex
                                    flexDirection={"column"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                  >
                                    <InfoIcon />
                                    <Text fontSize={"xs"} mx={2}>
                                      Info
                                    </Text>
                                  </Flex>
                                </Tab>
                                <Tab>
                                  <Flex
                                    flexDirection={"column"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                  >
                                    <RiMoneyDollarCircleLine />
                                    <Text fontSize={"xs"} mx={2}>
                                      Revenue
                                    </Text>
                                  </Flex>
                                </Tab>
                                <Tab>
                                  <Flex
                                    flexDirection={"column"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                  >
                                    <RiGlobalLine />

                                    <Text fontSize={"xs"} mx={2}>
                                      Domains
                                    </Text>
                                  </Flex>
                                </Tab>
                                <Tab>
                                  <Flex
                                    flexDirection={"column"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                  >
                                    <RiAttachmentLine />
                                    <Text fontSize={"xs"} mx={2}>
                                      Attach
                                    </Text>
                                  </Flex>
                                </Tab>
                                <Tab>
                                  <Flex
                                    flexDirection={"column"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                  >
                                    <RiGitMergeLine />
                                    <Text fontSize={"xs"} mx={2}>
                                      API
                                    </Text>
                                  </Flex>
                                </Tab>
                              </TabList>
                              <TabPanel key="info">
                                <Flex flexDirection="column" p={2}>
                                  <CompanyForm
                                    id={id}
                                    typeName={parentType}
                                    readOnly={false}
                                  />
                                  <Text variant="h3" mb={2}>
                                    Logo
                                  </Text>
                                  <CompanyLogo id={id} />
                                </Flex>
                              </TabPanel>
                              <TabPanel key="Details">
                                <Flex pl={2}>
                                  <CompanyRevenueEntries
                                    id={id}
                                    typeName={parentType}
                                    onSave={() => dataQuery.refetch()}
                                  />
                                </Flex>
                              </TabPanel>
                              <TabPanel>
                                <Flex pl={2}>
                                  <CompanyDomains
                                    id={id}
                                    typeName={parentType}
                                    onSave={() => dataQuery.refetch()}
                                  />
                                </Flex>
                              </TabPanel>
                              <TabPanel>
                                <Flex pl={2} flexDirection={"column"}>
                                  <Text variant="h3" mt={3}>
                                    Attach
                                  </Text>
                                  <Attachments
                                    id={id}
                                    parentType={parentType}
                                  />
                                </Flex>
                              </TabPanel>
                              <TabPanel>
                                <Flex pl={2}>
                                  <ExternalIds
                                    id={id}
                                    parentType={parentType}
                                  />
                                </Flex>{" "}
                              </TabPanel>
                            </Tabs>
                          </Flex>
                        </Flex>
                      </Flex>
                    ) : (
                      <Flex flexDirection="column" width="100%">
                        <Flex flex={1}>
                          <Flex width={1 / 2} flexDirection="column" p={2}>
                            <EndUserForm
                              id={id}
                              companyId={companyId}
                              typeName={parentType}
                              readOnly={false}
                            />
                          </Flex>
                          <Flex width={1 / 2} pl={2}>
                            <Tabs className="primary-tabs">
                              <TabList>
                                <Tab>
                                  <Flex
                                    flexDirection={"column"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                  >
                                    <RiAttachmentLine />
                                    <Text fontSize={"xs"} mx={2}>
                                      Attach
                                    </Text>
                                  </Flex>
                                </Tab>
                                <Tab>
                                  <Flex
                                    flexDirection={"column"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                  >
                                    <RiGitMergeLine />
                                    <Text fontSize={"xs"} mx={2}>
                                      API
                                    </Text>
                                  </Flex>
                                </Tab>
                              </TabList>
                              <TabPanel>
                                <Flex pl={2} flexDirection={"column"}>
                                  <Text variant="h3" mt={3}>
                                    Attach
                                  </Text>
                                  <Attachments
                                    id={id}
                                    parentType={parentType}
                                  />
                                </Flex>
                              </TabPanel>
                              <TabPanel>
                                <Flex pl={2}>
                                  <ExternalIds
                                    id={id}
                                    parentType={parentType}
                                  />
                                </Flex>
                              </TabPanel>
                            </Tabs>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </TabPanel>
            {parentType === MODEL_TYPES.Company.name && (
              <TabPanel key="Users">
                <Flex className="UserDetail" bg="white" px={4} py={3}>
                  <EndUsers id={id} />
                </Flex>
              </TabPanel>
            )}
            <TabPanel key="Events">
              <Flex className="EventDetail" bg="white" px={4} py={3}>
                <Events id={id} parentType={parentType} />
              </Flex>
            </TabPanel>
          </Tabs>
        </Flex>
      </Flex>
    </Flex>
  );
}

EntityDetail.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
  selected: PropTypes.number,
  onSelect: PropTypes.func,
};
export default EntityDetail;
