import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import { Flex, Text, Box } from "rebass";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { ArrowDropDownIcon } from "Components/Common/Icons";
import { HelpIcon } from "../../../Common/Icons";

function TriggerFilterList(props) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);
  const selectedOption = props.options.find((o) => o.value === props.value);
  return (
    <Box sx={{ position: "relative" }}>
      <Text px={1} pb={2} variant="label" htmlFor={props.name}>
        {props.label}
      </Text>
      <SecondaryButton
        rightIcon={<ArrowDropDownIcon color="brandLight" />}
        dropdownToggle
        onClick={() => setShowMenu(!showMenu)}
      >
        <Flex alignItems="center">
          {selectedOption ? (
            <selectedOption.icon size={20} />
          ) : (
            <HelpIcon size={20} />
          )}
          <Box ml={2}>
            <Text>{selectedOption?.title || "Not Selected"}</Text>
          </Box>
        </Flex>
      </SecondaryButton>
      {showMenu && (
        <Flex
          bg="white"
          sx={{
            borderColor: "gray.100",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: 99,
            position: "absolute",
            top: 65,
            width: 250,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[500]}`,
          }}
        >
          <Box>
            {props.options.map((o) => (
              <Flex
                px={3}
                py={2}
                onClick={() => {
                  props.onChange(props.name, o);
                  setShowMenu(false);
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "brandHighlight",
                    borderRadius: 10,
                  },
                }}
              >
                <Box>
                  <o.icon size={20} color={theme.colors.brandLight} />
                </Box>
                <Box ml={2}>
                  <Text>{o.title}</Text>
                  <Text variant="muted">{o.description}</Text>
                </Box>
              </Flex>
            ))}
          </Box>
        </Flex>
      )}
    </Box>
  );
}

export default TriggerFilterList;
