import { gql } from "apollo-boost";
import { useDynamicSchema } from "hooks/DynamicSchema";
import { CompanyStatus, MODEL_TYPES } from "consts";
import { getSearchFilter } from "hooks/getSearchFilter";

const _ = require("lodash");

const buildMutation = (schema, queryName, fields, productId) => {
  const filteredFields = fields
    ?.filter(
      (field) => !productId || field.productId === productId || !field.productId
    )
    ?.filter(
      (field) =>
        (field.access == "readwrite" || field.access == "write") &&
        !field.uiExclude
    );

  let inputTypes = filteredFields
    ?.filter((field) => field.storage !== "custom")
    .map((field) => `$${field.name}: String`); //${getType(field)}

  inputTypes = `$id: ID, $tagList: [String], $fields: JSON!, ${inputTypes}`;

  let inputs = filteredFields
    ?.filter((field) => field.storage !== "custom")
    .map((field) => `${field.name}: $${field.name}`);
  inputs = `id: $id, tagList: $tagList, fields: $fields, ${inputs}`;

  if (queryName === "company") {
    inputTypes = `${inputTypes}, $isParent: Boolean, $file: File`;
    inputs = `${inputs}, isParent: $isParent,  file: $file`;
  }

  const mutation = !!fields
    ? gql`mutation dynamicMutation(${inputTypes}) {
    ${queryName}(input: {${inputs}}){
      ${queryName} {
        ${getSchemaDetail(schema, fields, productId, false)}
      }
      errors
    }
  }`
    : gql`mutation dynamicMutation($id: ID) {
    ${queryName}(id: $id) {
        id
        name
    }
  }`;
  return mutation;
};

const getSchemaDetail = (schema, fields, productId, summary) => {
  const attributes = fields
    ?.filter((field) => !field.typeName && field.storage !== "custom")
    .filter(
      (field) =>
        field.name !== "sourceExternalId" &&
        (!productId || field.productId === productId || !field.productId)
    )
    .map((field) => field.name);

  const referenceAttributes = fields
    ?.filter((field) => !!field.typeName && field.modelName === schema.typeName)
    .map((field) => {
      return `${field.typeName} {
        id
        name
      }`;
    });

  const detailSummary =
    schema.name === MODEL_TYPES.Company.name &&
    `logoUrl
      isParent
      rootParentId
     details {
      id
      lifeCycleStageOverride
      lifeCycleStage {
        id
        name
        finalStep
      }
      status {
        id
        name
        color
      }
      mrr
      arr
      startDate
      renewalDate
      healthCategory
      healthScore
      lastSeenAt
      conversionQualified
      conversionFitScore
      conversionAdoptionScore
      owner {
        id
        name
        avatarUrl
      }
      shadowOwner {
        id
        name
        avatarUrl
      }
      planType {
        id
        name
      }
      companyPlan {
        id
        name
        startedAt
        completedAt
        expectedCompletedAt
        activeStepNo
        totalSteps
        completedSteps
        currentStepName
        currentStepOverdue
      }
    }`;

  const externalIds = `externalIds {
                          id
                          name
                          externalId
                          sourceType
                          sourceId
                        }`;

  const details =
    schema.name === MODEL_TYPES.Company.name &&
    ` logoUrl
      segmentList
      products {
        id
        name
        logoUrl
      }
      externalId
      isParent
      parent {
        id
        name
      }
      ${externalIds}
      details {
        id
        status {
          id
          name
          color
        }
        lifeCycleStageOverride
        lifeCycleStage {
          id
          name
          finalStep
        }
        domains
        mrr
        arr
        startDate
        renewalDate
        healthScore
        healthCategory
        healthScoreUpdatedAt
        healthScoreDiff
        healthscoreConfig
        healthscoreId
        lastSeenAt
        conversionQualified
        conversionFitScore
        conversionAdoptionScore
        churnReasonId
        churnReasonUpdatedAt
        churnReason {
          id
          name
        }
        owner {
          id
          name
          avatarUrl
        }
        shadowOwner {
          id
          name
          avatarUrl
        }
        companyRevenueEntries {
          id
          createdAt
          endAt
          revenueValue
          unit
          adjustmentValue
          revenueType
        }
        planType {
          id
          name
        }
        companyPlan {
          id
          name
          activeStepNo
          activeLifeCycleStageId
          startedAt
          completedAt
          expectedCompletedAt
          totalSteps
          completedSteps
          companyPlanSteps {
            id
            workflowName
            startedAt
            completedAt
            startDay
            durationDays
            lifeCycleStageId
            expectedCompletedAt
          }
        }
      }`;

  return `
        id
        fields
        tagList
        bccAddress
        timezone
        locale
        defaultTimezone
        defaultLocale
        ${attributes || ""}
        ${referenceAttributes || ""}
        ${summary ? detailSummary || "" : details || ""}
        ${
          schema.name === MODEL_TYPES.EndUser.name
            ? `
            ${summary ? "endUserId" : ""}
            ${!summary ? externalIds : ""}
            online
            endUserCategoryId
            currentScores {
              id
              scoreType
              value
              valueDiff
            }
            company { 
              id
              name
            }`
            : ""
        }
    `;
};
export const useDynamicGql = (schema, allData) => {
  const { data: filters } = getSearchFilter();
  const productId = filters?.filters?.productId;
  const queryName = allData ? schema?.plural : schema?.singular;
  const { fields } = useDynamicSchema(schema);

  const getAllData = gql`query dynamicQuerySearch ($searchFilters: SearchQuery){
    ${queryName}(searchFilters: $searchFilters) {
      total
      ${queryName} {
        ${getSchemaDetail(schema, fields, productId, true)}
      }
    }
  }`;
  const getDataById =
    schema.name !== MODEL_TYPES.EndUser.name
      ? gql`query dynamicQueryById($id: ID!) {
    ${schema?.singular}(id: $id) {
    ${getSchemaDetail(schema, fields, productId, false)}
    entityWorkflows{
        id
        name
        startedAt
        completedAt
        active
        completedSteps
        totalSteps
        workflowType
        entityWorkflowSteps {
          id
          name
          completedAt
          createdAt
          sequenceNo
          entityWorkflowStepActions {
            id
            config
            actionType
            completedAt
          }
        }
      }
}
}`
      : gql`query dynamicQueryById($id: ID!, $companyId: ID) {
    ${schema?.singular}(id: $id, companyId: $companyId) {
    ${getSchemaDetail(schema, fields, productId, false)}
    entityWorkflows{
        id
        name
        startedAt
        completedAt
        active
        completedSteps
        totalSteps
        workflowType
        entityWorkflowSteps {
          id
          name
          completedAt
          createdAt
          sequenceNo
          entityWorkflowStepActions {
            id
            config
            actionType
            completedAt
          }
        }
      }
}
}`;

  const formatValues = (values) => {
    const newValues = _.cloneDeep(values);
    Object.keys(values).map((key) => {
      const field = fields?.find((field) => field.name === key);
      let value = undefined;
      try {
        switch (field.type) {
          case "number":
          case "integer":
          case "decimal":
          case "currency":
          case "boolean":
            value = JSON.parse(values[key]);
            break;
          case "date":
            value = values[key];
            break;
          default:
            value = values[key];
            break;
        }

        if (key === "status") {
          // Convert to string
          value = CompanyStatus[value];
        }
      } catch (e) {
        value = values[key];
      }
      newValues[key] = value;
    });
    return newValues;
  };
  return {
    formatValues,
    getAllData,
    getDataById,
    mutation: buildMutation(schema, queryName, fields, productId),
  };
};
