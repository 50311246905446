import React from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import { curveMonotoneX } from "d3-shape";

import {
  FlexibleWidthXYPlot,
  LineSeries,
  AreaSeries,
  MarkSeries,
} from "react-vis";

import moment from "moment";
import { useTheme } from "emotion-theming";
import { Box, Flex } from "rebass";

const _ = require("lodash");

export default function BrandLine(props) {
  const theme = useTheme();

  const days = [...Array(20).keys()];
  const graphDataTemplate = days.reverse().map((i) => {
    return {
      date: moment().add(-i, "day"),
      x: days.length - i,
      y: _.random(60, 60 - i),
    };
  });

  const markData = graphDataTemplate
    .filter((d, i) => i > 0 && i % 3 === 0)
    .map((d) => {
      return { ...d, size: _.random(1, 30) };
    });

  return (
    <Flex sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
      <Box width="100%">
        <FlexibleWidthXYPlot
          yDomain={[40, 60]}
          height={175}
          margin={{ left: 0, right: 0, top: 30, bottom: 0 }}
          animation={{
            damping: 12,
            stiffness: 200,
            precision: 0.1,
          }}
        >
          <LineSeries
            key="test1"
            className="first-series"
            color={theme.colors.graph[0]}
            strokeWidth={2}
            curve={curveMonotoneX}
            data={graphDataTemplate}
            animation="noWobble"
          />
          <AreaSeries
            key="test2"
            animation="noWobble"
            curve={curveMonotoneX}
            color={theme.colors.brandHighlight}
            getNull={(d) => d.y !== null}
            data={graphDataTemplate}
          />
          <MarkSeries
            key="test3"
            animation="wobbly"
            sizeRange={[16, 18]}
            color={theme.colors.brandHighlight}
            data={markData}
          />
          <MarkSeries
            key="test5"
            animation="wobbly"
            sizeRange={[8, 10]}
            color={theme.colors.brandLight}
            data={markData}
          />
          <MarkSeries
            key="test4"
            animation="wobbly"
            sizeRange={[4, 6, 8]}
            color={theme.colors.brand}
            data={markData}
          />
        </FlexibleWidthXYPlot>
      </Box>
    </Flex>
  );
}
