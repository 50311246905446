import React from "react";
import { Box, Flex, Image } from "rebass";
import Settings from "../ProductSettings";
import { getProduct } from "hooks/getProduct";
import Loader from "Components/Common/Loader";
import ProductForm from "./ProductForm";

function Product(props) {
  const { data, loading, error, refetch } = getProduct();
  return (
    <Settings>
      <Flex width="100%" m={4}>
        {loading || !!error ? (
          <Loader error={!!error} />
        ) : (
          <ProductForm
            product={data?.currentProduct}
            onSave={() => refetch()}
          />
        )}
      </Flex>
    </Settings>
  );
}

export default Product;
