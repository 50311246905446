import React from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Box, Flex, Image, Text } from "rebass";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { PlusIcon } from "../../../Common/Icons";
import EventGroupForm from "./EventGroupForm";
import EventGroupList from "./EventGroupList";
import Settings from "../ProductSettings";

function EventGroup(props) {
  const { id } = useParams();
  return (
    <Settings>
      <Flex width="100%">
        <Flex
          p={2}
          bg="gray.100"
          sx={{
            height: "100%",
            maxWidth: "300px",
          }}
          flex={1}
        >
          <EventGroupList />
        </Flex>
        <Flex flex={1} p={4} bg="white">
          {id && <EventGroupForm {...props} />}
          {!id && <EmptyState />}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(EventGroup);

const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex flex={1} justifyContent="center" alignItems="center">
      <Flex flex={1} justifyContent="center" alignItems="center">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ height: "200px", objectFit: "contain" }}
        />
      </Flex>
      <Flex flex={1} alignItems="flex-start" flexDirection="column">
        <Text variant="h3">Events</Text>
        <Text my={3}>
          Set up all your Events that are expected from your product. These will
          also be automatically created if they do not exist.
        </Text>
        <Flex my={3} justifyContent="space-between">
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/events/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Event Type
          </PrimaryButton>
          <Box mx={3} />
        </Flex>
      </Flex>
    </Flex>
  );
};
