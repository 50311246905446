import React from "react";
import { Box, Flex, Link, Text } from "rebass";

export const TermsOfUsePrivacy = (props) => {
  return (
    <Flex my={3} variant="muted" flexDirection={"row"}>
      <Flex>
        <Text mx={1}>Userlot{"'"}s</Text>
        <Link
          target="_blank"
          href="https://help.userlot.com/article/39-userlot-terms-of-use"
        >
          Terms of Service
        </Link>
        <Text mx={1}>and</Text>
        <Link
          target="_blank"
          href="https://help.userlot.com/article/40-userlot-privacy-policy"
        >
          Privacy Policy
        </Link>
      </Flex>
    </Flex>
  );
};

export default TermsOfUsePrivacy;
