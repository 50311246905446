import React from "react";
import { Flex, Text, Box } from "rebass";
import { useTheme } from "emotion-theming";

const refactorToIndex = (value) => {
  if (value <= 25) return 1;
  if (value > 25 && value <= 50) return 2;
  if (value > 50 && value <= 75) return 3;
  if (value > 75) return 4;
};
function ConversionScoreIcon({
  conversionQualified,
  conversionFitScore,
  conversionAdoptionScore,
  status,
}) {
  const theme = useTheme();
  let color = "";

  let fitScore = refactorToIndex(conversionFitScore);
  let adoptionScore = refactorToIndex(conversionAdoptionScore);
  let calcScore = fitScore * adoptionScore;
  if (calcScore >= 12) {
    color = theme.colors.green;
  } else if (fitScore == 3 || adoptionScore == 3) {
    color = theme.colors.green + "2A";
  } else if (fitScore == 4 || adoptionScore == 4) {
    color = theme.colors.green + "80";
  } else if (
    (fitScore == 2 && adoptionScore <= 2) ||
    (fitScore <= 2 && adoptionScore == 2)
  ) {
    color = theme.colors.yellow;
  } else if (calcScore <= 1) color = theme.colors.red + "1A";

  return status?.name == "Trial" ? (
    conversionQualified ? (
      <Flex
        flexShrink={1}
        alignItems="center"
        justfiyContent="center"
        bg="green"
        color="white"
        sx={{ height: 20, borderRadius: 99 }}
      >
        <Text fontSize="xxs" mx={1}>
          Qualified
        </Text>
      </Flex>
    ) : color ? (
      <Flex
        alignItems="center"
        justfiyContent="center"
        bg={color}
        sx={{ width: 20, height: 20, borderRadius: 99 }}
      ></Flex>
    ) : null
  ) : null;
}

export default ConversionScoreIcon;
