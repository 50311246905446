import React from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Flex, Button, Text, Box, Link } from "rebass";
import { getEventGroups } from "hooks/getEventGroups";
import Loader from "Components/Common/Loader";
import { useEffect } from "react";
import { HelpIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";

function EventGroups(props) {
  const { id } = useParams();
  const { loading, data } = getEventGroups();
  const history = useHistory();

  if (loading) return <Loader />;
  return (
    <>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={1} justifyContent="space-between">
            <Text variant="h2">Events</Text>
            <PrimaryButton
              onClick={() => {
                history.push("/settings/product/events/new");
              }}
            >
              <PlusIcon />
            </PrimaryButton>
          </Flex>

          {(!data || data.eventGroups.length === 0) && (
            <Flex p={2} my={2} flexDirection="column">
              <Text variant="muted">
                Create events to log from your product and lets start collecting
                your data.
              </Text>
              <Link href="#" my={2}>
                <Flex alignItems="center">
                  <HelpIcon /> <Text mx={2}>Help</Text>
                </Flex>
              </Link>
            </Flex>
          )}
          {data &&
            data.eventGroups?.map((eventGroup) => (
              <Flex
                key={eventGroup.id}
                variant="box"
                bg={eventGroup.id === id ? "brandHighlight" : "white"}
                sx={{ cursor: "pointer" }}
                p={2}
                my={2}
                justifyContent="space-between"
                onClick={() =>
                  history.push(`/settings/product/events/${eventGroup.id}`)
                }
              >
                <Text variant="h4" color="text">
                  {eventGroup.name}
                </Text>
                {eventGroup.eventCategory?.name !== eventGroup.name && (
                  <Box>
                    <Flex
                      flexShrink={1}
                      variant="badge"
                      bg="gray.200"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text fontSize="xs">
                        {eventGroup.eventCategory?.name}
                      </Text>
                    </Flex>
                  </Box>
                )}
              </Flex>
            ))}
        </Flex>
      </Flex>
    </>
  );
}

export default withRouter(EventGroups);
