import React from "react";
import { useQuery } from "@apollo/react-hooks";
import IntegrationAuthentications from "../graphql/queries/IntegrationAuthentications.gql";

export function getIntegrationAuthentications(definitionId) {
  const query = useQuery(IntegrationAuthentications, {
    variables: { definitionId: definitionId }
  });

  return {
    ...query,
  };
}
