import React, { useState } from "react";
import { Button, Flex, Box, Text, Link, Image } from "rebass";
import moment from "moment";
import { useHistory } from "react-router-dom";

function NotificationItem({ note }) {
  return (
    <Flex
      flexDirection="column"
      sx={{
        paddingTop: 10,
        paddingBottom: 10,
        borderBottomColor: "gray.200",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        cursor: "pointer",
      }}
    >
      <Flex>
        <Text fontWeight="bold" fontSize="xs">
          {`${note.title}`}
        </Text>
        {note.companyId && (
          <Text fontWeight="bold" fontSize="xs" mx={1} color="brandLight">
            ({note.companyName})
          </Text>
        )}
      </Flex>
      <Text
        my={1}
        variant="body"
        fontSize="xs"
        sx={{ whiteSpace: "break-spaces" }}
        dangerouslySetInnerHTML={{ __html: note.description }}
      />
      <Text variant="muted" fontSize="xs">
        {moment(note.createdAt).fromNow()}
      </Text>
    </Flex>
  );
}

export default NotificationItem;
