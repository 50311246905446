import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import CompanyQuery from "../graphql/queries/Company.gql";

export function useUrlParams() {
  const urlSearchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );

  const getUrlParameter = (parameter) => urlSearchParams.get(parameter);

  const setUrlParameter = (parameter, value) => {};

  return [getUrlParameter, setUrlParameter];
}
