import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import Page from "../Page";
import { Flex } from "rebass";
import NotificationList from "./Notifications";

function Notifications(props) {
  return (
    <Page title="Notifications">
      <Flex
        p={3}
        style={{
          color: "text",
        }}
      >
        <NotificationList />
      </Flex>
    </Page>
  );
}

export default withRouter(Notifications);
