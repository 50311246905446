import React, { useState } from "react";
import { Box, Button, Flex, Link, Text } from "rebass";
import { CloseIcon, IntegrationIcon, TickIcon } from "../../../Common/Icons";
import { useMutation } from "@apollo/react-hooks";
import IntegrationAuthForm from "./IntegrationAuthForm";
import { getIntegrationAuthentications } from "../../../../hooks/getIntegrationAuthentications";
import IntegrationAuthenticationDelete from "../../../../graphql/mutations/IntegrationAuthenticationDelete.gql";
import GhostButton from "../../../Common/Buttons/GhostButton";
import { useEffect } from "react";

function SelectIntegrationStep(props) {
  const [showNew, setShowNew] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const { data, refetch } = getIntegrationAuthentications(props.app?.id);

  const [deleteIntegrationAuthentication, {}] = useMutation(
    IntegrationAuthenticationDelete
  );
  const handleDelete = (id) => {
    deleteIntegrationAuthentication({
      variables: {
        id: id,
      },
    }).then(
      (response) => {
        if (response.data?.integrationAuthenticationDelete.errors) {
          addToast(
            <Flex>
              {response.data?.integrationAuthenticationDelete.errors[0]}
            </Flex>,
            ErrorParams
          );
          return null;
        }
        refetch({ id: props.app?.id });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    setShowNew(data?.integrationAuthentications.length == 0);
  }, [data]);
  return (
    <Flex flexDirection={"column"} minWidth={600} mx={3} mt={3}>
      {props.app && (
        <Flex flexDirection={"column"} mb={3}>
          <Flex>
            <IntegrationIcon logoUrl={props.app.icon} />
            <Box mx={2}>
              <Text>{props.app.name}</Text>
              <Flex alignItems={"center"}>
                <Link fontSize={"xxs"} href={props.app.website}>
                  {props.app.website}
                </Link>
                <Text mx={2} fontSize={"xxs"}>
                  |
                </Text>
                <Link fontSize={"xxs"} href={props.app.documentation}>
                  {props.app.documentation}
                </Link>
              </Flex>
              <Text fontSize={"xs"} my={2}>
                {props.app.description}
              </Text>
              <Link fontSize={"xxs"} href={props.app.authDocumentation}>
                {props.app.authDocumentation}
              </Link>
            </Box>
          </Flex>
        </Flex>
      )}
      <Flex mx={3}>
        {showNew && (
          <Flex flexDirection={"column"} flex={1} width="100%">
            <IntegrationAuthForm
              app={props.app}
              selectedConnection={selected}
              onUpdate={(d) => {
                props.onSelect(d);
                props.nextStep();
              }}
            />
            <Box my={2} />
            <GhostButton
              onClick={() => {
                setShowNew(false);
                refetch();
              }}
            >
              Cancel
            </GhostButton>
          </Flex>
        )}
        {!showNew && (
          <Flex flexWrap={"wrap"}>
            {data?.integrationAuthentications
              .sort((a, b) => {
                if (a.connectionCount != b.connectionCount) {
                  return b.connectionCount - a.connectionCount;
                } else {
                  return a.verified == b.verified ? 0 : a.verified ? -1 : 1;
                }
              })
              .map((d) => {
                return (
                  <Flex
                    onClick={() => {
                      if (d.verified) {
                        props.onSelect(d);
                        props.nextStep();
                      } else {
                        setSelected(d);
                        setShowNew(true);
                      }
                    }}
                    key={d.id}
                    variant="box"
                    p={3}
                    m={2}
                    width={1 / 1}
                    flexDirection="column"
                    bg={!d.verified ? "gray.300" : ""}
                    sx={{
                      cursor: d.verified && "pointer",
                      "&:hover": {
                        borderColor: d.verified && "brandLight",
                        backgroundColor: d.verified && "brandHighlight",
                      },
                    }}
                  >
                    <Flex>
                      <Text fontWeight={"bold"}>{d.name}</Text>
                      <Flex
                        mx={3}
                        variant={"badge"}
                        bg={d.verified ? "greenLight" : "redLight"}
                        alignItems={"center"}
                      >
                        {d.verified ? (
                          <TickIcon color="green" />
                        ) : (
                          <CloseIcon color="red" />
                        )}

                        <Text>{d.verified ? "" : "not "}verified</Text>
                      </Flex>
                      {d.connectionCount == 0 && (
                        <Flex flex={1} justifyContent={"flex-end"}>
                          <GhostButton onClick={() => handleDelete(d.id)}>
                            <CloseIcon />
                          </GhostButton>
                        </Flex>
                      )}
                    </Flex>
                    <Text variant="muted">{d.username || d.apikey}</Text>
                    <Box>
                      {d.integrations.map((i) => (
                        <Box
                          key={i.id}
                          m={2}
                          display={"inline-flex"}
                          variant={"badge"}
                          alignItems={"center"}
                          bg={i.active ? "greenLight" : "gray.200"}
                        >
                          <Text>{d.name}</Text>
                        </Box>
                      ))}
                    </Box>
                  </Flex>
                );
              })}
            <Flex justifyContent={"space-between"}>
              <GhostButton onClick={() => setShowNew(true)}>
                Create new connection
              </GhostButton>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default SelectIntegrationStep;
