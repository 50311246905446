import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text, Box } from "rebass";
import AddScoreMutation from "../../../graphql/mutations/AddScore.gql";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import RadioGroup from "Components/Common/RadioGroup";
import { SaveIcon } from "Components/Common/Icons";
import {
  RiEmotionHappyLine,
  RiEmotionLaughLine,
  RiEmotionNormalLine,
  RiEmotionSadLine,
  RiEmotionUnhappyLine,
} from "react-icons/ri";
import { Formik } from "formik";
import * as Yup from "yup";
import GhostButton from "Components/Common/Buttons/GhostButton";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
const _ = require("lodash");
function AddCompanySatisfaction(props) {
  const { addToast } = useToasts();
  const [save, {}] = useMutation(AddScoreMutation);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        comment: "",
        scoreType: "nps",
        ids: props.ids,
        parentType: props.parentType,
      }}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        scoreType: Yup.string().required("Required"),
        value: Yup.string().required("Required"),
        comment: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        save({
          variables: {
            ids: props.ids,
            parentType: props.parentType,
            value: parseInt(values.value),
            comment: values.comment,
            scoreType: values.scoreType,
          },
        }).then(
          (response) => {
            if (!response.data?.addScore?.errors) {
              props.onSave();
            } else {
              addToast(<Flex>{response.data?.addScore?.errors[0]}</Flex>, {
                appearance: "error",
                autoDismiss: false,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;

        return (
          <Flex flex={1} flexDirection="column">
            <Flex mt={2} flexDirection={"column"}>
              <Box width={250} mb={3}>
                <RadioGroup
                  name="customerType"
                  selectedOption={values.scoreType}
                  options={[
                    { value: "nps", label: <Text fontSize="xs">NPS</Text> },
                    { value: "csat", label: <Text fontSize="xs">CSAT</Text> },
                    { value: "ces", label: <Text fontSize="xs">CES</Text> },
                  ]}
                  onChange={(name, target) => {
                    setFieldValue("scoreType", target.value);
                  }}
                />
              </Box>
              <Flex flexDirection="column" mb={3}>
                <RangeSelector
                  scoreType={values.scoreType}
                  onChange={(values) => setFieldValue("value", values)}
                  value={values.value}
                />
                {errors && errors.value && (
                  <Text variant="label" color="red">
                    {errors.value}
                  </Text>
                )}
              </Flex>
            </Flex>
            <CustomFormGroup
              type="textarea"
              placeholder={"Comment"}
              label={"Comment"}
              name={"comment"}
              value={values.comment}
              onChange={setFieldValue}
              helperText={errors && errors.comment && errors.comment}
            />
            <Box my={2} />
            <Flex alignItems="center" justifyContent="flex-end">
              {props.onClose && (
                <GhostButton mx={2} variant="ghost" onClick={props.onClose}>
                  Close
                </GhostButton>
              )}
              <Box mx={2} />
              <PrimaryButton
                leftIcon={<SaveIcon />}
                onClick={() => handleSubmit()}
              >
                Save
              </PrimaryButton>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default AddCompanySatisfaction;
const RangeSelector = (props) => {
  let colors = [
    "#d62839",
    "#d62839",
    "#d62839",
    "#d62839",
    "#d62839",
    "#d62839",
    "#d62839",
    "#FFA62B",
    "#FFA62B",
    "#3EC300",
    "#3EC300",
  ];
  let faces = [
    <RiEmotionSadLine size={25} />,
    <RiEmotionUnhappyLine size={25} />,
    <RiEmotionNormalLine size={25} />,
    <RiEmotionHappyLine size={25} />,
    <RiEmotionLaughLine size={25} />,
  ];
  if (props.scoreType === "ces") {
    colors = colors.reverse();
    colors.pop();
  }
  return (
    <Flex>
      {props.scoreType === "nps" &&
        colors.map((c, i) => {
          let selected = props.value === i;
          return (
            <Button
              key={i}
              variant="ghost"
              color={selected ? "white" : c}
              mx={1}
              bg={selected ? c : "gray.200"}
              fontWeight="bold"
              onClick={() => props.onChange(i)}
            >
              <Text fontSize={"xs"}>{i}</Text>
            </Button>
          );
        })}
      {props.scoreType === "ces" &&
        colors.map((c, i) => {
          let value = i + 1;
          let selected = props.value === value;
          return (
            <Button
              key={value}
              variant="ghost"
              color={selected ? "white" : c}
              mx={1}
              bg={selected ? c : "gray.200"}
              fontWeight="bold"
              onClick={() => props.onChange(value)}
            >
              <Text fontSize={"xs"}>{value}</Text>
            </Button>
          );
        })}
      {props.scoreType === "csat" &&
        faces.map((c, i) => {
          let value = (i + 1) * 2;
          let selected = props.value === value;
          return (
            <Button
              key={c}
              variant="ghost"
              color={selected ? "green" : "text"}
              bg={"gray.100"}
              onClick={() => props.onChange(value)}
            >
              <Text fontSize={"xs"}>{c}</Text>
            </Button>
          );
        })}
    </Flex>
  );
};
