import React from "react";
import { Prompt } from "react-router-dom";
import { Flex, Text, Box, Image } from "rebass";
import * as Yup from "yup";
import { deepOmit } from "consts";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useState } from "react";
import { ImageIcon } from "Components/Common/Icons";
import Dropzone from "react-dropzone";
import { useTheme } from "emotion-theming";
import FileUploadTemplate from "Components/Data/FileUploadTemplate";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import PasswordForm from "./PasswordForm";
import UserMutation from "../../../../graphql/mutations/User.gql";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { SuccessParams } from "Components/Common/Notifications/Success";
import LocalisationForm from "../Localisation";

function UserForm(props) {
  const { addToast } = useToasts();
  const theme = useTheme();
  const [file, setFile] = useState();
  const [saveUser, {}] = useMutation(UserMutation);
  const [open, setOpen] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={props.data || {}}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().email().required("Required"),
      })}
      onSubmit={(values, actions) => {
        saveUser({
          variables: {
            id: values.id ? values.id : null,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            settings: deepOmit(values.userSetting, "__typename"),
            file: file && file,
            timezone: values.timezone,
            locale: values.locale,
          },
          context: {
            hasUpload: !!file,
          },
        }).then(
          (response) => {
            if (response.data?.userMutation?.errors) {
              console.log(response.data.userMutation.errors);
            } else {
              props.onSave();
              addToast(<Flex>Saved!</Flex>, SuccessParams);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }}
    >
      {(formProps) => {
        const { values, setFieldValue, handleBlur, handleSubmit, dirty } =
          formProps;
        return (
          <>
            <Prompt
              when={dirty}
              message="You have unsaved changes. Are you sure you want to leave?"
            />
            <Flex w="100%" flexDirection="column" m={2}>
              <Flex flex={1} flexDirection="column" m={2}>
                <Flex justifyContent="space-between">
                  <Text variant="h2">Your information</Text>
                  <Flex>
                    <SecondaryButton onClick={() => setOpen(true)}>
                      Change your password
                    </SecondaryButton>
                    <Box mx={2} />
                    <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                  </Flex>
                </Flex>
                <Flex>
                  <Box p={3}>
                    <Dropzone
                      onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Flex
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          p={4}
                          sx={{
                            borderStyle: "dashed",
                            borderColor: theme.colors.accent,
                            borderWidth: 1,
                            borderRadius: "medium",
                          }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          {file ? (
                            <>
                              <Box color="secondaryLight">
                                <ImageIcon size={40} />
                                <Text>{file.name}</Text>
                              </Box>
                            </>
                          ) : values.avatarUrl ? (
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              flexDirection="column"
                            >
                              <Image
                                src={values.avatarUrl}
                                width={60}
                                height={60}
                              />
                              <PrimaryButton py={1} mt={2}>
                                Change
                              </PrimaryButton>
                            </Flex>
                          ) : (
                            <FileUploadTemplate logo />
                          )}
                        </Flex>
                      )}
                    </Dropzone>
                  </Box>
                  <Flex flex={1} flexDirection="column" mx={2}>
                    <Flex>
                      <CustomFormGroup
                        placeholder="First Name"
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={handleBlur}
                      />
                      <Box mx={2} />
                      <CustomFormGroup
                        placeholder="Last Name"
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Flex>
                    <CustomFormGroup
                      placeholder="Email"
                      label="Email"
                      name="email"
                      value={values.email}
                      disabled={true}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                    />
                    {values.unconfirmedEmail && (
                      <Text variant="muted" color="red">
                        You will have recieved an email to{" "}
                        {values.unconfirmedEmail}. You cannot log in as this
                        email until it is confirmed.
                      </Text>
                    )}
                    <LocalisationForm {...formProps} />
                    <Box p={3} mt={4} bg="gray.100">
                      <Text variant="h3">
                        Opt into these email notifications
                      </Text>
                      <Box px={3}>
                        <Flex alignItems={"center"}>
                          <Box mr={3}>
                            <CustomFormGroup
                              placeholder="Enabled"
                              label=""
                              noMargin
                              type="switch"
                              name="userSetting.config.dailyCommandCenter.disabled"
                              value={
                                !values.userSetting?.config?.dailyCommandCenter
                                  ?.disabled
                              }
                              onChange={(name, value) => {
                                setFieldValue(name, !value);
                              }}
                              onBlur={handleBlur}
                            />
                          </Box>

                          <Text variant="h5" mb={2} mt={3}>
                            Command Center Digest (Daily)
                          </Text>
                        </Flex>
                      </Box>
                      <Box px={3}>
                        <Flex alignItems={"center"}>
                          <Box mr={3}>
                            <CustomFormGroup
                              placeholder="Enabled"
                              noMargin
                              label=""
                              type="switch"
                              name="userSetting.config.weeklyTaskSummary.disabled"
                              value={
                                !values.userSetting?.config?.weeklyTaskSummary
                                  ?.disabled
                              }
                              onChange={(name, value) => {
                                setFieldValue(name, !value);
                              }}
                              onBlur={handleBlur}
                            />
                          </Box>

                          <Text variant="h5" mb={2} mt={3}>
                            Weekly Activity Summary
                          </Text>
                        </Flex>
                      </Box>
                      <Box px={3}>
                        <Flex alignItems={"center"}>
                          <Box mr={3}>
                            <CustomFormGroup
                              placeholder="Enabled"
                              noMargin
                              label=""
                              type="switch"
                              name="userSetting.config.taskAssignedEmail.disabled"
                              value={
                                !values.userSetting?.config?.taskAssignedEmail
                                  ?.disabled
                              }
                              onChange={(name, value) => {
                                setFieldValue(name, !value);
                              }}
                              onBlur={handleBlur}
                            />
                          </Box>

                          <Text variant="h5" mb={2} mt={3}>
                            An activity assigned to me
                          </Text>
                        </Flex>
                      </Box>
                      <Box px={3}>
                        <Flex alignItems={"center"}>
                          <Box mr={3}>
                            <CustomFormGroup
                              placeholder="Enabled"
                              noMargin
                              label=""
                              type="switch"
                              name="userSetting.config.accountAssignedEmail.disabled"
                              value={
                                !values.userSetting?.config
                                  ?.accountAssignedEmail?.disabled
                              }
                              onChange={(name, value) => {
                                setFieldValue(name, !value);
                              }}
                              onBlur={handleBlur}
                            />
                          </Box>

                          <Text variant="h5" mb={2} mt={3}>
                            An account owner is changed to me
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <PasswordForm
              data={values}
              onSave={() => {
                refetch();
              }}
              open={open}
              onDismiss={() => setOpen(false)}
            />
          </>
        );
      }}
    </Formik>
  );
}
UserForm.propTypes = {
  data: PropTypes.array,
  onSave: PropTypes.func,
};
export default UserForm;
