import React from "react";
import { Flex } from "rebass";
import LifeCycleForm from "./LifeCycleForm";
import Settings from "..";

function LifeCycles({ statusId }) {
  return (
    <Settings>
      <Flex width="100%">
        <Flex flex={1} p={4} bg="white">
          <LifeCycleForm statusId />
        </Flex>
      </Flex>
    </Settings>
  );
}

export default LifeCycles;
