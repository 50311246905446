import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Text, Flex } from "rebass";
import { useTheme } from "emotion-theming";

const Link = (props) => {
  const theme = useTheme();
  return (
    <Flex alignItems="center" variant="link" my={1}>
      <NavLink
        to={props.to}
        style={{
          color: theme.colors.secondary,
          textDecoration: "none",
          width: "100%",
          textAlign: "right",
        }}
        activeStyle={{
          color: theme.colors.secondary,
          borderRightColor: theme.colors.brandLight,
          borderRightWidth: 4,
          borderRightStyle: "solid",
          fontWeight: "bold",
        }}
      >
        <Flex p={2} pr={3} justifyContent="flex-end">
          {props.children}
        </Flex>
      </NavLink>
    </Flex>
  );
};

Link.propTypes = {
  children: PropTypes.node,
};

export default Link;
