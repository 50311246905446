import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import UsersQuery from "../graphql/queries/Users.gql";

export function getUsers(id, parentType) {
  const usersQuery = useQuery(UsersQuery, {
    variables: { id: id, parentType: parentType },
  });

  return {
    ...usersQuery,
  };
}
