import React, { useState } from "react";
import { Flex, Image, Text } from "rebass";

function ExternalId({ value }) {
  return (
    <Flex textAlign="left">
      <Text variant="muted" fontSize={"xxs"}>
        ID:
      </Text>
      <Text
        variant="muted"
        fontSize={"xxs"}
        mx={1}
        sx={{ wordWrap: "break-word", maxWidth: 260 }}
      >
        {value}
      </Text>
    </Flex>
  );
}

export default ExternalId;
