import React, { useState } from "react";
import { Flex, Text, Box } from "rebass";
import moment from "moment";
import WidgetWrapper from "./WidgetWrapper";
import Loader from "../../Common/Loader";

const _ = require("lodash");
const numeral = require("numeral");

function NRRSummary(props) {
  const [show, setShow] = useState(false);
  const mrrData = props.mrrData;

  const totalPrevValue = _.sumBy(
    mrrData.filter(
      (d) =>
        (d.type == "existing" || d.type == "new") &&
        moment(d.date).isSame(moment().add(-1, "months"), "month")
    ),
    "value"
  );

  const totalNewValue = _.sumBy(
    mrrData.filter(
      (d) => d.type === "new" && moment(d.date).isSame(moment(), "month")
    ),
    "value"
  );

  const totalExpansionsValue = _.sumBy(
    mrrData.filter((d) => d.type === "expansions"),
    "value"
  );
  const totalContractionsValue = _.sumBy(
    mrrData.filter((d) => d.type === "contractions"),
    "value"
  );

  const totalChurnedValue = _.sumBy(
    mrrData.filter(
      (d) => d.type === "churned" && moment(d.date).isSame(moment(), "month")
    ),
    "value"
  );

  const totalLatestValue =
    totalPrevValue > 0
      ? (totalPrevValue +
          totalNewValue +
          totalExpansionsValue -
          totalChurnedValue -
          totalContractionsValue) /
        totalPrevValue
      : 0;

  return (
    <WidgetWrapper title="Net Revenue Retention">
      <Flex width="100%" alignItems="center">
        {props.loading ? (
          <Loader />
        ) : (
          <Flex
            mt={3}
            flex={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={() => setShow(!show)}
          >
            {show ? (
              <CalculationDescription
                mrr={totalPrevValue}
                expansions={totalExpansionsValue}
                contractions={totalContractionsValue}
                churns={totalChurnedValue}
                total={totalPrevValue}
              />
            ) : (
              <Flex>
                <Text variant="h1" color="green">
                  {numeral(totalLatestValue * 100).format("0a")}
                </Text>
                <Text variant="h1" color="green" sx={{ fontSize: "0.83em" }}>
                  %
                </Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </WidgetWrapper>
  );
}

export default NRRSummary;

const CalculationDescription = ({
  total,
  mrr,
  expansions,
  churns,
  contractions,
}) => {
  return (
    <Flex alignItems="center">
      <Flex alignItems="center" flexDirection="column">
        <Flex
          alignItems="center"
          sx={{
            borderBottomStyle: "solid",
            borderBottomWidth: 2,
            borderBottomColor: "body",
          }}
          flexWrap="wrap"
        >
          <Text mx={1}>(</Text>
          <Box>
            <Text fontSize="xxs" fontWeight="bold" textAlign="center">
              ${numeral(mrr || 0).format("0,0")}
            </Text>
            <Text fontSize="xxs">MRR </Text>
          </Box>
          <Text mx={1}>+</Text>
          <Box>
            <Text fontSize="xxs" fontWeight="bold" textAlign="center">
              ${numeral(expansions || 0).format("0,0")}
            </Text>
            <Text fontSize="xxs">Expansion</Text>
          </Box>
          <Text mx={1}>-</Text>
          <Box>
            <Text fontSize="xxs" fontWeight="bold" textAlign="center">
              ${numeral(churns || 0).format("0,0")}
            </Text>
            <Text fontSize="xxs">Churn</Text>
          </Box>
          <Text mx={1}>-</Text>
          <Box>
            <Text fontSize="xxs" fontWeight="bold" textAlign="center">
              ${numeral(contractions || 0).format("0,0")}
            </Text>
            <Text fontSize="xxs">Contraction</Text>
          </Box>
          <Text mx={1}>)</Text>
        </Flex>
        <Box>
          <Text fontSize="xxs" fontWeight="bold" textAlign="center">
            ${numeral(mrr || 0).format("0,0")}
          </Text>
          <Text fontSize="xxs">
            Monthly Recurring Revenue (MRR) at Start of Month
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
