import React, { useEffect, useState } from "react";
import { Flex, Image, Text, Button } from "rebass";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import CommandCenter from "../../Dashboard/Home/CommandCenter";

function TaskReport(props) {
  return (
    <CommandCenter>
      <Flex m={4} flexDirection="column">
        <Flex justifyContent="space-between">
          <Text variant="h2">Command Center Reports</Text>
          <PrimaryButton>Create</PrimaryButton>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex p={2} flexDirection="column" justifyContent="center">
            <Image
              src={require("assets/images/Graphs.png")}
              sx={{ height: "100px", objectFit: "contain" }}
            />
            <Text variant="muted" my={3}>
              No reports have been created yet
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CommandCenter>
  );
}

export default TaskReport;
