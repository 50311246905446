import React, { useRef, useState } from "react";
import { Box, Button, Flex, Text } from "rebass";
import { useTheme } from "emotion-theming";
import { useMutation } from "@apollo/react-hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SwapSpinner } from "react-spinners-kit";
import { CopyIcon, TickIcon, WarningSignIcon } from "Components/Common/Icons";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import EmailDomainDeleteMutation from "../../../../graphql/mutations/EmailDomainDelete.gql";
import EmailDomainVerifyMutation from "../../../../graphql/mutations/EmailDomainVerify.gql";
import CopyableText from "Components/Common/CopyableText";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";

const EmailDomain = (props) => {
  const { domain } = props;
  const theme = useTheme();
  const [destroy, {}] = useMutation(EmailDomainDeleteMutation);
  const [verify, { loading }] = useMutation(EmailDomainVerifyMutation);

  const verifiedIcon = domain.verified ? (
    <Box>
      <Flex alignItems="center">
        <TickIcon color={theme.colors.green} />
        <Text mx={2}>Verified</Text>
      </Flex>
    </Box>
  ) : (
    <Box>
      <Flex alignItems="center">
        <WarningSignIcon color={theme.colors.warning} />
        <Text mx={2}>Pending Verification</Text>
      </Flex>
    </Box>
  );

  const dnsEntryVerifiedState = (state) => {
    return state ? (
      <Box>
        <Flex alignItems="center">
          <TickIcon color={theme.colors.green} />
          <Text mx={2}>Found</Text>
        </Flex>
      </Box>
    ) : (
      <Box>
        <Flex alignItems="center">
          <WarningSignIcon color={theme.colors.warning} />
          <Text mx={2}>Not Found</Text>
        </Flex>
      </Box>
    );
  };

  const verifyDomain = () => {
    verify({
      variables: {
        id: domain.id,
      },
    });
  };

  const dnsRecords = domain.verified ? (
    []
  ) : (
    <>
      {domain.dnsRecords.map((record, index) => (
        <Flex
          my={3}
          key={`${domain.id}-${index}`}
          justifyContent="space-between"
        >
          <Flex flex={1} alignItems="center">
            <Text as="span">
              <strong>Host</strong>
            </Text>
            <CopyableText mx={1} mr={3}>
              {record.host}
            </CopyableText>
            <Text as="span">
              <strong>Value</strong>
            </Text>
            <CopyableText mx={1} mr={3}>
              {record.value}
            </CopyableText>
          </Flex>
          <Box>
            <Text
              fontSize="md"
              alignSelf="center"
              textAlign="right"
              flexGrow={1}
            >
              {dnsEntryVerifiedState(record.valid)}
            </Text>
          </Box>
        </Flex>
      ))}
      <Flex justifyContent="flex-end" my={3}>
        <DeleteButton
          callback={destroy}
          id={domain?.id}
          refetch={props.refetch}
        />
        <PrimaryButton onClick={verifyDomain}>
          {loading ? (
            <Flex alignItems="center" justifyContent="center">
              <Text mx={2}>Verifying</Text>
              <SwapSpinner color={theme.colors.white} loading size={12} />
            </Flex>
          ) : (
            "Verify"
          )}
        </PrimaryButton>
      </Flex>
    </>
  );

  return (
    <Box my={1} p={3} width={1} variant="box">
      <Flex alignItems="center">
        <Text fontSize="md" flexGrow={1} alignSelf="center">
          <Text variant="h3">Domain</Text>
          <Text variant="domain">{domain.domain}</Text>
        </Text>
        <Flex flex={1} justifyContent="flex-end" alignItems="flex-end">
          <Text fontSize="md" alignSelf="center">
            {verifiedIcon}
          </Text>
        </Flex>
      </Flex>
      {dnsRecords}
    </Box>
  );
};

export default EmailDomain;
