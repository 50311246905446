import React, { useState, useEffect } from "react";
import { useTheme } from "emotion-theming";

export function useMentionTheme() {
  const theme = useTheme();
  return {
    defaultStyle: {
      control: {
        display: "inline-block",
        fontSize: 14,
        fontWeight: "normal",
      },

      "&multiLine": {
        control: {
          fontFamily: "monospace",
          minHeight: 63,
        },
        highlighter: {
          padding: 9,
          border: "1px solid transparent",
        },
        input: {
          padding: 9,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.colors.gray[500],
        },
      },

      "&singleLine": {
        display: "block",
        highlighter: {
          padding: 1,
          border: "1px inset transparent",
        },
        input: {
          padding: 8,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.colors.gray[500],
          borderRadius: theme.radii["medium"],
        },
      },

      suggestions: {
        list: {
          backgroundColor: "white",
          border: "1px solid rgba(0,0,0,0.15)",
          fontSize: 14,
        },
        item: {
          padding: "5px 15px",
          borderBottom: "1px solid rgba(0,0,0,0.15)",
          "&focused": {
            backgroundColor: theme.colors.secondaryLight,
          },
        },
      },
    },
    mentionStyle: {
      marginTop: 8,
      marginLeft: 6,
      display: "inline-block",
      backgroundColor: theme.colors.gray[200],
    },
  };
}
