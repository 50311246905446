import React from "react";
import { Flex, Box, Link, Button, Text, Image } from "rebass";
import { useQuery } from "@apollo/react-hooks";
import Table from "Components/Common/Table";
import { InfoIcon, RefreshIcon, WarningIcon } from "Components/Common/Icons";
import ReactJson from "react-json-view";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import Settings from "./ProductSettings";
import DevLogsQuery from "../../../graphql/queries/DevLogs.gql";
import { useHistory } from "react-router";
import { PlusIcon } from "../../Common/Icons";

const moment = require("moment");

const columns = [
  {
    name: "",
    width: "50px",
    selector: (row) => row.messageType,
    sortable: true,
    cell: (row) => {
      return row.messageType === "error" ? (
        <Text color="red" data-tag="allowRowEvents">
          <WarningIcon />
        </Text>
      ) : (
        <Text color="brandLight" data-tag="allowRowEvents">
          <InfoIcon />
        </Text>
      );
    },
  },
  {
    name: "Date",
    selector: (row) => row.createdAt,
    sortable: true,
    width: "150px",
    cell: (row) => {
      return (
        <Box data-tag="allowRowEvents">
          <Text data-tag="allowRowEvents">
            {moment(row.createdAt).format("hh:mma")}
          </Text>
          <Text variant="muted" data-tag="allowRowEvents">
            {moment(row.createdAt).fromNow()}
          </Text>
        </Box>
      );
    },
  },
  {
    name: "Message",
    selector: (row) => row.message,
    sortable: true,
  },
  {
    name: "Action",
    selector: (row) => row.action,
    sortable: true,
  },
];
function DevLogs(props) {
  const { loading, data, error, refetch } = useQuery(DevLogsQuery, {});
  return (
    <Settings>
      <Flex flex={1} flexDirection="column" bg="white" p={4}>
        <Flex justifyContent="space-between">
          <Text variant="h2">Admin Only - Dev Logs</Text>
          <Button variant="ghost" onClick={() => refetch()}>
            <RefreshIcon />
          </Button>
        </Flex>
        <Table
          title=""
          defaultSortField="createdAt"
          selectableRows={false}
          columns={columns}
          data={data && data.devLogs}
          loading={loading}
          expandableRowsComponent={ExpandableComponent}
          noDataComponent={<EmptyState />}
        />
      </Flex>
    </Settings>
  );
}
const ExpandableComponent = ({ data }) => (
  <Box m={3} pl={4}>
    <ReactJson name={false} src={data.data} />
    <Text fontSize="xxs" as="pre" mt={3}>
      {data.detailedMessage}
    </Text>
  </Box>
);
export default DevLogs;
const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex flex={1} justifyContent="center" alignItems="center">
      <Flex flex={1} justifyContent="center" alignItems="center">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ height: "200px", objectFit: "contain" }}
        />
      </Flex>
      <Flex flex={1} alignItems="flex-start" flexDirection="column">
        <Text variant="h3">Dev</Text>
        <Text my={3}>
          Dev logs will be displayed here when dev data comes in. This data is
          purged every week.
        </Text>

        <Flex my={3} justifyContent="space-between">
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/api");
            }}
            leftIcon={<PlusIcon />}
          >
            New API
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  );
};
