import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text, Link } from "rebass";
import { Label, Input, Textarea, Checkbox } from "@rebass/forms";
import Switch from "react-switch";
import { useTheme } from "emotion-theming";
import { Editor } from "@tinymce/tinymce-react";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import moment from "moment";
import { MentionsInput, Mention } from "react-mentions";
import ReactTooltip from "react-tooltip";
import {
  CalendarIcon,
  CloseIcon,
  HelpIcon,
  LinkIcon,
  TimeIcon,
} from "../Icons";
import { useMentionTheme } from "./mentions";
import Select from "./Select";

import "./form.css";
import "assets/styles/DatePicker.css";
import "assets/styles/TimePicker.css";
import "assets/styles/skin.css";

function CustomFormGroup({
  name,
  label,
  type,
  fieldType,
  value,
  helperText,
  fieldHelpText,
  width,
  placeholder,
  clearable,
  noMargin,
  expanded,
  readOnly,
  onChange,
  onBlur,
  options,
  suggestions,
  sx,
  rows,
  disabled,
  onOpen,
}) {
  const theme = useTheme();
  const [height, setHeight] = useState(150);
  const { defaultStyle, mentionStyle } = useMentionTheme();
  let input;
  let readOnlyLabel = value;

  switch (fieldType || type) {
    case "time":
      if (value) {
        readOnlyLabel = (
          <Flex alignItems="flex-start" flexDirection="column">
            <Text mt={3} pt={1}>
              {""}
            </Text>
            <Text variant="muted" fontSize="xs" mt={1}>
              {moment(value).format("HH:MM a")}
            </Text>
          </Flex>
        );
      }
      break;
    case "date":
      if (value) {
        readOnlyLabel = (
          <Flex alignItems="flex-start" flexDirection="column">
            <Text>{moment(value).fromNow()}</Text>
            <Text variant="muted" fontSize="xs" mt={1}>
              {moment(value).format("DD/MM/YYYY")}
            </Text>
          </Flex>
        );
      }
      break;
    case "list":
      readOnlyLabel = options?.find((o) => o.value === value)?.label;
      break;
    case "richtext":
      readOnlyLabel = <Box dangerouslySetInnerHTML={{ __html: value }} />;
      break;
    case "url":
      readOnlyLabel = (
        <Link href={value} target="blank">
          {value}
        </Link>
      );
      break;
    case "currency":
      readOnlyLabel = `${
        value
          ? Number(value).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          : Number(0).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
      }`;
      break;
    case "switch":
      readOnlyLabel = value ? "Yes" : "No";
      break;
    case "checkbox":
      readOnlyLabel = (
        <Text px={1} variant="muted" htmlFor={name}>
          <Flex alignItems="center">
            <input
              type="checkbox"
              id={name}
              name={name}
              checked={value}
              disabled={true}
              style={{
                backgroundColor: "#fff",
                margin: 0,
                font: "inherit",
                color: "currentColor",
                width: "1.35em",
                height: "1.35em",
                border: "0.15em solid currentColor",
                borderRadius: "0.15em",
                transform: "translateY(-0.075em)",
              }}
            />
            <Text mx={1}>{label}</Text>
          </Flex>
        </Text>
      );
      break;
    case "exists": // Calculated type
      let newValue = value;
      if (value === "true" || value === "false") {
        newValue = value === "true" ? true : false;
      }
      // variable is a boolean
      readOnlyLabel = newValue ? "Yes" : "No";
      break;
    default:
      break;
  }

  switch (fieldType || type) {
    case "mentions":
      input = (
        <Box mb={2}>
          <MentionsInput
            singleLine
            style={defaultStyle}
            value={value}
            onChange={(event) => {
              onChange(name, event.target.value);
            }}
          >
            <Mention
              trigger="@"
              data={suggestions}
              style={mentionStyle}
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => (
                <div className={`user ${focused ? "focused" : ""}`}>
                  {highlightedDisplay}
                </div>
              )}
            />
          </MentionsInput>
        </Box>
      );
      break;
    case "list":
      input = (
        <Box>
          <Select
            placeholder={
              value && !options?.find((o) => o.value === value)
                ? value
                : placeholder
            }
            name={name}
            label=""
            value={options?.find((o) => o.value === value)}
            options={options}
            onChange={(n, v) => {
              onChange(n, v);
            }}
            clearable={clearable}
            width="100%"
          />
        </Box>
      );
      break;
    case "date":
      input = (
        <DatePicker
          onChange={(content) => {
            if (!!content) {
              const date = moment(content);
              let currentDate = value ? moment(value) : moment();
              let newDate = currentDate
                .month(date.month())
                .year(date.year())
                .date(date.date());

              if (!value) {
                const remainder = 30 - (newDate.minute() % 30);

                newDate = newDate.add(remainder, "minutes");
              }
              console.log(newDate?.toISOString());
              onChange(name, newDate?.toISOString());
            } else {
              onChange(name, undefined);
            }
          }}
          clearIcon={clearable ? <CloseIcon /> : null}
          value={value ? moment(value).toDate() : undefined}
          format="dd/MM/yyyy"
          formatShortWeekday={(locale, date) => moment(date).format("dd")}
          calendarIcon={<CalendarIcon />}
        />
      );
      break;

    case "time":
      let myDate = moment(value)
        .clone()
        .minute(Math.floor(moment(value).clone().minute() / 15) * 15);
      let newValue = myDate.format("hh:mm a");

      input = (
        <Box width="145px">
          <Select
            placeholder={placeholder}
            name={name}
            label=""
            value={options?.find((o) => o.value === newValue)}
            options={options}
            onOpen={() => onOpen && onOpen(name)}
            onChange={(n, content) => {
              if (content) {
                onChange(name, content?.date);
              } else {
                onChange(name, undefined);
              }
            }}
            clearable={true}
            width="100%"
          />
        </Box>
      );
      break;
    case "textarea":
      input = (
        <Textarea
          placeholder={placeholder}
          resize="vertical"
          name={name}
          value={value || ""}
          onChange={(event) => {
            onChange(name, event.target.value);
          }}
          width="100%"
          onBlur={onBlur}
          rounded="large"
          px={2}
          py={3}
          sx={{
            borderColor: theme.colors.line,
            backgroundColor: "white",
            borderWidth: 1,
          }}
        />
      );

      break;
    case "richtext":
      input = (
        <Editor
          key={moment().milliseconds}
          apiKey="8ud3ffgr7gphz0gnv98gughb6nw20ifeanz8fw6htk7vch8a"
          initialValue={value}
          init={{
            skin: "CUSTOM",
            height: `${(rows || 10) * 20}px`,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap",
              "insertdatetime media table paste code",
            ],
            toolbar:
              "undo redo | formatselect | bold italic | forecolor backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | image link | removeformat | code",
          }}
          onEditorChange={(content) => onChange(name, content)}
        />
      );
      break;
    case "switch":
      input = (
        <Box>
          <Label>
            <Switch
              name={name}
              checked={value || false}
              onChange={() => onChange(name, !value)}
              offColor={theme.colors.gray[200]}
              offHandleColor={theme.colors.gray[500]}
              onColor={theme.colors.brandLight}
              onHandleColor={theme.colors.white}
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              disabled={disabled}
            />
          </Label>
        </Box>
      );
      break;
    case "checkbox":
      input = (
        <Text px={1} variant="muted" htmlFor={name}>
          <Flex alignItems="center">
            <input
              type="checkbox"
              id={name}
              name={name}
              checked={value}
              onChange={() => onChange(name, !value)}
              style={{
                backgroundColor: "#fff",
                margin: 0,
                font: "inherit",
                color: "currentColor",
                width: "1.35em",
                height: "1.35em",
                border: "0.15em solid currentColor",
                borderRadius: "0.15em",
                transform: "translateY(-0.075em)",
              }}
            />
            <Text mx={1}>{label}</Text>
          </Flex>
        </Text>
      );
      break;
    case "boolean":
      input = (
        <Text px={1} variant="muted" htmlFor={name}>
          <Flex alignItems="center">
            <input
              type="checkbox"
              id={name}
              name={name}
              checked={value}
              onChange={() => onChange(name, !value)}
              style={{
                backgroundColor: "#fff",
                margin: 0,
                font: "inherit",
                color: "currentColor",
                width: "1.35em",
                height: "1.35em",
                border: "0.15em solid currentColor",
                borderRadius: "0.15em",
                transform: "translateY(-0.075em)",
              }}
            />
            <Text mx={1}>{label}</Text>
          </Flex>
        </Text>
      );
      break;

    case "url":
      let urlLink = undefined;
      try {
        urlLink = new URL(value).href;
      } catch (err) {
        console.log(err);
      }

      input = (
        <Flex alignItems={"center"}>
          <Input
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={(event) => {
              onChange(name, event.target.value);
            }}
            onBlur={onBlur}
            rounded="large"
            sx={{
              ...sx,
              backgroundColor: "white",
            }}
          />
          {urlLink && (
            <Link href={urlLink} target="blank" p={2}>
              <Flex>
                <LinkIcon />
              </Flex>
            </Link>
          )}
        </Flex>
      );
      break;
    default:
      input = (
        <Input
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(event) => {
            onChange(name, event.target.value);
          }}
          onBlur={onBlur}
          rounded="large"
          sx={{
            ...sx,
            backgroundColor: "white",
          }}
        />
      );
      break;
  }

  if (name === "details.conversionFitScore") {
    let new_value = 25;
    if (value <= 100) {
      new_value = 100;
    }
    if (value <= 75) {
      new_value = 75;
    }
    if (value <= 50) {
      new_value = 50;
    }
    if (value <= 25) {
      new_value = 25;
    }
    let new_options = [
      {
        label: (
          <Box>
            <Text>Poor Match</Text>
            <Text variant="muted" fontSize={"xxs"}>
              0%-25%
            </Text>
          </Box>
        ),
        value: 25,
      },
      {
        label: (
          <Box>
            <Text>Partial Match</Text>
            <Text variant="muted" fontSize={"xxs"}>
              25%-50%
            </Text>
          </Box>
        ),
        value: 50,
      },
      {
        label: (
          <Box>
            <Text>Good Match</Text>
            <Text variant="muted" fontSize={"xxs"}>
              50%-75%
            </Text>
          </Box>
        ),
        value: 75,
      },
      {
        label: (
          <Box>
            <Text>Perfect Match</Text>
            <Text variant="muted" fontSize={"xxs"}>
              75%-100%
            </Text>
          </Box>
        ),
        value: 100,
      },
    ];
    input = (
      <Box>
        <Select
          placeholder={placeholder}
          name={name}
          label=""
          value={new_options?.find((o) => o.value === new_value)}
          options={new_options}
          onChange={(n, v) => {
            onChange(n, v.value);
          }}
          width="100%"
        />
      </Box>
    );
  }

  if (name === "details.conversionAdoptionScore") {
    let new_value = 25;
    if (value <= 100) {
      new_value = 100;
    }
    if (value <= 75) {
      new_value = 75;
    }
    if (value <= 50) {
      new_value = 50;
    }
    if (value <= 25) {
      new_value = 25;
    }
    let new_options = [
      {
        label: (
          <Box>
            <Text>Low</Text>
            <Text variant="muted" fontSize={"xxs"}>
              0%-25%
            </Text>
          </Box>
        ),
        value: 25,
      },
      {
        label: (
          <Box>
            <Text>Average</Text>
            <Text variant="muted" fontSize={"xxs"}>
              25%-50%
            </Text>
          </Box>
        ),
        value: 50,
      },
      {
        label: (
          <Box>
            <Text>High</Text>
            <Text variant="muted" fontSize={"xxs"}>
              50%-75%
            </Text>
          </Box>
        ),
        value: 75,
      },
      {
        label: (
          <Box>
            <Text>Very High</Text>
            <Text variant="muted" fontSize={"xxs"}>
              75%-100%
            </Text>
          </Box>
        ),
        value: 100,
      },
    ];
    input = (
      <Box>
        <Select
          placeholder={placeholder}
          name={name}
          label=""
          value={new_options?.find((o) => o.value === new_value)}
          options={new_options}
          onChange={(n, v) => {
            onChange(n, v.value);
          }}
          width="100%"
        />
      </Box>
    );
  }
  return !readOnly || value || expanded ? (
    <Box width={width || "100%"} mb={noMargin ? 0 : 3}>
      <ReactTooltip
        id={name}
        place="right"
        effect="solid"
        getContent={(dataTip) => dataTip}
      />
      {type !== "checkbox" && label && (
        <Flex alignItems="center">
          <Text
            pb={2}
            variant={type === "switch" ? "muted" : "label"}
            htmlFor={name}
          >
            {label}
          </Text>
          {fieldHelpText && (
            <Box
              color="primary"
              mx={1}
              data-for={name}
              data-tip={fieldHelpText}
            >
              <HelpIcon />
            </Box>
          )}
        </Flex>
      )}
      {readOnly ? (
        <Box>
          <Text variant="body" sx={{ wordBreak: "break-word" }}>
            {readOnlyLabel}
          </Text>
        </Box>
      ) : (
        input
      )}
      {helperText && (
        <Text m={0} variant="muted" color="red" htmlFor={name}>
          {helperText}
        </Text>
      )}
    </Box>
  ) : null;
}
CustomFormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  fieldType: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ]),
  helperText: PropTypes.string,
  fieldHelpText: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  noMargin: PropTypes.bool,
  expanded: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  suggestions: PropTypes.arrayOf(PropTypes.node),
  sx: PropTypes.object,
};

CustomFormGroup.defaultProps = {
  label: "",
  type: "string",
  helperText: undefined,
  fieldHelpText: undefined,
  fieldType: undefined,
  placeholder: "",
  onBlur: undefined,
  width: "100%",
  options: undefined,
  noMargin: false,
  expanded: true,
  readOnly: false,
  clearable: false,
  suggestions: undefined,
  sx: {},
};

export default CustomFormGroup;
