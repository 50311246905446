import React from "react";
import { Flex, Text, Box, Link, Image } from "rebass";
import { useQuery } from "@apollo/react-hooks";
import { useHistory, Link as RouterLink } from "react-router-dom";
import Loader from "Components/Common/Loader";
import { convertNumber } from "consts";
import WidgetWrapper from "./WidgetWrapper";
import EventsDashboard from "../../../graphql/queries/EventsDashboard";
import numeral from "numeral";

const _ = require("lodash");

function EventSummary(props) {
  const { data, loading } = useQuery(EventsDashboard, {
    variables: {
      searchFilters: {
        ownerUserId: props.filters?.ownerUserId,
        productId: props.filters?.productId,
        daysOffset: 30,
      },
    },
  });

  const dataTable = _.slice(data?.eventsDashboard?.summary, 0, 5);

  return (
    <WidgetWrapper title="Top 5 Events">
      {loading && <Loader />}
      <Box width="100%">
        {dataTable.length > 0
          ? dataTable.map((event) => {
              return (
                <Flex
                  key={event.key}
                  flex={1}
                  p={2}
                  m={2}
                  justifyContent="space-between"
                  sx={{
                    borderBottomWidth: 1,
                    borderBottomColor: "gray.200",
                    borderBottomStyle: "solid",
                  }}
                >
                  <Text fontSize="xs">
                    <RouterLink
                      data-tag="allowRowEventSummary"
                      to={`/adoption/events/${event.key}`}
                      component={Link}
                    >
                      {event.name}
                    </RouterLink>
                  </Text>
                  <Flex justifyContent="space-between">
                    <Text fontSize="xs" color="brandLight" mx={2}>
                      {`${numeral(event.value).format("0.0")}%`}
                    </Text>
                  </Flex>
                </Flex>
              );
            })
          : !loading && (
              <Flex flex={1} alignItems="center" justifyContent="center" p={3}>
                <Image
                  src={require("assets/images/EventsEmpty.png")}
                  width={80}
                />
              </Flex>
            )}
      </Box>
    </WidgetWrapper>
  );
}

export default EventSummary;
