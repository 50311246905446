import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { useDynamicGql } from "../graphql/DynamicGql";

export function getData(model, id) {
  const { getDataById } = useDynamicGql(model, false);
  const { companyId } = useParams();

  const variables = companyId ? { id: id, companyId: companyId } : { id: id };
  const dataQuery = useQuery(getDataById, {
    variables: variables,
    skip: !id || id === "new" || !model,
    fetchPolicy: "network-only",
  });
  console.log(dataQuery);
  return {
    ...dataQuery,
  };
}
