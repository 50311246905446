import React from "react";
import { useHistory } from "react-router";
import { Box, Flex, Image, Text } from "rebass";
import Settings from ".";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { PlusIcon, SettingIcon, TickIcon } from "../../Common/Icons";
import AccountReindexDataMutation from "../../../graphql/mutations/AccountReindexData.gql";
import { useMutation } from "@apollo/client";
import { getProduct } from "hooks/getProduct";
import { useToasts } from "react-toast-notifications";
import AdminOnly from "../AdminOnly";

function AccountIndex(props) {
  const { addToast } = useToasts();
  const { data: productData } = getProduct();
  const currentProduct = productData?.currentProduct;
  const [reindexData, {}] = useMutation(AccountReindexDataMutation);

  const reindex = () => {
    reindexData().then(
      (response) => {
        addToast(
          <Flex>Reindex has been started. This may take several minutes.</Flex>,
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
      },
      (error) => {
        addToast(<Flex>Reindex request failed.</Flex>, {
          appearance: "error",
          autoDismiss: false,
        });
      }
    );
  };
  return (
    <Settings>
      <Box m={4} justifyContent="center" flexDirection="column">
        <Flex>
          <Flex mx={3} flex={1} flexDirection="column">
            <InfoBox
              heading="Healthscore"
              description="Configure your accounts healthscore. A health score is a calculated number that measures the current status of your relationship with your account. Your teams can use this information to determine which accounts to focus on."
              url="/settings/product/healthscores"
            />
            <Flex mt={3}>
              <Flex mr={2} width={1 / 2}>
                <InfoBox
                  heading="Teams"
                  description="Manage your products teams and security."
                  url="/settings/product/teams"
                />
              </Flex>
              <Flex ml={2} width={1 / 2}>
                <InfoBox
                  heading="Users"
                  description="Manage your users and make sure all employees have access to the right information at the right time."
                  url="/settings/account/users"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width={1 / 3}
            pr={3}
          >
            <Flex p={3} m={3} flex={1} flexDirection="column">
              {currentProduct?.logoUrl ? (
                <Box sx={{ borderRadius: 9999, width: 120, height: 120 }}>
                  <Image
                    src={currentProduct?.logoUrl}
                    sx={{ maxHeight: 120, maxWidth: 120 }}
                  />
                </Box>
              ) : (
                <Image
                  src={require("assets/images/LoginSplash.png")}
                  height={120}
                />
              )}
            </Flex>
            <AdminOnly>
              <InfoBox
                heading="Admin Logs"
                description="View development logs for this account. Looking for reason issues are occuriing? Look here."
                url="/settings/product/dev-logs"
                buttonLabel="View"
              />
            </AdminOnly>
          </Flex>
        </Flex>
        <Flex m={3} flex={1} justifyContent="space-evenly">
          <Flex width={1 / 3}>
            <InfoBox
              heading="Customisations"
              description="Add custom fields to your accounts, users and events and track addition information to drive business decisions and more intelligent automations."
              url="/settings/product/fields"
            />
          </Flex>
          <Flex mx={3} width={1 / 3}>
            <InfoBox
              heading="Automations"
              description="Configure your automations, plans and accounts
                lifecycle for your product."
              url="/settings/product/automations"
            />
          </Flex>
          <Flex width={1 / 3}>
            <InfoBox
              heading="Integrations"
              description="Set up your API keys and events for integrations with our
                javascript API, webhooks or zapier"
              url="/settings/product/api"
            />
          </Flex>
        </Flex>
      </Box>
    </Settings>
  );
}

export default AccountIndex;

const InfoBox = ({ url, heading, description, buttonLabel, onClick }) => {
  const history = useHistory();
  return (
    <Flex
      p={3}
      flex={1}
      flexDirection="column"
      sx={{
        borderColor: "accent",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
      }}
    >
      <Flex flex={1} mb={4} flexDirection="column">
        <Text variant="h3">{heading}</Text>
        <Text variant="muted">{description}</Text>
      </Flex>
      <SecondaryButton
        onClick={() => {
          onClick ? onClick() : history.push(url);
        }}
        leftIcon={<SettingIcon />}
      >
        {buttonLabel ? buttonLabel : "Settings"}
      </SecondaryButton>
    </Flex>
  );
};
