import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text, Box } from "rebass";
import RadioGroup from "Components/Common/RadioGroup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "Components/Common/Loader";
import AssignCompanyPlanMutation from "../../../graphql/mutations/AssignCompanyPlan.gql";
import PlanQuery from "../../../graphql/queries/Plans.gql";

function AssignCompanyPlan(props) {
  const { addToast } = useToasts();
  const [planId, setPlan] = useState();
  const { data: planData } = useQuery(PlanQuery, {});

  const [save, { loading }] = useMutation(AssignCompanyPlanMutation);

  const plans =
    planData?.plans?.filter(
      (o) =>
        !props.moduleType || o.planType?.companyStatus.name === props.moduleType
    ) || [];

  const assignPlan = (id) => {
    save({
      variables: {
        companyIds: props.companyIds,
        planId,
      },
    }).then(
      (response) => {
        if (!response.data?.assignCompanyPlan?.errors) {
          props.onSave();
        } else {
          addToast(<Flex>{response.data?.relationship?.errors[0]}</Flex>, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  console.log(plans);
  return (
    <Flex flexDirection="column" px={3} pb={4}>
      {loading ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Loader />
          <Text mx={2}>Please wait we are updating your accounts plans</Text>
        </Flex>
      ) : (
        <>
          {plans.map((plan) => (
            <Box
              key={plan.id}
              p={3}
              variant="box"
              m={2}
              bg={planId === plan.id && "brandHighlight"}
              sx={{
                "&:hover": { backgroundColor: "brandHighlight" },
                borderRadius: "medium",
                cursor: "pointer",
              }}
              onClick={({ target }) => {
                setPlan(plan.id);
              }}
            >
              <Flex alignItems="center">
                <Box>
                  <Text variant="h3">{plan.name}</Text>
                  <Text variant="muted">{plan.planType?.name} tier</Text>
                </Box>
                {plan.default && (
                  <Text
                    bg="brandHighlight"
                    color="brand"
                    sx={{
                      borderColor: "brandLight",
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderRadius: "medium",
                    }}
                    p={1}
                    mx={2}
                    fontSize="xs"
                  >
                    Default
                  </Text>
                )}
              </Flex>

              <Text variant="muted">{plan.status?.name}</Text>
            </Box>
          ))}
          <Box my={2} />

          <Flex mt={3} justifyContent="flex-end">
            <Button
              variant={planId ? "primary" : "disabled"}
              disabled={!planId}
              onClick={() => assignPlan()}
            >
              Update
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default AssignCompanyPlan;
