import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useDynamicSchema } from "hooks/DynamicSchema";
import { MODEL_TYPES } from "consts";
import { getProduct } from "hooks/getProduct";
import { getChurnReasons } from "hooks/getChurnReasons";
function DynamicForm({
  typeName,
  detailView,
  expanded,
  readOnly,
  errors,
  values,
  handleBlur,
  setFieldValue,
}) {
  const { data: product } = getProduct();
  const { data: churnData } = getChurnReasons();
  const { fields } = useDynamicSchema(MODEL_TYPES[typeName]);

  const churnOptions = churnData?.churnReasons?.map((c) => {
    return { value: c.id, label: c.name };
  });
  const getFieldType = (type) => {
    switch (type) {
      case "textarea":
        return "textarea";
      case "richtext":
        return "richtext";
      case "boolean":
        return "checkbox";
      case "date":
        return "date";
      case "list":
        return "list";
      default:
        return "input";
    }
  };
  if (!product?.currentProduct) {
    return null;
  }
  let sortedFields = [];

  if (detailView) {
    sortedFields = fields
      ?.filter(
        (field) =>
          field.name !== "timezone" &&
          field.name !== "locale" &&
          field.name !== "externalId" &&
          field.name !== "sourceExternalId" &&
          field.name !== "churnReasonUpdatedAt" &&
          !field.uiExclude
      )
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
  } else {
    sortedFields = fields
      ?.filter(
        (field) =>
          field.name !== "timezone" &&
          field.name !== "locale" &&
          field.name !== "externalId" &&
          field.name !== "sourceExternalId" &&
          field.name !== "churnReasonUpdatedAt" &&
          !field.uiExclude
      )
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
  }

  let readOnlyFields = sortedFields
    ?.filter((field) => field.access == "calculated")
    .sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });

  let editableFields = sortedFields
    ?.filter((field) => field.access == "readwrite" || field.access == "write")
    .sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
  return (
    <>
      <Flex flexDirection={readOnly ? "row" : "column"} flexWrap="wrap">
        {editableFields?.map((field) => {
          let fieldValue;
          if (values.details && field.modelName === "CompanyDetail") {
            fieldValue =
              field.name === "ownerUserId"
                ? values.details.owner?.name
                : values.details[field.name];
          } else {
            if (field.storage == "custom") {
              fieldValue = values.fields && values.fields[field.name];
            } else {
              fieldValue = values[field.name];
            }
          }
          let fieldName;
          if (values.details && field.modelName === "CompanyDetail") {
            fieldName = `details.${field.name}`;
          } else {
            fieldName = field.name;
            if (field.storage == "custom") {
              fieldName = `fields.${fieldName}`;
            }
          }

          const errorMessage =
            errors && _.get(errors, fieldName) && _.get(errors, fieldName);

          const options =
            field.type === "list"
              ? field.options?.split(",").map((a) => {
                  return { value: a, label: a };
                })
              : field.name == "churnReasonId"
              ? churnOptions
              : undefined;

          const fieldType =
            field.access === "calculated"
              ? field.calculatedType
              : field.name == "churnReasonId"
              ? "list" // default to list for churn reasons
              : field.type;

          return (
            <Flex
              key={field.name}
              width={
                fieldValue?.length < 14 && (field.readOnly || readOnly)
                  ? "50%"
                  : "100%"
              }
            >
              <CustomFormGroup
                key={fieldName}
                placeholder={field.displayName}
                label={field.displayName}
                name={fieldName}
                value={fieldValue}
                clearable
                onChange={(name, value) => {
                  if (field.type === "list") {
                    setFieldValue(fieldName, value?.value);
                  } else {
                    setFieldValue(fieldName, value);
                  }
                }}
                onBlur={handleBlur}
                fieldType={fieldType}
                type={getFieldType(fieldType)}
                options={options}
                helperText={errorMessage}
                expanded={expanded}
                readOnly={field.readOnly || readOnly}
              />
            </Flex>
          );
        })}
      </Flex>
      <Flex flexDirection="row" flexWrap="wrap">
        {readOnlyFields?.map((field) => {
          let fieldValue;
          if (values.details && field.modelName === "CompanyDetail") {
            fieldValue =
              field.name === "ownerUserId"
                ? values.details.owner?.name
                : values.details[field.name];
          } else {
            if (field.storage == "custom") {
              fieldValue = values.fields && values.fields[field.name];
            } else {
              fieldValue = values[field.name];
            }
          }
          let fieldName;
          if (values.details && field.modelName === "CompanyDetail") {
            fieldName = `details.${field.name}`;
          } else {
            fieldName = field.name;
            if (field.storage == "custom") {
              fieldName = `fields.${fieldName}`;
            }
          }
          return (
            <Flex
              key={field.name}
              width={fieldValue?.length > 100 ? "100%" : "33%"}
            >
              <CustomFormGroup
                key={field.name}
                placeholder={field.displayName}
                label={field.displayName}
                name={field.name}
                value={fieldValue}
                type={getFieldType(field.type)}
                expanded={expanded}
                readOnly={true}
              />
            </Flex>
          );
        })}
      </Flex>
    </>
  );
}
DynamicForm.propTypes = {
  typeName: PropTypes.string.isRequired,
  detailView: PropTypes.bool,
  expanded: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object,
  values: PropTypes.object,
};

DynamicForm.defaultProps = {
  detailView: false,
  expanded: false,
  readOnly: false,
};
export default DynamicForm;
