import React, { useState } from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  VerticalBarSeries,
  HorizontalRectSeries,
  YAxis,
  XAxis,
} from "react-vis";
import { useTheme } from "emotion-theming";
import { Flex } from "rebass";
import moment from "moment";

const _ = require("lodash");

export default function StackedBarGraph(props) {
  const [value, setValue] = useState();
  const theme = useTheme();
  let graphDataTemplate = [];
  let idx = 0;
  for (let i = props.days; i >= 0; i--) {
    graphDataTemplate.push({
      date: moment()
        .clone()
        .add(-1 * i, "day"),
      x: idx++,
      y: 0,
    });
  }
  return props.series && props.series.length > 0 ? (
    <Flex>
      <XYPlot
        width={500}
        height={props.height || 250}
        onMouseLeave={() => setValue(false)}
        stackBy="y"
      >
        <XAxis
          tickFormat={(v, i) => {
            return (
              v % 10 == 0 &&
              moment()
                .add(v * -1, "days")
                .format("DD MMM")
            );
          }}
        />
        <YAxis />

        {props.marker &&
          props.series?.map((ev, i) => {
            return (
              i === 0 &&
              props.marker.map((m) => {
                const DATA = [
                  {
                    x0: 0,
                    x: ev.length + 1,
                    y0: m.max,
                    y: m.min,
                  },
                ];
                return (
                  <HorizontalRectSeries
                    key={i}
                    data={DATA}
                    color={m.color}
                    style={{ strokeWidth: 0 }}
                  />
                );
              })
            );
          })}

        {props.series?.map((ev, i) => {
          let mydata = ev.map((e, idx) => {
            return { y: e.count, x: idx + 1, name: e.name };
          });

          return (
            <VerticalBarSeries
              key={i}
              color={_.first(ev)?.color || theme.colors.graph[i]}
              data={mydata}
            />
          );
        })}
      </XYPlot>
    </Flex>
  ) : (
    <Flex>Data not in correct format</Flex>
  );
}
