import React from "react";
import { useMutation } from "@apollo/react-hooks";
import "./tags.css";
import { Text, Flex, Box } from "rebass";
import TagMutation from "../../../graphql/mutations/Tags.gql";

export default function Tags({ value, label }) {
  const [save] = useMutation(TagMutation);

  const tagList =
    (value &&
      value.map((t) => {
        return { id: t, displayValue: t };
      })) ||
    [];

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      sx={{ position: "relative" }}
    >
      {label && (
        <Text variant="label" textAlign="left">
          {label}
        </Text>
      )}
      <Flex flexWrap="wrap">
        {tagList.map((tag) => (
          <Box
            key={tag.displayValue}
            bg="brandHighlight"
            mr={1}
            mt={1}
            px={1}
            sx={{ borderRadius: 10 }}
          >
            <Flex
              alignItems="center"
              justfiyContent="center"
              flexDirection="column"
              p={1}
            >
              <Flex alignItems="center">
                <Text mx={1} color="brandLight" variant="muted">
                  {tag.displayValue}
                </Text>
              </Flex>
            </Flex>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
}
