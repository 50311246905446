import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import PropTypes from "prop-types";
import Modal from "Components/Common/Modal";
import TaskForm from "Components/CommandCenter/Tasks/TaskForm";
import AddCompanySatisfaction from "../Data/Company/AddCompanySatisfaction";
import AddCompanySentiment from "../Data/Company/AddCompanySentiment";
import CommentForm from "./CommentForm";
import CommentUpdateForm from "./CommentUpdateForm";
const ActionForm = (props) => {
  switch (props.formType) {
    case "activity":
      return (
        <TaskForm
          actionableId={props.id}
          parentType={props.parentType}
          actionableName={props.actionableName}
          open
          quickAction
          activityOnly
          hideCalendar
          task={{
            taskCategoryId: props.taskCategoryId,
            actionable: { id: props.id, type: props.parentType },
          }}
          onSave={() => {
            props.onSave();
          }}
          onClose={() => {
            props.onDismiss();
          }}
        />
      );
    case "sentiment":
      return (
        <AddCompanySentiment
          ids={[props.id]}
          parentType={props.parentType}
          onSave={() => {
            props.onSave();
          }}
          onClose={() => {
            props.onDismiss();
          }}
        />
      );
    case "satisfaction":
      return (
        <AddCompanySatisfaction
          ids={[props.id]}
          parentType={props.parentType}
          companyIds={[props.id]}
          onSave={() => {
            props.onSave();
          }}
          onClose={() => {
            props.onDismiss();
          }}
        />
      );
    case "comment":
      return (
        <CommentForm
          onUpdate={() => {
            props.onSave();
          }}
          onClose={() => {
            props.onDismiss();
          }}
          id={props.id}
          comment={props.values?.comment}
          {...props}
        />
      );
    case "updateComment":
      return (
        <CommentUpdateForm
          onUpdate={() => {
            props.onSave();
          }}
          onClose={() => {
            props.onDismiss();
          }}
          id={props.id}
          comment={props.values?.comment}
          {...props}
        />
      );
    case "task":
      return (
        <TaskForm
          open
          quickAction
          id={props.id}
          parentType={props.parentType}
          task={{
            taskCategoryId: props.taskCategoryId,
            actionable: { id: props.id, type: props.parentType },
          }}
          onSave={() => {
            props.onSave();
          }}
          onClose={() => {
            props.onDismiss();
          }}
        />
      );

    default:
      return "No input";
  }
};

const ModalTitle = (formType) => {
  let title = "";
  switch (formType) {
    case "sentiment":
      title = "Sentiment";
    case "satisfaction":
      title = "Satisfaction";
    case "activity":
      title = "Activity";
      break;
    case "comment":
      title = "Note";
      break;
    case "task":
      title = "Activity";
      break;
  }
  return title;
};

function MenuModal(props) {
  return (
    <Modal
      title={ModalTitle(props.formType)}
      width={900}
      open={props.open}
      onDismiss={() => {
        props.onDismiss();
      }}
    >
      <Flex ml={2} flexDirection="column" pb={4}>
        <ActionForm {...props} />
      </Flex>
    </Modal>
  );
}

MenuModal.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSave: PropTypes.func,
  selectedRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  formType: PropTypes.oneOf([
    "task",
    "activity",
    "comment",
    "sentiment",
    "satisfaction",
  ]),
};
export default MenuModal;
