import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Label } from "@rebass/forms";
import _uniqueId from "lodash/uniqueId";
import { Flex, Text } from "rebass";

const RadioGroup = ({
  title,
  labelSx,
  options = [],
  selectedOption,
  onChange,
  name,
  noFlex,
  ...otherProps
}) => {
  // useMemo ensures the groupId prop doesn't needlessly change on re-render
  const groupId = useMemo(() => _uniqueId("radio-group-"), []);

  const groupPillLabelStyle = {
    label: {
      display: "flex",
      flexGrow: 1,
      variant: "buttons.pill",
      fontWeight: "body",
      padding: "0",
      "&:hover": {
        bg: "gray.200",
      },
      "&:first-of-type:not(:last-of-type)": {
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      },
      "&:last-of-type:not(:first-of-type)": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
      },
      "&:not(:first-of-type):not(:last-of-type)": {
        borderRadius: "0",
        ml: "-1px",
        mr: "-1px",
      },
      "&:nth-last-of-type(2):first-of-type": {
        mr: "-1px",
      },
      'input[type="checkbox"]': {
        opacity: "0",
        position: "absolute",
        "&:checked + span": {
          bg: "secondaryLight",
          borderRadius: "inherit",
          color: "white",
        },
      },
    },
  };

  return (
    <Flex
      role="radiogroup"
      aria-labelledby={groupId}
      display="inline-flex"
      {...otherProps}
    >
      {options.map(({ label, value, cyName, ...rest }, index) => (
        <Flex
          key={`rd-${index}`}
          width={noFlex ? "" : label ? "100%" : ""}
          alignItems="center"
        >
          <input
            checked={selectedOption === value}
            type="radio"
            value={value}
            data-cy={cyName}
            onChange={({ target }) => {
              onChange(name, { label: label, value: target.value, ...rest });
            }}
            style={{ transform: "scale(1.3)", display: "inline-block" }}
          />
          <Text px={1} role="presentation" sx={{ display: "inline-block" }}>
            {label}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.string,
    })
  ),
  selectedOption: PropTypes.string,
  title: PropTypes.string,
  labelSx: PropTypes.object,
  noFlex: PropTypes.bool,
};

export default RadioGroup;
