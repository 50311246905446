import React from "react";
import { withRouter } from "react-router-dom";
import { Flex, Image, Box, Text, Link, Heading } from "rebass";
import StepWizard from "react-step-wizard";
import StepOne from "./Step1";
import StepTwo from "./Step2";
import WizardNav from "Components/Common/WizardNav";
import WebsiteWrapper from "../WebsiteWrapper";

function RegisterUser(props) {
  return (
    <WebsiteWrapper>
      <Box width={1 / 2}>
        <StepWizard
          nav={<WizardNav stepLabels={["User", "Email Confirmation", "..."]} />}
        >
          <StepOne />
          <StepTwo />
          <Flex />
        </StepWizard>
      </Box>
    </WebsiteWrapper>
  );
}

export default withRouter(RegisterUser);
