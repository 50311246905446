import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text, Button, Box, Link } from "rebass";
import PropTypes from "prop-types";
import GhostButton from "../Common/Buttons/GhostButton";
import { ArrowDropDownIcon, CustomIcon, PriorityIcon } from "../Common/Icons";
import { separateOperations } from "graphql";
import moment from "moment";
import TaskComments from "Components/CommandCenter/Tasks/TaskComments";

export const ActivityTaskTitle = ({ activity }) => {
  return (
    <Flex>
      <Text fontWeight={"bold"} mr={1}>
        {activity.actionable?.taskCategoryName}
      </Text>
      logged by
      <Text fontWeight={"bold"} ml={1}>
        {activity.user?.name}
      </Text>
    </Flex>
  );
};
function ActivityTask({ activity, refetch }) {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState(false);
  return (
    <Flex flexDirection={"column"} bg="gray.100" p={2}>
      <Flex>
        <Flex flex={1} alignItems="center" justifyContent={"space-between"}>
          <Flex alignItems="center">
            <GhostButton onClick={() => setOpen(!open)}>
              <ArrowDropDownIcon />
            </GhostButton>
            <Text mt={1} color="brandLight">
              <CustomIcon
                componentName={activity.actionable?.taskCategoryIcon}
              />
            </Text>
            <Text mx={2}>{activity.actionable?.title}</Text>
            {activity.entity?.firstName && (
              <Flex alignItems="center">
                <Text variant="muted">{activity.entity?.firstName}</Text>
                <Text mx={1} variant="muted">
                  {activity.entity?.lastName}
                </Text>
              </Flex>
            )}
          </Flex>
          <PriorityIcon value={activity.actionable?.priority} />
        </Flex>
      </Flex>
      {open && (
        <Flex px={2} flexDirection={"column"} mx={3}>
          <Box variant="divider" />
          <Flex>
            <Flex flex={1} flexDirection={"column"}>
              <Text variant="label">Start</Text>
              {activity.actionable?.dueAt && (
                <Text fontSize="xs">
                  {moment(activity.actionable?.dueAt).format(
                    "DD MMM YYYY hh:mm a"
                  )}
                </Text>
              )}
            </Flex>
            <Flex flex={1} flexDirection={"column"}>
              <Text variant="label">Duration</Text>
              {activity.actionable?.endAt && (
                <Text fontSize="xs">
                  {moment(activity.actionable?.endAt).diff(
                    activity.actionable?.dueAt,
                    "minutes"
                  )}{" "}
                  minutes
                </Text>
              )}
            </Flex>
            <Flex flexDirection={"column"}>
              <Text variant="label">Completed</Text>
              {activity.actionable?.completedAt && (
                <Text fontSize="xs">
                  {moment(activity.actionable?.completedAt).format(
                    "DD MMM YYYY"
                  )}
                </Text>
              )}
            </Flex>
          </Flex>
          <Box variant="divider" />

          <Flex mb={2} flex={1} flexDirection={"column"}>
            {activity.actionable?.description && (
              <Text
                fontSize={"xs"}
                bg="gray.100"
                dangerouslySetInnerHTML={{
                  __html: activity.actionable?.description,
                }}
              />
            )}
          </Flex>
        </Flex>
      )}
      {open && (
        <Box mx={3}>
          <TaskComments
            values={activity.actionable}
            onSave={() => refetch()}
            showLastNote
          />
        </Box>
      )}
      {open && notes ? (
        <Box mx={3}>
          <Flex justifyContent="flex-end">
            <Link
              fontSize={"xs"}
              my={2}
              onClick={() => setNotes(false)}
              sx={{ cursor: "pointer" }}
            >
              Hide
            </Link>
          </Flex>
          <TaskComments
            values={activity.actionable}
            onSave={() => refetch()}
            showAddOnly
          />
        </Box>
      ) : (
        open && (
          <Flex mx={3} justifyContent="flex-end">
            <Link
              fontSize={"xs"}
              onClick={() => setNotes(true)}
              sx={{ cursor: "pointer" }}
            >
              Add notes
            </Link>
          </Flex>
        )
      )}
    </Flex>
  );
}
ActivityTask.propTypes = {
  activity: PropTypes.object,
};
export default ActivityTask;
