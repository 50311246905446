import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Flex, Text } from "rebass";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PropTypes from "prop-types";
import { CompletedMailIcon, MailIcon } from "Components/Common/Icons";
import moment from "moment";
import { useUrlParams } from "../../../hooks/useUrlParams";
import InboxTable from "./InboxTable";

function Inbox({ detailedView, loading, onSelected, onSave, data }) {
  const [selectedRows, setSelectedRows] = useState();
  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const tabs = ["Unread", "Read", "Priority", "Archived"];
  const defaultTab = getUrlParameter("tab") || "Unread";
  const [selectedTab, setSelectedTab] = useState(
    tabs.indexOf(tabs.find((t) => t.toLowerCase() === defaultTab.toLowerCase()))
  );

  const allData = data?.filter((t) => t.id !== null);

  //not handled anymore
  const archivedData = data?.filter((t) => t.archived === true);

  return (
    <Flex flex={1} w="100%" flexDirection="column">
      <Flex mt={3} flex={1} alignItems="flex-start">
        <Tabs
          width="100%"
          direction="rtl"
          selectedIndex={selectedTab}
          onSelect={setSelectedTab}
        >
          <TabList>
            <Tab key="All">
              <Flex alignItems="center" sx={{ minWidth: 100 }}>
                <MailIcon />
                <Text fontSize="xs" color="text" mx={2}>
                  All
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="brandHighlight"
                  px={2}
                  py={1}
                  sx={{ borderRadius: "medium" }}
                >
                  {allData.length}
                </Flex>
              </Flex>
            </Tab>
            <Tab key="Archived">
              <Flex alignItems="center" sx={{ minWidth: 100 }}>
                <CompletedMailIcon />
                <Text fontSize="xs" color="text" mx={2}>
                  Archived
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="brandHighlight"
                  px={2}
                  py={1}
                  sx={{ borderRadius: "medium" }}
                >
                  <Text color="brandLight">{archivedData.length}</Text>
                </Flex>
              </Flex>
            </Tab>
          </TabList>
          <TabPanel key="All">
            <InboxTable detailedView data={allData} loading={loading} />
          </TabPanel>
          <TabPanel key="Archived">
            <InboxTable detailedView data={archivedData} loading={loading} />
          </TabPanel>
        </Tabs>
      </Flex>
    </Flex>
  );
}

Inbox.propTypes = {
  detailedView: PropTypes.bool,
  loading: PropTypes.bool,
  view: PropTypes.oneOf(["summary"]),
  onSelected: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

Inbox.defaultProps = {
  detailedView: true,
  loading: false,
  view: "summary",
  data: [],
  fromUser: [],
};

export default withRouter(Inbox);
