import React, { useEffect, useState } from "react";
import Page from "Components/Page";
import { Flex, Box, Text, Button } from "rebass";
import CompaniesList from "Components/Data/Company/Companies";
import Filter from "Components/Filter";
import SimpleStatCard from "Components/Dashboard/Widgets/SimpleStatCard";
import { MODEL_TYPES } from "consts";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getCompanyStatus } from "hooks/getCompanyStatuses";
import { getSearchFilter } from "hooks/getSearchFilter";
import { useTheme } from "emotion-theming";
import { HealthIcon } from "Components/Common/Icons";
import { getLifeCycles } from "hooks/getLifeCycles";
import { getAllCompanyData } from "hooks/getAllCompanyData";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
import CompanyDashboard from "../../../graphql/queries/CompanyDashboard.gql";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import CompanyTopbar from "./CompanyTopbar";
import StageMenuSelect from "./StageMenuSelect";
import moment from "moment";
import { TickIcon, WarningIcon } from "../../Common/Icons";
import { Label } from "@rebass/forms";
import { useUrlParams } from "../../../hooks/useUrlParams";
import PropTypes from "prop-types";

const _ = require("lodash");
const numeral = require("numeral");

function Companies(props) {
  const statusName = "Paid";
  const theme = useTheme();
  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const defaultTags = getUrlParameter("tags");
  const [trigger, setTriggerRefresh] = useState();
  const { data: statuses } = getCompanyStatus();
  const { data: searchFilters } = getSearchFilter();
  const [saveFilter] = useMutation(SearchFilterMutation);

  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(50);
  const [perPage, setPerPage] = useState(50);
  const [sort, setSort] = useState(undefined);

  const handleSort = async (column, sortDirection) => {
    setSort({
      column: column.id,
      direction: sortDirection,
      schemaName: column.schemaName,
    });
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(1);
  };
  const statusId = statuses?.companyStatuses.find(
    (s) => s.name === statusName
  )?.id;

  const filters = statusId
    ? {
        filterText: searchFilters?.filters?.paid?.filterText,
        lifeCycleStageId: searchFilters?.filters?.paid?.lifeCycleStageId,
        planId: searchFilters?.filters?.paid?.planId,
        statusId,
        segmentId: searchFilters?.filters?.segmentId,
        ownerUserId: searchFilters?.filters?.ownerUserId,
        teamId: searchFilters?.filters?.teamId,
        tags: searchFilters?.filters?.tags,
      }
    : {
        statusId: undefined,
      };

  const { loading, data, error, refetch } = getAllCompanyData(statusName, {
    filters: { ...filters, page: page, size: perPage, sort: sort },
  });
  const { data: dashboardData, refetch: dashboardRefetch } = useQuery(
    CompanyDashboard,
    {
      variables: {
        searchFilters: filters,
      },
    }
  );

  const { data: lcData } = getLifeCycles(statusId);
  const companies = data?.companies?.companies || [];
  useEffect(() => {
    if (data?.companies) {
      setTotalRows(data?.companies.total);
    }
  }, [data]);

  useEffect(() => {
    /* If there is a tags in the url set the state for searching. */
    if (
      searchFilters?.filters &&
      (!searchFilters.filters.tags || searchFilters.filters.tags.length == 0) &&
      defaultTags?.split(",").length > 0
    ) {
      if (defaultTags.split(",").length > 0) {
        updateFilter(
          "tags",
          defaultTags.split(","),
          `Tags (${defaultTags.split(",").length})`
        );
      } else {
        updateFilter("tags", [], undefined);
      }
    }
  }, [searchFilters?.filters]);

  const lifeCycles =
    lcData?.lifeCycles?.find((l) => l.name === "Paid")?.stages || [];

  const dashboard = dashboardData?.companiesDashboard;

  let healthData = [];
  const overdueCount =
    companies.filter((c) => {
      return (
        moment(c.details?.companyPlan?.expectedCompletedAt).isBefore(
          moment(),
          "day"
        ) &&
        c.details?.companyPlan?.completedSteps <
          c.details?.companyPlan?.totalSteps
      );
    }).length || 0;

  const stageCompleteCount =
    companies.filter((c) => {
      return (
        c.details?.companyPlan?.completedSteps ===
          c.details?.companyPlan?.totalSteps &&
        c.details?.companyPlan?.totalSteps > 0
      );
    }).length || 0;

  dashboard?.goodCount > 0 &&
    healthData.push({
      value: dashboard?.goodCount,
      label: "good",
      color: theme.colors.green,
    });
  dashboard?.okCount > 0 &&
    healthData.push({
      value: dashboard?.okCount,
      label: "ok",
      color: theme.colors.yellow,
    });

  dashboard?.poorCount > 0 &&
    healthData.push({
      value: dashboard?.poorCount,
      label: "poor",
      color: theme.colors.red,
    });

  if (healthData.length === 0) {
    healthData.push({
      value: 100,
      label: "remaining",
      color: theme.colors.gray[200],
    });
  }

  const updateFilter = (name, id, label) => {
    const newFilters = filters;

    let ref = newFilters;
    let nestedNames = name.split(".").reverse();
    while (nestedNames.length > 1) {
      const n = _.last(nestedNames);
      ref[n] = {};
      ref = ref[n];
      nestedNames = nestedNames.slice(0, -1);
    }
    const n = _.last(nestedNames);
    ref[n] = id;

    if (!newFilters.labels) {
      newFilters.labels = {};
    }
    newFilters.labels[name] = label;
    saveFilter({
      variables: {
        filters: newFilters,
      },
    }).then(
      (response) => {
        props.onChange && props.onChange();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Page title="Account Lifecycle" topBar={<CompanyTopbar />}>
      <Filter
        module="paid"
        menu={
          lifeCycles?.length > 0 && (
            <Flex alignItems="center">
              <Text variant="h3" mr={3} color="muted">
                Lifecycle:
              </Text>
              <StageMenuSelect
                moduleType="Paid"
                selected={!filters?.planId}
                onChange={(name, plan) => {
                  updateFilter("paid.planId", plan?.id, plan?.name);
                }}
                value={filters?.planId}
              />

              {lifeCycles?.map((lc, i) => (
                <Flex key={i} alignItems="center">
                  <Button
                    variant="default"
                    mx={1}
                    bg={
                      filters?.lifeCycleStageId === lc.id
                        ? "brand"
                        : "brandHighlight"
                    }
                    color={
                      filters?.lifeCycleStageId === lc.id
                        ? "white"
                        : "brandLight"
                    }
                    ml={0}
                    mr={1}
                    py={0}
                    my={0}
                    key={lc.id}
                    onClick={() => {
                      if (filters?.lifeCycleStageId === lc.id) {
                        updateFilter(
                          "paid.lifeCycleStageId",
                          undefined,
                          undefined
                        );
                      } else {
                        updateFilter("paid.lifeCycleStageId", lc.id, lc.name);
                      }
                    }}
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      height: 30,
                      marginLeft: 30,
                      marginRight: 30,
                      paddingLeft: i === 0 ? 12 : 30,
                      paddingRight: i === lifeCycles.length - 1 ? 20 : 0,
                      position: "relative",
                      borderRadius: 0,
                      borderTopRightRadius:
                        i === lifeCycles.length - 1 ? 99 : 0,
                      borderBottomRightRadius:
                        i === lifeCycles.length - 1 ? 99 : 0,
                      "&:before": {
                        // triangle hover color
                        borderLeft: i === 0 ? 0 : `15px solid white`,
                        // the triangle
                        content: `''`,
                        position: "absolute",
                        borderBottom: "15px solid transparent",
                        borderTop: "15px solid transparent",
                        height: 0,
                        width: 0,
                        marginTop: 0,
                        left: 0,
                        top: 0,
                        zIndex: 1,
                      },
                      "&:after": {
                        // triangle hover color
                        borderLeft:
                          i < lifeCycles.length - 1
                            ? `15px solid ${
                                filters?.lifeCycleStageId === lc.id
                                  ? theme.colors.brand
                                  : theme.colors.brandHighlight
                              }`
                            : 0,
                        // the triangle
                        content: `''`,
                        position: "absolute",
                        borderBottom: "15px solid transparent",
                        borderTop: "15px solid transparent",
                        height: 0,
                        width: 0,
                        marginRight: -15,
                        marginTop: 0,
                        top: 0,
                        right: 0,
                        zIndex: 2,
                      },
                      "&:hover": {
                        color: "white",
                        backgroundColor: "brandLight",
                        "&:after": {
                          // triangle hover
                          borderLeft:
                            i < lifeCycles.length - 1
                              ? `15px solid ${theme.colors.brandLight}`
                              : 0,
                        },
                      },
                    }}
                  >
                    {lc.name}
                  </Button>
                </Flex>
              ))}
            </Flex>
          )
        }
        onChange={() => setTriggerRefresh(new Date())}
        typeName={MODEL_TYPES.Company.name}
      >
        <Flex flex={1} p={4} pt={0} flexDirection="column">
          <Flex py={2} alignItems="center" justifyContent="flex-start">
            <Flex px={3} alignItems="center">
              <DonutGraph
                data={healthData}
                width={120}
                noLegend
                value={
                  <Flex
                    color="gray.500"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <HealthIcon />
                    <Label>
                      <Text fontSize="xxs">Health</Text>
                    </Label>
                  </Flex>
                }
              />
            </Flex>
            <Box mx={2} width={3 / 3}>
              <Flex>
                <Flex flex={1}>
                  <SimpleStatCard
                    value={numeral(dashboard?.totalMrr).format("0.00a")}
                    label="Total MRR"
                    icon={<Text>$</Text>}
                  />
                </Flex>
                <Flex flex={1}>
                  <SimpleStatCard
                    value={dashboard?.totalCount}
                    label="Total Accounts"
                    icon={<Text>#</Text>}
                  />
                </Flex>
                <Flex flex={1}>
                  <SimpleStatCard
                    value={stageCompleteCount}
                    label="Journey Complete"
                    icon={<TickIcon />}
                  />
                </Flex>
                <Flex flex={1}>
                  <SimpleStatCard
                    value={overdueCount}
                    label="Journey Overdue"
                    icon={<WarningIcon />}
                  />
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Flex mt={3} flex={1} flexDirection="column">
            <CompaniesList
              data={companies}
              error={error}
              loading={loading}
              onSort={handleSort}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRows}
              refetch={() => {
                dashboardRefetch({
                  variables: {
                    searchFilters: {
                      ...filters,
                    },
                  },
                });
                refetch({
                  variables: {
                    searchFilters: {
                      ...filters,
                    },
                  },
                });
              }}
              module="paid"
            />
          </Flex>
        </Flex>
      </Filter>
    </Page>
  );
}
Companies.propTypes = {
  onChange: PropTypes.func,
};

export default Companies;
