import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Page from "Components/Page";
import { Flex, Button, Text, Image, Box } from "rebass";
import Sidebar from "./ProductSidebar";
import { getProducts } from "hooks/getProducts";
import { useMutation } from "@apollo/react-hooks";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import { getSearchFilter } from "hooks/getSearchFilter";
import AdminTopbar from "./AdminTopbar";
import { getProduct } from "hooks/getProduct";
import { useTheme } from "emotion-theming";

function ProductSettings(props) {
  const theme = useTheme();
  const productsQuery = getProducts();
  const { data: filters } = getSearchFilter();
  const [product, setProduct] = useState();
  const products = productsQuery?.data?.products || [];

  useEffect(() => {
    if (!product && products.length > 0 && filters) {
      const p = products.find((x) => x.id === filters.filters?.productId);
      setProduct(p);
    }
  }, [filters]);

  return (
    <Page title="Product Settings" topBar={<AdminTopbar />}>
      <Flex
        flex={1}
        w="100%"
        justifyContent="center"
        style={{
          color: "text",
        }}
      >
        <Flex
          justifyContent="flex-end"
          sx={{
            borderRightColor: "accent",
            borderRightWidth: 1,
            borderRightStyle: "solid",
          }}
        >
          <Sidebar />
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Flex bg="white" flex={1}>
            {props.children}
          </Flex>
        </Flex>
      </Flex>
    </Page>
  );
}

export default withRouter(ProductSettings);
