import { useQuery } from "@apollo/react-hooks";
import LifeCycleQuery from "../graphql/queries/LifeCycles.gql";

export function getLifeCycles(statusId) {
  const query = useQuery(LifeCycleQuery, {
    variables: { statusId: statusId },
  });
  return {
    ...query,
  };
}
