import React from "react";
import PropTypes from "prop-types";
import { Button, Text, Flex } from "rebass";

const DefaultButton = ({ leftIcon, rightIcon, onClick, children, bg, sx }) => {
  return (
    <Button variant="ghost" bg={bg} onClick={onClick} sx={sx}>
      <Flex alignItems="center" justifyContent="center">
        {leftIcon && leftIcon}
        <Text ml={leftIcon && 1} mr={rightIcon && 2}>
          {children}
        </Text>
        {rightIcon && rightIcon}
      </Flex>
    </Button>
  );
};

DefaultButton.propTypes = {
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
DefaultButton.defaultProps = {
  leftIcon: undefined,
  rightIcon: undefined,
};

export default DefaultButton;
