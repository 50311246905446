import React, { useEffect } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Flex, Button, Text, Box, Link } from "rebass";
import { getIntegrations } from "hooks/getIntegrations";
import Loader from "Components/Common/Loader";
import { HelpIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { IntegrationIcon } from "../../../Common/Icons";

function Integrations(props) {
  const { id } = useParams();
  const history = useHistory();
  const { loading, data } = getIntegrations();

  return (
    <>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={1} justifyContent="space-between">
            <Text variant="h2">Integrations</Text>
            <PrimaryButton
              onClick={() => {
                history.push("/settings/product/integrations/new");
              }}
            >
              <PlusIcon />
            </PrimaryButton>
          </Flex>
          {data &&
            data.integrations?.map((api) => (
              <Flex
                key={api.id}
                variant="box"
                bg={api.id === id ? "brandHighlight" : "white"}
                sx={{
                  cursor: "pointer",
                  borderColor: api.id === id ? "brandLight" : "accent",
                }}
                p={2}
                my={2}
                flexDirection="column"
                onClick={() =>
                  history.push(`/settings/product/integrations/${api.id}`)
                }
              >
                <Flex alignItems="center">
                  {/*{apiOptions.find((a) => a.value == api.apiType)?.icon}*/}
                  <Flex flexDirection={"column"} mx={2}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <IntegrationIcon
                        logoUrl={api.integrationDefinition?.icon}
                      />
                      <Box mx={2}>
                        <Flex>
                          <Text variant="h4">{api.name}</Text>
                          {api.active && (
                            <Flex
                              variant="badge"
                              bg="green"
                              color="white"
                              alignItems="center"
                              mx={1}
                            >
                              active
                            </Flex>
                          )}
                        </Flex>
                        <Text variant="muted">
                          {api.integrationAuthentication?.name}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          {(!data || data.integrations.length === 0) && (
            <Flex p={2} my={2} flexDirection="column">
              <Text variant="muted">
                Connect an app and lets start integrating your product today.
              </Text>
              <Link href="#" my={2}>
                <Flex alignItems="center">
                  <HelpIcon /> <Text mx={2}>Help</Text>
                </Flex>
              </Link>
            </Flex>
          )}
          {loading && <Loader />}
        </Flex>
      </Flex>
    </>
  );
}

export default withRouter(Integrations);
