import React from "react";
import ReactTooltip from "react-tooltip";
import { Flex, Image, Link, Text } from "rebass";
import CopyableText from "../Common/CopyableText";
import Label from "../Common/Forms/Label";

function BccAddress({ address }) {
  return address ? (
    <Flex mt={3} flexDirection="column">
      <Label>BCC ADDRESS</Label>
      <ReactTooltip
        id={"bccAddress"}
        place="top"
        effect="solid"
        getContent={(dataTip) => dataTip}
      />
      <Flex alignItems={"center"} data-for={"bccAddress"} data-tip={address}>
        <Link href={`mailto:${address}`}>
          <Text
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 250,
            }}
          >
            {address}
          </Text>
        </Link>
        <CopyableText buttonOnly>{address}</CopyableText>
      </Flex>
    </Flex>
  ) : null;
}

export default BccAddress;
