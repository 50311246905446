import React, { useState, useEffect } from "react";
import { withRouter, useParams, useHistory, Prompt } from "react-router-dom";
import { Flex, Text, Box, Button, Image } from "rebass";
import PropTypes from "prop-types";
import "react-tabs/style/react-tabs.css";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FieldArray, Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import ShowError, { ErrorParams } from "Components/Common/Notifications/Error";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import ShowSuccess, {
  SuccessParams,
} from "Components/Common/Notifications/Success";
import { getLifeCycles } from "hooks/getLifeCycles";
import { deepOmit } from "consts";
import { SaveIcon } from "Components/Common/Icons";
import { useTheme } from "emotion-theming";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import "react-contexify/dist/ReactContexify.min.css";
import WorkflowsQuery from "../../../../graphql/queries/Workflows.gql";
import PlansQuery from "../../../../graphql/queries/Plans.gql";
import PlanMutation from "../../../../graphql/mutations/Plan.gql";
import PlanDeleteMutation from "../../../../graphql/mutations/PlanDelete.gql";
import PlanQuery from "../../../../graphql/queries/Plan.gql";
import {
  PlaybookIcon,
  PlusIcon,
  TickIcon,
  TrashIcon,
  WorkflowIcon,
} from "../../../Common/Icons";
import GhostButton from "../../../Common/Buttons/GhostButton";
import SimpleStatCard from "../../../Dashboard/Widgets/SimpleStatCard";
import Modal from "Components/Common/Modal";
import LibrarySearch from "./LibrarySearch";
import { RiCheckboxBlankFill } from "react-icons/ri";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import { getPlanTypes } from "../../../../hooks/getPlanTypes";
import PlanTypeForm from "../PlanTypes/PlanTypesForm";

const _ = require("lodash");

function PlanForm(props) {
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const theme = useTheme();

  const [lifeCycleStageId, setLifeCycleStageId] = useState();

  const { data: lcData, loading: lcLoading } = getLifeCycles();
  const { data: accountTypes } = getPlanTypes();

  const planTypes = accountTypes?.planTypes.map((o) => {
    return {
      value: o.id,
      label: `${o.name} (${o.companyStatus?.name})`,
      statusId: o.companyStatusId,
    };
  });
  const statuses = lcData?.lifeCycles
    ?.filter((l) => l.name !== "Churned" || l.name !== "Lost")
    .map((s) => {
      return { value: s.statusId, label: s.name, ...s };
    });
  const { data: workflowData } = useQuery(WorkflowsQuery, {
    fetchPolicy: "network-only",
  });

  const workflows = workflowData && workflowData.workflows;
  const [selectedAction, setSelectedAction] = useState({
    stepIndex: -1,
    actionIndex: -1,
  });
  const { data } = useQuery(PlanQuery, {
    variables: { id },
    skip: !id || id === "new",
  });
  const [save] = useMutation(PlanMutation, {
    refetchQueries: [{ query: PlansQuery }],
  });
  const [destroy] = useMutation(PlanDeleteMutation, {
    refetchQueries: [{ query: PlansQuery }],
  });

  useEffect(() => {
    if (lcData && data) {
      const status = lcData?.lifeCycles?.find(
        (l) => l.statusId === data?.plan?.planType?.companyStatus?.id
      );
      const selectedId = _.first(status?.stages)?.id;
      setLifeCycleStageId(selectedId);
    }
  }, [lcData, data]);

  let plan = data?.plan && {
    ...data?.plan,
    statusId: data?.plan?.planType?.companyStatus?.id,
    live: data?.plan?.live || false,
  };
  plan = data?.plan && deepOmit(plan, "__typename");

  const savePlan = (values, actions) => {
    save({
      variables: {
        ...values,
        id: values.id ? values.id : null,
        live: values.live ? values.live : false,
        default: values.default ? values.default : false,
        statusId: values.statusId,
      },
    }).then(
      (response) => {
        const result = response.data?.plan;
        if (result?.errors) {
          actions && actions.setErrors(result?.errors);
          addToast(
            <ShowError message={result?.errors && result?.errors[0]} />,
            ErrorParams
          );
        } else {
          actions && actions.resetForm(result?.plan);
          addToast(<ShowSuccess message="Saved!" />, SuccessParams);
          history.push("/settings/product/plans");
        }
      },
      (error) => {
        console.log(error);
        addToast(<ShowError message="Save error!" />, ErrorParams);
      }
    );
  };
  return (
    <Formik
      enableReinitialize
      initialValues={
        plan || {
          name: "",
          default: false,
          live: false,
          steps: [],
        }
      }
      autoComplete={false}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        planTypeId: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        savePlan(values, actions);
      }}
    >
      {(formProps) => {
        const {
          values,
          handleSubmit,
          resetForm,
          setFieldValue,
          handleBlur,
          errors,
          dirty,
        } = formProps;

        const lifeCycles =
          lcData?.lifeCycles?.find((l) => l.statusId === values.statusId)
            ?.stages || [];

        return (
          <Flex flex={1} w="100%" flexDirection="column">
            <Prompt
              when={dirty}
              message="You have unsaved changes. Are you sure you want to leave?"
            />
            <Flex w="100%" flexDirection="column">
              <Flex flexDirection="column" w="100%">
                <Flex w="100%" justifyContent="space-between">
                  <Text variant="h2">{values.name}</Text>
                  <Flex justifyContent="flex-end" alignItems={"center"}>
                    {values.inUse > 0 ? (
                      <Text variant="muted" mx={3}>
                        In use {values.inUse} accounts
                      </Text>
                    ) : (
                      <DeleteButton
                        callback={destroy}
                        refetch={() => {}}
                        onCancel={() => history.goBack()}
                        id={id}
                        name="Plan"
                      />
                    )}
                    <SecondaryButton
                      leftIcon={<SaveIcon />}
                      onClick={handleSubmit}
                    >
                      Save
                    </SecondaryButton>
                    <Box mx={2} />
                    {values.id && !values.live && (
                      <PrimaryButton
                        leftIcon={<TickIcon />}
                        onClick={() => {
                          setFieldValue("live", true);
                          savePlan({ ...values, live: true }, undefined);
                        }}
                      >
                        Publish
                      </PrimaryButton>
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Flex w="100%" flex={1}>
                <Flex flex={1} px={3} flexDirection="column">
                  <CustomFormGroup
                    placeholder="Name"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.name && errors.name}
                  />
                  <Flex mt={3}>
                    <CustomFormGroup
                      type="list"
                      placeholder="Tier Type"
                      label="Tier Type"
                      name="planTypeId"
                      value={values.planTypeId}
                      options={planTypes}
                      onChange={(name, value) => {
                        setFieldValue(name, value?.value);
                        setFieldValue("statusId", value?.statusId);
                      }}
                      onBlur={handleBlur}
                      helperText={errors.planTypeId && errors.planTypeId}
                    />
                    <EditTiers />
                    <Box mx={4} />
                    <Box>
                      <CustomFormGroup
                        type="switch"
                        placeholder="Default"
                        label="Default"
                        name="default"
                        value={values.default}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        helperText={errors.default && errors.default}
                      />
                    </Box>
                  </Flex>
                  <Text my={3} variant="h3">
                    Journey
                  </Text>
                  <Flex alignItems="flex-end" mb={3} flexWrap="wrap">
                    <Flex mt={2}>
                      {statuses
                        ?.filter((lc) => values.statusId === lc.value)
                        .map((lc, i) => (
                          <Button
                            key={lc.id}
                            variant="default"
                            mx={1}
                            bg={
                              values.statusId === lc.value
                                ? "brand"
                                : "brandHighlight"
                            }
                            color={
                              values.statusId === lc.value
                                ? "white"
                                : "brandLight"
                            }
                            ml={0}
                            py={0}
                            my={0}
                            sx={{
                              cursor: "pointer",
                              display: "inline-block",
                              height: 30,
                              marginLeft: 30,
                              marginRight: 30,
                              position: "relative",
                              borderRadius: 0,
                              borderTopRightRadius: 99,
                              borderBottomRightRadius: 99,
                              borderTopLeftRadius: 99,
                              borderBottomLeftRadius: 99,
                            }}
                          >
                            {lc.label}
                          </Button>
                        ))}
                    </Flex>
                    <Flex mt={2} mx={2}>
                      {lifeCycles?.map((lc, i) => (
                        <Button
                          key={lc.id}
                          variant="default"
                          mx={1}
                          bg={
                            lifeCycleStageId === lc.id
                              ? "brand"
                              : "brandHighlight"
                          }
                          color={
                            lifeCycleStageId === lc.id ? "white" : "brandLight"
                          }
                          ml={0}
                          mr={1}
                          py={0}
                          my={0}
                          onClick={() => setLifeCycleStageId(lc.id)}
                          sx={{
                            cursor: "pointer",
                            display: "inline-block",
                            height: 30,
                            marginLeft: 30,
                            marginRight: 30,
                            paddingLeft: i === 0 ? 12 : 30,
                            paddingRight: i === lifeCycles.length - 1 ? 20 : 0,
                            position: "relative",
                            borderRadius: 0,
                            borderTopRightRadius:
                              i === lifeCycles.length - 1 ? 99 : 0,
                            borderBottomRightRadius:
                              i === lifeCycles.length - 1 ? 99 : 0,
                            borderTopLeftRadius: i === 0 ? 99 : 0,
                            borderBottomLeftRadius: i === 0 ? 99 : 0,
                            "&:before": {
                              // triangle hover color
                              borderLeft: i === 0 ? 0 : `15px solid white`,
                              // the triangle
                              content: `''`,
                              position: "absolute",
                              borderBottom: "15px solid transparent",
                              borderTop: "15px solid transparent",
                              height: 0,
                              width: 0,
                              marginTop: 0,
                              left: 0,
                              top: 0,
                              zIndex: 1,
                            },
                            "&:after": {
                              // triangle hover color
                              borderLeft:
                                i < lifeCycles.length - 1
                                  ? `15px solid ${
                                      lifeCycleStageId === lc.id
                                        ? theme.colors.brand
                                        : theme.colors.brandHighlight
                                    }`
                                  : 0,
                              // the triangle
                              content: `''`,
                              position: "absolute",
                              borderBottom: "15px solid transparent",
                              borderTop: "15px solid transparent",
                              height: 0,
                              width: 0,
                              marginRight: -15,
                              marginTop: 0,
                              top: 0,
                              right: 0,
                              zIndex: 2,
                            },
                            "&:hover": {
                              color: "white",
                              backgroundColor: "brandLight",
                              "&:after": {
                                // triangle hover
                                borderLeft:
                                  i < lifeCycles.length - 1
                                    ? `15px solid ${theme.colors.brandLight}`
                                    : 0,
                              },
                            },
                          }}
                        >
                          <Flex alignItems="center">
                            {lc.name}
                            <Flex
                              fontSize="xxs"
                              mx={1}
                              bg="white"
                              color="brandLight"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ borderRadius: 99, width: 25, height: 25 }}
                            >
                              {values.steps.filter(
                                (s) => s.lifeCycleStageId === lc.id
                              )?.length || 0}
                            </Flex>
                          </Flex>
                        </Button>
                      ))}
                    </Flex>
                  </Flex>
                  {lifeCycleStageId && (
                    <FieldArray
                      name="steps"
                      render={(arrayHelpers) => {
                        const steps =
                          values.steps &&
                          values.steps.length > 0 &&
                          values.steps.filter(
                            (s) => s.lifeCycleStageId === lifeCycleStageId
                          );

                        const maxStartDay = _.maxBy(steps, "startDay");
                        const maxDuration = maxStartDay?.durationDays;
                        const fullDuration =
                          maxStartDay?.startDay + maxDuration || 0;

                        const totalDaysDisplay =
                          fullDuration < 14 ? 14 : fullDuration;

                        return (
                          <Flex>
                            <Flex flex={1} flexDirection="column">
                              <Flex>
                                <SimpleStatCard
                                  value={fullDuration}
                                  label="Duration"
                                />
                                <SimpleStatCard
                                  value={steps.length || 0}
                                  label=" Steps"
                                />
                              </Flex>
                              <Flex flex={1} justifyContent="flex-end" mt={3}>
                                <Text variant="label" width={100}>
                                  Start Day
                                </Text>
                                <Flex width={1 / 2}>
                                  {[...Array(totalDaysDisplay).keys()].map(
                                    (i) => (
                                      <Flex
                                        key={i}
                                        width={1 / totalDaysDisplay}
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Text fontSize="xxs" variant="muted">
                                          {totalDaysDisplay < 20 && i + 1}
                                        </Text>
                                      </Flex>
                                    )
                                  )}
                                </Flex>
                              </Flex>
                              {steps &&
                                steps
                                  .filter((step) => values.live || step.active)
                                  .map((step, index) => {
                                    return (
                                      <Flex
                                        flex={1}
                                        key={`${step.id}-${index}`}
                                        alignItems="center"
                                      >
                                        <Flex
                                          flex={1}
                                          flexDirection="column"
                                          justifyContent="flex-end"
                                        >
                                          <PlanSteps
                                            key={`step-${index}`}
                                            index={index}
                                            step={step}
                                            workflows={workflows}
                                            onEdit={(
                                              stepIndex,
                                              actionIndex
                                            ) => {
                                              setSelectedAction({
                                                stepIndex: index,
                                                actionIndex: 0,
                                              });
                                            }}
                                            onDelete={(idx) => {
                                              arrayHelpers.remove(idx);
                                            }}
                                            days={totalDaysDisplay}
                                            {...formProps}
                                          />
                                        </Flex>
                                      </Flex>
                                    );
                                  })}
                              {!values.live && (
                                <AddButton
                                  onClick={() => {
                                    arrayHelpers.push({
                                      lifeCycleStageId: lifeCycleStageId,
                                      workflowId: undefined,
                                      durationDays: 1,
                                      startDay: 1,
                                      active: true,
                                    });
                                    setSelectedAction({
                                      stepIndex: 0,
                                      actionIndex: 0,
                                    });
                                  }}
                                />
                              )}
                            </Flex>
                          </Flex>
                        );
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default withRouter(PlanForm);

const PlanSteps = (props) => {
  const {
    step: field,
    values,
    errors,
    setFieldValue,
    onDelete,
    handleBlur,
    workflows,
    days,
  } = props;

  const readOnly = !!values.live;
  const [open, setOpen] = useState(false);
  const index = values.steps.indexOf(field);
  const fieldName = `steps[${index}]`;

  const workflow = workflows?.find((w) => w.id === field.workflowId);

  const startDay = field.startDay - 1;
  const endDay = startDay + workflow?.totalDays - 1;

  return (
    <Flex flex={1} width="100%" alignItems="center">
      <Box mx={1}>
        {values?.live ? (
          <CustomFormGroup
            type="switch"
            placeholder="active"
            label="Active"
            name={`${fieldName}.active`}
            value={field?.active}
            onChange={setFieldValue}
            onBlur={handleBlur}
          />
        ) : (
          <GhostButton
            onClick={() => {
              if (!field?.id) {
                onDelete(index);
              } else {
                setFieldValue(`${fieldName}.active`, false);
              }
            }}
          >
            <TrashIcon />
          </GhostButton>
        )}
      </Box>
      <Flex
        p={2}
        alignItems="center"
        flex={1}
        onClick={() => {
          if (!readOnly) setOpen(true);
        }}
        sx={{
          cursor: readOnly ? "" : "pointer",
          height: "100%",
          width: "100%",
          borderStyle: !workflow ? "dashed" : "solid",
          borderColor: "accent",
          borderWidth: 1,
          backgroundColor: readOnly ? "gray.100" : "",
          borderRadius: "medium",
        }}
      >
        <Flex>
          {workflow ? (
            <Flex mx={2}>
              <Box color="brandLight">
                {workflow.workflowType === "Playbook" && <PlaybookIcon />}
                {workflow.workflowType === "Workflow" && <WorkflowIcon />}
              </Box>
              <Box mx={2}>
                <Text fontSize="xs">{workflow.name}</Text>
              </Box>
            </Flex>
          ) : (
            <Text variant="muted">Click to add item</Text>
          )}
        </Flex>
      </Flex>
      <Box px={2}>
        <CustomFormGroup
          noMargin
          width="90px"
          name={`${fieldName}.startDay`}
          readOnly={readOnly}
          value={field?.startDay}
          onChange={(name, value) => {
            setFieldValue(name, Number(value));
          }}
        />
      </Box>
      <Flex height="100%" width={1 / 2}>
        {[...Array(days).keys()].map((i) => (
          <Flex
            key={i}
            height={60}
            bg={i % 2 == 0 && "brandHighlight"}
            width={1 / days}
            alignItems="center"
          >
            {workflow?.totalDays === 0 && startDay === i ? (
              <Flex
                width="100%"
                alignItems="center"
                color="brandLight"
                sx={{ transform: "rotate(45deg)" }}
              >
                <RiCheckboxBlankFill size="100%" />
              </Flex>
            ) : (
              <Box
                width="100%"
                height={35}
                bg={i >= startDay && i <= endDay && "brandLight"}
              />
            )}
          </Flex>
        ))}
      </Flex>
      <Modal
        width={900}
        title="Automation Library"
        open={open}
        onDismiss={() => {
          setOpen(false);
        }}
      >
        <Flex mx={4}>
          <LibrarySearch
            selectedId={field.workflowId}
            onChange={(item) => {
              setFieldValue(`${fieldName}.durationDays`, item.totalDays);
              setFieldValue(`${fieldName}.workflowId`, item.id);
              setFieldValue(`${fieldName}.active`, true);
              setOpen(false);
            }}
          />
        </Flex>
      </Modal>
    </Flex>
  );
};
PlanSteps.propTypes = {
  step: PropTypes.object,
  values: PropTypes.array,
  errors: PropTypes.array,
  workflows: PropTypes.array,
  days: PropTypes.number,
  onDelete: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
};
const AddButton = (props) => {
  return (
    <Flex alignItems="center" mt={2} ml={4} pl={3}>
      <PrimaryButton
        onClick={() => {
          props.onClick();
        }}
        leftIcon={<PlusIcon />}
      >
        Add Step
      </PrimaryButton>
    </Flex>
  );
};
AddButton.propTypes = {
  onClick: PropTypes.func,
};

const EditTiers = (props) => {
  const [open, setOpen] = useState(false);

  return !open ? (
    <GhostButton onClick={() => setOpen(!open)}>edit</GhostButton>
  ) : (
    <Modal
      width={900}
      title="Product Tiers"
      open={open}
      onDismiss={() => {
        setOpen(false);
      }}
    >
      <Flex mx={4}>
        <PlanTypeForm onComplete={() => setOpen(false)} />
      </Flex>
    </Modal>
  );
};
