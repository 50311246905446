import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Box, Button, Link } from "rebass";
import Settings from "../ProductSettings";
import { getHealthscores } from "hooks/getHealthscores";
import Table from "Components/Common/Table";
import moment from "moment";
import { HelpIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import { SettingIcon } from "../../../Common/Icons";

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.segment.name,
    sortable: true,
    cell: (row) => <Text data-tag="allowRowEvents">{row.segment?.name}</Text>,
  },
  {
    name: "Created",
    selector: (row) => row.createdAt,
    sortable: true,
    cell: (row) => (
      <Text data-tag="allowRowEvents">
        {moment(row.createdAt).format("DD/MM/YYYY")}
      </Text>
    ),
  },
];
function Healthscore(props) {
  const history = useHistory();
  const { data, loading } = getHealthscores();
  return (
    <Settings>
      <Box bg="white" my={2} mr={4} p={2} width="100%">
        <Flex flexDirection="column" width="100%" p={2}>
          <Flex justifyContent="space-between">
            <Text variant="h2">Health Score Configuration</Text>
            <PrimaryButton
              onClick={() => {
                history.push("/settings/product/healthscores/new");
              }}
            >
              New Healthscore
            </PrimaryButton>
          </Flex>
          <Text>
            A health score is a calculated number that measures the current
            status of your relationship with your account. Accounts will default
            to the first health classification for which it is eligible.
          </Text>
          <Flex flex={1} my={3}>
            {data?.healthscores.map((row) => {
              return (
                <Flex
                  key={row.id}
                  width={1 / 4}
                  p={3}
                  m={3}
                  flexDirection="column"
                  variant="box"
                  sx={{
                    borderRadius: 10,
                  }}
                >
                  <Flex flexDirection="column">
                    <Text variant="h3">{row.name}</Text>
                  </Flex>
                  <Flex alignItems="center" ml={1} mb={2} flexWrap="wrap">
                    <Text variant="muted">Configuration for</Text>
                    <Box bg="brandHighlight" m={1} sx={{ borderRadius: 10 }}>
                      <Flex
                        alignItems="center"
                        justfiyContent="center"
                        flexDirection="column"
                        p={1}
                      >
                        <Text mx={1} color="brandLight" variant="muted">
                          {row.segment?.name}
                        </Text>
                      </Flex>
                    </Box>
                    <Text variant="muted">accounts.</Text>
                  </Flex>

                  <SecondaryButton
                    onClick={() => {
                      history.push(`/settings/product/healthscores/${row.id}`);
                    }}
                    leftIcon={<SettingIcon />}
                  >
                    Setup
                  </SecondaryButton>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </Settings>
  );
}

export default Healthscore;
