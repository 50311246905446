import React, { useState } from "react";
import { Flex, Link, Box, Text } from "rebass";

function StatusBadge({ status, lifecycle }) {
  return (
    <Box
      variant="box"
      p={1}
      fontSize="xs"
      sx={{ borderRadius: 99, maxWidth: "200px" }}
    >
      <Flex alignItems="center" justfiyContent="center">
        <Box
          p={1}
          sx={{ borderRadius: 99 }}
          bg={status?.color || "red"}
          color="white"
        >
          <Flex alignItems="center">
            <Text mx={1} color="white">
              {status?.name}
            </Text>
          </Flex>
        </Box>
        {lifecycle && (
          <Box>
            <Flex alignItems="center">
              <Text mx={2} color="text">
                {lifecycle?.name}
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>
    </Box>
  );
}

export default StatusBadge;
