import React, { useCallback, useEffect, useRef } from "react";
import { default as ReactSelect } from "react-select";
import { useTheme } from "emotion-theming";
import { Text, Flex } from "rebass";
import Label from "./Label";
import PropTypes from "prop-types";

const Select = (props) => {
  const theme = useTheme();
  const selectRef = useRef();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: theme.fontSizes.xs,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: theme.colors.accent,
      borderWidth: "thin",
      backgroundColor: "white",
      boxShadow: "none",
      padding: 0,
      paddingLeft: "0px",
      width: props.width ? props.width : "300px",
      borderRadius: theme.radii.medium,
      fontFamily: theme.fonts.body,
      fontSize: theme.fontSizes.xs,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const handleChange = (value) => {
    // this is going to call setFieldValue and manually update values
    props.onChange(props.name, value);
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched
    if (props.onBlur) {
      props.onBlur(props.name, true);
    }
  };

  // Feature of focusing selected option when menu is getting opened
  useEffect(() => {
    // Calling an initial prop if it has been passed
    // Getting a selected option
    // const option = props.defaultValue;
    // if (option) {
    //   setTimeout(() => {
    //     console.log(selectRef.current.select.getFocusableOptions());
    //     if (selectRef.current?.select) {
    //       // Focusing selected option only if it exists
    //       selectRef.current.select.scrollToFocusedOptionOnUpdate = true;
    //       selectRef.current.select.menuShouldScrollIntoView = true;
    //       selectRef.current.select.inputIsHiddenAfterUpdate = false;
    //       selectRef.current.select.setState({
    //         focusedValue: option.value,
    //         focusedOption: option,
    //       });
    //       console.log(selectRef.current.select);
    //     }
    //   });
    // }
  }, [props.defaultValue]);

  const isClearable = props.clearable !== undefined ? props.clearable : true;
  return (
    <Flex flex={1} flexDirection="column">
      {props.label && <Label name={props.name}>{props.label}</Label>}

      {props.readOnly && <Text>{props.value?.label}</Text>}
      {!props.readOnly && (
        <ReactSelect
          ref={selectRef}
          isMulti={props.isMulti | false}
          value={props.value}
          onChange={handleChange}
          onBlur={handleBlur}
          name={props.name}
          placeholder={props.placeholder}
          styles={customStyles}
          options={props.options}
          menuPortalTarget={document.body}
          captureMenuScroll
          isClearable={isClearable}
          menuPosition="fixed"
          isDisabled={props.disabled}
          noOptionsMessage={props.noOptionsMessage}
          menuShouldScrollIntoView
        />
      )}
      {props.helperText && (
        <Text m={0} px={1} variant="muted" color="red" htmlFor={props.name}>
          {props.helperText}
        </Text>
      )}
    </Flex>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  noOptionsMessage: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  isMulti: PropTypes.bool,
  width: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Select;
