import React from "react";
import { withRouter, Link as RouterLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Flex, Button, Text, Box, Link, Image } from "rebass";
import CompanyEndUserQuery from "../../graphql/queries/CompanyEndUsers.gql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useTheme } from "emotion-theming";
import Table from "Components/Common/Table";
import { Sparklines, SparklinesLine } from "react-sparklines";
import moment from "moment";
import SatIcon from "../Dashboard/Widgets/SatIcon";
import { MODEL_TYPES } from "../../consts";
import Tags from "../Common/Tags";
import AddEndUser from "./EndUser/AddEndUser";
import UserTypeBadge from "../Common/UserTypeBadge";

function EndUsers(props) {
  const theme = useTheme();
  const { id } = props;
  const history = useHistory();

  const { loading, data, refetch } = useQuery(CompanyEndUserQuery, {
    variables: {
      companyId: props.id,
    },
  });

  const dataTable = data?.companyEndUsers;
  const columns = [
    {
      name: "",
      selector: (row) => row.nps,
      sortable: true,
      width: "90px",
      cell: (row) => {
        return (
          <Text data-tag="allowRowEvents">
            <SatIcon scores={row.currentScores} simple />
          </Text>
        );
      },
    },
    {
      name: "Category",
      selector: (row) => row.endUserCategoryId,
      sortable: true,
      width: "120px",
      cell: (row) => {
        return (
          <UserTypeBadge
            categoryId={row.endUserCategoryId}
            online={row.online}
          />
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
      cell: (row) => {
        return (
          <Flex alignItems={"center"}>
            <Box mx={2}>
              <Text data-tag="allowRowEvents">{`${row.firstName || ""} ${
                row.lastName || ""
              }`}</Text>
              <Tags value={row.tagList} />
            </Box>
          </Flex>
        );
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: (
        <Text data-tag="allowRowEvents">
          Avg time
          <Text>in App</Text>
        </Text>
      ),
      selector: (row) => row.avgTime,
      sortable: true,
      width: "80px",
      cell: (row) => {
        return (
          <Text data-tag="allowRowEvents" textAlign={"center"} width="100%">
            {row.avgTime}
            <Text fontSize="xxs" variant="muted" textAlign={"center"}>
              minutes
            </Text>
          </Text>
        );
      },
    },
    {
      name: (
        <Text data-tag="allowRowEvents">
          Avg
          <Text>Events</Text>
        </Text>
      ),
      selector: (row) => row.avgEvents,
      sortable: true,
      width: "80px",
      cell: (row) => {
        return (
          <Text data-tag="allowRowEvents" textAlign={"center"} width="100%">
            {row.avgEvents}
          </Text>
        );
      },
    },
    {
      name: "Last Seen",
      selector: (row) => row.lastSeenAt,
      sortable: true,
      width: "100px",
      cell: (row) => {
        return row.lastSeenAt ? (
          <Box data-tag="allowRowEvents">
            <Text>{moment(row.lastSeenAt).format("DD/MM/YYYY")}</Text>
            <Text variant="muted">{moment(row.lastSeenAt).fromNow()}</Text>
          </Box>
        ) : (
          <Text data-tag="allowRowEvents" variant="muted">
            Not active
          </Text>
        );
      },
    },
    {
      name: "Recent Activity",
      selector: (row) => row.eventData,
      cell: (row) => {
        let d =
          row.eventData && row.eventData.length > 0 ? row.eventData : [0, 0, 0];
        d = d.length < 3 ? [0, 0].concat(d) : d;
        return (
          <Box data-tag="allowRowEvents" width={4 / 4}>
            <Sparklines data={d}>
              <SparklinesLine color={theme.colors.secondaryLight} />
            </Sparklines>
          </Box>
        );
      },
    },
  ];
  return (
    <Flex flexDirection="column" width="100%">
      <Flex justifyContent={"space-between"}>
        <Text variant="h2">Users</Text> <AddEndUser companyId={props.id} />
      </Flex>
      {!loading && dataTable?.length === 0 ? (
        <Flex
          p={4}
          flexDirection="column"
          bg="gray.100"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: "medium" }}
        >
          <Flex
            sx={{
              boxShadow: "large",
              borderRadius: "medium",
              maxWidth: "90%",
              height: "450px",
            }}
            bg="white"
          >
            <Image
              src={require("assets/images/UsersEmptyState.png")}
              sx={{ height: "400px", objectFit: "contain" }}
            />
          </Flex>
          <Box my={3} />
          <Text variant="muted">No users have been added yet.</Text>
        </Flex>
      ) : (
        <Table
          title=""
          defaultSortField={"lastSeenAt"}
          selectableRows={false}
          columns={columns}
          data={dataTable}
          loading={loading}
          onRowClicked={(p) => {
            const route = `${window.location.pathname.replace("/", "")}${
              MODEL_TYPES.EndUser.route
            }`;
            history.push(`/${route}/users/${p.id}`);
          }}
        />
      )}
    </Flex>
  );
}

EndUsers.propTypes = {
  id: PropTypes.string,
};
export default withRouter(EndUsers);
