import React from "react";
import { Box, Flex, Text, Link, Image } from "rebass";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import Table from "Components/Common/Table";
import HealthScoreIcon from "Components/Data/Company/HealthScoreIcon";
import moment from "moment";
import CompanyParentQuery from "../../../graphql/queries/CompanyParent.gql";

const _ = require("lodash");

const columns = [
  {
    name: "Health",
    selector: (row) => row.name,
    width: "80px",
    cell: (row) => {
      return (
        <HealthScoreIcon
          status={!row.disabled && { name: row.statusName }}
          simple
          disabled={row.disabled}
          data-tag="allowRowEvents"
          category={row.healthCategory}
        />
      );
    },
  },
  {
    name: "Account",
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => (
      <Text sx={{ color: !row.disabled ? "text" : "gray.600" }}>
        {row.name}
      </Text>
    ),
  },
  {
    name: "MRR",
    selector: (row) => row.revenue,
    sortable: true,
    right: true,
    cell: (row) => {
      return (
        !row.isParent &&
        !row.disabled &&
        `${Number(row.revenue || "0").toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`
      );
    },
  },
  {
    name: "Status",
    selector: (row) => row.statusName,
    sortable: true,
    cell: (row) =>
      !row.isParent && !row.disabled && <Text>{row.statusName}</Text>,
  },
  {
    name: "Lifecycle",
    selector: (row) => row.lifeCycleName,
    sortable: true,
    cell: (row) =>
      !row.isParent && !row.disabled && <Text>{row.lifeCycleName}</Text>,
  },
  {
    name: "Owner",
    selector: (row) => row.ownerName,
    sortable: true,
    cell: (row) =>
      !row.isParent && !row.disabled && <Text>{row.ownerName}</Text>,
  },
  {
    name: "Last Seen",
    selector: (row) => row.lastSeenAt,
    sortable: false,
    cell: (row) =>
      !row.isParent &&
      !row.disabled &&
      row.lastSeenAt && (
        <Text>{moment(row.lastSeenAt).format("DD MMM YYYY")}</Text>
      ),
  },
];

function CompanyHierarchy(props) {
  const history = useHistory();
  const { refetch, data, loading } = useQuery(CompanyParentQuery, {
    variables: { id: props.id },
  });

  const companyHierarchy = data?.companyHierarchy;

  const dataTable = [];
  companyHierarchy
    ?.filter((c) => !!c.parentId)
    .map((d) => {
      let company = { ...d };

      let total = 0;
      company.companyDetails &&
        company.companyDetails.map(
          (companyDetail) => (total += companyDetail.mrr)
        );
      dataTable.push({
        ...company,
        revenue: total,
      });
    });

  return (
    <Flex flexDirection="column" flex={1}>
      <Table
        title=""
        defaultSortField="name"
        columns={columns}
        data={dataTable}
        loading={loading}
        onRowClicked={(row) =>
          history.push(window.location.pathname.replace(props.id, row.id))
        }
        noDataComponent={
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            m={4}
          >
            <Image
              src={require("assets/images/AccountsEmpty.png")}
              width={80}
            />
            <Text my={2} variant="muted">
              No children assigned
            </Text>
          </Flex>
        }
      />
      <Flex flexGrow={2} />
    </Flex>
  );
}

export default CompanyHierarchy;
