import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { Flex, Text, Link, Box } from "rebass";
import { Input, Label } from "@rebass/forms";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import {
  CheckCircleIcon,
  CheckCircleOffIcon,
  CloseIcon,
  EditIcon,
  PlaybookIcon,
  RefreshIcon,
  SearchIcon,
  WorkflowIcon,
} from "../../../Common/Icons";
import WorkflowsQuery from "../../../../graphql/queries/Workflows.gql";
import TagsQuery from "../../../../graphql/queries/Tags.gql";
import MenuMultiSelect from "../../../Common/Forms/MenuMultiSelect";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import GhostButton from "../../../Common/Buttons/GhostButton";
import CustomFormGroup from "../../../Common/Forms/CustomFormGroup";
import Tags from "../../../Common/Tags";

import { Formik } from "formik";
import * as Yup from "yup";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";
function WorkflowSelectForm(props) {
  const { field, setFieldValue, fieldName, handleBlur, values } = props;

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  const [searchText, setSearchText] = useState(undefined);
  const [selectedTags, setSelectedTags] = useState([]);

  const playbook = true; //values.actionType === "playbook";
  const { data: workflowData, refetch } = useQuery(WorkflowsQuery, {
    variables: {
      searchText: searchText,
      tags: selectedTags,
    },
  });

  const { data: tags } = useQuery(TagsQuery, {
    variables: {
      modelType: "categories",
    },
  });

  const workflows = workflowData?.workflows;
  const title = playbook ? "Playbooks" : "Workflows";

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        workflowId: Yup.string().required("Required"),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        return (
          <>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <Flex justifyContent="flex-end" alignItems={"center"}>
              <Flex sx={{ position: "relative" }} alignItems="center" mx={2}>
                <Input
                  width="200px"
                  sx={{
                    zIndex: 1,
                  }}
                  placeholder=""
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                <Flex
                  sx={{
                    position: "absolute",
                    right: 4,
                    top: 0,
                    bottom: 0,
                    zIndex: 2,
                  }}
                  width={35}
                  alignItems="center"
                  justifyContent="center"
                  color="line"
                  p={2}
                  onClick={() => {
                    setSearchText("");
                  }}
                >
                  <CloseIcon size={20} />
                </Flex>
                <Flex
                  sx={{
                    position: "absolute",
                    right: 1,
                    top: 0,
                    bottom: 0,
                    zIndex: 2,
                  }}
                  width={35}
                  alignItems="center"
                  justifyContent="center"
                  color="brandLight"
                  p={2}
                >
                  <SearchIcon size={20} />
                </Flex>
              </Flex>
              <Flex alignItems="center">
                <Box m={2}>
                  <MenuMultiSelect
                    onChange={(types) => {
                      setSelectedTags(types);
                      refetch({
                        searchText: searchText,
                        tags: selectedTags,
                      });
                    }}
                    options={tags?.tags.map((o) => {
                      return { value: o, label: o };
                    })}
                    value={selectedTags}
                  />
                </Box>
                <SecondaryButton
                  onClick={() =>
                    refetch({
                      searchText: searchText,
                      tags: selectedTags,
                    })
                  }
                >
                  <RefreshIcon />
                </SecondaryButton>
              </Flex>
            </Flex>
            {(!workflows ||
              workflows?.filter((workflow) => values.workflowId !== workflow.id)
                .length === 0) && (
              <Flex flex={1} alignItems="center" p={3} justifyContent="center">
                <Flex
                  sx={{ maxWidth: 400 }}
                  p={2}
                  my={3}
                  flexDirection="column"
                >
                  <Text variant="muted" mb={3}>
                    No {title.toLowerCase()} have been found. {title} help you
                    to define a standard business process for certain acitvities
                    for your accounts.
                  </Text>
                </Flex>
              </Flex>
            )}
            {field.config.workflowId &&
              workflows
                ?.filter((workflow) => values.workflowId !== workflow.id)
                ?.filter((workflow) => field.config.workflowId === workflow.id)
                .map((trigger) => (
                  <Flex
                    key={trigger.id}
                    variant="box"
                    backgroundColor={"white"}
                    my={1}
                    p={3}
                    onClick={() => {
                      setFieldValue(`${defaultFieldName}.title`, trigger.name);
                      setFieldValue(
                        `${defaultFieldName}.workflowId`,
                        field.config.workflowId === trigger.id
                          ? undefined
                          : trigger.id
                      );
                    }}
                    flexDirection="column"
                  >
                    <Flex
                      flex={1}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Flex
                        flex={1}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Flex
                          width="60px"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <CustomFormGroup
                            value={trigger.enabled}
                            noMargin
                            type="switch"
                            label="ACTIVE"
                            onChange={() => {}}
                          />
                        </Flex>
                        <Flex flex={1} mx={2} flexDirection="column">
                          <Text variant="h4">{trigger.name}</Text>
                          <Text mt={1} variant="muted">
                            {trigger.description}
                          </Text>
                          <Flex>
                            <Tags value={trigger.tags} />
                          </Flex>
                        </Flex>
                        <Flex flex={1} justifyContent="flex-end">
                          <Flex width={1 / 3} flexDirection="column">
                            <Text textAlign="center" variant="label">
                              Total Days
                            </Text>
                            <Text textAlign="center" m={2}>
                              {trigger.totalDays || 0}
                            </Text>
                          </Flex>
                          <Flex width={1 / 3} flexDirection="column">
                            <Text textAlign="center" variant="label">
                              Total Steps
                            </Text>
                            <Text textAlign="center" m={2}>
                              {trigger.totalSteps || 0}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex flexDirection={"column"} ml={3}>
                        <Text textAlign="center" variant="label">
                          Actions
                        </Text>
                        <Flex>
                          <Box mx={2} />
                          <GhostButton
                            onClick={() => {
                              setFieldValue(
                                `${defaultFieldName}.title`,
                                trigger.name
                              );
                              setFieldValue(
                                `${defaultFieldName}.workflowId`,
                                field.config.workflowId === trigger.id
                                  ? undefined
                                  : trigger.id
                              );
                            }}
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              color="green"
                            >
                              <CheckCircleIcon size={20} />
                            </Flex>
                          </GhostButton>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex flexDirection="column">
                      {!trigger.enabled && (
                        <Text color="red" variant="muted">
                          This playbook has not been enabled
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                ))}
            {!field.config.workflowId && workflows && workflows.length > 0 && (
              <Flex
                sx={{ maxHeight: 400, overflowY: "scroll" }}
                flexDirection="column"
              >
                {workflows
                  ?.filter((workflow) => values.workflowId !== workflow.id)
                  .map((trigger) => (
                    <Flex
                      key={trigger.id}
                      bg={"white"}
                      variant="box"
                      my={1}
                      p={3}
                      alignItems="center"
                    >
                      <Flex
                        flex={1}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Flex
                          flex={1}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Flex flex={1} mx={2} flexDirection="column">
                            <Flex alignItems={"center"}>
                              <Text variant="h4">{trigger.name}</Text>
                              <Flex mx={2}>
                                {trigger.enabled && (
                                  <Box
                                    bg="greenLight"
                                    m={1}
                                    px={1}
                                    sx={{ borderRadius: 10 }}
                                  >
                                    <Flex
                                      alignItems="center"
                                      justfiyContent="center"
                                      flexDirection="column"
                                      p={1}
                                    >
                                      <Flex alignItems="center">
                                        <Text
                                          mx={1}
                                          color="green"
                                          variant="muted"
                                        >
                                          ACTIVE
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  </Box>
                                )}
                              </Flex>
                            </Flex>
                            <Text mt={1} variant="muted">
                              {trigger.description}
                            </Text>
                            <Flex>
                              <Tags value={trigger.tags} />
                            </Flex>
                          </Flex>
                          <Flex flex={1} justifyContent="flex-end">
                            <Flex width={1 / 3} flexDirection="column">
                              <Text textAlign="center" variant="label">
                                Total Days
                              </Text>
                              <Text textAlign="center" m={2}>
                                {trigger.totalDays || 0}
                              </Text>
                            </Flex>
                            <Flex width={1 / 3} flexDirection="column">
                              <Text textAlign="center" variant="label">
                                Total Steps
                              </Text>
                              <Text textAlign="center" m={2}>
                                {trigger.totalSteps || 0}
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex flexDirection={"column"} ml={3}>
                          <Text textAlign="center" variant="label">
                            Actions
                          </Text>
                          <Flex>
                            <Box mx={2} />
                            <GhostButton
                              onClick={() => {
                                setFieldValue(
                                  `${defaultFieldName}.title`,
                                  trigger.name
                                );
                                setFieldValue(
                                  `${defaultFieldName}.workflowId`,
                                  field.config.workflowId === trigger.id
                                    ? undefined
                                    : trigger.id
                                );
                              }}
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="center"
                                color="green"
                              >
                                <CheckCircleOffIcon size={20} />
                              </Flex>
                            </GhostButton>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}
              </Flex>
            )}
          </>
        );
      }}
    </Formik>
  );
}

export default WorkflowSelectForm;
