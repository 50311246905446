import React, { useState, useEffect } from "react";
import { useParams, Prompt } from "react-router-dom";
import { Flex, Text, Box } from "rebass";
import "react-tabs/style/react-tabs.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "Components/Common/Loader";
import ConversionScoreMutation from "../../../../graphql/mutations/ConversionScore.gql";
import ConversionScoreQuery from "../../../../graphql/queries/ConversionScore.gql";
import Settings from "../ProductSettings";
import DynamicSlider from "./DynamicSlider";
import TrialConversionMatrix from "Components/Common/TrailConversionMatrix";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TrialFactorForm from "./TrialFactorForm";
import { useEffectOnce } from "react-use";

function TrialConversionForm(props) {
  const { addToast } = useToasts();
  const { data, loading } = useQuery(ConversionScoreQuery);
  const [save] = useMutation(ConversionScoreMutation, {
    refetchQueries: [
      {
        query: ConversionScoreQuery,
      },
    ],
  });
  const [customerFit, setCustomerFit] = useState([]);
  const [adoption, setAdoption] = useState([]);

  const conversionScore = {
    ...data?.conversionScore,
  };
  useEffect(() => {
    if (conversionScore) {
      resetValues(conversionScore);
    }
  }, [data]);

  const resetValues = (values) => {
    let cf = [];
    values.config?.customerFit?.map((p, i) => {
      cf.push({
        label: p.name,
        value: p.score,
      });
    });
    setCustomerFit(cf);

    cf = [];
    values.config?.adoption?.map((p, i) => {
      cf.push({
        label: p.name,
        value: p.score,
      });
    });
    setAdoption(cf);
  };
  return (
    <Settings>
      {loading ? (
        <Loader />
      ) : (
        <Box bg="white" my={2} mr={4} p={2} width="100%">
          <Flex flexDirection="column" width="100%" px={4}>
            <Formik
              enableReinitialize
              initialValues={
                conversionScore && conversionScore.id
                  ? conversionScore
                  : {
                      name: "Default",
                      config: {
                        adoption: [],
                        customerFit: [],
                      },
                    }
              }
              autoComplete={false}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Required"),
                config: Yup.object().shape({
                  adoption: Yup.array()
                    .of(
                      Yup.object().shape({
                        name: Yup.string().required("Required"),
                        score: Yup.string().required("Required"),
                        criteria: Yup.array()
                          .of(
                            Yup.object().shape({
                              field: Yup.string().required("Required"),
                              entity: Yup.string().required("Required"),
                              score: Yup.string().required("Required"),
                              items: Yup.array().required("Required"),
                            })
                          )
                          .required("Required"),
                      })
                    )
                    .required("Required"),
                  customerFit: Yup.array()
                    .of(
                      Yup.object().shape({
                        name: Yup.string().required("Required"),
                        score: Yup.string().required("Required"),
                        criteria: Yup.array()
                          .of(
                            Yup.object().shape({
                              field: Yup.string().required("Required"),
                              entity: Yup.string().required("Required"),
                              score: Yup.string().required("Required"),
                              items: Yup.array().required("Required"),
                            })
                          )
                          .required("Required"),
                      })
                    )
                    .required("Required"),
                }),
              })}
              onSubmit={(values, actions) => {
                save({
                  variables: {
                    id: values.id,
                    name: values.name,
                    config: {
                      ...values.config,
                    },
                  },
                }).then(
                  (response) => {
                    if (response.data?.conversionScore?.errors) {
                      addToast(
                        <Flex>
                          {response.data?.conversionScore?.errors[0]}
                        </Flex>,
                        ErrorParams
                      );
                    } else {
                      addToast(<Flex>Saved!</Flex>, SuccessParams);
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              }}
            >
              {(formProps) => {
                const { values, errors, setFieldValue, dirty, handleSubmit } =
                  formProps;
                return (
                  <Flex width="100%" flexDirection="column" bg="white">
                    <Prompt
                      when={dirty}
                      message="You have unsaved changes. Are you sure you want to leave?"
                    />
                    <Flex>
                      <Flex flex={1} flexDirection="column">
                        <Flex justifyContent="space-between">
                          <Box>
                            <Text variant="h2">Trail Conversion</Text>
                          </Box>
                          <Box>
                            <PrimaryButton onClick={handleSubmit}>
                              Save
                            </PrimaryButton>
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box my={4}>
                      <Text variant="h3">Qualified Trials</Text>
                    </Box>

                    <TrialConversionMatrix />

                    <Tabs width="100%">
                      <TabList>
                        <Tab>Customer Fit</Tab>
                        <Tab>Adoption</Tab>
                      </TabList>
                      <TabPanel key="Customer Fit">
                        <Box px={4} pt={2}>
                          <DynamicSlider
                            key={customerFit.length}
                            data={customerFit}
                            domain={[0, 100]}
                            onChange={(ranges) => {
                              let cf = [];
                              values.config?.customerFit?.map((p, i) => {
                                let score =
                                  i < values.config.customerFit.length - 1
                                    ? ranges[i]
                                    : 100 - ranges[i - 1];
                                cf.push({
                                  label: p.name,
                                  value: score,
                                });
                              });
                              setCustomerFit(cf);
                            }}
                          />
                        </Box>

                        <Flex width="100%" flexDirection="column" my={2}>
                          <TrialFactorForm
                            {...formProps}
                            fieldName="customerFit"
                            onInsert={(items) => setCustomerFit(items)}
                            onDelete={(items) => setCustomerFit(items)}
                          />
                        </Flex>
                      </TabPanel>
                      <TabPanel key="Adoption">
                        <Box px={4} pt={2}>
                          <DynamicSlider
                            data={adoption}
                            domain={[0, 100]}
                            onChange={(ranges) => {
                              let cf = [];
                              values.config?.adoption?.map((p, i) => {
                                let score =
                                  i < values.config.adoption.length - 1
                                    ? ranges[i]
                                    : 100 - ranges[i - 1];
                                cf.push({
                                  label: p.name,
                                  value: score,
                                });
                              });
                              setAdoption(cf);
                            }}
                          />
                        </Box>

                        <Flex width="100%" flexDirection="column" my={2}>
                          <TrialFactorForm
                            {...formProps}
                            fieldName="adoption"
                            onInsert={(items) => setAdoption(items)}
                            onDelete={(items) => setAdoption(items)}
                          />
                        </Flex>
                      </TabPanel>
                    </Tabs>
                  </Flex>
                );
              }}
            </Formik>
          </Flex>
        </Box>
      )}
    </Settings>
  );
}

export default TrialConversionForm;
