import React, { useState } from "react";
import { Box, Flex, Text } from "rebass";
import TeamsQuery from "../../../../graphql/queries/Teams.gql";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useQuery } from "@apollo/react-hooks";
import {
  TaskCategories,
  TaskPriorityOptions,
} from "Components/CommandCenter/Tasks/TaskDefaults";

import { Formik } from "formik";
import * as Yup from "yup";
import WithOnValidationChange from "Components/Common/Forms/WithOnValidationChange";
import RadioGroupButton from "Components/Common/RadioGroupButton";
import UserBadge from "Components/Common/UserBadge";
function TaskForm(props) {
  const { field, setFieldValue, fieldName, handleBlur } = props;
  const categories = TaskCategories();
  const teamsQuery = useQuery(TeamsQuery);

  let teamsOptions = [];

  if (teamsQuery && !teamsQuery.loading) {
    teamsQuery.data?.teams.forEach((team) => {
      const usersOptions = team.memberships.map((r) => {
        return {
          value: `${team.id}${r.user.id}`,
          label: (
            <UserBadge
              name={`${r.user?.firstName || ""} ${r.user?.lastName || ""}`}
              email={r.user?.email}
              subtitle={team.name}
              avatarUrl={r.user?.avatarUrl}
              height={25}
            />
          ),
          teamId: team.id,
          userId: r.user.id,
        };
      });
      teamsOptions = teamsOptions.concat(usersOptions);
    });
  }

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  const periods = [
    {
      value: 0,
      label: <Flex alignItems="center">Same day</Flex>,
    },
    {
      value: 1,
      label: <Flex alignItems="center">Next day</Flex>,
    },
    {
      value: 2,
      label: <Flex alignItems="center">2 days</Flex>,
    },
    {
      value: 3,
      label: <Flex alignItems="center">3 days</Flex>,
    },
    {
      value: 7,
      label: <Flex alignItems="center">1 week</Flex>,
    },
    {
      value: 14,
      label: <Flex alignItems="center">2 weeks</Flex>,
    },
    {
      value: 31,
      label: <Flex alignItems="center">1 month</Flex>,
    },
  ];

  return field === undefined ? null : (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Required"),
        taskCategoryId: Yup.string().required("Required"),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        return (
          <>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <Flex
              flex={1}
              alignItems="flex-start"
              mt={1}
              mb={3}
              justifyContent="space-between"
            >
              <RadioGroupButton
                name={`${defaultFieldName}.taskCategoryId`}
                value={field.config?.taskCategoryId}
                options={categories?.filter((c) => c.active)}
                onChange={(name, value) => {
                  setFieldValue(name, value?.value);
                }}
              />
              {errors.taskCategoryId && (
                <Text variant="muted" color="red">
                  {errors.taskCategoryId}
                </Text>
              )}
            </Flex>
            <CustomFormGroup
              placeholder="Title"
              label="Title"
              name={`${defaultFieldName}.title`}
              value={field.config?.title}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.title && errors.title}
            />
            <Flex flex={1}>
              <CustomFormGroup
                placeholder=""
                label="Due Date"
                type="list"
                width={250}
                name={`${defaultFieldName}.duration`}
                value={field.config?.duration || 0}
                onChange={(name, value) => {
                  setFieldValue(name, value?.value);
                  setFieldValue(`${defaultFieldName}.period`, "days");
                }}
                options={periods}
                helperText={errors.duration}
              />
              <Box mx={2}></Box>
              <CustomFormGroup
                placeholder="Normal"
                label="Priority"
                type="list"
                width={250}
                name={`${defaultFieldName}.priority`}
                value={field.config?.priority}
                onChange={(name, value) => {
                  if (value) {
                    setFieldValue(name, value?.value);
                  } else {
                    setFieldValue(name, 3);
                  }
                }}
                options={TaskPriorityOptions}
                helperText={errors.priority}
              />
              <Flex mt={3} pt={2}>
                <CustomFormGroup
                  placeholder=""
                  label="Busy"
                  type="checkbox"
                  width={120}
                  name={`${defaultFieldName}.busy`}
                  value={field.config?.busy}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  helperText={errors.busy}
                />
                <CustomFormGroup
                  width={150}
                  placeholder="Assign To Owner"
                  label="Assign To Owner"
                  name={`${defaultFieldName}.assignToOwner`}
                  value={field.config?.assignToOwner}
                  type="checkbox"
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  helperText={errors.assignToOwner && errors.assignToOwner}
                />
              </Flex>
              {!field.config?.assignToOwner && (
                <Box>
                  <CustomFormGroup
                    type="list"
                    placeholder="Assign To"
                    label="Assign To"
                    width={250}
                    name={`${defaultFieldName}.team_id`}
                    value={
                      teamsOptions?.find(
                        (obj) =>
                          obj.teamId === field.config?.team_id &&
                          obj.userId === field.config?.user_id
                      )?.value
                    }
                    onChange={(name, value) => {
                      setFieldValue(
                        `${defaultFieldName}.user_id`,
                        value?.userId
                      );
                      setFieldValue(
                        `${defaultFieldName}.team_id`,
                        value?.teamId
                      );
                    }}
                    options={teamsOptions}
                    helperText={errors.team_id && errors.team_id}
                  />
                </Box>
              )}
            </Flex>
            <CustomFormGroup
              placeholder="Description"
              label="Description"
              name={`${defaultFieldName}.description`}
              value={field.config?.description}
              type="richtext"
              height={150}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.description && errors.description}
            />
          </>
        );
      }}
    </Formik>
  );
}

export default TaskForm;
