import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Image, Button, Text } from "rebass";
import "./menu.css";
import ReactTooltip from "react-tooltip";
import { useMutation } from "@apollo/react-hooks";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import {
  CompanyHealthIcon,
  ConversionIcon,
  HomeIcon,
  ProductHealthIcon,
  ReportIcon,
  SettingIcon,
  TasksIcon,
} from "Components/Common/Icons";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import AddMenu from "./AddMenu";
import { ContactsIcon } from "../../Common/Icons";
import CurrentAccountQuery from "../../../graphql/queries/CurrentAccount.gql";
import { useTheme } from "emotion-theming";
import { ImTab } from "react-icons/im";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";

const SidebarMain = (props) => {
  const theme = useTheme();
  const [toggled, setToggled] = React.useState(true);
  const [saveFilter] = useMutation(SearchFilterMutation);
  const currentAccount = useQuery(CurrentAccountQuery, {});
  const clearFilter = () => {
    saveFilter({
      variables: {
        filters: {
          statusId: undefined,
          segmentId: undefined,
        },
      },
    }).then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
  };

  const conversionModuleDisabled =
    currentAccount.data?.currentAccount?.setupConfig?.conversionModuleDisabled;

  return (
    <Flex height="100%" flexDirection="column">
      <Flex
        sx={{
          borderRadius: 99,
          width: 20,
          height: 20,
          top: 72,
          left: toggled ? 60 : 199,
          position: "absolute",
          zIndex: 999,
          backgroundColor: "white",
          borderColor: "gray.300",
          borderWidth: 1,
          borderStyle: "solid",
          cursor: "pointer",
          transition: "left 300ms",
        }}
        justifyContent={"center"}
        alignItems={"center"}
        onClick={() => setToggled(!toggled)}
      >
        {toggled ? (
          <RiArrowRightSFill size={20} color={theme.colors.line} />
        ) : (
          <RiArrowLeftSFill size={20} color={theme.colors.line} />
        )}
      </Flex>
      <Sidebar
        collapsedWidth={"70px"}
        width="208px"
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        collapsed={toggled}
        backgroundColor="white"
        rootStyles={{ display: "flex" }}
      >
        <Flex alignItems={"center"} pb={4} ml={2}>
          <Image src={require("assets/images/logo.png")} width={60} />
        </Flex>
        <Menu
          closeOnClick={true}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // the active class will be added automatically by react router
              // so we can use it to style the active menu item
              if (active) {
                return {
                  color: theme.colors.brandLight,
                  borderRightColor: theme.colors.brandLight,
                  borderRightWidth: 3,
                  borderRightStyle: "solid",
                  fontWeight: "bold",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "xs",
                };
              } else if (disabled) {
                return {
                  color: theme.colors.gray[400],
                  textDecoration: "none",
                  fontFamily: theme.fonts.body,
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "xs",
                  "&:hover": {
                    backgroundColor: theme.colors.gray[100],
                  },
                };
              } else {
                return {
                  color: theme.colors.menu,
                  textDecoration: "none",
                  fontFamily: theme.fonts.body,
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "xs",
                  "&:hover": {
                    color: theme.colors.brandLight,
                    backgroundColor: theme.colors.gray[100],
                  },
                };
              }
            },
          }}
        >
          <MenuItem
            active={window.location.pathname.startsWith("/home")}
            icon={<HomeIcon size={24} />}
            component={<Link to="/home" onClick={clearFilter} />}
          >
            <Text fontSize="xs">Dashboard</Text>
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/tasks")}
            icon={<TasksIcon size={24} />}
            component={<Link to="/tasks" onClick={clearFilter} />}
          >
            <Text fontSize="xs">Command Center</Text>
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/contacts")}
            icon={<ContactsIcon size={24} />}
            component={<Link to="/contacts/accounts" onClick={clearFilter} />}
          >
            <Text fontSize="xs">Contacts</Text>
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/conversion")}
            icon={<ConversionIcon size={24} />}
            component={<Link to="/conversion" onClick={clearFilter} />}
            disabled={conversionModuleDisabled}
          >
            <Text fontSize="xs">Conversion Journey</Text>
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/health")}
            icon={<CompanyHealthIcon size={24} />}
            component={<Link to="/health" onClick={clearFilter} />}
          >
            <Text fontSize="xs">Account Journey</Text>
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/adoption")}
            icon={<ProductHealthIcon size={24} />}
            component={<Link to="/adoption" onClick={clearFilter} />}
          >
            <Text fontSize="xs">Product Adoption</Text>
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/reports")}
            icon={<ReportIcon size={24} />}
            component={<Link to="/reports" onClick={clearFilter} />}
          >
            <Text fontSize="xs">Insights</Text>
          </MenuItem>
        </Menu>
      </Sidebar>
      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="flex-end"
        pb={2}
        sx={{
          position: "fixed",
          zIndex: 1,
          left: 0,
          bottom: 0,
        }}
      >
        <Sidebar
          collapsedWidth={"70px"}
          onBackdropClick={() => setToggled(false)}
          toggled={toggled}
          collapsed={toggled}
          backgroundColor="white"
        >
          <Menu
            closeOnClick={true}
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // the active class will be added automatically by react router
                // so we can use it to style the active menu item
                if (active) {
                  return {
                    color: theme.colors.brandLight,
                    borderRightColor: theme.colors.brandLight,
                    borderRightWidth: 3,
                    borderRightStyle: "solid",
                    fontWeight: "bold",
                    alignItems: "center",
                    justifyContent: "center",
                  };
                } else if (disabled) {
                  return {
                    color: theme.colors.gray[400],
                    textDecoration: "none",
                    fontFamily: theme.fonts.body,
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: theme.colors.gray[100],
                    },
                  };
                } else {
                  return {
                    color: theme.colors.line,
                    textDecoration: "none",
                    fontFamily: theme.fonts.body,
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: theme.colors.brandLight,
                      backgroundColor: theme.colors.gray[100],
                    },
                  };
                }
              },
            }}
          >
            <Box my={2}>
              <AddMenu />
            </Box>
            <MenuItem
              active={window.location.pathname.startsWith("/settings")}
              icon={<SettingIcon size={24} />}
              component={<Link to="/settings/account" />}
            >
              <Text fontSize="xs">Settings</Text>
            </MenuItem>
          </Menu>
        </Sidebar>
      </Flex>
    </Flex>
  );
};

SidebarMain.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
};

export default SidebarMain;
