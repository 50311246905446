import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Flex, Text, Image, Box, Button } from "rebass";
import Menu from "./Menu";
import Sidebar from "./Sidebar";
import { useQuery } from "@apollo/react-hooks";
import CurrentAccountQuery from "../../graphql/queries/CurrentAccount.gql";
import { useHistory } from "react-router";
import Onboarding from "../Common/Onboarding";
import { HelpScout } from "react-live-chat-loader";
import { onAdminDomain } from "../../auth-helper";
import { userlotInit } from "../../analytics/userlot-tracking";
import { userlotTrackEvent } from "../../analytics/userlot-tracking";
import { useEffectOnce } from "react-use";

export default function Page(props) {
  const history = useHistory();
  const currentAccount = useQuery(CurrentAccountQuery, {});
  const [open, setOpen] = useState(false);
  const account = currentAccount?.data?.currentAccount;
  const tourSteps = [
    {
      image: true,
      target: "body",
      title: "Welcome to Userlot",
      content:
        "Take control of our Customer Success daily plan. Ensure important tasks are completed early and activate proactive customer interactions.",
      disableBeacon: true,
      disableOverlayClose: true,
      placement: "center",
    },
    {
      target: ".add_menu",
      title: "Create New Account",
      content:
        "Accounts can be imported from an existing system (e. Segment, CRM) or imported from csv. To get started let's create your first account. Click the plus to manually add an account to continue your tutorial.",
      placement: "right",
      disableBeacon: true,
    },
  ];
  if (currentAccount?.error?.networkError?.statusCode === 401) {
    // Direct to login
    history.push("/");
  }

  function showHelpScout() {
    if (
      !onAdminDomain() &&
      window.UserlotConfig &&
      window.UserlotConfig.helpscout
    ) {
      return (
        <HelpScout
          color="#0074a5"
          icon="question"
          zIndex="1500"
          horizontalPosition="right"
        />
      );
    }
  }

  useEffectOnce(() => {
    // Just a test event so can pop up a survey
    userlotTrackEvent("PageView", { module: props.title });
  }, []);
  return (
    <Flex
      style={{
        color: "text",
        minHeight: "100vh",
      }}
    >
      {/* <CssBaseline /> */}
      <Helmet title={`Userlot | ${props.title}`} />
      {showHelpScout()}
      {userlotInit()}
      <Flex flex={1}>
        <Onboarding steps={tourSteps} stepName="add_account" continuous />
        <Sidebar data={account} open={open} onDismiss={() => setOpen(false)} />
        <Flex flex={1} flexGrow={1} flexDirection="column">
          <Menu
            onOpen={() => {
              setOpen(!open);
            }}
          />
          <Flex
            id="outer-container"
            m={0}
            flex={1}
            flexGrow={1}
            flexDirection="row"
          >
            <Flex
              id="page-wrap"
              flexDirection="column"
              w="100%"
              flex={1}
              flexGrow={1}
              style={{
                minWidth: 0,
              }}
            >
              <Flex
                flex={1}
                height="100%"
                flexDirection="column"
                variant="pageContent"
                className="transition-container"
                flexGrow={1}
              >
                <Flex flexGrow={1} flex={1}>
                  <Flex
                    flex={1}
                    flexGrow={1}
                    flexDirection="column"
                    bg={props.bg || "white"}
                    px={2}
                  >
                    <Flex mx={3}>{props.topBar}</Flex>
                    <Flex
                      flex={1}
                      flexGrow={1}
                      className="react-transition fade-in"
                      flexDirection="column"
                      sx={{ borderRadius: "medium" }}
                      ml={3}
                    >
                      {props.children}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

/*
                  <Breadcrumbs crumbs={props.crumbs} />*/
