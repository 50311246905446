import React, { useState, useEffect } from "react";
import Page from "Components/Page";
import { Flex, Text } from "rebass";
import EndUsersList from "Components/Data/EndUser/EndUsers";
import { MODEL_TYPES } from "consts";
import Filter from "Components/Filter";
import { getSearchFilter } from "hooks/getSearchFilter";
import { useQuery } from "@apollo/react-hooks";
import { useDynamicGql } from "../../../graphql/DynamicGql";
import ContactsTopbar from "./ContactsTopbar";

const pageTitle = "Accounts";
function UserContacts(props) {
  const { data: searchFilters } = getSearchFilter();
  const { getAllData } = useDynamicGql(MODEL_TYPES.EndUser, true);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(undefined);
  const [perPage, setPerPage] = useState(50);
  const [sort, setSort] = useState(undefined);

  const handleSort = async (column, sortDirection) => {
    setSort({
      column: column.id,
      direction: sortDirection,
      schemaName: column.schemaName,
    });
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const filters = {
    filterText: searchFilters?.filters?.contacts?.filterText,
    tags: searchFilters?.filters?.tags,
    segmentId: searchFilters?.filters?.segmentId,
    ownerUserId: searchFilters?.filters?.ownerUserId,
    teamId: searchFilters?.filters?.teamId,
    page: page,
    size: perPage,
    sort: sort,
  };

  const { data, loading, refetch } = useQuery(getAllData, {
    variables: { searchFilters: filters },
    skip: !searchFilters,
  });

  const endUsers = data?.endUsers?.endUsers || [];
  useEffect(() => {
    if (data?.endUsers) {
      setTotalRows(data?.endUsers.total);
    }
  }, [data]);

  return (
    <Page title="People" topBar={<ContactsTopbar />}>
      <Filter
        title={pageTitle}
        menu={
          <Flex alignItems="center">
            <Text variant="h2" mr={3}>
              People
            </Text>
          </Flex>
        }
        typeName={MODEL_TYPES.Company.name}
        onChange={() =>
          !loading &&
          refetch({
            searchFilters: {
              filterText: searchFilters?.filters?.contacts?.filterText,
              tags: searchFilters?.filters?.tags,
              segmentId: searchFilters?.filters?.segmentId,
              ownerUserId: searchFilters?.filters?.ownerUserId,
              page: page,
              size: perPage,
              sort: sort,
            },
          })
        }
      >
        <Flex flexDirection="column" px={4} flex={1}>
          <EndUsersList
            data={endUsers}
            loading={loading}
            onSort={handleSort}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            refetch={() =>
              refetch({
                searchFilters: {
                  filterText: searchFilters?.filters?.contacts?.filterText,
                  tags: searchFilters?.filters?.tags,
                  segmentId: searchFilters?.filters?.segmentId,
                  ownerUserId: searchFilters?.filters?.ownerUserId,
                  page: page,
                  size: perPage,
                  sort: sort,
                },
              })
            }
          />
        </Flex>
      </Filter>
    </Page>
  );
}

export default UserContacts;
