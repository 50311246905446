import React, { useEffect } from "react";
import { Flex } from "rebass";

export const SuccessParams = {
  appearance: "success",
  autoDismiss: true,
};

const ShowSuccess = ({ message, detail }) => {
  return <Flex alignItems="center">{message}</Flex>;
};

export default ShowSuccess;
