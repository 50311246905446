import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Image } from "rebass";
import AnimatedIcon, { ErrorAnimatedIcon } from "../AnimatedIcon";

function Loader(props) {
  const theme = useTheme();
  return props.error ? (
    <Flex m={4} alignItems="center" justifyContent="center" color="secondary">
      <ErrorAnimatedIcon />
    </Flex>
  ) : (
    <Flex m={3} alignItems="center" justifyContent="center" color="secondary">
      <AnimatedIcon large={props.large}></AnimatedIcon>
    </Flex>
  );
}

export default Loader;
