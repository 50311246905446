import React, { useState } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Flex, Button, Box } from "rebass";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";

function Payment(props) {
  const handlePayment = () => {
    const cardElement = props.elements.getElement("card");
    props.stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { name: props.billingName },
      })
      .then(({ paymentMethod }) => {
        props.onUpdate({ token: paymentMethod.id });
      });
  };
  return (
    <>
      <Box m={4} variant="box" p={3}>
        <CardElement style={{ base: { fontSize: "18px" } }} />
      </Box>
      <Flex mt={4} justifyContent="space-between">
        <SecondaryButton onClick={props.previousStep}>Back</SecondaryButton>
        <PrimaryButton onClick={() => handlePayment()}>Continue</PrimaryButton>
      </Flex>
    </>
  );
}

export default injectStripe(Payment);
