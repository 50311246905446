import React from "react";
import { withRouter } from "react-router-dom";
import CurrentUserQuery from "../../../../graphql/queries/CurrentUser.gql";
import { useQuery } from "@apollo/react-hooks";
import { Flex, Text, Box } from "rebass";
import UserForm from "./UserForm";
import PasswordForm from "./PasswordForm";
import Page from "Components/Page";
import Topbar from "Components/Common/Topbar/Topbar";

function Profile(props) {
  const { loading, data, refetch, error } = useQuery(CurrentUserQuery, {});
  const currentUser = data && data.currentUser;
  return (
    <Page
      title="Profile"
      topBar={<Topbar title="Profile" menuItems={[]} crumbs={props.crumbs} />}
    >
      <Flex flexDirection="column" width="100%" my={2} mr={4}>
        <Box p={2} width="100%">
          <UserForm
            data={currentUser}
            onSave={() => {
              refetch();
            }}
          />
        </Box>
      </Flex>
    </Page>
  );
}

export default withRouter(Profile);
