import React, { useState } from "react";
import { Box, Text, Flex } from "rebass";
import { useTheme } from "emotion-theming";
import { RiArrowDownSLine } from "react-icons/ri";
import Label from "./Label";
import { TwitterPicker } from "react-color";
import PropTypes from "prop-types";
import { CloseIcon } from "../Icons";

function ColorSelect(props) {
  const [showColor, setShowColor] = useState(undefined);
  return (
    <Flex flexDirection="column" mb={2} sx={{ minWidth: 250 }}>
      <Label>{props.label}</Label>
      <Flex variant="box" width={120} mt={2}>
        <Flex
          bg={props.value}
          height={35}
          flex={1}
          sx={{ cursor: "pointer" }}
          alignItems="center"
          justifyContent="center"
          fontSize="xs"
          onClick={() => setShowColor(!showColor)}
        ></Flex>
        <Flex
          p={2}
          color="line"
          alignItems="center"
          justifyContent="center"
          onClick={() => setShowColor(!showColor)}
          sx={{
            borderLeftWidth: 1,
            borderLeftStyle: "solid",
            borderLeftColor: "line",
          }}
        >
          <RiArrowDownSLine size={20} />
        </Flex>
        <Flex
          p={2}
          color="line"
          alignItems="center"
          justifyContent="center"
          sx={{
            borderLeftWidth: 1,
            borderLeftStyle: "solid",
            borderLeftColor: "line",
          }}
          onClick={() => props.onChange(props.name, undefined)}
        >
          <CloseIcon size={20} />
        </Flex>
      </Flex>
      <ColorEditor
        field={props.name}
        showColor={!!showColor}
        onChange={(color, event) => {
          props.onChange(props.name, color?.hex);
          if (!event.target.value) {
            setShowColor(false);
          }
        }}
      />
      {props.helperText && (
        <Text variant="muted" fontSize={"xxs"}>
          {props.helperText}
        </Text>
      )}
    </Flex>
  );
}
export default ColorSelect;

const ColorEditor = ({ value, showColor, onChange }) => {
  return showColor ? (
    <Flex
      sx={{
        position: "relative",
      }}
      flex={1}
    >
      <Flex
        sx={{
          position: "absolute",
          top: 15,
          left: 0,
          zIndex: 2,
        }}
        flexDirection="column"
        bg="white"
      >
        <TwitterPicker color={value} onChangeComplete={onChange} />
      </Flex>
    </Flex>
  ) : null;
};
ColorEditor.propTypes = {
  field: PropTypes.object,
  showColor: PropTypes.func,
  onChange: PropTypes.func,
};
