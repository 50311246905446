import React, { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Flex, Button, Text, Box } from "rebass";
import TypeSchemaQuery from "../../../../graphql/queries/TypeSchemas.gql";
import ImportMutation from "../../../../graphql/mutations/Import.gql";
import Loader from "Components/Common/Loader";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { MODEL_TYPES } from "consts";
import Table from "Components/Common/Table";
import { Checkbox, Label, Radio } from "@rebass/forms";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { RiKey2Fill } from "react-icons/ri";
import { TicketIcon } from "../../../Common/Icons";
import { ModelTypeIcon } from "../../../../consts";

function PreviewData(props) {
  const history = useHistory();
  const { importData } = props;

  const {
    id,
    fileFormat: columns,
    includeHeaders,
    fieldMapping,
    sourceId,
    sourceType,
  } = importData;

  if (!importData) return null;

  const [selectedTab, setSelectedTab] = useState(0);
  const [save, { client }] = useMutation(ImportMutation);
  const [mergeData, setMergeData] = useState(importData.mergeData);
  const { loading, data, error, refetch } = useQuery(TypeSchemaQuery, {});

  if (loading) <Loader />;

  const dataModels = data?.typeSchemas?.sort((a, b) =>
    a.typeName.localeCompare(b.typeName)
  );

  const saveImport = () => {
    save({
      variables: {
        id: importData.id,
        includeHeaders: importData.includeHeaders,
        mergeData: mergeData,
        fieldMapping: importData.fieldMapping,
        setupComplete: true,
        sourceId: sourceId,
        sourceType: sourceType,
      },
    }).then(
      (response) => {
        if (!response.data?.importUpload?.errors) {
          props.onUploadComplete(response.data?.import?.import);
          props.nextStep();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Flex flexDirection="column">
      <Flex></Flex>

      <Tabs selectedIndex={selectedTab} onSelect={setSelectedTab}>
        <TabList>
          {dataModels?.map((schema, i) => {
            let dataTable = importData[MODEL_TYPES[schema.typeName].plural];

            return (
              dataTable?.length > 0 && (
                <Tab key={schema.id}>
                  <Flex alignItems="center" flexDirection="column">
                    {ModelTypeIcon(schema.typeName, 25)}
                    <Text fontSize="sm">{schema?.displayName}</Text>
                  </Flex>
                </Tab>
              )
            );
          })}
        </TabList>
        {dataModels?.map((schema) => {
          const dataTable = importData[MODEL_TYPES[schema.typeName].plural];
          const row = (dataTable && dataTable.length > 0 && dataTable[0]) || {};
          const attributes = Object.entries(row).map((key) => key[0]);

          const columns =
            schema.attributes &&
            schema.attributes
              .filter((field) => attributes.find((a) => a === field.name))
              .sort((a, b) => {
                if (a.sortOrder > b.sortOrder) return 1;
                if (a.sortOrder < b.sortOrder) return -1;
                return 0;
              })
              .map((field) => {
                const isPrimaryKey =
                  fieldMapping &&
                  fieldMapping["keys"] &&
                  fieldMapping["keys"][schema.typeName] &&
                  fieldMapping["keys"][schema.typeName].includes(field.name);
                return {
                  name: (
                    <Flex alignItems="center">
                      {field.displayName}
                      {isPrimaryKey ? (
                        <Text mx={2} color="yellow">
                          <RiKey2Fill />
                        </Text>
                      ) : (
                        ""
                      )}
                    </Flex>
                  ),
                  selector: field.name,
                  sortable: true,
                };
              });

          return (
            dataTable?.length > 0 && (
              <TabPanel key={schema.id}>
                <Flex my={2}>
                  <Table
                    title=""
                    defaultSortField="id"
                    selectableRows={false}
                    columns={columns}
                    data={dataTable}
                    loading={loading}
                  />
                </Flex>
              </TabPanel>
            )
          );
        })}
      </Tabs>
      <Flex justifyContent="space-between">
        <SecondaryButton
          onClick={() => {
            props.previousStep();
          }}
        >
          Back
        </SecondaryButton>
        <Flex>
          <SecondaryButton
            mx={2}
            onClick={() => {
              history.go("/settings/product/import#");
            }}
          >
            Cancel
          </SecondaryButton>
          <Box mx={2} />
          <PrimaryButton
            onClick={() => {
              saveImport();
            }}
          >
            Import
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default withRouter(PreviewData);
