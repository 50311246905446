import React, { useState } from "react";
import { Flex, Text, Box, Button } from "rebass";
import "react-tabs/style/react-tabs.css";
import { FaEye, FaEyeSlash, FaStarOfLife } from "react-icons/fa";
import { useTheme } from "emotion-theming";
import ReactTooltip from "react-tooltip";

import { TYPES } from "consts";
import EditField from "./EditField";
import { getProducts } from "hooks/getProducts";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { EditIcon, StarIcon } from "Components/Common/Icons";

const typeOptions = TYPES?.map((r) => {
  return { value: r.id, label: r.name };
});

function TypeModelItem(props) {
  const { data } = getProducts();
  const { addToast, removeAllToasts } = useToasts();
  const theme = useTheme();
  const [open, setOpen] = useState(props.new);
  const { child, errors, values, setFieldValue, index, handleBlur } = props;

  const product = data?.products.find((p) => p.id === child.productId);
  return (
    <>
      <EditField
        open={open}
        index={index}
        child={child}
        typeName={values.typeName}
        onDismiss={() => {
          props.onDelete(index);
          setOpen(false);
        }}
        onSave={(savedValues) => {
          setFieldValue(`attributes[${index}]`, savedValues);
          setOpen(false);
          props.onSave && props.onSave();
        }}
        onDelete={(index) => {
          addToast(
            <Flex flexDirection="column">
              <Text my={2}>
                Are you sure you want to delete this attribute?
              </Text>
              <Flex justifyContent="flex-end">
                <Button
                  variant="error"
                  onClick={() => {
                    removeAllToasts();
                    setFieldValue(`attributes[${index}]`, {
                      name: child.name,
                      deleted: true,
                    });
                    props.onDelete(index);
                    setOpen(false);
                  }}
                >
                  Delete
                </Button>
              </Flex>
            </Flex>,
            ErrorParams
          );
        }}
      />

      <Flex
        key={index}
        alignItems="center"
        width="100%"
        sx={{
          borderWidth: 0,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: theme.colors.accent,
          ":hover": {
            backgroundColor: "brandHighlight",
            borderRadius: 99,
            borderBottomWidth: 0,
          },
        }}
        py={2}
      >
        <Flex alignItems="center">
          <Button variant="ghost" onClick={() => setOpen(true)}>
            <EditIcon />
          </Button>
        </Flex>
        <Flex width={1 / 4}>
          <Box pl={2}>
            <Text fontWeight="bold">{child.displayName}</Text>
            <Text variant="muted" my={0} py={0}>
              {child.apiDisplayName}
            </Text>
          </Box>
        </Flex>
        <Flex width={1 / 4}>
          <Box mx={1} />

          <Box>
            <Text fontWeight="bold">
              {typeOptions.find((obj) => obj.value === child.type)?.label}
            </Text>
          </Box>
        </Flex>

        <Flex width={1 / 4}>
          <Box pl={2}>
            {product ? (
              <Text>{product?.name}</Text>
            ) : (
              <Text variant="muted">All</Text>
            )}
          </Box>
        </Flex>
        <Flex width={1 / 4}>
          <Box mx={2} />
          <Flex
            color={values.attributes[index].required ? "primary" : "gray.400"}
            alignItems="center"
          >
            <ReactTooltip
              id={`tooltip-${index}`}
              place="bottom"
              effect="solid"
              overridePosition={({ left, top }) => ({
                left: left,
                top: top,
              })}
              getContent={(dataTip) => dataTip}
            />
            <Box
              data-for={`tooltip-${index}`}
              data-tip="Required field"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                !values.attributes[index].locked &&
                  setFieldValue(
                    `attributes[${index}].required`,
                    !values.attributes[index].required
                  );
                props.onSave();
              }}
            >
              <FaStarOfLife size="25" />
            </Box>
          </Flex>
          <Box mx={2} />

          <Flex
            color={values.attributes[index].featured ? "primary" : "gray.400"}
            alignItems="center"
          >
            <Box
              data-for={`tooltip-${index}`}
              data-tip="Featured field"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setFieldValue(
                  `attributes[${index}].featured`,
                  !values.attributes[index].featured
                );
                props.onSave();
              }}
            >
              <StarIcon size="25" />
            </Box>
          </Flex>
          <Box mx={2} />
          <Flex
            color={values.attributes[index].hidden ? "primary" : "gray.400"}
            alignItems="center"
          >
            <Box
              data-for={`tooltip-${index}`}
              data-tip="Hidden field"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setFieldValue(
                  `attributes[${index}].hidden`,
                  !values.attributes[index].hidden
                );
                props.onSave();
              }}
            >
              {values.attributes[index].hidden ? (
                <FaEyeSlash size="25" />
              ) : (
                <FaEye size="25" />
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default TypeModelItem;
