import { useMutation, useQuery } from "@apollo/react-hooks";
import { FieldArray, Formik } from "formik";
import React, { useState } from "react";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "../../../Common/Notifications/Error";
import { SuccessParams } from "../../../Common/Notifications/Success";
import { Button, Flex, Text, Box, Image, Link } from "rebass";
import * as Yup from "yup";
import SurveyMutation from "../../../../graphql/mutations/SurveyQuestion.gql";
import SurveyDeleteMutation from "../../../../graphql/mutations/SurveyQuestionDelete.gql";
import SurveyQuery from "../../../../graphql/queries/Survey.gql";
import SurveysQuery from "../../../../graphql/queries/Surveys.gql";
import CustomFormGroup from "../../../Common/Forms/CustomFormGroup";
import {
  SaveIcon,
  CloseIcon,
  TrashIcon,
  PlusIcon,
} from "../../../Common/Icons";
import DeleteButton from "../Common/DeleteButton";
import PropTypes from "prop-types";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  RiArrowDownSLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiEmotionLaughLine,
  RiEmotionNormalLine,
  RiEmotionSadLine,
  RiGlobalLine,
  RiMailSettingsLine,
  RiMore2Line,
} from "react-icons/ri";
import SurveyButtons, { SurveyButton } from "./SurveyButtons";
import { TwitterPicker } from "react-color";
import Label from "../../../Common/Forms/Label";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import moment from "moment";
import { getProduct } from "hooks/getProduct";
import ColorSelect from "../../../Common/Forms/ColorSelect";
import { useTheme } from "emotion-theming";
import {
  detratorMax,
  promoterMin,
  SurveyQuestionSuggestions,
} from "../../../Common/Satisfaction/SatisfactionDefaults";
import GhostButton from "../../../Common/Buttons/GhostButton";
import { SurveyWidget } from "./SurveyWidget";

export const WebDeliveryType = 0;
export const EmailDeliveryType = 1;

function SurveysForm(props) {
  const theme = useTheme();
  const history = useHistory();
  const { addToast } = useToasts();
  const { id } = useParams();
  const { data: currentProduct } = getProduct();
  const [save, {}] = useMutation(SurveyMutation, {
    refetchQueries: [
      {
        query: SurveysQuery,
        variables: {},
      },
    ],
  });
  const [destroy, {}] = useMutation(SurveyDeleteMutation, {
    refetchQueries: [
      {
        query: SurveysQuery,
        variables: {},
      },
    ],
  });

  const { data, loading, error } = useQuery(SurveyQuery, {
    variables: { id: id },
    skip: !id || id === "new",
  });

  const survey = id &&
    id !== "new" && {
      ...data?.survey,
      surveyQuestions: data?.survey?.surveyQuestions
        ? data?.survey?.surveyQuestions?.map((q) => {
            let max = detratorMax(data?.survey?.surveyType);
            let min = promoterMin(data?.survey?.surveyType);
            let responseType =
              q.minScore >= min
                ? "positive"
                : q.maxScore <= max
                ? "negative"
                : "neutral";
            return { responseType: responseType, ...q };
          })
        : [
            { question: "", primary: true },
            { question: "", responseType: "neutral" },
          ],
    };

  const getMinMaxScore = (responseType, surveyType) => {
    let q = {};
    switch (responseType) {
      case "positive":
        q.minScore = promoterMin(surveyType);
        q.maxScore = 10;
        break;
      case "negative":
        q.minScore = 0;
        q.maxScore = detratorMax(surveyType);
        break;
      default:
        q.minScore = detratorMax(surveyType) + 1;
        q.maxScore = promoterMin(surveyType) - 1;
        break;
    }
    return q;
  };

  const getSuggestedQuestion = (surveyType, responseType) => {
    return _.first(
      _.shuffle(SurveyQuestionSuggestions).filter(
        (s) => s.responseType == responseType && s.surveyType == surveyType
      )
    )?.question.replaceAll("[product]", currentProduct?.currentProduct?.name);
  };
  const resetQuestions = (conditional, values, defaultValues) => {
    let primaryQuestion =
      values.surveyQuestions.find((q) => q.primary) ||
      _.first(values.surveyQuestions.filter((q) => !q.isArchived));

    if (!primaryQuestion) {
      primaryQuestion = { primary: true };
    }
    if (defaultValues) {
      primaryQuestion.primary = true;
      primaryQuestion.question = getSuggestedQuestion(
        values.surveyType,
        undefined
      );
    }
    let questions = [primaryQuestion];
    if (!!conditional) {
      ["neutral", "positive", "negative"].map((rp) =>
        questions.push({
          question: defaultValues
            ? getSuggestedQuestion(values.surveyType, rp)
            : "",
          responseType: rp,
          minScore: getMinMaxScore(rp, values.surveyType)?.minScore,
          maxScore: getMinMaxScore(rp, values.surveyType)?.maxScore,
        })
      );
    } else {
      questions.push({
        question: defaultValues
          ? getSuggestedQuestion(values.surveyType, "neutral")
          : "",
        responseType: "neutral",
      });
    }
    return questions;
  };
  const saveTemplate = (values, actions) => {
    // Set min and max score and sequence number
    values.surveyQuestions?.map((q, i) => {
      if (i == 0) {
        q.primary = true;
      }
      if (!q.primary) {
        let minMax = getMinMaxScore(q.responseType, values.surveyType);
        q.minScore = minMax.minScore;
        q.maxScore = minMax.maxScore;
      }
      q.orderSeq = i;
      delete q.responseType;
      delete q.__typename;
    });

    save({
      variables: {
        id: values.id ? values.id : null,
        name: values.name ? values.name : null,
        surveyType: values.surveyType,
        deliveryType: values.deliveryType,
        questions: values.surveyQuestions,
        config: values.config,
      },
    }).then(
      (response) => {
        if (response.data?.surveyQuestion?.errors) {
          addToast(
            <Flex>{response.data?.surveyQuestion?.errors[0]}</Flex>,
            ErrorParams
          );
        } else {
          addToast(<Flex>Saved!</Flex>, SuccessParams);
          actions.resetForm({
            values: response.data?.survey?.survey,
          });
          history.push("/settings/product/library?tab=surveys");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const responseOptions = [
    {
      label: (
        <Flex alignItems={"center"} fontWeight="bold">
          <Text color={theme.colors.green} mx={1}>
            <RiEmotionLaughLine size={20} />
          </Text>
          Positive
        </Flex>
      ),
      value: "positive",
      title: "Positve",
    },
    {
      label: (
        <Flex alignItems={"center"} fontWeight="bold">
          <Text color={theme.colors.gray[600]} mx={1}>
            <RiEmotionNormalLine size={20} />
          </Text>
          Neutral
        </Flex>
      ),
      value: "neutral",
      title: "Neutral",
    },
    {
      label: (
        <Flex alignItems={"center"} fontWeight="bold">
          <Text color={theme.colors.yellow} mx={1}>
            <RiEmotionSadLine size={20} />
          </Text>
          Negative
        </Flex>
      ),
      value: "negative",
      title: "Negative",
    },
  ];
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={
          survey || {
            name: "",
            surveyType: "nps",
            deliveryType: WebDeliveryType,
            config: {},
            surveyQuestions: [
              {
                question: "",
                primary: true,
              },
              {
                question: "",
                responseType: "neutral",
              },
            ],
          }
        }
        autoComplete={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          saveTemplate(values, actions);
        }}
      >
        {(formProps) => {
          const {
            values,
            setFieldValue,
            handleBlur,
            handleSubmit,
            resetForm,
            errors,
            dirty,
          } = formProps;

          console.log(errors);
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex flexGrow={1} flexDirection="column">
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex flex={1} justifyContent="flex-end">
                    <DeleteButton
                      callback={destroy}
                      onCancel={() => history.goBack()}
                      id={id}
                      name="Survey Question"
                    />
                    {!values.live && (
                      <Button
                        variant="action"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <Flex>
                          <SaveIcon />
                          <Text mx={1}>Save</Text>
                        </Flex>
                      </Button>
                    )}
                  </Flex>
                </Flex>
                <Flex mt={2}>
                  <CustomFormGroup
                    placeholder="Name"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors && errors.name && errors.name}
                  />
                  <Flex flexDirection="column" mx={2}>
                    <ColorSelect
                      label="Branding Color"
                      name={`config.primaryColor`}
                      value={values.config?.primaryColor}
                      onChange={(name, value) => setFieldValue(name, value)}
                      helperText={
                        !values.config?.primaryColor &&
                        "Using default brand color"
                      }
                    />
                  </Flex>
                </Flex>
                <Text>What kind of feedback would you like to get?</Text>
                <Flex mt={2}>
                  {[
                    {
                      icon: undefined,
                      value: "nps",
                      title: "NPS",
                      description: "Net Promoter Score",
                    },
                    {
                      icon: undefined,
                      value: "ces",
                      title: "CES",
                      description: "Customer Effort Score",
                    },
                    {
                      icon: undefined,
                      value: "csat",
                      title: "CSAT",
                      description: "Customer Satisfaction Score",
                    },
                  ].map((o) => (
                    <Flex key={o.value} mr={2}>
                      <Button
                        variant={"secondary"}
                        sx={{
                          textAlign: "left",
                          borderWidth: values.surveyType == o.value ? 2 : 1,
                          borderColor:
                            values.surveyType == o.value
                              ? "brandLight"
                              : "gray.200",
                        }}
                        type="submit"
                        onClick={() => {
                          setFieldValue("surveyType", o.value);
                          let questions = resetQuestions(
                            values?.config?.followUpConditional || false,
                            values
                          );

                          console.log(values);
                          console.log(questions);
                          setFieldValue(`surveyQuestions`, questions);
                        }}
                      >
                        <Box
                          color={
                            values.surveyType == o.value ? "brandLight" : "text"
                          }
                        >
                          <Text mx={1}>{o.title}</Text>
                          <Text mx={1} variant="muted">
                            {o.description}
                          </Text>
                        </Box>
                      </Button>
                    </Flex>
                  ))}
                </Flex>
                <Flex my={3} alignItems="center">
                  <Text>How would you like to survey people?</Text>
                  <Flex mx={2}>
                    {[
                      {
                        icon: <RiGlobalLine />,
                        value: WebDeliveryType,
                        title: "Web",
                      },
                      {
                        icon: <RiMailSettingsLine />,
                        value: EmailDeliveryType,
                        title: "Email",
                      },
                    ].map((o) => (
                      <Flex key={o.value} mr={2}>
                        <Button
                          variant={"secondary"}
                          sx={{
                            textAlign: "left",
                            borderWidth: values.deliveryType == o.value ? 2 : 1,
                            borderColor:
                              values.deliveryType == o.value
                                ? "brandLight"
                                : "gray.200",
                          }}
                          type="submit"
                          onClick={() => setFieldValue("deliveryType", o.value)}
                        >
                          <Flex
                            color={
                              values.deliveryType == o.value
                                ? "brandLight"
                                : "text"
                            }
                          >
                            {o.icon}
                            <Text mx={1}>{o.title}</Text>
                          </Flex>
                        </Button>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
                <Tabs width="100%" direction="rtl">
                  <TabList>
                    <Tab key="Setup">Settings</Tab>
                    <Tab key="Preview">Preview</Tab>
                  </TabList>
                  <TabPanel key="Setup">
                    <Flex
                      variant="box"
                      bg="gray.100"
                      px={4}
                      pt={3}
                      flexDirection="column"
                    >
                      <Flex justifyContent="flex-end">
                        <Link
                          fontSize="xs"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            let questions = resetQuestions(
                              values?.config?.followUpConditional,
                              values,
                              true
                            );

                            setFieldValue(`surveyQuestions`, questions);
                          }}
                        >
                          prefill
                        </Link>
                      </Flex>
                      <Flex flexDirection="column" flex={1}>
                        {values.deliveryType == EmailDeliveryType && (
                          <Flex>
                            <CustomFormGroup
                              placeholder="Email subject"
                              label="Subject"
                              name="config.subject"
                              value={values.config?.subject}
                              onChange={setFieldValue}
                              onBlur={handleBlur}
                              helperText={
                                errors && errors.subject && errors.subject
                              }
                            />
                          </Flex>
                        )}
                        <FieldArray
                          name="surveyQuestions"
                          render={(arrayHelpers) => (
                            <Flex flexDirection="column" flex={1}>
                              {values.surveyQuestions &&
                              values.surveyQuestions.length > 0
                                ? values.surveyQuestions
                                    .filter((q) => !q.isArchived)
                                    .map((field, index) => (
                                      <Flex key={index} flexDirection="column">
                                        <Flex
                                          alignItems="flex-start"
                                          my={2}
                                          flex={1}
                                        >
                                          <Flex flex={1}>
                                            <CustomFormGroup
                                              placeholder={getSuggestedQuestion(
                                                values.surveyType,
                                                field?.responseType
                                              )}
                                              label={
                                                index == 0 ? (
                                                  "Question"
                                                ) : (
                                                  <Flex alignItems="center">
                                                    <Text>
                                                      Follow up Question
                                                    </Text>
                                                    {index > 0 &&
                                                      values?.config
                                                        ?.followUpConditional && (
                                                        <>
                                                          <Text
                                                            mx={1}
                                                            variant="label"
                                                          >
                                                            On
                                                          </Text>
                                                          <Box mx={1}>
                                                            {
                                                              responseOptions.find(
                                                                (o) =>
                                                                  o.value ==
                                                                  field?.responseType
                                                              )?.label
                                                            }
                                                          </Box>
                                                          <Text
                                                            mx={1}
                                                            variant="label"
                                                          >
                                                            Response
                                                          </Text>
                                                        </>
                                                      )}
                                                  </Flex>
                                                )
                                              }
                                              name={`surveyQuestions[${index}].question`}
                                              value={field?.question}
                                              onChange={setFieldValue}
                                              onBlur={handleBlur}
                                              helperText={
                                                errors &&
                                                errors.surveyQuestions &&
                                                errors.surveyQuestions[index]
                                                  .question
                                              }
                                              type="textarea"
                                            />
                                          </Flex>
                                        </Flex>
                                        {index == 0 && (
                                          <Box>
                                            <CustomFormGroup
                                              label={
                                                "Conditional followup question?"
                                              }
                                              name={`config.followUpConditional`}
                                              value={
                                                values?.config
                                                  ?.followUpConditional
                                              }
                                              onChange={(name, value) => {
                                                setFieldValue(name, value);

                                                let questions = resetQuestions(
                                                  value,
                                                  values
                                                );

                                                setFieldValue(
                                                  `surveyQuestions`,
                                                  questions
                                                );
                                              }}
                                              type="switch"
                                            />
                                          </Box>
                                        )}
                                      </Flex>
                                    ))
                                : undefined}
                            </Flex>
                          )}
                        />
                        <Flex my={2} flexDirection="column">
                          <Box>
                            <CustomFormGroup
                              label="Custom thankyou"
                              name={`config.messageEnabled`}
                              value={values.config?.messageEnabled}
                              onChange={setFieldValue}
                              onBlur={handleBlur}
                              type="switch"
                            />
                          </Box>
                          {values.config?.messageEnabled && (
                            <CustomFormGroup
                              placeholder="What didn't you like about this product?"
                              label="Thank you message"
                              name={`config.message`}
                              value={values.config.message}
                              onChange={setFieldValue}
                              onBlur={handleBlur}
                              helperText={
                                errors &&
                                errors.config &&
                                errors.config?.message &&
                                errors.config?.message
                              }
                              type="richtext"
                            />
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </TabPanel>
                  <TabPanel key="Preview">
                    <Flex flex={1} variant="box" bg="gray.100" p={4}>
                      <SurveyPreview
                        data={values}
                        product={currentProduct?.currentProduct}
                      />
                    </Flex>
                  </TabPanel>
                </Tabs>
              </Flex>
              <Flex mt={2} alignItems="center">
                <Text variant="label">Created By</Text>
                <Text mx={1}>{values.createdBy?.name}</Text>
                <Text ml={3} variant="label">
                  Last Updated
                </Text>
                <Text mx={1}>
                  {moment(values.updatedAt).format("DD MMM YYYY")}
                </Text>
              </Flex>
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default SurveysForm;

const SurveyPreview = ({ data, product }) => {
  return data.deliveryType == EmailDeliveryType ? (
    <EmailPreview data={data} product={product} />
  ) : (
    <WebPreview data={data} product={product} />
  );
};

const WebPreview = ({ data, product }) => {
  return (
    <Flex flexDirection="column" flex={1} variant="box" bg="white">
      <Flex
        sx={{
          borderBottomColor: "gray.200",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
        px={2}
      >
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          alignItems="center"
          justifyContent="center"
          px={2}
          m={1}
        >
          <Text color="gray.600">
            <RiArrowLeftSLine />
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          m={1}
          alignItems="center"
          justifyContent="center"
          px={2}
        >
          <Text color="gray.600">
            <RiArrowRightSLine />
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          m={1}
          p={3}
          flex={4}
        />
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          m={1}
          alignItems="center"
          justifyContent="center"
          px={2}
        >
          <Text color="gray.600">
            <RiMore2Line />
          </Text>
        </Flex>
      </Flex>
      <Flex minHeight={200} mx={3} mt={2}>
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          mx={2}
          p={3}
          flex={2}
        />
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          mx={2}
          p={3}
          flex={1}
        />
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          mx={2}
          p={3}
          flex={1}
        />
      </Flex>
      <SurveyWidget data={data} product={product} preview />
    </Flex>
  );
};
WebPreview.propTypes = {
  data: PropTypes.object,
};
SurveyPreview.propTypes = {
  data: PropTypes.object,
};
const EmailPreview = ({ data, product }) => {
  const subject = data?.config?.subject || "Enter an email subject";
  const fromUser = product.fromName || "Userlot";
  const fromEmail = product.userlotDefaultEmail;

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex
        flexDirection="column"
        variant="box"
        bg="white"
        sx={{ borderRadius: "md" }}
        m={3}
        p={3}
      >
        <Flex flex={1} my={2}>
          <Text p={2} width={100}>
            From
          </Text>
          <Flex flex={1} mx={2} variant="box" p={2}>
            {fromUser} {`<${fromEmail}>`}
          </Flex>
        </Flex>
        <Flex flex={1} my={2}>
          <Text p={2} width={100}>
            Subject
          </Text>
          <Flex flex={1} mx={2} variant="box" p={2}>
            {subject}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        variant="box"
        bg="white"
        sx={{ borderRadius: "md" }}
        mx={3}
      >
        <SurveyWidget data={data} showUnsubscribe product={product} preview />
      </Flex>
    </Flex>
  );
};
EmailPreview.propTypes = {
  data: PropTypes.object,
};
const ColorEditor = ({ field, showColor, setFieldValue }) => {
  return showColor ? (
    <Flex
      sx={{
        position: "relative",
      }}
      flex={1}
    >
      <Flex
        sx={{
          position: "absolute",
          top: 15,
          left: 0,
          zIndex: 2,
        }}
        flexDirection="column"
        bg="white"
      >
        <TwitterPicker
          color={"#ff0000"}
          onChangeComplete={(color) => {
            setFieldValue(field, color?.hex);
          }}
        />
      </Flex>
    </Flex>
  ) : null;
};
ColorEditor.propTypes = {
  field: PropTypes.object,
  showColor: PropTypes.func,
  setFieldValue: PropTypes.func,
};
