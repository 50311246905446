import React from "react";
import Avatar from "react-avatar";
import { FaGripVertical } from "react-icons/fa";

import PropTypes from "prop-types";
import {
  RiBuilding2Line,
  RiCloseLine,
  RiSave2Line,
  RiCheckFill,
  RiAlarmWarningLine,
  RiArrowRightLine,
  RiArrowLeftLine,
  RiFlashlightLine,
  RiEmotionHappyLine,
  RiThumbUpLine,
  RiCalendar2Line,
  RiMailLine,
  RiPhoneLine,
  RiTimeLine,
  RiTimer2Line,
  RiMoneyDollarBoxLine,
  RiMap2Line,
  RiQuestionLine,
  RiEyeLine,
  RiEyeOffLine,
  RiArrowUpLine,
  RiArrowDownLine,
  RiAddFill,
  RiLinksLine,
  RiStarLine,
  RiStarFill,
  RiDeleteBin2Line,
  RiImage2Fill,
  RiDownload2Fill,
  RiSlackFill,
  RiFileUploadLine,
  RiShareFill,
  RiLogoutBoxLine,
  RiFilter3Fill,
  RiUserLine,
  RiNotification2Line,
  RiSettings3Line,
  RiArrowDropDownFill,
  RiPushpin2Line,
  RiCodeSLine,
  RiHeart3Fill,
  RiHeartLine,
  RiCheckboxCircleLine,
  RiCheckboxBlankCircleLine,
  RiChatSmile2Line,
  RiHomeGearLine,
  RiStackshareLine,
  RiUserSharedLine,
  RiArrowUpDownLine,
  RiSwapBoxLine,
  RiExchangeDollarLine,
  RiMore2Line,
  RiSubtractLine,
  RiFileCopyLine,
  RiFilterFill,
  RiFilterOffLine,
  RiBook2Line,
  RiGitBranchLine,
  RiInformationLine,
  RiRefreshLine,
  RiErrorWarningLine,
  RiArrowDownSLine,
  RiPushpinLine,
  RiSearch2Line,
  RiHeart3Line,
  RiArrowDropUpFill,
  RiContactsBook2Line,
  RiEdit2Line,
  RiLock2Line,
  RiFilter2Line,
  RiLockUnlockLine,
  RiTaskLine,
  RiUserHeartLine,
  RiFileList2Line,
  RiUser5Line,
  RiSettingsLine,
  RiDashboard3Line,
  RiUserShared2Line,
  RiLineChartLine,
  RiMailSendLine,
  RiMailStarLine,
  RiMailOpenLine,
  RiMailCheckLine,
  RiCustomerService2Line,
  RiHomeLine,
  RiHomeHeartLine,
  RiUser2Fill,
  RiSurveyLine,
  RiLayoutColumnLine,
  RiTableLine,
  RiPriceTag3Line,
  RiTicketLine,
  RiTreasureMapLine,
  RiGroupLine,
  RiExternalLinkLine,
  RiShareForwardLine,
  RiFilter2Fill,
} from "react-icons/ri";
import { TiTick, TiWarningOutline } from "react-icons/ti";
import { Flex, Image, Text } from "rebass";

export const IconComponentMapping = {
  RiCheckFill,
  RiCalendar2Line,
  RiMailLine,
  RiPhoneLine,
  RiTimeLine,
  RiTimer2Line,
  RiMoneyDollarBoxLine,
  RiMap2Line,
  RiStarLine,
  RiStarFill,
  RiImage2Fill,
  RiUserLine,
  RiPushpin2Line,
  RiCodeSLine,
  RiHeartLine,
  RiCheckboxCircleLine,
  RiChatSmile2Line,
  RiUserSharedLine,
  RiSwapBoxLine,
  RiExchangeDollarLine,
  RiBook2Line,
  RiPushpinLine,
  RiContactsBook2Line,
  RiTaskLine,
  RiUserHeartLine,
  RiFileList2Line,
  RiUser5Line,
  RiDashboard3Line,
  RiUserShared2Line,
  RiLineChartLine,
  RiMailSendLine,
  RiCustomerService2Line,
  RiHomeLine,
  RiHomeHeartLine,
  RiUser2Fill,
  RiSurveyLine,
  RiArrowRightLine,
  RiArrowLeftLine,
  RiArrowUpLine,
  RiArrowDownLine,
};
export const ExternalLinkIcon = (props) => (
  <RiShareForwardLine size={16} {...props} />
);
export const LifeCycleIcon = (props) => <RiSwapBoxLine size={16} {...props} />;

export const CustomIcon = ({ componentName, size, color }) => {
  const Component = IconComponentMapping[componentName];
  return componentName && !!Component ? (
    <Text color={color}>
      <Component size={size || 16} />{" "}
    </Text>
  ) : null;
};
export const UserlotIcon = (props) => (
  <Image
    src={require("assets/images/logo.png")}
    sx={{ height: props.height || 60 }}
  />
);
export const DelayIcon = (props) => <RiTimeLine size={16} {...props} />;
export const HomeSettingsIcon = (props) => (
  <RiHomeGearLine size={16} {...props} />
);
export const ProductSettingsIcon = (props) => (
  <Flex alignItems="center" justifyContent="center">
    <RiCodeSLine size={16} {...props} />
    <Flex
      sx={{ position: "absolute" }}
      alignItems="center"
      justifyContent="center"
    >
      <SettingIcon {...props} size={8} />
    </Flex>
  </Flex>
);

export const ContactsIcon = (props) => (
  <RiContactsBook2Line size={16} {...props} />
);
export const HomeIcon = (props) => <RiHomeHeartLine size={16} {...props} />;
export const ConversionIcon = (props) => {
  const size = props.size || 16;
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative" }}
    >
      <RiUserShared2Line {...props} size={size} />
      <Flex
        sx={{ position: "absolute", bottom: 0, right: 0 }}
        alignItems="center"
        justifyContent="center"
        bg="white"
      >
        <RiFilter3Fill {...props} size={size * 0.5} />
      </Flex>
    </Flex>
  );
};
ConversionIcon.propTypes = {
  size: PropTypes.number,
};
export const ColumnIcon = (props) => <RiTableLine size={16} {...props} />;
export const ListIcon = (props) => <RiFileList2Line size={16} {...props} />;
export const SummaryIcon = (props) => <RiFileList2Line size={16} {...props} />;
export const AutomationIcon = (props) => (
  <RiSettingsLine size={16} {...props} />
);
export const ExitIcon = (props) => <RiLogoutBoxLine size={16} {...props} />;
export const ShareIcon = (props) => <RiShareFill size={16} {...props} />;
export const SlackIcon = (props) => <RiSlackFill size={16} {...props} />;
export const NotificationIcon = (props) => (
  <RiNotification2Line size={16} {...props} />
);
export const SurveyIcon = (props) => <RiSurveyLine size={16} {...props} />;
export const ImportIcon = (props) => <RiFileUploadLine size={16} {...props} />;

export const CheckCircleIcon = (props) => (
  <RiCheckboxCircleLine size={16} {...props} />
);
export const CheckCircleOffIcon = (props) => (
  <RiCheckboxBlankCircleLine size={16} {...props} />
);
export const ImageIcon = (props) => <RiImage2Fill size={16} {...props} />;
export const MoveIcon = (props) => <FaGripVertical size={16} {...props} />;
export const DownloadIcon = (props) => <RiDownload2Fill size={16} {...props} />;
export const TrashIcon = (props) => <RiDeleteBin2Line size={16} {...props} />;

export const FilterOnIcon = (props) => <RiFilterFill size={16} {...props} />;
export const FilterOffIcon = (props) => (
  <RiFilterOffLine size={16} {...props} />
);
export const SearchIcon = (props) => <RiSearch2Line size={16} {...props} />;
export const SettingIcon = (props) => <RiSettings3Line size={16} {...props} />;
export const StarIcon = (props) => <RiStarFill size={16} {...props} />;
export const StarOffIcon = (props) => <RiStarLine size={16} {...props} />;
export const PinIcon = (props) => <RiPushpin2Line size={16} {...props} />;
export const UnPinIcon = (props) => <RiPushpinLine size={16} {...props} />;
export const LinkIcon = (props) => <RiLinksLine size={16} {...props} />;
export const SegmentIcon = (props) =>
  props.selected ? (
    <RiFilter2Fill size={16} {...props} />
  ) : (
    <RiFilter2Line size={16} {...props} />
  );
export const PlusIcon = (props) => <RiAddFill size={16} {...props} />;
export const RefreshIcon = (props) => <RiRefreshLine size={16} {...props} />;

export const MoreIcon = (props) => <RiMore2Line size={16} {...props} />;
export const EyeIcon = (props) => <RiEyeLine size={16} {...props} />;
export const EyeOffIcon = (props) => <RiEyeOffLine size={16} {...props} />;
export const HelpIcon = (props) => <RiQuestionLine size={16} {...props} />;
export const MapIcon = (props) => <RiMap2Line size={16} {...props} />;
export const MoneyIcon = (props) => (
  <RiMoneyDollarBoxLine size={16} {...props} />
);
export const CalendarIcon = (props) => <RiCalendar2Line size={16} {...props} />;
export const PlaybookIcon = (props) => <RiBook2Line size={16} {...props} />;
export const WorkflowIcon = (props) => <RiGitBranchLine size={16} {...props} />;
export const MailIcon = (props) => <RiMailLine size={16} {...props} />;
export const PriorityMailIcon = (props) => (
  <RiMailStarLine size={16} {...props} />
);
export const CompletedMailIcon = (props) => (
  <RiMailCheckLine size={16} {...props} />
);
export const OpenMailIcon = (props) => <RiMailOpenLine size={16} {...props} />;
export const InboxIcon = (props) => <RiMailSendLine size={16} {...props} />;
export const SupportIcon = (props) => (
  <RiCustomerService2Line size={16} {...props} />
);
export const PhoneIcon = (props) => <RiPhoneLine size={16} {...props} />;
export const TimeIcon = (props) => <RiTimeLine size={16} {...props} />;
export const TimerIcon = (props) => <RiTimer2Line size={16} {...props} />;

export const MinusIcon = (props) => <RiSubtractLine size={16} {...props} />;
export const ArrowUpIcon = (props) => (
  <RiArrowDropUpFill size={20} {...props} />
);
export const ArrowDownIcon = (props) => (
  <RiArrowDropDownFill size={20} {...props} />
);
export const JourneyIcon = (props) => (
  <RiTreasureMapLine size={16} {...props} />
);
export const ArrowDropDownIcon = (props) => (
  <RiArrowDropDownFill size={16} {...props} />
);
export const ArrowRightIcon = (props) => (
  <RiArrowRightLine size={16} {...props} />
);
export const ArrowLeftIcon = (props) => (
  <RiArrowLeftLine size={16} {...props} />
);
export const EventIcon = (props) => <RiFlashlightLine size={16} {...props} />;
export const MessageIcon = (props) => <RiChatSmile2Line size={16} {...props} />;
export const SatisfactionIcon = (props) => (
  <RiEmotionHappyLine size={16} {...props} />
);

export const CopyIcon = (props) => <RiFileCopyLine size={16} {...props} />;
export const SharedIcon = (props) => <RiLockUnlockLine size={16} {...props} />;
export const PrivateIcon = (props) => <RiLock2Line size={16} {...props} />;
export const SentimentIcon = (props) => <RiThumbUpLine size={16} {...props} />;
export const EditIcon = (props) => <RiEdit2Line size={16} {...props} />;
export const SaveIcon = (props) => <RiSave2Line size={16} {...props} />;
export const CloseIcon = (props) => <RiCloseLine size={16} {...props} />;
export const UserIcon = (props) => <RiUserLine size={16} {...props} />;
export const TeamIcon = (props) => <RiGroupLine size={16} {...props} />;
export const TagIcon = (props) => <RiPriceTag3Line size={16} {...props} />;

export const UsersIcon = (props) => <RiUser5Line size={16} {...props} />;
export const ScoreIcon = (props) => <RiThumbUpLine size={16} {...props} />;
export const TicketIcon = (props) => <RiTicketLine size={16} {...props} />;
export const ProductIcon = (props) => <RiCodeSLine size={16} {...props} />;
export const CompanyIcon = (props) => {
  return props.logo ? (
    <Avatar src={props.logo} size="30" />
  ) : (
    <RiBuilding2Line size={16} {...props} />
  );
};
CompanyIcon.propTypes = {
  logo: PropTypes.string,
};
export const OwnerChangeIcon = (props) => (
  <Flex
    sx={{ position: "relative", width: 20, height: 20 }}
    alignItems="flex-end"
  >
    <Flex sx={{ position: "absolute", bottom: 0 }} color="white">
      <RiUser2Fill size={20} {...props} />
    </Flex>
    <Flex
      sx={{ position: "absolute", bottom: 0, right: 0, borderRadius: 999 }}
      alignItems="center"
      justifyContent="center"
      bg="brandLight"
      color="white"
    >
      <PlusIcon {...props} size={10} />
    </Flex>
  </Flex>
);
export const CompanyAddIcon = (props) => (
  <Flex sx={{ position: "relative" }} alignItems="flex-end">
    <RiBuilding2Line size={16} {...props} />
    <PlusIcon {...props} size={8} />
  </Flex>
);
export const CompanyChurnedIcon = (props) => (
  <Flex sx={{ position: "relative" }} alignItems="flex-end">
    <RiBuilding2Line size={16} {...props} />
    <MinusIcon {...props} size={8} />
  </Flex>
);
export const CompanyHealthIcon = (props) => (
  <RiUserHeartLine size={16} {...props} />
);
export const HealthIcon = (props) => (
  <Text color={props.color}>
    <RiHeart3Line size={16} {...props} />
  </Text>
);
export const ProductHealthIcon = (props) => (
  <Flex alignItems="center" justifyContent="center">
    <RiCodeSLine size={16} {...props} />
    <Flex
      sx={{ position: "absolute" }}
      alignItems="center"
      justifyContent="center"
    >
      <RiHeart3Fill {...props} size={8} />
    </Flex>
  </Flex>
);
export const ProductLogo = (props) => (
  <Flex alignItems="center" justifyContent="center">
    <Avatar
      src={props.product?.logoUrl}
      size="30"
      name={`${props.product?.name}`}
      round
      textSizeRatio={2}
    />
  </Flex>
);
ProductLogo.propTypes = {
  product: PropTypes.object,
};
export const TasksIcon = (props) => <RiTaskLine size={16} {...props} />;
export const ReportIcon = (props) => <RiLineChartLine size={16} {...props} />;
export const InfoIcon = (props) => <RiInformationLine size={16} {...props} />;
export const SuccessIcon = (props) => <RiCheckFill size={16} {...props} />;
export const WarningIcon = (props) => (
  <Text color={props.color}>
    <RiAlarmWarningLine size={16} {...props} />
  </Text>
);
WarningIcon.propTypes = {
  color: PropTypes.string,
};
export const TickIcon = (props) => <TiTick size={16} {...props} />;
export const WarningSignIcon = (props) => (
  <TiWarningOutline size={16} {...props} />
);

export const WebhookIcon = (props) => <RiStackshareLine size={16} {...props} />;
export const AssignOwnerIcon = (props) => (
  <Text color={props.color}>
    <RiUserSharedLine size={16} {...props} />
  </Text>
);
AssignOwnerIcon.propTypes = {
  color: PropTypes.string,
};
export const StatusChangeIcon = (props) => (
  <Text color={props.color}>
    <RiExchangeDollarLine size={16} {...props} />
  </Text>
);
StatusChangeIcon.propTypes = {
  color: PropTypes.string,
};
export const StageChangeIcon = (props) => (
  <Text color={props.color}>
    <RiSwapBoxLine size={16} {...props} />
  </Text>
);
StageChangeIcon.propTypes = {
  color: PropTypes.string,
};
export const HealthChangeIcon = (props) => {
  const size = props.size || 16;
  const innerSize = size / 2;
  return (
    <Text color={props.color}>
      <Flex
        alignItems="center"
        justifyContent="center"
        sx={{ position: "relative" }}
      >
        <RiHeartLine size={size} {...props} />
        <Flex
          sx={{ position: "absolute" }}
          alignItems="center"
          justifyContent="center"
        >
          <RiArrowUpDownLine {...props} size={innerSize} />
        </Flex>
      </Flex>
    </Text>
  );
};
HealthChangeIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
export const WorkflowTypeIcon = (props) => {
  const { type } = props;
  return (
    <Text {...props}>
      {type === "0" ? (
        <PlaybookIcon />
      ) : type === "1" ? (
        <WorkflowIcon />
      ) : (
        <MailIcon />
      )}
    </Text>
  );
};
WorkflowTypeIcon.propTypes = {
  type: PropTypes.string,
};
export const CriticalIcon = (props) => (
  <RiErrorWarningLine size={16} {...props} />
);
export const PriorityIcon = (props) => {
  const size = props.size || 16;
  switch (Number(props.value)) {
    case 1:
      return (
        <Text {...props} color="gray.500">
          <RiArrowDownSLine size={size} />
          {props.detailed && <Text>Lowest</Text>}
        </Text>
      );
    case 2:
      return (
        <Text {...props} color="brand">
          <RiArrowDownLine size={size} />
          {props.detailed && <Text>Low</Text>}
        </Text>
      );
    case 4:
      return (
        <Text {...props} color="red">
          <RiArrowUpLine size={size} />
          {props.detailed && <Text>High</Text>}
        </Text>
      );
    case 5:
      return (
        <Text {...props} color="red">
          <RiErrorWarningLine size={size} />
          {props.detailed && <Text>Critical</Text>}
        </Text>
      );
    default:
      return props.detailed ? <Text>Normal</Text> : null;
  }
};
PriorityIcon.propTypes = {
  detailed: PropTypes.bool,
  value: PropTypes.number,
  size: PropTypes.number,
};

export const IntegrationIcon = (props) => {
  const logo = props.logoUrl;
  return (
    <Image
      src={require(`assets/app_logos/${logo}`)}
      sx={{ height: "25px", objectFit: "contain" }}
    />
  );
};
IntegrationIcon.propTypes = {
  logoUrl: PropTypes.string,
};
