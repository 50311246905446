import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import { Flex, Image, Text, Button, Box } from "rebass";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import {
  ArrowDropDownIcon,
  FilterOffIcon,
  FilterOnIcon,
} from "Components/Common/Icons";

export const mailOptionTypes = [
  {
    label: "Company",
    value: "company",
    mail: true,
  },
  {
    label: "End User",
    value: "endUser",
    mail: true,
  },
];

function InboxFilter(props) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);
  const [mailOptions, setMailOptions] = useState(props.types);
  return (
    <Flex sx={{ position: "relative" }}>
      <SecondaryButton
        leftIcon={
          mailOptions?.length === 0 ? (
            <Text color="gray.400">
              <FilterOffIcon size={12} />
            </Text>
          ) : (
            <Text color="brandLight">
              <FilterOnIcon size={12} onClick={() => setMailOptions([])} />
            </Text>
          )
        }
        rightIcon={<ArrowDropDownIcon color="brandLight" />}
        dropdownToggle
        onClick={() => setShowMenu(!showMenu)}
      >
        Filter
      </SecondaryButton>
      {/*showMenu && (
        <Flex
          p={3}
          bg="white"
          sx={{
            borderColor: "gray.100",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: 99,
            position: "absolute",
            top: 40,
            width: 200,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[500]}`,
          }}
          flexDirection="column"
        >
          <CheckboxGroupSwitch
            name="mailOptions"
            selectedOptions={mailOptions}
            options={mailOptionTypes}
            onChange={(name, value) => {
              let types = _.cloneDeep(mailOptions);
              let idx = types.indexOf(value);
              if (idx < 0) {
                types.push(value);
              } else {
                types.splice(idx, 1);
              }
              setMailOptions(types);
              props.onChange(types);
            }}
          />
        </Flex>
          )*/}
    </Flex>
  );
}

export default InboxFilter;
