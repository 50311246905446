import React from "react";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import { useTheme } from "emotion-theming";
import { Flex, Box, Text, Link, Button } from "rebass";
import PieGraph from "Components/Common/Graphs/PieGraph";
import { getSegments } from "hooks/getSegments";
import { useMutation } from "@apollo/react-hooks";
import SearchFilterMutation from "../../graphql/mutations/SearchFilter";
import { getSearchFilter } from "hooks/getSearchFilter";
import SearchFilterForm from "../Filter/Segment/SegmentForm";
import { useState } from "react";
const _ = require("lodash");
var numeral = require("numeral");
import { Menu, Item, animation, MenuProvider } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { MODEL_TYPES } from "consts";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  SegmentIcon,
} from "Components/Common/Icons";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
const menuItems = {
  name: "Name",
  count: "Account Count",
  value: "Account Value",
  activity: "Account Activity",
};

function SegmentSummary(props) {
  const theme = useTheme();
  const { data, refetch } = getSegments(MODEL_TYPES.Company.name);
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState("name");
  const [orderDirection, setOrderDirection] = useState(1);
  const [segmentId, setSegmentId] = useState(undefined);
  const [saveFilter, {}] = useMutation(SearchFilterMutation);
  const filterQuery = getSearchFilter();
  const saveSegmentFilter = (segmentId) => {
    saveFilter({
      variables: {
        filters: {
          segmentId: segmentId,
        },
      },
    }).then(
      (response) => {
        filterQuery.refetch();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const segments =
    data?.segments
      ?.sort((a, b) => {
        return a[orderBy] < b[orderBy]
          ? -1 * orderDirection
          : a[orderBy] > b[orderBy]
          ? 1 * orderDirection
          : 0;
      })
      .map((segment) => {
        let scoreValue = segment.valueByHealthScore;
        let scoreCount = segment.countByHealthScore;
        return {
          id: segment.id,
          name: segment.name,
          count: segment.count,
          value: numeral(segment.value).format("0.0a"),
          data: segment.activity,
          scores: [
            {
              label: `${numeral(scoreValue[0]).format("0.0a")} (${
                scoreCount[0]
              } Accounts)`,
              value: scoreCount[0],
            },
            {
              label: `${numeral(scoreValue[1]).format("0.0a")} (${
                scoreCount[1]
              } Accounts)`,
              value: scoreCount[1],
            },
            {
              label: `${numeral(scoreValue[2]).format("0.0a")} (${
                scoreCount[2]
              } Accounts)`,
              value: scoreCount[2],
            },
          ],
        };
      }) || [];

  const selectOrderBy = (field) => {
    if (orderBy === field) {
      setOrderDirection(orderDirection * -1);
    } else {
      setOrderDirection(1);
    }
    setOrderBy(field);
  };
  return (
    <Flex width="100%" flexDirection="column">
      <Flex justifyContent="space-between">
        <Flex>
          <MenuProvider id="menu_id" event="onClick">
            <Button variant="ghost">
              <Flex alignItems="center">
                {orderDirection > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}
                <Text mx={1}>{menuItems[orderBy]}</Text>
              </Flex>
            </Button>
          </MenuProvider>
          <Menu id="menu_id" animation={animation.zoom}>
            <Item onClick={() => selectOrderBy("name")}>
              <Flex alignItems="center">
                <Text mx={1}>Name</Text>
                {orderBy === "name" &&
                  (orderDirection > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />)}
              </Flex>
            </Item>
            <Item onClick={() => selectOrderBy("count")}>
              <Flex alignItems="center">
                <Text mx={1}>Account Count</Text>
                {orderBy === "count" &&
                  (orderDirection > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />)}
              </Flex>
            </Item>
            <Item onClick={() => selectOrderBy("value")}>
              <Flex alignItems="center">
                <Text mx={1}>Account Value</Text>
                {orderBy === "value" &&
                  (orderDirection > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />)}
              </Flex>
            </Item>
            <Item onClick={() => selectOrderBy("activity")}>
              <Flex alignItems="center">
                <Text mx={1}>Activity</Text>
                {orderBy === "activity" &&
                  (orderDirection > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />)}
              </Flex>
            </Item>
          </Menu>
        </Flex>
        <Button
          variant="ghost"
          onClick={() => {
            setOpen(true);
            setSegmentId(undefined);
          }}
        >
          <Flex alignItems="center">
            <SegmentIcon />
            <Text mx={2}>Create Segment</Text>
          </Flex>
        </Button>
      </Flex>
      {segments?.length === 0 && (
        <Box width="100%">
          <Text variant="muted" textAlign="center">
            No segments have been set up
          </Text>
        </Box>
      )}
      {segments.map((segment, key) => {
        return (
          <Flex key={key} m={2} variant="box" p={2} alignItems="center">
            <Flex width={2 / 10} alignItems="center" flexDirection="column">
              <Link href="#" onClick={() => saveSegmentFilter(segment.id)}>
                <Text variant="h3">{segment.name}</Text>
              </Link>
              <Flex justifyContent="flex-end">
                <Button
                  variant="ghost"
                  onClick={() => {
                    setSegmentId(segment.id);
                    setOpen(true);
                  }}
                >
                  <Flex alignItems="center" color="secondaryLight">
                    <SegmentIcon size={12} />
                    <Text mx={1} variant="muted" color="secondaryLight">
                      Edit
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
            <Box width={1 / 10}>
              <Text textAlign="center">{segment.count}</Text>
              <Text textAlign="center" variant="label">
                Accounts
              </Text>
            </Box>
            <Box width={1 / 10}>
              <Text textAlign="center">${segment.value}</Text>
              <Text textAlign="center" variant="label">
                Total Value
              </Text>
            </Box>
            <Box width={3 / 10}>
              <Flex>
                <Box mx={2}>
                  <DonutGraph data={segment.scores} width={100} />
                  <Text variant="label" textAlign="center">
                    Health
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box width={3 / 10}>
              <Sparklines data={segment.data} height={20}>
                <SparklinesLine color={theme.colors.green} />
                <SparklinesSpots />
              </Sparklines>
              <Text variant="label" textAlign="center">
                Activity
              </Text>
            </Box>
          </Flex>
        );
      })}
      <SearchFilterForm
        open={open}
        onClose={() => {
          refetch();
          setOpen(false);
        }}
        segmentId={segmentId}
      />
    </Flex>
  );
}

export default SegmentSummary;
