import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import { useTheme } from "emotion-theming";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import WidgetWrapper from "./WidgetWrapper";
import Loader from "../../Common/Loader";

const _ = require("lodash");
const numeral = require("numeral");

function MTDGrowth(props) {
  const theme = useTheme();
  const mrrData = props.mrrData;
  const companyTypes = ["existing", "new", "churned"];
  const colors = [
    theme.colors.brandLight,
    theme.colors.brandHighlight,
    theme.colors.red,
  ];

  const latestData = companyTypes.map((ev, i) => {
    const lastItem = _.first(
      mrrData?.filter(
        (d) => d.type === ev && moment(d.date).isSame(moment(), "month")
      )
    );
    return {
      name: ev,
      value: lastItem && lastItem["value"],
    };
  });

  const totalLatestValue = _.sumBy(latestData, "value");
  return (
    <WidgetWrapper title="MTD Growth">
      {props.loading ? (
        <Loader />
      ) : totalLatestValue > 0 ? (
        <Flex m={2} width="100%" flexDirection="column" alignItems="center">
          <Flex height="20px" width="100%">
            {latestData.map((d, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    borderTopLeftRadius: i === 0 && 8,
                    borderBottomLeftRadius: i === 0 && 8,
                    borderTopRightRadius: i === latestData.length - 1 && 8,
                    borderBottomRightRadius: i === latestData.length - 1 && 8,
                  }}
                  width={d.value / totalLatestValue}
                  bg={colors[i]}
                />
              );
            })}
          </Flex>
          <Flex flex={1} flexWrap="wrap">
            {latestData.map((d, i) => {
              return (
                <Flex key={i} alignItems="center" my={1}>
                  <Flex
                    m={1}
                    sx={{ width: 10, height: 10, borderRadius: 99 }}
                    bg={colors[i]}
                  />
                  <Text mx={1} variant="muted">
                    {d.name}
                  </Text>
                  <Text mx={1} fontWeight="bold" fontSize="sm">
                    {numeral(d.value).format("$0.00a")}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      ) : (
        <Flex flex={1} alignItems="center" justifyContent="center" p={3}>
          <Image src={require("assets/images/MTDEmpty.png")} width={120} />
        </Flex>
      )}
    </WidgetWrapper>
  );
}

export default MTDGrowth;
