import React from "react";
import { Flex, Text, Box, Link } from "rebass";
import { useQuery } from "@apollo/react-hooks";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { getEventGroups } from "hooks/getEventGroups";
import { getSearchFilter } from "hooks/getSearchFilter";
import Table from "Components/Common/Table";
import LineGraph from "Components/Common/Graphs/LineGraph";
import BarGraph from "Components/Common/Graphs/BarGraph";
import Loader from "Components/Common/Loader";
import TableEmpty from "Components/Data/TableEmpty";
import DoubleStatCard from "Components/Dashboard/Widgets/DoubleStatCard";
import EventsDashboardQuery from "../../../graphql/queries/EventsDashboard";
import { convertNumber } from "consts";
import { getEventCategories } from "../../../hooks/getEventCategories";
import TimeSpent from "./TimeSpent";
import Legend from "../../Common/Graphs/Legend";

const _ = require("lodash");

const columns = [
  {
    name: "Event",
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => {
      return (
        <RouterLink
          data-tag="allowRowEvents"
          to={`/adoption/events/${row.key}`}
          component={Link}
        >
          {row.name}
        </RouterLink>
      );
    },
  },
  {
    name: (
      <Box>
        <Text>Count</Text>
        <Text fontSize={"xxs"}>Events</Text>
      </Box>
    ),
    selector: (row) => row.count,
    sortable: true,
    width: "80px",
    cell: (row) => convertNumber(row.count, 0),
  },
  {
    name: (
      <Box>
        <Text>Avg/Day</Text>
        <Text fontSize={"xxs"}>Events</Text>
      </Box>
    ),
    selector: (row) => row.avg,
    sortable: true,
    width: "80px",
    cell: (row) => convertNumber(row.avg),
  },
  {
    name: (
      <Box>
        <Text>Count</Text>
        <Text fontSize={"xxs"}>Accounts</Text>
      </Box>
    ),
    selector: (row) => row.companyCount,
    sortable: true,
    width: "80px",
    cell: (row) => convertNumber(row.companyCount, 0),
  },
  {
    name: (
      <Box>
        <Text>Avg/Day</Text>
        <Text fontSize={"xxs"}>Accounts</Text>
      </Box>
    ),
    selector: (row) => row.companyAvg,
    sortable: true,
    width: "80px",
    cell: (row) => convertNumber(row.companyAvg),
  },
  {
    name: (
      <Box>
        <Text>Count</Text>
        <Text fontSize={"xxs"}>Users</Text>
      </Box>
    ),
    selector: (row) => row.userCount,
    sortable: true,
    width: "80px",
    cell: (row) => convertNumber(row.userCount, 0),
  },
  {
    name: (
      <Box>
        <Text>Avg/Day</Text>
        <Text fontSize={"xxs"}>Users</Text>
      </Box>
    ),
    width: "80px",
    selector: (row) => row.userAvg,
    sortable: true,
    cell: (row) => convertNumber(row.userAvg),
  },
];

function Events(props) {
  const history = useHistory();
  const { loading, data } = getEventCategories();

  const { data: searchFilters } = getSearchFilter();

  const filters = {
    segmentId: searchFilters?.filters?.segmentId,
    ownerUserId: searchFilters?.filters?.ownerUserId,
    daysOffset: props.daysAgo?.value,
  };

  const eventDashboard = useQuery(EventsDashboardQuery, {
    variables: {
      searchFilters: {
        ...filters,
      },
    },
    fetchPolicy: "network-only",
  });

  const eventSummary = eventDashboard?.data?.eventsDashboard?.eventSummary;

  const timeSpentCategory = data?.eventCategories.find(
    (c) => c.label === "time-spent"
  );
  const graphData = data?.eventCategories
    .filter((d) => d.value != timeSpentCategory?.id)
    .map((ev, i) => {
      let d0 = {
        name: ev.label,
        data: eventSummary?.map((d) => {
          const x = d.eventGroups?.find((g) => g.value === ev.id);
          return {
            date: d.date,
            count: x ? x.count : 0,
          };
        }),
      };
      return d0.data && d0.data?.filter((d1) => d1.count > 0).length > 0
        ? d0
        : undefined;
    })
    .filter((d0) => !!d0);
  const flattened = _.flatten(
    eventSummary
      ?.map((d) => {
        return d.eventGroups
          .filter((dd) => dd.value != timeSpentCategory?.id)
          .map((dd) => {
            return {
              name: data?.eventCategories.find((ev) => ev.id === dd.value)
                ?.label,
              count: dd.count,
            };
          });
      })
      .filter((d0) => !!d0)
  );
  const kpiAggregate =
    data &&
    _(flattened)
      .groupBy("name")
      .map((objs, key) => ({
        name: key,
        count: _.sumBy(objs, "count"),
      }))
      .value()
      .sort((a, b) => b.count - a.count);

  return (
    <Flex flexDirection="column">
      <Flex variant="box" justifyContent="space-between">
        {eventDashboard.loading && <Loader />}
        {eventDashboard.data?.eventsDashboard?.data?.map((event, idx) => (
          <Flex key={idx} flex={1}>
            {idx > 0 && (
              <Box
                sx={{
                  borderRightColor: "accent",
                  borderRightStyle: "solid",
                  borderRightWidth: 1,
                }}
                my={3}
              />
            )}
            <Flex flex={1} flexDirection="column">
              <Text mx={3} my={2} variant="h4" color="brandLight">
                {event.name}
              </Text>
              <DoubleStatCard
                value={convertNumber(event.total, 0)}
                value2={convertNumber(event.avgDaily)}
                label="Total"
                label2="Daily AVG"
                increase={event.increase}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Flex>
        <Flex mt={4} flex={1} flexDirection="column">
          <Box px={3}>
            <Text variant="h4" color="brandLight">
              Events trending
            </Text>

            {eventDashboard.loading ? (
              <Loader />
            ) : (
              <>
                <LineGraph
                  days={props.daysAgo?.value || 90}
                  series={_.flatten(graphData)}
                  height={200}
                  ytitle="Count"
                  noLegend
                />
                <Legend series={_.flatten(graphData)} />
              </>
            )}
          </Box>
          <Box px={3} mt={4}>
            <Text variant="h4" color="brandLight">
              Top 5 events
            </Text>
            {eventDashboard.loading ? (
              <Loader />
            ) : (
              <>
                <BarGraph
                  series={_.slice(kpiAggregate, 0, 5)}
                  height={200}
                  noLegend
                />
                <Legend series={_.slice(kpiAggregate, 0, 5)} />
              </>
            )}
          </Box>
          <Box m={3} mt={4}>
            <Text variant="h4" color="brandLight">
              Summary
            </Text>
            <Table
              title=""
              defaultSortField="count"
              selectableRows={false}
              columns={columns}
              data={eventDashboard.data?.eventsDashboard?.summary}
              loading={eventDashboard.loading}
              onRowClicked={(row) =>
                history.push(`/adoption/events/${row.key}`)
              }
              noDataText={
                <TableEmpty help="No data returned, check selected filters." />
              }
            />
          </Box>
        </Flex>
        <Flex width={400}>
          <TimeSpent daysAgo={props.daysAgo} />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Events;
