import React from "react";
import PropTypes from "prop-types";
import { Button, Text, Flex } from "rebass";

const GhostButton = ({
  leftIcon,
  rightIcon,
  onClick,
  children,
  active,
  disabled,
  sx,
}) => {
  return (
    <Button
      variant="ghost"
      onClick={onClick}
      sx={sx}
      disabled={!!disabled}
      bg={active ? "gray.200" : "inherit"}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        color={disabled ? "gray.500" : "inherit"}
      >
        {leftIcon && leftIcon}

        <Text ml={leftIcon && 1} mr={rightIcon && 2}>
          {children}
        </Text>
        {rightIcon && rightIcon}
      </Flex>
    </Button>
  );
};

GhostButton.propTypes = {
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default GhostButton;
