import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text, Button } from "rebass";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import {
  ArrowDropDownIcon,
  MessageIcon,
  PlusIcon,
} from "Components/Common/Icons";
import PropTypes from "prop-types";
import MenuModal from "./MenuModal";
import { TaskCategories } from "Components/CommandCenter/Tasks/TaskDefaults";

function AddHistoryMenu({ id, parentType, onSave }) {
  const categories = TaskCategories();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [taskCategory, setTaskCategory] = useState(undefined);
  return (
    <Flex sx={{ position: "relative" }}>
      <PrimaryButton
        dropdownToggle
        onClick={() => setOpenMenu(!openMenu)}
        rightIcon={<ArrowDropDownIcon />}
        leftIcon={<PlusIcon />}
      >
        Log
      </PrimaryButton>
      {openMenu && (
        <Flex
          p={3}
          bg="white"
          flexDirection="column"
          sx={{
            borderColor: "gray.100",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: 99,
            position: "absolute",
            top: 35,
            width: 175,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[500]}`,
          }}
        >
          <Button
            variant="ghost"
            onClick={() => {
              setOpen("comment");
              setOpenMenu(!openMenu);
            }}
          >
            <Flex alignItems="center">
              <Text textAlign="center" color="brandLight">
                <MessageIcon />
              </Text>
              <Text fontSize="sm" mx={1}>
                Note
              </Text>
            </Flex>
          </Button>

          {categories
            .filter((a) => a.activity)
            .map((a) => (
              <Button
                key={a.id}
                variant="ghost"
                onClick={() => {
                  setTaskCategory(a);
                  setOpen("activity");
                  setOpenMenu(!openMenu);
                }}
              >
                <Flex alignItems="center">
                  <Text textAlign="center" color="brandLight">
                    {a.icon}
                  </Text>
                  <Text fontSize="sm" mx={1}>
                    {a.name}
                  </Text>
                </Flex>
              </Button>
            ))}
        </Flex>
      )}
      <MenuModal
        open={!!open}
        onDismiss={() => setOpen(undefined)}
        onSave={() => {
          setOpen(undefined);
          onSave();
        }}
        formType={open}
        id={id}
        parentType={parentType}
        taskCategoryId={taskCategory?.id}
        taskCategoryName={taskCategory?.description}
      />
    </Flex>
  );
}
AddHistoryMenu.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  active: PropTypes.bool,
};
export default AddHistoryMenu;
