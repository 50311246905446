import theme from "@rebass/preset";

export default {
  ...theme,
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  breakpoints: ["30em", "48em", "62em", "80em"],
  fonts: {
    label: "Inter",
    body: "Inter, sans-serif",
    heading: "Inter, Nunito Sans, sans-serif",
    brand: "Inter, 'Nunito Sans', sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    ...theme.sizes,
    xxs: "10px",
    xs: "13px",
    sm: "14px",
    md: "16px",
    lg: "20px",
    xl: "24px",
    xxl: "36px",
    "2xl": "38px",
    "3xl": "52px",
  },
  styles: {
    root: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#5C5C77",
    },
  },
  shadows: {
    ...theme.shadows,
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  radii: {
    ...theme.radii,
    default: 6,
    small: 2,
    medium: 6,
    large: 16,
  },
  colors: {
    ...theme.colors,
    primary: "#0074A5",
    brand: "#0074A5",
    brandHighlight: "#53AEDF33",
    brandLight: "#53AEDF",
    brandDark1: "#005685",
    brandDark2: "#003459",
    secondary: "#0074A5",
    secondaryLight: "#53AEDF",
    success: "#37c89c",
    error: "#ff2c55",
    warning: "#FBC42F",
    warningDark: "#DB5C3A",
    heading: "#385983",
    text: "#5C5C77",
    black: "#5C5C77",
    muted: "#9797AA",
    white: "#fff",
    yellow: "#FBC42F",
    yellowLight: "#FFFFCC",
    green: "#37C89C",
    greenDark: "#1db490",
    greenLight: "#F7FDF2",
    red: "#DF5553",
    redLight: "#F7D5D4",
    redDark: "#A92045",
    menu: "#BDC4CA",
    line: "#CFD4D7",
    accent: "#C0C7CC",
    health: {
      product: "#93D7F4",
      sentiment: "#DF5397",
      satisfaction: "#8453DF",
      satisfactionRange: ["#7740DC", "#6A2ED9", "#5F25CB"],
    },
    blue: {
      ...theme.colors.blue,
      900: "#2697AE",
      500: "#58c4da",
      400: "#72CDE0",
      300: "#95DAE8",
      200: "#B0E3EE",
      100: "#D1EFF5",
      50: "#F3FBFC",
    },
    gray: {
      100: "#FAFCFE",
      200: "#F1F3F5",
      300: "#E7E9EB",
      400: "#DDE0E1",
      500: "#CFD4D7",
      600: "#C0C7CC",
      700: "#9797AA",
      800: "#5C5C77",
    },
    graph: [
      "#53AEDF",
      "#FFCA08",
      "#3DF7D5",
      "#CA53DF",
      "#AEDF53",
      "#93D7F4",
      "#F67A8C",
      "#8FE09C",
      "#FA9D1C",
      "#5DC4C1",
    ],
    avatars: [
      "#53AEDF",
      "#FFCA08",
      "#3DF7D5",
      "#CA53DF",
      "#AEDF53",
      "#93D7F4",
      "#F67A8C",
      "#8FE09C",
      "#FA9D1C",
      "#5DC4C1",
    ],
    // ...
  },
  variants: {
    ...theme.variants,
    default: {
      fontFamily: "body",
      color: "#5C5C77",
    },
    link: {
      color: "secondaryLight",
      textDecoration: "none",
      "&:visited": {
        color: "secondaryLight",
        textDecoration: "none",
      },
      "&:hover:not(:disabled)": {
        color: "secondaryLight",
        textDecoration: "none",
      },
    },
    box: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "accent",
      borderRadius: "4px",
    },
    circle: {
      borderRadius: 9999,
      width: 25,
      height: 25,
    },
    badge: {
      height: 20,
      fontSize: "xxs",
      borderRadius: 9999,
      paddingLeft: [2],
      paddingRight: [2],
    },
    pill: {
      borderRadius: 9999,
      backgroundColor: "secondary",
      fontSize: "sm",
      padding: [2],
      minWidth: 50,
      alignItems: "center",
      display: "flex",
    },
    avatar: {
      ...theme.variants.avatar,
      fontSize: 30,
    },
    successBox: {
      borderRadius: "lg",
      backgroundColor: "blue.50",
      color: "blue.500",
      fontFamily: "body",
    },
    divider: {
      marginTop: [3],
      marginBottom: [3],
      borderBottomColor: "gray.200",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
    },
  },
  text: {
    default: {
      fontFamily: "body",
      fontSize: "sm",
    },
    brand: {
      fontFamily: "brand",
      fontSize: "xl",
      fontWeight: 500,
    },
    title: {
      fontFamily: "heading",
      fontSize: "3xl",
      fontWeight: "bold",
    },
    h1: {
      fontFamily: "heading",
      fontSize: "xl",
      color: "brandLight",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "heading",
      fontSize: "lg",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "md",
      fontFamily: "heading",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "md",
      fontFamily: "heading",
      fontWeight: 700,
      color: "gray.700",
    },
    h5: {
      fontWeight: 500,
      fontSize: "sm",
      fontFamily: "heading",
    },
    h6: {
      fontWeight: 500,
      fontSize: "sm",
      fontFamily: "heading",
    },
    menuHeading: {
      fontSize: "md",
      fontFamily: "heading",
      fontWeight: 600,
      textAlign: "right",
      marginRight: [3],
    },
    subtitle: {
      fontSize: "lg",
      fontWeight: 500,
      letterSpacing: "5%",
    },
    label: {
      fontFamily: "label",
      fontSize: "xs",
      color: "#C0C7CC",
      textTransform: "uppercase",
    },
    error: {
      fontFamily: "label",
      color: "red",
      fontWeight: "bold",
    },
    muted: {
      fontFamily: "label",
      fontSize: "xs",
      lineHeight: "subtitle",
      color: "muted",
    },
    domain: {
      fontFamily: "monospace",
      color: "gray.500",
    },
  },
  forms: {
    radio: {},
    input: {
      borderColor: "line",
      fontFamily: "body",
      fontSize: "sm",
      "::placeholder": {
        color: "gray.400",
        fontSize: "sm",
      },
      "&:focus": {
        outline: "#C0C7CC auto 1px !important",
      },
    },
    unstyled: {
      border: "none",
      "::placeholder": {
        color: "white",
        fontSize: "sm",
      },
    },
    label: {
      color: "line",
      textTransform: "uppercase",
    },
    placeholder: {
      color: "line",
    },
    textarea: {
      borderColor: "line",
      paddingTop: "6px",
      paddingBottom: "6px",
      fontFamily: "body",
      fontFize: "10px",
      "::placeholder": {
        color: "gray.400",
        fontSize: "sm",
      },
    },
  },
  sizes: {
    ...theme.sizes,
    button: 40,
  },
  buttons: {
    default: {
      borderRadius: "lg",
      fontFamily: "body",
      cursor: "pointer",
      fontSize: "md",
      fontWeight: 400,
    },
    primary: {
      variant: "buttons.default",
      color: "white",
      bg: "brandLight",
      "&:hover:not(:disabled)": {
        bg: "primary",
        textDecoration: "none",
      },
    },
    secondary: {
      bg: "white",
      variant: "buttons.default",
      color: "brandLight",
      borderColor: "accent",
      borderWidth: 1,
      borderStyle: "solid",
      "&:hover:not(:disabled)": {
        bg: "gray.300",
        textDecoration: "none",
      },
    },
    ghost: {
      variant: "buttons.default",
      borderRadius: "lg",
      color: "brandLight",
      bg: "transparent",
      "&:hover:not(:disabled)": {
        color: "brand",
        bg: "brandHighlight",
      },
    },
    tab: {
      variant: "buttons.default",
      borderRadius: "lg",
      color: "black",
      bg: "transparent",
      "&:hover:not(:disabled)": {
        color: "brand",
      },
    },
    action: {
      variant: "buttons.default",
      color: "white",
      bg: "brandLight",
      "&:hover:not(:disabled)": {
        bg: "brand",
        textDecoration: "none",
      },
    },
    warning: {
      variant: "buttons.default",
      color: "white",
      bg: "warning",
      "&:hover:not(:disabled)": {
        bg: "warningDark",
        textDecoration: "none",
      },
    },
    error: {
      variant: "buttons.default",
      bg: "red",
      color: "white",
      "&:hover:not(:disabled)": {
        color: "white",
        borderColor: "red",
        bg: "redDark",
        borderWidth: 1,
        borderStyle: "solid",
        textDecoration: "none",
      },
    },
    circle: {
      borderRadius: 999,
      width: 40,
      height: 40,
    },
    ghost2: {
      variant: "buttons.default",
      borderRadius: "lg",
      color: "white",
      bg: "transparent",
      "&:hover:not(:disabled)": {
        color: "brand",
      },
    },
    pill: {
      outline: "none",
      borderRadius: "99px",
      border: "1px solid",
      borderColor: "gray.200",
      color: "black",
      cursor: "pointer",
      bg: "white",
      px: 2,
      py: 1,
      "&:hover": {
        color: "primary",
      },
    },
    disabled: {
      borderRadius: "lg",
      bg: "gray.200",
      borderColor: "gray.200",
      borderWidth: 1,
      borderStyle: "solid",
      color: "gray.400",
    },
  },
};
