import React from "react";
import { Box, Flex, Text } from "rebass";

function SatIcon({ scores, simple }) {
  const SatBadge = ({ label }) => {
    let value = scores?.find((s) => s.scoreType === label)?.value;

    let color = "yellow";
    switch (label) {
      case "nps":
        color = value <= 6 ? "red" : value > 8 ? "green" : "yellow";
        break;

      case "ces":
        color = value <= 2 ? "green" : value >= 6 ? "red" : "yellow";
        break;

      case "csat":
        color = value <= 6 ? "red" : value > 8 ? "green" : "yellow";
        break;
    }
    return value ? (
      simple ? (
        <Text
          color={color}
          textAlign="center"
          variant="label"
          mx={1}
          sx={{ textTransform: "uppercase", fontSize: "x-small" }}
        >
          {label}
        </Text>
      ) : (
        <Flex
          alignItems="center"
          justfiyContent="center"
          bg={color}
          sx={{ borderRadius: 10 }}
          px={2}
          py={1}
          mx={1}
        >
          <Box>
            <Text color="white" textAlign="center">
              {value}
            </Text>
            <Text
              color="white"
              textAlign="center"
              variant="label"
              sx={{ textTransform: "uppercase", fontSize: "x-small" }}
            >
              {label}
            </Text>
          </Box>
        </Flex>
      )
    ) : null;
  };

  return (
    <Flex flexWrap="wrap">
      <SatBadge label="nps" />

      <SatBadge label="ces" />

      <SatBadge label="csat" />
    </Flex>
  );
}

export default SatIcon;
