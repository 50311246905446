import React from "react";
import PropTypes from "prop-types";
import { Button, Text, Flex } from "rebass";

const SecondaryButton = ({
  leftIcon,
  rightIcon,
  onClick,
  children,
  sx,
}) => {
  return (
    <Button variant="secondary" onClick={onClick} sx={sx}>
      <Flex alignItems="center" justifyContent="center" sx={{ minHeight: 20 }}>
        {leftIcon && leftIcon}

        <Text
          ml={leftIcon && 2}
          mr={rightIcon && 2}
          fontSize="xs"
          color={leftIcon && rightIcon ? "black" : "brandLight"}
        >
          {children}
        </Text>
        {rightIcon && rightIcon}
      </Flex>
    </Button>
  );
};

SecondaryButton.propTypes = {
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

SecondaryButton.defaultProps = {
  leftIcon: undefined,
  rightIcon: undefined,
  sx: undefined,
};
export default SecondaryButton;
