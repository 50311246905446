import React from "react";
import { Text, Flex, Box } from "rebass";
import Avatar from "react-avatar";
import { clearStore } from "../../../consts";
import { getDefaultProduct, storeDefaultProduct } from "../../../auth-helper";
import PropTypes from "prop-types";
import { getCurrentUser } from "hooks/getCurrentUser";

export default function CompanyProductList({ products }) {
  const { client } = getCurrentUser();
  const productId = getDefaultProduct();
  const storeProduct = (productId) => {
    storeDefaultProduct(productId);
    window.location.reload();
  };

  return (
    <Flex
      mt={3}
      flexDirection="column"
      justifyContent="center"
      sx={{ position: "relative" }}
    >
      <Text variant="label" textAlign="left">
        Products
      </Text>
      <Flex flexWrap="wrap">
        {products?.map((row) => (
          <Box key={row.id} m={1} px={1}>
            <Flex
              alignItems="center"
              justfiyContent="center"
              p={1}
              sx={{ cursor: "pointer" }}
              onClick={() => storeProduct(row.id)}
            >
              <Avatar
                src={row?.logoUrl}
                size="30"
                name={`${row?.name}`}
                round
                textSizeRatio={2}
              />
              <Box mx={2} data-tag="allowRowEvents">
                <Text data-tag="allowRowEvents">{row.name}</Text>
                {row.id == productId && (
                  <Text data-tag="allowRowEvents" variant="muted">
                    current
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
}
CompanyProductList.propTypes = {
  products: PropTypes.array,
};
