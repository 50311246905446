import React, { useState } from "react";
import Topbar from "Components/Common/Topbar/Topbar";
import { CompanyIcon, UsersIcon } from "Components/Common/Icons";
import { RiGitMergeLine } from "react-icons/ri";

function ContactsTopbar(props) {
  return (
    <Topbar
      title="Contacts"
      menuItems={[
        {
          name: "Accounts",
          icon: CompanyIcon,
          link: "/contacts/accounts",
        },
        { name: "People", icon: UsersIcon, link: "/contacts/users" },
        {
          name: "Hierarchy",
          icon: RiGitMergeLine,
          link: "/contacts/hierarchy",
        },
      ]}
      crumbs={props.crumbs}
    />
  );
}

export default ContactsTopbar;
