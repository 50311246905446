import React from "react";
import { Box, Flex, Image, Text } from "rebass";

const NoRoute = ({ component: Component, ...rest }) => {
  return (
    <Flex alignItems="center" justifyContent="center" height="100vh">
      <Image width={1 / 3} src={require("assets/images/404Page.png")} />
    </Flex>
  );
};
export default NoRoute;
