import Cookies from "js-cookie";

const psl = require("psl");

const domainParts = psl.parse(window.location.hostname);
const { subdomain } = domainParts;
const cookieApi = Cookies.withAttributes({
  path: "/",
  domain: `${domainParts.domain}`,
});

export function Subdomain() {
  return subdomain;
}

export function onAppDomain() {
  return subdomain === "home";
}

export function onAdminDomain() {
  return subdomain === "admin";
}

export function serverHostname() {
  const hostname =
    process.env.NODE_ENV === "development"
      ? `${window.location.origin.replace("3000", "3001")}`
      : `${window.location.origin}`;
  return hostname;
}

export function storeDefaultProduct(product) {
  cookieApi.set("product", product);
}
export function getDefaultProduct() {
  return Cookies.get("product", { domain: `.${domainParts.domain}` });
}

export function hideWelcome() {
  cookieApi.set("hideWelcome", true);
}
export function shouldShowWelcome() {
  return Cookies.get("hideWelcome", { domain: `.${domainParts.domain}` });
}


export function redirectBaseUrlForSubdomain(sub = "home", stripProtocol = false) {
  let redirectUrl = `${window.location.protocol}//${sub}.${
    domainParts.domain
  }${window.location.port !== "" ? `:${window.location.port}` : ""}`;
  if (stripProtocol) {
    redirectUrl = redirectUrl.replace("http://", "").replace("https://", "");
  }
  return redirectUrl;
}

export function redirectToRoot() {
  const redirectUrl = `${window.location.protocol}//${subdomain}.${
    domainParts.domain
  }${window.location.port !== "" ? `:${window.location.port}` : ""}/`;
  console.log(`redirect to ${redirectUrl}`);

  // Before we redirect wipe out any credentials we had since they are in cookies
  if (onAppDomain) {
    localStorage.removeItem("credentials");
  }
  if (window.location.href !== redirectUrl) {
    //  window.location.href = redirectUrl;
  }
}

export function redirectToSubdomain(sub = "home", path) {
  const pathname = path || window.location.pathname;
  const redirectUrl = `${redirectBaseUrlForSubdomain(sub)}${pathname}${
    window.location.search ? `?${window.location.search}` : ""
  }`;
  console.log(`redirect to ${redirectUrl}`);

  // Before we redirect wipe out any credentials we had since they are in cookies
  if (onAppDomain) {
    localStorage.removeItem("credentials");
  }
  if (window.location.href !== redirectUrl) {
    window.location.href = redirectUrl;
  }
}

export function enforceSubdomain() {
  if (subdomain === undefined || subdomain === "www") {
    redirectToSubdomain();
  } else {
    console.log(`We are on subdomain ${subdomain}`);
  }
}
