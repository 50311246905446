import { Flex, Text } from "rebass";
import React from "react";
import { getIntegrationAuthentications } from "../../../../../hooks/getIntegrationAuthentications";
import CustomFormGroup from "../../../../Common/Forms/CustomFormGroup";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";

const ApikeyForm = ({
  values,
  errors,
  setFieldValue,
  handleBlur,
  handleSubmit,
  loading,
  handleSave,
}) => {
  const save = () => {
    handleSave(values, null);
  };

  return (
    <>
      <CustomFormGroup
        placeholder="API KEY"
        label={
          <Flex>
            Api Key
            <Text mx={2} fontSize={"xs"} sx={{ textTransform: "none" }}>
              (Your API KEY )
            </Text>
          </Flex>
        }
        name="apikey"
        value={values.apikey}
        onChange={setFieldValue}
        onBlur={handleBlur}
        helperText={errors && errors.apikey && errors.apikey}
      />

      <PrimaryButton onClick={save} disabled={loading}>
        {!loading ? "Verify Account" : "Verifying account..."}
      </PrimaryButton>
    </>
  );
};
export default ApikeyForm;
