import React, { useState, useEffect } from "react";
import Page from "Components/Page";
import { Text, Flex, Button, Image, Box } from "rebass";
import { useQuery } from "@apollo/client";
import { getCurrentUser } from "hooks/getCurrentUser";
import Avatar from "react-avatar";
import Loader from "Components/Common/Loader";
import { getDashboard } from "hooks/getDashboard";
import HomeTopbar from "./HomeTopbar";
import CurrentUserQuery from "../../../graphql/queries/CurrentUser.gql";
import RevenueDashboardQuery from "../../../graphql/queries/RevenueDashboard";
import { searchFilters } from "../../../hooks/searchFilters";
import PropTypes from "prop-types";
import { userlotTrackEvent } from "../../../analytics/userlot-tracking";
import UserTeamSelect from "../../Filter/UserTeamSelect";
import { useEffectOnce } from "react-use";

function HomeDashboard(props) {
  const [filterQuery, setFilter] = searchFilters();
  const { currentAccount, loading } = getCurrentUser();
  const filters = filterQuery.data?.filters;

  const { data, loading: dLoading, refetch } = getDashboard(filters);

  const dashboardQuery = useQuery(RevenueDashboardQuery, {
    variables: {
      searchFilters: {
        ownerUserId: filters?.ownerUserId,
        daysOffset: filters?.daysAgo,
      },
    },
  });

  const { data: userData } = useQuery(CurrentUserQuery, {
    fetchPolicy: "network-only",
  });
  const user = userData?.currentUser;

  const refetchData = () => {
    refetch(filters);
    dashboardQuery.refetch({
      searchFilters: {
        ownerUserId: filters?.ownerUserId,
        daysOffset: filters?.daysAgo,
      },
    });
  };

  useEffectOnce(() => {
    // Just a test event so can pop up a survey
    userlotTrackEvent("PageView", { module: "home" });
  }, []);

  if (loading)
    return (
      <Page title={props.title} topBar={<HomeTopbar />}>
        <Loader />
      </Page>
    );
  return (
    <Page title={props.title} topBar={<HomeTopbar />} bg="gray.100">
      <Flex flexDirection="column" p={3}>
        <Flex mx={2} my={3} alignItems="center" justifyContent="space-between">
          <Flex
            width={2 / 3}
            color="white"
            alignItems="center"
            bg="brandLight"
            p={3}
            sx={{ borderRadius: "medium", position: "relative" }}
          >
            <Image
              src={require("assets/images/DashboardHeader.png")}
              height={150}
              sx={{ position: "absolute", right: 0 }}
            />
            <Avatar
              src={user?.avatarUrl}
              size="60"
              name={`${user?.firstName} ${user?.lastName}`}
              email={user?.email}
              round
              textSizeRatio={1}
            />
            <Box mx={3}>
              <Flex>
                <Text fontWeight="bold" fontSize="lg">
                  Welcome,
                </Text>
                {user && (
                  <Text
                    mx={1}
                    fontSize="lg"
                  >{`${user?.firstName} ${user?.lastName}`}</Text>
                )}
              </Flex>
              <Text variant="muted" color="white" fontSize="xs">
                User
              </Text>
            </Box>
          </Flex>
          <Flex
            width={1 / 3}
            flex={1}
            height="100%"
            justifyContent="flex-end"
            alignItems={"flex-end"}
          >
            <Box>
              <UserTeamSelect
                teamsOnly={props.teamsOnly}
                onChange={() => {
                  refetchData();
                  props.onChange && props.onChange();
                }}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex width="100%">{props.children}</Flex>
      </Flex>
    </Page>
  );
}
HomeDashboard.propTypes = {
  onChange: PropTypes.func,
};
export default HomeDashboard;

/*<Box mt={1}>
              <Select
                width="170px"
                value={dataFilterOptions?.find(
                  (obj) => obj.value === filters?.daysAgo
                )}
                options={dataFilterOptions}
                onChange={(name, value) => {
                  setFilter("daysAgo", value?.value, value?.name);
                  props.onChange && props.onChange();
                }}
              />
            </Box>*/
