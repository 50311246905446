import React from "react";
import { useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Flex, Image, Text, Box, Link } from "rebass";
import Loader from "Components/Common/Loader";
import { TRIGGER_CONFIG } from "consts";
import { useToasts } from "react-toast-notifications";
import ShowError, { ErrorParams } from "Components/Common/Notifications/Error";
import ShowSuccess, {
  SuccessParams,
} from "Components/Common/Notifications/Success";
import {
  CalendarIcon,
  MailIcon,
  NotificationIcon,
  PlusIcon,
  SlackIcon,
  UsersIcon,
  WebhookIcon,
} from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import {
  RiCheckboxBlankCircleLine,
  RiRadioButtonFill,
  RiMessageLine,
} from "react-icons/ri";
import { AutomationIcon, EditIcon } from "Components/Common/Icons";
import { useQuery, useMutation } from "@apollo/react-hooks";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import TriggerQuery from "../../../../graphql/queries/Triggers.gql";
import {
  EventIcon,
  PlaybookIcon,
  SurveyIcon,
  TagIcon,
  TickIcon,
  WorkflowIcon,
} from "../../../Common/Icons";
import TriggerPage from "./";
import GhostButton from "../../../Common/Buttons/GhostButton";
import CustomFormGroup from "../../../Common/Forms/CustomFormGroup";
import TriggersQuery from "../../../../graphql/queries/Triggers.gql";
import TriggerMutation from "../../../../graphql/mutations/Trigger.gql";

function TriggerList(props) {
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const [showLibrary, setShowLibrary] = useState(false);
  const { loading, data, error, refetch } = useQuery(TriggerQuery, {});

  const [save] = useMutation(TriggerMutation, {
    refetchQueries: [{ query: TriggersQuery }],
  });
  const getIcon = (type) => {
    let icon;
    switch (type) {
      case "webhook":
        icon = <WebhookIcon size={20} />;
        break;
      case "ask_survey":
        icon = <SurveyIcon size={20} />;
        break;
      case "tags":
        icon = <TagIcon size={20} />;
        break;
      case "assign_owner":
        icon = <UsersIcon size={20} />;
        break;
      case "slack":
        icon = <SlackIcon size={20} />;
        break;
      case "email":
        icon = <MailIcon size={20} />;
        break;
      case "in_app_notification":
        icon = <NotificationIcon size={20} />;
        break;
      case "task_create":
        icon = <CalendarIcon size={20} />;
        break;

      case "workflow":
        icon = <WorkflowIcon size={20} />;
        break;
      case "playbook":
        icon = <PlaybookIcon size={20} />;
        break;
      case "in_app_banner":
        icon = <RiMessageLine size={20} />;
        break;
      default:
        break;
    }
    return <Text color="secondaryLight">{icon}</Text>;
  };

  if (loading) return <Loader />;
  return (
    <TriggerPage>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={2} flexDirection="column">
            <Text variant="h2">Automations</Text>
            <Flex mt={2} flex={1} justifyContent="space-between">
              <Flex flex={1}>
                <SecondaryButton
                  leftIcon={
                    !showLibrary ? (
                      <RiRadioButtonFill />
                    ) : (
                      <RiCheckboxBlankCircleLine />
                    )
                  }
                  onClick={() => {
                    setShowLibrary(false);
                  }}
                  sx={{ borderColor: !showLibrary && "brandLight" }}
                >
                  Custom
                </SecondaryButton>
                <Box mx={2} />
                <SecondaryButton
                  sx={{ borderColor: showLibrary && "brandLight" }}
                  leftIcon={
                    showLibrary ? (
                      <RiRadioButtonFill />
                    ) : (
                      <RiCheckboxBlankCircleLine />
                    )
                  }
                  onClick={() => {
                    setShowLibrary(true);
                  }}
                >
                  Packaged
                </SecondaryButton>
              </Flex>
              <Box>
                <PrimaryButton
                  onClick={() => {
                    history.push("/settings/product/automations/new");
                  }}
                >
                  <PlusIcon /> Add Automation
                </PrimaryButton>
              </Box>
            </Flex>
          </Flex>
          {data.triggers?.filter((a) => a.packaged === showLibrary).length ===
            0 && <EmptyState />}
          {TRIGGER_CONFIG.Events.map((t) => {
            const triggers =
              data &&
              data.triggers?.filter(
                (a) => a.eventType === t.id && a.packaged === showLibrary
              );
            return triggers.length > 0 ? (
              <Flex flexDirection="column" mt={3}>
                <Flex alignItems="center">
                  <Text color="brandLight">
                    <EventIcon />
                  </Text>
                  <Text variant="h3" mx={2}>
                    {t.label}
                  </Text>
                </Flex>
                {triggers.map((trigger) => (
                  <Flex
                    key={trigger.id}
                    variant="box"
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "brandHighlight",
                        borderBottomWidth: 0,
                      },
                    }}
                    my={1}
                    p={3}
                    alignItems="center"
                  >
                    <Flex
                      flex={1}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Flex
                        width={2 / 5}
                        mx={2}
                        onClick={() =>
                          history.push(
                            `/settings/product/automations/${trigger.id}`
                          )
                        }
                      >
                        {getIcon(trigger.actionType)}
                        <Flex mx={2} flexDirection="column">
                          <Text variant="h4">{trigger.name}</Text>
                        </Flex>
                      </Flex>
                      <Flex
                        width={2 / 5}
                        mx={2}
                        onClick={() =>
                          history.push(
                            `/settings/product/automations/${trigger.id}`
                          )
                        }
                      >
                        {trigger.quickAction && <Text>Quick Action</Text>}

                        {!trigger.quickAction &&
                          trigger.actionType === "playbook" && (
                            <Text mx={2}>Playbook</Text>
                          )}
                      </Flex>
                      <Flex
                        width={1 / 5}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Flex alignItems="center">
                          <Text mx={2} variant="muted">
                            Enabled
                          </Text>
                          <Box>
                            <CustomFormGroup
                              noMargin
                              type="switch"
                              name="switch"
                              value={trigger.active}
                              onChange={() => {
                                save({
                                  variables: {
                                    ...trigger,
                                    active: !trigger.active,
                                  },
                                }).then(
                                  (response) => {
                                    const result = response.data?.trigger;
                                    if (result?.errors) {
                                      addToast(
                                        <ShowError
                                          message={
                                            result?.errors && result?.errors[0]
                                          }
                                        />,
                                        ErrorParams
                                      );
                                    } else {
                                      addToast(
                                        <ShowSuccess
                                          message={`Automation has been ${
                                            trigger.active
                                              ? "disabled"
                                              : "enabled"
                                          }`}
                                        />,
                                        SuccessParams
                                      );
                                    }
                                  },
                                  (error) => {
                                    addToast(
                                      <ShowError message="Save error!" />,
                                      ErrorParams
                                    );
                                  }
                                );
                              }}
                            />
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            ) : null;
          })}
        </Flex>
      </Flex>
    </TriggerPage>
  );
}

export default withRouter(TriggerList);

const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex
      p={5}
      flex={1}
      justifyContent="center"
      bg="gray.100"
      sx={{
        borderColor: "accent",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        minHeight: 400,
      }}
    >
      <Flex m={4} flex={1} justifyContent="center" flexDirection="column">
        <Text my={3} fontWeight="bold">
          Automations help you to define a standard business process for certain
          acitvities for your accounts.
        </Text>
        <Text my={3}>To set up your playbooks you will need to:</Text>
        <Box mx={1}>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Check your custom fields have been defined
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Create your workflows &amp; playbooks
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Add to your email template library
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Validate your email domains
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Add your users to your account
          </Flex>
        </Box>
        <Flex my={3}>
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/automations/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Automation
          </PrimaryButton>
          <Box mx={3} />
          <SecondaryButton
            onClick={() => {
              history.push("/settings/product/library");
            }}
            leftIcon={<PlusIcon />}
          >
            New Library Item
          </SecondaryButton>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ marginTop: "-10%", height: "150px", objectFit: "contain" }}
        />
      </Flex>
    </Flex>
  );
};
