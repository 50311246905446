import React, { useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import TeamQuery from "../../../graphql/queries/Team.gql";
import TeamMutation from "../../../graphql/mutations/Team.gql";
import TeamDeleteMutation from "../../../graphql/mutations/TeamDelete.gql";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { Flex, Text, Box, Button, Image } from "rebass";
import "react-tabs/style/react-tabs.css";
import "assets/styles/react-tabs.css";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { Formik } from "formik";
import Settings from "./ProductSettings";
import Table from "Components/Common/Table";
import { useToasts } from "react-toast-notifications";
import "emojione-picker/css/picker.css";
import "./emoji.css";
import ShowError, { ErrorParams } from "Components/Common/Notifications/Error";
import ShowSuccess, {
  SuccessParams,
} from "Components/Common/Notifications/Success";
import DeleteButton from "./Common/DeleteButton";
import { getTeams } from "hooks/getTeams";
import Dropzone from "react-dropzone";
import { useTheme } from "emotion-theming";
import { ImageIcon, PlusIcon, SaveIcon } from "Components/Common/Icons";
import RolesQuery from "../../../graphql/queries/Roles.gql";
import UserMutation from "../../../graphql/mutations/User.gql";
import UserTeamMutation from "../../../graphql/mutations/UserTeam.gql";
import UsersQuery from "../../../graphql/queries/Users.gql";
import Modal from "Components/Common/Modal";
import Select from "Components/Common/Forms/Select";
import moment from "moment";
import UserStatusBadge from "./UserStatusBadge";
import FileUploadTemplate from "Components/Data/FileUploadTemplate";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import TeamsQuery from "../../../graphql/queries/Teams.gql";

const columns = [
  {
    name: "Email",
    selector: (row) => row.user.email,
    sortable: true,
  },
  {
    name: "First Name",
    selector: (row) => row.user.firstName,
    sortable: true,
  },
  {
    name: "Last Name",
    selector: (row) => row.user.lastName,
    sortable: true,
  },
  {
    name: "Last Seen",
    selector: (row) => row.lastSeenAt,
    sortable: true,
    cell: (row) => {
      return (
        row.user.lastSeenAt && moment(row.user.lastSeenAt).format("DD MMM YYYY")
      );
    },
  },
  {
    name: "Status",
    selector: (row) => row.invitedAt,
    sortable: true,
    cell: (row) => {
      return <UserStatusBadge user={row.user} />;
    },
  },
  {
    name: "Role",
    selector: (row) => row.roleName,
    sortable: true,
  },
];
function Team(props) {
  const theme = useTheme();
  const history = useHistory();
  const [openInvite, setOpenInvite] = useState(false);
  const [file, setFile] = useState();
  const { addToast } = useToasts();
  const { id } = useParams();
  const [save, {}] = useMutation(TeamMutation, {
    refetchQueries: [
      { query: TeamQuery, variables: { id: id } },
      { query: TeamsQuery },
    ],
  });
  const [destroy, {}] = useMutation(TeamDeleteMutation);
  const { refetch } = getTeams();

  const { loading, data } = useQuery(TeamQuery, {
    variables: { id: id },
    skip: !id || id === "new",
  });
  const team = id === "new" ? { name: "" } : data && data.team;

  return (
    <Settings>
      <Formik
        enableReinitialize
        initialValues={team || {}}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          save({
            variables: {
              id: values.id ? values.id : null,
              name: values.name,
              description: values.description,
              file: file && file,
            },
            context: {
              hasUpload: !!file,
            },
          }).then(
            (response) => {
              let result = response.data?.teamMutation;
              if (result?.errors) {
                actions.setErrors(result?.errors);
                addToast(
                  <ShowError message={result?.errors[0]} />,
                  ErrorParams
                );
              } else {
                let teamId = result?.team?.id;
                addToast(<ShowSuccess message="Saved!" />, SuccessParams);
                refetch();
                history.push(`/settings/product/teams/${teamId}`);
              }
            },
            (error) => {
              actions.setErrors(error);
              addToast(<ShowError message={"Error occured!"} />, ErrorParams);
            }
          );
        }}
      >
        {(formProps) => {
          const {
            values,
            errors,
            setFieldValue,
            handleBlur,
            handleSubmit,
            resetForm,
          } = formProps;
          return (
            <Flex flex={1} m={4} flexDirection="column">
              <Flex justifyContent="space-between">
                <Text variant="h2">{team?.name || "New Team"}</Text>

                <Box>
                  <DeleteButton
                    callback={destroy}
                    onCancel={() => history.goBack()}
                    id={id}
                    refetch={refetch}
                    name="team"
                    redirectUrl="/settings/product/teams"
                  />
                  <PrimaryButton onClick={handleSubmit} leftIcon={<SaveIcon />}>
                    Save
                  </PrimaryButton>
                </Box>
              </Flex>
              <Flex bg="white" pt={2}>
                <Flex alignItems="center">
                  <Dropzone
                    onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          height: "100%",
                          width: "100%",
                          borderStyle: "dashed",
                          borderColor: theme.colors.accent,
                          borderWidth: 1,
                          borderRadius: "medium",
                        }}
                        p={4}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        {file ? (
                          <>
                            <Box color="secondaryLight">
                              <ImageIcon size={40} />
                              <Text>{file.name}</Text>
                            </Box>
                          </>
                        ) : values.logoUrl ? (
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            <Image
                              src={values.logoUrl}
                              width={60}
                              height={60}
                            />
                            <PrimaryButton>Change</PrimaryButton>
                          </Flex>
                        ) : (
                          <FileUploadTemplate logo />
                        )}
                      </Flex>
                    )}
                  </Dropzone>
                </Flex>
                <Flex flex={1} flexDirection="column" mx={2}>
                  <CustomFormGroup
                    placeholder="Name"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.name && errors.name}
                  />
                  <CustomFormGroup
                    placeholder="Description"
                    label="Description"
                    name="description"
                    type="textarea"
                    value={values.description}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.description && errors.description}
                  />
                </Flex>
              </Flex>
              <Flex flexDirection="column" bg="white" pt={4}>
                <Flex justifyContent="space-between">
                  <Text variant="h3">Users</Text>

                  {values.id && (
                    <Box>
                      <PrimaryButton onClick={() => setOpenInvite(true)}>
                        <PlusIcon />
                      </PrimaryButton>
                    </Box>
                  )}
                </Flex>

                <Table
                  columns={columns}
                  loading={loading}
                  defaultSortField={"name"}
                  data={team?.memberships?.filter((item) => !!item.user) || []}
                  title=""
                  onRowClicked={(p) => {
                    history.push(`/settings/account/users/${p.user.id}`);
                  }}
                />
              </Flex>
              <InviteUser
                teamId={values.id}
                open={openInvite}
                onDismiss={() => setOpenInvite(false)}
              />
            </Flex>
          );
        }}
      </Formik>
    </Settings>
  );
}

export default withRouter(Team);
const InviteUser = (props) => {
  const { addToast } = useToasts();
  const [saveUser, {}] = useMutation(UserMutation);
  const [saveUserTeams, {}] = useMutation(UserTeamMutation);
  const usersQuery = useQuery(UsersQuery, {});
  const rolesQuery = useQuery(RolesQuery, {});

  const { refetch } = useQuery(TeamQuery, {
    variables: { id: props.teamId },
  });
  const users = usersQuery.data?.users;
  const userOptions = users?.map((r) => {
    return { value: r.id, label: `${r.firstName} ${r.lastName || ""}` };
  });
  const roles = rolesQuery.data?.roles;

  const roleOptions = roles?.map((r) => {
    return { value: r.id, label: r.name };
  });
  return (
    <Modal title="Add User" open={props.open} onDismiss={props.onDismiss}>
      <Formik
        enableReinitialize
        initialValues={{ role: "", users: [] }}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          role: Yup.string().required("Required"),
          users: Yup.array().required("Required"),
        })}
        onSubmit={(values, actions) => {
          values.users.forEach((user) => {
            saveUserTeams({
              variables: {
                id: user.id,
                memberships: [
                  {
                    teamId: props.teamId,
                    roleName: values.role,
                    deleted: false,
                  },
                ],
              },
            }).then(
              (response) => {
                refetch({ networkOnly: true });
                let result = response.data?.userTeamMutation;
                if (result?.errors) {
                  actions.setErrors(result?.errors);
                  addToast(
                    <ShowError message={result?.errors[0]} />,
                    ErrorParams
                  );
                } else {
                  props.onDismiss();
                  addToast(<ShowSuccess message="Saved!" />, SuccessParams);
                }
              },
              (error) => {
                addToast(<ShowError message="Save error!" />, ErrorParams);
              }
            );
          });
        }}
      >
        {(formProps) => {
          const { values, errors, setFieldValue, handleBlur, handleSubmit } =
            formProps;
          return (
            <Flex p={4} flexDirection="column">
              <Text mb={2} variant="muted">
                Select the people you would like to add to this team and thier
                role.
              </Text>
              <Select
                placeholder="Select teams to add as a user"
                isMulti
                label="Users"
                width={350}
                name="users"
                onChange={(name, value) => {
                  let items = value?.map((item) => {
                    return {
                      id: item?.value,
                    };
                  });
                  setFieldValue(name, items);
                }}
                mb={0}
                options={userOptions}
              />
              <Box my={2} />
              <Select
                placeholder="Select role"
                label="Role"
                width={350}
                name="role"
                onChange={(name, value) => {
                  setFieldValue(name, value?.label);
                }}
                mb={0}
                options={roleOptions}
              />
              <Text variant="label" color="error">
                {errors.role && errors.role}
              </Text>
              <Box my={2} />
              <Box>
                <PrimaryButton onClick={handleSubmit}>Add User</PrimaryButton>
              </Box>
            </Flex>
          );
        }}
      </Formik>
    </Modal>
  );
};
InviteUser.propTypes = {
  teamId: PropTypes.string,
  onDismiss: PropTypes.func,
  open: PropTypes.bool,
};
