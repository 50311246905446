import React, { useEffect, useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import IntegrationVerifyMutation from "../../../../graphql/mutations/IntegrationVerify.gql";
import IntegrationsQuery from "../../../../graphql/queries/Integrations.gql";
import Loader from "../../../Common/Loader";

function AddIntegrationStep(props) {
  const { addToast } = useToasts();

  const [verifyIntegration, { loading, error }] = useMutation(
    IntegrationVerifyMutation,
    {
      refetchQueries: [{ query: IntegrationsQuery }],
    }
  );

  const apiData = {
    authId: props.appConnection?.id,
    name: props.app?.name,
    active: false,
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.appConnection && props.app) verify();
    }, 1000);
  }, [props.app, props.appConnection]);
  const verify = () => {
    verifyIntegration({
      variables: {
        authId: props.appConnection?.id,
        name: props.app?.name,
        active: false,
        id: null,
      },
    }).then(
      (response) => {
        if (response.data?.verifyIntegration.errors) {
          addToast(
            <Flex>{response.data?.verifyIntegration.errors[0]}</Flex>,
            ErrorParams
          );

          props.previousStep();
        } else {
          const id = response.data?.verifyIntegration?.integration?.id;
          window.location.replace(`/settings/product/integrations/${id}`);

          addToast(<Flex>Saved!</Flex>, SuccessParams);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Flex flexDirection="column" flexGrow={1} minWidth={600}>
      <Flex
        m={4}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Loader />
        <Text>Validating and configuring api schema</Text>
      </Flex>
    </Flex>
  );
}

export default AddIntegrationStep;
