import React from "react";
import SecondaryButton from "../Buttons/SecondaryButton";
import { DownloadIcon } from "../Icons";

function convertArrayOfObjectsToCSV(columns, array) {
  let result;
  if (!array) return;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = columns.map((c) => c.selector);
  result = "";
  result += columns
    .map((c) => {
      return _.isString(c.name) ? c.name : c.label;
    })
    .join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += key(item) ? key(item) : "";

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(columns, array) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(columns, array);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export const ExportButton = ({ columns, data }) => {
  return (
    <SecondaryButton onClick={(e) => downloadCSV(columns, data)}>
      <DownloadIcon />
    </SecondaryButton>
  );
};

export default ExportButton;
