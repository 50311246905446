import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Avatar from "react-avatar";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Flex, Text, Box, Link, Image } from "rebass";
import PropTypes from "prop-types";
import Table from "Components/Common/Table";
import ModelHeader from "Components/Common/ModelHeader";
import { MODEL_TYPES } from "consts";
import { PriorityIcon } from "Components/Common/Icons";
import { ArrowDropDownIcon, CustomIcon } from "Components/Common/Icons";
import TaskMutation from "../../../graphql/mutations/Task.gql";
import TaskStatusQuery from "../../../graphql/queries/TaskStatus.gql";
import DefaultButton from "Components/Common/Buttons/DefaultButton";
import TaskTimeline from "./TaskTimeline";
import AssignedTo from "./AssignedTo";

function TaskTable({
  detailedView,
  loading,
  data,
  onSelected,
  onSave,
  onSelectedRowsChange,
  saveProductFilter,
  selectedRows,
}) {
  const { data: statuses } = useQuery(TaskStatusQuery);

  const [save] = useMutation(TaskMutation, {});

  const updateStatus = (task) => {
    console.log(task);
    save({
      variables: {
        tasks: [
          {
            id: task.id,
            actionableId: task.actionableId,
            actionableType: task.actionableType,
            recurrenceCode: task.recurrenceCode,
            title: task.title,
            description: task.description,
            taskCategoryId: task.taskCategoryId,
            priority: task.priority,
            dueAt: task.dueAt,
            endAt: task.endAt,
            userId: task.user?.id,
            teamId: task.team?.id,
            taskStatusId: task.taskStatusId,
            taskStatusName: task.taskStatusName,
            busy: task.busy,
            allDay: task.allDay,
          },
        ],
      },
      optimisticResponse: {
        task: {
          tasks: [
            {
              __typename: "Task",
              ...task,
              id: task.id,
            },
          ],
        },
      },
    }).then(
      (response) => {
        onSave && onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const columns = [
    detailedView && {
      name: " ",
      selector: (row) => row.product.name,
      sortable: true,
      width: "30px",
      cell: (row) => {
        return (
          <Flex justifyContent="center">
            <Avatar
              round
              name={row.product?.name}
              src={row.product?.logoUrl}
              size="25"
            />
          </Flex>
        );
      },
    },
    {
      name: " ",
      selector: (row) => row.priority,
      sortable: true,
      width: "40px",
      cell: (row) => (
        <Flex
          flex={1}
          data-tag="allowRowEvents"
          alignItems="center"
          justifyContent="center"
        >
          <PriorityIcon value={row.priority} />
        </Flex>
      ),
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      width: "30%",
      cell: (row) => (
        <Flex data-tag="allowRowEvents" alignItems="center">
          <Box data-tag="allowRowEvents" alignItems="center">
            <CustomIcon componentName={row?.taskCategoryIcon} color="brand" />
          </Box>
          <Text mx={2} data-tag="allowRowEvents">
            {row.title}
          </Text>
        </Flex>
      ),
    },
    detailedView && {
      name: "Account",
      selector: (row) =>
        row.actionable?.company?.name || row.actionable?.name || "",
      sortable: true,
      cell: (row) => {
        const name = row.actionable?.company?.name || row.actionable?.name;
        const id = row.actionable?.company?.id || row.actionable?.id;
        const logoUrl =
          row.actionable?.company?.logoUrl || row.actionable?.logoUrl;
        return (
          row.actionableType && (
            <Flex
              alignItems="center"
              onClick={() => {
                saveProductFilter(row.product?.id, `/tasks/accounts/${id}`);
              }}
            >
              <Link>
                <ModelHeader
                  data-tag="allowRowEvents"
                  type={MODEL_TYPES.Company.name}
                  name={name}
                  logoUrl={logoUrl}
                />
              </Link>
            </Flex>
          )
        );
      },
    },
    {
      name: "User",
      selector: (row) =>
        (row.actionableType === MODEL_TYPES.EndUser.name &&
          row.actionable?.name) ||
        "",
      sortable: true,
      cell: (row) => {
        const name = row.actionable?.name;
        const id = row.actionable?.id;
        return (
          row.actionableType === MODEL_TYPES.EndUser.name && (
            <Flex
              flexDirection="column"
              onClick={() => {
                saveProductFilter &&
                  saveProductFilter(
                    row.product?.id,
                    `/tasks/accounts/${row.actionable?.company?.id}/users/${id}`
                  );
              }}
            >
              <Link>
                <ModelHeader
                  data-tag="allowRowEvents"
                  type={MODEL_TYPES.EndUser.name}
                  name={name}
                />
              </Link>
              <Text variant="muted" fontSize={"xxs"}>
                {row.actionable.email}
              </Text>
            </Flex>
          )
        );
      },
    },
    {
      name: "Person",
      selector: (row) => row.user?.name,
      sortable: true,
      width: "80px",
      cell: (row) => <AssignedTo user={row.user} />,
    },
    {
      name: "Timeline",
      selector: (row) => row.endAt,
      sortable: true,
      width: "160px",
      cell: (row) => <TaskTimeline row={row} />,
    },
    {
      name: "Status",
      selector: (row) => row.taskStatusName,
      sortable: true,
      width: "180px",
      cell: (row) => (
        <TaskStatus
          row={row}
          data={statuses?.taskStatuses}
          onChange={(task) => updateStatus(task)}
        />
      ),
    },
  ];

  return (
    <Table
      title=""
      defaultSortField="dueAt"
      columns={columns.filter((c) => !!c)}
      data={data}
      loading={loading}
      onSelectedRowsChange={onSelectedRowsChange}
      selectableRows
      selectedRows={selectedRows}
      onRowClicked={(p) => {
        window.scrollTo(0, 0);
        onSelected(p);
      }}
      noDataComponent={<NoTaskData />}
    />
  );
}

const NoTaskData = () => {
  return (
    <Flex
      flex={1}
      bg="gray.100"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ borderRadius: "medium" }}
    >
      <Flex flex={1} p={4} flexDirection="column" justifyContent="center">
        <Image
          src={require("assets/images/TaskEmptyState.png")}
          sx={{ height: "120px", objectFit: "contain" }}
        />

        <Text variant="muted" my={3}>
          You have nothing left to do here!
        </Text>
      </Flex>
    </Flex>
  );
};

const TaskStatus = ({ row, data, onChange }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [newStatusText, setNewStatusText] = useState(undefined);
  const [selectedStatus, setSelectedStatus] = useState(undefined);

  const handleChange = (selectedStatus) => {
    setIsHovering(false);
    setSelectedStatus(selectedStatus);
    onChange({
      ...row,
      taskStatusId: selectedStatus.value,
      taskStatusName: selectedStatus.label,
    });
  };

  return (
    <Flex alignItems="center" sx={{ position: "relative" }}>
      <DefaultButton
        leftIcon={
          <Box
            sx={{
              height: 16,
              width: 16,
              borderRadius: 99,
            }}
            bg={row.taskStatusColor || "gray.100"}
          />
        }
        rightIcon={<ArrowDropDownIcon />}
        onClick={() => setIsHovering(!isHovering)}
        sx={{ fontSize: "xs" }}
      >
        {row.taskStatusName || selectedStatus?.label}
      </DefaultButton>
      {isHovering && (
        <Flex
          bg="white"
          width={200}
          flexDirection="column"
          sx={{
            position: "absolute",
            top: 35,
            right: 0,
            zIndex: 999,
            boxShadow: "large",
          }}
        >
          <Box py={3}>
            {data.map((d) => (
              <Flex
                key={d.label}
                px={3}
                alignItems="center"
                sx={{ "&:hover": { backgroundColor: "brandHighlight" } }}
              >
                <Box
                  sx={{
                    height: 18,
                    width: 20,
                    borderRadius: 99,
                  }}
                  bg={d.info || "gray.100"}
                />
                <Text
                  width="100%"
                  py={2}
                  px={2}
                  onClick={() => {
                    handleChange(d);
                  }}
                >
                  {d.label}
                </Text>
              </Flex>
            ))}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
TaskStatus.propTypes = {
  row: PropTypes.object,
  data: PropTypes.array,
  onChange: PropTypes.func,
};

TaskTable.propTypes = {
  detailedView: PropTypes.bool,
  loading: PropTypes.bool,
  onSelected: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  onSelectedRowsChange: PropTypes.func.isRequired,
  saveProductFilter: PropTypes.func,
};

TaskTable.defaultProps = {
  detailedView: true,
  loading: false,
  data: [],
};
export default withRouter(TaskTable);
