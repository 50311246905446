import React from "react";
import { Flex, Button, Text, Box } from "rebass";

const Plan = (props) => {
  return (
    <Flex flex={1} mx={2} variant="box" flexDirection="column">
      <Box m={3}>
        <Text textAlign="center" variant="h3">
          {props.name}
        </Text>

        <Text textAlign="center" variant="muted">
          {props.description}
        </Text>
        <ul>
          {props.items?.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </Box>
      <Flex flexGrow={1} alignItems="flex-end">
        {!props.selected ? (
          <Button
            size="md"
            variant="primary"
            width="100%"
            m={3}
            onClick={() => props.onSelected(props.name)}
          >
            <Flex flex={1} alignItems="center" justifyContent="center">
              {props.button || props.name}
            </Flex>
          </Button>
        ) : (
          <Button variant="ghost" width="100%" disabled>
            <Flex flex={1} alignItems="center" justifyContent="center">
              Current Plan
            </Flex>
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Plan;
