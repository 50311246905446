import React, { useEffect, useState } from "react";
import Page from "Components/Page";
import { Box, Flex, Text, Link, Image } from "rebass";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { MODEL_TYPES } from "consts";
import Filter from "Components/Filter";
import { getSearchFilter } from "hooks/getSearchFilter";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Table from "Components/Common/Table";
import Avatar from "react-avatar";
import HealthScoreIcon from "Components/Data/Company/HealthScoreIcon";
import moment from "moment";
import { useTheme } from "emotion-theming";
import numeral from "numeral";
import RadioGroup from "Components/Common/RadioGroup";
import { Sparklines, SparklinesLine } from "react-sparklines";
import ReportTopbar from "./ReportTopbar";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import AllCompanyQuery from "../../../graphql/queries/AllCompanies";
import RevenueDashboardQuery from "../../../graphql/queries/RevenueDashboard";
import StackedBarGraph from "./StackedBarGraph";
import WidgetWrapper from "../Home/WidgetWrapper";

const _ = require("lodash");

const pageTitle = "Accounts";

const columns = [
  {
    name: "Health",
    selector: (row) => row.name,
    width: "80px",
    cell: (row) => {
      let healthScore = {};
      let status =
        row.companyDetails &&
        row.companyDetails.map((companyDetail) => {
          if (healthScore.healthScore < companyDetail.healthScore) {
            status = companyDetail?.status;
            healthScore.healthScore = companyDetail.healthScore;
            healthScore.healthCategory = companyDetail.healthCategory;
            healthScore.healthscoreName = companyDetail.healthscoreName;
            healthScore.healthScoreUpdatedAt =
              companyDetail.healthScoreUpdatedAt;
          }
        });
      return (
        <HealthScoreIcon
          status={{ name: "Paid" }}
          simple
          data-tag="allowRowEvents"
          value={healthScore.healthScore}
          category={healthScore.healthCategory}
          date={healthScore.healthScoreUpdatedAt}
        />
      );
    },
  },
  {
    name: "Account",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Products",
    selector: (row) => row.products,
    width: "80px",
    sortable: true,
    center: true,
    cell: (row) => {
      return <Text textAlign="center">{row.products || 0}</Text>;
    },
  },
  {
    name: "Total MRR",
    selector: (row) => row.revenue,
    sortable: true,
    width: "150px",
    right: true,
    cell: (row) => {
      return `${Number(row.revenue || "0").toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`;
    },
  },
];

function AllCompanies(props) {
  const theme = useTheme();
  const { loading, data, error, refetch } = useQuery(AllCompanyQuery, {});
  const [triggerRefresh, setTriggerRefresh] = useState();
  const [selectedType, setSelectedType] = useState("count");
  const [saveFilter, {}] = useMutation(SearchFilterMutation);
  const { data: filters } = getSearchFilter();

  const dashboardQuery = useQuery(RevenueDashboardQuery);
  let mrrData = dashboardQuery.data?.revenueDashboard || [];
  const companyTypes = ["existing", "new", "churned"];
  const colors = [
    theme.colors.brandLight,
    theme.colors.brandHighlight,
    theme.colors.red,
  ];

  const allCompanies = data?.allCompanies;

  const dataTable = [];
  allCompanies?.map((d) => {
    let company = { ...d };

    let total = 0;
    company.companyDetails &&
      company.companyDetails.map(
        (companyDetail) => (total += companyDetail.mrr)
      );
    dataTable.push({
      ...company,
      revenue: total,
      products: company.companyDetails.length,
    });
  });

  let graphData = companyTypes.map((ev, i) => {
    return {
      name: ev,
      data: mrrData
        ?.filter((d) => d.type === ev)
        .map((d) => {
          return {
            date: moment(d.date, "YYYY-MM-DD"),
            count: d[selectedType],
          };
        }),
    };
  });
  let latestData = companyTypes.map((ev, i) => {
    let lastItem = _.first(
      mrrData?.filter(
        (d) => d.type === ev && moment(d.date).isSame(moment(), "month")
      )
    );
    return {
      name: ev,
      value: lastItem && lastItem[selectedType],
    };
  });

  var totalLatestValue = _.sumBy(latestData, "value");

  let dateDate = mrrData
    ?.filter((d) => d.type !== "churned")
    .map((d) => {
      return {
        date: moment(d.date, "YYYY-MM-DD"),
        count: d.count,
        value: d.value,
      };
    });
  var countAggregate =
    dateDate &&
    _(dateDate)
      .groupBy("date")
      .map((objs, key) => ({
        name: key,
        count: _.sumBy(objs, "count"),
      }))
      .value();

  var valueAggregate =
    dateDate &&
    _(dateDate)
      .groupBy("date")
      .map((objs, key) => ({
        name: key,
        count: _.sumBy(objs, "value"),
      }))
      .value();

  var avgAggregate =
    valueAggregate &&
    valueAggregate.map((item, idx) => ({
      name: item.name,
      count:
        item.count / countAggregate.find((d) => d.name === item.name).count,
    }));

  useEffect(() => {
    if (filters?.filters?.statusId !== undefined) {
      resetFilter();
      setTriggerRefresh();
    }
  }, [loading]);

  const resetFilter = () => {
    saveFilter({
      variables: {
        filters: {
          healthId: undefined,
          statusId: undefined,
          filterText: undefined,
          lifeCycleStageId: undefined,
        },
      },
    }).then(
      (response) => {
        props.onChange && props.onChange();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Page title={pageTitle} topBar={<ReportTopbar />}>
      <Filter
        title={pageTitle}
        typeName={MODEL_TYPES.Company.name}
        onChange={() => setTriggerRefresh(new Date())}
        menu={undefined}
      >
        <Flex flexDirection="column" flex={1}>
          <WidgetWrapper title="Growth">
            <Flex flexDirection="column" flex={1}>
              <Flex justifyContent="space-between">
                <Flex px={3} flexDirection="column" width="100%">
                  <Box>
                    <Text variant="muted">MRR</Text>
                    <Text variant="h1">
                      {numeral(_.last(valueAggregate)?.count).format("0.0a")}
                    </Text>
                  </Box>
                  <Box width="100%">
                    <Sparklines
                      data={valueAggregate.map((d) => d.count)}
                      height={30}
                    >
                      <SparklinesLine
                        color={theme.colors.secondaryLight}
                        style={{ fill: "none" }}
                      />
                    </Sparklines>
                  </Box>
                </Flex>
                <Box
                  sx={{
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderBottomWidth: 0,
                    borderStyle: "solid",
                    borderColor: "gray.200",
                  }}
                />
                <Flex px={3} flexDirection="column" width="100%">
                  <Box>
                    <Text variant="muted">Accounts</Text>
                    <Text variant="h1">
                      {numeral(_.last(countAggregate)?.count).format("0")}
                    </Text>
                  </Box>
                  <Box width="100%">
                    <Sparklines
                      data={countAggregate.map((d) => d.count)}
                      height={30}
                    >
                      <SparklinesLine
                        color={theme.colors.secondaryLight}
                        style={{ fill: "none" }}
                      />
                    </Sparklines>
                  </Box>
                </Flex>
                <Box
                  sx={{
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderBottomWidth: 0,
                    borderStyle: "solid",
                    borderColor: "gray.200",
                  }}
                />
                <Flex px={3} flexDirection="column" width="100%">
                  <Box>
                    <Text variant="muted">Avg&nbsp;MRR</Text>
                    <Text variant="h1">
                      {numeral(_.sumBy(avgAggregate, "count")).format("0.0a")}
                    </Text>
                  </Box>
                  <Box width="100%">
                    <Sparklines
                      height={30}
                      data={avgAggregate.map((d) => d.count)}
                    >
                      <SparklinesLine
                        color={theme.colors.secondaryLight}
                        style={{ fill: "none" }}
                      />
                    </Sparklines>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </WidgetWrapper>
          <WidgetWrapper
            title="MRR Breakdown"
            menu={
              <RadioGroup
                name="count"
                selectedOption={selectedType}
                options={[
                  {
                    value: "count",
                    label: (
                      <Text fontWeight="bold" fontSize="sm" mx={2}>
                        #
                      </Text>
                    ),
                  },
                  {
                    value: "value",
                    label: (
                      <Text fontWeight="bold" fontSize="sm" mx={2}>
                        $
                      </Text>
                    ),
                  },
                ]}
                onChange={(name, target) => {
                  setSelectedType(target.value);
                }}
              />
            }
          >
            {latestData.filter((d) => d.value > 0).length > 0 ? (
              <Flex flexDirection="column" flex={1}>
                <Flex flexDirection="column" flex={1} m={2}>
                  <Flex alignItems="center">
                    <Box m={3}>
                      <Text variant="label">
                        Total {selectedType === "count" ? "" : "MRR"}
                      </Text>
                      <Text variant="title">
                        {selectedType === "count"
                          ? totalLatestValue
                          : numeral(totalLatestValue).format("0.0a")}
                      </Text>
                    </Box>
                    <Flex
                      width="100%"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Flex height="20px" width="100%">
                        {latestData.map((d, i) => {
                          return (
                            <Box
                              key={i}
                              width={d.value / totalLatestValue}
                              bg={colors[i]}
                            />
                          );
                        })}
                      </Flex>
                      <Flex>
                        {latestData.map((d, i) => {
                          return (
                            <Flex
                              key={i}
                              alignItems="center"
                              my={1}
                              width="100%"
                            >
                              <Flex
                                m={1}
                                sx={{ width: 10, height: 10, borderRadius: 99 }}
                                bg={colors[i]}
                              />
                              <Text mx={2} variant="label">
                                {d.name}
                              </Text>
                              <Text mx={2} fontWeight="bold" fontSize="sm">
                                {selectedType === "count"
                                  ? d.value
                                  : numeral(d.value).format("0.00a")}
                              </Text>
                            </Flex>
                          );
                        })}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Box m={2}>
                  <StackedBarGraph
                    series={graphData}
                    height={200}
                    colors={colors}
                  />
                </Box>
              </Flex>
            ) : (
              <Flex
                flexDirection="column"
                flex={1}
                p={4}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  src={require("assets/images/HealthEmpty.png")}
                  width={80}
                />
              </Flex>
            )}
          </WidgetWrapper>
          <Box my={2} />
          <Table
            title=""
            defaultSortField="name"
            columns={columns}
            data={dataTable}
            loading={loading}
            expandableRowsComponent={ExpandableComponent}
            noDataComponent={
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                m={4}
              >
                <Image
                  src={require("assets/images/AccountsEmpty.png")}
                  width={80}
                />
                <Text my={2} variant="muted">
                  No accounts returned
                </Text>
              </Flex>
            }
          />
          <Flex flexGrow={2} />
        </Flex>
      </Filter>
    </Page>
  );
}

export default AllCompanies;

const ExpandableComponent = ({ data }) => {
  return (
    <Flex width="100%" mx={4} my={3} flexDirection="column" fontSize="sm">
      <Flex width="100%">
        <Flex alignItems="center" width={2 / 7} fontWeight="bold">
          Product
        </Flex>
        <Flex width={1 / 7} fontWeight="bold">
          Health
        </Flex>
        <Flex width={1 / 7} fontWeight="bold">
          MRR
        </Flex>
        <Flex width={1 / 7} fontWeight="bold">
          Status
        </Flex>
        <Flex width={1 / 7} fontWeight="bold">
          Life Cycle
        </Flex>
        <Flex width={1 / 7} fontWeight="bold">
          Owner
        </Flex>
        <Flex width={1 / 7} fontWeight="bold">
          Last Seen
        </Flex>
      </Flex>
      {data.companyDetails &&
        data.companyDetails.map((companyDetail) => (
          <Flex key={companyDetail.id} width="100%" mt={2}>
            <Flex alignItems="center" width={2 / 7}>
              <RouterLink to={`/reports/accounts/${data.id}`} component={Link}>
                <Flex alignItems="center">
                  <Avatar
                    size={25}
                    round
                    name={companyDetail.product?.name}
                    src={companyDetail.product?.logoUrl}
                  />
                  <Box mx={2}>
                    <Text>{data.name}</Text>
                    <Text variant="muted">{companyDetail.product?.name}</Text>
                  </Box>
                </Flex>
              </RouterLink>
            </Flex>
            <Flex width={1 / 7}>
              <HealthScoreIcon
                status={companyDetail?.status}
                simple
                data-tag="allowRowEvents"
                value={companyDetail.healthScore}
                category={companyDetail.healthCategory}
                date={companyDetail.healthScoreUpdatedAt}
              />
            </Flex>
            <Flex width={1 / 7}>
              {`${Number(companyDetail.mrr || "0").toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}`}
            </Flex>
            <Flex width={1 / 7}>{companyDetail.status?.name}</Flex>
            <Flex width={1 / 7}>{companyDetail.lifeCycleStage?.name}</Flex>
            <Flex width={1 / 7}>{companyDetail.owner?.name}</Flex>
            <Flex width={1 / 7}>
              {companyDetail.lastSeenAt &&
                moment(companyDetail.lastSeenAt).format("DD MMM YYYY")}
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
};
