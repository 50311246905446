import React, { useState } from "react";
import { Flex, Image, Text } from "rebass";
import Table from "Components/Common/Table";
import { useHistory } from "react-router-dom";
import { useDynamicSchema } from "hooks/DynamicSchema";
import { MODEL_TYPES } from "consts";
import CompanyFilter from "../../Dashboard/CompanyHealth/CompanyFilter";
import CompanyMenu from "../../Dashboard/CompanyHealth/CompanyMenu";
import { getContactFields } from "../../../hooks/DynamicSchema";
import ReactTable from "../../Common/Table/ReactTable";

function CompanyContactsHierarchy(props) {
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);

  const { columns } = useDynamicSchema(
    MODEL_TYPES.Company,
    getContactFields(MODEL_TYPES.Company)
  );

  let parents = props.data && props.data.filter((c) => c.isParent);

  const dataTable = parents?.map((p) => {
    return {
      ...p,
      subRows: props.data.filter((c) => c.rootParentId == p.id),
    };
  });

  return (
    <Flex flex={1} flexDirection="column">
      {props.title && (
        <Flex my={2} justifyContent="space-between">
          <Text variant="h3">{props.title}</Text>
        </Flex>
      )}
      <ReactTable
        title=""
        defaultSortField="name"
        columns={columns}
        loading={props.loading}
        data={dataTable}
        rawData={props.data}
        exportable
        configurable
        onSort={props.onSort}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        paginationTotalRows={props.paginationTotalRows}
        onSelectedRowsChange={setSelectedRows}
        search={
          <CompanyFilter
            module={props.module}
            onChange={() => {
              setSelectedRows([]);
              props.refetch();
            }}
          />
        }
        actions={
          <CompanyMenu
            moduleType="People"
            selectedRows={selectedRows}
            parentType="Company"
            onChange={() => {
              setSelectedRows([]);
              props.refetch();
            }}
          />
        }
        onRowClicked={(p) => {
          const route = window.location.pathname;
          console.log(window.location);
          history.push(`${route}/${p.id}`);
        }}
        noDataComponent={
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            m={4}
          >
            <Image
              src={require("assets/images/AccountsEmpty.png")}
              width={80}
            />
            <Text my={2} variant="muted">
              No accounts returned. Connect accounts or check your filter
              criteria.
            </Text>
          </Flex>
        }
      />
    </Flex>
  );
}

export default CompanyContactsHierarchy;
