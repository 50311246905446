import React from "react";
import { Box, Text, Flex, Button, Link } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useMutation } from "@apollo/react-hooks";
import RequestResetPasswordMutation from "../../../graphql/mutations/UserRequestResetPassword.gql";

function StepOne(props) {
  const [requestPasswordReset, {}] = useMutation(RequestResetPasswordMutation);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("Required"),
      })}
      autoComplete={false}
      onSubmit={(values, actions) => {
        requestPasswordReset({
          variables: {
            email: values.email,
          },
        }).then(
          (response) => {
            if (
              //FIXME: These errors
              response.data?.requestResetPassword?.errors &&
              response.data?.requestResetPassword?.errors.length > 0
            ) {
              actions.setErrors(response.data?.requestResetPassword?.errors);
            } else {
              props.nextStep();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;

        return (
          <Box
            as="form"
            flexDirection="column"
            onSubmit={handleSubmit}
            noValidate
            width="100%"
            sx={{
              minWidth: 350,
            }}
          >
            <Text as="h4" variant="h2" my={4} color="brandLight">
              Reset your password
            </Text>
            <CustomFormGroup
              placeholder="Email"
              label="Your Business Email"
              name="email"
              value={values.email}
              onChange={setFieldValue}
              onBlur={handleBlur}
            />
            {errors && errors.length > 0 && (
              <Text color="error">{errors[0]}</Text>
            )}
            <Flex mt={3} justifyContent="center" flexDirection="column">
              <Button
                rounded="large"
                size="md"
                flex={1}
                primary
                onClick={handleSubmit}
              >
                <Flex flex={1} alignItems="center" justifyContent="center">
                  Continue
                </Flex>
              </Button>
            </Flex>
            <Flex mt={3}>
              Already have an account?
              <Link href="/" mx={2}>
                Login
              </Link>
            </Flex>
          </Box>
        );
      }}
    </Formik>
  );
}

export default StepOne;
