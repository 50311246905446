import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Flex, Image, Text } from "rebass";

function DataEmpty(props) {
  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={require("assets/images/TablesEmptyState.png")}
        sx={{ width: "30%" }}
      />
      <Text variant="muted">No activity data</Text>
    </Flex>
  );
}

export default withRouter(DataEmpty);
