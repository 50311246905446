import React, { useState } from "react";
import PropTypes from "prop-types";
import { Text, Flex } from "rebass";
import RouterLink from "./Link";

const Sidebar = (props) => {
  return (
    <Flex
      py={3}
      flexDirection="column"
      sx={{
        fontFamily: "body",
        width: "250px",
        minWidth: 250,
      }}
    >
      <Flex flexDirection="column">
        <Text mt={3} variant="menuHeading">
          Account
        </Text>
        <RouterLink to="/settings/account/general">General</RouterLink>
        <RouterLink to="/settings/account/products">Products</RouterLink>
        <RouterLink to="/settings/account/users">Users</RouterLink>
        <RouterLink to="/settings/account/churnReasons">
          Churn Reasons
        </RouterLink>
        <RouterLink to="/settings/product/tasks">Activity Types</RouterLink>
        <RouterLink to="/settings/account/tags">Tags</RouterLink>
        <RouterLink to="/settings/account/domains">Email Domains</RouterLink>
      </Flex>
    </Flex>
  );
};
// <RouterLink to="/settings/account/subscription">Billing</RouterLink>
Sidebar.propTypes = {};

export default Sidebar;
