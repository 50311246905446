import React from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { useState } from "react";
import { Box, Flex, Text } from "rebass";
import { useTheme } from "emotion-theming";

const sliderStyle = {
  position: "relative",
};

const railStyle = {
  width: "100%",
  height: 20,
  borderRadius: 7,
  cursor: "pointer",
};

const Track = ({ source, target, getTrackProps, disabled = false }) => {
  const theme = useTheme();
  return <div {...getTrackProps()} />;
};
const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
}) => {
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          WebkitTapHighlightColor: "rgba(0,0,0,0)",
          zIndex: 5,
          width: 28,
          height: 42,
          cursor: "pointer",
          backgroundColor: "none",
        }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          marginTop: -10,
          left: `${percent}%`,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          width: 24,
          height: 24,
          borderRadius: "50%",
          boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
          backgroundColor: "white",
        }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 24,
            height: 24,
          }}
        />
      </div>
    </>
  );
};

function HealthScoreInputSlider(props) {
  const theme = useTheme();
  const { domain, onChange, value } = props;
  const [values, setValues] = useState(value);

  return (
    <div style={{ width: "100%" }}>
      <Slider
        mode={1}
        step={1}
        domain={domain}
        rootStyle={sliderStyle}
        onChange={onChange}
        onUpdate={setValues}
        values={values}
      >
        <Rail>
          {({ getRailProps }) => {
            let start = values[0];
            let end = values[1];
            let redWidth = start;
            let yellowWidth = end - start;
            let greenWidth = 100 - end;
            return (
              <>
                <Flex mt={2} sx={railStyle} {...getRailProps()}>
                  <Box width={redWidth / 100} bg={theme.colors.health.product}>
                    <Text fontSize="sm" textAlign="center">
                      Product Usage and Adoption {values[0]}
                    </Text>
                  </Box>
                  <Box
                    width={yellowWidth / 100}
                    bg={theme.colors.health.satisfaction}
                  >
                    <Text
                      color="white"
                      fontSize="sm"
                      textAlign="center"
                      sx={{ zIndex: 99 }}
                    >
                      Satisfaction {values[1] - values[0]}
                    </Text>
                  </Box>
                  <Box
                    width={greenWidth / 100}
                    bg={theme.colors.health.sentiment}
                  >
                    <Text fontSize="sm" textAlign="center" color="white">
                      Sentiment {100 - values[1]}
                    </Text>
                  </Box>
                </Flex>
              </>
            );
          }}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  );
}

export default HealthScoreInputSlider;
