import moment from "moment";
import numeral from "numeral";
import React from "react";
import {
  RiCalendarEventLine,
  RiMessageLine,
  RiPieChart2Line,
  RiPriceTag3Line,
} from "react-icons/ri";
import { config } from "react-transition-group";
import { Flex, Text } from "rebass";
import {
  AssignOwnerIcon,
  CompanyIcon,
  MailIcon,
  NotificationIcon,
  SlackIcon,
  TasksIcon,
  WebhookIcon,
  MapIcon,
  MessageIcon,
  ProductIcon,
  TimeIcon,
  UserIcon,
  PlaybookIcon,
  ScoreIcon,
  HealthChangeIcon,
  SatisfactionIcon,
  SentimentIcon,
  StatusChangeIcon,
  StageChangeIcon,
  EventIcon,
  WorkflowIcon,
  SurveyIcon,
  TicketIcon,
  TagIcon,
} from "./components/Common/Icons";
import SearchFilter from "./graphql/queries/SearchFilter.gql";

export const SearchQuery = SearchFilter;
export const SeedData = {
  filters: {
    segmentId: undefined,
    ownerUserId: undefined,
    statusId: undefined,
    planId: undefined,
    contacts: {
      filterText: undefined,
    },
    conversion: {
      filterText: undefined,
    },
    paid: {
      filterText: undefined,
    },
  },
};

export const setupConfigureItems = [
  {
    id: "schema",
    title: "Configure your custom fields",
    link: "settings/product/fields",
  },
  {
    id: "accounts",
    title: "Connect datasources",
    link: "settings/account/setup/datasource",
  },
  {
    id: "events",
    title: "Import events",
    link: "settings/account/setup/datasource",
  },
  {
    id: "lifecycle",
    title: "Configure lifecycle journeys",
    link: "settings/product/statuses",
  },
  {
    id: "healthscores",
    title: "Configure customer health scoring",
    link: "settings/product/healthscores",
  },
  {
    id: "plans",
    title: "Configure account types & plans",
    link: "settings/product/plans",
  },
  {
    id: "automation",
    title: "Create and use automations",
    link: "settings/product/automations",
  },
  {
    id: "churn",
    title: "Configure churn reasons",
    link: "settings/account/churnReasons",
  },
  {
    id: "tasks",
    title: "Create a task",
    link: "/tasks",
  },
  {
    id: "emaildomains",
    title: "Setup email domains",
    link: "settings/account/domains",
  },
];
export const clearStore = (client) => {
  if (client) {
    client.resetStore().then(() => {
      console.log("cache cleared");
      //cache.writeQuery({ SearchQuery, SeedData });
    });
  }
};

export const TriggerActionTypeOptions = [
  {
    type: "Recurring",
    icon: RiCalendarEventLine,
    title: "Recurring",
    subtitle: "Recurring automation",
    entities: ["Company", "EndUser"],
  },
  {
    type: "Company",
    icon: CompanyIcon,
    title: "Account",
    subtitle: "Account property changes",
    entities: ["Company", "EndUser"],
  },
  {
    type: "EndUser",
    icon: UserIcon,
    title: "User",
    subtitle: "User property changes",
    entities: ["EndUser"],
  },
  {
    type: "Health",
    icon: HealthChangeIcon,
    title: "Health",
    subtitle: "Account Health changes",
    entities: ["Company"],
  },
  {
    type: "Satisfaction",
    icon: SatisfactionIcon,
    title: "Satisfaction",
    subtitle: "Satisfaction changes",
    entities: ["EndUser"],
  },
  {
    type: "Sentiment",
    icon: SentimentIcon,
    title: "Sentiment",
    subtitle: "Sentiment changes",
    entities: ["Company"],
  },
  {
    type: "Status",
    icon: StatusChangeIcon,
    title: "Status",
    subtitle: "Account Status changes",
    entities: ["Company"],
  },
  {
    type: "Stage",
    icon: StageChangeIcon,
    title: "Lifecycle",
    subtitle: "Account Lifecycle changes",
    entities: ["Company"],
  },
  {
    type: "Segment",
    icon: RiPieChart2Line,
    title: "Segment",
    subtitle: "a Account enters or exits a segment",
    entities: ["Company"],
  },
  {
    type: "Event",
    icon: EventIcon,
    title: "Event",
    subtitle: "a specific event occurs",
    entities: ["Company", "EndUser"],
  },
  {
    type: "Tag",
    icon: RiPriceTag3Line,
    title: "Tag",
    subtitle: "when an Account or User is tagged",
    entities: ["Company", "EndUser"],
  },
];
export const dataFilterOptions = [
  { value: 7, label: "Last 7 days" },
  { value: 30, label: "Last 30 days" },
  { value: 60, label: "Last 60 days" },
  { value: 90, label: "Last 90 days" },
  { value: 180, label: "Last 180 days" },
  { value: 365, label: "Last year" },
];
export const dateOffsetOptions = [
  { value: 1, label: "days" },
  { value: -1, label: "days ago" },
];

export const convertNumber = (number, precision = 1) => {
  return numeral(number).format(`0,0.${"".padEnd(precision, "0")}a`);
};

// call this function, passing-in your date
export const dateToFromNowDaily = (myDate) => {
  // get from-now for this date
  const fromNow = moment(myDate).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    },
  });
};

export const ModelTypeIcon = (type, size) => {
  return (
    <>
      {type === MODEL_TYPES.Company.name && <CompanyIcon size={size} />}
      {type === MODEL_TYPES.Comment.name && <MessageIcon size={size} />}
      {type === MODEL_TYPES.Address.name && <MapIcon size={size} />}
      {type === MODEL_TYPES.EndUser.name && <UserIcon size={size} />}
      {type === MODEL_TYPES.Event.name && <TimeIcon size={size} />}
      {type === MODEL_TYPES.Product.name && <ProductIcon size={size} />}
      {type === MODEL_TYPES.Score.name && <ScoreIcon size={size} />}
      {type === MODEL_TYPES.Ticket.name && <TicketIcon size={size} />}
      {type === MODEL_TYPES.Task.name && <TasksIcon size={size} />}
    </>
  );
};
export const MODEL_TYPES = {
  Company: {
    name: "Company",
    plural: "companies",
    singular: "company",
    label: "Account",
    route: "/accounts",
  },
  Product: {
    name: "Product",
    plural: "products",
    singular: "product",
    label: "Product",
    route: "data/products",
  },
  EndUser: {
    name: "EndUser",
    plural: "endUsers",
    singular: "endUser",
    label: "User",
    route: "",
  },
  Comment: {
    name: "Comment",
    plural: "comments",
    singular: "comment",
    label: "Notes",
  },
  Address: {
    name: "Address",
    plural: "addresses",
    singular: "address",
    label: "Address",
  },
  Event: {
    name: "Event",
    plural: "events",
    singular: "event",
    label: "Event",
    route: "events",
  },
  Task: {
    name: "Task",
    plural: "tasks",
    singular: "task",
    label: "Task",
    route: "tasks",
  },
  Score: {
    name: "Score",
    plural: "scores",
    singular: "score",
    label: "Score",
    route: "scores",
  },
  Ticket: {
    name: "Ticket",
    plural: "tickets",
    singular: "ticket",
    label: "Ticket",
    route: "tickets",
  },
};

export const CompanyStatus = ["Trial", "Paid", "Churned", "Not Converted"];

export const SEARCH_MODEL_TYPES = {
  endusers: MODEL_TYPES.EndUser,
  products: MODEL_TYPES.Product,
  companies: MODEL_TYPES.Company,
  comments: MODEL_TYPES.Comment,
  addresses: MODEL_TYPES.Address,
  events: MODEL_TYPES.Event,
  tasks: MODEL_TYPES.Task,
  scores: MODEL_TYPES.Score,
  tickets: MODEL_TYPES.Ticket,
};
const libraryActions = [
  {
    id: "playbook",
    name: "Playbook",
    icon: <PlaybookIcon />,
    label: "Start a playbook",
  },
  {
    id: "workflow",
    name: "Workflow",
    icon: <WorkflowIcon />,
    label: "Start a workflow",
  },
];
export const triggerActions = [
  {
    id: "task_create",
    name: "Task",
    icon: <TasksIcon size={20} />,
    label: "Create a task",
  },
  {
    id: "email",
    name: "Email",
    icon: <MailIcon size={20} />,
    label: "Send an email",
  },
  {
    id: "assign_owner",
    name: "Assign",
    icon: <AssignOwnerIcon size={20} />,
    label: "Assign an owner",
  },
  {
    id: "slack",
    name: "Slack",
    icon: <SlackIcon size={20} />,
    label: "Trigger slack notification",
  },
  {
    id: "in_app_notification",
    name: "Alert",
    icon: <NotificationIcon size={20} />,
    label: "Send in app alert",
  },
  {
    id: "ask_survey",
    name: "Survey",
    icon: <SurveyIcon size={20} />,
    label: "Send a survey to a user",
  },
  {
    id: "playbook",
    name: "Playbook",
    icon: <PlaybookIcon size={20} />,
    label: "Kick off a playbook",
  },
  {
    id: "tags",
    name: "Tags",
    icon: <TagIcon size={20} />,
    label: "Add or remove tags",
  },
  {
    id: "in_app_banner",
    name: "In App Notification",
    icon: <RiMessageLine size={20} />,
    label: "Banner notification",
  },
];
const playbookActions = [
  { id: "task_create", name: "Task", icon: <TasksIcon /> },
  { id: "email", name: "Email", icon: <MailIcon /> },
  { id: "in_app_notification", name: "Alert", icon: <NotificationIcon /> },
];
const recurrence = [
  { id: "no_repeat", name: "No repeat" },
  { id: "daily", name: "Daily" },
  { id: "weekly", name: "Weekly" },
  { id: "monthly", name: "Monthly" },
  { id: "quarterly", name: "Quarterly" },
  { id: "yearly", name: "Yearly" },
];
const triggerEvents = [
  {
    id: "reoccuring_daily",
    name: "Daily",
    type: "Recurring",
    label: "Daily",
    time: undefined,
    entities: ["Company", "EndUser"],
  },
  {
    id: "reoccuring_weekly",
    name: "Weekly",
    type: "Recurring",
    label: "Weekly",
    time: undefined,
    entities: ["Company", "EndUser"],
  },
  {
    id: "reoccuring_monthly",
    name: "Monthly",
    type: "Recurring",
    label: "Monthly",
    time: undefined,
    entities: ["Company", "EndUser"],
  },
  {
    id: "reoccuring_quarterly",
    name: "Quarterly",
    type: "Recurring",
    label: "Quarterly",
    time: undefined,
    entities: ["Company", "EndUser"],
  },
  {
    id: "reoccuring_custom",
    name: "Custom",
    type: "Recurring",
    label: "Custom",
    time: undefined,
    entities: ["Company", "EndUser"],
  },

  {
    id: "company_created",
    name: "Account Added",
    type: "Company",
    label: "New account",
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "company_owner_changed",
    name: "Owner Assigned",
    type: "Company",
    label: "Owner changed",
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "renewal",
    name: "Upcoming renewal",
    label: "Renewal",
    type: "Company",
    time: { past: true, future: true },
    entities: ["Company"],
  },
  {
    id: "start_date",
    name: "From start date",
    label: "Start Date",
    type: "Company",
    time: { past: false, future: true },
    entities: ["Company"],
  },
  {
    id: "end_user_created",
    name: "End User Created",
    type: "EndUser",
    label: "User created",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "end_user_seen",
    name: "End User Seen",
    type: "EndUser",
    label: "User seen",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "comment_created",
    name: "Note Added",
    type: "Company",
    label: "Note added",
    time: undefined,
    entities: ["Company"],
  },

  {
    id: "end_user_comment_created",
    name: "Note Added",
    type: "EndUser",
    label: "Note added",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "health_score_changed",
    name: "Changed",
    type: "Health",
    label: "Health changed",
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "health_score_increased_good",
    name: "Increased",
    type: "Health",
    label: (
      <Flex>
        Improved to{" "}
        <Text
          bg="greenLight"
          mx={1}
          px={2}
          color="green"
          sx={{ borderRadius: "medium" }}
        >
          Good
        </Text>
      </Flex>
    ),
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "health_score_increased_ok",
    name: "Increased",
    type: "Health",
    label: (
      <Flex>
        Improved to{" "}
        <Text
          bg="yellowLight"
          mx={1}
          px={2}
          color="yellow"
          sx={{ borderRadius: "medium" }}
        >
          Ok
        </Text>
      </Flex>
    ),
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "health_score_decreased_ok",
    name: "Decreased",
    type: "Health",
    label: (
      <Flex>
        Decreased to{" "}
        <Text
          bg="yellowLight"
          mx={1}
          px={2}
          color="yellow"
          sx={{ borderRadius: "medium" }}
        >
          Ok
        </Text>
      </Flex>
    ),
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "health_score_decreased_poor",
    name: "Decreased",
    type: "Health",
    label: (
      <Flex>
        Decreased{" "}
        <Text
          bg="redLight"
          mx={1}
          px={2}
          color="red"
          sx={{ borderRadius: "medium" }}
        >
          Poor
        </Text>
      </Flex>
    ),
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "health_score_decreased_good_poor",
    name: "Decreased",
    type: "Health",
    label: (
      <Flex>
        Decreased from{" "}
        <Text
          bg="greenLight"
          mx={1}
          px={2}
          color="green"
          sx={{ borderRadius: "medium" }}
        >
          Good
        </Text>{" "}
        to{" "}
        <Text
          bg="redLight"
          mx={1}
          px={2}
          color="red"
          sx={{ borderRadius: "medium" }}
        >
          Poor
        </Text>
      </Flex>
    ),
    time: undefined,
    entities: ["Company"],
  },

  {
    id: "company_status_changed",
    name: "Changed",
    type: "Status",
    label: "Status changed",
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "company_lifecycle_changed",
    name: "Changed",
    type: "Stage",
    label: "Lifecycle changed",
    time: undefined,
    entities: ["Company"],
  },

  {
    id: "nps_increased",
    name: "NPS Increased",
    type: "Satisfaction",
    label: "NPS Increased",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "nps_decreased",
    name: "NPS Decreased",
    type: "Satisfaction",
    label: "NPS Decreased",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "ces_increased",
    name: "CES Increased",
    type: "Satisfaction",
    label: "CES Increased",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "ces_decreased",
    name: "CES Decreased",
    type: "Satisfaction",
    label: "CES Decreased",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "csat_increased",
    name: "CSAT Increased",
    type: "Satisfaction",
    label: "CSAT Increased",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "csat_decreased",
    name: "CSAT Decreased",
    type: "Satisfaction",
    label: "CSAT Decreased",
    time: undefined,
    entities: ["EndUser"],
  },

  {
    id: "sentiment_increased",
    name: "Score Increased",
    type: "Sentiment",
    label: "Sentiment Increased",
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "sentiment_decreased",
    name: "Score Decreased",
    type: "Sentiment",
    label: "Sentiment Decreased",
    time: undefined,
    entities: ["Company"],
  },

  {
    id: "entered_segment",
    name: "Entered Segment",
    type: "Segment",
    label: "Entered Segment",
    time: undefined,
    entities: ["Company", "EndUser"],
  },
  {
    id: "exited_segment",
    name: "Exited Segment",
    type: "Segment",
    label: "Exited Segment",
    time: undefined,
    entities: ["Company", "EndUser"],
  },
  {
    id: "event_created",
    name: "Event Triggered",
    type: "Event",
    label: "Event Triggered",
    time: undefined,
    entities: ["Company", "EndUser"],
  },

  {
    id: "account_tag_added",
    name: "Account Tag Added",
    type: "Tag",
    label: "Account Tag Added",
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "account_tag_removed",
    name: "Account Tag Removed",
    type: "Tag",
    label: "Account Tag Removed",
    time: undefined,
    entities: ["Company"],
  },
  {
    id: "user_tag_added",
    name: "User Tag Added",
    type: "Tag",
    label: "User Tag Added",
    time: undefined,
    entities: ["EndUser"],
  },
  {
    id: "user_tag_removed",
    name: "UserTag Removed",
    type: "Tag",
    label: "User Tag Removed",
    time: undefined,
    entities: ["EndUser"],
  },
];

export const TRIGGER_CONFIG = {
  PlaybookActions: playbookActions,
  Actions: triggerActions,
  LibraryActions: libraryActions,
  Events: triggerEvents,
  Recurrence: recurrence,
};
export const TYPES = [
  { id: "string", name: "Text", allowReset: false },
  { id: "textarea", name: "Long Text", allowReset: false },
  { id: "richtext", name: "Rich Text", allowReset: false },
  { id: "boolean", name: "Checkbox", allowReset: false },
  { id: "number", name: "Number", allowReset: false },
  { id: "url", name: "Url", allowReset: false },
  { id: "email", name: "Email", allowReset: false },
  { id: "date", name: "Date", allowReset: false },
  { id: "list", name: "List", allowReset: false },
  { id: "currency", name: "Currency", allowReset: false },
];

export const RESET_FIELDS = [
  { id: "daily", name: "Daily" },
  { id: "weekly", name: "Weekly" },
  { id: "monthly", name: "Monthly" },
];

export const deepOmit = (obj, keysToOmit) => {
  var keysToOmitIndex = _.keyBy(
    Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]
  ); // create an index object of the keys that should be omitted

  function omitFromObject(obj) {
    // the inner function which will be called recursivley
    return _.transform(obj, function (result, value, key) {
      // transform to a new object
      if (key in keysToOmitIndex) {
        // if the key is in the index skip it
        return;
      }

      result[key] = _.isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    });
  }

  return omitFromObject(obj); // return the inner function result
};

export const getConfigDefaults = (actionType) => {
  let config = {};

  switch (actionType) {
    case "in_app_banner":
      config = { to: [] };
      break;
    case "tags":
      config = { remove: "false" };
      break;
    case "ask_survey":
      config = { to: [] };
      break;
    case "slack":
      config = {};
      break;
    case "email":
      config = { to: [], from: "account" };
      break;
    case "in_app_notification":
      config = { assignToOwner: true, title: "", description: "" };
      break;
    case "task_create":
      config = {
        activityType: "task",
        assignToOwner: true,
        title: "",
        description: "",
      };
      break;
    case "webhook":
      config = {};
      break;
    case "assign_owner":
      config = {};
      break;
  }
  return config;
};
