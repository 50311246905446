import React from "react";
import { withRouter } from "react-router-dom";
import { Flex, Image, Box, Text, Link } from "rebass";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import BrandLine from "../../Common/Graphs/BrandLine";
import WebsiteWrapper from "../WebsiteWrapper";
import LoginForm from "./LoginForm";

function Login(props) {
  return (
    <WebsiteWrapper>
      <Box width={1 / 2}>
        <LoginForm {...props} />
      </Box>
    </WebsiteWrapper>
  );
}

export default withRouter(Login);
