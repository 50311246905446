import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import { useTheme } from "emotion-theming";
import GaugeChart from "react-gauge-chart";
import WidgetWrapper from "./WidgetWrapper";
import SatisfactionDashboardQuery from "../../../graphql/queries/SatisfactionDashboard.gql";
import { useQuery } from "@apollo/client";
import Loader from "../../Common/Loader";
import Avatar from "react-avatar";

function SatisfactionChart(props) {
  const { data: satisfactionDashboard, loading } = useQuery(
    SatisfactionDashboardQuery,
    { variables: { scoreType: props.scoreType?.toLowerCase() } }
  );
  const value = satisfactionDashboard?.satisfactionDashboard || 0;

  return (
    <WidgetWrapper title={props.scoreType}>
      <SatisfactionGauge min={-100} max={100} value={value} loading={loading} />
    </WidgetWrapper>
  );
}

export default SatisfactionChart;

export function SatisfactionChartList({ data, loading }) {
  return data ? (
    <WidgetWrapper title={"NPS"}>
      <Flex flexWrap="wrap" flex={1}>
        {data.map((t) => (
          <Flex key={t.id} width={1 / 5}>
            <Flex m={2} bg="gray.100" flex={1}>
              <SatisfactionGauge
                count={t.count}
                value={t.value}
                loading={loading}
                min={-100}
                max={100}
                title={t.name}
                avatarUrl={t.avatarUrl}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </WidgetWrapper>
  ) : null;
}

export function SatisfactionGauge({
  value,
  min,
  max,
  loading,
  title,
  avatarUrl,
  count,
}) {
  const theme = useTheme();

  const percent = value ? (value + 100) / 200 : 0;

  return (
    <Flex flex={1} flexDirection="column" p={2}>
      <Flex alignItems="center" mb={2}>
        {avatarUrl && <Avatar size={30} round name={title} src={avatarUrl} />}
        <Box ml={1}>
          <Text
            textAlign={"left"}
            fontSize={"xs"}
            fontWeight="bold"
            sx={{ whiteSpace: "break-spaces" }}
          >
            {title}
          </Text>
          <Flex>
            <Text fontSize="xs" variant="muted">
              {`${(value || 0).toFixed(0)}%`}
              {` (${count || 0})`}
            </Text>
          </Flex>
        </Box>
      </Flex>
      {loading ? (
        <Loader />
      ) : value ? (
        <>
          <GaugeChart
            id="gauge-chart3"
            nrOfLevels={80}
            arcsLength={[0.33, 0.33, 0.33, 0.33]}
            colors={[
              theme.colors.red,
              theme.colors.yellow,
              theme.colors.green,
              theme.colors.green,
            ]}
            formatTextValue={(v) => value?.toFixed(0) + "%"}
            percent={percent}
            arcPadding={0.02}
            arcWidth={0.2}
            cornerRadius={3}
            hideText
            textColor={theme.colors.brand}
            needleColor={theme.colors.brandHighlight}
            needleBaseColor={theme.colors.brand}
          />
          <Flex justifyContent="space-between">
            <Text fontSize="xxs" textAlign="left">
              {min}
            </Text>
            <Text fontSize="xxs" textAlign="right">
              {max}
            </Text>
          </Flex>
        </>
      ) : (
        <Flex flex={1} alignItems="center" justifyContent="center" p={2}>
          <Image
            src={require("assets/images/SatisfactionEmpty.png")}
            width={60}
          />
        </Flex>
      )}
    </Flex>
  );
}
