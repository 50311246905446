import React, { useState } from "react";
import { Box, Text, Flex, Button } from "rebass";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "assets/styles/react-tabs.css";
import { getEntityScores } from "hooks/getEntityScores";
import moment from "moment";
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "Components/Common/Icons";
import SatisfactionResponses from "../../Common/Satisfaction/SatisfactionResponses";
import "react-vertical-timeline-component/style.min.css";
import "../../Activity/timeline.css";
import LineGraph from "Components/Common/Graphs/LineGraph";

const _ = require("lodash");

function EndUserHealth(props) {
  return (
    <Flex width="100%" flexDirection="column">
      <Text variant="h3">Satisfaction</Text>
      <Box width="100%" m={2}>
        <Tabs width="100%">
          <TabList>
            <Tab key="NPS">
              <Flex alignItems="center">NPS</Flex>
            </Tab>
            <Tab key="CSAT">
              <Flex alignItems="center">CSAT</Flex>
            </Tab>
            <Tab key="CES">
              <Flex alignItems="center">CES</Flex>
            </Tab>
          </TabList>
          <TabPanel>
            <SatifactionBreakdown {...props} scoreType="nps" total={0} />
          </TabPanel>
          <TabPanel>
            <SatifactionBreakdown {...props} scoreType="csat" total={0} />
          </TabPanel>
          <TabPanel>
            <SatifactionBreakdown {...props} scoreType="ces" total={0} />
          </TabPanel>
        </Tabs>
      </Box>
    </Flex>
  );
}

export default EndUserHealth;

const SatifactionBreakdown = (props) => {
  let days = 365;
  const { loading, data: scoreData } = getEntityScores(
    props.id,
    props.parentType,
    props.scoreType,
    days
  );

  let graphData = [];
  const dates = [...Array(days).keys()]
    .map((i) => moment().add(-i, "day"))
    .reverse();

  let prevTotal = _.maxBy(
    scoreData?.scores
      .filter((c) =>
        moment(c.createdAt).isBefore(moment().add(-1 * days, "day"), "day")
      )
      .map((d) => {
        return { ...d, createdAt: moment(d.createdAt).toDate() };
      }),
    "createdAt"
  )?.value;

  graphData = dates?.map((date) => {
    const total = _.last(
      scoreData?.scores?.filter((c) => date.isSame(moment(c.createdAt), "day"))
    )?.value;

    const item = {
      date: moment(date),
      count: total || prevTotal,
    };
    prevTotal = total || prevTotal;
    return item;
  });
  const lastScore = scoreData && _.last(scoreData.scores);

  const yMin = (type) => {
    switch (type) {
      case "nps":
        return 0;
      case "csat":
        return 0;
      case "ces":
        return 0;
      default:
        return 0;
    }
  };
  const yMax = (type) => {
    switch (type) {
      case "nps":
        return 10;
      case "csat":
        return 10;
      case "ces":
        return 10;
      default:
        break;
    }
  };

  return (
    <Flex width="100%" flexDirection="column" pl={3}>
      <Flex width="100%" flex={1} my={4}>
        <Flex
          width={150}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex alignItems="center" justifyContent="center">
            <Text variant="h1">
              {lastScore?.value}
              {props.percentage && "%"}
            </Text>

            <Text
              variant="label"
              color={
                lastScore?.valueDiff < 0
                  ? "red"
                  : lastScore?.valueDiff > 0 && "green"
              }
            >
              <Flex>
                {lastScore?.valueDiff === 0 ? (
                  <MinusIcon />
                ) : lastScore?.valueDiff < 0 ? (
                  <ArrowDownIcon />
                ) : (
                  lastScore?.valueDiff > 0 && <ArrowUpIcon />
                )}
                <Text>
                  {lastScore?.valueDiff}
                  {props.percentage && "%"}
                </Text>
              </Flex>
            </Text>
          </Flex>
          <Box mt={2}>
            <Text variant="muted">{days} Days</Text>
          </Box>
        </Flex>
        <Flex flexGrow={1} flex={1} mx={3}>
          {graphData && graphData.length > 0 && (
            <LineGraph
              days={days}
              yMin={yMin(props.scoreType)}
              yMax={yMax(props.scoreType)}
              series={[{ name: "", data: graphData }]}
              height={200}
              noLegend
            />
          )}
        </Flex>
      </Flex>
      <Flex flex={1} width="100%" mt={4} bg="gray.100" variant="box">
        <Box p={3} width="100%">
          <SatisfactionResponses
            data={scoreData?.scores}
            scoreType={props.scoreType}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
