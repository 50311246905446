import React from "react";
import PropTypes from "prop-types";
import { Flex, Button, Text, Box, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import TaskMutation from "../../../graphql/mutations/Task.gql";
import TaskOpenMutation from "../../../graphql/mutations/TaskOpen.gql";
import TaskQuery from "../../../graphql/queries/Task.gql";
import TasksQuery from "../../../graphql/queries/Tasks.gql";
import TaskCompleteMutation from "../../../graphql/mutations/TaskComplete.gql";
import TaskDeleteMutation from "../../../graphql/mutations/TaskDelete.gql";
import TeamsQuery from "../../../graphql/queries/Teams.gql";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import Select from "Components/Common/Forms/Select";
import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { TaskCategories, TaskPriorityOptions } from "./TaskDefaults";
import TaskActionable from "./TaskActionable";
import { getCurrentUser } from "hooks/getCurrentUser";
import Loader from "Components/Common/Loader";
import { PlusIcon, SaveIcon, TimeIcon } from "Components/Common/Icons";
import UserlotCalendar from "./Calendar";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { EventIcon } from "Components/Common/Icons";
import UserBadge from "Components/Common/UserBadge";
import RadioGroupButton from "Components/Common/RadioGroupButton";
import TaskComments from "./TaskComments";
import EntityAdminMenu from "../../Data/EntityAdminMenu";
import { TrashIcon } from "../../Common/Icons";
import { EntityDeleteButton } from "../../Data/EntityDetailMenu";

const moment = require("moment");
const _ = require("lodash");

export const timePeriods = (value) => {
  const minutesInDay = 1440;
  const startDate = value
    ? moment(value).startOf("day")
    : moment().startOf("day");
  const timeBlocksArr = [
    {
      date: startDate.startOf("day"),
      label: "",
      value: startDate.startOf("day").format("hh:mm a"),
    },
  ];
  for (let i = 15; i <= minutesInDay - 15; i += 15) {
    const halfHourInLoop = i / 60;

    let formattedBlock = String(halfHourInLoop);
    const hour = formattedBlock.split(".")[0];
    const minutePerc = parseFloat("0." + formattedBlock.split(".")[1]);
    const minute = minutePerc * 60 === 0 ? "00" : String(minutePerc * 60);
    formattedBlock = `${hour}:${minute}`;

    const myDate = startDate.clone().hour(hour).minute(minute);
    timeBlocksArr.push({
      date: myDate,
      label: myDate.format("hh:mm a"),
      value: myDate.format("hh:mm a"),
    });
  }

  return timeBlocksArr;
};
function TaskForm(props) {
  const { id: taskId } = props;
  const { addToast } = useToasts();
  const categories = TaskCategories();
  const [open, setOpen] = useState(props.open);
  const [task, setTask] = useState({});
  const currentUser = getCurrentUser();
  const isNew = !taskId || taskId === "new";

  const [save] = useMutation(TaskMutation, {
    refetchQueries: [{ query: TasksQuery }],
  });
  const [openTaskMutation] = useMutation(TaskOpenMutation, {
    refetchQueries: [{ query: TasksQuery }],
  });
  const [completeTaskMutation] = useMutation(TaskCompleteMutation, {
    refetchQueries: [{ query: TasksQuery }],
  });
  const [deleteTaskMutation] = useMutation(TaskDeleteMutation, {
    refetchQueries: [{ query: TasksQuery }],
  });
  const { data, loading, refetch } = useQuery(TaskQuery, {
    variables: { id: taskId },
    skip: isNew,
  });

  const teamsQuery = useQuery(TeamsQuery);

  useEffect(() => {
    console.log("TaskForm initial load");
  }, []);

  useEffect(() => {
    console.log("useEffect - updating task - this is going in a loop in prod");
    if (!task.userId || !task.dueAtTime) {
      const newTask =
        data && data.task
          ? {
              ...data?.task,
              teamId: data?.task?.team?.id,
              userId: data?.task?.user?.id,
              priority: data?.task?.priority || 0,
              dueAtTime: !data?.task?.allDay
                ? moment(data?.task?.dueAt).toDate()
                : moment(data?.task?.dueAt).startOf("day").toDate(),
              endAtTime: !data?.task?.allDay
                ? moment(data?.task?.endAt).toDate()
                : moment(data?.task?.dueAt).startOf("day").toDate(),
              actionable: {
                name: data?.task?.actionable?.name,
                id: data?.task?.actionableId,
                type: data?.task?.actionableType,
              },
            }
          : {
              title: "",
              description: "",
              recurrenceCode: "no_repeat",
              dueAt: defaultTime,
              dueAtTime: defaultTime,
              endAt: moment().startOf("day"),
              endAtTime: moment().startOf("day"),
              taskCategoryId: categories && _.first(categories)?.value,
              priority: 3,
              teamId: teamsOptions.find(
                (t) => t.userId === currentUser.currentUser.id
              )?.teamId,
              product: props.task?.product,
              userId: currentUser.currentUser.id,
              actionable: {
                name: props.actionableName,
                id: props.actionableId,
                type: props.parentType,
              },
              busy: true,
              allDay: false,
              ...props.task,
            };

      if (newTask) {
        setTask(newTask);
      }
    }
  }, [data, currentUser?.currentUser]);

  let teamsOptions = [];

  const taskCompleted = data?.task && data?.task.completedAt;
  teamsQuery?.data?.teams?.forEach((team) => {
    let usersOptions = team.memberships.map((r) => {
      return {
        value: `${team.id}${r.user?.id}`,
        label: (
          <UserBadge
            name={`${r.user?.firstName || ""} ${r.user?.lastName || ""}`}
            email={r.user?.email}
            subtitle={team.name}
            avatarUrl={r.user?.avatarUrl}
            height={25}
          />
        ),
        teamId: team.id,
        userId: r.user?.id,
      };
    });
    teamsOptions = teamsOptions.concat(usersOptions);
  });

  if (teamsQuery.loading || loading || !currentUser?.currentUser?.id) {
    return <Loader />;
  }
  let defaultTime = moment().minute(
    Math.floor(moment().clone().minute() / 15) * 15
  );
  const openTask = () => {
    openTaskMutation({
      variables: {
        id: taskId,
      },
    }).then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
  };
  const completeTask = (id) => {
    completeTaskMutation({
      variables: {
        ids: [id],
      },
    }).then(
      (response) => {
        props.onSave && props.onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const deleteTask = (id) => {
    deleteTaskMutation({
      variables: {
        ids: [id],
      },
    }).then(
      (response) => {
        props.onSave && props.onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const saveCompleteTask = (values) => {
    saveTask(values, true, (id) => {
      props.onSave && props.onSave();
    });
  };

  const saveTask = (values, activityOnly, callback) => {
    save({
      variables: {
        tasks: props.actionableIds
          ? props.actionableIds.map((companyId) => {
              return {
                id: null,
                actionableId: companyId,
                actionableType: values.actionableType || props.parentType,
                recurrenceCode: values.recurrenceCode,
                title: values.title,
                description: values.description,
                taskCategoryId: values.taskCategoryId,
                priority: values.priority,
                dueAt: values.dueAt,
                endAt: values.endAt,
                userId: values.userId,
                teamId: values.teamId,
                taskStatusId: values.taskStatusId,
                taskStatusName: values.taskStatusName,
                busy: values.busy,
                allDay: values.allDay,
              };
            })
          : [
              {
                id: values.id ? values.id : null,
                actionableId: values.actionable ? values.actionable.id : null,
                actionableType: values.actionable
                  ? values.actionable.type
                  : values.actionableType,
                recurrenceCode: task.recurrenceCode,
                title: values.title,
                description: values.description,
                taskCategoryId: values.taskCategoryId,
                priority: values.priority,
                dueAt: values.dueAt,
                endAt: values.endAt,
                userId: values.userId,
                teamId: values.teamId,
                taskStatusId: values.taskStatusId,
                taskStatusName: values.taskStatusName,
                busy: values.busy,
                allDay: values.allDay,
              },
            ],
        flagAsActivity: activityOnly,
      },
    }).then(
      (response) => {
        if (response.data?.task?.errors) {
          addToast(<Flex>{response.data?.task?.errors[0]}</Flex>, ErrorParams);
        } else {
          addToast(<Flex>Saved!</Flex>, SuccessParams);
          callback && callback(response.data?.task?.task?.id);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return open ? (
    <Flex width="100%">
      <Formik
        enableReinitialize
        initialValues={task}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Required"),
          taskCategoryId: Yup.string().required("Required"),
          dueAt: Yup.date().required("Required"),
          teamId: Yup.string().required("Required"),
          userId: Yup.string().required("Required"),
          endAtTime: Yup.date().test(
            "endAtTime",
            "must be after start time",
            (value, schema) => {
              const { dueAtTime } = schema.parent;
              return (
                (moment(value).hour() == 0 && moment(value).minute() == 0) ||
                moment(value).isSameOrAfter(moment(dueAtTime))
              );
            }
          ),
        })}
        onSubmit={(values, actions) => {
          saveTask(values, props.activityOnly, (id) => {
            props.onSave && props.onSave();
          });
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, handleBlur, handleSubmit, errors } =
            formProps;
          console.log("Task Values");

          console.log(values);
          return (
            <Flex width="100%" flexDirection="column">
              <Flex
                width="100%"
                my={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex flex={1} flexDirection="column" mb={2}>
                  <RadioGroupButton
                    name="taskCategoryId"
                    value={values.taskCategoryId}
                    options={categories?.filter((c) => c.active)}
                    readOnly={taskCompleted}
                    onChange={(name, value) => {
                      if (!taskCompleted) {
                        setFieldValue(name, value?.value);
                      }
                    }}
                  />
                  {errors?.taskCategoryId && (
                    <Text variant="muted" color="red">
                      Required
                    </Text>
                  )}
                </Flex>

                {taskCompleted && (
                  <Flex>
                    <Box>
                      <Box px={2} py={1}>
                        <Text
                          textAlign="center"
                          color="green"
                          fontWeight="bold"
                        >
                          Completed
                        </Text>
                        <Text textAlign="center" variant="muted">
                          {moment(values.completedAt)?.format("DD/MM/YYYY")}
                        </Text>
                      </Box>
                    </Box>
                    <SecondaryButton onClick={() => openTask()}>
                      Reopen
                    </SecondaryButton>
                  </Flex>
                )}
                {!taskCompleted && (
                  <Flex>
                    {values.id ? (
                      <>
                        <EntityDeleteButton
                          useIcon
                          onDelete={() => deleteTask(taskId)}
                          onCancel={() => {}}
                          id={taskId}
                          parentType={"Task"}
                          refetch={() => {
                            history.push("/tasks");
                          }}
                        />
                        <Box mx={2} />
                        <SecondaryButton
                          leftIcon={<TimeIcon />}
                          onClick={() => completeTask(taskId)}
                          bg="green"
                        >
                          Complete
                        </SecondaryButton>
                      </>
                    ) : (
                      <SecondaryButton
                        leftIcon={<TimeIcon />}
                        onClick={() => saveCompleteTask(values)}
                      >
                        <Text>Save &amp; Complete</Text>
                      </SecondaryButton>
                    )}
                    <Box mx={2} />
                    <PrimaryButton
                      leftIcon={<SaveIcon />}
                      onClick={handleSubmit}
                    >
                      Save
                    </PrimaryButton>
                  </Flex>
                )}
              </Flex>
              <Flex width="100%">
                <Flex width="100%" flexDirection="column">
                  <CustomFormGroup
                    placeholder={"Title"}
                    label="Title"
                    name="title"
                    value={values.title}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    readOnly={taskCompleted}
                    helperText={errors && errors.title && errors.title}
                  />
                  <Flex alignItems={"center"}>
                    <Box>
                      <CustomFormGroup
                        placeholder="Start Date"
                        label={"Start Date"}
                        noMargin
                        name={`dueAt`}
                        value={values.dueAt}
                        type="date"
                        readOnly={taskCompleted}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          setFieldValue("endAt", value);

                          if (values.dueAtTime) {
                            setFieldValue(
                              "dueAtTime",
                              moment(value)
                                .hour(moment(values.dueAtTime).hour())
                                .minute(moment(values.dueAtTime).minute())
                            );
                          }
                          if (values.endAtTime) {
                            const isAllDay =
                              moment(values.endAtTime).hour() === 0 &&
                              moment(values.endAtTime).minute() === 0;

                            !isAllDay &&
                              setFieldValue(
                                "endAtTime",
                                moment(value)
                                  .hour(moment(values.endAtTime).hour())
                                  .minute(moment(values.endAtTime).minute())
                              );
                          }
                        }}
                        helperText={errors.dueAt && errors.dueAt}
                      />
                    </Box>
                    <Box mt={3} pt={2} ml={1}>
                      <CustomFormGroup
                        name={`dueAtTime`}
                        value={values.dueAtTime}
                        type="time"
                        noMargin
                        options={timePeriods(values.dueAt)}
                        readOnly={taskCompleted}
                        onChange={(name, value) => {
                          if (!value) {
                            const startOfDay = moment(values.dueAt).startOf(
                              "day"
                            );
                            setFieldValue(name, startOfDay);
                            setFieldValue("dueAt", startOfDay);
                            setFieldValue("endAt", startOfDay);
                            setFieldValue("endAtTime", startOfDay);
                            setFieldValue("allDay", true);
                          } else {
                            setFieldValue(name, value);
                            setFieldValue("dueAt", value);
                            setFieldValue("allDay", false);

                            if (
                              values.endAtTime &&
                              moment(value).isAfter(moment(values.endAtTime))
                            ) {
                              const isAllDay =
                                moment(values.endAtTime).hour() === 0 &&
                                moment(values.endAtTime).minute() === 0;

                              !isAllDay && setFieldValue("endAtTime", value);
                              !isAllDay && setFieldValue("endAt", value);
                            }
                          }
                        }}
                      />
                    </Box>
                    <Text mx={2} mt={3} pt={2}>
                      -
                    </Text>
                    <Box mt={3} pt={2}>
                      <CustomFormGroup
                        name="endAtTime"
                        noMargin
                        value={values.endAtTime}
                        type="time"
                        options={timePeriods(values.endAt)}
                        readOnly={taskCompleted}
                        onChange={(name, value) => {
                          if (!value) {
                            setFieldValue(
                              name,
                              moment(values.endAt).startOf("day")
                            );
                            setFieldValue(
                              "endAt",
                              moment(values.endAt).startOf("day")
                            );
                          } else {
                            setFieldValue(name, value);
                            setFieldValue("endAt", value);
                          }
                          setFieldValue("allDay", false);
                        }}
                        helperText={errors.endAtTime && errors.endAtTime}
                      />
                    </Box>
                    <Box mx={1}>
                      <CustomFormGroup
                        placeholder="End Date"
                        label="End Date"
                        noMargin
                        name={`endAt`}
                        value={values.endAt}
                        type="date"
                        readOnly={taskCompleted}
                        onChange={(name, value) => {
                          setFieldValue(name, value);

                          if (!!values.endAtTime) {
                            const isAllDay =
                              moment(values.endAtTime).hour() === 0 &&
                              moment(values.endAtTime).minute() === 0;
                            !isAllDay &&
                              setFieldValue(
                                "endAtTime",
                                moment(value)
                                  .hour(moment(values.endAtTime).hour())
                                  .minute(moment(values.endAtTime).minute())
                              );
                          }
                        }}
                        helperText={errors.endAt && errors.endAt}
                      />
                    </Box>
                  </Flex>
                  <Flex mt={1}>
                    <CustomFormGroup
                      placeholder="Show as Busy"
                      label="Busy"
                      name="busy"
                      value={values.busy}
                      type="checkbox"
                      readOnly={taskCompleted}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                      noMargin
                      helperText={errors && errors.busy && errors.busy}
                    />
                    <CustomFormGroup
                      placeholder="All day"
                      label="All day"
                      name="allDay"
                      value={values.allDay}
                      type="checkbox"
                      readOnly={taskCompleted}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        if (value) {
                          const startOfDay = moment(values.dueAt).startOf(
                            "day"
                          );
                          setFieldValue("dueAtTime", startOfDay);
                          setFieldValue("dueAt", startOfDay);
                          setFieldValue("endAt", startOfDay);
                          setFieldValue("endAtTime", startOfDay);
                        } else {
                          setFieldValue("dueAt", moment(defaultTime));
                          setFieldValue("dueAtTime", moment(defaultTime));
                        }
                      }}
                      onBlur={handleBlur}
                      noMargin
                      helperText={errors && errors.allDay && errors.allDay}
                    />
                  </Flex>
                  <Flex mt={2}>
                    <Box width={1 / 2}>
                      <Select
                        placeholder="Assigned To"
                        label="Assigned To"
                        name="teamId"
                        width={275}
                        value={teamsOptions?.find(
                          (obj) =>
                            obj.teamId === values.teamId &&
                            obj.userId === values.userId
                        )}
                        readOnly={taskCompleted}
                        onChange={(name, value) => {
                          setFieldValue("userId", value?.userId);
                          setFieldValue("teamId", value?.teamId);
                        }}
                        options={teamsOptions}
                        noOptionsMessage={() => (
                          <Text>
                            {task.product?.name}
                            <Link mx={1} href="/settings/product/teams">
                              team setup
                            </Link>
                            incomplete
                          </Text>
                        )}
                        helperText={
                          (errors.teamId && errors.teamId) ||
                          (errors.userId && errors.userId)
                        }
                      />
                    </Box>
                    <Box width={1 / 2}>
                      <Select
                        placeholder="Priority"
                        label="Priority"
                        name="priority"
                        value={TaskPriorityOptions?.find(
                          (obj) => obj.value === values.priority
                        )}
                        readOnly={taskCompleted}
                        onChange={(name, value) => {
                          if (value) {
                            setFieldValue(name, value?.value);
                          } else {
                            setFieldValue(name, 3);
                          }
                        }}
                        clearable={false}
                        options={TaskPriorityOptions}
                        helperText={errors.priority}
                      />
                      <Box mr={3} />
                    </Box>
                  </Flex>
                  <Box my={2} />
                  <Flex width="100%">
                    {!props.actionableIds && (
                      <TaskActionable
                        placeholder="Linked To"
                        label="Linked To"
                        width={250}
                        name={`actionable`}
                        value={{
                          id: values.actionable?.id,
                          type: values.actionable?.type,
                        }}
                        readOnly={taskCompleted}
                        onChange={(name, value) => {
                          console.log("on Change Value");
                          console.log(name);
                          console.log(value);
                          if (value) {
                            setFieldValue("actionable.id", value.id);
                            setFieldValue("actionable.type", value.type);
                            setFieldValue("actionable.name", value.name);
                          } else {
                            setFieldValue("actionable", undefined);
                          }
                        }}
                      />
                    )}
                  </Flex>
                  <Box my={2} />
                  <CustomFormGroup
                    placeholder="Description"
                    label="Description"
                    name="description"
                    value={values.description}
                    type="richtext"
                    readOnly={taskCompleted}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={
                      errors && errors.description && errors.description
                    }
                  />
                  <TaskComments
                    key={`${values.dueAt}-${values.userId}`}
                    {...formProps}
                    height={600}
                    onSave={() => {
                      refetch({ id: taskId });
                    }}
                  />
                </Flex>

                <Flex
                  ml={3}
                  pl={2}
                  width={350}
                  sx={{
                    borderLeftColor: "accent",
                    borderLeftStyle: "solid",
                    borderLeftWidth: 1,
                  }}
                >
                  <TaskCalendar
                    key={`${values.dueAt}-${values.userId}`}
                    {...formProps}
                  />
                </Flex>
              </Flex>

              {values.trigger && (
                <Flex alignItems={"center"} mb={3}>
                  <Text color="brandLight">
                    <EventIcon size={12} />
                  </Text>
                  <Text variant="muted" mx={1}>
                    Generated by automation
                  </Text>
                  <Text variant="muted">
                    <Link
                      href={`settings/product/automations/${values.trigger.id}`}
                    >
                      {values.trigger.name}
                    </Link>
                  </Text>
                </Flex>
              )}
            </Flex>
          );
        }}
      </Formik>
    </Flex>
  ) : (
    <Flex flex={1} alignItems="center" justifyContent="space-between">
      <PrimaryButton leftIcon={<PlusIcon />} onClick={() => setOpen(true)}>
        Activity
      </PrimaryButton>
    </Flex>
  );
}

TaskForm.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
  open: PropTypes.bool,
  actionableName: PropTypes.string,
  actionableId: PropTypes.string,
  actionableIds: PropTypes.array,
  task: PropTypes.object,
  activityOnly: PropTypes.bool,
  onSave: PropTypes.func,
};

export default TaskForm;

const TaskCalendar = (props) => {
  const { values, setFieldValue } = props;
  let startOffset = moment(values.dueAt).diff(moment({ hours: 0 }), "days");
  const { data, loading } = useQuery(TasksQuery, {
    variables: {
      ownerUserId: values.userId,
      activityTypes: [],
      startDays: startOffset,
      endDays: 1,
      includeDone: false,
      includeOverdue: false,
      includeBusyOnly: false,
    },
  });
  if (loading) return <Loader />;

  const currentTask = [{ ...values, selected: true }];
  const tasks = (data?.tasks.filter((t) => t.id !== values.id) || []).concat(
    currentTask
  );

  const update = (startDate, endDate) => {
    props.setFieldValue("dueAt", startDate);
    props.setFieldValue("dueAtTime", startDate);

    props.setFieldValue("endAt", endDate);
    props.setFieldValue("endAtTime", endDate);
    props.setFieldValue("allDay", false);
  };
  return (
    <UserlotCalendar
      key={`event-${moment(values.dueAt).format("DDMMMYYYYhhmmss")}-${moment(
        values.endAt
      ).format("DDMMMYYYYhhmmss")}`}
      height={props.height}
      defaultView="day"
      defaultDate={values.dueAt}
      data={tasks}
      onNavigate={(event) => {
        const isToday = moment(event).isSame(moment());
        const date = moment(event).startOf("day");
        const startDate = date
          .clone()
          .hour(moment(values.dueAt).hour())
          .minute(moment(values.dueAt).minute());

        const minutesDiff = moment(values.endAt).diff(
          moment(values.dueAt),
          "minutes",
          true
        );

        const endDate =
          !isToday && minutesDiff > 0
            ? date.clone().add(minutesDiff, "minutes")
            : startDate;

        update(startDate, endDate);
      }}
      onMoveEvent={(event) => {
        update(moment(event.dueAt), moment(event.endAt));
      }}
      onEventSelected={(event) => {
        update(moment(event.dueAt), moment(event.endAt));
      }}
      onRangeChange={(event) => {
        const isAllDay =
          moment(event[0]).hour() === 0 && moment(event[0]).minute() === 0;

        if (!isAllDay) {
          // ignore if all day as it is triggered from Navigate and date is already set
          const startDate = moment(event[0]);
          const endDate =
            event.length > 0 && event[1] !== undefined
              ? moment(event[1])
              : startDate;

          update(startDate, endDate);
        }
      }}
    />
  );
};
