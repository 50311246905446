import React from "react";
import { CompanyIcon, MoneyIcon } from "Components/Common/Icons";
import Topbar from "Components/Common/Topbar/Topbar";
import { SatisfactionIcon } from "../../Common/Icons";

function ReportTopbar(props) {
  return (
    <Topbar
      title="Insights"
      menuItems={[
        {
          name: "Account",
          icon: CompanyIcon,
          link: "/reports/accounts",
        },
        { name: "Renewals", icon: MoneyIcon, link: "/reports/renewals" },
        {
          name: "Satisfaction",
          icon: SatisfactionIcon,
          link: "/reports/satisfaction",
        },
      ]}
      crumbs={props.crumbs}
    />
  );
}

export default ReportTopbar;
