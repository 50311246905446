import React, { useState } from "react";
import { Flex } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useTheme } from "emotion-theming";

import { Formik } from "formik";
import * as Yup from "yup";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";
function WebhookForm(props) {
  const theme = useTheme();
  const { field, setFieldValue, fieldName, handleBlur } = props;

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        url: Yup.string().required("Required"),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        return (
          <>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <CustomFormGroup
              placeholder="Webhook Url"
              label="Webhook Url"
              name={`${fieldName}.config.url`}
              value={field.config?.url}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.url && errors.url}
            />
          </>
        );
      }}
    </Formik>
  );
}

export default WebhookForm;
