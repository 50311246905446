import React from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import TasksQuery from "../../../graphql/queries/Tasks.gql";
import TaskDeleteMutation from "../../../graphql/mutations/TaskDelete.gql";
import { Formik } from "formik";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import Label from "Components/Common/Forms/Label";
import Select from "Components/Common/Forms/Select";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { getCurrentUser } from "hooks/getCurrentUser";
import Loader from "Components/Common/Loader";
import { SaveIcon } from "Components/Common/Icons";
import TaskIcon, { TaskCategories, TaskPriorityOptions } from "./TaskDefaults";
import TaskActionable from "./TaskActionable";
import TaskMutation from "../../../graphql/mutations/Task.gql";
import TeamsQuery from "../../../graphql/queries/Teams.gql";
import { CloseIcon, TickIcon } from "Components/Common/Icons";
import ActionButton from "Components/Common/Buttons/ActionButton";
import RadioGroupButton from "Components/Common/RadioGroupButton";
import { timePeriods } from "./TaskForm";
import moment from "moment";
import Avatar from "react-avatar";
import { EntityDeleteButton } from "../../Data/EntityDetailMenu";
import { TrashIcon } from "../../Common/Icons";

const _ = require("lodash");

function BulkTaskForm(props) {
  const currentUser = getCurrentUser();
  const { addToast } = useToasts();
  const categories = TaskCategories();
  const [save, {}] = useMutation(TaskMutation);
  const teamsQuery = useQuery(TeamsQuery);
  const [deleteTaskMutation] = useMutation(TaskDeleteMutation, {
    refetchQueries: [{ query: TasksQuery }],
  });

  let teamsOptions = [];

  const deleteTask = () => {
    deleteTaskMutation({
      variables: {
        ids: props.data.map((t) => t.id),
      },
    }).then(
      (response) => {
        props.onSave && props.onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  teamsQuery &&
    !teamsQuery.loading &&
    teamsQuery.data?.teams.forEach((team) => {
      let usersOptions = team.memberships.map((r) => {
        return {
          value: `${team.id}${r.user.id}`,
          label: (
            <Flex alignItems={"center"}>
              <Avatar
                size={25}
                round
                name={`${r.user.firstName || ""} ${r.user.lastName || ""} `}
                email={r.user?.email}
                src={r.user?.avatarUrl}
              />
              <Box mx={2}>
                <Text>
                  {r.user.firstName} {r.user.lastName}
                </Text>
                <Text variant="muted">{team.name}</Text>
              </Box>
            </Flex>
          ),
          teamId: team.id,
          userId: r.user.id,
        };
      });
      teamsOptions = teamsOptions.concat(usersOptions);
    });

  const task = {
    title: undefined,
    description: undefined,
    dueAt: undefined,
    taskCategoryId: undefined,
    teamId: undefined,
    userId: undefined,
    actionable: {
      name: undefined,
      id: undefined,
      type: undefined,
    },
    titleSet: false,
    markAsBusySet: false,
    dueAtSet: false,
    activityTypeSet: false,
    teamSet: false,
    actionableSet: false,
  };
  return teamsQuery.loading || !currentUser?.currentUser?.id ? (
    <Loader />
  ) : (
    <Flex flexDirection="column" flexGrow={1} flex={1} w="100%">
      <Formik
        enableReinitialize
        initialValues={task}
        autoComplete={false}
        onSubmit={(values, actions) => {
          const bulkTasks = props.data.map((t) => {
            const item = {
              id: t.id,
              recurrenceCode: t.recurrenceCode,
              title: values.titleSet && values.title ? values.title : t.title,
              description: values.markAsBusySet
                ? values.description
                : t.description,
              taskCategoryId:
                values.activityTypeSet && values.taskCategoryId
                  ? values.taskCategoryId
                  : t.taskCategoryId,
              dueAt: values.dueAtSet && values.dueAt ? values.dueAt : t.dueAt,
              endAt: values.dueAtSet && values.endAt ? values.endAt : t.endAt,
              userId:
                values.teamIdSet && values.userId ? values.userId : t.user?.id,
              teamId:
                values.teamIdSet && values.teamId ? values.teamId : t.team?.id,
              actionableId:
                values.actionableSet && values.actionable.id
                  ? values.actionable.id
                  : t.actionable?.id,
              actionableType: t.actionableType,
              taskStatusId: t.taskStatusId,
              taskStatusName: t.taskStatusName,
              busy: t.busy,
            };
            return item;
          });

          save({
            variables: {
              tasks: bulkTasks,
              flagAsActivity: false,
            },
          }).then(
            (response) => {
              if (response.data?.task?.errors) {
                addToast(
                  <Flex>{response.data?.task?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                addToast(<Flex>Saved!</Flex>, SuccessParams);
                props.onSave && props.onSave();
                actions.resetForm();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, handleBlur, handleSubmit, errors } =
            formProps;
          return (
            <Flex w="100%" flexGrow={1} flexDirection="column" mb={4}>
              <Flex w="100%" flexDirection="column">
                <Flex
                  flex={1}
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Text variant="h2">Bulk Edit</Text>
                  <Button variant="ghost" onClick={props.onDismiss}>
                    <CloseIcon />
                  </Button>
                </Flex>
                <Text variant="muted" color="brandLight" my={2} mb={3}>
                  {props.data.length} tasks selected
                </Text>

                <Flex alignItems="center" my={2} justifyContent="space-between">
                  <Text mx={1} variant="label">
                    Dates
                  </Text>
                  <Box mx={2} />
                  <CustomFormGroup
                    type="switch"
                    name="dueAtSet"
                    value={values.dueAtSet}
                    onChange={setFieldValue}
                    noMargin
                    width={70}
                  />
                </Flex>
                {values.dueAtSet && (
                  <Flex
                    mx={2}
                    justifyContent="space-between"
                    flexDirection={"column"}
                  >
                    <Text variant="label">Start</Text>
                    <Flex>
                      <CustomFormGroup
                        placeholder="Keep existing value"
                        width={250}
                        name="dueAt"
                        value={values.dueAt}
                        type="date"
                        noMargin
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          setFieldValue("endAt", value);

                          if (values.dueAtTime) {
                            setFieldValue(
                              "dueAtTime",
                              moment(value)
                                .hour(moment(values.dueAtTime).hour())
                                .minute(moment(values.dueAtTime).minute())
                            );
                          }
                          if (values.endAtTime) {
                            const isAllDay =
                              moment(values.endAtTime).hour() === 0 &&
                              moment(values.endAtTime).minute() === 0;

                            !isAllDay &&
                              setFieldValue(
                                "endAtTime",
                                moment(value)
                                  .hour(moment(values.endAtTime).hour())
                                  .minute(moment(values.endAtTime).minute())
                              );
                          }
                        }}
                        helperText={errors.dueAt && errors.dueAt}
                      />
                      <CustomFormGroup
                        width={250}
                        name="dueAtTime"
                        value={values.dueAt}
                        type="time"
                        noMargin
                        onChange={(name, value) => {
                          if (!value) {
                            const startOfDay = moment(values.dueAt).startOf(
                              "day"
                            );
                            setFieldValue(name, startOfDay);
                            setFieldValue("dueAt", startOfDay);
                            setFieldValue("endAt", startOfDay);
                            setFieldValue("endAtTime", startOfDay);
                            setFieldValue("allDay", true);
                          } else {
                            setFieldValue(name, value);
                            setFieldValue("dueAt", value);
                            setFieldValue("allDay", false);

                            if (
                              values.endAtTime &&
                              moment(value).isAfter(moment(values.endAtTime))
                            ) {
                              const isAllDay =
                                moment(values.endAtTime).hour() === 0 &&
                                moment(values.endAtTime).minute() === 0;

                              !isAllDay && setFieldValue("endAtTime", value);
                              !isAllDay && setFieldValue("endAt", value);
                            }
                          }
                        }}
                        options={timePeriods(values.dueAt)}
                      />
                    </Flex>
                    <Text mx={1} variant="label">
                      End
                    </Text>
                    <Flex>
                      <CustomFormGroup
                        placeholder="Keep existing value"
                        width={250}
                        name="endAt"
                        value={values.endAt}
                        type="date"
                        noMargin
                        onChange={(name, value) => {
                          setFieldValue(name, value);

                          if (!!values.endAtTime) {
                            const isAllDay =
                              moment(values.endAtTime).hour() === 0 &&
                              moment(values.endAtTime).minute() === 0;
                            !isAllDay &&
                              setFieldValue(
                                "endAtTime",
                                moment(value)
                                  .hour(moment(values.endAtTime).hour())
                                  .minute(moment(values.endAtTime).minute())
                              );
                          }
                        }}
                        helperText={errors.endAt && errors.endAt}
                      />
                      <CustomFormGroup
                        width={250}
                        name="endAtTime"
                        value={values.endAt}
                        type="time"
                        noMargin
                        onChange={(name, value) => {
                          if (!value) {
                            setFieldValue(
                              name,
                              moment(values.endAt).startOf("day")
                            );
                            setFieldValue(
                              "endAt",
                              moment(values.endAt).startOf("day")
                            );
                          } else {
                            setFieldValue(name, value);
                            setFieldValue("endAt", value);
                          }
                          setFieldValue("allDay", false);
                        }}
                        options={timePeriods(values.endAt)}
                      />
                    </Flex>
                  </Flex>
                )}
                <Flex alignItems="center" my={2} justifyContent="space-between">
                  <Label mx={1} name={props.name}>
                    Activity Type
                  </Label>
                  <Box mx={2} />
                  <CustomFormGroup
                    type="switch"
                    name="activityTypeSet"
                    value={values.activityTypeSet}
                    onChange={setFieldValue}
                    noMargin
                    width={70}
                  />
                </Flex>

                {values.activityTypeSet && (
                  <RadioGroupButton
                    name="taskCategoryId"
                    value={values.taskCategoryId}
                    options={categories?.filter((c) => c.active)}
                    onChange={(name, value) => {
                      setFieldValue(name, value?.value);
                    }}
                  />
                )}

                <Flex alignItems="center" my={2} justifyContent="space-between">
                  <Text mx={1} variant="label">
                    Title
                  </Text>
                  <Box mx={2} />
                  <CustomFormGroup
                    type="switch"
                    name={`titleSet`}
                    value={values.titleSet}
                    onChange={setFieldValue}
                    noMargin
                    width={70}
                  />
                </Flex>

                {values.titleSet && (
                  <CustomFormGroup
                    placeholder="Keep existing value"
                    name="title"
                    value={values.title}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors && errors.title && errors.title}
                  />
                )}

                <Flex alignItems="center" my={2} justifyContent="space-between">
                  <Text mx={1} mb={2} variant="label">
                    Assigned To
                  </Text>
                  <Box mx={2} />
                  <CustomFormGroup
                    type="switch"
                    name={`teamIdSet`}
                    value={values.teamIdSet}
                    onChange={setFieldValue}
                    noMargin
                    width={70}
                  />
                </Flex>

                {values.teamIdSet && (
                  <Select
                    placeholder="Keep existing value"
                    name={`teamId`}
                    value={teamsOptions?.find(
                      (obj) =>
                        obj.teamId === values.teamId &&
                        obj.userId === values.userId
                    )}
                    onChange={(name, value) => {
                      setFieldValue("userId", value?.userId);
                      setFieldValue("teamId", value?.teamId);
                    }}
                    options={teamsOptions}
                    helperText={errors.teamId && errors.teamId}
                  />
                )}

                <Flex alignItems="center" my={2} justifyContent="space-between">
                  <Text mx={1} variant="label">
                    Linked To
                  </Text>
                  <Box mx={2} />
                  <CustomFormGroup
                    type="switch"
                    name={`actionableSet`}
                    value={values.actionableSet}
                    onChange={setFieldValue}
                    noMargin
                    width={70}
                  />
                </Flex>
                {values.actionableSet && (
                  <TaskActionable
                    flexDirection="column"
                    placeholder="Keep existing value"
                    name="actionable"
                    value={{
                      id: values.actionable?.id,
                      type: values.actionable?.type,
                    }}
                    onChange={(name, value) => {
                      setFieldValue("actionable", value);
                    }}
                  />
                )}

                <Box my={2} />
                <PrimaryButton
                  leftIcon={<SaveIcon />}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Update All
                </PrimaryButton>
                <Box my={3} variant="divider" />
                <ActionButton
                  leftIcon={<TickIcon />}
                  onClick={props.onComplete}
                >
                  Complete All
                </ActionButton>
                <Box my={3} variant="divider" />
                <Text variant="muted" bg="gray.100" p={2}>
                  Note: deleting a task is permanent.
                </Text>
                <Flex
                  justifyItems={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <EntityDeleteButton
                    useIcon
                    onDelete={() => deleteTask()}
                    onCancel={() => {}}
                    parentType={"Task"}
                    refetch={() => {
                      history.push("/tasks");
                    }}
                    label={
                      <Flex justifyContent={"center"} alignItems={"center"}>
                        <TrashIcon />
                        <Text mx={2}>Delete all</Text>
                      </Flex>
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default BulkTaskForm;
