import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "rebass";
import Select from "./Forms/Select";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import EndUserCategoryQuery from "../../graphql/queries/EndUserCategory.gql";

export const UserBadgeTypes = [
  {
    value: 3,
    color: "gray.700",
    bg: "white",
    border: "gray.600",
  },
  {
    value: 2,
    color: "greenDark",
    bg: "white",
    border: "gray.500",
  },
  {
    value: 1,
    color: "greenDark",
    bg: "greenLight",
    border: "greenDark",
  },
  {
    value: 0,
    color: "white",
    bg: "greenDark",
    border: "greenDark",
  },
];
const UserTypeBadge = (props) => {
  const { categoryId, online } = props;
  const { data } = useQuery(EndUserCategoryQuery);

  var category = data?.endUserCategories?.find((c) => c.value == categoryId);
  var badge = UserBadgeTypes.find((b) => b.value.toString() == category?.info);

  return category ? (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      minWidth={props.minimise == true ? undefined : "100%"}
      sx={{
        borderWidth: 1,
        borderRadius: 99,
        borderStyle: "solid",
        borderColor: badge?.border,
        backgroundColor: badge?.bg,
      }}
    >
      {category?.active && online !== undefined && (
        <Box
          ml={2}
          sx={{
            width: 10,
            height: 10,
            borderRadius: 99,
            borderColor: online ? "greenLight" : "white",
            borderWidth: 1,
            borderStyle: "solid",
            backgroundColor: online ? "green" : "gray.200",
          }}
        ></Box>
      )}
      <Text
        textAlign={"center"}
        mx={2}
        my={1}
        variant="label"
        fontSize="xxs"
        color={badge?.color}
      >
        {category?.label}
      </Text>
    </Flex>
  ) : null;
};
UserTypeBadge.propTypes = {
  categoryId: PropTypes.string,
};

export default UserTypeBadge;

export const UserTypeSelect = ({ values, setFieldValue }) => {
  const { data: userTypes } = useQuery(EndUserCategoryQuery);
  const types = userTypes?.endUserCategories?.map((t) => {
    return {
      value: t.value,
      label: (
        <Flex flexDirection={"column"}>
          <UserTypeBadge categoryId={t.value} minimise />
        </Flex>
      ),
      labelDetail: (
        <Flex flexDirection={"column"}>
          <UserTypeBadge categoryId={t.value} minimise />
          {!t.active && (
            <Text variant="muted" fontSize="xxs" px={2}>
              NB: product usage statistics not recorded
            </Text>
          )}
        </Flex>
      ),
    };
  });
  return (
    <Box mb={2}>
      <Select
        placeholder="Category"
        label="Category"
        name="endUserCategoryId"
        value={types?.find((o) => o.value === values?.endUserCategoryId)}
        options={types?.map((d) => {
          return { value: d.value, label: d.labelDetail };
        })}
        onChange={(name, option) => setFieldValue(name, option?.value)}
        width="100%"
        clearable={false}
      />
      {values.endUserCategoryId == undefined && (
        <Text variant="muted" color="red">
          Required
        </Text>
      )}
    </Box>
  );
};
UserTypeSelect.propTypes = {
  value: PropTypes.number,
};
