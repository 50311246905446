import React, { useState } from "react";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";

const Oauth2Form = ({
  values,
  errors,
  setFieldValue,
  handleSave,
  onComplete,
}) => {
  // FIXME: Right now we just create new authentications each time,
  // but we want to switch this to a list that you can manage
  const save = () => {
    handleSave(values, (auth) => {
      //This calculation probably has to be on other types too?
      let resolvedAuthUrl = values.authEndpoint;
      Object.keys(values.settings).forEach((key) => {
        const value = values.settings[key];
        resolvedAuthUrl = resolvedAuthUrl.replace(`{{${key}}}`, value);
      });
      
      authoriseOAuth2(resolvedAuthUrl, auth);
    });
  };

  const authoriseOAuth2 = (endpoint, state) => {
    let authUrl = endpoint;
    authUrl = `${authUrl}&state=${state.id}`;
    const width = 850;
    const height = 720;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `WINDOW TITLE`;
    const url = authUrl;
    window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
    window.addEventListener(
      "message",
      (response) => {
        let data = response.data;
        if (data === "success") {
          onComplete(state);
        }
      },
      false
    );
  };

  return (
    <>
      <PrimaryButton
        onClick={() => {
          save();
        }}
      >
        Connect to {values.apiName}
      </PrimaryButton>
    </>
  );
};

export default Oauth2Form;
