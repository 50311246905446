import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import CompanyStatusQuery from "../graphql/queries/CompanyStatus.gql";

export function getCompanyStatus() {
  const query = useQuery(CompanyStatusQuery, {});
  return {
    ...query,
  };
}
