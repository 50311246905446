import React from "react";
import { Flex, Text, Box } from "rebass";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  HealthIcon,
  MinusIcon,
} from "Components/Common/Icons";
import { RiHeart3Fill } from "react-icons/ri";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import { useTheme } from "emotion-theming";
import moment from "moment";
import { dateToFromNowDaily } from "../../../consts";

function HealthScoreIcon({
  disabled,
  value,
  valueDiff,
  createdAt,
  category,
  status,
  simple,
  trend,
}) {
  const theme = useTheme();
  let color = disabled
    ? "gray.300"
    : category === "poor"
    ? "red"
    : category === "good"
    ? "green"
    : "yellow";

  return status?.name == "Paid" ? (
    simple ? (
      <Flex
        alignItems="center"
        justfiyContent="center"
        color={color}
        sx={{ position: "relative" }}
      >
        <RiHeart3Fill size={30} />
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "absolute" }}
        >
          <Text textAlign="center" color="white" fontSize="xxs">
            {value}
          </Text>
        </Flex>
      </Flex>
    ) : (
      <Flex
        p={3}
        flex={1}
        justfiyContent="center"
        variant="box"
        flexDirection="column"
      >
        <Flex flexDirection="column" flex={1}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="label">Health Score</Text>
          </Flex>
          <Text variant="muted" fontSize={"xxs"} mt={1}>
            {createdAt && ` updated ${dateToFromNowDaily(moment(createdAt))}`}
          </Text>
          <Flex width="100%" pb={1} alignItems="center" mt={2}>
            <Flex color={color}>
              <RiHeart3Fill size={25} />
            </Flex>
            <Text variant="h2" fontSize="xl" mx={1}>
              {value}
            </Text>
            <Text color={valueDiff < 0 ? "red" : valueDiff > 0 && "green"}>
              <Flex alignItems="center">
                {!valueDiff || valueDiff === 0 ? (
                  <MinusIcon />
                ) : valueDiff < 0 ? (
                  <ArrowDownIcon />
                ) : (
                  valueDiff > 0 && <ArrowUpIcon />
                )}
                <Text fontSize={"xxs"}>{valueDiff || 0}</Text>
              </Flex>
            </Text>
          </Flex>

          {trend && (
            <Flex width="100%" flexDirection={"column"} maxWidth={100}>
              <Box height={30}>
                <Sparklines data={trend}>
                  <SparklinesLine
                    color={theme.colors.brandLight}
                    style={{ fill: "none" }}
                  />
                  <SparklinesSpots style={{ fill: theme.colors.brandLight }} />
                </Sparklines>
              </Box>
              <Flex flex={1}>
                <Text variant="muted" fontSize={"xxs"} mt={1}>
                  30 day trend
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  ) : (
    !status && (
      <Flex
        alignItems="center"
        justfiyContent="center"
        color={color}
        sx={{ position: "relative" }}
      >
        <RiHeart3Fill size={30} />
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "absolute" }}
        >
          <Text textAlign="center" color="white" fontSize="xxs">
            {value}
          </Text>
        </Flex>
      </Flex>
    )
  );
}

export default HealthScoreIcon;
