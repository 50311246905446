import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import ChurnReasonsQuery from "../graphql/queries/ChurnReasons.gql";

export function getChurnReasons() {
  const query = useQuery(ChurnReasonsQuery, {});
  return {
    ...query,
  };
}
