import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import CurrentUserQuery from "../graphql/queries/CurrentUser.gql";
import CurrentAccountQuery from "../graphql/queries/CurrentAccount.gql";
import { userlotIdentify } from "../analytics/userlot-tracking";

export function getCurrentUser() {
  const currentUser = useQuery(CurrentUserQuery, {});
  const currentAccount = useQuery(CurrentAccountQuery, {});

  userlotIdentify(currentUser.data?.currentUser);

  return {
    client: currentAccount?.client,
    loading: currentUser?.loading || currentAccount?.loading,
    currentUser: !currentUser.loading && currentUser.data?.currentUser,
    currentAccount:
      !currentAccount.loading && currentAccount.data?.currentAccount,
  };
}
