import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { setContext } from "apollo-link-context";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { ApolloClient, gql, HttpLink } from "apollo-boost";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import apolloLogger from "apollo-link-logger";
import { ThemeProvider } from "emotion-theming";
import "./react-transitions.css";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Box, Flex, Text } from "rebass";
import { ConfigProvider, Cache } from "react-avatar";
import { onError } from "@apollo/client/link/error";
import {
  getDefaultProduct,
  redirectToSubdomain,
  redirectToRoot,
  storeDefaultProduct,
  serverHostname,
} from "./auth-helper";
import {
  CloseIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from "./components/Common/Icons";
import theme from "./theme";
import Routes from "./routes";
import introspectionQueryResultData from "./graphql/schema/fragmentTypes.json";
import SearchFilter from "./graphql/queries/SearchFilter.gql";
import { getSearchFilter } from "./hooks/getSearchFilter";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import gaTracking from "./analytics/ga-tracking";
import { LiveChatLoaderProvider } from "react-live-chat-loader";
import { userlotIdentify } from "./analytics/userlot-tracking";
import { ErrorParams } from "./components/Common/Notifications/Error";

export function getProduct() {
  const filters = getSearchFilter();
  const productId = filters?.filters?.productId;
  return productId;
}

const avatarCache = new Cache({
  // Keep cached source failures for up to 7 days
  sourceTTL: 7 * 24 * 3600 * 1000,

  // Keep a maximum of 20 entries in the source cache
  sourceSize: 20,
});

const errorLink = onError(
  ({ response, operation, graphQLErrors, networkError, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.log(
          `[GraphQL error]: Message: ${err.message}, Path: ${err.path}`
        );
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);

      if (
        networkError.statusCode === 401 ||
        networkError.statusCode === "401"
      ) {
        redirectToRoot();
      }
      if (
        networkError.result &&
        networkError.result.exception &&
        networkError.result.exception.includes("Tenant not found")
      ) {
        redirectToSubdomain();
      }
      if (
        networkError.result &&
        networkError.result.data &&
        networkError.result.data.code &&
        networkError.result.data.code === "REDIRECT_SUBDOMAIN"
      ) {
        console.log(JSON.stringify(networkError.result.response));
        redirectToSubdomain(networkError.result.data.subdomain);
      }
    }
  }
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });
console.log(`Our api will be at ${serverHostname()}`);
export const ServerUrl = `${serverHostname()}/graphql`;

const query = SearchFilter;
const data = {
  filters: {
    segmentId: undefined,
    ownerUserId: undefined,
    statusId: undefined,
    contacts: {
      filterText: undefined,
    },
    conversion: {
      filterText: undefined,
    },
    paid: {
      filterText: undefined,
    },
  },
};

export const clearStore = (client) => {
  if (client) {
    client.clearStore().then(() => {
      console.log("cache cleared");
      cache.writeQuery({ query, data });
    });
  }
};
const typeDefs = gql`
  extend type Query {
    filters: SearchFilter
  }

  type SearchFilter {
    id: ID!
    ownerUserId: String
    segmentId: String
    productId: String
    statusId: String
    health: String
  }
`;

export const resolvers = {
  Mutation: {
    saveFilters: (_, { filters }, { cache, client }) => {
      const data = client.readQuery({ query });
      data.filters = { ...data.filters, ...filters };

      storeDefaultProduct(data.filters?.productId);

      client.writeQuery({ query, data });
    },
  },
};

cache.writeQuery({ query, data });

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const productId = getDefaultProduct();
  userlotIdentify();
  return {
    headers: {
      ...headers,
      product: productId,
    },
  };
});

const options = {
  uri: ServerUrl,
  credentials: "include", //'same-origin'
};

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(options),
  ApolloLink.split(
    (operation) => operation.getContext().isAuth,
    new HttpLink(options),
    new HttpLink(options)
    //new BatchHttpLink(options)
  )
);
const link = ApolloLink.from(
  [
    process.env.NODE_ENV === "development" ? apolloLogger : null,
    authLink,
    errorLink,
    httpLink,
  ].filter((o) => o)
);

export const client = new ApolloClient({
  cache: cache,
  link: link,
  typeDefs: typeDefs,
  resolvers: resolvers,
});

const ToastComponent = ({ appearance, children, ...props }) => {
  let color = "success";
  switch (appearance) {
    case "error":
      color = "error";
      break;
    case "warning":
      color = "error";
      break;
    case "success":
      color = "success";
      break;
    case "info":
      color = "secondaryLight";
      break;
    default:
      color = "secondaryLight";
      break;
  }
  let icon;
  switch (appearance) {
    case "error":
      icon = <WarningIcon size={30} />;
      break;
    case "warning":
      icon = <WarningIcon size={30} />;
      break;
    case "success":
      icon = <SuccessIcon size={30} />;
      break;
    case "info":
      icon = <InfoIcon size={30} />;
      break;
    default:
      icon = <InfoIcon size={30} />;
      break;
  }
  return (
    <Box
      py={3}
      m={0}
      sx={{
        boxShadow: "small",
        borderRadius: "small",
        borderLeftColor: color,
        borderLeftStyle: "solid",
        borderLeftWidth: 6,
        minWidth: 250,
      }}
      color="black"
      bg="white"
    >
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Box color={color} mx={3}>
          {icon}
        </Box>
        <Flex flex={1} mr={3} flexDirection="column">
          <Text fontWeight="bold" sx={{ textTransform: "capitalize" }}>
            {appearance}
          </Text>
          {children}
        </Flex>
        <Flex>
          <Box
            sx={{ cursor: "pointer" }}
            color="gray.500"
            onClick={props.onDismiss}
            mx={2}
          >
            <CloseIcon size={20} />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

const App = () => {
  gaTracking();

  return (
    <ErrorBoundary name="outer">
      <LiveChatLoaderProvider
        provider="helpScout"
        providerKey={window.UserlotConfig?.helpscout}
        idlePeriod="500"
      >
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <ToastProvider
              placement="top-center"
              components={{ Toast: ToastComponent }}
              autoDismissTimeout={3000}
            >
              <ConfigProvider colors={theme.colors.avatars} cache={avatarCache}>
                <ErrorBoundary name="inner">
                  <Router>
                    <ScrollToTop />
                    <Routes />
                  </Router>
                </ErrorBoundary>
              </ConfigProvider>
            </ToastProvider>
          </ApolloProvider>
        </ThemeProvider>
      </LiveChatLoaderProvider>
    </ErrorBoundary>
  );
};

export default App;

function ScrollToTop2({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export const ScrollToTop = withRouter(ScrollToTop2);
