import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";

function StepTwo(props) {
  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Required"),
      })}
      autoComplete={false}
      onSubmit={(values, actions) => {
        props.nextStep();
      }}
    >
      {(formProps) => {
        const { values, handleChange, handleBlur, handleSubmit } = formProps;

        return (
          <Box
            as="form"
            flexDirection="column"
            onSubmit={handleSubmit}
            noValidate
            width="100%"
            sx={{
              minWidth: 350,
            }}
          >
            <Text color="brandLight" variant="h2" mt={4}>
              Please check your email!
            </Text>

            <Text my={2} as="body" variant="body">
              We have sent you an email. Please confirm your email address by
              clicking the link.
            </Text>
          </Box>
        );
      }}
    </Formik>
  );
}

export default StepTwo;
