import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Flex, Image, Box, Text, Link, Heading } from "rebass";
import { useMutation } from "@apollo/react-hooks";
import { ServerUrl } from "../../../App";
import Loader from "Components/Common/Loader";
import WebsiteWrapper from "../WebsiteWrapper";
import ConfirmEmailMutation from "../../../graphql/mutations/ConfirmEmail.gql";
import { useEffectOnce } from "react-use";

function ConfirmEmail(props) {
  const { key } = useParams();
  const [errors, setErrors] = useState();

  const [confirmEmail, { error, loading: savingRegistration }] =
    useMutation(ConfirmEmailMutation);

  useEffectOnce(() => {
    confirmEmail({
      variables: {
        confirmationToken: key,
      },
      context: {
        uri: ServerUrl,
        // fetchOptions: { method: "GET" },
      },
    }).then(
      (response) => {
        if (response.data.confirmEmail?.errors) {
          setErrors(response.data.confirmEmail?.errors);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  });

  return (
    <WebsiteWrapper>
      <Flex width={1 / 2} justifyContent="center" flexDirection={"column"}>
        <Text my={3} textAlign={"center"} variant="h3">
          Email change request
        </Text>
        {savingRegistration && <Loader />}

        {errors
          ? errors.map((e) => (
              <Text key={e} variant="error" textAlign={"center"}>
                Confirmation failed
              </Text>
            ))
          : !savingRegistration && (
              <Text textAlign={"center"}>Your email has been confirmed!</Text>
            )}
        <Flex mt={3} justifyContent="center">
          Return to
          <Link href="/" mx={2}>
            Login
          </Link>
        </Flex>
      </Flex>
    </WebsiteWrapper>
  );
}

export default withRouter(ConfirmEmail);
