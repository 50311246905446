import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import TagsQuery from "../graphql/queries/TagDetails.gql";

export function getAllTags() {
  const tagsQuery = useQuery(TagsQuery, {});

  return {
    ...tagsQuery,
  };
}
