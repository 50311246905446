import React, { useEffect, useState } from "react";
import Page from "Components/Page";
import { Flex, Button, Text, Box } from "rebass";
import CompaniesList from "Components/Data/Company/CompaniesConversions";
import Filter from "Components/Filter";
import { MODEL_TYPES } from "consts";
import { useTheme } from "emotion-theming";
import { getSearchFilter } from "hooks/getSearchFilter";
import { getLifeCycles } from "hooks/getLifeCycles";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getAllCompanyData } from "hooks/getAllCompanyData";
import ConversionTopbar from "./ConversionTopbar";
import { getCompanyStatus } from "../../../hooks/getCompanyStatuses";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import StageMenuSelect from "../CompanyHealth/StageMenuSelect";
import TrialConversionMatrix from "Components/Common/TrailConversionMatrix";
import { useUrlParams } from "../../../hooks/useUrlParams";
import PropTypes from "prop-types";
import { getCompanyConversionDashboard } from "../../../hooks/getCompanyConversionDashboard";

function Conversions(props) {
  const theme = useTheme();
  const { data: searchFilters } = getSearchFilter();
  const [saveFilter] = useMutation(SearchFilterMutation);
  const statusName = "Trial";
  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const defaultTags = getUrlParameter("tags");

  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(400);
  const [perPage, setPerPage] = useState(50);
  const [sort, setSort] = useState(undefined);

  const handleSort = async (column, sortDirection) => {
    setSort({
      column: column.id,
      direction: sortDirection,
      schemaName: column.schemaName,
    });
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const { data: statuses } = getCompanyStatus();
  const statusId = statuses?.companyStatuses.find(
    (s) => s.name === statusName
  )?.id;
  const filters = statusId
    ? {
        filterText: searchFilters?.filters?.conversion?.filterText,
        lifeCycleStageId: searchFilters?.filters?.conversion?.lifeCycleStageId,
        planId: searchFilters?.filters?.conversion?.planId,
        segmentId: searchFilters?.filters?.segmentId,
        ownerUserId: searchFilters?.filters?.ownerUserId,
        teamId: searchFilters?.filters?.teamId,
        tags: searchFilters?.filters?.tags,
        page: page,
        size: perPage,
        sort: sort,
      }
    : {};

  const {
    loading,
    data: companyData,
    error,
    refetch,
  } = getAllCompanyData(statusName, {
    filters,
  });

  const {
    data: companiesConversionDashboard,
    loading: dashboardLoading,
    refetch: refetchDashboard,
  } = getCompanyConversionDashboard(statusName, {
    ...filters,
    page: undefined,
    size: undefined,
  });

  useEffect(() => {
    if (companyData?.companies) {
      setTotalRows(companyData?.companies.total);
    }
  }, [companyData]);

  const [trigger, setTriggerRefresh] = useState();

  const { data: lcData } = getLifeCycles(statusId);

  useEffect(() => {
    /* If there is a tags in the url set the state for searching. */
    if (
      searchFilters?.filters &&
      (!searchFilters.filters.tags || searchFilters.filters.tags.length == 0) &&
      defaultTags?.split(",").length > 0
    ) {
      if (defaultTags.split(",").length > 0) {
        updateFilter(
          "tags",
          defaultTags.split(","),
          `Tags (${defaultTags.split(",").length})`
        );
      } else {
        updateFilter("tags", [], undefined);
      }
    }
  }, [searchFilters?.filters]);

  const lifeCycles =
    lcData?.lifeCycles?.find((l) => l.name === statusName)?.stages || [];
  const data = {
    companies: companyData?.companies?.companies
      ?.filter((d) => d.details?.status?.name === statusName)
      .sort((a, b) => {
        return (
          b.details?.conversionFitScore - a.details?.conversionFitScore ||
          b.details?.conversionAdoptionScore -
            a.details?.conversionAdoptionScore
        );
      }),
  };

  const matrixData =
    companiesConversionDashboard?.companiesConversionDashboard?.map((d) => {
      return {
        customerFit: d.details?.conversionFitScore,
        adoption: d.details?.conversionAdoptionScore,
      };
    });

  const updateFilter = (name, id, label) => {
    const newFilters = filters;

    let ref = newFilters;
    let nestedNames = name.split(".").reverse();
    while (nestedNames.length > 1) {
      const n = _.last(nestedNames);
      ref[n] = {};
      ref = ref[n];
      nestedNames = nestedNames.slice(0, -1);
    }
    const n = _.last(nestedNames);
    ref[n] = id;

    if (!newFilters.labels) {
      newFilters.labels = {};
    }
    newFilters.labels[name] = label;
    saveFilter({
      variables: {
        filters: newFilters,
      },
    }).then(
      (response) => {
        props.onChange && props.onChange();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Page title="Conversion Accounts" topBar={<ConversionTopbar />}>
      <Filter
        menu={
          lifeCycles?.length > 0 && (
            <Flex alignItems="center">
              <Text variant="h3" mr={3} color="muted">
                Lifecycle:
              </Text>

              <StageMenuSelect
                moduleType="Trial"
                selected={!filters?.planId}
                onChange={(name, plan) => {
                  updateFilter("conversion.planId", plan?.id, plan?.name);
                }}
                value={filters?.planId}
              />
              {lifeCycles?.map((lc, i) => (
                <Flex key={i} alignItems="center">
                  <Button
                    variant="default"
                    mx={1}
                    bg={
                      filters?.lifeCycleStageId === lc.id
                        ? "brand"
                        : "brandHighlight"
                    }
                    color={
                      filters?.lifeCycleStageId === lc.id
                        ? "white"
                        : "brandLight"
                    }
                    ml={0}
                    mr={1}
                    py={0}
                    my={0}
                    key={lc.id}
                    onClick={() => {
                      if (filters?.lifeCycleStageId === lc.id) {
                        updateFilter(
                          "conversion.lifeCycleStageId",
                          undefined,
                          undefined
                        );
                      } else {
                        updateFilter(
                          "conversion.lifeCycleStageId",
                          lc.id,
                          lc.name
                        );
                      }
                    }}
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      height: 30,
                      marginLeft: 30,
                      marginRight: 30,
                      paddingLeft: i === 0 ? 12 : 30,
                      paddingRight: i === lifeCycles.length - 1 ? 20 : 0,
                      position: "relative",
                      borderRadius: 0,
                      borderTopRightRadius:
                        i === lifeCycles.length - 1 ? 99 : 0,
                      borderBottomRightRadius:
                        i === lifeCycles.length - 1 ? 99 : 0,
                      "&:before": {
                        // triangle hover color
                        borderLeft: i === 0 ? 0 : `15px solid white`,
                        // the triangle
                        content: `''`,
                        position: "absolute",
                        borderBottom: "15px solid transparent",
                        borderTop: "15px solid transparent",
                        height: 0,
                        width: 0,
                        marginTop: 0,
                        left: 0,
                        top: 0,
                        zIndex: 1,
                      },
                      "&:after": {
                        // triangle hover color
                        borderLeft:
                          i < lifeCycles.length - 1
                            ? `15px solid ${
                                filters?.lifeCycleStageId === lc.id
                                  ? theme.colors.brand
                                  : theme.colors.brandHighlight
                              }`
                            : 0,
                        // the triangle
                        content: `''`,
                        position: "absolute",
                        borderBottom: "15px solid transparent",
                        borderTop: "15px solid transparent",
                        height: 0,
                        width: 0,
                        marginRight: -15,
                        marginTop: 0,
                        top: 0,
                        right: 0,
                        zIndex: 2,
                      },
                      "&:hover": {
                        color: "white",
                        backgroundColor: "brandLight",
                        "&:after": {
                          // triangle hover
                          borderLeft:
                            i < lifeCycles.length - 1
                              ? `15px solid ${theme.colors.brandLight}`
                              : 0,
                        },
                      },
                    }}
                  >
                    {lc.name}
                  </Button>
                </Flex>
              ))}
            </Flex>
          )
        }
        onChange={() => setTriggerRefresh(new Date())}
        typeName={MODEL_TYPES.Company.name}
        module="conversion"
      >
        <Flex flexDirection="column" flexGow={1} flex={1} pt={0}>
          <Flex flex={1} px={4} flexDirection="column">
            <TrialConversionMatrix
              data={matrixData}
              loading={dashboardLoading}
            />
            <Flex flex={1} flexDirection="column" mt={2}>
              <CompaniesList
                data={data}
                error={error}
                loading={loading}
                onChangeRowsPerPage={handlePerRowsChange}
                onSort={handleSort}
                onChangePage={handlePageChange}
                paginationTotalRows={totalRows}
                refetch={() => {
                  refetchDashboard({
                    variables: {
                      ...filters,
                      page: undefined,
                      size: undefined,
                    },
                  });
                  refetch({
                    variables: {
                      searchFilters: {
                        ...filters,
                      },
                    },
                  });
                }}
                module="conversion"
              />
            </Flex>
          </Flex>
        </Flex>
      </Filter>
    </Page>
  );
}
Conversions.propTypes = {
  onChange: PropTypes.func,
};
export default Conversions;
