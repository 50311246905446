import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Flex, Image, Box, Text, Button } from "rebass";
import StepWizard from "react-step-wizard";
import WizardNav from "Components/Common/WizardNav";
import Page from "Components/Page";
import CodePreview from "../../Settings/ApplicationKeys/CodePreview";
import ImportWizard from "../../Settings/Import/ImportWizard";
import { useState } from "react";
import { getCurrentUser } from "hooks/getCurrentUser";
import Loader from "Components/Common/Loader";
import { getApplicationKeys } from "hooks/getApplicationKeys";
import {Subdomain} from "../../../../auth-helper";

function SetupAccountsWizard(props) {
  const { currentAccount, loading } = getCurrentUser();
  const [currentStep, setCurrentStep] = useState();
  const [stepTwo, setStepTwo] = useState();

  useEffect(() => {
    let step = !currentAccount.apiSetupComplete
      ? 1
      : !currentAccount.churnSetupComplete
      ? 3
      : 4;
    setCurrentStep(step);
  }, [loading]);

  if (loading || !currentStep) return <Loader />;
  return (
    <Page>
      <Box width="100%" height="100%">
        <StepWizard
          onStepChange={setCurrentStep}
          initialStep={currentStep}
          nav={<WizardNav stepLabels={["Data Source", "Connect"]} />}
        >
          <SetupConnectAccounts onSelect={(type) => setStepTwo(type)} />

          {stepTwo === "api" ? (
            <SetupConnectAccountsAPI />
          ) : (
            <SetupConnectAccountsImport />
          )}
        </StepWizard>
      </Box>
    </Page>
  );
}

export default withRouter(SetupAccountsWizard);

function SetupConnectAccounts(props) {
  return (
    <Flex flex={1}>
      <Flex alignItems="center" justifyContent="center" flex={1}>
        <Flex width={1 / 3}>
          <Flex
            flex={1}
            m={4}
            p={4}
            variant="box"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer" }}
          >
            <Image
              src={require("assets/images/partner-segment-logo.png")}
              height={35}
              sx={{ objectFit: "contain" }}
            />
          </Flex>
        </Flex>
        <Flex width={1 / 3}>
          <Flex
            flex={1}
            m={4}
            p={4}
            variant="box"
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              props.onSelect("api");
              props.nextStep();
            }}
            sx={{ cursor: "pointer" }}
          >
            <Image src={require("assets/images/icon.png")} height={35} />
            <Text variant="h3">Userlot API</Text>
          </Flex>
        </Flex>
        <Flex width={1 / 3}>
          <Flex
            flex={1}
            m={4}
            p={4}
            variant="box"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              props.onSelect("import");
              props.nextStep();
            }}
          >
            <Image src={require("assets/images/icon.png")} height={35} />
            <Text variant="h3">Manual Import</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function SetupConnectAccountsAPI(props) {
  const { data } = getApplicationKeys();
  const apiKey = data?.apis && data.apis[0];

  return (
    <Flex alignItems="center" flex={1} flexDirection="column">
      <Text mt={3} variant="h3">
        Install Script
      </Text>
      <Text>Paste the following javascript code into your application.</Text>
      <Box width={600}>
        <CodePreview>
          {`<script>
    window.Userlot=window.Userlot||[];
    function userlot(){window.Userlot.push(arguments);}
    (function(a,b,c,d){var cz=a.createElement(b); cz.type=c; cz.async=true; cz.src=d;var s=a.getElementsByTagName(b)[0];s.parentNode.insertBefore(cz, s);})(document, "script",'text/javascript', 'https://scripts.userlot.app/analytics.js');
    userlot(['init', '${Subdomain()}', '${props.apiKey || ""}']);

    // Then before sending any events you should identify the user and company/account
    userlot(["identify", /*<userEmail>*/, /*<userExternalId>*/, /*<companyName>*/, /*<companyExternalId>*/]);
</script>`}
        </CodePreview>
      </Box>
      <Text mt={3} variant="h4">
        Log events
      </Text>
      <Text>Paste the following javascript code into your application.</Text>

      <Box width={600}>
        <CodePreview>
          {`Userlot.push(["trackEvent", "login", 1, { loadedPage: true }]);
`}
        </CodePreview>
        <Button onClick={() => props.nextStep()}>Next</Button>
      </Box>
    </Flex>
  );
}

function SetupConnectAccountsImport(props) {
  return (
    <Flex flex={1} justifyContent="center">
      <Box variant="content">
        <ImportWizard onComplete={() => props.nextStep()} />
      </Box>
    </Flex>
  );
}
