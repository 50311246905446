import React from "react";
import { Text } from "rebass";

function Label({ name, children, sx, ...restProps }) {
  return (
    <Text
      variant="label"
      htmlFor={name}
      sx={{
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Text>
  );
}
export default Label;
