import React, { useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Flex, Image, Box, Text, Link, Heading } from "rebass";
import StepWizard from "react-step-wizard";
import WizardNav from "Components/Common/WizardNav";
import StepOne from "./Step1";
import StepThree from "./Step3";
import StepTwo from "./Step2";
import RegisterMutation from "../../../graphql/mutations/AccountRegistration.gql";
import LoginMutation from "../../../graphql/mutations/Login.gql";
import { useMutation } from "@apollo/react-hooks";
import { ServerUrl } from "../../../App";
import StepFour from "./Step4";
import Loader from "Components/Common/Loader";
import WebsiteWrapper from "../WebsiteWrapper";
import TermsOfUsePrivacy from "../TermsOfUsePrivacy";

function RegisterAccount(props) {
  const { key } = useParams();
  const [errors, setErrors] = useState();
  const history = useHistory();

  const [register, { error, loading: savingRegistration }] =
    useMutation(RegisterMutation);
  const [login, { loading: savingLogin }] = useMutation(LoginMutation);

  const loading = savingLogin || savingRegistration;

  const [accountData, setAccountData] = useState();

  const handleSubmit = (values) => {
    register({
      variables: {
        accountName: values.accountName,
        firstName: values.firstName,
        lastName: values.lastName,
        confirmationToken: key,
        password: values.password,
        productName: values.productName,
        externalId: "dontneedthis",
        subdomain: values.subdomain,
      },
      context: {
        isAuth: true,
        uri: ServerUrl,
      },
    }).then(
      (response) => {
        if (response.data.registration?.errors) {
          setErrors(response.data.registration?.errors);
        } else {
          let email = response.data.registration?.authenticatable?.email;
          if (!!email) {
            login({
              variables: {
                email: email,
                password: values.password,
              },
              context: {
                isAuth: true,
                uri: ServerUrl,
              },
              skip: !email,
            }).then(
              (response) => {
                if (response.errors) {
                  let firstError = response.errors[0];
                  if (firstError?.extensions.code === "USER_ERROR") {
                    actions.setErrors([firstError.message]);
                  }
                } else if (response?.data && response.data?.redirect_url) {
                  window.location.href = response.data.redirect_url;
                } else {
                  props.history.push("/home");
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <WebsiteWrapper>
      <Box width={1 / 2}>
        {loading && (
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            bg="gray.100"
            sx={{
              zIndex: 2,
              position: "absolute",
              width: "100%",
              height: "100%",
              opacity: 0.5,
            }}
          >
            <Loader />
          </Flex>
        )}
        <StepWizard
          nav={
            <WizardNav
              stepLabels={["User", "Password", "Product", "Account"]}
            />
          }
        >
          <StepOne
            onUpdate={(data) => {
              setAccountData({ ...accountData, ...data });
            }}
          />
          <StepTwo
            onUpdate={(data) => {
              setAccountData({ ...accountData, ...data });
            }}
          />
          <StepThree
            data={accountData}
            onUpdate={(data) => {
              setAccountData({ ...accountData, ...data });
            }}
          />
          <StepFour
            data={accountData}
            onUpdate={(data) => {
              handleSubmit({ ...accountData, ...data });
            }}
          />
        </StepWizard>
        {errors &&
          errors.map((e) => (
            <Text key={e} variant="error">
              {e}
            </Text>
          ))}
        <Flex>
          Already have an account?
          <Link href="/" mx={2}>
            Login
          </Link>
        </Flex>
        <TermsOfUsePrivacy />
      </Box>
    </WebsiteWrapper>
  );
}

export default withRouter(RegisterAccount);
