import React, { useState, useEffect } from "react";
import Page from "Components/Page";
import { Box, Text, Flex, Link, Image } from "rebass";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTheme } from "emotion-theming";
import {
  RiEmotionLaughLine,
  RiEmotionNormalLine,
  RiEmotionSadLine,
  RiLayoutLine,
} from "react-icons/ri";
import "react-vertical-timeline-component/style.min.css";
import Table from "Components/Common/Table";
import "assets/styles/react-tabs.css";
import ReportTopbar from "./ReportTopbar";
import moment from "moment";
import MenuSelect from "../../Common/Forms/MenuSelect";
import CustomFormGroup from "../../Common/Forms/CustomFormGroup";
import DonutGraph from "../../Common/Graphs/DonutGraph";
import { getProducts } from "hooks/getProducts";
import { getProduct } from "hooks/getProduct";
import { getScoreReport } from "../../../hooks/getScoreReport";
import SimpleBarGraph from "../../Common/Graphs/SimpleBarGraph";
import SatisfactionResponses from "../../Common/Satisfaction/SatisfactionResponses";
import SatisfactionTable from "../../Common/Satisfaction/SatisfactionTable";
import { processSatisfactionData } from "../../Common/Satisfaction/SatisfactionDefaults";

const scoreColumns = [
  {
    name: "Score",
    selector: (row) => row.value,
    sortable: true,
  },
  {
    name: "Responses",
    selector: (row) => row.total,
    sortable: true,
    right: true,
  },
  {
    name: "%",
    selector: (row) => row.percentage,
    sortable: true,
    right: true,
  },
];

function Satisfaction(props) {
  const { data: currentProductData } = getProduct();
  const [productId, setProduct] = useState(undefined);
  const [daysAgo, setDaysAgo] = useState(90);
  const { data: productData } = getProducts();

  useEffect(() => {
    if (!productId) {
      setProduct(currentProductData?.currentProduct?.id);
    }
  }, [currentProductData]);

  return (
    <Page title="Satisfaction" topBar={<ReportTopbar />}>
      <Flex mt={3} justifyContent="flex-end" alignItems="center">
        <MenuSelect
          label=""
          name=""
          value={productId}
          options={
            productData?.products?.map((product) => {
              return {
                icon: undefined,
                value: product.id,
                title: product.name,
                description: "",
              };
            }) || []
          }
          onChange={(name, value) => {
            // on change
            setProduct(value?.value);
          }}
        />
        <Flex ml={2}>
          <CustomFormGroup
            type="list"
            noMargin
            onChange={(name, value) => {
              setDaysAgo(value?.value);
            }}
            value={daysAgo}
            width="200px"
            options={[
              { value: 30, label: "Last 30 Days" },
              { value: 60, label: "Last 60 Days" },
              { value: 90, label: "Last 3 months" },
              { value: 180, label: "Last 6 months" },
            ]}
          />
        </Flex>
      </Flex>
      <Tabs width="100%" direction="rtl">
        <TabList>
          <Tab key={"NPS"}>
            <Flex alignItems="center" justifyContent="flex-end">
              NPS
            </Flex>
          </Tab>
          <Tab key={"CSAT"}>
            <Flex alignItems="center" justifyContent="flex-end">
              CSAT
            </Flex>
          </Tab>
          <Tab key={"CES"}>
            <Flex alignItems="center" justifyContent="flex-end">
              CES
            </Flex>
          </Tab>
        </TabList>
        <TabPanel key={"NPS"} className="health-react-tabs__tab-panel">
          <SatifactionWidget
            scoreType="nps"
            daysAgo={daysAgo}
            productId={productId}
          />
        </TabPanel>
        <TabPanel key={"CSAT"} className="health-react-tabs__tab-panel">
          <SatifactionWidget
            scoreType="csat"
            daysAgo={daysAgo}
            productId={productId}
          />
        </TabPanel>
        <TabPanel key={"CES"} className="health-react-tabs__tab-panel">
          <SatifactionWidget
            scoreType="ces"
            daysAgo={daysAgo}
            productId={productId}
          />
        </TabPanel>
      </Tabs>
    </Page>
  );
}

export default Satisfaction;

const SatifactionWidget = (props) => {
  return (
    <Flex px={4}>
      <Tabs className="primary-tabs" width="100%" direction="rtl">
        <TabList className="primary-tabs__tab-list">
          <Tab
            key={"Snapshot"}
            className="primary-tabs__tab"
            selectedClassName="primary-tabs__tab--selected"
          >
            <Flex alignItems="center" justifyContent="flex-end">
              <RiLayoutLine />
              <Text mx={2}>Snapshot</Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanel key={"Snapshot"}>
          <SatifactionBreakdown
            scoreType={props.scoreType}
            productId={props.productId}
            daysAgo={props.daysAgo}
          />
        </TabPanel>
      </Tabs>
    </Flex>
  );
};

const SatifactionBreakdown = (props) => {
  const theme = useTheme();
  const [responses, setResponses] = useState(undefined);
  const { data: scoreData, loading } = getScoreReport(
    props.scoreType?.toLowerCase(),
    props.productId,
    Number(props.daysAgo)
  );

  let { processedData: breakdownData, totalResponses } =
    processSatisfactionData(
      scoreData?.scoresReport,
      props.scoreType,
      responses
    );

  let graphData = [];
  graphData = breakdownData?.map((data) => {
    const item = {
      date: moment().add(-1 * data.value, "days"),
      day: data.value,
      classify: data.classify,
      count: data.total,
      percentage: data.percentage,
    };
    return item;
  });

  let score = 0;
  if (props.scoreType === "nps") {
    let promoters =
      scoreData?.scoresReport?.filter(
        (c) => classifyData(props.scoreType, c.value) > 0
      ).length || 0;

    let detractors =
      scoreData?.scoresReport?.filter(
        (c) => classifyData(props.scoreType, c.value) < 0
      ).length || 0;

    score =
      totalResponses > 0
        ? (((promoters - detractors) / totalResponses) * 100).toFixed(0)
        : 0;
  } else {
    let sumScores =
      _.sum(
        _(scoreData?.scoresReport || [])
          .map((objs, key) => Number(objs.value))
          .value()
      ) || 0;
    score = totalResponses > 0 ? (sumScores / totalResponses).toFixed(0) : 0;
    score = convertValue(props.scoreType, score);
  }

  let breakdownGraphData = [
    {
      value: scoreData?.scoresReport?.filter(
        (c) =>
          classifyData(
            props.scoreType,
            convertValue(props.scoreType, c.value)
          ) > 0
      ).length,
      label: labelPositive(props.scoreType),
      color: theme.colors.green,
    },
    {
      value: scoreData?.scoresReport?.filter(
        (c) =>
          classifyData(
            props.scoreType,
            convertValue(props.scoreType, c.value)
          ) === 0
      ).length,
      label: labelNeutral(props.scoreType),
      color: theme.colors.gray["300"],
    },
    {
      value: scoreData?.scoresReport?.filter(
        (c) =>
          classifyData(
            props.scoreType,
            convertValue(props.scoreType, c.value)
          ) < 0
      ).length,
      label: labelNegative(props.scoreType),
      color: theme.colors.red,
    },
  ];

  

  return (
    <Flex width="100%" flexDirection="column" mt={2}>
      <Flex px={4} mb={3} flexDirection="column">
        <Flex justifyContent="flex-end" flex={1} my={2}>
          <CustomFormGroup
            type="list"
            noMargin
            onChange={(name, value) => {
              setResponses(value?.value);
            }}
            value={responses}
            width="250px"
            options={[
              { value: undefined, label: "All Responses" },
              { value: 1, label: labelPositive(props.scoreType) },
              { value: -1, label: labelNegative(props.scoreType) },
              { value: 0, label: labelNeutral(props.scoreType) },
            ]}
          />
        </Flex>
        <Flex mx={2}>
          <Flex
            width={1 / 3}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <DonutGraph
              key={props.scoreType}
              data={breakdownGraphData}
              value={
                <Box>
                  <Text fontWeight="normal" fontSize="xs" textAlign="center">
                    {props.scoreType.toUpperCase()}
                  </Text>
                  <Text fontSize="xl" textAlign="center">
                    {score || 0}
                  </Text>
                </Box>
              }
              width={150}
            />
          </Flex>
          {graphData && (
            <Box width={2 / 3}>
              <SimpleBarGraph
                series={graphData}
                height={200}
                detratorMax={detratorMax(props.scoreType)}
                promoterMin={promoterMin(props.scoreType)}
                max={yMax(props.scoreType)}
              />
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex flex={1} width="100%" mt={3} bg="gray.100" variant="box">
        <Flex
          px={4}
          pt={4}
          flexDirection="column"
          sx={{
            borderRightStyle: "solid",
            borderRightWidth: 1,
            borderRightColor: "accent",
          }}
        >
          <SatisfactionTable data={breakdownData} loading={loading} />
        </Flex>
        <Box width="50%" px={4} pt={4}>
          <SatisfactionResponses
            data={scoreData?.scoresReport}
            scoreType={props.scoreType}
            responses={responses}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

const convertValue = (type, value) => {
  switch (type) {
    case "csat":
      return value / 2;
    default:
      return value;
  }
};
const yMax = (type) => {
  switch (type) {
    case "nps":
      return 11;
    case "csat":
      return 6;
    case "ces":
      return 11;
    default:
      return 11;
  }
};
const labelNeutral = (type) => {
  switch (type) {
    case "nps":
      return "Passive";
    case "csat":
      return "Neutral";
    case "ces":
      return "Neutral";
    default:
      return "Neutral";
  }
};
const labelNegative = (type) => {
  switch (type) {
    case "nps":
      return "Detractor";
    case "csat":
      return "Dissatisfied";
    case "ces":
      return "Disagree";
    default:
      return "Detractor";
  }
};
const labelPositive = (type) => {
  switch (type) {
    case "nps":
      return "Promoter";
    case "csat":
      return "Satisfied";
    case "ces":
      return "Agree";
    default:
      return "Promoter";
  }
};

const classifyData = (type, value) => {
  let convertedValue = value;
  switch (type) {
    case "nps":
      if (convertedValue <= 6) return -1;
      if (convertedValue >= 9) return 1;
      return 0;
    case "csat":
      if (convertedValue <= 2) return -1;
      if (convertedValue >= 4) return 1;
      return 0;
    case "ces":
      if (convertedValue <= 2) return 1;
      if (convertedValue >= 5) return -1;
      return 0;
    default:
      return 0;
  }
};
const detratorMax = (type) => {
  switch (type) {
    case "nps":
      return 6;
    case "csat":
      return 2;
    case "ces":
      return 2;
    default:
      return 0;
  }
};

const promoterMin = (type) => {
  switch (type) {
    case "nps":
      return 9;
    case "csat":
      return 4;
    case "ces":
      return 4;
    default:
      return 0;
  }
};
