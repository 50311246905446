import React, { useState } from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import {
  FlexibleWidthXYPlot,
  YAxis,
  VerticalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
} from "react-vis";
import { useTheme } from "emotion-theming";
import { Box, Flex, Image, Text } from "rebass";

const _ = require("lodash");

export default function BarGraph(props) {
  const [value, setValue] = useState();
  const theme = useTheme();

  return props.series && props.series.length > 0 ? (
    <Flex mx={3}>
      <FlexibleWidthXYPlot
        height={props.height || 250}
        onMouseLeave={() => setValue(false)}
        margin={{ left: 60 }}
      >
        <VerticalGridLines />
        <YAxis tickFormat={(tick) => tick % 1 == 0 && tick.toFixed(0)} />
        {props.series?.map((ev, i) => {
          return (
            <VerticalBarSeries
              onNearestXY={(value) => setValue(value)}
              key={i}
              animation={{ damping: 5, stiffness: 200 }}
              color={theme.colors.graph[i]}
              data={[{ y: ev.count, x: 1, name: ev.name }]}
            />
          );
        })}
      </FlexibleWidthXYPlot>
      {!props.noLegend && (
        <Box sx={{ minWidth: "150px" }}>
          {props.series.map((d, i) => (
            <Flex key={i} alignItems="center" my={1}>
              <Flex
                m={1}
                sx={{ width: 5, height: 5, borderRadius: 3 }}
                bg={theme.colors.graph[i]}
              ></Flex>
              <Text mx={2} variant="muted" fontSize="xs">
                {d.name}
              </Text>
            </Flex>
          ))}
        </Box>
      )}
    </Flex>
  ) : (
    <Flex p={3} flexDirection="column" justifyContent="center">
      <Image
        src={require("assets/images/EventsEmpty.png")}
        sx={{ width: "70px", objectFit: "contain" }}
      ></Image>
    </Flex>
  );
}
