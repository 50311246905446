import React from "react";
import { useQuery } from "@apollo/react-hooks";
import IntegrationDefinitions from "../graphql/queries/IntegrationDefinitions.gql";

export function getIntegrationDefinitions() {
  const integrationDefinitions = useQuery(IntegrationDefinitions, {});

  return {
    ...integrationDefinitions,
  };
}
