import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import ScoreReportQuery from "../graphql/queries/ScoreReport.gql";

export function getScoreReport(scoreType, productId, daysAgo) {
  const query = useQuery(ScoreReportQuery, {
    variables: { scoreType: scoreType, productId: productId, daysAgo: daysAgo },
  });

  return {
    ...query,
  };
}
