import React from "react";
import { Flex, Box, Text } from "rebass";
import PropTypes from "prop-types";
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "Components/Common/Icons";

function SimpleStatCard(props) {
  const bgColor = props.bg || "brandHighlight";
  const color = props.color || "brandLight";
  return (
    <Flex
      flex={1}
      mr={3}
      p={3}
      justifyContent="center"
      flexDirection="column"
      variant={!props.noBorder && "box"}
    >
      <Flex
        flex={1}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Flex
          flex={1}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text name={props.label} fontSize="xs">
            {props.label}
          </Text>
          {props.icon && (
            <Flex
              ml={2}
              alignItems="center"
              justifyContent="center"
              bg={bgColor}
              p={2}
              sx={{ borderRadius: "medium", width: 30, height: 30 }}
            >
              <Text color={color}>{props.icon}</Text>
            </Flex>
          )}
        </Flex>
        <Text variant="h1" color={props.color || "brandLight"}>
          {props.value}
        </Text>

        {props.increase !== undefined && (
          <Flex alignItems="center">
            <Text
              color={
                props.increase > 0
                  ? "green"
                  : props.increase === 0
                  ? "gray.400"
                  : "red"
              }
              variant="label"
              textAlign="center"
            >
              {props.increase > 0 ? (
                <ArrowUpIcon size={16} />
              ) : props.increase === 0 ? (
                <MinusIcon size={16} />
              ) : (
                <ArrowDownIcon size={16} />
              )}
            </Text>
            <Text
              variant="label"
              fontWeight="bold"
              color="gray.400"
              textAlign="center"
              fontSize={"xxs"}
            >
              {props.increase}%
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

SimpleStatCard.propTypes = {
  label: PropTypes.string,
  increase: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default SimpleStatCard;
