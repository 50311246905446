import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import { Flex, Text, Box } from "rebass";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import { ArrowDropDownIcon } from "Components/Common/Icons";

function VisibilityFilter(props) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Box sx={{ position: "relative" }}>
      <Text px={1} pb={2} variant="label" htmlFor={props.name}>
        {props.label}
      </Text>
      <SecondaryButton
        rightIcon={<ArrowDropDownIcon color="brandLight" />}
        sx={{ width: "100%" }}
        dropdownToggle
        onClick={() => setShowMenu(!showMenu)}
      >
        {props.options.find((o) => o.value === props.value)?.label || ""}
      </SecondaryButton>
      {showMenu && (
        <Flex
          p={3}
          bg="white"
          sx={{
            borderColor: "gray.100",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: 99,
            position: "absolute",
            top: 65,
            width: 225,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[500]}`,
          }}
        >
          <Box>
            {props.options.map((o) => (
              <Flex
                key={o.id}
                my={2}
                onClick={() => {
                  props.onChange(props.name, o);
                  setShowMenu(false);
                }}
              >
                {o.description}
              </Flex>
            ))}
          </Box>
        </Flex>
      )}
    </Box>
  );
}

export default VisibilityFilter;
