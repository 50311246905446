import React from "react";
import { Button, Flex, Text, Box, Image } from "rebass";
import {
  RiEmotionHappyLine,
  RiEmotionLaughLine,
  RiEmotionNormalLine,
  RiEmotionSadLine,
  RiEmotionUnhappyLine,
} from "react-icons/ri";
import { useTheme } from "emotion-theming";

const SurveyButtons = ({ value, surveyType, onClick, brandColor }) => {
  switch (surveyType) {
    case "nps":
      return (
        <Flex
          flexShrink={1}
          alignItems="center"
          justifyContent="center"
          my={2}
          flexDirection="column"
        >
          <Flex width="100%" justifyContent="space-between" px={2} pb={1}>
            <Text variant="muted">Unlikely</Text>
            <Text variant="muted">Likely</Text>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            {[...new Array(11)].map((a, i) => (
              <SurveyButton
                key={i}
                surveyType={surveyType}
                value={i}
                currentValue={value}
                brandColor={brandColor}
                onClick={onClick}
              />
            ))}
          </Flex>
        </Flex>
      );
    case "csat":
      return (
        <Flex
          flexShrink={1}
          alignItems="center"
          justifyContent="center"
          my={2}
          flexDirection="column"
        >
          <Flex alignItems="center" justifyContent="center">
            {[...new Array(5)].map((a, i) => (
              <SurveyButton
                key={i}
                surveyType={surveyType}
                value={i + 1}
                brandColor={brandColor}
                onClick={onClick}
              />
            ))}
          </Flex>
        </Flex>
      );
    case "ces":
      return (
        <Flex
          flexShrink={1}
          alignItems="center"
          justifyContent="center"
          my={2}
          flexDirection="column"
        >
          <Flex width="100%" justifyContent="space-between" mx={2}>
            <Text variant="muted">Very Difficult</Text>
            <Text variant="muted">Very Easy</Text>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            {[...new Array(10)].map((a, i) => (
              <SurveyButton
                key={i}
                surveyType={surveyType}
                value={i + 1}
                brandColor={brandColor}
                onClick={onClick}
              />
            ))}
          </Flex>
        </Flex>
      );
    default:
      return null;
  }
};

export default SurveyButtons;

export const SurveyButton = ({
  value,
  currentValue,
  onClick,
  surveyType,
  brandColor,
}) => {
  const theme = useTheme();
  const faces = [
    <RiEmotionSadLine size={25} />,
    <RiEmotionUnhappyLine size={25} />,
    <RiEmotionNormalLine size={25} />,
    <RiEmotionHappyLine size={25} />,
    <RiEmotionLaughLine size={25} />,
  ];

  brandColor = !brandColor ? theme.colors.brandLight : brandColor;
  switch (surveyType) {
    case "csat":
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          key={value}
          width={35}
          height={35}
          onClick={() => onClick && onClick(value)}
          sx={{
            borderRadius: "medium",
            boxShadow: "medium",
            borderColor: brandColor,
            borderStyle: "solid",
            borderWidth: 1,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: brandColor,
              color: "white",
            },
          }}
          bg={currentValue === value ? brandColor : "white"}
          color={currentValue === value ? "white" : brandColor}
          mx={1}
          p={1}
        >
          {faces[value - 1]}
        </Flex>
      );
    default:
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          key={value}
          width={35}
          height={35}
          onClick={() => onClick && onClick(value)}
          sx={{
            borderRadius: "medium",
            boxShadow: "medium",
            borderColor: brandColor,
            borderStyle: "solid",
            borderWidth: 1,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: brandColor,
              color: "white",
            },
          }}
          bg={currentValue === value ? brandColor : "white"}
          color={currentValue === value ? "white" : brandColor}
          mx={1}
          p={1}
        >
          {value}
        </Flex>
      );
  }
};
