import React, { useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Flex, Button, Text, Box, Image } from "rebass";
import Loader from "Components/Common/Loader";
import { useQuery } from "@apollo/react-hooks";
import { Input } from "@rebass/forms";
import { PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import {
  CloseIcon,
  EditIcon,
  SearchIcon,
  TickIcon,
} from "../../../Common/Icons";
import MenuMultiSelect from "../../../Common/Forms/MenuMultiSelect";
import EmailTemplateQuery from "../../../../graphql/queries/EmailTemplates.gql";
import TagsQuery from "../../../../graphql/queries/Tags.gql";
import RadioGroup from "../../../Common/RadioGroup";
import { getCurrentUser } from "hooks/getCurrentUser";
import moment from "moment";
import Tags from "../../../Common/Tags";

function EmailTemplates(props) {
  const { currentUser } = getCurrentUser();
  const history = useHistory();
  const [searchText, setSearchText] = useState(undefined);
  const [enabledState, setEnabledState] = useState("all");
  const [selectedTags, setSelectedTags] = useState([]);

  const { data: tags } = useQuery(TagsQuery, {
    variables: {
      modelType: "categories",
    },
  });
  const { data, loading, error, refetch } = useQuery(EmailTemplateQuery, {
    variables: {
      searchText: searchText,
      tags: selectedTags,
    },
  });

  let emailTemplates = data?.emailTemplates;

  emailTemplates =
    enabledState == "active"
      ? data?.emailTemplates.filter((w) => w.enabled)
      : emailTemplates;

  emailTemplates =
    enabledState == "mine"
      ? data?.emailTemplates.filter((w) => w.createdBy?.id === currentUser?.id)
      : emailTemplates;
  return (
    <>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={1} justifyContent="space-between">
            <Text variant="h2">Email Templates</Text>
            <Button
              variant="action"
              onClick={() => {
                history.push("/settings/product/emailtemplates/new");
              }}
            >
              <Flex>
                <PlusIcon /> Add Template
              </Flex>
            </Button>
          </Flex>
          <Flex alignItems="center" justifyContent="flex-end">
            <Box mx={2} width={1 / 3}>
              <RadioGroup
                name={`enabledState`}
                selectedOption={enabledState}
                noFlex
                options={[
                  { value: "all", label: "All" },
                  { value: "mine", label: "Show only mine" },
                ]}
                onChange={(name, value) => {
                  setEnabledState(value?.value);
                }}
              />
            </Box>
            <MenuMultiSelect
              onChange={(types) => {
                setSelectedTags(types);
                refetch({
                  searchText: searchText,
                  tags: types,
                });
              }}
              options={tags?.tags.map((o) => {
                return { value: o, label: o };
              })}
              value={selectedTags}
            />
            <Box sx={{ position: "relative" }} alignItems="center" m={2}>
              <Input
                bg="white"
                sx={{
                  zIndex: 1,
                }}
                placeholder=""
                value={searchText}
                onChange={(e) => {
                  refetch({
                    searchText: e.target.value,
                    tags: selectedTags,
                  });
                  setSearchText(e.target.value);
                }}
              />
              <Flex
                sx={{
                  position: "absolute",
                  right: 4,
                  top: 0,
                  bottom: 0,
                  zIndex: 2,
                }}
                width={35}
                alignItems="center"
                justifyContent="center"
                color="line"
                p={2}
                onClick={() => {
                  setSearchText("");
                }}
              >
                <CloseIcon size={20} />
              </Flex>
              <Flex
                sx={{
                  position: "absolute",
                  right: 1,
                  top: 0,
                  bottom: 0,
                  zIndex: 2,
                }}
                width={35}
                alignItems="center"
                justifyContent="center"
                color="brandLight"
                p={2}
              >
                <SearchIcon size={20} />
              </Flex>
            </Box>
          </Flex>
          {loading && <Loader />}
          {emailTemplates?.map((item) => (
            <Flex
              key={item.id}
              variant="box"
              sx={{
                minHeight: "60px",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "brandHighlight",
                  borderBottomWidth: 0,
                },
              }}
              m={2}
              p={2}
              px={4}
              alignItems="center"
              onClick={() =>
                history.push(`/settings/product/emailtemplates/${item.id}`)
              }
            >
              <Flex flex={1} alignItems="center" justifyContent="space-between">
                <Flex width={4 / 6} mx={2} flexDirection="column">
                  <Text variant="h4">{item.name}</Text>
                  <Text my={1} variant="muted">
                    {item.subject}
                  </Text>
                  <Tags value={item.tags} />
                </Flex>

                <Flex width={1 / 6} flexDirection="column">
                  <Text variant="label">Created By</Text>
                  <Text>{item.createdBy?.name}</Text>
                </Flex>
                <Flex width={1 / 6} flexDirection="column">
                  <Text variant="label">Last Updated</Text>
                  <Text>{moment(item.updatedAt).format("DD MMM YYYY")}</Text>
                </Flex>
              </Flex>
            </Flex>
          ))}

          {data?.emailTemplates.length > 0 && emailTemplates.length === 0 && (
            <Flex m={5} alignItems={"center"} justifyContent="center">
              <Text>No email templates matching your filters</Text>
            </Flex>
          )}
          {data?.emailTemplates.length === 0 && <EmptyState />}
        </Flex>
      </Flex>
    </>
  );
}

export default withRouter(EmailTemplates);
const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex
      p={5}
      flex={1}
      justifyContent="center"
      bg="gray.100"
      sx={{
        borderColor: "accent",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        minHeight: 400,
      }}
    >
      <Flex m={4} flex={1} justifyContent="center" flexDirection="column">
        <Text my={3} fontWeight="bold">
          Email templates keep your brand and message consistant with your
          accounts and have your team use standard templates for communication.
        </Text>
        <Text my={3}>To set up your templates you will need to:</Text>
        <Box mx={1}>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Validate your email domains
          </Flex>
        </Box>
        <Flex my={3}>
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/emailtemplates/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Email Templates
          </PrimaryButton>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ marginTop: "-10%", height: "150px", objectFit: "contain" }}
        />
      </Flex>
    </Flex>
  );
};
