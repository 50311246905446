import React from "react";
import UserpassForm from "./UserpassForm";
import Oauth2Form from "./Oauth2Form";
import ApikeyForm from "./ApikeyForm";
import _ from "lodash";

const AuthenticationForm = (props) => {
  const authForms = {
    UserpassForm: UserpassForm,
    ApikeyForm: ApikeyForm,
    Oauth2Form: Oauth2Form,
  };

  const formName = _.upperFirst(_.toLower(props.values.authenticationType));

  const AuthTag = authForms[`${formName}Form`];
  return AuthTag ? <AuthTag {...props} /> : null;
};

export default AuthenticationForm;
