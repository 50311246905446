import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import ApisQuery from "../graphql/queries/Apis.gql";

export function getApplicationKeys() {
  const apisQuery = useQuery(ApisQuery, {});

  return {
    ...apisQuery,
  };
}
