import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Settings from "../ProductSettings";
import { Link, Flex, Button, Text, Box, Image } from "rebass";
import ImportQuery from "../../../../graphql/queries/Import.gql";
import ImportsQuery from "../../../../graphql/queries/Imports.gql";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Table from "Components/Common/Table";
import moment from "moment";

import ImportCancelMutation from "../../../../graphql/mutations/ImportCancel.gql";
import ImportRollbackMutation from "../../../../graphql/mutations/ImportRollback.gql";
import { useToasts } from "react-toast-notifications";
import { SuccessParams } from "Components/Common/Notifications/Success";
import ImportWizard from "./ImportWizard";
import { CheckCircleIcon, HelpIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import axios from "axios";
import ReactJson from "react-json-view";
import { InfoIcon, WarningIcon } from "../../../Common/Icons";

function Import(props) {
  const { addToast, removeToast, removeAllToasts } = useToasts();
  const { loading, data, error, refetch } = useQuery(ImportQuery, {});
  const importsQuery = useQuery(ImportsQuery, {});
  const [importData, setImportData] = useState(undefined);
  const [currentStep, setCurrentStep] = useState(undefined);
  const [rollback, {}] = useMutation(ImportRollbackMutation);
  const [cancel, {}] = useMutation(ImportCancelMutation);

  useEffect(() => {
    if (data && data.import) {
      setImportData(data?.import);
    } else if (!loading) {
    }
  }, [loading]);

  const rollbackImport = (id) => {
    removeAllToasts();
    rollback({
      variables: {
        id: id,
      },
    }).then(
      (response) => {
        addToast(
          <Flex flexDirection="column">
            Backing out import. We'll notify you once it's complete.
          </Flex>,
          SuccessParams
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const cancelImport = (id) => {
    removeAllToasts();
    cancel({
      variables: {
        id: id,
      },
    }).then(
      (response) => {
        addToast(
          <Flex flexDirection="column">Cancelling import.</Flex>,
          SuccessParams
        );
        setTimeout(() => window.location.reload(), 2000);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const downloadTemplate = (modelName) => {
    const hostname =
      process.env.NODE_ENV === "development"
        ? `${window.location.origin.replace("3000", "3001")}`
        : `${window.location.origin}`;

    axios
      .get(`${hostname}/import/download?model=${modelName}`, {
        responseType: "blob",
      })
      .then((res) => {
        const a = document.createElement("a");
        a.download = `${modelName}.csv`;
        a.href = window.URL.createObjectURL(res.data);
        a.addEventListener("click", (e) => {
          setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
      });
  };
  const cancelImportRequest = (id) => {
    addToast(
      <Flex flexDirection="column">
        <Box>
          Are you sure you want to cancel import?
          <Text variant="muted">
            This action cancels import at current stage and will not rollback
            data that has been added
          </Text>
        </Box>
        <Flex my={2}>
          <PrimaryButton
            onClick={() => {
              cancelImport(id);
            }}
          >
            Confirm
          </PrimaryButton>
        </Flex>
      </Flex>,
      {
        appearance: "info",
        autoDismiss: false,
      }
    );
  };
  const rollbackImportRequest = (id) => {
    addToast(
      <Flex flexDirection="column">
        <Box>Are you sure you want to rollback import?</Box>
        <Flex my={2}>
          <PrimaryButton
            onClick={() => {
              rollbackImport(id);
            }}
          >
            Confirm
          </PrimaryButton>
        </Flex>
      </Flex>,
      {
        appearance: "info",
        autoDismiss: false,
      }
    );
  };
  const columns = [
    {
      name: "File",
      selector: (row) => row.filename,
      sortable: true,
      cell: (row) => <Text data-tag="allowRowEvents">{row.filename}</Text>,
    },
    {
      name: "User",
      selector: (row) => row.user.firstName,
      sortable: true,
      cell: (row) => (
        <Text
          data-tag="allowRowEvents"
          variant="muted"
        >{`${row.user.firstName} ${row.user.lastName}`}</Text>
      ),
    },
    {
      name: "Created",
      selector: (row) => row.importedAt,
      width: "120px",
      sortable: true,
      cell: (row) => (
        <Text data-tag="allowRowEvents" variant="muted">
          {row.updatedAt && moment(row.updatedAt).format("DD/MM/YYYY")}
        </Text>
      ),
    },
    {
      name: "Rows",
      selector: (row) => row.rows,
      sortable: true,
      width: "80px",
      cell: (row) => (
        <Text data-tag="allowRowEvents" variant="muted">
          {row.rows}
        </Text>
      ),
    },
    {
      name: "Processed",
      selector: (row) => row.processedRows / row.rows,
      sortable: true,
      width: "80px",
      cell: (row) => {
        return (
          <Text data-tag="allowRowEvents" variant="muted">
            {`${((row.processedRows / row.rows) * 100).toFixed(0)}%`}
          </Text>
        );
      },
    },
    {
      name: "Issues",
      selector: (row) => row.erroredRows,
      sortable: true,
      width: "80px",
    },
    {
      name: "Status",
      selector: (row) => row.setupComplete,
      sortable: true,
      cell: (row) => {
        let status = "Setup";
        if (row.setupComplete) status = "Scheduled";
        if (row.processing) status = "Processing";
        if (row.importedAt) status = "Complete";
        if (row.errorText) status = row.errorText;
        return (
          <Box data-tag="allowRowEvents">
            <Text
              data-tag="allowRowEvents"
              color={!!row.errorText ? "red" : ""}
            >
              {status}
            </Text>
          </Box>
        );
      },
    },
    {
      name: "Imported",
      selector: (row) => row.importedAt,
      width: "120px",
      sortable: true,
      cell: (row) => (
        <Text data-tag="allowRowEvents">
          {row.importedAt && moment(row.importedAt).format("DD/MM/YYYY")}
        </Text>
      ),
    },
    {
      name: "",
      sortable: false,
      width: "150px",
      cell: (row) => {
        return row.processing && !row.errorText ? (
          <PrimaryButton
            data-tag="allowRowEvents"
            onClick={() => {
              cancelImportRequest(row.id);
            }}
          >
            Cancel
          </PrimaryButton>
        ) : row.setupComplete ? (
          <SecondaryButton
            data-tag="allowRowEvents"
            onClick={() => {
              rollbackImportRequest(row.id);
            }}
          >
            Rollback
          </SecondaryButton>
        ) : (
          !row.errorText && (
            <PrimaryButton onClick={() => setCurrentStep(2)}>
              Continue
            </PrimaryButton>
          )
        );
      },
    },
  ];

  return (
    <Settings>
      <Flex width="100%" id="outer-container-trigger">
        <Flex
          flexDirection="column"
          px={4}
          bg="white"
          width="100%"
          id="page-wrap-trigger"
        >
          {currentStep ? (
            <ImportWizard />
          ) : (
            <Flex flexDirection="column">
              <Flex justifyContent="space-between">
                <Text variant="h2">Import</Text>
              </Flex>
              <Flex flexDirection="column" mb={4}>
                <Flex justifyContent="space-between">
                  <Flex flexDirection="column">
                    <Text mt={1}>
                      Userlot has a flexible import and allows you to map your
                      attributes to your data.
                    </Text>
                    <Flex my={2}>
                      <Box color="green">
                        <CheckCircleIcon size={20} />
                      </Box>
                      <Text mx={2}> Upload any model</Text>
                    </Flex>
                    <Flex my={2}>
                      <Box color="green">
                        <CheckCircleIcon size={20} />
                      </Box>
                      <Text mx={2}>Map any fields including custom fields</Text>{" "}
                    </Flex>
                    <Flex my={2}>
                      <Box color="green">
                        <CheckCircleIcon size={20} />
                      </Box>
                      <Text mx={2}>
                        <Flex>
                          Download templates of your models
                          <Link
                            mx={1}
                            onClick={() => downloadTemplate("Company")}
                          >
                            Account
                          </Link>
                          <Text mx={1}>,</Text>
                          <Link
                            mx={1}
                            onClick={() => downloadTemplate("EndUser")}
                          >
                            Account Users
                          </Link>
                          <Text mx={1}>,</Text>
                          <Link
                            mx={1}
                            onClick={() => downloadTemplate("Event")}
                          >
                            Events
                          </Link>
                        </Flex>
                      </Text>
                    </Flex>
                  </Flex>
                  <Box>
                    <PrimaryButton onClick={() => setCurrentStep(1)}>
                      Create New Import
                    </PrimaryButton>
                  </Box>
                </Flex>
              </Flex>
              <Table
                title={""}
                selectableRows={false}
                columns={columns}
                defaultSortField={"createdAt"}
                defaultSortAsc={false}
                data={importsQuery?.data?.imports}
                loading={importsQuery?.loading}
                expandableRowsComponent={ExpandableComponent}
                noDataComponent={
                  <Flex p={2} my={3} flexDirection="column">
                    <Text variant="muted">
                      No data has been connected to your account or manually
                      imported yet.
                    </Text>
                    <Text variant="muted">
                      Start here by manually importing your data or set up
                      application keys and events.
                    </Text>
                    <Link href="#" my={2}>
                      <Flex alignItems="center">
                        <HelpIcon /> <Text mx={2}>Need more help?</Text>
                      </Flex>
                    </Link>
                  </Flex>
                }
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(Import);

const messageColumns = [
  {
    name: "",
    width: "50px",
    selector: (row) => row.messageType,
    sortable: true,
    cell: (row) => {
      return row.messageType === "error" ? (
        <Text color="red" data-tag="allowRowEvents">
          <WarningIcon />
        </Text>
      ) : (
        <Text color="brandLight" data-tag="allowRowEvents">
          <InfoIcon />
        </Text>
      );
    },
  },
  {
    name: "Message",
    selector: (row) => row.message,
    sortable: true,
    cell: (row) => (
      <Box>
        <Text>{row.message}</Text>
      </Box>
    ),
  },
  {
    name: "Row",
    width: "80px",
    selector: (row) => row.rowNo,
    sortable: true,
  },
  {
    name: "Data",
    selector: (row) => row.data,
    cell: (row) => (
      <Box>
        {row.data && <ReactJson name={false} src={row.data} collapsed />}
      </Box>
    ),
  },
];
const ExpandableComponent = ({ data }) => (
  <Box pl={4}>
    {data && data.importMessages.length > 0 ? (
      <Table
        title=""
        selectableRows={false}
        columns={messageColumns}
        data={data && data.importMessages}
        loading={false}
      />
    ) : (
      <Flex fleex={1} p={3} justifyContent="center">
        <Text variant="muted">No issues reported on import</Text>
      </Flex>
    )}
  </Box>
);
