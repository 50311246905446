import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import RouterLink from "./RouterLink";

const Topbar = (props) => {
  return (
    <Flex sx={{ height: "35px" }} my={2}>
      <Text variant="h1" mr={4}>
        {props.title}
      </Text>
      <Flex alignItems="center" flex={1}>
        {props.menuItems?.map((item, index) => {
          return (
            <RouterLink key={index} to={item.link} disabled={item.disabled}>
              <Flex
                alignItems="center"
                data-for="enrich-topbar"
                data-tip={item.tooltip || item.name}
                justifyContent="center"
              >
                <item.icon size={16}></item.icon>
                <Text fontSize="sm" mx={2}>
                  {item.name}
                </Text>
              </Flex>
            </RouterLink>
          );
        })}
      </Flex>
    </Flex>
  );
};

Topbar.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
};

export default Topbar;
