import React from "react";
import Loader from "Components/Common/Loader";
import PropTypes from "prop-types";
import { getEventGroups } from "hooks/getEventGroups";
import moment from "moment";
import { useTheme } from "emotion-theming";
import { Box, Flex, Text } from "rebass";
import CalendarHeatmap from "react-d3-calendar-heatmap";
import "react-d3-calendar-heatmap/dist/react.d3.calendar.heatmap.css";

import * as chromatic from "d3-scale-chromatic";
import { scaleSequential } from "d3-scale";
import { getEventsByEntitySummary } from "hooks/getEventsByEntitySummary";

const _ = require("lodash");

function EventSummary(props) {
  const theme = useTheme();
  const { loading, data } = getEventGroups();
  const eventSummary = getEventsByEntitySummary(props.id, props.parentType);

  let kpiAggregate = eventSummary.data?.eventsByEntitySummary?.map((d) => {
    return {
      day: moment(d.label).format("YYYY-MM-DD"),
      value: d.count,
      projects: [],
    };
  });

  const timeRange = {
    from: moment().add(-6, "months").toDate(),
    to: moment().toDate(),
  };

  return kpiAggregate ? (
    <Box my={2}>
      <Flex flexDirection={"row"}>
        <Text variant="label" textAlign="left">
          Product Usage
        </Text>
      </Flex>
      {props.lastSeenAt && (
        <Flex flexDirection={"row"}>
          <Text mr={2} variant={"muted"}>
            Last seen:
          </Text>
          <Text variant={"muted"}>
            {props.lastSeenAt &&
              moment(props?.lastSeenAt).format(`DD MMM YYYY`)}
          </Text>
        </Flex>
      )}
      <Box pb={2} />
      {loading ? (
        <Loader />
      ) : (
        <CalendarHeatmap
          data={kpiAggregate}
          color={theme.colors.brandLight}
          weekday="weekend"
          disableTooltip
          customD3ColorScale={scaleSequential(chromatic.interpolateSpectral)}
          timeRange={timeRange}
          width={250}
          cellSize={7}
          defaultColor={theme.colors.gray[200]}
          formatDay={(dayOfWeek) => {
            const arrayDays = ["", "", "", "", "", "", ""];
            return arrayDays[dayOfWeek];
          }}
          formatDate={(date) =>
            new Intl.DateTimeFormat("en", {
              weekday: "long",
              day: "numeric",
              year: "numeric",
              month: "short",
            }).format(date)
          }
        />
      )}
    </Box>
  ) : null;
}
EventSummary.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
};
export default EventSummary;
