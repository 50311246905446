import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";

function StepFour(props) {
  return (
    <Formik
      enableReinitialize
      initialValues={props.data || { subdomain: "" }}
      validationSchema={Yup.object().shape({
        subdomain: Yup.string()
          .required("Required")
          .matches(
            /^[aA-zZ0-9-]+$/,
            "Only alphanumeric are allowed for this field "
          ),
      })}
      autoComplete={false}
      onSubmit={(values, actions) => {
        props.onUpdate(values);
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;

        return (
          <Flex
            flexDirection="column"
            justifyContent="center"
            noValidate
            width="100%"
            sx={{
              minWidth: 350,
            }}
            py={4}
          >
            <Text variant="h2" my={4} color="brandLight">
              Just a few more details
            </Text>
            <Flex alignItems="center" mb={3}>
              <Text mb={2} mr={1}>
                https://
              </Text>
              <CustomFormGroup
                noMargin
                placeholder="subdomain"
                label=""
                name="subdomain"
                value={values.subdomain}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
                width="150px"
                onBlur={handleBlur}
                helperText={errors.subdomain && errors.subdomain}
              />
              <Text mb={2}>.userlot.app</Text>
            </Flex>

            <CustomFormGroup
              label="I agree to terms "
              name="terms"
              type="checkbox"
              value={values.terms}
              onChange={setFieldValue}
              onBlur={handleBlur}
            />
            <Flex mt={2} justifyContent="space-between">
              <SecondaryButton onClick={props.previousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton onClick={handleSubmit}>Register</PrimaryButton>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default StepFour;
