import React from "react";
import { useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Flex, Button, Text, Box, Link, Image } from "rebass";
import Loader from "Components/Common/Loader";
import { useQuery } from "@apollo/react-hooks";
import {
  CalendarIcon,
  MailIcon,
  NotificationIcon,
  PlusIcon,
  SlackIcon,
} from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";
import { Input } from "@rebass/forms";
import {
  AutomationIcon,
  CheckCircleIcon,
  CheckCircleOffIcon,
  CloseIcon,
  EditIcon,
  SearchIcon,
  TickIcon,
} from "../../../Common/Icons";
import WorkflowQuery from "../../../../graphql/queries/Workflows.gql";
import TagsQuery from "../../../../graphql/queries/Tags.gql";
import MenuMultiSelect from "../../../Common/Forms/MenuMultiSelect";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import RadioGroup from "../../../Common/RadioGroup";
import {
  RiArrowDropDownFill,
  RiArrowDropUpFill,
  RiCheckboxBlankCircleLine,
  RiRadioButtonFill,
} from "react-icons/ri";
import { getCurrentUser } from "hooks/getCurrentUser";
import Tags from "../../../Common/Tags";

function PlaybookList(props) {
  const history = useHistory();
  const { currentUser } = getCurrentUser();
  const [searchText, setSearchText] = useState(undefined);
  const [selectedTags, setSelectedTags] = useState([]);
  const [enabledState, setEnabledState] = useState("all");
  const [sortDirection, setSortDirection] = useState(1);

  const { data: tags } = useQuery(TagsQuery, {
    variables: {
      modelType: "categories",
    },
    fetchPolicy: "network-only",
  });

  const { loading, data, error, refetch } = useQuery(WorkflowQuery, {
    variables: {
      searchText: searchText,
      tags: selectedTags,
    },
  });

  let workflows = data?.workflows;

  workflows =
    enabledState == "active"
      ? data?.workflows.filter((w) => w.enabled)
      : workflows;

  workflows =
    enabledState == "mine"
      ? data?.workflows.filter((w) => w.createdBy?.id === currentUser?.id)
      : workflows;
  return (
    <>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={1} justifyContent="space-between">
            <Text variant="h2">Playbooks</Text>
            <PrimaryButton
              onClick={() => {
                history.push("/settings/product/playbooks/new");
              }}
            >
              <PlusIcon /> Add Playbook
            </PrimaryButton>
          </Flex>
          <Flex alignItems="center" justifyContent="flex-end" pl={4}>
            <Flex width="50px"></Flex>
            <Flex
              flex={1}
              mx={2}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                let newSort = sortDirection * -1;
                workflows = workflows.sort((a, b) => {
                  if (a.name < b.name) return newSort;
                  if (a.name > b.name) return newSort * -1;
                  return 0;
                });
                setSortDirection(newSort);
              }}
            >
              <Text variant="label">Name</Text>
              {sortDirection < 0 ? (
                <RiArrowDropUpFill />
              ) : (
                <RiArrowDropDownFill />
              )}
            </Flex>
            <Box mx={2} sx={{ minWidth: "400px" }}>
              <RadioGroup
                name={`enabledState`}
                selectedOption={enabledState}
                noFlex
                options={[
                  { value: "all", label: "All" },
                  { value: "active", label: "Active" },
                  {
                    value: "mine",
                    label: "Show only mine",
                  },
                ]}
                onChange={(name, value) => {
                  setEnabledState(value?.value);
                }}
              />
            </Box>
            <MenuMultiSelect
              onChange={(types) => {
                setSelectedTags(types);
                refetch({
                  searchText: searchText,
                  tags: types,
                });
              }}
              options={tags?.tags.map((o) => {
                return { value: o, label: o };
              })}
              value={selectedTags}
            />
            <Box sx={{ position: "relative" }} alignItems="center" m={2}>
              <Input
                bg="white"
                sx={{
                  zIndex: 1,
                }}
                placeholder=""
                value={searchText}
                onChange={(e) => {
                  refetch({
                    searchText: e.target.value,
                    tags: selectedTags,
                  });
                  setSearchText(e.target.value);
                }}
              />
              <Flex
                sx={{
                  position: "absolute",
                  right: 4,
                  top: 0,
                  bottom: 0,
                  zIndex: 2,
                }}
                width={35}
                alignItems="center"
                justifyContent="center"
                color="line"
                p={2}
                onClick={() => {
                  setSearchText("");
                }}
              >
                <CloseIcon size={20} />
              </Flex>
              <Flex
                sx={{
                  position: "absolute",
                  right: 1,
                  top: 0,
                  bottom: 0,
                  zIndex: 2,
                }}
                width={35}
                alignItems="center"
                justifyContent="center"
                color="brandLight"
                p={2}
              >
                <SearchIcon size={20} />
              </Flex>
            </Box>
          </Flex>
          {loading && <Loader />}
          {data?.workflows.length === 0 && <EmptyState />}
          {data?.workflows.length > 0 && workflows.length === 0 && (
            <Flex m={5} alignItems={"center"} justifyContent="center">
              <Text>No playbooks matching your filters</Text>
            </Flex>
          )}
          {workflows?.map((trigger) => (
            <Flex
              key={trigger.id}
              variant="box"
              sx={{
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "brandHighlight",
                  borderBottomWidth: 0,
                },
                minHeight: "60px",
              }}
              bg={!trigger.enabled && "gray.100"}
              m={2}
              p={2}
              px={4}
              alignItems="center"
              onClick={() =>
                history.push(`/settings/product/playbooks/${trigger.id}`)
              }
            >
              <Flex
                flex={1}
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Flex width="50px" flexDirection="column">
                  {trigger.enabled ? (
                    <Text color="green">
                      <CheckCircleIcon size={20} />
                    </Text>
                  ) : (
                    <CheckCircleOffIcon size={20} />
                  )}
                </Flex>
                <Flex width={6 / 10} mx={2} flexDirection="column">
                  <Text variant="h4">{trigger.name}</Text>
                  <Text my={1} variant="muted">
                    {trigger.description}
                  </Text>
                  <Tags value={trigger.tags} />
                </Flex>
                <Flex flex={1} alignItems="flex-start">
                  <Flex
                    width={2 / 4}
                    flexDirection="column"
                    alignItems={"flex-start"}
                  >
                    <Text variant="label">Created By</Text>
                    <Text>{trigger.createdBy?.name}</Text>
                  </Flex>
                  <Flex
                    width={1 / 4}
                    flexDirection="column"
                    alignItems={"flex-start"}
                  >
                    <Text textAlign="center" variant="label">
                      Total Days
                    </Text>
                    <Text textAlign="center">{trigger.totalDays || 0}</Text>
                  </Flex>
                  <Flex
                    width={1 / 4}
                    flexDirection="column"
                    alignItems={"flex-start"}
                  >
                    <Text textAlign="center" variant="label">
                      Total Steps
                    </Text>
                    <Text textAlign="center">{trigger.totalSteps || 0}</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
}

export default withRouter(PlaybookList);

const EmptyState = (props) => {
  const history = useHistory();
  return (
    <Flex
      p={5}
      flex={1}
      justifyContent="center"
      bg="gray.100"
      sx={{
        borderColor: "accent",
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10,
        minHeight: 400,
      }}
    >
      <Flex m={4} flex={1} justifyContent="center" flexDirection="column">
        <Text my={3} fontWeight="bold">
          Your playbooks are action centric and customer focused workflows. The
          major steps of the workflow are defined and the specific activities in
          those areas are outlined.
        </Text>
        <Text my={3}>
          Playbooks help the team visualize targets, understand the continuous
          improvement, and know what is needed to help your customers achieve
          goals and be successful.
        </Text>
        <Text my={3}>To set up your playbooks you will need to:</Text>
        <Box mx={1}>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Check your custom fields have been defined
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Add to your email template library
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Validate your email domains
          </Flex>
          <Flex my={2}>
            <Text color="green" mx={2}>
              <TickIcon />
            </Text>
            Add your users to your account
          </Flex>
        </Box>
        <Flex my={3}>
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/playbooks/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Playbook
          </PrimaryButton>
          <Box mx={3} />
          <SecondaryButton
            onClick={() => {
              history.push("/settings/product/emailtemplates/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Email Templates
          </SecondaryButton>
        </Flex>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="flex-start">
        <Image
          src={require("assets/images/Support.png")}
          sx={{ marginTop: "-10%", height: "150px", objectFit: "contain" }}
        />
      </Flex>
    </Flex>
  );
};
