import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Flex, Box, Image, Text } from "rebass";
import { Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import Loader from "Components/Common/Loader";
import { MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { ErrorParams } from "Components/Common/Notifications/Error";
import Dropzone from "react-dropzone";
import { useTheme } from "emotion-theming";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import FileUploadTemplate from "../FileUploadTemplate";
import { useDynamicGql } from "../../../graphql/DynamicGql";
import GhostButton from "../../Common/Buttons/GhostButton";

function CompanyLogo(props) {
  const theme = useTheme();
  const { id } = props;
  const [file, setFile] = useState(undefined);
  const { addToast } = useToasts();

  const { loading, data } = getData(MODEL_TYPES.Company, id);

  const { mutation } = useDynamicGql(MODEL_TYPES.Company, false);

  const [save] = useMutation(mutation);

  const company = data?.company;

  useEffect(() => {
    if (file && company) {
      saveLogo(company, file);
    }
  }, [file]);

  const saveLogo = (values, file2) => {
    setFile(undefined);
    save({
      variables: {
        id: values.id,
        fields: values.fields,
        startDate: values.details?.startDate,
        renewalDate: values.details?.renewalDate,
        arr: values.details?.arr?.toString(),
        mrr: values.details?.mrr?.toString(),
        name: values.name,
        ownerUserId: values.details?.ownerUserId,
        lifeCycleStageId: values.details?.lifeCycleStageId,
        conversionAdoptionScore:
          values.details?.conversionAdoptionScore?.toString(),
        conversionFitScore: values.details?.conversionFitScore?.toString(),
        file: file2,
      },
      context: {
        hasUpload: !!file2,
      },
    }).then(
      (response) => {
        let result = response.data.company;
        if (result?.errors) {
          addToast(<Flex>{result?.errors[0]}</Flex>, ErrorParams);
        } else {
          addToast(<Flex>Saved!</Flex>, SuccessParams);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Formik enableReinitialize initialValues={company} autoComplete={false}>
        {(formProps) => {
          const { values } = formProps;
          return (
            <Box width="100%">
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setFile(acceptedFiles[0]);
                }}
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderStyle: "dashed",
                      borderColor: theme.colors.accent,
                      borderWidth: 1,
                      borderRadius: "medium",
                    }}
                    p={3}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {values.logoUrl ? (
                      <Flex alignItems="center" justifyContent="center">
                        <Image src={values?.logoUrl} width={60} height={60} />
                        <Box my={1} />
                        <GhostButton>Change</GhostButton>
                      </Flex>
                    ) : (
                      <FileUploadTemplate logo />
                    )}
                  </Flex>
                )}
              </Dropzone>
            </Box>
          );
        }}
      </Formik>
    </>
  );
}

export default CompanyLogo;
