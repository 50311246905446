import React from "react";
import ChurnReasonForm from "./ChurnReasonForm";
import { Flex, Image } from "rebass";
import Settings from "..";

function ChurnReasons(props) {
  return (
    <Settings>
      <Flex width="100%">
        <Flex flex={1} p={4} bg="white">
          <ChurnReasonForm />
        </Flex>
      </Flex>
    </Settings>
  );
}

export default ChurnReasons;
