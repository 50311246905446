import React, { useEffect } from "react";
import { Flex, Box, Text } from "rebass";
import PropTypes from "prop-types";
import { CloseIcon } from "../Icons";

const transforms = {
  top: "translateY(-100%)",
  right: "translateX(100%)",
  bottom: "translateY(100%)",
  left: "translateX(-100%)",
};

const ModalWrapper = (props) => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "block",
        height: "100%",
        top: 60,
        right: 0,
        bottom: 0,
        left: 60,
        transform: !props.open ? transforms[props.position] : null,
      }}
    >
      {props.children}
    </Box>
  );
};

const ModalOverlay = (props) => {
  return (
    <Box
      sx={{
        display: props.open ? null : "none",
        boxSizing: "border-box",
        position: "fixed",
        backgroundColor: "gray.100",
        zIndex: 8000,
        top: props.addMargin ? "60px" : 0,
        right: 0,
        bottom: 0,
        left: props.addMargin ? "60px" : 0,
      }}
    >
      <Flex
        fontSize={25}
        justifyContent="space-between"
        alignItems="center"
        color="gray.700"
        px={4}
        py={2}
      >
        <Text variant="h1" color="gray.700">
          {props.title}
        </Text>
        <Box sx={{ cursor: "pointer" }} onClick={props.onDismiss}>
          <CloseIcon size={25} />
        </Box>
      </Flex>
    </Box>
  );
};

const ModalContent = (props) => {
  return (
    <Box
      sx={{
        display: "block",
        boxSizing: "border-box",
        position: "fixed",
        borderRadius: "medium",
        left: "50%",
        top: props.addMargin ? "100px" : "50px",
        transform: "translate(-50%, 0%)",
        zIndex: 16000,
        width: !props.open ? "0px" : "1200px",
        minWidth: !props.open ? "0px" : "1000px",
        transition: "transform 0.4s ease-in",
        overflowX: "hidden",
        overflowY: "auto",
        color: "body",
        fontFamily: "body",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      {props.children}
    </Box>
  );
};

function Modal(props) {
  useEffect(() => {
    if (props.open) {
      window.scrollTo(0, 0);
    }
  }, [props.open]);

  return props.open ? (
    <ModalWrapper {...props}>
      <ModalOverlay {...props} />
      <ModalContent {...props}>{props.children}</ModalContent>
    </ModalWrapper>
  ) : null;
}

Modal.propTypes = {
  onDismiss: PropTypes.func,
  open: PropTypes.bool,
  position: PropTypes.oneOf(["top", "left", "right", "bottom"]),
};

export default Modal;
