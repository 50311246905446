import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Flex, Text } from "rebass";
import { Menu, Item, animation, MenuProvider } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import "./menu.css";
import Modal from "Components/Common/Modal";
import TaskForm from "Components/CommandCenter/Tasks/TaskForm";
import CompanyForm from "Components/Data/Company/CompanyForm";
import {
  CompanyIcon,
  PlusIcon,
  TasksIcon,
  UserIcon,
} from "Components/Common/Icons";
import EndUserForm from "Components/Data/EndUser/EndUserForm";

const FORM = {
  ACCOUNT: "account",
  TASK: "task",
  USER: "user",
};
function AddMenu(props) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState("");

  return (
    <Flex justifyContent="left" ml={3} sx={{ zIndex: 3 }}>
      <MenuProvider id="menu_id" event="onClick">
        <Flex
          alignItems="center"
          color="white"
          bg="brandLight"
          variant="avatar"
          sx={{ width: 40, height: 40, ":hover": { bg: "brand" } }}
          justifyContent="center"
          className="add_menu"
        >
          <PlusIcon size={30} />
        </Flex>
      </MenuProvider>
      <Menu id="menu_id" animation={animation.zoom}>
        <Item
          onClick={() => {
            setShowForm(FORM.ACCOUNT);
            setOpen(true);
          }}
        >
          <CompanyIcon />
          <Text mx={2}>Account</Text>
        </Item>
        <Item
          onClick={() => {
            setShowForm(FORM.USER);
            setOpen(true);
          }}
        >
          <UserIcon />
          <Text mx={2}>User</Text>
        </Item>
        <Item
          onClick={() => {
            setShowForm(FORM.TASK);
            setOpen(true);
          }}
        >
          <TasksIcon />
          <Text mx={2}>Activity</Text>
        </Item>
      </Menu>
      <Modal
        title={`Create ${showForm || ""}`}
        open={open}
        width={800}
        addMargin
        onDismiss={() => {
          setOpen(false);
          setShowForm(undefined);
        }}
      >
        <Flex flexDirection="column" ml={2} pb={3}>
          {showForm == FORM.USER && (
            <EndUserForm
              id={undefined}
              modalAdd
              onSave={() => {
                setOpen(false);
                setShowForm(undefined);
              }}
            />
          )}
          {showForm == FORM.TASK && (
            <TaskForm
              id={undefined}
              open={true}
              onSave={() => {
                setOpen(false);
                setShowForm(undefined);
                history.push(`/tasks/`);
              }}
            />
          )}
          {showForm == FORM.ACCOUNT && (
            <CompanyForm
              id={undefined}
              modalAdd
              onSave={() => {
                setOpen(false);
                setShowForm(undefined);
              }}
            />
          )}
        </Flex>
      </Modal>
    </Flex>
  );
}

export default withRouter(AddMenu);
