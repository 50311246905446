import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import SearchFilter from "../graphql/queries/SearchFilter";
import SearchFilterMutation from "../graphql/mutations/SearchFilter";

export function searchFilters() {
  const [saveFilter] = useMutation(SearchFilterMutation);

  const dataQuery = useQuery(SearchFilter, { fetchPolicy: "network-only" });
  const filters = dataQuery.data?.filters;

  const updateFilter = (name, id, label) => {
    const newFilters = filters;

    let ref = newFilters;
    let nestedNames = name.split(".").reverse();
    while (nestedNames.length > 1) {
      const n = _.last(nestedNames);
      ref[n] = {};
      ref = ref[n];
      nestedNames = nestedNames.slice(0, -1);
    }
    const n = _.last(nestedNames);
    ref[n] = id;

    if (!newFilters.labels) {
      newFilters.labels = {};
    }
    newFilters.labels[name] = label;
    saveFilter({
      variables: {
        filters: newFilters,
      },
    }).then(
      (response) => {
        dataQuery.refetch();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const setFilters = (name, id, label) => {
    updateFilter(name, id, label);
  };

  return [{ ...dataQuery }, setFilters];
}
