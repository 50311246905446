import React from "react";
import WidgetWrapper from "./WidgetWrapper";
import { Box, Flex, Text } from "rebass";
import { useTheme } from "emotion-theming";
import moment from "moment";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import numeral from "numeral";

function LostAccounts({ data, mrrData, loading }) {
  const theme = useTheme();

  const churnedData = mrrData
    ?.filter((d) => d.type === "churned")
    .map((d) => {
      return {
        date: moment(d.date, "YYYY-MM-DD"),
        count: d["count"],
      };
    });
  return (
    <WidgetWrapper title="Contractions">
      <Flex
        p={2}
        flex={1}
        flexGrow={1}
        alignItems="center"
        flexDirection="column"
      >
        <Flex
          flex={1}
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Flex
            flex={1}
            mx={3}
            flexDirection="column"
            sx={{
              borderRightColor: "gray.200",
              borderRightStyle: "solid",
              borderRightWidth: 1,
            }}
            px={3}
          >
            <Flex flexDirection="column">
              <Text variant="muted">Churned</Text>
              <Flex justifyContent="space-around">
                <Text variant="h1" textAlign="center" color="brand">
                  ${numeral(data?.churned?.mrr).format("0.00a") || 0}
                </Text>
                <Text variant="h1" textAlign="center" color="brand">
                  {data?.churned?.total || 0}
                </Text>
              </Flex>
            </Flex>
            <Flex>
              <Sparklines data={churnedData.map((d) => d.count)} height={40}>
                <SparklinesLine color={theme.colors.red} />
                <SparklinesSpots style={{ fill: theme.colors.red }} />
              </Sparklines>
            </Flex>
          </Flex>
          <Flex mx={3} flexDirection="column">
            <Text variant="muted">Lost</Text>
            <Flex justifyContent="space-around">
              <Text variant="h1" textAlign="center" color="brand">
                {data?.lost?.total || 0}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </WidgetWrapper>
  );
}

export default LostAccounts;
