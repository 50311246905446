import React, { useEffect } from "react";
import { Flex, Box, Link, Text } from "rebass";
import Select from "Components/Common/Forms/Select";
import { useQuery } from "@apollo/react-hooks";
import { getCurrentUser } from "hooks/getCurrentUser";
import { searchFilters } from "hooks/searchFilters";
import { getUsers } from "hooks/getUsers";
import _ from "lodash";
import Avatar from "react-avatar";
import PropTypes from "prop-types";
import TeamsQuery from "../../graphql/queries/Teams.gql";

function TeamSelect({ onChange, value }) {
  const teamsQuery = useQuery(TeamsQuery);

  let accountOptions = [];

  teamsQuery?.data?.teams?.forEach((team) => {
    accountOptions.push({
      value: team.id,
      name: undefined,
      userId: undefined,
      teamId: team.id,
      teamName: team.name,
      label: (
        <Flex alignItems="center">
          <Avatar size={25} round name={team.name} src={team.logoUrl} />
          <Text mx={2} fontWeight="bold" color={"brand"}>
            {team.name}
          </Text>
        </Flex>
      ),
    });
  });

  return (
    <Select
      width="270px"
      placeholder="Accounts"
      label=""
      name="accounts"
      value={accountOptions?.find((obj) => obj.value === value)}
      onChange={(name, v) => {
        value !== v?.teamId && onChange("teamId", v?.teamId);
      }}
      options={accountOptions}
    />
  );
}
TeamSelect.propTypes = {
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};
export default TeamSelect;
