import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "assets/styles/react-tabs.css";
import Page from "../Page";
import { useDynamicSchemas } from "hooks/DynamicSchema";
import Companies from "./Company/Companies";
import EndUsers from "./EndUser/EndUsers";
import { Flex } from "rebass";
import { MODEL_TYPES } from "consts";
import ModelHeader from "Components/Common/ModelHeader";
import Filter from "../Filter";
import SegmentSummary from "./SegmentSummary";
import { SegmentIcon } from "Components/Common/Icons";

const DataModelList = (props) => {
  switch (props.typeName) {
    case "Company":
      return <Companies />;
    case "EndUser":
      return <EndUsers />;
    default:
      return (
        <Flex width="100%" justifyContent="center" alignItems="center" p={3}>
          Detailed screen not configured.
        </Flex>
      );
  }
};
function CustomEntity(props) {
  const { addToast } = useToasts();
  const [triggerRefresh, setTriggerRefresh] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  let dataModels = useDynamicSchemas();
  dataModels = dataModels?.filter(
    (m) =>
      m.typeName !== MODEL_TYPES.Address.name &&
      m.typeName !== MODEL_TYPES.Comment.name &&
      m.typeName !== MODEL_TYPES.Product.name &&
      m.typeName !== MODEL_TYPES.Event.name
  );
  return (
    <Page title="Accounts" {...props}>
      <Filter onChange={() => setTriggerRefresh(new Date())} />
      <Flex
        flex={1}
        w="100%"
        pl={2}
        py={2}
        style={{
          color: "text",
        }}
      >
        <Tabs selectedIndex={selectedTab} onSelect={setSelectedTab}>
          <TabList>
            <Tab>
              <SegmentIcon /> Segments
            </Tab>
            {dataModels?.map((schema, i) => (
              <Tab key={schema.id}>
                <ModelHeader
                  name={schema?.displayName}
                  type={schema?.typeName}
                />
              </Tab>
            ))}
          </TabList>
          <TabPanel>
            <Flex bg="white" p={3}>
              <SegmentSummary />
            </Flex>
          </TabPanel>
          {dataModels?.map((schema) => (
            <TabPanel key={schema.id}>
              <Flex bg="white" p={3}>
                <DataModelList typeName={schema.typeName} />
              </Flex>
            </TabPanel>
          ))}
        </Tabs>
      </Flex>
    </Page>
  );
}

export default withRouter(CustomEntity);
