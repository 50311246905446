import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text } from "rebass";
import PropTypes from "prop-types";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import CheckboxGroupSwitch from "Components/Common/CheckboxGroupSwitch";
import { ArrowDropDownIcon, FilterOnIcon } from "Components/Common/Icons";

const Icon = (props) => {
  return props.icon ? (
    <props.icon size={12} onClick={props.onClick} />
  ) : (
    <FilterOnIcon size={12} onClick={props.onClick} />
  );
};
Icon.propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};
function MenuMultiSelect(props) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);
  const [activityTypes, setActivityType] = useState(props.value);

  return (
    <Flex sx={{ position: "relative" }}>
      <SecondaryButton
        leftIcon={
          props.options?.length === 0 ? (
            <Text color="gray.400">
              <Icon
                icon={props.icon}
                onClick={() => {
                  setActivityType(props.options.map((i) => i.value));
                }}
              />
            </Text>
          ) : (
            <Text color="brandLight">
              <Icon icon={props.icon} onClick={() => setActivityType([])} />
            </Text>
          )
        }
        rightIcon={<ArrowDropDownIcon color="brandLight" />}
        dropdownToggle
        onClick={() => setShowMenu(!showMenu)}
      >
        Filter
      </SecondaryButton>
      {showMenu && (
        <Flex
          p={3}
          bg="white"
          sx={{
            borderColor: "gray.100",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: 99,
            position: "absolute",
            top: 40,
            width: 200,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[500]}`,
          }}
          flexDirection="column"
        >
          {props.options && props.options.length > 0 ? (
            <CheckboxGroupSwitch
              name="activityTypes"
              selectedOptions={activityTypes}
              options={props.options || []}
              onChange={(name, value) => {
                let types = _.cloneDeep(props.value);
                const idx = types.indexOf(value);
                if (idx < 0) {
                  types.push(value);
                } else {
                  types.splice(idx, 1);
                }
                setActivityType(types);
                props.onChange(types);
              }}
            />
          ) : (
            <Text variant="muted" py={2}>
              No options available
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
}
MenuMultiSelect.propTypes = {
  options: PropTypes.array,
  icon: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};
export default MenuMultiSelect;
