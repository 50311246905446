import React, { useState } from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import { curveMonotoneX } from "d3-shape";

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  DiscreteColorLegend,
  Highlight,
} from "react-vis";

import moment from "moment";
import { useTheme } from "emotion-theming";
import { Box, Flex, Image, Text } from "rebass";
import numeral from "numeral";

const _ = require("lodash");

export default function LineGraph(props) {
  const theme = useTheme();
  const defaultLastDrawLocation =
    props.yMin && props.yMax
      ? {
          lastDrawLocation: {
            bottom: props.yMin,
            top: props.yMax,
            left: 0,
            right: props.days,
          },
        }
      : {};

  const [filter, setFilter] = useState(defaultLastDrawLocation);
  const { lastDrawLocation } = filter;
  const days = [...Array(props.days).keys()];
  const graphDataTemplate = days.reverse().map((i) => {
    return {
      date: moment().add(-i, "day"),
      x: days.length - i,
      y: 0,
    };
  });

  const series = props.series.filter(
    (s) => _.maxBy(s.data, "count")?.count > 0
  );

  const tickValues = graphDataTemplate
    .map((i) => {
      let date = i.date;
      if (props.days < 31) {
        return date.date() == 1 || (date.date() % 5 === 0 && date.date() < 30)
          ? i.x
          : undefined;
      } else if (props.days < 61) {
        return date.date() == 1 || date.date() % 7 === 0 ? i.x : undefined;
      } else {
        return date.date() == 1 || date.date() === 10 || date.date() === 20
          ? i.x
          : undefined;
      }
    })
    .filter((i) => !!i);

  return series.length > 0 ? (
    <Flex flex={1}>
      <FlexibleWidthXYPlot
        margin={{ left: 40, right: 40, top: 10, bottom: 40 }}
        height={props.height || 250}
        xDomain={
          lastDrawLocation && [lastDrawLocation.left, lastDrawLocation.right]
        }
        yDomain={
          lastDrawLocation && [lastDrawLocation.bottom, lastDrawLocation.top]
        }
      >
        <XAxis
          tickLabelAngle={-45}
          tickSize={0}
          position="end"
          orientation="bottom"
          tickValues={tickValues}
          tickFormat={(v, i) => {
            let date = moment().add((props.days - v) * -1, "days");
            return date.format("DD MMM");
          }}
        />
        <HorizontalGridLines />
        <YAxis
          orientation="left"
          title={props.ytitle || "Value"}
          tickFormat={(tick) => numeral(tick).format("0a")}
        />
        <VerticalGridLines />
        {series?.map((ev, i) => {
          let graphData = _.cloneDeep(graphDataTemplate);
          graphData.forEach((item) => {
            const c =
              ev &&
              ev.data &&
              ev.data.find((c) =>
                moment(c.date).isSame(moment(item.date), "day")
              );

            item.y = c?.count || 0;
          });

          return (
            <LineSeries
              key={ev.name}
              className="first-series"
              animation={{ damping: 9, stiffness: 300 }}
              color={theme.colors.graph[i]}
              strokeWidth={2}
              curve={curveMonotoneX}
              data={graphData}
            />
          );
        })}
        <Highlight
          drag
          onBrushEnd={(area) => setFilter({ lastDrawLocation: area })}
          onDrag={(area) => {
            setFilter({
              lastDrawLocation: {
                bottom: lastDrawLocation.bottom + (area.top - area.bottom),
                left: lastDrawLocation.left - (area.right - area.left),
                right: lastDrawLocation.right - (area.right - area.left),
                top: lastDrawLocation.top + (area.top - area.bottom),
              },
            });
          }}
        />
        {props.yRescale && (
          <YAxis
            orientation="right"
            title="Score"
            right={0}
            tickFormat={(tick) => props.yRescale && props.yRescale(tick)}
          />
        )}
      </FlexibleWidthXYPlot>
      {!props.noLegend && (
        <Box sx={{ minWidth: "150px" }}>
          {series.map((d, i) => {
            return i < 8 ? (
              <Flex key={i} alignItems="center" my={1}>
                <Flex
                  m={1}
                  sx={{ width: 5, height: 5, borderRadius: 3 }}
                  bg={theme.colors.graph[i]}
                />
                <Text mx={2} variant="muted" fontSize="xs">
                  {d.name}
                </Text>
              </Flex>
            ) : null;
          })}
        </Box>
      )}
    </Flex>
  ) : (
    <Flex p={3}>
      <Image
        src={require("assets/images/GrowthEmpty.png")}
        sx={{ width: "80px", objectFit: "contain" }}
      />
    </Flex>
  );
}
