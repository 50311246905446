import React, { useEffect } from "react";
import { Flex, Box, Link, Text } from "rebass";
import Select from "Components/Common/Forms/Select";
import { useQuery } from "@apollo/react-hooks";
import { getCurrentUser } from "hooks/getCurrentUser";
import { searchFilters } from "hooks/searchFilters";
import { getUsers } from "hooks/getUsers";
import _ from "lodash";
import Avatar from "react-avatar";
import PropTypes from "prop-types";
import TeamsQuery from "../../graphql/queries/Teams.gql";

function UserTeamSelect({ onChange, teamsOnly }) {
  const currentUser = getCurrentUser();
  const [filterQuery, setFilter] = searchFilters();
  const filters = filterQuery.data?.filters;
  const teamsQuery = useQuery(TeamsQuery);

  const product = currentUser?.currentProduct;

  useEffect(() => {
    //onChange();
  }, [filters]);

  let accountOptions = teamsOnly
    ? []
    : [
        {
          value: undefined,
          label: (
            <Flex alignItems={"center"}>
              <Avatar
                size={25}
                round
                name={product?.name}
                src={product?.logoUrl}
              />
              <Text mx={2}>All Accounts</Text>
            </Flex>
          ),
        },
      ];

  !teamsOnly &&
    teamsQuery?.data?.teams?.forEach((team) => {
      team.memberships.map((r) => {
        if (r.user?.id == currentUser?.currentUser?.id) {
          !accountOptions.find((o) => o.name == "My Accounts") &&
            accountOptions.push({
              value: `${team.id}${r.user?.id}`,
              name: "My Accounts",
              label: (
                <Flex
                  alignItems="center"
                  sx={{
                    borderBottomColor: "gray.300",
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderTopColor: "gray.300",
                    borderTopWidth: 1,
                    borderTopStyle: "solid",
                  }}
                  py={2}
                >
                  <Avatar
                    size={25}
                    round
                    name={r?.user.name}
                    email={r?.user?.email}
                    src={r.user?.avatarUrl}
                  />
                  <Text mx={2}>My Accounts</Text>
                </Flex>
              ),
              teamId: team.id,
              teamName: team.name,
              userId: r.user?.id,
            });
        }
      });
    });

  teamsQuery?.data?.teams?.forEach((team) => {
    accountOptions.push({
      value: team.id,
      name: undefined,
      userId: undefined,
      teamId: team.id,
      teamName: team.name,
      label: (
        <Flex alignItems="center">
          <Avatar size={25} round name={team.name} src={team.logoUrl} />
          <Text mx={2} fontWeight="bold" color={"brand"}>
            {team.name}
          </Text>
        </Flex>
      ),
    });
    !teamsOnly &&
      team.memberships.map((r) => {
        accountOptions.push({
          value: `${team.id}${r.user?.id}`,
          name: `${r.user?.firstName || ""} ${r.user?.lastName || ""}`,
          label: (
            <Flex alignItems="center" ml={3}>
              <Avatar
                size={25}
                round
                name={`${r.user?.firstName || ""} ${r.user?.lastName || ""}`}
                email={r.user?.email}
                src={r.user?.avatarUrl}
              />
              <Box mx={2}>
                <Text fontWeight="bold" color={"brand"}>
                  {`${r.user?.firstName || ""} ${r.user?.lastName || ""}`}
                </Text>
                <Text variant="label" fontSize="xxs">
                  {team.name}
                </Text>
              </Box>
            </Flex>
          ),
          teamId: team.id,
          teamName: team.name,
          userId: r.user?.id,
        });
      });
  });

  let selectedValue = teamsOnly
    ? filters?.teamId
    : `${filters?.teamId || ""}${filters?.ownerUserId || ""}`;
  return (
    <Select
      width={270}
      placeholder="Accounts"
      label=""
      name="accounts"
      value={accountOptions?.find((obj) => obj.value === selectedValue)}
      onChange={(name, value) => {
        setFilter("ownerUserId", value?.userId, value?.name);
        setFilter("teamId", value?.teamId, value?.teamName);
        onChange();
      }}
      options={accountOptions}
    />
  );
}
UserTeamSelect.propTypes = {
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};
export default UserTeamSelect;
