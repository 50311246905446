import React from "react";
import { Flex, Text } from "rebass";
import { getTaskCategories } from "hooks/getTaskCategories";

import {
  CalendarIcon,
  MailIcon,
  MoneyIcon,
  PhoneIcon,
  TasksIcon,
  UserIcon,
  WarningIcon,
} from "Components/Common/Icons";
import { CustomIcon, PriorityIcon } from "Components/Common/Icons";
import ReactTooltip from "react-tooltip";

const icon = (type, size) => {
  switch (type) {
    case "task":
      return <TasksIcon size={size || 16} />;
    case "phone":
      return <PhoneIcon size={size || 16} />;
    case "email":
      return <MailIcon size={size || 16} />;
    case "onboarding":
      return <UserIcon size={size || 16} />;
    case "meeting":
      return <CalendarIcon size={size || 16} />;
    case "renewal":
      return <MoneyIcon size={size || 16} />;
    default:
      return <TasksIcon size={size || 16} />;
  }
};
export const TaskIconHelpText = (type) => {
  switch (type) {
    case "task":
      return "Task Activity";
    case "phone":
      return "Phone Activity";
    case "email":
      return "Email Activity";
    case "onboarding":
      return "Onboarding Activity";
    case "meeting":
      return "Meeting Activity";
    case "renewal":
      return "Renewal";
    default:
      return "Task Activity";
  }
};

export const TaskPriorityOptions = [
  {
    value: 5,
    label: (
      <Flex alignItems="center">
        <PriorityIcon mr={2} value={5} />
        Critical
      </Flex>
    ),
  },
  {
    value: 3,
    label: (
      <Flex alignItems="center">
        <PriorityIcon mr={2} value={3} />
        Normal
      </Flex>
    ),
  },
  {
    value: 1,
    label: (
      <Flex alignItems="center">
        <PriorityIcon mr={2} value={2} />
        Low
      </Flex>
    ),
  },
];
function TaskIcon({ type, size, color }) {
  return (
    <Text color={color} mx={1}>
      {icon(type, size)}
    </Text>
  );
}

export const TaskCategories = () => {
  const taskCategories = getTaskCategories();
  const categories = taskCategories?.data?.taskCategories?.map((c) => {
    return {
      value: c.id,
      label: (
        <Flex
          flexWrap={1}
          alignItems="center"
          data-for={c.id}
          data-tip={c.name}
        >
          <CustomIcon componentName={c.iconName} />
          <ReactTooltip
            id={c.id}
            place="top"
            effect="solid"
            getContent={(dataTip) => dataTip}
          />
        </Flex>
      ),
      description: (
        <Flex alignItems="center">
          <Text>
            <CustomIcon componentName={c.iconName} />
          </Text>
          <Text mx={1}>{c.name}</Text>
        </Flex>
      ),
      icon: <CustomIcon componentName={c.iconName} />,
      name: c.name,
      id: c.id,
      active: c.active,
    };
  });

  return categories || [];
};

export default TaskIcon;
