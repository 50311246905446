import React, { useState } from "react";
import { Flex, Button, Box, Text } from "rebass";
import {
  Menu,
  Item,
  animation,
  MenuProvider,
  Separator,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { MoreIcon } from "Components/Common/Icons";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import CompanyMenuModal from "./CompanyMenuModal";
import PropTypes from "prop-types";
import { MODEL_TYPES } from "../../../consts";

function CompanyMenu(props) {
  const [showModal, setShowModal] = useState(undefined);

  const open = showModal && props.selectedRows && props.selectedRows.length > 0;
  const containsParents =
    props.selectedRows.filter((p) => p.isParent).length > 0;

  return (
    <Flex flexGrow={1} alignItems="center" justifyContent="flex-end">
      <MenuProvider id="company_health_menu" event="onClick">
        {props.selectedRows && props.selectedRows > 0 ? (
          <PrimaryButton>
            <MoreIcon />
          </PrimaryButton>
        ) : (
          <SecondaryButton>
            <MoreIcon />
          </SecondaryButton>
        )}
      </MenuProvider>
      <Menu id="company_health_menu" animation={animation.zoom}>
        <Box p={2}>
          <Text variant="muted" mx={2}>
            ASSIGN
          </Text>
          {props.parentType === MODEL_TYPES.Company.name && (
            <Item onClick={() => setShowModal("Owner")}>Owner</Item>
          )}
          {props.parentType === MODEL_TYPES.Company.name && (
            <Item
              onClick={() => {
                !containsParents && setShowModal("Status");
              }}
            >
              <Text color={containsParents && "gray.500"}>Account Status</Text>
            </Item>
          )}
          {props.parentType === MODEL_TYPES.Company.name && (
            <Item
              onClick={() => {
                !containsParents && setShowModal("Plan");
              }}
            >
              <Text color={containsParents && "gray.500"}>Plan</Text>
            </Item>
          )}
          <Item
            onClick={() => {
              !containsParents && setShowModal("Playbook");
            }}
          >
            <Text color={containsParents && "gray.500"}>Playbook</Text>
          </Item>
          <Item
            onClick={() => {
              setShowModal("Tag");
            }}
          >
            Tags
          </Item>

          <Text variant="muted" mx={2} mt={3}>
            NEW
          </Text>
          <Item onClick={() => setShowModal("Comment")}> Note</Item>
          <Item onClick={() => setShowModal("Task")}> Task</Item>
          <Item onClick={() => setShowModal("Activity")}> Activity</Item>
          {props.parentType === MODEL_TYPES.Company.name && (
            <Text variant="muted" mx={2} mt={3}>
              UPDATE
            </Text>
          )}
          {props.parentType === MODEL_TYPES.Company.name && (
            <Item onClick={() => setShowModal("Feedback")}>Sentiment</Item>
          )}
        </Box>
      </Menu>
      <CompanyMenuModal
        moduleType={props.moduleType}
        selectedRows={props.selectedRows.map((d) => {
          return { ...d };
        })}
        parentType={props.parentType}
        open={open}
        formType={showModal}
        onSave={() => {
          setShowModal(undefined);
          props.onChange();
        }}
        onDismiss={() => {
          setShowModal(undefined);
        }}
      />
    </Flex>
  );
}
CompanyMenu.propTypes = {
  moduleType: PropTypes.string,
  selectedRows: PropTypes.array,
  onChange: PropTypes.func,
};
export default CompanyMenu;
