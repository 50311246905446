import React, { useState } from "react";
import Settings from "..";
import getEmailDomains from "hooks/getEmailDomains";
import Loader from "Components/Common/Loader";
import EmailTemplatesList from "./EmailDomainsList";

function EmailDomains(props) {
  const { data, loading, error, refetch } = getEmailDomains();

  if (loading || !data) return <Loader />;

  return (
    <Settings>
      <EmailTemplatesList domains={data.emailDomains} />
    </Settings>
  );
}

export default EmailDomains;
