import React, { useState } from "react";
import { Box, Flex, Image, Text } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";

import * as Yup from "yup";
import { getProduct } from "hooks/getProduct";
import PropTypes from "prop-types";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiMore2Line,
} from "react-icons/ri";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import TagsEditor from "../../../Common/Tags/TagsEditor";
import Label from "../../../Common/Forms/Label";
import RadioGroup from "../../../Common/RadioGroup";
import { Formik } from "formik";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";
import UserFilterForm from "./UserFilterForm";

function BannerForm(props) {
  const { data: currentProduct } = getProduct();
  const { field, setFieldValue, fieldName, handleBlur } = props;

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        to: Yup.array().required("Required"),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        console.log(errors);
        return (
          <Flex>
            <Box width={1 / 2} pr={3}>
              <WithOnValidationChange
                errors={errors}
                values={formProps.values}
                onError={() => props.onError && props.onError(errors)}
                onValid={() => props.onValid && props.onValid()}
              />

              <CustomFormGroup
                placeholder="Title"
                label="Title"
                name={`${defaultFieldName}.title`}
                value={field.config?.title}
                onChange={setFieldValue}
                onBlur={handleBlur}
                helperText={errors.title && errors.title}
              />
              <CustomFormGroup
                placeholder="Description"
                label="Description"
                type="textarea"
                name={`${defaultFieldName}.description`}
                value={field.config?.description}
                onChange={setFieldValue}
                onBlur={handleBlur}
                helperText={errors.description && errors.description}
              />
              <CustomFormGroup
                placeholder="Button Label"
                label="Button Label"
                name={`${defaultFieldName}.buttonLabel`}
                value={field.config?.buttonLabel}
                onChange={setFieldValue}
                onBlur={handleBlur}
                helperText={errors.buttonLabel && errors.buttonLabel}
              />
              <Flex flex={1} flexDirection="column">
                <Box>
                  <UserFilterForm {...props} label="Audience" />
                </Box>
              </Flex>
            </Box>
            <WebPreview
              data={field?.config}
              product={currentProduct?.currentProduct}
            />
          </Flex>
        );
      }}
    </Formik>
  );
}

export default BannerForm;

const WebPreview = ({ data, product }) => {
  return (
    <Flex flexDirection="column" flex={1} variant="box" bg="white">
      <Flex
        sx={{
          borderBottomColor: "gray.200",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
        px={2}
      >
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          alignItems="center"
          justifyContent="center"
          px={2}
          m={1}
        >
          <Text color="gray.600">
            <RiArrowLeftSLine />
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          m={1}
          alignItems="center"
          justifyContent="center"
          px={2}
        >
          <Text color="gray.600">
            <RiArrowRightSLine />
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          m={1}
          p={3}
          flex={4}
        />
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          m={1}
          alignItems="center"
          justifyContent="center"
          px={2}
        >
          <Text color="gray.600">
            <RiMore2Line />
          </Text>
        </Flex>
      </Flex>
      <Flex minHeight={200} mx={3} mt={2}>
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          mx={2}
          p={3}
          flex={2}
        />
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          mx={2}
          p={3}
          flex={1}
        />
        <Flex
          flexDirection="column"
          bg="gray.200"
          sx={{ borderRadius: "md" }}
          mx={2}
          p={3}
          flex={1}
        />
      </Flex>
      <WidgetPreview
        title={data.title}
        description={data.description}
        buttonLabel={data.buttonLabel}
        product={product}
      />
    </Flex>
  );
};
WebPreview.propTypes = {
  data: PropTypes.object,
};

const WidgetPreview = ({ product, title, description, buttonLabel }) => {
  return (
    <Flex
      bg="white"
      sx={{
        borderTopStyle: "solid",
        borderTopColor: product?.brandPrimaryColor,
        borderTopWidth: 4,
      }}
      flexDirection="column"
      flex={1}
    >
      <Flex
        flexGrow={1}
        bg={product?.brandBgColor || "gray.100"}
        alignItems="center"
      >
        <Flex flex={1} flexDirection="column" p={3}>
          <Text p={2} fontWeight="bold">
            {title}
          </Text>
          <Text
            p={2}
            mb={2}
            dangerouslySetInnerHTML={{ __html: description }}
          ></Text>

          <Flex justifyContent={"flex-end"}>
            <SecondaryButton
              sx={{
                borderColor: product?.brandPrimaryColor,
              }}
            >
              <Text color={product?.brandPrimaryColor}>
                {buttonLabel || "Ok"}
              </Text>
            </SecondaryButton>
          </Flex>
        </Flex>
      </Flex>
      <Flex p={1} width="100%">
        <Flex flex={1} alignItems="center" justifyContent={"center"}>
          <Flex alignItems="center" flex={1} justifyContent="flex-end">
            <Text p={2} variant="muted">
              Powered by Userlot
            </Text>
            <Image
              src={require("assets/images/logo.png")}
              sx={{ height: 30 }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
WidgetPreview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
