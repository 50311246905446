import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import CompanyQuery from "../graphql/queries/CompanyConversionDashboard.gql";
import { getCompanyStatus } from "./getCompanyStatuses";

export function getCompanyConversionDashboard(status, filters) {
  const { data: statuses } = getCompanyStatus();
  let statusId = statuses?.companyStatuses.find((s) => s.name === status)?.id;

  const dataQuery = useQuery(CompanyQuery, {
    variables: {
      searchFilters: {
        ...filters?.filters,
        statusId: statusId,
      },
    },
    skip: !statusId && !!status,
  });

  return {
    ...dataQuery,
  };
}
