import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text, Button, Box, Link } from "rebass";
import PropTypes from "prop-types";
import GhostButton from "../Common/Buttons/GhostButton";
import { ArrowDropDownIcon, CustomIcon, PriorityIcon } from "../Common/Icons";
import moment from "moment";
import TaskComments from "Components/CommandCenter/Tasks/TaskComments";
import AssignedTo from "Components/CommandCenter/Tasks/AssignedTo";
import Label from "../Common/Forms/Label";

export const ActivityEmailTitle = ({ activity }) => {
  return (
    <Flex>
      <Text fontWeight={"bold"} mr={1}>
        Email
      </Text>
      logged by
      <Text fontWeight={"bold"} ml={1}>
        {activity.user?.name}
      </Text>
    </Flex>
  );
};
function ActivityEmail({ activity, refetch }) {
  const [open, setOpen] = useState(false);
  return (
    <Flex flexDirection={"column"} bg="gray.100" p={2}>
      <Flex>
        <Flex flex={1} alignItems="center" justifyContent={"space-between"}>
          <Flex alignItems="center">
            <GhostButton onClick={() => setOpen(!open)}>
              <ArrowDropDownIcon />
            </GhostButton>

            <Box mx={3}>
              <Label>From</Label>
              <AssignedTo user={activity.actionable?.fromUser} detailed />
            </Box>
            <Box mx={3}>
              <Label>Subject</Label>
              <Text
                dangerouslySetInnerHTML={{
                  __html: activity.actionable?.subject,
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      {open && (
        <Box m={4}>
          {activity.actionable?.bodyHtml ? (
            <Text
              bg="gray.100"
              dangerouslySetInnerHTML={{
                __html: activity.actionable?.bodyHtml,
              }}
            />
          ) : (
            <Text
              bg="gray.100"
              dangerouslySetInnerHTML={{
                __html: activity.actionable?.bodyText,
              }}
            />
          )}
        </Box>
      )}
    </Flex>
  );
}
ActivityEmail.propTypes = {
  activity: PropTypes.object,
};
export default ActivityEmail;
