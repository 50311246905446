import React, {useEffect, useState} from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import CopyableText from "Components/Common/CopyableText";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { getApplicationKeys } from "hooks/getApplicationKeys";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import CodePreview from "./CodePreview";
import ReactJson from "react-json-view";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import { SaveIcon } from "Components/Common/Icons";
import CollapsibleGroup from "Components/Common/CollapsibleGroup";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import ApiMutation from "../../../../graphql/mutations/Api.gql";
import ApiDeleteMutation from "../../../../graphql/mutations/ApiDelete.gql";
import ApiQuery from "../../../../graphql/queries/Api.gql";
import Label from "../../../Common/Forms/Label";
import {Subdomain} from "../../../../auth-helper";
import { useRemark } from 'react-remark';

function ApplicationKeysForm(props) {
  const { addToast } = useToasts();
  const { id } = useParams();
  const history = useHistory();
  const [selected, setSelected] = useState(undefined);

  const { refetch } = getApplicationKeys();
  const [save, {}] = useMutation(ApiMutation);
  const [destroy, {}] = useMutation(ApiDeleteMutation);
  const { data, loading, error } = useQuery(ApiQuery, {
    variables: { id: id },
    skip: !id || id === "new",
  });
  const apiData = data?.api && {
    ...data?.api,
    productId: data?.api.product?.id,
  };
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={
          apiData || {
            name: "",
            productId: undefined,
          }
        }
        autoComplete={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          save({
            variables: {
              ...values,
              id: values.id ? values.id : null,
            },
          }).then(
            (response) => {
              if (response.data?.api?.errors) {
                addToast(
                  <Flex>{response.data?.api?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                actions.resetForm({
                  values: response.data?.api?.api,
                });
                addToast(<Flex>Saved!</Flex>, SuccessParams);
                refetch();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const {
            values,
            setFieldValue,
            handleBlur,
            handleSubmit,
            dirty,
            errors,
          } = formProps;

          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex flexGrow={1} flexDirection="column">
                <Flex alignItems="center" justifyContent="flex-end">
                  <DeleteButton
                    callback={destroy}
                    onCancel={() => history.goBack()}
                    refetch={refetch}
                    id={id}
                    name="Api Key"
                  />
                  <PrimaryButton leftIcon={<SaveIcon />} onClick={handleSubmit}>
                    Save
                  </PrimaryButton>
                </Flex>
                <CustomFormGroup
                  placeholder="Name"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  helperText={errors && errors.name && errors.name}
                />
                <Box my={2} />
                <Label>Api Key</Label>
                <CopyableText mx={1} mr={3}>
                  {values.publicKey}
                </CopyableText>
                <Box my={3}></Box>
                <Text variant="h3">Documentation</Text>
                <CollapsibleGroup
                  selected={selected}
                  setSelected={(idx) => setSelected(idx)}
                  options={[
                    {
                      title: (
                        <Flex fontWeight="bold">
                          <Text fontWeight="bold">
                            Userlot Javascript Analytics Snippet
                          </Text>
                        </Flex>
                      ),
                      content: <HelpUserlotAnalytics apiKey={values.publicKey} />,
                    },
                    {
                      title: (
                        <Flex fontWeight="bold">
                          <Text>Userlot API</Text>
                        </Flex>
                      ),
                      content: <HelpUserlotApi apiKey={values.publicKey} apiUrl={values.apiUrl} />,
                    },
                    {
                      title: (
                        <Flex fontWeight="bold">
                          <Text fontWeight="bold">Segment Webhook</Text>
                        </Flex>
                      ),
                      content: <HelpSegmentApi apiKey={values.publicKey} />,
                    },
                  ]}
                />
              </Flex>
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default ApplicationKeysForm;

const HelpUserlotAnalytics = (props) => {
  return (
    <Flex m={2} flex={1} flexDirection="column">
      <Text variant="h3">Loading the Javascript Snippet</Text>
      <CodePreview>
        {`<script>
    window.Userlot=window.Userlot||[];
    function userlot(){window.Userlot.push(arguments);}
    (function(a,b,c,d){var cz=a.createElement(b); cz.type=c; cz.async=true; cz.src=d;var s=a.getElementsByTagName(b)[0];s.parentNode.insertBefore(cz, s);})(document, "script",'text/javascript', 'https://scripts.userlot.app/analytics.js');
    userlot(['init', '${Subdomain()}', '${props.apiKey || ""}']);

    // Then before sending any events you should identify the user and company/account
    userlot(["identify", /*<userEmail>*/, /*<userExternalId>*/, /*<companyName>*/, /*<companyExternalId>*/]);
</script>`}
      </CodePreview>
      <Text variant="h3">Track Event</Text>
      <CodePreview>
        {`
    userlot(["trackEvent", "/*<event-name>*/", /*<event-value>*/, data = {}]);
    // For example
    userlot(["trackEvent", "view client", 1, { loadedPage: true }]);

`}
      </CodePreview>
      {/*<Text variant="h4">Event Body</Text>*/}
      {/*<Flex width="100%" my={2} p={2} variant="box" bg="gray.100">*/}
      {/*  <ReactJson*/}
      {/*    name={false}*/}
      {/*    src={{*/}
      {/*      eventName: "My Event",*/}
      {/*      userExternalId: "019mr8mf4r",*/}
      {/*      companyExternalId: "019mr",*/}
      {/*      data: { field1: "Your", field2: "Custom", field3: "Fields" },*/}
      {/*      appKey: props.publicKey,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Flex>*/}
    </Flex>
  );
};

const HelpUserlotApi = (props) => {
  const [reactContent, setMarkdownSource] = useRemark();

  useEffect(() => {
    setMarkdownSource('[![Run in Postman](https://run.pstmn.io/button.svg)](https://god.gw.postman.com/run-collection/17694474-ab3d3651-ac50-4aad-a5a9-271579bb4f30?action=collection%2Ffork&collection-url=entityId%3D17694474-ab3d3651-ac50-4aad-a5a9-271579bb4f30%26entityType%3Dcollection%26workspaceId%3Db0f206c2-20f7-4761-abaf-bf8bf65e8c0a#?env%5Buserlot.app%5D=W3sia2V5Ijoic3ViZG9tYWluIiwidmFsdWUiOiJ7e3lvdXJ0ZW5hbnR9fSIsImVuYWJsZWQiOnRydWUsInNlc3Npb25WYWx1ZSI6Int7eW91cnRlbmFudH19Iiwic2Vzc2lvbkluZGV4IjowfSx7ImtleSI6ImRvbWFpbiIsInZhbHVlIjoidXNlcmxvdC5hcHAiLCJlbmFibGVkIjp0cnVlLCJzZXNzaW9uVmFsdWUiOiJ1c2VybG90LmFwcCIsInNlc3Npb25JbmRleCI6MX0seyJrZXkiOiJhcGlfdXJsIiwidmFsdWUiOiJ7e3NjaGVtZX19Oi8ve3tzdWJkb21haW59fS57e2RvbWFpbn19IiwiZW5hYmxlZCI6dHJ1ZSwic2Vzc2lvblZhbHVlIjoie3tzY2hlbWV9fTovL3t7c3ViZG9tYWlufX0ue3tkb21haW59fSIsInNlc3Npb25JbmRleCI6Mn0seyJrZXkiOiJzY2hlbWUiLCJ2YWx1ZSI6Imh0dHBzIiwiZW5hYmxlZCI6dHJ1ZSwic2Vzc2lvblZhbHVlIjoiaHR0cHMiLCJzZXNzaW9uSW5kZXgiOjN9LHsia2V5IjoiYXBpX2tleSIsInZhbHVlIjoiSW5zZXJ0WW91ckFwaUtleSIsImVuYWJsZWQiOnRydWUsInR5cGUiOiJzZWNyZXQiLCJzZXNzaW9uVmFsdWUiOiJJbnNlcnRZb3VyQXBpS2V5Iiwic2Vzc2lvbkluZGV4Ijo0fV0=)');
  }, []);

  return (
    <Flex m={2} flex={1} flexDirection="column">
      <Flex>
        <Box flex={1}>
          <Text>Our public API is <Link href={props.apiUrl} target="_blank">published in a Postman collection</Link> allowing you to fully browse and interact with the API</Text>
          <Text>The easiest way to understand and interact with the API is via the Run in Postman button to the right</Text>
          <Text>Some examples using Javascript are shown below for reference</Text>
        </Box>
        <Box>
          {reactContent}
        </Box>
      </Flex>
      <Text variant="h3">Authentication</Text>
      <Flex width="100%" my={2} p={2} variant="box" bg="gray.100">
        <code>HEADER apiKey: {props.apiKey}</code>
      </Flex>
      <Text variant="h3">Create Company</Text>
      <Flex width="100%" my={2} p={2} variant="box" bg="gray.100">
        <code>POST {`${window.location.origin}/api/v1/companies`}</code>
      </Flex>
      <Flex width="100%" my={2} p={2} variant="box" bg="gray.100">
        <ReactJson
          name={false}
          src={{
            'arr': '12000',
            'start_date': '01/01/2021',
            'userlot_external_id': 'externalabc',
            'renewal_date': '01/01/2022'
          }}
        />
      </Flex>
      <Text variant="h3">Read Custom Fields</Text>
      <Flex width="100%" my={2} p={2} variant="box" bg="gray.100">
        <code>GET {`${window.location.origin}/api/v1/company_fields`}</code>
      </Flex>
      <Flex width="100%" my={2} p={2} variant="box" bg="gray.100">
        <ReactJson
          name={false}
          src={[
            {
              "name": "arr",
              "type": "currency",
              "required": true,
              "default": "",
              "displayName": "ARR"
            },
            {
              "name": "name",
              "type": "string",
              "required": false,
              "default": "",
              "displayName": "Name"
            },
            {
              "name": "lastSeenAt",
              "type": "date",
              "required": false,
              "default": "",
              "displayName": "Last Seen"
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};

const HelpSegmentApi = (props) => {
  return (
    <Flex flex={1} m={2} flexDirection="column">
      <Text my={2}>
        Userlot supports <strong>Track</strong> and <strong>Identify</strong>{" "}
        events from Segment. See
        <Link
          mx={1}
          href="https://segment.com/docs/connections/destinations/catalog/webhooks/"
        >
          segment documentation
        </Link>
      </Text>
      <Flex width="100%" my={2} p={2} variant="box" bg="gray.100">
        <Link>{`${window.location.origin}/segment/track?appKey=${props.apiKey}`}</Link>
      </Flex>
    </Flex>
  );
};
