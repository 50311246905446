import React, { useState } from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import { curveBasis } from "d3-shape";

import {
  XYPlot,
  HorizontalRectSeries,
  YAxis,
  LineSeries,
  XAxis,
  FlexibleWidthXYPlot,
} from "react-vis";
import { useTheme } from "emotion-theming";
import { Flex } from "rebass";
import moment from "moment";

const _ = require("lodash");

export default function StackedLineGraph(props) {
  const [value, setValue] = useState();
  const theme = useTheme();
  const days = [...Array(props.days).keys()];
  const graphDataTemplate = days.reverse().map((i) => {
    return {
      date: moment().add(-i, "day"),
      x: days.length - i,
      y: 0,
    };
  });
  return (
    props.series &&
    props.series.length > 0 && (
      <Flex>
        <FlexibleWidthXYPlot
          margin={{ left: 40, right: 40, top: 10, bottom: 40 }}
          width={500}
          height={props.height || 250}
          onMouseLeave={() => setValue(false)}
          stackBy="y"
        >
          <XAxis
            tickFormat={(v, i) => {
              let date =
                i % 2 == 0 &&
                v < graphDataTemplate.length &&
                moment(graphDataTemplate[v].date);
              return date && moment(date).format("MMM");
            }}
          />
          <YAxis orientation="left" title="Value" />

          {props.marker &&
            graphDataTemplate.map((ev, i) => {
              return (
                i === 0 &&
                props.marker.map((m) => {
                  const markerData = [
                    {
                      x0: 0,
                      x: props.days + 1,
                      y0: m.max,
                      y: m.min,
                    },
                  ];
                  return (
                    <HorizontalRectSeries
                      data={markerData}
                      color={m.color}
                      style={{ strokeWidth: 0 }}
                    />
                  );
                })
              );
            })}

          {props.series?.map((ev, i) => {
            let graphData = _.cloneDeep(graphDataTemplate);
            graphData.forEach((item) => {
              item.y =
                (ev &&
                  ev.data &&
                  ev.data.find((c) =>
                    moment(c.date).isSame(moment(item.date), "day")
                  )?.count) ||
                0;
            });

            return (
              <LineSeries
                key={ev.name}
                className="first-series"
                animation={{ damping: 9, stiffness: 300 }}
                color={theme.colors.graph[i]}
                strokeWidth={2}
                curve={curveBasis}
                data={graphData}
              />
            );
          })}
          {props.yRescale && (
            <YAxis
              orientation="right"
              title="Score"
              right={0}
              tickFormat={(tick, i) =>
                i % 2 == 0 && props.yRescale ? props.yRescale(tick) : ""
              }
            />
          )}
        </FlexibleWidthXYPlot>
      </Flex>
    )
  );
}
