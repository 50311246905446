import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Flex } from "rebass";

function DroppableSection(props) {
  return (
    <Droppable droppableId={props.droppableId}>
      {(provided, snapshot) => (
        <Flex
          flexDirection="column"
          {...provided.droppableProps}
          flex={1}
          ref={provided.innerRef}
          sx={{
            "&:hover": {
              cursor: "hand",
              bg: "brandHighlight",
              outlineStyle: "dashed",
              boxSizing: "border-box",
              outlineWidth: 2,
              outlineOffset: -2,
              outlineColor: "brandLight",
              borderRadius: "medium",
            },
          }}
        >
          {props.children}
          {provided.placeholder}
        </Flex>
      )}
    </Droppable>
  );
}

export default DroppableSection;
