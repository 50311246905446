import React from "react";
import PropTypes from "prop-types";
import { Text, Flex, Box } from "rebass";
import { useTheme } from "emotion-theming";

const UserStatusBadge = (props) => {
  const theme = useTheme();
  const { user } = props;

  return user.unconfirmedEmail ? (
    <Box>
      <Flex
        data-tag="allowRowEvents"
        p={1}
        px={2}
        sx={{ borderRadius: 99 }}
        bg={user.invitedAt ? "gray.700" : user.lastSeenAt ? "green" : "orange"}
      >
        <Text data-tag="allowRowEvents" variant="label" color="white">
          Awaiting Email Confirmation
        </Text>
      </Flex>
    </Box>
  ) : (
    <Box>
      <Flex
        data-tag="allowRowEvents"
        p={1}
        px={2}
        sx={{ borderRadius: 99 }}
        bg={user.invitedAt ? "gray.700" : user.lastSeenAt ? "green" : "orange"}
      >
        <Text data-tag="allowRowEvents" variant="label" color="white">
          {user.invitedAt ? "Invited" : user.lastSeenAt ? "Active" : "Inactive"}
        </Text>
      </Flex>
    </Box>
  );
};

UserStatusBadge.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

export default UserStatusBadge;
