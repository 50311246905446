import React, { useEffect, useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import moment from "moment";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { useTheme } from "emotion-theming";
import { useQuery } from "@apollo/react-hooks";
import { getCurrentUser } from "hooks/getCurrentUser";
import { dataFilterOptions, MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import Loader from "Components/Common/Loader";
import Select from "Components/Common/Forms/Select";
import {
  ArrowRightIcon,
  HealthIcon,
  EventIcon,
  MessageIcon,
  SatisfactionIcon,
  SentimentIcon,
} from "Components/Common/Icons";
import TaskIcon, {
  TaskCategories,
} from "Components/CommandCenter/Tasks/TaskDefaults";
import ActivityMenu from "./ActivityMenu";
import ActivityHistoryFilter from "./ActivityHistoryFilter";
import EntityActivityQuery from "../../graphql/queries/EntityActivities.gql";
import "react-vertical-timeline-component/style.min.css";
import "react-tabs/style/react-tabs.css";
import "assets/styles/react-tabs.css";
import { triggerActions } from "../../consts";
import PropTypes from "prop-types";
import { CustomIcon, LifeCycleIcon, MailIcon, UserIcon } from "../Common/Icons";
import AddHistoryMenu from "./AddHistoryMenu";
import ActivityTask, { ActivityTaskTitle } from "./ActivityTask";
import ActivityComment, { ActivityCommentTitle } from "./ActivityComment";
import ActivityAutomation, {
  ActivityAutomationTitle,
} from "./ActivityAutomation";
import ActivityItem, { ActivityItemTitle } from "./ActivityItem";
import ActivityEmail, { ActivityEmailTitle } from "./ActivityEmail";
import SatisfactionItem from "./SatisfactionItem";

const _ = require("lodash");

const filtersLookup = [
  { value: "comment", filter: "comment" },
  { value: "nps_changed", filter: "trigger" },
  { value: "csat_changed", filter: "trigger" },
  { value: "ces_changed", filter: "trigger" },
  { value: "sentiment_increased", filter: "trigger" },
  { value: "sentiment_decreased", filter: "trigger" },
  { value: "task", filter: "tasks" },
  { value: "trigger", filter: "trigger" },
  { value: "status_changed", filter: "trigger" },
  { value: "health_score_increased", filter: "trigger" },
  { value: "health_score_decreased", filter: "trigger" },
  { value: "company_churned", filter: "trigger" },
  { value: "company_owner_changed", filter: "trigger" },
  { value: "company_lifecycle_changed", filter: "trigger" },
];
const filters = [
  {
    value: "tasks",
    label: "Tasks",
    default: true,
  },
  { value: "comment", label: "Notes", default: true },
  { value: "email", label: "Email", default: true },
  {
    value: "trigger",
    label: "Actions",
  },
];
const ActivityIcon = (props) => {
  const { type, data } = props;
  const theme = useTheme();

  switch (type) {
    case "company_lifecycle_changed":
      return <LifeCycleIcon />;
    case "nps_increased":
    case "nps_decreased":
    case "nps_changed":
    case "csat_changed":
    case "ces_changed":
      return <SatisfactionIcon />;
    case "sentiment_increased":
    case "sentiment_decreased":
      return <SentimentIcon />;
    case "status_changed":
      return <ArrowRightIcon />;
    case "health_score_increased":
      return (
        <Text mt={3}>
          <HealthIcon />
        </Text>
      );
    case "health_score_decreased":
      return (
        <Text mt={3}>
          <HealthIcon color={theme.colors.red} />
        </Text>
      );
    case "trigger":
      return <EventIcon />;
    case "comment":
      return <MessageIcon />;
    case "email":
      return <MailIcon />;
    case "company_owner_changed":
      return <UserIcon />;
    default:
      return (
        <Text mt={3}>
          <CustomIcon componentName={data.actionable?.taskCategoryIcon} />
        </Text>
      );
  }
};

ActivityIcon.propTypes = {
  type: PropTypes.string,
};
const ActivityTitle = (activity) => {
  switch (activity.activityType) {
    case "trigger":
      return <ActivityAutomationTitle activity={activity} />;
    case "comment":
      return <ActivityCommentTitle activity={activity} />;
    case "task":
      return <ActivityTaskTitle activity={activity} />;
    case "email":
      return <ActivityEmailTitle activity={activity} />;
    default:
      return <ActivityItemTitle activity={activity} />;
  }
};

const ActivityDetail = (activity, refetch) => {
  switch (activity.activityType) {
    case "health_score_increased":
      return (
        <Text
          p={3}
          bg="gray.100"
          dangerouslySetInnerHTML={{
            __html: `Increased by ${activity.fields.value} from ${
              activity.fields.old_health_category || ""
            }`,
          }}
        />
      );
    case "health_score_decreased":
      return (
        <Text
          p={3}
          bg="gray.100"
          dangerouslySetInnerHTML={{
            __html: `Decreased by ${activity.fields.value} from ${
              activity.fields.old_health_category || ""
            }`,
          }}
        />
      );
    case "nps_increased":
    case "nps_decreased":
    case "nps_changed":
    case "csat_changed":
    case "ces_changed":
      return <SatisfactionItem activity={activity} refetch={refetch} />;
    case "pulse_changed":
      return (
        <Text
          p={3}
          bg="gray.100"
          dangerouslySetInnerHTML={{
            __html: `${activity.fields.value} - ${
              activity.fields.comment || ""
            }`,
          }}
        />
      );
    case "status_changed":
      return <ActivityItem activity={activity} refetch={refetch} />;
    case "trigger":
      return <ActivityAutomation activity={activity} refetch={refetch} />;
    case "email":
      return <ActivityEmail activity={activity} refetch={refetch} />;

    case "comment":
      return <ActivityComment activity={activity} refetch={refetch} />;
    case "task":
      return <ActivityTask activity={activity} refetch={refetch} />;
    default:
      return <ActivityItem activity={activity} refetch={refetch} />;
  }
};

function ActivityHistory(props) {
  const { id, parentType } = props;
  const theme = useTheme();
  const [daysAgo, setDaysAgo] = useState({ value: 30, label: "Last 30 days" });
  const [activityTypes, setActivityType] = useState(
    filters.filter((f) => f.default).map((o) => o.value)
  );

  const myFilters = filtersLookup
    .filter((value) => activityTypes.includes(value.filter))
    .map((v) => v.value);

  const activityQuery = useQuery(EntityActivityQuery, {
    variables: {
      id,
      parentType,
      filters: myFilters,
      daysAgo: daysAgo?.value,
    },
    fetchPolicy: "network-only",
    skip: !id || id === "new",
  });

  useEffect(() => {
    activityQuery.refetch();
  }, [props.key]);

  const data =
    (activityQuery.data && activityQuery.data?.activityByEntity)?.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    ) || [];

  return (
    <Flex width="100%">
      <Box width="100%">
        <Flex alignItems="center">
          <Flex>
            <Text variant="h3">History</Text>
          </Flex>
          <Flex flexGrow={1} alignItems="center" justifyContent="flex-end">
            <ActivityHistoryFilter
              types={filters}
              onChange={(types) => setActivityType(types)}
              value={activityTypes}
            />
            <Box ml={2}>
              <Select
                width="180px"
                value={daysAgo}
                options={dataFilterOptions}
                onChange={(name, value) => {
                  setDaysAgo(value);
                }}
              />
            </Box>
          </Flex>
        </Flex>
        {activityQuery.loading && <Loader />}
        <Flex
          bg="gray.100"
          sx={{ borderRadius: "medium", overflowY: "scroll" }}
        >
          {data?.length > 0 ? (
            <Flex variant="box" flex={1}>
              <VerticalTimeline layout="1-column">
                {data
                  ?.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  )
                  .map((event, key) => {
                    return (
                      <VerticalTimelineElement
                        key={event.id}
                        date={moment(event.createdAt).fromNow()}
                        iconStyle={{
                          color: theme.colors.secondaryLight,
                        }}
                        icon={
                          <ActivityIcon
                            type={event.activityType}
                            data={event}
                          />
                        }
                        contentStyle={{ boxShadow: "none" }}
                      >
                        <HistoryDetail
                          event={event}
                          parentType={parentType}
                          refetch={activityQuery.refetch}
                        />
                      </VerticalTimelineElement>
                    );
                  })}
              </VerticalTimeline>
            </Flex>
          ) : (
            !activityQuery.loading && (
              <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                py={4}
              >
                <Image
                  src={require("assets/images/Activities.png")}
                  sx={{ height: "80px", objectFit: "contain" }}
                />
                <Text pt={2} mt={2} variant="muted" textAlign="center">
                  No activity history.
                </Text>
              </Flex>
            )
          )}
        </Flex>
      </Box>
    </Flex>
  );
}

ActivityHistory.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
};
export default ActivityHistory;

const HistoryDetail = ({ event, refetch, parentType }) => {
  const { currentUser } = getCurrentUser();

  const canEdit =
    event.activityType === "comment" && event.user?.id === currentUser?.id;

  let type = event.activityType;
  if (type == "comment" && event.id) {
    type = "updateComment";
  }
  return (
    <Box p={2}>
      <Flex alignItems={"center"} mb={1}>
        <Text variant="muted">{ActivityTitle(event)}</Text>
      </Flex>
      {ActivityDetail(event, refetch)}
      <Flex justifyContent="space-between" mt={1}>
        <Box></Box>
        <ActivityMenu
          refetch={refetch}
          actionable={event.actionable}
          type={type}
          parentType={parentType}
          canEdit={canEdit}
          onSave={() => refetch()}
        />
      </Flex>
    </Box>
  );
};

HistoryDetail.propTypes = {
  event: PropTypes.object,
  parentType: PropTypes.string,
  refetch: PropTypes.func,
};
