import React from "react";
import { Flex, Box } from "rebass";
import Settings from "../ProductSettings";
import HealthScoreForm from "./HealthScoreForm";

function Healthscore(props) {
  return (
    <Settings>
      <Box bg="white" my={2} mr={4} p={2} width="100%">
        <Flex flexDirection="column" width="100%" px={4}>
          <HealthScoreForm />
        </Flex>
      </Box>
    </Settings>
  );
}

export default Healthscore;
