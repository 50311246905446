import React, { useState } from "react";
import { RadialChart, DiscreteColorLegend, XYPlot } from "react-vis";
import { useTheme } from "emotion-theming";
import { Flex, Box, Text } from "rebass";

const _ = require("lodash");

export default function DonutGraph(props) {
  const theme = useTheme();
  const myData = props.data ? props.data.filter((d) => !!d) : [];
  return (
    <Flex alignItems={"center"}>
      <Box sx={{ position: "relative" }}>
        <RadialChart
          colorType="literal"
          innerRadius={props.width / 3 - 1}
          radius={props.width / 2 - 1}
          data={
            myData.map((d, i) => {
              return {
                angle: Number(d.value),
                theta: Number(d.value),
                color: d.color,
              };
            }) || []
          }
          width={props.width}
          height={props.width}
          animation
        />
        <Flex
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            margin: props.width / 4,
            height: props.width / 2,
            width: props.width / 2,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Text variant="h3">{props.value}</Text>
        </Flex>
        {props.label && (
          <Text
            mt={1}
            variant="muted"
            textAlign={"center"}
            maxWidth={props.width}
          >
            {props.label}
          </Text>
        )}
      </Box>
      {!props.noLegend && (
        <Box ml={3}>
          <Flex
            flexWrap="wrap"
            flexDirection={"column"}
            maxHeight={props.width + 10}
          >
            {myData.map((d) => (
              <Flex
                key={d.label}
                my={1}
                sx={{ cursor: props.onClick && "pointer" }}
                onClick={() => props.onClick && props.onClick(d)}
              >
                <Box
                  m={1}
                  sx={{ width: 5, height: 5, borderRadius: 3 }}
                  bg={d.color}
                />
                <Text mx={1} variant="muted" fontSize="xxs">
                  {d.label}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      )}
    </Flex>
  );
}
