import React, { useState } from "react";
import { Flex, Text, Link, Box, Image } from "rebass";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import HealthScoreIcon from "Components/Data/Company/HealthScoreIcon";
import Table from "Components/Common/Table";
import Label from "Components/Common/Forms/Label";
import Page from "Components/Page";
import { useTheme } from "emotion-theming";
import numeral from "numeral";
import _ from "lodash";
import moment from "moment";
import Avatar from "react-avatar";
import Select from "Components/Common/Forms/Select";
import StackedBarGraph from "./StackedBarGraph";
import ReportTopbar from "./ReportTopbar";
import CompanyRenewalsQuery from "../../../graphql/queries/CompanyRenewals";
import WidgetWrapper from "../Home/WidgetWrapper";
import RadioGroup from "Components/Common/RadioGroup";

function Renewals(props) {
  const theme = useTheme();
  const history = useHistory();
  const [selectedType, setSelectedType] = useState("health");
  const [days, setDays] = useState({
    value: "60",
    label: "In the next 60 Days",
  });
  const { loading, data } = useQuery(CompanyRenewalsQuery, {
    variables: { days: days.value, priorityOnly: false },
  });

  const companyRenewals = data && data.companyRenewals;

  let dataTable = [];

  companyRenewals?.map((d) => {
    let company = { ...d };
    company.companyDetails.map((p) => {
      dataTable.push({
        ...company,
        companyId: company.id,
        id: p.id,
        detail: { ...p },
        revenue: p.mrr,
        renewalDate: p.renewalDate,
      });
    });
  });

  const columns = [
    {
      name: "Health",
      selector: (row) => row.details?.healthScore,
      sortable: true,
      width: "100px",
      cell: (row) => {
        return (
          <HealthScoreIcon
            status={row.detail?.status}
            simple
            data-tag="allowRowEvents"
            value={row.detail?.healthScore}
            category={row?.healthCategory}
            date={row?.healthScoreUpdatedAt}
          />
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => {
        return (
          <RouterLink to={`/reports/accounts/${row.id}`} component={Link}>
            <Flex alignItems="center">
              <Avatar
                size={25}
                round
                name={row.detail.product?.name}
                src={row.detail.product?.logoUrl}
              />
              <Box mx={2}>
                <Text>{row.name}</Text>
                <Text variant="muted">{row.detail.product?.name}</Text>
              </Box>
            </Flex>
          </RouterLink>
        );
      },
    },
    {
      name: "Owner",
      selector: (row) => row.details?.owner?.name,
      sortable: true,
      cell: (row) => {
        return row.detail?.owner?.name;
      },
    },
    {
      name: "Next Renewal Date",
      selector: (row) => row.renewalDate,
      sortable: true,
      cell: (row) => {
        return (
          <Box data-tag="allowRowEvents">
            <Text
              data-tag="allowRowEvents"
              fontWeight="bold"
              color={
                moment(row.renewalDate).isSameOrBefore(moment()) ? "red" : ""
              }
            >
              {moment().to(moment(row.renewalDate))}
            </Text>
            <Text variant="muted" data-tag="allowRowEvents">
              renews {moment(row.renewalDate).format("DD MMM")}
            </Text>
          </Box>
        );
      },
    },
    {
      name: "MRR",
      selector: (row) => row.revenue,
      sortable: true,
      cell: (row) => {
        return (
          <Text data-tag="allowRowEvents">
            {Number(row.revenue || "0").toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Text>
        );
      },
    },
  ];

  let csm = [];

  let graphData =
    dataTable &&
    dataTable
      .filter((d) =>
        moment(d.renewalDate).isBefore(moment().add(days.value, "days"))
      )
      .map((d, i) => {
        csm.push(d.detail?.owner);
        return {
          dateLabel: moment(d.renewalDate).format("MMM YY"),
          health: d.detail?.healthCategory,
          value: Number(d.revenue),
          id: d.detail?.owner?.id,
          name: d.detail?.owner?.name,
          count: 1,
          date: moment(d.renewalDate),
        };
      });

  var totalLatestValue = _.sumBy(graphData, "value");

  const minDate = _(graphData).minBy("date")?.date;

  let defaults = [];
  const daysTotal = moment()
    .add(days.value, "days")
    .diff(moment(minDate), "months");
  for (let i = 0; i < daysTotal + 1; i++) {
    defaults.push({
      name: moment(minDate).add(i, "months").format("MMM YY"),
      count: 0,
    });
  }
  defaults.sort((a, b) => {
    if (moment(a.name).isBefore(moment(b.name))) return -1;
    if (moment(a.name).isAfter(moment(b.name))) return 1;
    return 0;
  });
  let graphDataByCSM = _.uniq(csm).map((owner, i) => {
    let data = _(graphData)
      .filter((d) => d.id === owner?.id)
      .groupBy("dateLabel")
      .map((objs, key) => ({
        name: key,
        count: _.sumBy(objs, "value"),
      }))
      .value();
    let data2 = _.unionWith(data, defaults, (a, b) => {
      return a.name === b.name;
    }).sort((a, b) => {
      if (moment(a.name).isBefore(moment(b.name))) return -1;
      if (moment(a.name).isAfter(moment(b.name))) return 1;
      return 0;
    });
    return {
      name: owner ? owner.name : "Unassigned",
      type: "date",
      xLabels: defaults.map((d) => d.name),
      data: data2,
    };
  });

  let graphDataByHealth = ["good", "ok", "poor"].map((health, i) => {
    let data = _(graphData)
      .filter((d) => d.health === health)
      .groupBy("dateLabel")
      .map((objs, key) => ({
        name: key,
        count: _.sumBy(objs, "value"),
      }))
      .value();

    let data2 = _.unionWith(data, defaults, (a, b) => {
      return a.name === b.name;
    }).sort((a, b) => {
      if (moment(a.name).isBefore(moment(b.name))) return -1;
      if (moment(a.name).isAfter(moment(b.name))) return 1;
      return 0;
    });

    return {
      name: health,
      type: "date",
      xLabels: defaults.map((d) => d.name),
      data: data2,
    };
  });

  const graphDataByHealthByCSM = _.uniq(csm).map((owner, i) => {
    const ownerData = graphData.filter((d) => d.id === owner?.id);
    return {
      name: owner?.name,
      poor: _.sumBy(
        ownerData.filter((d) => d.health === "poor"),
        "value"
      ),
      ok: _.sumBy(
        ownerData.filter((d) => d.health === "ok"),
        "value"
      ),
      good: _.sumBy(
        ownerData.filter((d) => d.health === "good"),
        "value"
      ),
    };
  });

  return (
    <Page title="Renewals" topBar={<ReportTopbar />}>
      <Flex
        width="100%"
        justifyContent="flex-end"
        alignItems={"center"}
        p={2}
        px={4}
      >
        <Text mx={2}>Renewal Due</Text>
        <Box>
          <Select
            onChange={(name, value) => setDays(value)}
            value={days}
            options={[
              { value: "30", label: "In the next 30 Days" },
              { value: "60", label: "In the next 60 Days" },
              { value: "90", label: "In the next 3 months" },
              { value: "180", label: "In the next 6 months" },
            ]}
          />
        </Box>
      </Flex>
      <Flex bg="white" flexDirection="column" flexGow={1} flex={1} px={4}>
        <Flex flex={1} width="100%">
          <Flex flex={1} width="100%" flexDirection="column">
            <WidgetWrapper title="">
              <Flex
                flex={1}
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Text variant="h1" fontSize="2xl">
                  {numeral(totalLatestValue).format("$0.0a")}
                </Text>
                <Text variant="muted">Renewing Revenue</Text>
              </Flex>
            </WidgetWrapper>

            <WidgetWrapper
              title="Renewal Value"
              menu={
                <RadioGroup
                  name="count"
                  selectedOption={selectedType}
                  options={[
                    {
                      value: "health",
                      label: (
                        <Text fontWeight="bold" fontSize="sm" mx={2}>
                          Health
                        </Text>
                      ),
                    },
                    {
                      value: "csm",
                      label: (
                        <Text fontWeight="bold" fontSize="sm" mx={2}>
                          CSM
                        </Text>
                      ),
                    },
                  ]}
                  onChange={(name, target) => {
                    setSelectedType(target.value);
                  }}
                />
              }
            >
              {selectedType == "health" &&
              graphDataByHealth.length > 0 &&
              graphDataByHealth[0].data.length > 0 ? (
                <Box width="100%">
                  <StackedBarGraph
                    series={graphDataByHealth}
                    height={250}
                    colors={[
                      theme.colors.green,
                      theme.colors.yellow,
                      theme.colors.red,
                    ]}
                  />
                </Box>
              ) : (
                selectedType == "health" && (
                  <Flex alignItems="center" justifyContent="center" p={4}>
                    <Image
                      src={require("assets/images/GrowthEmpty.png")}
                      width={80}
                    />
                  </Flex>
                )
              )}
              {selectedType == "csm" &&
              graphDataByCSM.length > 0 &&
              graphDataByCSM[0].data.length > 0 ? (
                <Box width="100%">
                  <StackedBarGraph
                    series={graphDataByCSM}
                    height={250}
                    colors={theme.colors.graph}
                  />
                </Box>
              ) : (
                selectedType == "csm" && (
                  <Flex alignItems="center" justifyContent="center" p={4}>
                    <Image
                      src={require("assets/images/GrowthEmpty.png")}
                      width={80}
                    />
                  </Flex>
                )
              )}
            </WidgetWrapper>
          </Flex>
          <Flex flex={1} width="100%" flexDirection="column">
            <WidgetWrapper title="CSM & Health">
              {graphDataByHealthByCSM.length > 0 ? (
                <Box width="100%">
                  <Flex my={1}>
                    <Flex width={2 / 5}>
                      <Label>Owner</Label>
                    </Flex>
                    <Flex width={1 / 5}>
                      <HealthScoreIcon category="poor" simple />
                    </Flex>
                    <Flex width={1 / 5}>
                      <HealthScoreIcon category="ok" simple />
                    </Flex>
                    <Flex width={1 / 5}>
                      <HealthScoreIcon category="good" simple />
                    </Flex>
                  </Flex>
                  {graphDataByHealthByCSM.map((csm, i) => (
                    <Flex
                      key={csm.name}
                      py={2}
                      bg={i % 2 === 0 ? "gray.100" : "white"}
                    >
                      <Flex fontWeight="bold" width={2 / 5} px={2}>
                        <Text sx={{ wordBreak: "break-word" }}>
                          {csm.name || "Unassigned"}
                        </Text>
                      </Flex>
                      <Flex width={1 / 5}>
                        {numeral(csm.poor).format("0.0a")}
                      </Flex>
                      <Flex width={1 / 5}>
                        {numeral(csm.ok).format("0.0a")}
                      </Flex>
                      <Flex width={1 / 5}>
                        {numeral(csm.good).format("0.0a")}
                      </Flex>
                    </Flex>
                  ))}
                </Box>
              ) : (
                <Flex flex={1} p={4}>
                  <Image
                    src={require("assets/images/GrowthEmpty.png")}
                    width={80}
                  />
                </Flex>
              )}
            </WidgetWrapper>
          </Flex>
        </Flex>

        <Text variant="h4" mt={3} mx={2} color="brandLight">
          At Risk Renewals
        </Text>
        <Table
          title=""
          defaultSortField="renewalDate"
          selectableRows={false}
          columns={columns}
          data={dataTable.filter((d) =>
            moment(d.renewalDate).isBefore(moment().add(days.value, "days"))
          )}
          loading={loading}
          onRowClicked={(p) => {
            history.push(`/reports/accounts/${p.companyId}`);
          }}
          noDataComponent={
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              m={4}
            >
              <Image
                src={require("assets/images/AccountsEmpty.png")}
                width={80}
              />
              <Text my={2} variant="muted">
                No accounts returned
              </Text>
            </Flex>
          }
        />
        <Flex flexGrow={2} />
      </Flex>
    </Page>
  );
}

export default Renewals;
