import React, { useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import IntegrationList from "./IntegrationList";
import IntegrationForm from "./IntegrationForm";
import { Flex, Image, Text, Box } from "rebass";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import Settings from "../ProductSettings";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { PlusIcon } from "Components/Common/Icons";
import StepWizard from "react-step-wizard";
import WizardNav from "Components/Common/WizardNav";
import SelectAppStep from "./SelectAppStep";
import SelectIntegrationStep from "./SelectIntegrationStep";
import AddIntegrationStep from "./AddIntegrationStep";
import { getIntegrationDefinitions } from "../../../../hooks/getIntegrationDefinitions";
import { IntegrationIcon } from "../../../Common/Icons";

function Integrations(props) {
  const { id } = useParams();
  const [app, setApp] = useState();
  const [appConnection, setAppConnection] = useState();
  return (
    <Settings>
      <Flex width="100%">
        <Flex
          p={2}
          bg="gray.100"
          sx={{
            height: "100%",
            maxWidth: "300px",
          }}
          flex={1}
        >
          <IntegrationList />
        </Flex>
        <Flex flex={1} p={4} bg="white">
          {id == "new" ? (
            <StepWizard
              nav={
                <WizardNav
                  stepLabels={["Select App", "Configure Connection", "Finish"]}
                />
              }
            >
              <SelectAppStep
                onSelect={(data) => {
                  setApp(data);
                }}
              />
              <SelectIntegrationStep
                app={app}
                onSelect={(data) => {
                  setAppConnection(data);
                }}
              />
              <AddIntegrationStep
                app={app}
                appConnection={appConnection}
                onSelect={(data) => {}}
              />
            </StepWizard>
          ) : (
            id && <IntegrationForm />
          )}
          {!id && <EmptyState />}
        </Flex>
      </Flex>
    </Settings>
  );
}

export default withRouter(Integrations);

const EmptyState = (props) => {
  const history = useHistory();
  const definitions = getIntegrationDefinitions();
  return (
    <Flex
      p={5}
      flex={1}
      justifyContent="center"
      sx={{
        minHeight: 400,
      }}
    >
      <Flex flex={1} alignItems="flex-start" flexDirection="column">
        <Text variant="h2">Integrations</Text>
        <Text my={3}>
          Userlot can be integrated using the following enabled apps
        </Text>
        <Flex flexWrap="wrap">
          {definitions?.data?.integrationDefinitions
            .filter((d) => d.state == "ENABLED")
            .map((d) => (
              <Flex key={d.id} p={2} m={2} flexDirection="column">
                <Flex>
                  <IntegrationIcon logoUrl={d.icon} />
                  <Box mx={2}>
                    <Text>{d.name}</Text>

                    <Text fontSize={"xxs"}>{d.website}</Text>
                  </Box>
                </Flex>
              </Flex>
            ))}
        </Flex>
        <Flex width="100%" my={3} justifyContent="space-between">
          <PrimaryButton
            onClick={() => {
              history.push("/settings/product/integrations/new");
            }}
            leftIcon={<PlusIcon />}
          >
            New Integration
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              history.push("/settings/product/import");
            }}
            leftIcon={<PlusIcon />}
          >
            Manual Import
          </SecondaryButton>
        </Flex>
        <Text my={3}>
          These apps have not yet been enabled for your account
        </Text>

        <Flex flexWrap="wrap">
          {definitions?.data?.integrationDefinitions
            .filter((d) => d.state != "ENABLED")
            .map((d) => (
              <Flex key={d.id} p={3} flexDirection="column" width={1 / 2}>
                <Flex>
                  <IntegrationIcon logoUrl={d.icon} />
                  <Box mx={2}>
                    <Text>{d.name}</Text>

                    <Text fontSize={"xxs"}>{d.website}</Text>
                  </Box>
                </Flex>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
