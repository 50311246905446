import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Button, Flex, Link, Text } from "rebass";
import { useMutation, useQuery } from "@apollo/react-hooks";
import UserImpersonate from "../../../graphql/mutations/UserImpersonate.gql";
import { redirectToSubdomain } from "../../../auth-helper";
import CurrentUserQuery from "../../../graphql/queries/CurrentUser.gql";
import { clearStore } from "../../../consts";
import SecondaryButton from "../Buttons/SecondaryButton";
import { UserIcon } from "../Icons";

function ImpersonateLink(props) {
  const { userId, companyId } = props;
  const [impersonate_user, {}] = useMutation(UserImpersonate);
  const { data, client } = useQuery(CurrentUserQuery, {
    fetchPolicy: "no-cache",
  });

  const isImpersonate = () => {
    return typeof userId !== "undefined";
  };

  const impersonate = () => {
    console.log(`We are ere! Impersonate ${userId}`);

    impersonate_user({
      variables: {
        id: userId,
        companyId: companyId
      },
    }).then((response) => {
      let redirect = response.data.userImpersonate.redirect;
      redirectToSubdomain(redirect, "/home");
      if (client) {
        clearStore(client);
      }
    });
  };

  return isImpersonate() ? (
    <Button
      variant="primary"
      sx={{ backgroundColor: "warning", color: "white" }}
      onClick={impersonate}
    >
      <Flex>
        <UserIcon />
        <Text color="white" mx={1}>
          Impersonate
        </Text>
      </Flex>
    </Button>
  ) : data?.currentUser?.impersonating ? (
    <SecondaryButton onClick={impersonate}>Back to Admin</SecondaryButton>
  ) : null;
}

export default ImpersonateLink;
