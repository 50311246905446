import { useMutation, useQuery } from "@apollo/react-hooks";
import React from "react";
import { useToasts } from "react-toast-notifications";
import { Box, Flex, Text } from "rebass";
import CompanyRevenueEntryMutation from "../../../graphql/mutations/CompanyRevenueEntry.gql";

import Modal from "Components/Common/Modal";
import CustomFormGroup from "../../Common/Forms/CustomFormGroup";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import GhostButton from "../../Common/Buttons/GhostButton";
import { SaveIcon } from "../../Common/Icons";
import { Formik } from "formik";
import * as Yup from "yup";
import RadioGroup from "Components/Common/RadioGroup";
import moment from "moment";
import RadioGroupButton from "../../Common/RadioGroupButton";

function CompanyRevenueEntryForm(props) {
  const { addToast } = useToasts();
  const [save, { loading }] = useMutation(CompanyRevenueEntryMutation);

  let defaultRevenueType = props.defaultRevenueType;
  if (props.item) {
    switch (props.item?.revenueType) {
      case "0":
        defaultRevenueType = "mrr";
        break;
      case "1":
        defaultRevenueType = "arr";
        break;
      case "2":
        defaultRevenueType = "nonrr";
        break;
    }
  }
  return (
    <Modal
      width="500px"
      title="Update Revenue"
      open={props.open}
      onDismiss={() => {
        props.onDismiss();
      }}
    >
      <Flex flexDirection="column" px={3} pb={4} alignItems={"center"}>
        <Formik
          enableReinitialize
          initialValues={{
            id: props.item?.id,
            companyId: props.id,
            createdAt: new Date(),
            endAt: props.renewalDate,
            ...props.item,
            revenueType: defaultRevenueType,
          }}
          autoComplete={false}
          validationSchema={Yup.object().shape({
            revenueValue: Yup.string().required("Required"),
            createdAt: Yup.string().required("Required"),
            endAt: Yup.string().required("Required"),
          })}
          onSubmit={(values, actions) => {
            save({
              variables: {
                id: props.item?.id,
                companyId: values.companyId,
                revenueValue: Number(values.revenueValue),
                revenueType: values.revenueType,
                createdAt: values.createdAt,
                endAt: values.endAt,
              },
            }).then(
              (response) => {
                if (!response.data?.companyRevenueEntry?.errors) {
                  props.onSave();
                } else {
                  addToast(
                    <Flex>
                      {response.data?.companyRevenueEntry?.errors[0]}
                    </Flex>,
                    {
                      appearance: "error",
                      autoDismiss: false,
                    }
                  );
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }}
        >
          {(formProps) => {
            const { values, errors, setFieldValue, handleBlur, handleSubmit } =
              formProps;
            return (
              <Flex flexDirection="column" maxWidth={450} minHeight={550}>
                <RadioGroupButton
                  name="revenueType"
                  value={values.revenueType}
                  options={[
                    {
                      label: <Text fontSize="xs">Monthly Subscription</Text>,
                      value: "mrr",
                    },
                    {
                      label: <Text fontSize="xs">Annual Subscription</Text>,
                      value: "arr",
                    },
                    {
                      label: <Text fontSize="xs">Non-Recurring</Text>,
                      value: "nonrr",
                    },
                  ]}
                  onChange={(name, target) => {
                    if (!props.item) {
                      // Only change type if the record has not saved
                      setFieldValue("revenueType", target.value);
                      if (target.value == "nonrr") {
                        setFieldValue("endAt", values.createdAt);
                      } else {
                        setFieldValue("endAt", props.renewalDate);
                      }
                    }
                  }}
                />
                <Flex mt={3} flexDirection={"column"}>
                  <Flex>
                    <CustomFormGroup
                      type="date"
                      placeholder="Start Date"
                      label="Start Date"
                      name="createdAt"
                      value={values.createdAt}
                      onChange={setFieldValue}
                      helperText={
                        errors && errors.createdAt && errors.createdAt
                      }
                    />
                    <CustomFormGroup
                      type="date"
                      placeholder="End Date"
                      label="End Date"
                      name="endAt"
                      value={values.endAt}
                      onChange={setFieldValue}
                      helperText={errors && errors.endAt && errors.endAt}
                    />
                  </Flex>
                  <CustomFormGroup
                    placeholder="$0.00"
                    label="Value ($)"
                    name="revenueValue"
                    value={values.revenueValue}
                    onChange={setFieldValue}
                    helperText={
                      errors && errors.revenueValue && errors.revenueValue
                    }
                  />
                </Flex>
                <Box my={1} />
                <Flex alignItems="center" justifyContent="flex-end">
                  {props.onClose && (
                    <GhostButton onClick={props.onClose}>Close</GhostButton>
                  )}

                  <PrimaryButton
                    leftIcon={<SaveIcon />}
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </PrimaryButton>
                </Flex>
              </Flex>
            );
          }}
        </Formik>
      </Flex>
    </Modal>
  );
}

export default CompanyRevenueEntryForm;
