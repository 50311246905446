import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams, useHistory, Prompt } from "react-router-dom";
import { Flex, Text, Box, Button, Link } from "rebass";
import "react-tabs/style/react-tabs.css";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FieldArray, Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import ShowError, { ErrorParams } from "Components/Common/Notifications/Error";
import ShowSuccess, {
  SuccessParams,
} from "Components/Common/Notifications/Success";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { Menu, Item, animation, MenuProvider } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { deepOmit, MODEL_TYPES, TRIGGER_CONFIG } from "consts";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import {
  DelayIcon,
  MoreIcon,
  PlusIcon,
  SaveIcon,
  TrashIcon,
} from "Components/Common/Icons";
import {
  RiOrderPlayLine,
  RiPauseMiniLine,
  RiSettings2Fill,
  RiSkipForwardFill,
  RiSkipForwardMiniFill,
} from "react-icons/ri";
import Modal from "Components/Common/Modal";
import SegmentConfigForm from "Components/Filter/Segment/SegmentConfigForm";
import theme from "../../../../theme";
import AssignOwnerForm from "../Triggers/AssignOwnerForm";
import WorkflowsQuery from "../../../../graphql/queries/Workflows.gql";
import WebhookForm from "../Triggers/WebhookForm";
import SlackForm from "../Triggers/SlackForm";
import WorkflowMutation from "../../../../graphql/mutations/Workflow.gql";
import WorkflowDeleteMutation from "../../../../graphql/mutations/WorkflowDelete.gql";
import WorkflowQuery from "../../../../graphql/queries/Workflow.gql";
import TagsEditor from "../../../Common/Tags/TagsEditor";
import EmailForm from "../Triggers/EmailForm";
import AlertForm from "../Triggers/AlertForm";
import TaskForm from "../Triggers/TaskForm";
import SelectSurveyForm from "../Triggers/SelectSurveyForm";
import { AutomationIcon, WarningIcon } from "../../../Common/Icons";
import moment from "moment";
import WorkflowForm from "../Triggers/WorkflowForm";
import TagForm from "../Triggers/TagForm";
import Label from "../../../Common/Forms/Label";
import BannerForm from "../Triggers/BannerForm";
import { getConfigDefaults } from "../../../../consts";

const actionOptions = TRIGGER_CONFIG.Actions?.map((r) => {
  return {
    value: r.id,
    label: (
      <Flex alignItems={"center"}>
        <Box color="brandLight">{r.icon}</Box>
        <Box mx={2}>
          {r.name}
          <Text variant="muted" fontSize={"xxs"}>
            {r.label}
          </Text>
        </Box>
      </Flex>
    ),
  };
});

function PlaybookForm(props) {
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const [selectedTab, setSelectedTab] = useState(0);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      name: Yup.string().required("Required"),
    })
  );

  const [openEditor, setOpenEditor] = useState(0);
  const [selectedAction, setSelectedAction] = useState({
    stepIndex: -1,
    actionIndex: -1,
  });
  const { data } = useQuery(WorkflowQuery, {
    variables: { id },
    skip: !id || id === "new",
  });
  const [save] = useMutation(WorkflowMutation, {
    refetchQueries: [
      {
        query: WorkflowsQuery,
        variables: {
          searchText: undefined,
          tags: [],
        },
        fetchPolicy: "network-only",
      },
    ],
  });
  const [destroy] = useMutation(WorkflowDeleteMutation, {
    refetchQueries: [
      {
        query: WorkflowsQuery,
        variables: {
          searchText: undefined,
          tags: [],
        },
        fetchPolicy: "network-only",
      },
    ],
  });

  let workflow = data?.workflow && {
    ...data?.workflow,
    segmentId: data?.workflow?.segment?.id,
    action: TRIGGER_CONFIG.Events.find(
      (i) => i.id === data?.workflow?.eventType
    )?.type,
  };
  workflow = data?.workflow && deepOmit(workflow, "__typename");

  return (
    <Formik
      enableReinitialize
      initialValues={
        workflow || {
          name: "",
          eventType: undefined,
          actionType: undefined,
          enabled: false,
          triggerOnce: true,
          action: undefined,
        }
      }
      autoComplete={false}
      validateOnBlur
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        const valid =
          !values.steps ||
          values.steps?.filter((s) => s.stepType === "step" && !s.config?.valid)
            .length == 0;
        if (valid) {
          save({
            variables: {
              ...values,
              id: values.id ? values.id : null,
              playbook: true,
            },
          }).then(
            (response) => {
              const result = response.data?.workflow;
              if (result?.errors) {
                actions.setErrors(result?.errors);
                addToast(
                  <ShowError message={result?.errors && result?.errors[0]} />,
                  ErrorParams
                );
              } else {
                actions.resetForm({
                  values: response.data?.workflow?.workflow,
                });
                addToast(<ShowSuccess message="Saved!" />, SuccessParams);
                history.push("/settings/product/library?tab=playbooks");
              }
            },
            (error) => {
              addToast(<ShowError message="Save error!" />, ErrorParams);
            }
          );
        }
      }}
    >
      {(formProps) => {
        const {
          values,
          handleSubmit,
          setFieldValue,
          handleBlur,
          errors,
          dirty,
        } = formProps;

        const invalid =
          values.steps?.filter((s) => s.stepType === "step" && !s.config?.valid)
            .length > 0;

        const incomplete =
          !values.steps ||
          values.steps.length == 0 ||
          values.steps.filter((s) => s.stepType === "step" && !s.config?.valid)
            .length > 0;

        return (
          <Flex flex={1} flexDirection="column">
            <Prompt
              when={dirty}
              message="You have unsaved changes. Are you sure you want to leave?"
            />
            <Flex w="100%" flexDirection="column">
              <Flex flexDirection="column" w="100%">
                <Flex justifyContent="space-between">
                  <Text variant="h2">Playbook - {values.name}</Text>
                  <Flex justifyContent="flex-end">
                    <Flex alignItems="flex-start" pt={2}>
                      <Text variant="label" mx={2} sx={{ marginLeft: -80 }}>
                        Enabled
                      </Text>
                      <Flex>
                        <CustomFormGroup
                          type="switch"
                          placeholder="Enabled"
                          label=""
                          name="enabled"
                          value={values.enabled}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          disabled={incomplete}
                          helperText={errors.enabled && errors.enabled}
                        />
                      </Flex>
                    </Flex>
                    <DeleteButton
                      callback={destroy}
                      refetch={() => {}}
                      onCancel={() => {
                        history.goBack();
                      }}
                      redirectUrl="/settings/product/library"
                      id={id}
                      name="Playbook"
                    />
                    <PrimaryButton
                      leftIcon={<SaveIcon />}
                      onClick={handleSubmit}
                    >
                      Save
                    </PrimaryButton>
                  </Flex>
                </Flex>
                <Tabs
                  width="100%"
                  selectedIndex={selectedTab}
                  onSelect={setSelectedTab}
                >
                  <TabList>
                    <Tab key="Info">
                      <Flex>
                        <RiSettings2Fill />
                        <Text mx={1}>Configuration</Text>
                        {errors.name && (
                          <Text mx={1} color="red">
                            *
                          </Text>
                        )}
                      </Flex>
                    </Tab>
                    <Tab key="Steps">
                      <Flex>
                        <RiOrderPlayLine />
                        <Text mx={1}>Actions</Text>
                        {invalid && (
                          <Text mx={1} color="red">
                            *
                          </Text>
                        )}
                      </Flex>
                    </Tab>
                    <Tab key="Steps">
                      <AutomationIcon /> Automations
                    </Tab>
                  </TabList>
                  <TabPanel key="Info">
                    <StepOne
                      {...formProps}
                      nextStep={() => setSelectedTab(selectedTab + 1)}
                    />
                    <Flex>
                      <Flex flexDirection="column">
                        <Text variant="label">Created By</Text>
                        <Text>{values.createdBy?.name}</Text>
                      </Flex>
                      <Flex flexDirection="column" mx={3}>
                        <Text variant="label">On</Text>
                        <Text>
                          {moment(values.createdAt).format("DD MMM YYYY")}
                        </Text>
                      </Flex>
                    </Flex>
                  </TabPanel>
                  <TabPanel key="Steps">
                    <Flex width="100%" flexDirection="column">
                      <Flex
                        mt={3}
                        alignItems="center"
                        justifyContent="center"
                        bg="gray.100"
                        sx={{ borderRadius: "medium" }}
                        px={3}
                        py={2}
                      >
                        When
                        <Text mx={1} fontWeight="bold">
                          Playbook
                        </Text>
                        has
                        <Text mx={1} fontWeight="bold">
                          started
                        </Text>
                      </Flex>
                      <FieldArray
                        name="steps"
                        render={(arrayHelpers) => (
                          <Flex flex={1} flexDirection="column">
                            <ActionStepConfig
                              {...formProps}
                              open={openEditor}
                              stepIndex={selectedAction.stepIndex}
                              actionIndex={selectedAction.actionIndex}
                              onDismiss={() => {
                                setOpenEditor(false);
                                setSelectedAction({
                                  stepIndex: -1,
                                  actionIndex: -1,
                                });
                              }}
                              onUpdateSchema={(type) => {}}
                              arrayHelpers={arrayHelpers}
                            />
                            <Flex flex={1}>
                              <Flex alignItems="flex-end">
                                <Text mx={2} width="40px" variant="muted">
                                  Then...
                                </Text>
                              </Flex>
                              <Flex
                                flex={1}
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box width="1px" height={10} bg="gray.100" />
                                <MenuProvider id="add_menu_" event="onClick">
                                  <Flex
                                    variant="avatar"
                                    width={20}
                                    height={20}
                                    alignItems="center"
                                    justifyContent="center"
                                    bg="gray.100"
                                    sx={{
                                      borderRadius: 999,
                                      transition:
                                        "width 0.7s ease, height 0.7s ease",
                                      ":hover": {
                                        width: 40,
                                        height: 40,
                                      },
                                    }}
                                    color="gray.500"
                                  >
                                    <PlusIcon />
                                  </Flex>
                                </MenuProvider>
                                <Menu id="add_menu_" animation={animation.zoom}>
                                  <Item
                                    m={1}
                                    variant="action"
                                    onClick={() => {
                                      arrayHelpers.insert(0, {
                                        stepType: "step",
                                        config: {},
                                        actions: [
                                          {
                                            actionType: undefined,
                                            config: {},
                                          },
                                        ],
                                      });
                                      setSelectedAction({
                                        stepIndex: 0,
                                        actionIndex: 0,
                                      });
                                      setOpenEditor(true);
                                    }}
                                  >
                                    <Flex alignItems="center">
                                      <Text color="brandLight">
                                        <PlusIcon size="12" />
                                      </Text>
                                      <Text fontSize="xs" mx={1}>
                                        Add Action
                                      </Text>
                                    </Flex>
                                  </Item>
                                  <Item
                                    m={1}
                                    variant="ghost"
                                    onClick={() =>
                                      arrayHelpers.insert(0, {
                                        stepType: "delay",
                                        config: { delay: 1 },
                                      })
                                    }
                                  >
                                    <Flex alignItems="center">
                                      <Text color="brandLight">
                                        <DelayIcon size="12" />
                                      </Text>
                                      <Text fontSize="xs" mx={1}>
                                        Add Delay
                                      </Text>
                                    </Flex>
                                  </Item>
                                </Menu>
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  width="100%"
                                >
                                  <Box width="1px" height={10} bg="gray.100" />
                                </Flex>
                                <Box
                                  sx={{
                                    width: 0,
                                    height: 0,
                                    borderLeft: "5px solid transparent",
                                    borderRight: "5px solid transparent",
                                    borderTopWidth: "5px",
                                    borderTopStyle: "solid",
                                    borderTopColor: "gray.200",
                                  }}
                                />
                              </Flex>
                            </Flex>
                            {values.steps && values.steps.length > 0
                              ? values.steps.map((step, index) => (
                                  <>
                                    <PlaybookStep
                                      key={`step-${index}`}
                                      index={index}
                                      step={step}
                                      {...formProps}
                                      onEdit={(stepIndex, actionIndex) => {
                                        setOpenEditor(true);
                                        setSelectedAction({
                                          stepIndex: index,
                                          actionIndex: 0,
                                        });
                                      }}
                                      onDelete={() =>
                                        arrayHelpers.remove(index)
                                      }
                                    />
                                    <Flex flex={1}>
                                      <Flex alignItems="flex-end">
                                        <Text
                                          width="40px"
                                          mx={2}
                                          variant="muted"
                                        >
                                          {index < values.steps.length - 1
                                            ? "Then..."
                                            : ""}
                                        </Text>
                                      </Flex>
                                      <Flex
                                        flex={1}
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Box
                                          width="1px"
                                          height={10}
                                          bg="gray.100"
                                        />
                                        <MenuProvider
                                          id={`add_menu_${index}`}
                                          event="onClick"
                                        >
                                          <Flex
                                            variant="avatar"
                                            width={20}
                                            height={20}
                                            alignItems="center"
                                            justifyContent="center"
                                            bg="gray.100"
                                            sx={{
                                              transition:
                                                "width 0.7s ease, height 0.7s ease",
                                              borderRadius: 999,
                                              ":hover": {
                                                width: 40,
                                                height: 40,
                                              },
                                            }}
                                            color="gray.500"
                                          >
                                            <PlusIcon />
                                          </Flex>
                                        </MenuProvider>
                                        <Menu
                                          id={`add_menu_${index}`}
                                          animation={animation.zoom}
                                        >
                                          <Item
                                            m={1}
                                            variant="action"
                                            onClick={() => {
                                              arrayHelpers.insert(index + 1, {
                                                stepType: "step",
                                                config: {},
                                                actions: [
                                                  {
                                                    actionType: undefined,
                                                    config: {},
                                                  },
                                                ],
                                              });
                                              setSelectedAction({
                                                stepIndex: index + 1,
                                                actionIndex: 0,
                                              });
                                              setOpenEditor(true);
                                            }}
                                          >
                                            <Flex alignItems="center">
                                              <Text color="brandLight">
                                                <PlusIcon size="12" />
                                              </Text>
                                              <Text fontSize="xs" mx={1}>
                                                Add Action
                                              </Text>
                                            </Flex>
                                          </Item>
                                          <Item
                                            m={1}
                                            variant="ghost"
                                            onClick={() =>
                                              arrayHelpers.insert(index + 1, {
                                                stepType: "delay",
                                                config: { delay: 1 },
                                              })
                                            }
                                          >
                                            <Flex alignItems="center">
                                              <Text color="brandLight">
                                                <DelayIcon size="12" />
                                              </Text>
                                              <Text fontSize="xs" mx={1}>
                                                Add Delay
                                              </Text>
                                            </Flex>
                                          </Item>
                                        </Menu>
                                        <Flex
                                          alignItems="center"
                                          justifyContent="center"
                                          width="100%"
                                        >
                                          <Box
                                            width="1px"
                                            height={10}
                                            bg="gray.100"
                                          />
                                        </Flex>
                                        <Box
                                          sx={{
                                            width: 0,
                                            height: 0,
                                            borderLeft: "5px solid transparent",
                                            borderRight:
                                              "5px solid transparent",
                                            borderTopWidth: "5px",
                                            borderTopStyle: "solid",
                                            borderTopColor: "gray.200",
                                          }}
                                        />
                                      </Flex>
                                    </Flex>
                                  </>
                                ))
                              : undefined}
                          </Flex>
                        )}
                      />
                    </Flex>
                  </TabPanel>
                  <TabPanel key="Info">
                    {values.references?.length == 0 ? (
                      <Flex
                        flex={1}
                        alignItems="center"
                        justifyContent={"center"}
                        p={6}
                        bg="gray.100"
                      >
                        <Text variant="muted">
                          This playbook is not currently used
                        </Text>
                      </Flex>
                    ) : (
                      <Flex
                        my={3}
                        flex={1}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Text mx={2}>
                          This Playbook is used in the following areas.
                        </Text>
                      </Flex>
                    )}
                    {values.references?.map((ref) => (
                      <Flex
                        key={ref.value}
                        variant="box"
                        sx={{
                          cursor: "pointer",
                          ":hover": {
                            backgroundColor: "brandHighlight",
                            borderBottomWidth: 0,
                          },
                        }}
                        m={2}
                        p={2}
                        px={4}
                        alignItems="center"
                      >
                        <Flex flex={2}>
                          <Text variant="h4">{ref.label}</Text>
                        </Flex>
                        <Flex flex={1}>
                          <Flex flex={1} flexDirection="column">
                            <Text textAlign="center" variant="label">
                              References
                            </Text>
                            <Text textAlign="center">{ref.info}</Text>
                          </Flex>
                          <Flex flex={1} flexDirection="column">
                            <Text textAlign="center" variant="label">
                              Active
                            </Text>
                            <Text textAlign="center">
                              {ref.active ? "Yes" : "No"}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    ))}
                  </TabPanel>
                </Tabs>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default withRouter(PlaybookForm);

const StepOne = (props) => {
  const { values, errors, setFieldValue, handleBlur } = props;

  return (
    <Flex flexDirection="column" width="100%">
      <CustomFormGroup
        placeholder="Name"
        label="Name"
        name="name"
        value={values.name}
        onChange={setFieldValue}
        onBlur={handleBlur}
        helperText={errors.name && errors.name}
      />
      <TagsEditor
        parentType="Workflow"
        label="Category"
        value={values.tags}
        onChange={(tags) => setFieldValue("tags", tags)}
      />
      <Box mt={3} />
      <CustomFormGroup
        type="textarea"
        placeholder="Summary"
        label="Summary"
        name="description"
        value={values.description}
        onChange={setFieldValue}
        onBlur={handleBlur}
        helperText={errors.description && errors.description}
      />
      <Box mt={2} />
      <CustomFormGroup
        type="richtext"
        placeholder="Objective"
        label="Objective"
        name="objective"
        value={values.objective || ""}
        onChange={setFieldValue}
        onBlur={handleBlur}
        rows={10}
        helperText={errors.objective && errors.objective}
      />
    </Flex>
  );
};
StepOne.propTypes = {
  stepType: PropTypes.string,
  index: PropTypes.number,
  step: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

const getFormLabel = (actionType) => {
  let label = "";
  switch (actionType) {
    case "in_app_notification":
      label = "Send an app notification";
      break;
    case "email":
      label = "Send an email";
      break;
    case "task_create":
      label = "Create a task ";
      break;
    case "assign_owner":
      label = "Assign owner";
      break;
    case "email":
      label = "Send an Email";
      break;
    case "slack":
      label = "Send a Slack";
      break;
    case "ask_survey":
      label = "Ask a survey";
      break;
    case "tags":
      label = "Add or remove tags";
      break;
    case "playbook":
      label = "Kickoff a playbook";
      break;
    case "in_app_banner":
      label = "Show an in app banner";
      break;
  }
  return label;
};
const PlaybookStep = (props) => {
  const { step, index, values, errors, setFieldValue } = props;
  const [segmentOpen, setSegmentOpen] = useState(false);

  const field = values && values.steps && values.steps[index];
  const fieldName = `steps[${index}].config`;

  const action = step.actions && step.actions.length > 0 && step.actions[0];
  let label = getFormLabel(action?.actionType);

  return (
    <Flex flexDirection="column" mx={2}>
      <SegmentConfigForm
        open={segmentOpen}
        field={field?.config || {}}
        fieldName={fieldName}
        onClose={() => setSegmentOpen(false)}
        onSave={(newValues) => {
          setFieldValue(fieldName, newValues);
          setSegmentOpen(false);
        }}
        {...props}
      />
      <Flex variant="box" flexDirection="column">
        <Flex p={2} alignItems="center" justifyContent="space-between">
          <Flex
            flex={1}
            sx={{ cursor: "pointer" }}
            alignItems="center"
            onClick={() => props.onEdit(index, 0)}
          >
            <Flex height="100%" mx={2}>
              {step.stepType === "step" ? (
                action?.actionType ? (
                  <Text mx={1} color="brandLight">
                    {
                      TRIGGER_CONFIG.Actions.find(
                        (obj) => obj.id === action?.actionType
                      )?.icon
                    }
                  </Text>
                ) : (
                  <Text mx={1} color="warning">
                    <WarningIcon />
                  </Text>
                )
              ) : (
                <Text mx={1} color="brandLight">
                  <DelayIcon />
                </Text>
              )}
            </Flex>
            <Box
              px={2}
              sx={{
                borderLeftWidth: 1,
                borderLeftColor: "accent",
                borderLeftStyle: "solid",
              }}
            >
              <Text fontWeight="bold">
                {step.stepType === "step"
                  ? `${label}`
                  : `Delay from moving to next step`}
              </Text>

              {step.stepType === "step" ? (
                step.config?.valid ? (
                  <Text variant="muted">{action.config?.title}</Text>
                ) : (
                  <Text variant="muted" color="warning">
                    Setup not complete
                  </Text>
                )
              ) : (
                <Text variant="muted">{`for ${step?.config?.delay} days`}</Text>
              )}
              <Box mt={2} />
              {field?.config?.andFields?.length > 0 && (
                <Flex my={1} alignItems="center" color="gray.400">
                  <RiSkipForwardMiniFill />
                  <Text variant="label" color="gray.400">
                    Can be skipped
                  </Text>
                </Flex>
              )}
              {field?.config?.requiresComplete && (
                <Flex my={1} alignItems="center" color="gray.400">
                  <RiPauseMiniLine />
                  <Text variant="label" color="gray.400">
                    Wait until complete
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>
          <Flex alignItems="center">
            <MenuProvider id={`edit_menu_${index}`} event="onClick">
              <Button variant="ghost">
                <MoreIcon />
              </Button>
            </MenuProvider>
            <Menu id={`edit_menu_${index}`} animation={animation.zoom}>
              <Item m={1} variant="action" onClick={() => setSegmentOpen(true)}>
                <Flex alignItems="center">
                  <Text color="brandLight">
                    <RiSkipForwardFill />
                  </Text>
                  <Text fontSize="xs" mx={1}>
                    {field?.config?.andFields?.length > 0
                      ? "Edit skip conditions"
                      : "Add skip conditions"}
                  </Text>
                </Flex>
              </Item>
              <Item m={1} variant="ghost" onClick={props.onDelete}>
                <Flex alignItems="center">
                  <Text color="brandLight">
                    <TrashIcon />
                  </Text>
                  <Text fontSize="xs" mx={1}>
                    Remove Step
                  </Text>
                </Flex>
              </Item>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
PlaybookStep.propTypes = {
  stepType: PropTypes.string,
  index: PropTypes.number,
  step: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
export const EventButton = (props) => {
  return (
    <Flex
      sx={{ cursor: "pointer", width: "200px" }}
      m={2}
      p={2}
      variant="box"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      bg={props.selected ? "gray.100" : ""}
      onClick={props.onClick}
    >
      <Flex alignItems="center" justifyContent="center">
        <props.icon size={30} color={theme.colors.primary} />
        <Text variant="h5" mx={1}>
          {props.title}
        </Text>
      </Flex>
      <Text variant="muted">{props.subtitle}</Text>
    </Flex>
  );
};
EventButton.propTypes = {
  subtitle: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
const ActionStepConfig = (props) => {
  const { stepIndex, actionIndex, values, setFieldValue, onUpdateSchema } =
    props;
  const [quickActionValid, setQuickActionValid] = useState(true);
  const step = values && values.steps && values.steps[stepIndex];
  const field = step && step.actions && step.actions[actionIndex];
  const fieldName = `steps[${stepIndex}].actions[${actionIndex}]`;

  return (
    <Modal
      title={
        <Flex>
          {actionOptions.find((obj) => obj.value === field?.actionType)
            ?.label || ""}
          <Text mx={1}>Configuration</Text>
        </Flex>
      }
      open={props.open || false}
      width="700px"
      onDismiss={() => {
        props.onDismiss();
      }}
    >
      <Flex px={4} py={2} flexDirection="column">
        {step && step.stepType === "step" && (
          <CustomFormGroup
            type="list"
            label="Action"
            name={`${fieldName}.actionType`}
            value={field?.actionType}
            options={actionOptions}
            onChange={(name, target) => {
              setFieldValue(`${fieldName}.actionType`, target.value);
              setFieldValue(
                `${fieldName}.config`,
                getConfigDefaults(target?.value)
              );
            }}
          />
        )}
        {step && step.stepType === "delay" ? (
          <Flex alignItems="center">
            <Text mx={2} mb={3} variant="muted">
              Delay/pause the playbook for
            </Text>
            <CustomFormGroup
              width="50px"
              name={`steps[${stepIndex}].config.delay`}
              value={values.steps[stepIndex].config.delay}
              onChange={(name, value) => {
                setFieldValue(name, value);
              }}
            />
            <Text mx={2} mb={3} variant="muted">
              days
            </Text>
          </Flex>
        ) : (
          <>
            <Label>Configuration</Label>
            <Flex p={3} flexDirection="column" variant="box">
              {!field?.actionType && (
                <Text m={4} variant="muted">
                  Action has not been selected
                </Text>
              )}
              {field?.actionType === "in_app_notification" && (
                <AlertForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "task_create" && (
                <TaskForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "assign_owner" && (
                <AssignOwnerForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "email" && (
                <EmailForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "slack" && (
                <SlackForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "webhook" && (
                <WebhookForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "ask_survey" && (
                <SelectSurveyForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "tags" && (
                <TagForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "playbook" && (
                <WorkflowForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              {field?.actionType === "in_app_banner" && (
                <BannerForm
                  fieldName={fieldName}
                  field={field}
                  {...props}
                  onError={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, false);
                    quickActionValid && setQuickActionValid(false);
                  }}
                  onValid={() => {
                    setFieldValue(`steps[${stepIndex}].config.valid`, true);
                    !quickActionValid && setQuickActionValid(true);
                  }}
                />
              )}
              <Flex
                flex={1}
                my={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                {field?.actionType === "task_create" && (
                  <Flex flex={1} alignItems="center" mr={3}>
                    <Box>
                      <CustomFormGroup
                        noMargin
                        type="switch"
                        label=""
                        name={`steps[${stepIndex}].config.requiresComplete`}
                        value={values.steps[stepIndex].config.requiresComplete}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    </Box>
                    <Text ml={2} variant="muted" sx={{ whiteSpace: "nowrap" }}>
                      Pause Playbook Until Complete
                    </Text>
                  </Flex>
                )}
                {field?.actionType && (
                  <PrimaryButton
                    onClick={() => quickActionValid && props.onDismiss()}
                  >
                    Add
                  </PrimaryButton>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Modal>
  );
};
ActionStepConfig.propTypes = {
  stepIndex: PropTypes.number,
  open: PropTypes.bool,
  actionIndex: PropTypes.number,
  values: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onUpdateSchema: PropTypes.func.isRequired,
};
