import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Text, Flex, Link, Image } from "rebass";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import { useTheme } from "emotion-theming";
import Loader from "Components/Common/Loader";
import { getEntityScores } from "hooks/getEntityScores";
import PieGraph from "Components/Common/Graphs/PieGraph";
import StackedBarGraph from "Components/Common/Graphs/StackedBarGraph";
import { getData } from "hooks/getData";
import { MODEL_TYPES } from "consts";
import { getHealthscore } from "hooks/getHealthscore";
import { getEntityScoreDetail } from "hooks/getEntityScoreDetail";
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "Components/Common/Icons";
import "react-vertical-timeline-component/style.min.css";
import "../../Activity/timeline.css";
import StackedLineGraph from "Components/Common/Graphs/StackedLineGraph";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
import HealthScoreIcon from "../Company/HealthScoreIcon";
import "assets/styles/react-tabs.css";
import ConversionDashboard from "../Conversion";
import {
  CalculationDescription,
  processSatisfactionData,
  watermarkSeries,
} from "../../Common/Satisfaction/SatisfactionDefaults";
import SatisfactionResponses from "../../Common/Satisfaction/SatisfactionResponses";
import SatisfactionTable from "../../Common/Satisfaction/SatisfactionTable";

const _ = require("lodash");

function CompanyHealth(props) {
  const theme = useTheme();

  const { data: detailData } = getData(MODEL_TYPES[props.parentType], props.id);
  const { data: healthScoreData } = getHealthscore(
    detailData?.company?.details?.healthscoreId
  );

  console.log(healthScoreData);

  const { loading, data } = getEntityScores(
    props.id,
    props.parentType,
    "health_score"
  );
  if (loading) return <Loader />;

  const detail = detailData?.company?.details;
  const currentScore = _.last(data?.scores);

  let graphData = data?.scores?.map((d) => {
    return {
      date: d.createdAt,
      product:
        _.sum(
          _(d.config?.filter((a) => a.type === "product") || [])
            .map((objs, key) => Number(objs.value))
            .value()
        ) || 0,
      sentiment:
        _.sum(
          _(d.config?.filter((a) => a.type === "sentiment") || [])
            .map((objs, key) => Number(objs.value))
            .value()
        ) || 0,
      satisfaction:
        _.sum(
          _(d.config?.filter((a) => a.type === "satisfaction") || [])
            .map((objs, key) => Number(objs.value))
            .value()
        ) || 0,
    };
  });

  const value1 = _.last(graphData)?.product || 0;
  const value2 = _.last(graphData)?.sentiment || 0;
  const value3 = _.last(graphData)?.satisfaction || 0;
  const remaining = 100 - value1 - value2 - value3;

  const healthscoreGood =
    healthScoreData?.healthscore?.config?.healthScoreSplit[1] || 70;

  return detail?.status?.name == "Trial" ? (
    <Flex flexDirection={"column"}>
      <ConversionDashboard
        id={props.id}
        parentType={props.parentType}
        data={[
          {
            customerFit: detail?.conversionFitScore,
            adoption: detail?.conversionAdoptionScore,
          },
        ]}
      />

      <Flex flex={1} m={3} flexDirection="column">
        <Tabs>
          <TabList>
            <Tab key={"Sentiment"}>
              <Flex alignItems="center" justifyContent="flex-end">
                Sentiment
              </Flex>
            </Tab>
            <Tab>
              <Flex alignItems="center" justifyContent="flex-end">
                Satisfaction
              </Flex>
            </Tab>
          </TabList>
          <TabPanel key={"sentiment"} className="health-react-tabs__tab-panel">
            <Flex flex={1} m={3} flexDirection="column">
              <Text variant="h3" px={3}>
                Sentiment
              </Text>

              <SentimentBreakdown {...props} scoreType="sentiment" />
            </Flex>
          </TabPanel>

          <TabPanel
            key={"Satisfaction"}
            className="health-react-tabs__tab-panel"
          >
            <Flex flex={1} m={3} flexDirection="column">
              <Tabs width="100%">
                <TabList>
                  <Tab key={"NPS"}>
                    <Flex alignItems="center">NPS</Flex>
                  </Tab>
                  <Tab key={"CSAT"}>
                    <Flex alignItems="center">CSAT</Flex>
                  </Tab>
                  <Tab key={"CES"}>
                    <Flex alignItems="center">CES</Flex>
                  </Tab>
                </TabList>
                <TabPanel>
                  <SatifactionBreakdown
                    {...props}
                    scoreType="nps"
                    percentage
                    total={undefined}
                  />
                </TabPanel>
                <TabPanel>
                  <SatifactionBreakdown
                    {...props}
                    scoreType="csat"
                    percentage
                    total={undefined}
                  />
                </TabPanel>
                <TabPanel>
                  <SatifactionBreakdown
                    {...props}
                    scoreType="ces"
                    total={undefined}
                  />
                </TabPanel>
              </Tabs>
            </Flex>
          </TabPanel>
        </Tabs>
      </Flex>
    </Flex>
  ) : (
    <Flex flexGow={1} flex={1}>
      <Flex
        flex={1}
        flexGrow={1}
        bg="white"
        flexDirection="column"
        onClick={() => {}}
      >
        <Text variant="h2">
          Health Breakdown - {healthScoreData?.healthscore?.name}
        </Text>
        {!healthScoreData?.healthscore ? (
          <Flex
            p={4}
            flexDirection="column"
            bg="gray.100"
            alignItems="center"
            justifyContent="center"
            sx={{ borderRadius: "medium" }}
          >
            <Flex
              sx={{
                height: "450px",
                boxShadow: "large",
                borderRadius: "medium",
              }}
              bg="white"
              p={4}
            >
              <Image
                src={require("assets/images/HealthEmptyState.png")}
                sx={{ height: "400px", objectFit: "contain" }}
              />
            </Flex>
            <Box my={3} />
            <Text variant="muted">Health has not been calculated yet.</Text>
          </Flex>
        ) : (
          <>
            <Flex my={3} flex={1} alignItems="center">
              <Flex sx={{ position: "relative" }} mr={4}>
                <DonutGraph
                  noLegend
                  data={[
                    {
                      label: "Product",
                      value: value1,
                      color: theme.colors.health.product,
                    },
                    {
                      label: "Sentiment",
                      value: value2,
                      color: theme.colors.health.sentiment,
                    },
                    {
                      label: "Satisfaction",
                      value: value3,
                      color: theme.colors.health.satisfaction,
                    },
                    {
                      label: "Remaining",
                      value: remaining,
                      color: theme.colors.gray[200],
                    },
                  ]}
                  width={200}
                />
                <Flex
                  sx={{ position: "absolute", top: "70px", left: "80px" }}
                  flex={1}
                  alignItems="center"
                  flexDirection="column"
                >
                  <Text textAlign="center" variant="label">
                    Today
                  </Text>
                  <Text textAlign="center" variant="h1" color="body">
                    {currentScore?.value}
                  </Text>
                </Flex>
              </Flex>
              <Flex ml={4}>
                {graphData && (
                  <Box>
                    <StackedBarGraph
                      days={90}
                      marker={[
                        { min: healthscoreGood, max: 100, color: "#D9F3CD4f" },
                        { min: 30, max: healthscoreGood, color: "transparent" },
                        { min: 0, max: 30, color: "#F8D5D882" },
                      ]}
                      series={[
                        graphData?.map((d) => {
                          return {
                            name: d.date,
                            count: d.product,
                            color: theme.colors.health.product,
                          };
                        }),
                        graphData?.map((d) => {
                          return {
                            name: d.date,
                            count: d.satisfaction,
                            color: theme.colors.health.satisfaction,
                          };
                        }),
                        graphData?.map((d) => {
                          return {
                            name: d.date,
                            count: d.sentiment,
                            color: theme.colors.health.sentiment,
                          };
                        }),
                      ]}
                      height={200}
                    />
                    <Text textAlign="center" varient="h4">
                      Health over time
                    </Text>
                  </Box>
                )}
              </Flex>
            </Flex>
            <Flex>
              <Tabs width="100%" direction="rtl">
                <TabList>
                  <Tab key={"Product"}>
                    <Flex alignItems="center" justifyContent="flex-end">
                      {getScore(
                        "product",
                        currentScore?.config,
                        theme.colors.health.product,
                        "Product"
                      )}
                    </Flex>
                  </Tab>
                  {currentScore?.config?.find((t) => t.type === "sentiment")
                    ?.total > 0 && (
                    <Tab key={"sentiment"}>
                      <Flex alignItems="center" justifyContent="flex-end">
                        {getScore(
                          "sentiment",
                          currentScore?.config,
                          theme.colors.health.sentiment,
                          "Sentiment"
                        )}
                      </Flex>
                    </Tab>
                  )}
                  {healthScoreData?.healthscore?.config?.satisfactionTotal >
                    0 && (
                    <Tab key={"satisfaction"}>
                      <Flex alignItems="center" justifyContent="flex-end">
                        {getScore(
                          "satisfaction",
                          currentScore?.config,
                          theme.colors.health.satisfaction,
                          "Satisfaction"
                        )}
                      </Flex>
                    </Tab>
                  )}
                </TabList>
                <TabPanel
                  key={"product"}
                  className="health-react-tabs__tab-panel"
                >
                  <Flex m={3}>
                    <Text variant="h3" px={3}>
                      Product
                    </Text>
                    {currentScore?.config && (
                      <Flex m={3}>
                        <BreakdownPieChart
                          name="product"
                          items={currentScore?.config}
                        />
                      </Flex>
                    )}
                  </Flex>
                </TabPanel>
                {healthScoreData?.healthscore?.config?.sentimentTotal > 0 && (
                  <TabPanel
                    key={"sentiment"}
                    className="health-react-tabs__tab-panel"
                  >
                    <Flex flex={1} m={3} flexDirection="column">
                      <Text variant="h3" px={3}>
                        Sentiment
                      </Text>

                      <SentimentBreakdown {...props} scoreType="sentiment" />
                    </Flex>
                  </TabPanel>
                )}
                {healthScoreData?.healthscore?.config?.satisfactionTotal >
                  0 && (
                  <TabPanel
                    key="satisfaction"
                    className="health-react-tabs__tab-panel"
                  >
                    <Flex m={3} width="100%" flexDirection="column">
                      <Text variant="h3" px={3}>
                        Satisfaction
                      </Text>
                      <Box width="100%" m={3}>
                        <Tabs width="100%">
                          <TabList>
                            {currentScore?.config.find((f) => f.key === "NPS")
                              .total > 0 && (
                              <Tab key={"NPS"}>
                                <Flex alignItems="center">
                                  {getScore(
                                    "NPS",
                                    currentScore?.config,
                                    undefined,
                                    "NPS"
                                  )}
                                </Flex>
                              </Tab>
                            )}
                            {currentScore?.config.find((f) => f.key === "CSAT")
                              .total > 0 && (
                              <Tab key={"CSAT"}>
                                <Flex alignItems="center">
                                  {getScore(
                                    "CSAT",
                                    currentScore?.config,
                                    undefined,
                                    "CSAT"
                                  )}
                                </Flex>
                              </Tab>
                            )}
                            {currentScore?.config.find((f) => f.key === "CES")
                              .total > 0 && (
                              <Tab key={"CES"}>
                                <Flex alignItems="center">
                                  {getScore(
                                    "CES",
                                    currentScore?.config,
                                    undefined,
                                    "CES"
                                  )}
                                </Flex>
                              </Tab>
                            )}
                          </TabList>
                          {currentScore?.config.find((f) => f.key === "NPS")
                            .total > 0 && (
                            <TabPanel>
                              <SatifactionBreakdown
                                {...props}
                                scoreType="nps"
                                percentage
                                total={currentScore?.config
                                  ?.find((a) => a.key === "NPS")
                                  ?.total.toFixed(1)}
                              />
                            </TabPanel>
                          )}
                          {currentScore?.config.find((f) => f.key === "CSAT")
                            .total > 0 && (
                            <TabPanel>
                              <SatifactionBreakdown
                                {...props}
                                scoreType="csat"
                                percentage
                                total={
                                  currentScore?.config
                                    ?.find((a) => a.key === "CSAT")
                                    ?.total.toFixed(1) || 0
                                }
                              />
                            </TabPanel>
                          )}
                          {currentScore?.config.find((f) => f.key === "CES")
                            .total > 0 && (
                            <TabPanel>
                              <SatifactionBreakdown
                                {...props}
                                scoreType="ces"
                                total={
                                  currentScore?.config
                                    ?.find((a) => a.key === "CES")
                                    ?.total.toFixed(1) || 0
                                }
                              />
                            </TabPanel>
                          )}
                        </Tabs>
                      </Box>
                    </Flex>
                  </TabPanel>
                )}
              </Tabs>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default CompanyHealth;

const getScore = (name, items, color, title) => {
  const value = _.sum(
    _(items?.filter((a) => a.type === name || a.key === name) || [])
      .map((objs, key) => Number(objs.value))
      .value()
  );
  const total = _.sum(
    _(items?.filter((a) => a.type === name || a.key === name) || [])
      .map((objs, key) => Number(objs.total))
      .value()
  );

  return (
    <Box color="text">
      <Flex>
        {color && <Box mx={2} width={20} height={20} bg={color} />}
        <Box>
          <Text mx={1} color="text" fontSize="xs">
            {title}
          </Text>
          <Flex alignItems="center">
            <Text color="brandLight" fontWeight="bold" fontSize="sm">
              {value.toFixed(1)}
            </Text>
            <Text fontSize="sm" fontWeight="bold" mx={1}>
              /
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              {total.toFixed(1)}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
const BreakdownPieChart = (props) => {
  const theme = useTheme();
  const total = _.sum(
    props.items?.filter((f) => f.type === props.name).map((v) => v.value) || 0
  );
  const overallTotal = _.sum(
    props.items?.filter((f) => f.type === props.name).map((v) => v.total) || 0
  );
  const remaining = overallTotal - total;

  const data = [
    {
      label: undefined,
      value: remaining,
      color: theme.colors.gray[200],
    },
  ].concat(
    props.items
      ?.filter((f) => f.type === props.name)
      .map((c, i) => {
        return {
          label: (
            <Flex alignItems="center">
              <Text fontSize="md" mx={2}>
                {`${c.key} ${c.value} / ${props.total}`}
              </Text>
              <HealthScoreIcon value={c.value} category={c.health} simple />
            </Flex>
          ),
          value: c.value,
          color: theme.colors.graph[i],
        };
      })
  );

  return (
    <Flex my={3} p={2}>
      <Box>
        <PieGraph data={data} width={200} noLegend />
      </Box>
      <Flex flexDirection="column" mx={3}>
        {props.items
          ?.filter((f) => f.type === props.name)
          .map((c, i) => {
            return (
              <Flex key={c.key} alignItems="center" my={2}>
                <Box mx={2} width={20} height={20} bg={theme.colors.graph[i]} />
                <Text fontSize="md">{`${c.key} ${c.value} / ${c.total}`}</Text>
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};
const SentimentBreakdown = (props) => {
  const theme = useTheme();
  const days = 365;
  const { data, loading } = getEntityScores(
    props.id,
    props.parentType,
    props.scoreType,
    days
  );

  let graphData = [];
  const dates = [...Array(days).keys()]
    .map((i) => moment().add(-i, "day"))
    .reverse();

  let prevTotal = _.maxBy(
    data?.scores
      .filter((c) =>
        moment(c.createdAt).isBefore(moment().add(-90, "day"), "day")
      )
      .map((d) => {
        return { ...d, createdAt: moment(d.createdAt).toDate() };
      }),
    "createdAt"
  )?.value;

  graphData = dates?.map((date) => {
    const total = _.last(
      data?.scores?.filter((c) => date.isSame(moment(c.createdAt), "day"))
    )?.value;

    const item = {
      date: moment(date),
      count: total || prevTotal,
    };
    prevTotal = total || prevTotal;
    return item;
  });
  const lastScore = data && _.last(data.scores);

  return (
    <Flex width="100%" flex={1} flexDirection="column" m={3}>
      <Flex alignItems="center" my={4}>
        <Flex
          width={290}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Text variant="h1">
            {lastScore?.value || 0}
            {props.percentage && "%"}
          </Text>
          <Text
            variant="label"
            color={
              lastScore?.valueDiff < 0
                ? "red"
                : lastScore?.valueDiff > 0 && "green"
            }
          >
            <Flex>
              {lastScore?.valueDiff === 0 ? (
                <MinusIcon />
              ) : lastScore?.valueDiff < 0 ? (
                <ArrowDownIcon />
              ) : (
                lastScore?.valueDiff > 0 && <ArrowUpIcon />
              )}
              <Text>
                {lastScore?.valueDiff || 0}
                {props.percentage && "%"}
              </Text>
            </Flex>
          </Text>

          <Text variant="muted">{days} days</Text>
        </Flex>
        {graphData && (
          <Flex width="100%">
            <StackedLineGraph
              marker={[
                { min: 8, max: 10, color: "#D9F3CD4f" },
                { min: 5, max: 8, color: "transparent" },
                { min: 0, max: 5, color: "#F8D5D882" },
              ]}
              days={90}
              series={[{ name: "Sentiment", data: graphData }]}
              height={200}
              yMin={0}
              yMax={10}
              noLegend
            />
          </Flex>
        )}
      </Flex>
      <Flex
        flex={1}
        flexDirection="column"
        mt={4}
        bg="gray.100"
        variant="box"
        p={4}
      >
        <SatisfactionResponses
          data={data?.scores}
          scoreType={props.scoreType}
        />
      </Flex>
    </Flex>
  );
};
const SatifactionBreakdown = (props) => {
  const days = 365;
  const { data: scoreData } = getEntityScores(
    props.id,
    props.parentType,
    props.scoreType,
    days
  );
  const { loading, data, error } = getEntityScoreDetail(
    props.id,
    props.parentType,
    props.scoreType,
    days
  );

  let graphData = [];
  const dates = [...Array(days).keys()]
    .map((i) => moment().add(-i, "day"))
    .reverse();
  let prevTotal = _.maxBy(
    scoreData?.scores
      .filter((c) =>
        moment(c.createdAt).isBefore(moment().add(-1 * days, "day"), "day")
      )
      .map((d) => {
        return { ...d, createdAt: moment(d.createdAt).toDate() };
      }),
    "createdAt"
  )?.value;
  graphData = dates?.map((date) => {
    const total = _.last(
      scoreData?.scores?.filter((c) => date.isSame(moment(c.createdAt), "day"))
    )?.value;

    const item = {
      date: moment(date),
      count: total || prevTotal,
    };
    prevTotal = total || prevTotal;
    return item;
  });

  const lastScore = scoreData && _.last(scoreData.scores);

  const rescale = (tick, type) => {
    const total = props.total || 0;
    switch (type) {
      case "nps":
        return tick < 0 ? 0 : tick > 30 ? total : total / 2;
      case "csat":
        return tick < 20 ? 0 : tick > 60 ? total : total / 2;
      case "ces":
        return tick > 4 ? 0 : tick <= 2 ? total : total / 2;
      default:
        return 0;
    }
  };

  const yMin = (type) => {
    switch (type) {
      case "nps":
        return -100;
      case "csat":
        return 0;
      case "ces":
        return 0;
      default:
        return null;
    }
  };
  const yMax = (type) => {
    switch (type) {
      case "nps":
        return 100;
      case "csat":
        return 100;
      case "ces":
        return 10;
      default:
        return null;
    }
  };

  let { processedData: breakdownData, totalResponses } =
    processSatisfactionData(data?.endUserScores, props.scoreType, undefined);

  return (
    <Flex width="100%" flexDirection="column" mt={2}>
      <Flex alignItems="center" my={4}>
        <Flex
          width={250}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex alignItems="center">
            <Text variant="h1">
              {lastScore?.value || 0}
              {props.percentage && "%"}
            </Text>
            <Text
              variant="label"
              color={
                lastScore?.valueDiff < 0
                  ? "red"
                  : lastScore?.valueDiff > 0 && "green"
              }
            >
              <Flex>
                {lastScore?.valueDiff === 0 ? (
                  <MinusIcon />
                ) : lastScore?.valueDiff < 0 ? (
                  <ArrowDownIcon />
                ) : (
                  lastScore?.valueDiff > 0 && <ArrowUpIcon />
                )}
                <Text>
                  {lastScore?.valueDiff || 0}
                  {props.percentage && "%"}
                </Text>
              </Flex>
            </Text>
          </Flex>
          <Box mt={2} mb={3}>
            <Text variant="muted">{days} days</Text>
          </Box>
        </Flex>
        {graphData && graphData.length > 0 && (
          <Flex flexDirection="column">
            <StackedLineGraph
              marker={watermarkSeries(props.scoreType)}
              days={days}
              yMin={yMin(props.scoreType)}
              yMax={yMax(props.scoreType)}
              yRescale={(tick) =>
                props.total ? rescale(tick, props.scoreType) : ""
              }
              series={[{ name: "", data: graphData }]}
              height={200}
              noLegend
            />
            <CalculationDescription
              data={scoreData?.scores}
              scoreType={props.scoreType}
            />
          </Flex>
        )}
      </Flex>
      <Flex flex={1} width="100%" mt={2} bg="gray.100" variant="box">
        <Flex
          px={4}
          pt={4}
          flexDirection="column"
          sx={{
            borderRightStyle: "solid",
            borderRightWidth: 1,
            borderRightColor: "accent",
          }}
        >
          <SatisfactionTable data={breakdownData} loading={loading} />
        </Flex>
        <Box width="50%" px={4} pt={4}>
          <SatisfactionResponses
            data={data?.endUserScores}
            scoreType={props.scoreType}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
