import React, { useState } from "react";
import { withRouter, useParams, useHistory, Prompt } from "react-router-dom";
import { Flex, Text, Box, Button } from "rebass";
import "react-tabs/style/react-tabs.css";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { useToasts } from "react-toast-notifications";
import ShowError, { ErrorParams } from "Components/Common/Notifications/Error";
import ShowSuccess, {
  SuccessParams,
} from "Components/Common/Notifications/Success";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { MODEL_TYPES, TriggerActionTypeOptions, TRIGGER_CONFIG } from "consts";
import { getTags } from "hooks/getTags";
import { getEventGroups } from "hooks/getEventGroups";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import { getSegments } from "hooks/getSegments";
import { SaveIcon, TrashIcon } from "Components/Common/Icons";
import Label from "Components/Common/Forms/Label";
import RadioGroup from "Components/Common/RadioGroup";
import SegmentConfigForm from "Components/Filter/Segment/SegmentConfigForm";
import WebhookForm from "./WebhookForm";
import AssignOwnerForm from "./AssignOwnerForm";
import EmailForm from "./EmailForm";
import AlertForm from "./AlertForm";
import TaskForm from "./TaskForm";
import SlackForm from "./SlackForm";
import TriggersQuery from "../../../../graphql/queries/Triggers.gql";
import TriggerMutation from "../../../../graphql/mutations/Trigger.gql";
import TriggerDeleteMutation from "../../../../graphql/mutations/TriggerDelete.gql";
import TriggerQuery from "../../../../graphql/queries/Trigger.gql";
import TypeSchemaQuery from "../../../../graphql/queries/TypeSchemas.gql";
import TriggerFilterList from "./TriggerFilterList";
import { FilterOffIcon, FilterOnIcon, TagIcon } from "../../../Common/Icons";

import TriggerPage from "./";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import { RiCheckboxBlankCircleLine, RiRadioButtonFill } from "react-icons/ri";
import WorkflowSelectForm from "./WorkflowSelectForm";
import SelectSurveyForm from "./SelectSurveyForm";
import { getConfigDefaults, ModelTypeIcon } from "../../../../consts";
import SegmentSelect from "../../../Filter/Segment/SegmentSelect";
import _ from "lodash";
import TagForm from "./TagForm";
import BannerForm from "./BannerForm";

const actionOptions = TRIGGER_CONFIG.Actions?.filter(
  (o) => o.id !== "playbook"
).map((r) => {
  return {
    value: r.id,
    label: (
      <Flex alignItems={"center"}>
        <Box color="brandLight">{r.icon}</Box>
        <Box mx={2}>
          {r.name}
          <Text variant="muted" fontSize={"xxs"}>
            {r.label}
          </Text>
        </Box>
      </Flex>
    ),
  };
});

function TriggerForm(props) {
  const { id } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();
  const [quickActionValid, setQuickActionValid] = useState(true);
  const [exitSegmentOpen, setExitSegmentOpen] = useState(false);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      name: Yup.string().required("Required"),
      eventType: Yup.string().required("Required"),
      actionType: Yup.string().required("Required"),
      config: Yup.object().shape({}),
    })
  );

  const { data: accountTags } = getTags("tags");
  const { data: eventData } = getEventGroups();
  const [triggerSegmentOpen, setTriggerSegmentOpen] = useState(false);
  const {
    loading,
    data: typeSchema,
    error,
    refetch,
  } = useQuery(TypeSchemaQuery, {});

  let dataModels = typeSchema?.typeSchemas?.filter(
    (m) => m.typeName === MODEL_TYPES.Company.name && m.name !== "lastSeenAt"
  );

  const fields =
    _.flatten(
      dataModels?.map((model) =>
        model.attributes
          .filter((m) => m.type == "date")
          .map((m) => {
            return {
              value: `${m.name}-${model.id}`,
              field: m.name,
              label: (
                <Flex alignItems="center">
                  <Text mx={2}>{ModelTypeIcon(model.typeName, 16)}</Text>
                  {m.displayName}
                </Flex>
              ),
              type: m.type,
              entity: model.id,
            };
          })
      )
    ) || [];

  fields.push({
    value: `custom`,
    field: "custom",
    label: undefined,
    type: "custom",
    entity: "custom",
  });

  const { data } = useQuery(TriggerQuery, {
    variables: { id },
    skip: !id || id === "new",
  });
  const [save] = useMutation(TriggerMutation, {
    refetchQueries: [{ query: TriggersQuery }],
  });
  const [destroy] = useMutation(TriggerDeleteMutation, {
    refetchQueries: [{ query: TriggersQuery }],
  });

  const trigger = data?.trigger && {
    ...data?.trigger,
    config: data?.trigger?.config || {},
    action: TRIGGER_CONFIG.Events.find((i) => i.id === data?.trigger?.eventType)
      ?.type,
  };

  const tags = accountTags?.tags?.map((t) => {
    return { value: t, label: t };
  });

  const updateValidationSchema = (actionType) => {
    let schema = Yup.object().shape({
      name: Yup.string().required("Required"),
      eventType: Yup.string().required("Required"),
      actionType: Yup.string().required("Required"),
      config: Yup.object().shape({}),
    });

    switch (actionType) {
      case "tags":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape({
            tags: Yup.array().required("Required"),
          }),
        });
        break;
      case "ask_survey":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape({
            to: Yup.array().required("Required"),
            surveyQuestionId: Yup.string().required("Required"),
          }),
        });
        break;
      case "slack":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape({
            title: Yup.string().required("Required"),
            url: Yup.string().required("Required"),
          }),
        });
        break;
      case "email":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape(
            {
              emailTemplateId: Yup.string().required("Required"),
              to: Yup.array().required("Required"),
              from: Yup.string().required("Required"),
              fromEmail: Yup.string().when("from", {
                is: (value) => value == "custom",
                then: Yup.string()
                  .email("Not a valid email")
                  .required("Required"),
                otherwise: Yup.string(),
              }),
              toAccountOwner: Yup.boolean().when("to", {
                is: (value) => value == "none",
                then: Yup.boolean()
                  .required("Required")
                  .oneOf([true], "Required"),
                otherwise: Yup.boolean(),
              }),
            },
            [
              ["emailTemplateId"],
              ["from", "fromEmail"],
              ["to", "toAccountOwner"],
            ]
          ),
        });
        break;
      case "in_app_notification":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape({
            title: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            assignToOwner: Yup.boolean()
              .required("Required")
              .oneOf([true], "Required"),
          }),
        });
        break;
      case "task_create":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape({
            title: Yup.string().required("Required"),
            taskCategoryId: Yup.string().required("Required"),
          }),
        });
        break;
      case "webhook":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape({
            title: Yup.string().required("Required"),
            url: Yup.string().url().required("Required"),
          }),
        });
        break;
      case "assign_owner":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape({
            ownerUserId: Yup.string().required("Required"),
          }),
        });
        break;
      case "is_app_banner":
        schema = Yup.object().shape({
          name: Yup.string().required("Required"),
          eventType: Yup.string().required("Required"),
          config: Yup.object().shape({
            title: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            to: Yup.array().required("Required"),
          }),
        });
        break;
    }

    setValidationSchema(schema);
  };
  const eventGroups = eventData?.eventGroups?.map((e) => {
    return {
      value: e.id,
      label: e.name,
      time: undefined,
    };
  });
  return (
    <TriggerPage>
      <Formik
        enableReinitialize
        initialValues={
          trigger || {
            name: "",
            eventType: "",
            actionType: "playbook",
            active: true,
            config: {},
          }
        }
        autoComplete={false}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          if (!values.quickAction || quickActionValid) {
            save({
              variables: {
                ...values,
                id: values.id ? values.id : null,
                triggerOnce:
                  values.action !== "Recurring" && !values.config?.time
                    ? values.triggerOnce
                    : true,
              },
            }).then(
              (response) => {
                const result = response.data?.trigger;
                if (result?.errors) {
                  actions.setErrors(result?.errors);
                  addToast(
                    <ShowError message={result?.errors && result?.errors[0]} />,
                    ErrorParams
                  );
                } else {
                  actions.resetForm({
                    values: response.data?.trigger?.trigger,
                  });
                  addToast(<ShowSuccess message="Saved!" />, SuccessParams);
                  history.push("/settings/product/automations");
                }
              },
              (error) => {
                addToast(<ShowError message="Save error!" />, ErrorParams);
              }
            );
          }
        }}
      >
        {(formProps) => {
          const {
            values,
            errors,
            setFieldValue,
            handleBlur,
            handleSubmit,
            dirty,
          } = formProps;
          const typeOptions =
            values.action === "Event"
              ? eventGroups
              : TRIGGER_CONFIG.Events?.filter(
                  (e) => e.type === values.action
                ).map((r) => {
                  return {
                    value: r.id,
                    label: r.name,
                    ...r,
                  };
                });
          const selectedAction = TriggerActionTypeOptions?.find(
            (obj) => obj.type === values.action
          );

          const eventTypeValue =
            values.action == "Event"
              ? values.config?.eventGroupId
              : values.eventType;

          const options = TriggerActionTypeOptions?.map((item) => {
            return {
              icon: item.icon,
              title: item.title,
              description: item.subtitle,
              value: item.type,
              time: item.time,
            };
          });
          console.log(values);
          return (
            <Flex flex={1} flexDirection="column">
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex w="100%" flexDirection="column">
                <Flex flexDirection="column" w="100%">
                  <Flex justifyContent="flex-end">
                    <DeleteButton
                      callback={destroy}
                      refetch={() => {}}
                      onCancel={() => {
                        history.goBack();
                      }}
                      id={id}
                      name="Trigger"
                    />

                    <PrimaryButton
                      leftIcon={<SaveIcon />}
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </PrimaryButton>
                  </Flex>

                  <CustomFormGroup
                    placeholder="Name"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.name && errors.name}
                  />
                  <Box my={2} />
                  <Flex justifyContent="space-between">
                    <Box>
                      <Label>Trigger</Label>
                      <Text my={2} variant="muted">
                        This automation will trigger the selected playbook,
                        workflow or quick action.
                      </Text>
                    </Box>
                    {values.action && (
                      <Button
                        variant="ghost"
                        my={0}
                        py={1}
                        onClick={() => {
                          setFieldValue("action", undefined);
                        }}
                      >
                        <TrashIcon /> Clear
                      </Button>
                    )}
                  </Flex>
                  <Flex variant="box" p={3} flex={1} flexDirection="column">
                    <Flex flex={1}>
                      <TriggerFilterList
                        label="Type"
                        name="action"
                        value={values.action}
                        options={options}
                        onChange={(name, value) => {
                          setFieldValue("eventType", "");
                          setFieldValue("config.eventGroupId", "");
                          setFieldValue(name, value?.value);
                        }}
                      />
                      {selectedAction && (
                        <Flex>
                          <Box mx={3}>
                            <CustomFormGroup
                              key={eventTypeValue}
                              placeholder={
                                values.action === "Recurring"
                                  ? `${selectedAction?.title} Period`
                                  : `${selectedAction?.title}`
                              }
                              label={
                                values.action === "Recurring"
                                  ? `${selectedAction?.title} Period`
                                  : `${selectedAction?.title} Event`
                              }
                              name="eventType"
                              value={eventTypeValue}
                              type="list"
                              width={325}
                              onChange={(name, value) => {
                                if (values.action === "Event") {
                                  setFieldValue(
                                    "config.eventGroupId",
                                    value?.value
                                  );
                                  setFieldValue(name, "event_created");
                                } else {
                                  setFieldValue(name, value?.value);
                                }
                                setFieldValue("config.time", value?.time);
                              }}
                              options={typeOptions}
                              helperText={errors.eventType && errors.eventType}
                            />
                          </Box>

                          {values.action == "Tag" && (
                            <Box>
                              <CustomFormGroup
                                placeholder="All tags"
                                label={"Tag"}
                                name="config.tagValue"
                                value={values.config?.tagValue}
                                type="list"
                                width={250}
                                onChange={(name, value) => {
                                  setFieldValue(name, value?.value);
                                }}
                                options={tags}
                                helperText={
                                  errors.config?.tagValue &&
                                  errors.config?.tagValue
                                }
                              />
                            </Box>
                          )}

                          {values.config?.time && (
                            <Flex alignItems={"center"} flex={1}>
                              <Text variant={"muted"} mx={1}>
                                trigger
                              </Text>
                              <Box>
                                <CustomFormGroup
                                  placeholder="days"
                                  label=""
                                  noMargin
                                  width="50px"
                                  name="days"
                                  value={values.config?.time?.days}
                                  onChange={(name, value) => {
                                    setFieldValue("config.time.days", value);
                                  }}
                                  helperText={
                                    errors.config &&
                                    errors.config.time &&
                                    errors.config.time.days &&
                                    errors.config.time.days
                                  }
                                />
                              </Box>
                              <Text variant={"muted"} mx={1}>
                                days
                              </Text>
                              <Box>
                                {values.config.time.past &&
                                values.config.time.future ? (
                                  <CustomFormGroup
                                    placeholder="offset"
                                    label=""
                                    noMargin
                                    type={"list"}
                                    options={[
                                      { value: -1, label: "Before" },
                                      { value: 1, label: "After" },
                                    ]}
                                    width="100px"
                                    name="offset"
                                    value={values.config?.time?.offset}
                                    onChange={(name, value) => {
                                      setFieldValue(
                                        "config.time.offset",
                                        value?.value
                                      );
                                    }}
                                    helperText={
                                      errors.config &&
                                      errors.config.time &&
                                      errors.config.days &&
                                      errors.config.days
                                    }
                                  />
                                ) : values.config.time.past ? (
                                  <Text variant={"muted"} mx={1}>
                                    before
                                  </Text>
                                ) : (
                                  values.config.time.future && (
                                    <Text variant={"muted"}>after</Text>
                                  )
                                )}
                              </Box>
                            </Flex>
                          )}
                          {values.eventType == "reoccuring_custom" ? (
                            <Flex alignItems={"center"}>
                              <Text variant={"muted"} mx={1}>
                                Trigger once every
                              </Text>
                              <CustomFormGroup
                                placeholder="days"
                                label=""
                                noMargin
                                width="50px"
                                name="days"
                                value={values.config?.days}
                                onChange={(name, value) => {
                                  setFieldValue("config.days", value);
                                }}
                                helperText={
                                  errors.config &&
                                  errors.config.days &&
                                  errors.config.days
                                }
                              />
                              <Text variant={"muted"} mx={1}>
                                days
                              </Text>
                            </Flex>
                          ) : (
                            values.action !== "Recurring" &&
                            !values.config?.time && (
                              <Box ml={3}>
                                <CustomFormGroup
                                  type="switch"
                                  placeholder="Trigger Once"
                                  label="Trigger Once"
                                  width="100px"
                                  name="triggerOnce"
                                  value={values.triggerOnce}
                                  onChange={setFieldValue}
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.triggerOnce && errors.triggerOnce
                                  }
                                />
                              </Box>
                            )
                          )}
                        </Flex>
                      )}
                    </Flex>

                    {values.action === "Recurring" && (
                      <Box>
                        <Label>Start From</Label>
                        <Flex alignItems="center">
                          <Box>
                            <RadioGroup
                              name={`config.recurringField`}
                              selectedOption={
                                fields.find(
                                  (o) =>
                                    o.field ===
                                      values.config?.recurringField?.field &&
                                    o.entity ===
                                      values.config?.recurringField?.entity
                                )?.value
                              }
                              options={fields}
                              onChange={(name, value) => {
                                setFieldValue(name, {
                                  field: value.field,
                                  entity: value.entity,
                                });
                              }}
                            />
                          </Box>
                          <Box ml={3}>
                            <CustomFormGroup
                              type="date"
                              placeholder=""
                              label=""
                              noMargin
                              width="100px"
                              name="config.recurringField.date"
                              value={values.config?.recurringField?.date}
                              onChange={setFieldValue}
                              onBlur={handleBlur}
                              helperText={
                                errors.config &&
                                errors.config.recurringField &&
                                errors.config.recurringField.date
                              }
                            />
                          </Box>
                        </Flex>
                      </Box>
                    )}
                  </Flex>
                  <Flex justifyContent="space-between" my={4}>
                    <Box>
                      <Label>Entry Criteria</Label>
                      <Text m={2} variant="muted">
                        This automation will only execute for this criteria. If
                        no criteria is specified the automation will apply to
                        all accounts.
                      </Text>
                      <Box>
                        <SegmentSelect
                          typeName="Company"
                          segmentId={values.segmentId}
                          onChange={(name, value) => {
                            setFieldValue("segmentId", value);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        variant={
                          values.triggerConfig?.andFields?.length > 0
                            ? "action"
                            : "secondary"
                        }
                        onClick={() => setTriggerSegmentOpen(true)}
                      >
                        <FilterOnIcon />
                        {values.triggerConfig?.andFields?.length > 0
                          ? "Edit"
                          : "Add"}
                      </Button>
                      <Button
                        variant="ghost"
                        onClick={() =>
                          setFieldValue("triggerConfig.andFields", undefined)
                        }
                      >
                        <FilterOffIcon />
                      </Button>
                    </Box>
                    <SegmentConfigForm
                      open={triggerSegmentOpen}
                      field={values.triggerConfig || {}}
                      fieldName={"triggerConfig"}
                      onClose={() => setTriggerSegmentOpen(false)}
                      onSave={(newValues) => {
                        setFieldValue(`triggerConfig`, newValues);
                        setTriggerSegmentOpen(false);
                      }}
                      {...props}
                    />
                  </Flex>
                  <Flex my={2}>
                    <SecondaryButton
                      leftIcon={
                        !values.quickAction ? (
                          <RiRadioButtonFill />
                        ) : (
                          <RiCheckboxBlankCircleLine />
                        )
                      }
                      onClick={() => {
                        setFieldValue("actionType", "playbook");
                        setFieldValue("config", {});
                        setFieldValue("quickAction", false);
                      }}
                      sx={{ borderColor: !values.quickAction && "brandLight" }}
                    >
                      Library
                    </SecondaryButton>
                    <Box mx={2} />
                    <SecondaryButton
                      sx={{ borderColor: values.quickAction && "brandLight" }}
                      leftIcon={
                        values.quickAction ? (
                          <RiRadioButtonFill />
                        ) : (
                          <RiCheckboxBlankCircleLine />
                        )
                      }
                      onClick={() => {
                        setFieldValue("actionType", undefined);
                        setFieldValue("quickAction", true);
                        setFieldValue("workflowId", undefined);
                      }}
                    >
                      <Flex alignItems="center">
                        Quick Actions
                        {values.quickAction && !quickActionValid && (
                          <Text ml={1} color="red">
                            *
                          </Text>
                        )}
                      </Flex>
                    </SecondaryButton>
                  </Flex>
                  <Box>
                    {values.quickAction ? (
                      <Box
                        p={4}
                        bg="gray.100"
                        variant="box"
                        sx={{ borderColor: "brandLight" }}
                      >
                        <CustomFormGroup
                          type="list"
                          label="Action"
                          name="actionType"
                          value={values.actionType}
                          options={actionOptions}
                          onChange={(name, target) => {
                            setFieldValue(name, target?.value);
                            if (target?.value) {
                              let config = {
                                time: values.config.time,
                                ...getConfigDefaults(target.value),
                              };
                              setFieldValue("config", config);
                            }
                            updateValidationSchema(target?.value);
                          }}
                        />
                        <Label>Configuration</Label>

                        {(!values.actionType || values.actionType == "") && (
                          <Box variant="box" bg="white">
                            <Text m={4} variant="muted">
                              Quick action has not been selected
                            </Text>
                          </Box>
                        )}
                        {values.actionType && (
                          <Box p={3} my={2} variant="box" bg="white">
                            {values.actionType === "ask_survey" && (
                              <SelectSurveyForm
                                field={values}
                                fieldName=""
                                {...formProps}
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                              />
                            )}
                            {values.actionType === "tags" && (
                              <TagForm
                                field={values}
                                fieldName=""
                                {...formProps}
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                              />
                            )}
                            {values.actionType === "slack" && (
                              <SlackForm
                                field={values}
                                fieldName=""
                                {...formProps}
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                              />
                            )}
                            {values.actionType === "email" && (
                              <EmailForm
                                field={values}
                                fieldName=""
                                {...formProps}
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                              />
                            )}
                            {values.actionType === "in_app_notification" && (
                              <AlertForm
                                field={values}
                                fieldName=""
                                {...formProps}
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                              />
                            )}
                            {values.actionType === "in_app_banner" && (
                              <BannerForm
                                field={values}
                                fieldName=""
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                                {...formProps}
                              />
                            )}
                            {values.actionType === "task_create" && (
                              <TaskForm
                                field={values}
                                fieldName=""
                                {...formProps}
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                              />
                            )}
                            {values.actionType === "webhook" && (
                              <WebhookForm
                                field={values}
                                fieldName=""
                                {...formProps}
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                              />
                            )}
                            {values.actionType === "assign_owner" && (
                              <AssignOwnerForm
                                field={values}
                                fieldName=""
                                {...formProps}
                                onError={() => {
                                  quickActionValid &&
                                    setQuickActionValid(false);
                                }}
                                onValid={() => {
                                  !quickActionValid &&
                                    setQuickActionValid(true);
                                }}
                              />
                            )}
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box
                        p={4}
                        bg="gray.100"
                        variant="box"
                        sx={{ borderColor: "brandLight" }}
                      >
                        <Box p={2}>
                          <WorkflowSelectForm
                            field={values}
                            fieldName=""
                            {...formProps}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>

                  {(values.actionType === "playbook" ||
                    values.actionType === "workflow") && (
                    <Flex justifyContent="space-between" my={3}>
                      <Box>
                        <Label>Exit Criteria</Label>
                        <Text m={2} variant="muted">
                          This automation will <strong>exit</strong> the if this
                          criteria is met.
                        </Text>
                      </Box>
                      <Flex alignItems="center">
                        <Button
                          variant={
                            values.exitConfig?.andFields?.length > 0
                              ? "action"
                              : "secondary"
                          }
                          onClick={() => setExitSegmentOpen(true)}
                        >
                          <FilterOnIcon />
                          {values.exitConfig?.andFields?.length > 0
                            ? "Edit "
                            : "Add "}
                        </Button>
                        {values.exitConfig?.andFields && (
                          <Button
                            variant="ghost"
                            onClick={() =>
                              setFieldValue("exitConfig.andFields", undefined)
                            }
                          >
                            <FilterOffIcon />
                          </Button>
                        )}
                      </Flex>
                      <SegmentConfigForm
                        open={exitSegmentOpen}
                        field={values.exitConfig || {}}
                        fieldName={"exitConfig"}
                        onClose={() => setExitSegmentOpen(false)}
                        onSave={(newValues) => {
                          setFieldValue(`exitConfig`, newValues);
                          setExitSegmentOpen(false);
                        }}
                        {...props}
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </TriggerPage>
  );
}

export default withRouter(TriggerForm);
