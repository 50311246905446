import React from "react";
import { Flex, Text, Box } from "rebass";
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "Components/Common/Icons";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import { useTheme } from "emotion-theming";
import { HealthIcon, SentimentIcon } from "../../Common/Icons";
import HealthByEntity from "../../../graphql/queries/HealthByEntity.gql";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { dateToFromNowDaily } from "../../../consts";
import ReactTooltip from "react-tooltip";

const Icon = ({ name, color }) => {
  switch (name.toLowerCase()) {
    case "nps":
      return null;
    case "csat":
    case "ces":
      return null;
    case "sentiment":
      return <SentimentIcon size={25} color={color} />;
    default:
      return <HealthIcon size={25} color={color} />;
  }
};

const rangeConversion = (name, range) => {
  switch (name.toLowerCase()) {
    case "ces":
      return range.map((v) => 10 - v);
    default:
      return range;
  }
};
function HealthBadge({ name, id, parentType, days, required, onClick }) {
  const daysAgo = days || 30 * 6;
  const { data: sentimentHealthData } = useQuery(HealthByEntity, {
    variables: {
      id,
      parentType,
      days: daysAgo,
      scoreType: name.toLowerCase(),
    },
    fetchPolicy: "network-only",
  });

  const value = sentimentHealthData?.healthByEntity?.value;
  const valueDiff = sentimentHealthData?.healthByEntity?.valueDiff;
  const trend = sentimentHealthData?.healthByEntity?.trend;
  const createdAt = sentimentHealthData?.healthByEntity?.createdAt;

  const theme = useTheme();
  let color =
    value <= 5
      ? theme.colors.red
      : value >= 9
      ? theme.colors.green
      : theme.colors.yellow;

  return (
    (required || value > 0) && (
      <>
        <ReactTooltip
          id={name}
          place="top"
          effect="solid"
          getContent={(dataTip) => dataTip}
        />
        <Flex
          flex={1}
          justfiyContent="center"
          variant="box"
          p={3}
          flexDirection="column"
          mr={2}
          onClick={onClick}
          sx={{ cursor: "pointer", maxWidth: 150 }}
          data-for={name}
          data-tip="Click to Update"
        >
          <Flex flexDirection="column" flex={1}>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              flexWrap={"wrap"}
            >
              <Text variant="label">{name}</Text>
              {createdAt && (
                <Text variant="muted" fontSize={"xxs"}>
                  updated {dateToFromNowDaily(moment(createdAt))}
                </Text>
              )}
            </Flex>
            {value ? (
              <Flex width="100%" pb={1} alignItems="center" mt={2}>
                <Flex color={color}>
                  <Icon name={name} color={color} />
                </Flex>
                <Text variant="h2" fontSize="xl" mx={1}>
                  {value}
                </Text>
                <Text color={valueDiff < 0 ? "red" : valueDiff > 0 && "green"}>
                  <Flex alignItems="center">
                    {!valueDiff || valueDiff === 0 ? (
                      <MinusIcon />
                    ) : valueDiff < 0 ? (
                      <ArrowDownIcon />
                    ) : (
                      valueDiff > 0 && <ArrowUpIcon />
                    )}
                    <Text fontSize={"xxs"}>{valueDiff || 0}</Text>
                  </Flex>
                </Text>
              </Flex>
            ) : (
              <Box>
                <Flex width="100%" alignItems="center" my={2}>
                  <Flex color={"gray.300"}>
                    <Icon name={name} color={"gray.300"} />
                  </Flex>
                  <Text variant="muted" mx={2}>
                    Not updated
                  </Text>
                </Flex>
                <Text variant="muted" fontSize={"xxs"} mt={1}>
                  {daysAgo} day trend
                </Text>
              </Box>
            )}

            {trend && trend.length > 0 && (
              <Flex flexDirection={"column"}>
                <Box height={30}>
                  <Sparklines data={rangeConversion(name, trend)}>
                    <SparklinesLine
                      color={theme.colors.brandLight}
                      style={{ fill: "none" }}
                    />
                    <SparklinesSpots
                      style={{ fill: theme.colors.brandLight }}
                    />
                  </Sparklines>
                </Box>
                <Text variant="muted" fontSize={"xxs"} mt={1}>
                  {daysAgo} day trend
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </>
    )
  );
}

export default HealthBadge;
