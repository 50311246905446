import React from "react";
import { Flex, Box, Link, Button, Text } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import CurrentAccountQuery from "../../../graphql/queries/CurrentAccount.gql";
import AccountMutation from "../../../graphql/mutations/Account.gql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Settings from ".";
import Loader from "Components/Common/Loader";
import { useToasts } from "react-toast-notifications";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "Components/Common/Buttons/GhostButton";
import Label from "../../Common/Forms/Label";
import { useHistory } from "react-router-dom";
import LocalisationForm from "./Localisation";

function AccountForm(props) {
  const { addToast } = useToasts();
  const history = useHistory();
  const { loading, data, error, refetch } = useQuery(CurrentAccountQuery, {});
  const [saveAccount, {}] = useMutation(AccountMutation);

  if (loading)
    return (
      <Settings>
        <Loader />
      </Settings>
    );

  return (
    <Settings>
      <Formik
        initialValues={data?.currentAccount}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          subdomain: Yup.string().required("Required"),
          firstName: Yup.string().required("Required"),
          lastName: Yup.string().required("Required"),
          email: Yup.string().required("Required"),
          timezone: Yup.string().required("Required"),
          locale: Yup.string().required("Required"),
        })}
        autoComplete={false}
        onSubmit={(values, actions) => {
          // call mutation here
          saveAccount({
            variables: {
              ...values,
              config: values.setupConfig,
            },
          }).then(
            (response) => {
              let result = response.data.accountMutation;
              if (result?.errors) {
                console.log(result?.errors);
                actions.setErrors([result?.errors]);
                addToast(<Flex>{result?.errors[0]}</Flex>, {
                  appearance: "error",
                  autoDismiss: false,
                });
              } else {
                addToast(<Flex>Saved!</Flex>, {
                  appearance: "success",
                  autoDismiss: true,
                });
              }
            },
            (error) => {
              console.log(error);
              actions.setErrors([error]);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, handleBlur, handleSubmit, errors } =
            formProps;

          return (
            <Flex noValidate flex={1} flexDirection="column">
              <Flex flexDirection="column" bg="white" p={4}>
                <Flex my={2} justifyContent="space-between">
                  <Text variant="h2">Account Details</Text>
                  <Flex>
                    <GhostButton
                      onClick={() =>
                        history.push("/settings/account/subscription")
                      }
                    >
                      Change Subscription
                    </GhostButton>
                    <Box mx={2} />
                    <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                  </Flex>
                </Flex>
                <CustomFormGroup
                  placeholder="Account Name"
                  label="Account Name"
                  name="name"
                  value={values.name}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  onBlur={handleBlur}
                  helperText={errors.name && errors.name}
                />
                <Box my={3}>
                  <Text variant="h3">Billing</Text>
                  <CustomFormGroup
                    placeholder="Email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    onBlur={handleBlur}
                    helperText={errors.email && errors.email}
                  />
                  <Flex>
                    <CustomFormGroup
                      placeholder="First Name"
                      label="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                      helperText={errors.firstName && errors.firstName}
                    />
                    <Box mx={2} />
                    <CustomFormGroup
                      placeholder="Last Name"
                      label="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                      helperText={errors.lastName && errors.lastName}
                    />
                  </Flex>
                </Box>
                <Box my={3}>
                  <Text variant="h3">Localisation</Text>
                  <LocalisationForm {...formProps} hideWarning />
                </Box>
                <Box my={3}>
                  <Text variant="h3">Features</Text>
                  <Text variant="muted">Enable features in your account.</Text>
                  <Box m={2} mt={4}>
                    <CustomFormGroup
                      placeholder="Disable Conversion Module"
                      label="Disable Conversion Module"
                      type="switch"
                      name="setupConfig.conversionModuleDisabled"
                      value={values.setupConfig?.conversionModuleDisabled}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                    />
                  </Box>
                </Box>
                <Box my={3}>
                  <Text variant="h3">Branding</Text>
                  <Text variant="muted">Personalize your account.</Text>
                  <Box m={2} mt={4}>
                    <Label>Custom URL</Label>
                    <Flex alignItems="center" mt={2}>
                      <Text mb={1}>http://</Text>
                      <CustomFormGroup
                        noMargin
                        placeholder="your domain"
                        label=""
                        name="subdomain"
                        value={values.subdomain}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        width="150px"
                        onBlur={handleBlur}
                        helperText={errors.subdomain && errors.subdomain}
                      />
                      <Text mb={1}>.userlot.app</Text>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </Flex>
          );
        }}
      </Formik>
    </Settings>
  );
}

export default AccountForm;
