import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Flex } from "rebass";
import { useTheme } from "emotion-theming";
import { MoveIcon } from "Components/Common/Icons";

function DraggableItem(props) {
  const theme = useTheme();
  const { item, index, typeName } = props;
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? theme.colors.blue[100] : "",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable
      key={item.name}
      draggableId={`${typeName}.${item.name}`}
      index={index}
    >
      {(provided, snapshot) => {
        if (snapshot.isDragging) {
          const offset = { x: -200, y: 0 }; // your fixed container left/top position
          const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
          const x = provided.draggableProps.style.left - offset.x;
          const y = provided.draggableProps.style.top + winScroll;
          provided.draggableProps.style.left = x;
          provided.draggableProps.style.top = y;
        }
        return (
          <Flex
            variant="box"
            my={2}
            p={2}
            justifyContent="space-between"
            alignItems="center"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {props.children}
            <Flex
              alignItems="center"
              {...provided.dragHandleProps}
              color={theme.colors.primary}
            >
              <MoveIcon />
            </Flex>
          </Flex>
        );
      }}
    </Draggable>
  );
}

export default DraggableItem;
