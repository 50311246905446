import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Avatar from "react-avatar";
import { Flex, Text, Box, Link, Image } from "rebass";
import PropTypes from "prop-types";
import Table from "Components/Common/Table";
import ModelHeader from "Components/Common/ModelHeader";
import { MODEL_TYPES } from "consts";
import { PriorityIcon } from "Components/Common/Icons";
import { WarningIcon } from "Components/Common/Icons";
import { RiUser2Fill } from "react-icons/ri";
import moment from "moment";
import Tags from "Components/Common/Tags";

function TicketTable({
  detailedView,
  loading,
  data,
  onSelected,
  onSelectedRowsChange,
  saveProductFilter,
  selectedRows,
}) {
  const columns = [
    detailedView && {
      name: " ",
      selector: (row) => row.product.name,
      sortable: true,
      width: "30px",
      cell: (row) => {
        return (
          <Flex justifyContent="center">
            <Avatar
              round
              name={row.product?.name}
              src={row.product?.logoUrl}
              size="25"
            />
          </Flex>
        );
      },
    },
    {
      name: " ",
      selector: (row) => row.priority,
      sortable: true,
      width: "40px",
      cell: (row) => (
        <Flex
          flex={1}
          data-tag="allowRowEvents"
          alignItems="center"
          justifyContent="center"
        >
          <PriorityIcon value={row.priority} />
        </Flex>
      ),
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      width: "30%",
      cell: (row) => (
        <Flex data-tag="allowRowEvents" alignItems="center">
          <Box data-tag="allowRowEvents" alignItems="center"></Box>
          <Flex>
            <Text mx={2} data-tag="allowRowEvents">
              {/*TODO: Stop after a certain number of characters?*/}
              {row.title}
            </Text>
            {row.escalated && (
              <Flex alignItems={"center"} color="red">
                <WarningIcon />
                <Text fontSize={"xxs"} mx={1}>
                  Escalated
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      ),
    },
    detailedView && {
      name: "Account",
      selector: (row) =>
        row.actionable?.company?.name || row.actionable?.name || "",
      //sorting does not work for now so its been disabled
      sortable: false,
      cell: (row) => {
        const name = row.company?.name;
        const id = row.company?.id;
        return row.company ? (
          <Flex alignItems="center">
            <Link href={`/data/accounts/${id}`}>
              <ModelHeader
                data-tag="allowRowEvents"
                type={MODEL_TYPES.Company.name}
                name={name}
              />
            </Link>
          </Flex>
        ) : (
          <Flex alignItems="center">
            <Text variant="muted">No account set</Text>
          </Flex>
        );
      },
    },
    {
      name: "User",
      selector: (row) => row.endUser?.name,
      sortable: true,
      cell: (row) => {
        const name = row.endUser?.name;
        const id = row.endUser?.id;
        return (
          row.endUser && (
            <Flex flexDirection="column">
              <Link href={`/data/${id}`}>
                <ModelHeader
                  data-tag="allowRowEvents"
                  type={MODEL_TYPES.EndUser.name}
                  name={name}
                />
              </Link>
              <Text variant="muted" fontSize={"xxs"}>
                {row.endUser?.email}
              </Text>
            </Flex>
          )
        );
      },
    },
    {
      name: "Due",
      selector: (row) => row.dueAt,
      sortable: true,
      width: "100px",
      cell: (row) => (
        <Flex data-tag="allowRowEvents" alignItems="center">
          {row.dueAt && moment(row.dueAt).format("DD MMM YYYY")}
        </Flex>
      ),
    },
    {
      name: "Tags",
      selector: (row) => row.category,
      sortable: false,
      width: "200px",
      cell: (row) => (
        <Flex data-tag="allowRowEvents" alignItems="center" flexWrap={"wrap"}>
          <Tags value={row.ticketTagList} />
        </Flex>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "120px",
      cell: (row) => (
        <Flex data-tag="allowRowEvents" alignItems="center">
          <Box data-tag="allowRowEvents" alignItems="center"></Box>
          <Text mx={2} data-tag="allowRowEvents">
            {/*TODO: Convert this from a number to an actual changeable value ergo the task list*/}
            {row.status}
          </Text>
        </Flex>
      ),
    },
  ];

  return (
    <Table
      title=""
      defaultSortField="dueAt"
      columns={columns.filter((c) => !!c)}
      data={data}
      loading={loading}
      onSelectedRowsChange={onSelectedRowsChange}
      selectableRows
      selectedRows={selectedRows}
      onRowClicked={(p) => {
        if (p.sourceLink) {
          window.open(p.sourceLink);
        }
      }}
      noDataComponent={<NoTicketData />}
    />
  );
}

const NoTicketData = () => {
  return (
    <Flex
      flex={1}
      py={3}
      bg="gray.100"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ borderRadius: "medium" }}
    >
      <Flex flex={1} p={4} flexDirection="column" justifyContent="center">
        <Image
          src={require("assets/images/TaskEmptyState.png")}
          sx={{ height: "120px", objectFit: "contain" }}
        />

        <Text variant="muted" my={3}>
          You have nothing left to do here!
        </Text>
      </Flex>
    </Flex>
  );
};

const AssignedTo = ({ row }) => {
  const [isHovering, setIsHovering] = useState(false);
  return row.user ? (
    <Flex data-tag="allowRowEvents" alignItems="center">
      <Avatar
        src={row.user?.avatarUrl}
        name={`${row.user?.firstName} ${row.user?.lastName}`}
        email={row.user?.email}
        size="30"
        round
        textSizeRatio={2}
      />
    </Flex>
  ) : (
    <Flex
      justifyContent="center"
      data-tag="allowRowEvents"
      alignItems="flex-end"
      sx={{
        backgroundColor: "gray.200",
        borderRadius: 99,
        width: 30,
        height: 30,
      }}
      color="white"
    >
      <RiUser2Fill size="22" />
    </Flex>
  );
};
AssignedTo.propTypes = {
  row: PropTypes.object,
};
TicketTable.propTypes = {
  detailedView: PropTypes.bool,
  loading: PropTypes.bool,
  onSelected: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  onSelectedRowsChange: PropTypes.func.isRequired,
  saveProductFilter: PropTypes.func,
};

TicketTable.defaultProps = {
  detailedView: true,
  loading: false,
  data: [],
};
export default withRouter(TicketTable);
