import React from "react";
import { Flex, Link, Text } from "rebass";
import { HelpIcon } from "Components/Common/Icons";

function TableEmpty(props) {
  return (
    <Flex p={2} flexDirection="column">
      <Text variant="muted">{props.help}</Text>
      <Link href="#" my={2}>
        <Flex alignItems="center">
          <HelpIcon /> <Text mx={2}>Need more help?</Text>
        </Flex>
      </Link>
    </Flex>
  );
}

export default TableEmpty;
