import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
import Loader from "../../Common/Loader";
import Avatar from "react-avatar";

//data: {id:, name:, logoUrl:, data: [{label:, color:, value:}]}
function DonutGraphList({ title, data, loading, width }) {
  const defaultSize = width || 100;
  return loading ? (
    <Loader />
  ) : (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexWrap={"wrap"}
      flex={1}
    >
      {data?.map((team, i) => {
        return team.data.length > 0 ? (
          <Flex
            px={team.data.length > 1 && 3}
            flexDirection="column"
            height={defaultSize + 20}
            my={2}
          >
            <DonutGraph
              data={team.data}
              width={defaultSize}
              noLegend={i < data.length - 1}
              label={team.name}
              value={
                <Flex
                  bg="white"
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: 99 }}
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Avatar
                    size={defaultSize - 30}
                    round
                    email={team.email}
                    name={team.name}
                    src={team.logoUrl}
                  />
                </Flex>
              }
            />
          </Flex>
        ) : (
          <Flex alignItems="center" justifyContent="center">
            <Image src={require("assets/images/HealthEmpty.png")} width={120} />
            <Text variant="muted" textAlign={"center"} maxWidth={defaultSize}>
              {team.name}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
}
export default DonutGraphList;
