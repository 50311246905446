import React from "react";
import { Flex, Text } from "rebass";
import { HomeSettingsIcon, ProductSettingsIcon } from "Components/Common/Icons";
import Topbar from "Components/Common/Topbar/Topbar";

function AdminTopbar(props) {
  return (
    <Topbar
      title="Settings"
      menuItems={[
        {
          name: (
            <Flex>
              <Text>Account Settings</Text>
            </Flex>
          ),
          tooltip: "General",
          icon: HomeSettingsIcon,
          link: "/settings/account/general",
        },
        {
          name: (
            <Flex>
              <Text>Product Settings</Text>
            </Flex>
          ),
          tooltip: "Product",
          icon: ProductSettingsIcon,
          link: "/settings/product/general",
        },
      ]}
      crumbs={props.crumbs}
    />
  );
}

export default AdminTopbar;
