import React from "react";
import { Flex, Button, Text, Box } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Prompt } from "react-router-dom";
import { FieldArray, Formik } from "formik";
import { getTaskCategories } from "hooks/getTaskCategories";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { PlusIcon, TrashIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import TaskCategoriesMutation from "../../../../graphql/mutations/TaskCategories.gql";
import TaskCategoriesQuery from "../../../../graphql/queries/TaskCategory.gql";
import { CustomIcon, IconComponentMapping } from "../../../Common/Icons";
import DraggableItem from "../DataModel/DraggableItem";
import DndList from "../DataModel/DND";

function TaskCategoriesForm(props) {
  const { data, refetch } = useQuery(TaskCategoriesQuery);
  const { addToast } = useToasts();
  const [save, {}] = useMutation(TaskCategoriesMutation, {
    refetchQueries: [{ query: TaskCategoriesQuery }],
  });

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={{
          taskCategories: data?.taskCategories || [{ name: "", iconName: "" }],
        }}
        autoComplete={false}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values, actions) => {
          save({
            variables: {
              taskCategories: values.taskCategories.map((r) => {
                return {
                  id: r.id || null,
                  name: r.name,
                  iconName: r.iconName,
                  active: r.active || false,
                  sortOrder: r.sortOrder || 0,
                };
              }),
            },
          }).then(
            (response) => {
              if (response.data?.taskCategories?.errors) {
                addToast(
                  <Flex>{response.data?.taskCategories?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                actions.resetForm({
                  values: response.data?.taskCategories?.taskCategories,
                });
                props.onComplete && props.onComplete();
                addToast(<Flex>Saved!</Flex>, SuccessParams);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, dirty, handleSubmit } = formProps;

          console.log(values);
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <FieldArray
                name="taskCategories"
                render={(arrayHelpers) => (
                  <Box my={2}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text variant="h2">Activity Types</Text>
                      <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                    </Flex>
                    <Box pt={2}>
                      <Text>
                        Define a list of categories for your activities.
                      </Text>
                    </Box>
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      mt={4}
                    >
                      <Box width={3 / 5} fontWeight="bold">
                        Name
                      </Box>
                      <Box mx={2} width={1 / 5} fontWeight="bold">
                        Icon
                      </Box>
                      <Box mx={2} width={1 / 5} fontWeight="bold">
                        Active
                      </Box>
                      <Box mx={2} width={1 / 5}></Box>
                    </Flex>
                    <DndList
                      items={values.taskCategories}
                      onChange={(data) => {
                        const data2 = data.map((field, index) => {
                          return { ...field, sortOrder: index };
                        });
                        setFieldValue("taskCategories", data2);
                      }}
                    >
                      {values.taskCategories && values.taskCategories.length > 0
                        ? values.taskCategories.map((field, index) => (
                            <DraggableItem
                              key={`DraggableItem-${field.id}`}
                              id={`DraggableItem-${field.id}`}
                              item={field}
                              index={index}
                            >
                              <Flex
                                flex={1}
                                alignItems="center"
                                justifyContent="flex-start"
                                my={2}
                              >
                                <Box width={3 / 5}>
                                  <CustomFormGroup
                                    placeholder="Activity Type"
                                    name={`taskCategories[${index}].name`}
                                    value={field.name}
                                    onChange={setFieldValue}
                                    onBlur={setFieldValue}
                                  />
                                </Box>
                                <Box mx={2} width={1 / 5}>
                                  <CustomFormGroup
                                    type="list"
                                    clearable={false}
                                    placeholder="Icon"
                                    name={`taskCategories[${index}].iconName`}
                                    value={field.iconName}
                                    width={120}
                                    options={ImageOptions()}
                                    onChange={(name, value) =>
                                      setFieldValue(name, value?.value)
                                    }
                                  />
                                </Box>
                                <Box mx={2} width={1 / 5}>
                                  <CustomFormGroup
                                    type="checkbox"
                                    name={`taskCategories[${index}].active`}
                                    value={field.active}
                                    onChange={(name, value) =>
                                      setFieldValue(name, value)
                                    }
                                  />
                                </Box>

                                <Flex
                                  mx={2}
                                  width={1 / 5}
                                  justifyContent="flex-end"
                                >
                                  <Button
                                    m={1}
                                    variant="ghost"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <Flex
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <TrashIcon />
                                    </Flex>
                                  </Button>
                                </Flex>
                              </Flex>
                            </DraggableItem>
                          ))
                        : undefined}
                    </DndList>
                    <Flex justifyContent="space-between">
                      <PrimaryButton
                        onClick={() =>
                          arrayHelpers.push({
                            name: "",
                          })
                        }
                        leftIcon={<PlusIcon size="12" />}
                      >
                        Add Activity Types
                      </PrimaryButton>
                    </Flex>
                  </Box>
                )}
              />
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default TaskCategoriesForm;

const ImageOptions = () => {
  return Object.keys(IconComponentMapping).map((i) => {
    return { value: i, label: <CustomIcon componentName={i} size={20} /> };
  });
};
