import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import EventsQuery from "../graphql/queries/EventsByEntitySummary.gql";

export function getEventsByEntitySummary(id, parentType) {
  const eventsQuery = useQuery(EventsQuery, {
    variables: { id: id, parentType: parentType },
  });

  return {
    ...eventsQuery,
  };
}
