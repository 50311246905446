import React from "react";
import { Flex, Button, Text, Box } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Prompt } from "react-router-dom";
import { FieldArray, Formik } from "formik";
import { getPlanTypes } from "hooks/getPlanTypes";
import { getCompanyStatus } from "hooks/getCompanyStatuses";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { PlusIcon, TrashIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import PlanTypeMutation from "../../../../graphql/mutations/PlanType.gql";
import PlanTypesQuery from "../../../../graphql/queries/PlanTypes.gql";

function PlanTypeForm(props) {
  const { data, refetch } = getPlanTypes();
  const { data: statusData } = getCompanyStatus();
  const { addToast } = useToasts();
  const [save, {}] = useMutation(PlanTypeMutation, {
    refetchQueries: [{ query: PlanTypesQuery }],
  });

  const options = statusData?.companyStatuses.map((s) => {
    return { value: s.id, label: `${s.name}` };
  });
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={{
          planTypes: data?.planTypes || [{ name: "", default: false }],
        }}
        autoComplete={false}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values, actions) => {
          save({
            variables: {
              planTypes: values.planTypes.map((r) => {
                return {
                  id: r.id || null,
                  name: r.name,
                  companyStatusId: r.companyStatusId,
                  default: r.default,
                };
              }),
            },
          }).then(
            (response) => {
              if (response.data?.planType?.errors) {
                addToast(
                  <Flex>{response.data?.planType?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                actions.resetForm({
                  values: response.data?.planType?.planTypes,
                });
                props.onComplete && props.onComplete();
                addToast(<Flex>Saved!</Flex>, SuccessParams);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, setFieldValue, dirty, handleSubmit } = formProps;
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex justifyContent={"space-between"}>
                <Text variant="h3" mb={2} mt={3}>
                  Tiers
                </Text>
                <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
              </Flex>
              <FieldArray
                name="planTypes"
                render={(arrayHelpers) => (
                  <Box my={2}>
                    {values.planTypes && values.planTypes.length > 0
                      ? values.planTypes.map((field, index) => (
                          <Flex
                            key={index}
                            alignItems="center"
                            justifyContent="flex-start"
                            my={1}
                          >
                            <Box width={2 / 5}>
                              <CustomFormGroup
                                placeholder="Tier"
                                name={`planTypes[${index}].name`}
                                value={field.name}
                                onChange={(name, value) =>
                                  setFieldValue(name, value)
                                }
                              />
                            </Box>
                            <Box width={2 / 5} mx={2}>
                              <CustomFormGroup
                                placeholder="Status"
                                type="list"
                                options={options}
                                name={`planTypes[${index}].companyStatusId`}
                                value={field.companyStatusId}
                                onChange={(name, value) =>
                                  setFieldValue(name, value?.value)
                                }
                              />
                            </Box>
                            <Box width={1 / 5}>
                              <CustomFormGroup
                                placeholder="Tier"
                                label="default"
                                type="checkbox"
                                name={`planTypes[${index}].default`}
                                value={field.default}
                                onChange={(name, value) =>
                                  setFieldValue(name, value)
                                }
                              />
                            </Box>
                            <Button
                              m={1}
                              variant="ghost"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Flex alignItems="center" justifyContent="center">
                                <TrashIcon />
                              </Flex>
                            </Button>
                          </Flex>
                        ))
                      : undefined}
                    <Flex justifyContent="space-between">
                      <PrimaryButton
                        onClick={() =>
                          arrayHelpers.push({
                            name: "",
                          })
                        }
                        leftIcon={<PlusIcon size="12" />}
                      >
                        Add Tier
                      </PrimaryButton>
                    </Flex>
                  </Box>
                )}
              />
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default PlanTypeForm;
