import React, { useState } from "react";
import { useHistory } from "react-router";
import { RiExternalLinkFill } from "react-icons/ri";
import { Box, Flex, Link, Text } from "rebass";
import { MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import Label from "Components/Common/Forms/Label";
import Select from "Components/Common/Forms/Select";
import { useQuery } from "@apollo/client";
import EndUsersQuery from "../../../graphql/queries/EndUsersList.gql";
import CompaniesQuery from "../../../graphql/queries/CompaniesList.gql";
import {
  CloseIcon,
  CompanyIcon,
  EditIcon,
  LinkIcon,
  MailIcon,
  UserIcon,
} from "Components/Common/Icons";
import GhostButton from "Components/Common/Buttons/GhostButton";
import { useEffectOnce } from "react-use";

const _ = require("lodash");

function TaskActionable(props) {
  const [showLink, setShowLink] = useState(true);
  const flexDirection = props.flexDirection || "row";
  const type = props.value?.type || MODEL_TYPES.Company.name;

  const { data: companyData } = useQuery(CompaniesQuery, {});
  const { data: userData } = useQuery(EndUsersQuery, {});

  const entityData = getData(MODEL_TYPES[type], props.value?.id);

  const companyId =
    type === MODEL_TYPES.Company.name
      ? entityData.data?.company?.id
      : entityData.data?.endUser?.company?.id;

  const userId =
    type === MODEL_TYPES.EndUser.name && entityData.data?.endUser?.id;

  const key =
    companyData &&
    _.first(Object.keys(companyData).filter((n) => n !== "errors"));
  const companyOptions =
    companyData &&
    companyData[key].map((d) => {
      return {
        id: d.id,
        value: d.id,
        label: d.name,
        type: MODEL_TYPES.Company.name,
        name: d.name,
      };
    });

  const key2 =
    userData && _.first(Object.keys(userData).filter((n) => n !== "errors"));
  const userOptions =
    userData &&
    userData[key2]
      .filter((c) => !companyId || c.company.id == companyId)
      .map((d) => {
        return {
          id: d.id,
          value: d.id,
          label: (
            <Box>
              <Text>{d.name}</Text>
              <Text variant="muted" fontSize={"xxs"}>
                {d.company?.name}
              </Text>
            </Box>
          ),
          type: MODEL_TYPES.EndUser.name,
          name: d.name,
          email: d.email,
        };
      });

  const company = companyOptions?.find((o) => o.id == companyId);
  const user = userOptions?.find((o) => o.id == userId);

  /*async function findUsers(inputValue, client) {
    let options = [];
    await client
      ?.query({
        query: EndUsersQuery,
        variables: { name: inputValue },
      })
      .then(({ error, data }) => {
        if (error) {
          console.log("response error");
          console.log(error);
        }
        if (data) {
          const key = _.first(Object.keys(data).filter((n) => n !== "errors"));
          options = data[key]
            .filter((c) => !company?.id || c.company.id == company?.id)
            .map((d) => {
              return {
                id: d.id,
                value: d.id,
                label: (
                  <Box>
                    <Text>{d.name}</Text>
                    <Text variant="muted" fontSize={"xxs"}>
                      {d.company?.name}
                    </Text>
                  </Box>
                ),
                type: MODEL_TYPES.EndUser.name,
                name: d.name,
              };
            });
        }
      })
      .catch((error) => {
        console.log("catch user error");
        console.log(error);
      });
    return options;
  }

  async function findCompanies(inputValue, client) {
    let options = [];
    await client
      ?.query({
        query: CompaniesQuery,
        variables: { name: inputValue },
      })
      .then(({ error, data }) => {
        if (error) {
          console.log("response error");
          console.log(error);
        }
        if (data) {
          const key = _.first(Object.keys(data));
          options = data[key].map((d) => {
            return {
              id: d.id,
              value: d.id,
              label: d.name,
              type: MODEL_TYPES.Company.name,
              name: d.name,
            };
          });
        }
      })
      .catch((error) => {
        console.log("catch company error");
        console.log(error);
      });
    return options;
  }

  function loadOptions(inputValue, callback) {
    (async () => {
      let options = await findUsers(inputValue, client);
      callback(options);
    })().catch((err) => console.log("ASYNC ERROR " + err.message));
  }
  function loadCompanyOptions(inputValue, callback) {
    (async () => {
      let options = await findCompanies(inputValue, client);
      callback(options);
    })().catch((err) => console.log("ASYNC ERROR " + err.message));
  }
  */

  useEffectOnce(() => {
    setShowLink(!props.value?.id);
  });

  return (
    <Flex width="100%" flexDirection={flexDirection}>
      <Flex flex={1} alignItems="center" justifyContent="center">
        <Flex flex={1}>
          {!showLink ? (
            <Flex flex={1} variant="box" flexDirection="column">
              <Flex
                flex={1}
                justifyContent="space-between"
                alignItems={"center"}
              ></Flex>
              <Flex justifyContent="space-between" p={2}>
                <Text mx={1}>
                  <LinkIcon />
                </Text>
                <Box width={1 / 2}>
                  <Label>Account</Label>
                  <Flex my={2} alignItems="center" fontSize={"xs"}>
                    <CompanyIcon />
                    <Link href={`/tasks/accounts/${company?.id}`}>
                      <Text mx={1}>{company?.name}</Text>
                    </Link>
                  </Flex>
                </Box>
                <Box width={1 / 2}>
                  <Label>User</Label>
                  <Flex my={2} alignItems="center">
                    {user ? (
                      <Flex fontSize={"xs"}>
                        <UserIcon />
                        <Link
                          href={`/tasks/accounts/${company?.id}/users/${user.id}`}
                        >
                          <Flex>
                            <Text mx={1}>{user?.name}</Text>
                          </Flex>
                        </Link>
                      </Flex>
                    ) : (
                      <Box>
                        <Flex
                          onClick={() => setShowLink(true)}
                          sx={{ cursor: "pointer" }}
                        >
                          <Text mx={1} color="gray.500">
                            <UserIcon />
                          </Text>
                          <Box>
                            <Text color="gray.500" mx={1}>
                              No user assigned
                            </Text>
                            <Link fontSize={"xxs"}>
                              <Text mx={1}>Click to assign user</Text>
                            </Link>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                  </Flex>
                  {user && (
                    <Flex mt={2} fontSize={"xs"}>
                      <MailIcon />

                      <Link
                        mx={1}
                        target="_blank"
                        href={`mailto:${user?.email}?bcc=${user?.bccAddress}`}
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {user?.email}
                      </Link>
                    </Flex>
                  )}
                </Box>
                {!props.readOnly && (
                  <GhostButton onClick={() => setShowLink(true)}>
                    <EditIcon />
                  </GhostButton>
                )}
              </Flex>
            </Flex>
          ) : (
            <Flex flex={1} variant="box" p={2} flexDirection="column">
              <Flex
                flex={1}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Text>
                  <LinkIcon /> Linked to
                </Text>
                <GhostButton onClick={() => setShowLink(false)}>
                  <CloseIcon />
                </GhostButton>
              </Flex>
              <Flex flexDirection={props.flexDirection}>
                <Select
                  placeholder="Account"
                  label="Account"
                  options={companyOptions}
                  name={props.name}
                  value={company}
                  onChange={props.onChange}
                  width="100%"
                />
                <Box mx={2} />
                <Select
                  placeholder="User"
                  name={props.name}
                  label="User"
                  value={user}
                  options={userOptions}
                  onChange={props.onChange}
                  width="100%"
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TaskActionable;
