import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useDynamicGql } from "../graphql/DynamicGql";
import { MODEL_TYPES } from "consts";
import { getCompanyStatus } from "./getCompanyStatuses";

export function getAllCompanyData(status, filters) {
  const { data: statuses } = getCompanyStatus();
  let statusId = statuses?.companyStatuses.find((s) => s.name === status)?.id;
  const { getAllData } = useDynamicGql(MODEL_TYPES.Company, true);

  const dataQuery = useQuery(getAllData, {
    variables: {
      searchFilters: {
        ...filters?.filters,
        statusId: statusId,
      },
    },
    skip: !statusId && !!status,
  });

  return {
    ...dataQuery,
  };
}
