import React, { useState, useEffect } from "react";
import { Flex, Box, Text, Link } from "rebass";
import { Link as RouterLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { classifyData, convertValue, icon } from "./SatisfactionDefaults";
import moment from "moment";
import Tags from "../Tags";

const SatisfactionResponses = (props) => {
  const { scoreType, data, responses } = props;
  return (
    <Box>
      <Text variant="h4">Recent Responses</Text>
      <Flex flex={1} width="100%">
        <VerticalTimeline layout="1-column">
          {data
            ?.filter((s) => s.comments.length > 0)
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((score, i) => {
              let classify = classifyData(
                scoreType,
                convertValue(scoreType, score.value)
              );
              var c = _.last(score.comments);
              return responses === undefined || classify === responses ? (
                <VerticalTimelineElement
                  key={i}
                  date={moment(score?.createdAt).fromNow()}
                  icon={icon(scoreType, convertValue(scoreType, score.value))}
                  contentStyle={{ boxShadow: "none" }}
                >
                  <Box p={2}>
                    <RouterLink
                      to={`/contacts/accounts/${score?.scoreable?.company?.id}/users/${score?.scoreable?.id}`}
                      component={Link}
                      color="brandLight"
                      fontWeight="bold"
                    >
                      {score?.scoreable?.name}
                    </RouterLink>

                    <Text mt={2} bg="gray.100" p={1}>
                      {c?.comment}
                    </Text>
                    <Tags value={score?.categoryList} />
                  </Box>
                </VerticalTimelineElement>
              ) : null;
            })}
        </VerticalTimeline>
      </Flex>
    </Box>
  );
};
SatisfactionResponses.propTypes = {
  scoreType: PropTypes.string,
  data: PropTypes.object,
};

export default SatisfactionResponses;
