import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Link, Button, Text } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFormGroup from "../../../components/Common/Forms/CustomFormGroup";
import { Link as RouterLink } from "react-router-dom";
import { onAppDomain, redirectToSubdomain } from "../../../auth-helper";
import LoginMutation from "../../../graphql/mutations/Login.gql";
import { useMutation } from "@apollo/react-hooks";
import { ServerUrl } from "../../../App";

function LoginForm(props) {
  const [login, {}] = useMutation(LoginMutation, { fetchPolicy: "no-cache" });
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
      })}
      autoComplete={false}
      onSubmit={(values, actions) => {
        login({
          variables: {
            email: values.email,
            password: values.password,
          },
          context: {
            isAuth: true,
            uri: ServerUrl,
          },
        }).then(
          (response) => {
            if (response.errors) {
              let firstError = response.errors[0];
              if (firstError?.extensions.code === "USER_ERROR") {
                actions.setErrors([firstError.message]);
              }
            } else {
              let redirectSubdomain =
                response.data?.userLogin?.redirectSubdomain;
              redirectSubdomain
                ? redirectToSubdomain(redirectSubdomain, "/home")
                : props.history.push("/home");
            }
          },
          (err) => {
            console.log(err.message);
            actions.setErrors([err.message.replace("GraphQL error: ", "")]);
          }
        );
      }}
    >
      {(formProps) => {
        const { values, handleBlur, handleSubmit, setFieldValue, errors } =
          formProps;

        return (
          <Box as="form" onSubmit={handleSubmit} noValidate m={[4, 3, 2, 2]}>
            <CustomFormGroup
              placeholder="Email"
              label="Email"
              name="email"
              value={values.email}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.email && errors.email}
            />
            <CustomFormGroup
              placeholder="Password"
              label="Password"
              type="password"
              name="password"
              value={values.password}
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.password && errors.password}
            />
            <Box mb={1}>
              <CustomFormGroup
                placeholder="Remember me"
                label="Remember me"
                type="checkbox"
                value={true}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
              />
            </Box>
            {errors && errors.length > 0 && (
              <Text
                variant="body"
                color="red"
                sx={{ maxWidth: "400px", wordWrap: "normal" }}
              >
                {errors[0]}
              </Text>
            )}
            <Box mt={2}>
              <Flex>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}
                  fontWeight="bold"
                  width="100%"
                >
                  Sign In
                </Button>
              </Flex>
              <Flex my={3} justifyContent="space-between">
                <Link mx={1} as={RouterLink} to="/reset" fontsize="md">
                  Forgot password?
                </Link>
                {onAppDomain() && (
                  <Link mx={1} as={RouterLink} to="/register">
                    Let{"'"}s Create an Account
                  </Link>
                )}
              </Flex>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}
LoginForm.propTypes = {
  setFieldValue: PropTypes.func,
};
export default LoginForm;
