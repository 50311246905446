import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text, Box, Button } from "rebass";
import { useQuery } from "@apollo/client";
import { ArrowDropDownIcon } from "Components/Common/Icons";
import PlanQuery from "../../../graphql/queries/Plans.gql";

function StageMenuSelect(props) {
  const theme = useTheme();
  const { loading, data } = useQuery(PlanQuery, {});
  const [showMenu, setShowMenu] = useState(false);
  const options = !loading
    ? data?.plans.filter((o) => o.planType?.status?.name === props.moduleType)
    : [];
  const selectedOption = options?.find((o) => o.id === props.value);
  return (
    <Box sx={{ position: "relative" }}>
      <Button
        variant="default"
        bg="brand"
        color="white"
        ml={0}
        mr={1}
        py={0}
        onClick={() => setShowMenu(!showMenu)}
        sx={{
          cursor: "pointer",
          display: "inline-block",
          height: 30,
          marginLeft: 30,
          marginRight: 30,
          paddingLeft: 25,
          position: "relative",
          borderRadius: 0,
          borderTopLeftRadius: 99,
          borderBottomLeftRadius: 99,
        }}
      >
        <Flex alignItems="center">
          {selectedOption ? (
            <Text mx={2}>{selectedOption.name}</Text>
          ) : (
            <Text mx={2}>All Accounts</Text>
          )}
          {options.length > 0 && <ArrowDropDownIcon color="brandLight" />}
        </Flex>
      </Button>
      {showMenu && (
        <Flex
          bg="white"
          flexDirection="column"
          sx={{
            borderColor: "gray.100",
            borderWidth: 1,
            borderStyle: "solid",
            zIndex: 99,
            position: "absolute",
            top: 32,
            left: 15,
            width: 200,
            borderRadius: 8,
            borderTopLeftRadius: 0,
            boxShadow: `2px 0px 6px -1px ${theme.colors.gray[500]}`,
          }}
        >
          <Flex
            flex={1}
            px={3}
            py={2}
            onClick={() => {
              props.onChange(props.name, undefined);
              setShowMenu(false);
            }}
            sx={{
              "&:hover": {
                backgroundColor: "brandHighlight",
                borderRadius: 10,
              },
            }}
          >
            <Box ml={2} fontSize="xs">
              All Accounts
            </Box>
          </Flex>
          {options?.map((o) => (
            <Flex
              flex={1}
              px={3}
              py={2}
              onClick={() => {
                props.onChange(props.name, o);
                setShowMenu(false);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "brandHighlight",
                  borderRadius: 10,
                },
              }}
            >
              <Box ml={2}>
                <Text fontSize="xs">{o.name}</Text>
              </Box>
            </Flex>
          ))}
        </Flex>
      )}
    </Box>
  );
}

export default StageMenuSelect;
