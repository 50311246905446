import React, { useState } from "react";
import { RadialChart, DiscreteColorLegend, XYPlot } from "react-vis";
import { useTheme } from "emotion-theming";
import { Flex, Box, Text } from "rebass";

const _ = require("lodash");

export default function PieGraph(props) {
  const theme = useTheme();
  
  const myData = props.data?.filter(d => !!d) || [];
  return (
    <Flex>
      <Box sx={{ position: "relative" }} >
        <RadialChart
          colorType="literal"
          innerRadius={props.width / 2}
          radius={0}
          data={myData.map((d, i) => {
            return {
              angle: Number(d.value),
              theta: Number(d.value),
              color: d.color,
            };
          })||[]}
          width={props.width}
          height={props.width}
        />
        <Flex
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            margin: props.width / 4,
            height: props.width / 2,
            width: props.width / 2,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Text variant="h3">{props.value}</Text>
        </Flex>
      </Box>
      {!props.noLegend && <Box ml={3}>
        {myData.map((d) => (
          <Flex key={d.label} my={1}>
            <Box
              m={1}
              sx={{ width: 5, height: 5, borderRadius: 3 }}
              bg={d.color}
            ></Box>
            <Text mx={2} variant="muted" fontSize="xs">
              {d.label}
            </Text>
          </Flex>
        ))}
      </Box>}
    </Flex>
  );
}
