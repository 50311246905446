import { RowData, RowModel } from "@tanstack/react-table";
import React from "react";
import {
  RiArrowLeftLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import { Flex, Text } from "rebass";
import GhostButton from "../../Buttons/GhostButton";
import Select from "../../Forms/Select";
import {
  MdChevronLeft,
  MdChevronRight,
  MdFirstPage,
  MdLastPage,
} from "react-icons/md";

export function ActionButtons({
  getSelectedRowModel,
  hasNextPage,
  hasPreviousPage,
  nextPage,
  pageCount,
  pageIndex,
  pageSize,
  previousPage,
  refreshData,
  rerender,
  rowSelection,
  setPageIndex,
  setPageSize,
  totalRows,
}) {
  const minRow = (pageIndex + 1) * pageSize - (pageSize - 1);
  const maxRow =
    minRow + pageSize > totalRows ? totalRows : (pageIndex + 1) * pageSize;

  const options = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 150, label: 150 },
  ];

  return (
    <Flex flex={1} justifyContent="flex-end" alignItems={"center"}>
      <Flex alignItems={"center"}>
        <Text mr={2} variant="muted">
          Rows
        </Text>
        <Select
          className="border p-1 rounded"
          value={options.find((o) => o.value === pageSize)}
          onChange={(name, value) => {
            console.log(value);
            setPageSize(Number(value.value));
          }}
          width={80}
          clearable={false}
          options={options}
        ></Select>
        <Flex mx={3} alignItems={"center"}>
          <Text variant="muted">{minRow}</Text>-
          <Text variant="muted" mr={2}>
            {maxRow}
          </Text>
          <Text variant="muted">of</Text>
          <Text variant="muted" mx={2}>
            {totalRows}
          </Text>
        </Flex>
      </Flex>
      <GhostButton onClick={() => setPageIndex(0)} disabled={!hasPreviousPage}>
        <MdFirstPage size={25} />
      </GhostButton>
      <GhostButton onClick={() => previousPage()} disabled={!hasPreviousPage}>
        <MdChevronLeft size={25} />
      </GhostButton>
      <GhostButton onClick={() => nextPage()} disabled={!hasNextPage}>
        <MdChevronRight size={25} />
      </GhostButton>
      <GhostButton
        onClick={() => setPageIndex(pageCount - 1)}
        disabled={!hasNextPage}
      >
        <MdLastPage size={25} />
      </GhostButton>
    </Flex>
  );
}

export default ActionButtons;
