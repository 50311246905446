import React, { useState } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Flex, Image, Box, Text, Link, Heading } from "rebass";
import StepWizard from "react-step-wizard";
import WizardNav from "Components/Common/WizardNav";
import StepOne from "./Step1";
import StepTwo from "./Step2";
import AcceptInviteMutation from "../../../graphql/mutations/AcceptInvite.gql";
import LoginMutation from "../../../graphql/mutations/Login.gql";
import { useMutation } from "@apollo/react-hooks";
import { ServerUrl } from "../../../App";
import Loader from "Components/Common/Loader";
import WebsiteWrapper from "../WebsiteWrapper";
import TermsOfUsePrivacy from "../TermsOfUsePrivacy";

function AcceptInvite(props) {
  const { key } = useParams();
  const [errors, setErrors] = useState();
  const history = useHistory();

  const [acceptInvite, { error, loading: savingRegistration }] =
    useMutation(AcceptInviteMutation);
  const [login, { loading: savingLogin }] = useMutation(LoginMutation);

  const loading = savingLogin || savingRegistration;

  const [accountData, setAccountData] = useState();

  const handleSubmit = (values) => {
    acceptInvite({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        confirmationToken: key,
        password: values.password,
      },
      context: {
        isAuth: true,
        uri: ServerUrl,
      },
    }).then(
      (response) => {
        if (response.data.acceptInvite?.errors) {
          setErrors(response.data.acceptInvite?.errors);
        } else {
          let email = response.data.acceptInvite?.authenticatable?.email;
          if (!!email) {
            login({
              variables: {
                email: email,
                password: values.password,
              },
              context: {
                isAuth: true,
                uri: ServerUrl,
              },
              skip: !email,
            }).then(
              (response) => {
                if (response.data.userLogin?.errors) {
                  setErrors(response.data.userLogin?.errors);
                } else {
                  history.push("/");
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <WebsiteWrapper>
      <Box width={1 / 2}>
        {loading && (
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            bg="gray.100"
            sx={{
              zIndex: 2,
              position: "absolute",
              width: "100%",
              height: "100%",
              opacity: 0.5,
            }}
          >
            <Loader />
          </Flex>
        )}
        <StepWizard nav={<WizardNav stepLabels={["User", "Password"]} />}>
          <StepOne
            onUpdate={(data) => {
              setAccountData({ ...accountData, ...data });
            }}
          />
          <StepTwo
            onUpdate={(data) => {
              handleSubmit({ ...accountData, ...data });
            }}
          />
        </StepWizard>
        {errors &&
          errors.map((e) => (
            <Text key={e} variant="error">
              {e}
            </Text>
          ))}
        <Flex mt={3}>
          Already have an account?
          <Link href="/" mx={2}>
            Login
          </Link>
        </Flex>
        <TermsOfUsePrivacy />
      </Box>
    </WebsiteWrapper>
  );
}

export default withRouter(AcceptInvite);
