import React from "react";
import { Box, Flex, Text } from "rebass";
import "react-tabs/style/react-tabs.css";
import theme from "../../theme";
import { StarIcon } from "./Icons";
import Loader from "./Loader";
const refactorToIndex = (value) => {
  if (value < 25) return 0;
  if (value >= 25 && value < 50) return 1;
  if (value >= 50 && value < 75) return 2;
  if (value >= 75) return 3;
  return value;
};
function TrialConversionMatrix(props) {
  let matrix = [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ];
  const single = props.data ? props.data.length == 1 : false;
  props.data?.map((d) => {
    let customerFit = refactorToIndex(d.customerFit ? d.customerFit - 1 : 0);
    let adoption = refactorToIndex(d.adoption ? d.adoption - 1 : 0);

    matrix[customerFit][adoption]++;
  });

  return (
    <Flex flexDirection="column">
      <Flex flex={1} sx={{ position: "relative" }}>
        <Flex
          alignItems="center"
          justifyContent="center"
          p={2}
          fontSize="xs"
          flexDirection="column"
        >
          <Text>Customer</Text>
          <Text>Fit</Text>
        </Flex>
        <Flex flexDirection="column">
          <Flex
            height={35}
            m={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="right"
          >
            <Text color={theme.colors.green} fontSize="xs">
              Perfect Match
            </Text>
            <Text variant="muted" fontSize="xxs">
              75%-100%
            </Text>
          </Flex>
          <Flex
            height={35}
            m={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="right"
          >
            <Text color={theme.colors.green + "80"} fontSize="xs">
              Good Match
            </Text>
            <Text variant="muted" fontSize="xxs">
              50%-75%
            </Text>
          </Flex>
          <Flex
            height={35}
            m={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="right"
          >
            <Text color={theme.colors.yellow} fontSize="xs">
              Partial Match
            </Text>
            <Text variant="muted" fontSize="xxs">
              25%-50%
            </Text>
          </Flex>
          <Flex
            height={35}
            m={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="right"
          >
            <Text color={theme.colors.red} fontSize="xs">
              Poor Match
            </Text>
            <Text variant="muted" fontSize="xxs">
              0%-25%
            </Text>
          </Flex>
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Cell single={single} color={theme.colors.green + "4D"}>
            {matrix[3][0]}
          </Cell>
          <Cell single={single} color={theme.colors.green + "1A"}>
            {matrix[2][0]}
          </Cell>
          <Cell single={single} color={theme.colors.yellow + "1A"}>
            {matrix[1][0]}
          </Cell>
          <Cell single={single} color={theme.colors.red + "1A"}>
            {matrix[0][0]}
          </Cell>
          <Flex
            height={35}
            m={1}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Text color={theme.colors.red} fontSize="xs" textAlign="center">
              Low
            </Text>
            <Text variant="muted" fontSize="xxs">
              0%-25%
            </Text>
          </Flex>
        </Flex>

        <Flex flex={1} flexDirection="column">
          <Cell single={single} color={theme.colors.green + "4D"}>
            {matrix[3][1]}
          </Cell>
          <Cell single={single} color={theme.colors.green + "1A"}>
            {matrix[2][1]}
          </Cell>
          <Cell single={single} color={theme.colors.yellow + "1A"}>
            {matrix[1][1]}
          </Cell>
          <Cell single={single} color={theme.colors.yellow + "1A"}>
            {matrix[0][1]}
          </Cell>
          <Flex
            height={35}
            m={1}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Text color={theme.colors.yellow} fontSize="xs" textAlign="center">
              Average
            </Text>
            <Text variant="muted" fontSize="xxs">
              25%-50%
            </Text>
          </Flex>
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Cell single={single} color={theme.colors.green}>
            {matrix[3][2]}
          </Cell>
          <Cell single={single} color={theme.colors.green + "1A"}>
            {matrix[2][2]}
          </Cell>
          <Cell single={single} color={theme.colors.green + "1A"}>
            {matrix[1][2]}
          </Cell>
          <Cell single={single} color={theme.colors.green + "1A"}>
            {matrix[0][2]}
          </Cell>
          <Flex
            height={35}
            m={1}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Text
              color={theme.colors.green + "80"}
              fontSize="xs"
              textAlign="center"
            >
              High
            </Text>
            <Text variant="muted" fontSize="xxs">
              50%-75%
            </Text>
          </Flex>
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Cell single={single} color={theme.colors.green}>
            {matrix[3][3]}
          </Cell>
          <Cell single={single} color={theme.colors.green}>
            {matrix[2][3]}
          </Cell>
          <Cell single={single} color={theme.colors.green + "4D"}>
            {matrix[1][3]}
          </Cell>
          <Cell single={single} color={theme.colors.green + "4D"}>
            {matrix[0][3]}
          </Cell>
          <Flex
            height={35}
            m={1}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Text color={theme.colors.green} fontSize="xs" textAlign="center">
              Very High
            </Text>
            <Text variant="muted" fontSize="xxs">
              75%-100%
            </Text>
          </Flex>
        </Flex>
        {props.loading && (
          <Flex
            bg="brandLight"
            width="100%"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              opacity: 0.2,
            }}
          >
            <Flex alignItems="center" justifyConent="center" width="100%">
              <Box width="100%">
                <Loader />
              </Box>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex
        flex={1}
        my={3}
        justifyContent="center"
        alignItems="center"
        fontSize="xs"
      >
        Adoption
      </Flex>
    </Flex>
  );
}

export default TrialConversionMatrix;

const Cell = ({ color, children, single }) => {
  return (
    <Flex
      height={35}
      m={1}
      bg={color}
      alignItems="center"
      justifyContent="center"
    >
      <Flex fontSize="xxs" alignItems="center" justifyContent="center">
        {single && Number(children) == 1 ? (
          <StarIcon />
        ) : Number(children) <= 0 ? (
          ""
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            sx={{ width: 25, height: 25, borderRadius: 99 }}
            bg="gray.100"
          >
            {Number(children)}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
