import React, { useState } from "react";
import { Flex, Text, Image } from "rebass";
import { useTheme } from "emotion-theming";
import GaugeChart from "react-gauge-chart";
import WidgetWrapper from "./WidgetWrapper";
import SentimentDashboardQuery from "../../../graphql/queries/SentimentDashboard.gql";
import { useQuery } from "@apollo/client";
import Loader from "../../Common/Loader";

function SentimentChart(props) {
  const theme = useTheme();

  const { data: sentimentDashboard, loading } = useQuery(
    SentimentDashboardQuery
  );
  const percent = !sentimentDashboard
    ? 0
    : sentimentDashboard.sentimentDashboard / 10;

  return (
    <WidgetWrapper title="Sentiment">
      <Flex alignItems="center" justifyContent="center" flex={1}>
        {loading ? (
          <Loader />
        ) : sentimentDashboard?.sentimentDashboard > 0 ? (
          <GaugeChart
            id="gauge-chart3"
            nrOfLevels={10}
            arcWidth={0.2}
            arcsLength={[0.5, 0.3, 0.2]}
            colors={[theme.colors.red, theme.colors.yellow, theme.colors.green]}
            percent={percent}
            arcPadding={0.04}
            textColor={theme.colors.brand}
            needleColor={theme.colors.brandHighlight}
            needleBaseColor={theme.colors.brand}
          />
        ) : (
          <Flex flex={1} alignItems="center" justifyContent="center" p={3}>
            <Image
              src={require("assets/images/SatisfactionEmpty.png")}
              width={80}
            />
          </Flex>
        )}
      </Flex>
    </WidgetWrapper>
  );
}

export default SentimentChart;
