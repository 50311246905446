import { useQuery } from "@apollo/react-hooks";
import EmailDomainsQuery from "../graphql/queries/EmailDomains.gql";

function getEmailDomains() {
  const emailDomainsQuery = useQuery(EmailDomainsQuery, {});

  return {
    ...emailDomainsQuery,
  };
}

export default getEmailDomains;
