import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";

function StepTwo(props) {
  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().min(6).required("Required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match")
          .required("Required"),
      })}
      autoComplete={false}
      onSubmit={(values, actions) => {
        props.onUpdate(values);
        props.nextStep();
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;

        return (
          <Box
            as="form"
            flexDirection="column"
            onSubmit={handleSubmit}
            noValidate
            width="100%"
            sx={{
              minWidth: 350,
            }}
          >
            <Text variant="h3" color="brandLight">
              Let's set your password
            </Text>
            <CustomFormGroup
              placeholder="Password"
              name="password"
              value={values.password}
              label="Password"
              type="password"
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.password && errors.password}
            />
            <CustomFormGroup
              placeholder="Confirm Password"
              name="confirmPassword"
              value={values.confirmPassword}
              label="Confirm Password"
              type="password"
              onChange={setFieldValue}
              onBlur={handleBlur}
              helperText={errors.confirmPassword && errors.confirmPassword}
            />

            <Flex mt={2} justifyContent="space-between">
              <SecondaryButton onClick={props.previousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton onClick={handleSubmit}>Continue</PrimaryButton>
            </Flex>
          </Box>
        );
      }}
    </Formik>
  );
}

export default StepTwo;
