import React from "react";
import { Flex, Image, Text, Button, Box } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import {
  AssignOwnerIcon,
  EditIcon,
  EventIcon,
  MailIcon,
  NotificationIcon,
  PlaybookIcon,
  SlackIcon,
} from "Components/Common/Icons";
import Page from "Components/Page";
import ConversionTopbar from "./ConversionTopbar";

const actions = [
  {
    id: "slack",
    name: "Recieve a Slack notification",
    icon: <SlackIcon />,
  },
  { id: "email", name: "Recieve a Email notification", icon: <MailIcon /> },
  {
    id: "in_app_notification",
    name: "Recieve an In App notification",
    icon: <NotificationIcon />,
  },
  {
    id: "assign_owner",
    name: "Assign owner to account",
    icon: <AssignOwnerIcon />,
  },
  {
    id: "playbook",
    name: "Assign playbook to account",
    icon: <PlaybookIcon />,
  },
];
const automations = [
  {
    type: "paid",
    triggerType: "company_created",
    name: "New account",
    actions: ["slack", "email", "in_app_notification", "playbook"],
  },
  {
    type: "paid",
    triggerType: "company_created",
    name: "Assign an owner for each new Account",
    actions: ["assign_owner"],
  },
];
function ConversionAutomation(props) {
  return (
    <Page title="Conversion Automations" topBar={<ConversionTopbar />}>
      <Flex m={4} flexDirection="column">
        <Flex justifyContent="space-between">
          <Text variant="h2">Trail Accounts Automations</Text>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flex={1}
          m={2}
        >
          {automations.map((automation) => {
            return (
              <Flex my={2} flexDirection="column" width="100%">
                <Flex alignItems="center">
                  <Text mx={2} color="brandLight">
                    <EventIcon />
                  </Text>
                  <Text mx={1} fontWeight="bold">
                    {automation.name}
                  </Text>
                </Flex>
                {automation.actions.map((b) => {
                  let action = actions.find((a) => a.id === b);
                  return (
                    <Flex variant="box" flex={1} alignItems="center" m={1}>
                      <Flex
                        flex={1}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Flex alignItems="center" flex={1}>
                          <Text mx={2} color="gray.400">
                            {action.icon}
                          </Text>
                          <Text>{action.name}</Text>
                        </Flex>
                        <Flex justifyContent="flex-end" flex={1} mx={2}>
                          <Box>
                            <Button variant="ghost" m={1}>
                              <EditIcon />
                            </Button>
                          </Box>
                          <Box>
                            <CustomFormGroup
                              type="switch"
                              name="switch"
                              value={false}
                              onChange={() => {}}
                            />
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Page>
  );
}

export default ConversionAutomation;
