import React from "react";
import TaskCategoriesForm from "./TaskCategoriesForm";
import { Flex, Image } from "rebass";
import Settings from "..";

function TaskCategories(props) {
  return (
    <Settings>
      <Flex width="100%">
        <Flex flex={1} p={4} bg="white">
          <TaskCategoriesForm />
        </Flex>
      </Flex>
    </Settings>
  );
}

export default TaskCategories;
