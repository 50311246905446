import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Flex, Text, Button, Image, Box, Link } from "rebass";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import CurrentUserQuery from "../../../graphql/queries/CurrentUser.gql";
import CurrentAccountQuery from "../../../graphql/queries/CurrentAccount.gql";
import ImportActiveQuery from "../../../graphql/queries/ImportActive.gql";
import NotificationQuery from "../../../graphql/queries/Notifications.gql";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownDivider,
} from "styled-dropdown-component";
import { Input } from "@rebass/forms";
import moment from "moment";
import LogoutMutation from "../../../graphql/mutations/Logout.gql";
import ManualJobExecMutation from "../../../graphql/mutations/ManualJobExec";

import { getProducts } from "hooks/getProducts";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import NotificationMutation from "../../../graphql/mutations/Notification.gql";
import { getSearchFilter } from "hooks/getSearchFilter";
import {
  storeDefaultProduct,
  getDefaultProduct,
  serverHostname,
  redirectBaseUrlForSubdomain,
} from "../../../auth-helper";
import {
  ArrowDropDownIcon,
  ArrowUpIcon,
  AutomationIcon,
  ExitIcon,
  NotificationIcon,
  SearchIcon,
  SettingIcon,
  UserIcon,
  UsersIcon,
} from "Components/Common/Icons";
import { useTheme } from "emotion-theming";
import { clearStore } from "consts";
import { RiLogoutCircleRLine, RiRunLine } from "react-icons/ri";
import { FaExchangeAlt, FaNetworkWired } from "react-icons/fa";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { useToasts } from "react-toast-notifications";
import { ImpulseSpinner } from "react-spinners-kit";
import Avatar from "react-avatar";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import ImpersonateLink from "../../Common/ImpersonateLink";
import AdminOnly from "../../MyAccount/AdminOnly";
import DevelopmentOnly, {
  NotDevelopment,
} from "../../MyAccount/DevelopmentOnly";
import { GrGraphQl } from "react-icons/all";
import styled from "@emotion/styled";
import NotificationItem from "../../Notifications/NotificationItem";

export default function DefaultMenu(props) {
  const { addToast } = useToasts();
  const theme = useTheme();
  const history = useHistory();
  const { data: filters } = getSearchFilter();
  const productId = getDefaultProduct();
  const [saveFilter] = useMutation(SearchFilterMutation);
  const [markRead, {}] = useMutation(NotificationMutation, {
    refetchQueries: [{ query: NotificationQuery }],
  });
  const [manualJobExec] = useMutation(ManualJobExecMutation);
  const { data, client } = useQuery(CurrentUserQuery, {
    fetchPolicy: "network-only",
  });
  const { data: importData } = useQuery(ImportActiveQuery, {
    fetchPolicy: "network-only",
  });
  const currentAccount = useQuery(CurrentAccountQuery, {});
  const productsQuery = getProducts();
  const notificationQuery = useQuery(NotificationQuery, {});
  const currentUser = data && data.currentUser;

  const [hidden, setHidden] = useState(true);
  const [showAccountSwitcher, setShowAccountSwitcher] = useState(false);
  const [hiddenNotifications, setHiddenNotifications] = useState(true);
  const [searchText, setSearchText] = useState();
  const [hiddenProducts, setHiddenProducts] = useState(true);
  const [product, setProduct] = useState();
  const [logout] = useMutation(LogoutMutation, { fetchPolicy: "no-cache" });

  const notifications =
    notificationQuery.data?.notifications?.filter((n) => !n.readAt) || [];
  const products = productsQuery?.data?.products || [];

  const DropdownMenuItem = styled(DropdownItem)`
    cursor: pointer;
    user-select: none;
  `;

  const DropdownSwitchAccountMenu = styled(DropdownMenu)`
    position: absolute;
    left: -300px;
    top: -15px;
  `;

  const saveProductFilter = (productId) => {
    saveFilter({
      variables: {
        filters: {
          productId,
        },
      },
    }).then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
  };

  const storeProduct = (productId) => {
    clearStore(client);
    storeDefaultProduct(productId);
    window.location.reload();
  };

  const sidekiqClicked = () => {
    window.open(`${serverHostname()}/sidekiq`, "_sidekiq");
  };

  const graphiqlClicked = () => {
    window.open(`${serverHostname()}/graphiql`, "_graphiql");
  };

  const availableSwitchAccounts = (currentUser) => {
    if (!(currentAccount.data && currentUser)) {
      return [];
    }

    const subdomain = currentAccount.data?.currentAccount.subdomain;
    return currentUser.switchAccounts.filter((a) => a.subdomain !== subdomain);
  };

  useEffect(() => {
    let p = null;
    if (products && products.length > 0) {
      if (!!productId) {
        p = products.find((x) => x.id === productId);
      }
      if (!p) {
        p = products[0];
      }
      saveProductFilter(p.id);
      setProduct(p);
    }
  }, [filters, products, productId]);

  const logoutClicked = () => {
    const redirectToLogin = () => {
      const loginUrl = `${location.protocol}//home.${location.host.replace(
        /^[^.]+\./g,
        ""
      )}/login`;
      window.location.replace(loginUrl);
    };

    logout().then(
      (response) => {
        if (response.errors) {
          console.log(errors);
        }

        redirectToLogin();
      },
      (error) => {
        console.log(error);
        redirectToLogin();
      }
    );
  };

  return (
    <Flex
      sx={{
        zIndex: 2,
        height: "60px",
      }}
      width="100%"
      bg={currentUser?.impersonating ? "brandDark1" : "brandLight"}
      py={3}
    >
      <Flex pl={3} flex={1} alignItems="center" justifyContent="space-between">
        <Flex sx={{ position: "relative" }} alignItems="center">
          <Flex
            sx={{ position: "absolute", left: 1, top: 0, bottom: 0 }}
            width={35}
            color="white"
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <SearchIcon size={20} />
          </Flex>
          <Input
            variant="unstyled"
            width="100px"
            placeholder="Search"
            backgroundColor={`${theme.colors.brand}1A`}
            color="white"
            sx={{
              paddingLeft: 40,
              zIndex: 1,
              "&:focus": {
                outline: "none",
                width: "200px",
                border: "white solid 1px !important",
              },
              "&:hover": {
                width: "200px",
                outline: "none",
                border: "white solid 1px !important",
              },
              transition: "width 2s",
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                history.push(`/global-search/${searchText}`);
              }
            }}
          />
        </Flex>
        {importData?.importActive && (
          <Flex
            sx={{ borderRadius: "medium" }}
            bg={"brand"}
            p={2}
            alignItems="center"
          >
            <ImpulseSpinner
              size={15}
              frontColor={theme.colors.brand}
              backColor={theme.colors.white}
            />
            <Text mx={2} color="white">
              importing
            </Text>
          </Flex>
        )}
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            fontFamily: "body",
            color: "white",
          }}
        >
          <DevelopmentOnly>
            <Button
              variant="ghost2"
              sx={{
                bg: "transparent",
              }}
              hidden={true}
              onClick={graphiqlClicked}
            >
              <Text>
                <GrGraphQl />
              </Text>
            </Button>
            <Button
              variant="ghost2"
              sx={{
                bg: "transparent",
              }}
              hidden={true}
              onClick={sidekiqClicked}
            >
              <Text>
                <FaNetworkWired />
              </Text>
            </Button>
            <Button
              variant="ghost2"
              sx={{
                bg: "transparent",
              }}
              onClick={() => {
                manualJobExec({}).then(
                  (response) => {
                    addToast(
                      <Flex>
                        Running Background Jobs - Give it a minute :)
                      </Flex>,
                      SuccessParams
                    );
                  },
                  (error) => {}
                );
              }}
            >
              <Text>
                <RiRunLine />
              </Text>
            </Button>
          </DevelopmentOnly>

          <AdminOnly>
            <NotDevelopment>
              <Button
                variant="ghost2"
                sx={{
                  bg: "transparent",
                }}
                hidden={true}
                onClick={sidekiqClicked}
              >
                <Text>
                  <FaNetworkWired />
                </Text>
              </Button>
              <Button
                variant="ghost2"
                sx={{
                  bg: "transparent",
                }}
                onClick={() => {
                  manualJobExec({}).then(
                    (response) => {
                      addToast(
                        <Flex>
                          Running Background Jobs - Give it a minute :)
                        </Flex>,
                        SuccessParams
                      );
                    },
                    (error) => {}
                  );
                }}
              >
                <Text>
                  <RiRunLine />
                </Text>
              </Button>
            </NotDevelopment>
          </AdminOnly>
          {products.length > 1 ? (
            <Dropdown right>
              <Button
                variant="ghost2"
                dropdownToggle
                onClick={() => setHiddenProducts(!hiddenProducts)}
                mx={1}
                px={1}
              >
                <Flex alignItems="center" justifyContent="center">
                  <Box sx={{ borderRadius: 9999, width: 25, height: 25 }}>
                    <Image
                      src={product?.logoUrl}
                      sx={{ maxHeight: 25, maxWidth: 25 }}
                    />
                  </Box>
                  <Text fontWeight="bold" mx={2}>
                    {product?.name}
                  </Text>
                  <ArrowDropDownIcon />
                </Flex>
              </Button>

              <DropdownMenu
                hidden={hiddenProducts}
                toggle={() => setHiddenProducts(!hiddenProducts)}
              >
                {products.map((product) => (
                  <DropdownItem
                    key={product.id}
                    onClick={() => {
                      storeProduct(product.id);
                      setProduct(product);
                      setHiddenProducts(!hiddenProducts);
                    }}
                  >
                    <Flex alignItems="center" sx={{ cursor: "pointer" }}>
                      <Box sx={{ borderRadius: 9999, width: 25, height: 25 }}>
                        <Image
                          src={product.logoUrl}
                          sx={{ maxHeight: 25, maxWidth: 25 }}
                        />
                      </Box>
                      <Text mx={2} fontWeight="bold">{`${product.name}`}</Text>
                    </Flex>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          ) : (
            products.length === 1 &&
            products[0].logoUrl && (
              <Box sx={{ maxHeight: 35, maxWidth: 35 }}>
                <Image
                  src={products[0].logoUrl}
                  sx={{ width: 35, height: 35 }}
                />
              </Box>
            )
          )}
          <Dropdown right>
            <Button
              variant="ghost2"
              sx={{
                bg: "transparent",
              }}
              dropdownToggle
              onClick={() => setHiddenNotifications(!hiddenNotifications)}
              mx={1}
              px={1}
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                sx={{ position: "relative" }}
              >
                <NotificationIcon size={18} />
                {notifications.length > 0 && (
                  <Flex
                    variant="badge"
                    p={1}
                    bg="red"
                    color="white"
                    sx={{
                      margin: 0,
                      padding: 0,
                      position: "absolute",
                      top: -14,
                      right: 1,
                    }}
                  >
                    {notifications.length}
                  </Flex>
                )}
                <ArrowDropDownIcon />
              </Flex>
            </Button>
            <DropdownMenu
              right
              hidden={hiddenNotifications}
              toggle={() => setHiddenNotifications(!hiddenNotifications)}
            >
              {notifications.slice(0, 5).map((note) => (
                <DropdownItem
                  onClick={() => {
                    markRead({
                      variables: {
                        id: note.id,
                      },
                    }).then(
                      (response) => {},
                      (error) => {
                        console.log(error);
                      }
                    );
                    history.push(note.link);
                  }}
                  key={note.id}
                >
                  <NotificationItem note={note} />
                </DropdownItem>
              ))}
              <DropdownItem
                onClick={() => {
                  history.push("/notifications");
                }}
              >
                <Text
                  variant="muted"
                  color="brandLight"
                  sx={{ cursor: "pointer" }}
                  my={3}
                >{`Open Notifications (${notifications.length} unread)`}</Text>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Dropdown>
            <PrimaryButton
              dropdownToggle
              onClick={() => setHidden(!hidden)}
              rightIcon={<ArrowDropDownIcon />}
            >
              <Flex alignItems="center">
                <Avatar
                  src={currentUser?.avatarUrl}
                  size="25"
                  name={`${currentUser?.firstName} ${currentUser?.lastName}`}
                  email={currentUser?.email}
                  round
                  textSizeRatio={1}
                />
                <Text mx={2}>
                  {currentAccount && currentAccount.data?.currentAccount.name}
                </Text>
              </Flex>
            </PrimaryButton>
            <DropdownMenu
              right
              hidden={hidden}
              toggle={() => setHidden(!hidden)}
            >
              <DropdownMenuItem
                onClick={() => {
                  history.push("/settings/account/profile");
                }}
              >
                <Flex alignItems="center" color="primary">
                  <UserIcon />
                  <Text mx={1}>Edit Profile</Text>
                </Flex>
              </DropdownMenuItem>
              <DropdownDivider />
              <DropdownMenuItem
                onClick={() => {
                  history.push("/settings/account/");
                }}
              >
                <Flex alignItems="center" color="primary">
                  <SettingIcon />
                  <Text mx={1}>Settings</Text>
                </Flex>
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => {
                  history.push("/settings/product/automations");
                }}
              >
                <Flex alignItems="center" color="primary">
                  <AutomationIcon />
                  <Text mx={1}>Automation</Text>
                </Flex>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  history.push("/settings/account/users");
                }}
              >
                <Flex alignItems="center" color="primary">
                  <UsersIcon />
                  <Text mx={1}>Users</Text>
                </Flex>
              </DropdownMenuItem>
              <DropdownDivider />
              <DropdownMenuItem
                onClick={() => {
                  props.history.push("/settings/account/");
                }}
              >
                <Flex alignItems="center" color="primary">
                  <ArrowUpIcon />
                  <Text mx={1}>Upgrade Plan</Text>
                </Flex>
              </DropdownMenuItem>
              <DropdownDivider />
              <DropdownMenuItem
                onClick={() =>
                  availableSwitchAccounts(currentUser).length > 0 &&
                  setShowAccountSwitcher(!showAccountSwitcher)
                }
              >
                <Flex sx={{ position: "relative" }}>
                  <DropdownSwitchAccountMenu hidden={!showAccountSwitcher}>
                    {availableSwitchAccounts(currentUser).map((account) => (
                      <DropdownMenuItem
                        key={account.id}
                        onClick={() =>
                          window.location.replace(
                            `${redirectBaseUrlForSubdomain(
                              account.subdomain
                            )}/home`
                          )
                        }
                      >
                        <Flex alignItems="center" color="primary">
                          <Text mx={1}>
                            <strong>{account.name}: </strong>
                            {redirectBaseUrlForSubdomain(
                              account.subdomain,
                              true
                            )}
                          </Text>
                        </Flex>
                      </DropdownMenuItem>
                    ))}
                  </DropdownSwitchAccountMenu>
                </Flex>
                <Flex alignItems="center" color="primary">
                  {availableSwitchAccounts(currentUser).length > 0 && (
                    <FaExchangeAlt />
                  )}
                  <Text mx={2}>
                    {currentAccount && currentAccount.data?.currentAccount.name}
                  </Text>
                </Flex>
              </DropdownMenuItem>
              <DropdownDivider />
              <DropdownMenuItem onClick={logoutClicked}>
                <Flex alignItems="center" color="primary">
                  <ExitIcon />
                  <Text mx={1}>Logout</Text>
                </Flex>
              </DropdownMenuItem>
            </DropdownMenu>
          </Dropdown>

          <Box mx={2} />
          <AdminOnly>
            <ImpersonateLink />
          </AdminOnly>
          <Box mx={2} />
        </Flex>
      </Flex>
    </Flex>
  );
}
