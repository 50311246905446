import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Flex, Box, Button, Text } from "rebass";
import "react-contexify/dist/ReactContexify.min.css";
import { CalendarIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import { ColumnIcon, ListIcon } from "../../Common/Icons";
import RadioGroupButton from "../../Common/RadioGroupButton";
import { TaskCategories } from "./TaskDefaults";

function TaskMenu(props) {
  const [taskCategoryId, setTaskCategoryId] = useState(undefined);
  const categories = TaskCategories();
  return (
    <Flex alignItems="center">
      <Button
        variant={props.viewType === "list" ? "primary" : "secondary"}
        onClick={() => props.onViewChange("list")}
        sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      >
        <ListIcon />
      </Button>
      <Button
        variant={props.viewType === "column" ? "primary" : "secondary"}
        onClick={() => props.onViewChange("column")}
        sx={{ borderRadius: 0 }}
      >
        <ColumnIcon />
      </Button>
      <Button
        variant={props.viewType === "calendar" ? "primary" : "secondary"}
        onClick={() => props.onViewChange("calendar")}
        sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      >
        <CalendarIcon />
      </Button>
      <Box mx={2} />
      <Text mx={2} variant="muted">
        New
      </Text>
      <Box>
        <RadioGroupButton
          name="taskCategoryId"
          value={taskCategoryId}
          options={categories?.filter((c) => c.active)}
          onChange={(name, value) => {
            props.onAdd(value?.value);
          }}
        />
      </Box>
    </Flex>
  );
}

export default withRouter(TaskMenu);
