import React from "react";
import { withRouter } from "react-router-dom";
import { Flex, Image, Text, Button } from "rebass";
import moment from "moment";
import { getEntityTasks } from "hooks/getEntityTasks";
import Loader from "Components/Common/Loader";
import TaskIcon from "Components/CommandCenter/Tasks/TaskDefaults";
import Modal from "Components/Common/Modal";
import { useState } from "react";
import TaskForm from "Components/CommandCenter/Tasks/TaskForm";
import TaskList from "Components/CommandCenter/Tasks/TaskList";
import TasksQuery from "../../../graphql/queries/EntityTasks.gql";
import { useQuery } from "@apollo/client";

function Tasks(props) {
  const { loading, data, refetch } = useQuery(TasksQuery, {
    variables: { id: props.id, parentType: props.parentType },
  });
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(undefined);

  return (
    <Flex width="100%">
      <Modal
        title="Activity"
        open={open}
        onDismiss={() => {
          setOpen(false);
          setEvent(undefined);
        }}
        width="950px"
      >
        <Flex ml={2}>
          <TaskForm
            open
            id={event?.id}
            parentType={props.parentType}
            actionableId={props.id}
            actionableName={props.actionableName}
            onSave={() => {
              refetch({ id: props.id, parentType: props.parentType });
              setOpen(false);
              setEvent(undefined);
              props.onSave && props.onSave();
            }}
          />
        </Flex>
      </Modal>
      <Flex width="100%">
        <TaskList
          detailedView={false}
          title="Open Tasks"
          data={data?.tasksByEntity}
          loading={loading}
          onSelected={(event) => {
            setEvent(event);
            setOpen(true);
          }}
          onSave={() => {
            refetch({ id: props.id, parentType: props.parentType });
            props.onSave && props.onSave();
          }}
        />
      </Flex>
    </Flex>
  );
}

export default withRouter(Tasks);
