import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "rebass";
import { Link as RouterLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { classifyData, convertValue } from "./SatisfactionDefaults";
import Table from "../Table";

const scoreColumns = [
  {
    name: "Score",
    selector: (row) => row.value,
    sortable: true,
  },
  {
    name: "Responses",
    selector: (row) => row.total,
    sortable: true,
    right: true,
  },
  {
    name: "%",
    selector: (row) => row.percentage,
    sortable: true,
    right: true,
  },
];

const SatisfactionTable = (props) => {
  const { loading, data } = props;
  return (
    <Box>
      <Text variant="h4">Score breakdown</Text>
      <Box bg="white" p={2}>
        <Table
          title={""}
          defaultSortField={"value"}
          selectableRows={false}
          columns={scoreColumns}
          data={data}
          loading={loading}
        />
      </Box>
    </Box>
  );
};
SatisfactionTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
};

export default SatisfactionTable;
