import React from "react";
import { DragDropContext } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function DndContext(props) {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    props.onChange({
      items: props.items,
      draggableId: result.draggableId,
      droppableId: result.destination.droppableId,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>{props.children}</DragDropContext>
  );
}

export default DndContext;
