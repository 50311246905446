import React, { useState, useEffect } from "react";
import Page from "Components/Page";
import { Text, Flex, Button, Image, Box } from "rebass";
import { dataFilterOptions } from "consts";
import { useQuery } from "@apollo/client";
import { getCurrentUser } from "hooks/getCurrentUser";
import Avatar from "react-avatar";
import Loader from "Components/Common/Loader";
import { getDashboard } from "hooks/getDashboard";
import HomeTopbar from "./HomeTopbar";
import Select from "Components/Common/Forms/Select";
import CommandCenter from "./CommandCenter";
import RenewalSummary from "./RenewalSummary";
import PaidSummary from "./PaidSummary";
import MTDGrowth from "./MTDGrowth";
import HealthBreakdown from "./HealthBreakdown";
import AccountGrowth from "./AccountGrowth";
import ConversionSummary from "./ConversionSummary";
import LostAccounts from "./LostAccounts";
import EventSummary from "./EventSummary";
import CurrentUserQuery from "../../../graphql/queries/CurrentUser.gql";
import NRRSummary from "./NRRSummary";
import AccountEventSummary from "./AccountEventSummary";
import SentimentChart from "./SentimentGauge";
import SatisfactionChart from "./SatisfactionGauge";
import RevenueDashboardQuery from "../../../graphql/queries/RevenueDashboard";
import { searchFilters } from "../../../hooks/searchFilters";
import PropTypes from "prop-types";
import { userlotTrackEvent } from "../../../analytics/userlot-tracking";
import UserTeamSelect from "../../Filter/UserTeamSelect";
import HomeDashboard from ".";
import EndUserEventSummary from "./EndUserEventSummary";

function Home(props) {
  const [filterQuery, setFilter] = searchFilters();
  const { currentAccount, loading } = getCurrentUser();
  const filters = filterQuery.data?.filters;

  const { data, loading: dLoading, refetch } = getDashboard(filters);

  const dashboardQuery = useQuery(RevenueDashboardQuery, {
    variables: {
      searchFilters: {
        ownerUserId: filters?.ownerUserId,
        daysOffset: filters?.daysAgo,
      },
    },
  });
  const mrrData = dashboardQuery.data?.revenueDashboard || [];

  const dashboardLoading = dashboardQuery.loading && dLoading;

  if (loading)
    return (
      <HomeDashboard title="Dashboard">
        <Loader />
      </HomeDashboard>
    );
  return (
    <HomeDashboard title="Dashboard">
      <Flex width={2 / 3} flexDirection="column">
        <Flex>
          <NRRSummary
            currentAccount={currentAccount}
            data={data?.homeDashboard}
            loading={dashboardLoading}
            filters={filters}
            mrrData={mrrData}
          />
          <PaidSummary
            currentAccount={currentAccount}
            data={data?.homeDashboard}
            loading={dashboardLoading}
            filters={filters}
          />
          <ConversionSummary
            currentAccount={currentAccount}
            data={data?.homeDashboard}
            loading={dashboardLoading}
            filters={filters}
          />
        </Flex>
        <CommandCenter
          currentAccount={currentAccount}
          data={data?.homeDashboard}
          loading={dashboardLoading}
        />
        <Flex width="100%">
          <EventSummary loading={dashboardLoading} filters={filters} />
          <AccountEventSummary loading={dashboardLoading} filters={filters} />
          <EndUserEventSummary loading={dashboardLoading} filters={filters} />
        </Flex>
        <RenewalSummary filters={filters} data={data?.homeDashboard} />
        <Flex flexGrow={2} />
      </Flex>
      <Flex width={1 / 3} flexDirection="column">
        <MTDGrowth
          currentAccount={currentAccount}
          data={data?.homeDashboard}
          loading={dashboardLoading}
          filters={filters}
          mrrData={mrrData}
        />

        <AccountGrowth
          currentAccount={currentAccount}
          data={data?.homeDashboard}
          loading={dashboardLoading}
          filters={filters}
          mrrData={mrrData}
          days={filters?.daysAgo || 30}
        />

        <Flex justifyContent="space-between">
          <HealthBreakdown
            currentAccount={currentAccount}
            data={data?.homeDashboard}
            loading={dashboardLoading}
          />
        </Flex>
        <Flex justifyContent="space-between">
          <SatisfactionChart
            loading={dashboardLoading}
            scoreType="NPS"
            filters={filters}
          />
          <SentimentChart
            loading={dashboardLoading}
            scoreType="Satisfaction"
            filters={filters}
          />
        </Flex>
        <LostAccounts
          data={data?.homeDashboard}
          loading={dashboardLoading}
          filters={filters}
          mrrData={mrrData}
        />
        <Flex flexGrow={2} />
      </Flex>
    </HomeDashboard>
  );
}
Home.propTypes = {
  onChange: PropTypes.func,
};
export default Home;
