import React from "react";
import { Flex } from "rebass";
import SegmentForm from "./SegmentForm";

function SegmentEditor(props) {
  return (
    <Flex alignItems="center">
      <SegmentForm
        open={props.open}
        typeName={props.typeName}
        onClose={(segment) => props.onClose(segment)}
        segmentId={props.segmentId}
      />
    </Flex>
  );
}

export default SegmentEditor;
