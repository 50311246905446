import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import DonutGraph from "Components/Common/Graphs/DonutGraph";
import { useTheme } from "emotion-theming";
import { Label } from "@rebass/forms";
import SimpleStatCard from "Components/Dashboard/Widgets/SimpleStatCard";
import { CriticalIcon, TimerIcon, TimeIcon } from "Components/Common/Icons";
import { UserIcon, WarningIcon } from "Components/Common/Icons";
import { TiStopwatch } from "react-icons/ti";

function TicketStats({ data }) {
  const theme = useTheme();

  const unresolvedCount = data?.filter(
    (t) => t.status !== "Resolved" && t.status !== "Closed"
  ).length;
  //These may not return any information yet:
  const onHoldCount = data?.filter((t) => t.status == "Pending").length;
  const unassignedCount = data?.filter((t) => t.unassigned).length;
  //We dont track response times yet.
  const escalated = data?.filter((t) => t.escalated).length;

  let ticketPriorityData = [];
  ticketPriorityData.push({
    value: data?.filter((t) => t.priority <= 2).length,
    label: "Low",
    color: theme.colors.brandLight,
  });
  ticketPriorityData.push({
    value: data?.filter((t) => t.priority === 3).length,
    label: "Normal",
    color: theme.colors.brand,
  });
  ticketPriorityData.push({
    value: data?.filter((t) => t.priority === 4).length,
    label: "High",
    color: theme.colors.yellow,
  });
  ticketPriorityData.push({
    value: data?.filter((t) => t.priority === 5).length,
    label: "Critical",
    color: theme.colors.red,
  });

  //Not sure about these colors, need a second opinion. Also status is just displayed as a number in develop? Not sure why.
  let ticketStatusData = [];
  ticketStatusData.push({
    value: data?.filter((t) => t.status === "Open").length,
    label: "Open",
    color: theme.colors.brand,
  });
  ticketStatusData.push({
    value: data?.filter((t) => t.status === "Pending").length,
    label: "Pending",
    color: theme.colors.brandLight,
  });
  ticketStatusData.push({
    value: data?.filter((t) => t.status === "Resolved").length,
    label: "Resolved ",
    color: theme.colors.gray["500"],
  });
  ticketStatusData.push({
    value: data?.filter((t) => t.status === "Closed").length,
    label: "Closed",
    color: theme.colors.gray["300"],
  });

  if (ticketStatusData.length == 0) {
    ticketStatusData.push({
      value: 100,
      label: "",
      color: theme.colors.gray["200"],
    });
  }

  if (ticketPriorityData.length == 0) {
    ticketPriorityData.push({
      value: 100,
      label: "",
      color: theme.colors.gray["200"],
    });
  }

  return (
    <Flex flex={1} alignItems="center" justifyContent="center">
      <Flex px={3} alignItems="center">
        <DonutGraph
          data={ticketStatusData}
          width={120}
          value={
            <Flex
              color="gray.500"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Label>
                <Text fontSize="xxs">Status</Text>
              </Label>
            </Flex>
          }
        />
        <Flex minWidth="20px" />
        <DonutGraph
          data={ticketPriorityData}
          width={120}
          value={
            <Flex
              color="gray.500"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Label>
                <Text fontSize="xxs">Priority</Text>
              </Label>
            </Flex>
          }
        />
      </Flex>

      <Flex width="75%">
        <Flex flex={1}>
          <SimpleStatCard
            value={unresolvedCount || 0}
            label="Unresolved"
            icon={<CriticalIcon />}
          />
        </Flex>
        <Flex flex={1}>
          <SimpleStatCard
            value={onHoldCount || 0}
            label="Pending"
            icon={<TiStopwatch />}
          />
        </Flex>
        <Flex flex={1}>
          <SimpleStatCard
            value={unassignedCount || 0}
            label="Unassigned"
            icon={<UserIcon />}
          />
        </Flex>
        <Flex flex={1}>
          <SimpleStatCard
            value={escalated || 0}
            label="Escalated"
            icon={<WarningIcon />}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TicketStats;
