import React from "react";
import { Box, Flex, Text } from "rebass";
import { Elements } from "react-stripe-elements";
import Payment from "./Payment";

function StepTwo(props) {
  const onUpdate = (values) => {
    props.onUpdate(values);
    props.nextStep();
  };
  return (
    <Box
      flexDirection="column"
      width="100%"
      sx={{
        minWidth: 350,
      }}
    >
      <Text as="h4" variant="h3" mb={4}>
        Payment Details
      </Text>
      <Text variant="body" my={2}>
        Your selected {props.data?.name}
      </Text>
      <Flex alignItems="center" justifyContent="center">
        <Text as="h4" variant="h1" mb={4}>
          ${props.data?.price ? props.data?.price / 100 : 0}
        </Text>
        <Text>/month</Text>
      </Flex>
      <Elements>
        <Payment
          billingName={props.billingName}
          onUpdate={onUpdate}
          previousStep={props.previousStep}
        />
      </Elements>
    </Box>
  );
}

export default StepTwo;
