import React, { useState } from "react";
import { Box, Flex, Link, Text } from "rebass";
import TagsEditor from "../../../Common/Tags/TagsEditor";
import CheckboxGroup from "../../../Common/CheckboxGroup";
import Label from "../../../Common/Forms/Label";
import { useQuery } from "@apollo/client";
import EndUserCategoryQuery from "../../../../graphql/queries/EndUserCategory.gql";

export const allAccountUsers = [
  { value: "all", label: "All Users" },
  { value: "key", label: "Key" },
  { value: "active", label: "Active" },
  { value: "passive", label: "Passive" },
  { value: "inactive", label: "Inactive" },
];
function UserFilterForm(props) {
  const { data: userTypes } = useQuery(EndUserCategoryQuery);
  const { field, setFieldValue, fieldName, label, errors } = props;

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  const selectOptions = Array.isArray(field.config?.to)
    ? field.config?.to
    : [field.config?.to];

  const allUserTypes = userTypes?.endUserCategories.filter((c) => c.active);
  return (
    <Flex flex={1} flexDirection="column">
      <Box>
        <Label>{label}</Label>
        <Flex alignItems="center" mt={1}>
          <Box mx={2} width="800px">
            <CheckboxGroup
              name={`${defaultFieldName}.to`}
              selectedOptions={selectOptions}
              options={allUserTypes}
              onChange={(target) => {
                const selectedValue = target.target.value;
                let toValues = Array.isArray(field.config?.to)
                  ? field.config?.to
                  : [field.config?.to];

                if (_.includes(toValues, selectedValue)) {
                  toValues = toValues.filter((t) => t !== selectedValue);
                } else {
                  toValues.push(selectedValue);
                }
                setFieldValue(`${defaultFieldName}.to`, toValues);
              }}
            />
            {errors?.to && (
              <Text variant="muted" color="red">
                {errors.to}
              </Text>
            )}
          </Box>
        </Flex>
      </Box>
      <Flex mx={4} flex={1} alignItems="center">
        <Text variant="label" mt={2}>
          Filter Users By
        </Text>
        <Flex alignItems="center">
          <TagsEditor
            parentType="Company"
            label=""
            value={field.config?.triggerEntityTags}
            onChange={(tags) =>
              setFieldValue(`${defaultFieldName}.triggerEntityTags`, tags)
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default UserFilterForm;
