import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import SurveysQuery from "../../../../graphql/queries/Surveys.gql";
import MenuSelect from "../../../Common/Forms/MenuSelect";
import { Box, Flex, Link, Text } from "rebass";
import TagsEditor from "../../../Common/Tags/TagsEditor";
import CheckboxGroup from "../../../Common/CheckboxGroup";
import Label from "../../../Common/Forms/Label";

import { Formik } from "formik";
import * as Yup from "yup";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";
import UserFilterForm from "./UserFilterForm";
function SelectSurveyForm(props) {
  const { data, loading } = useQuery(SurveysQuery);

  const { field, setFieldValue, fieldName, handleBlur } = props;

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  const surveyOptions = data?.surveys?.map((c) => {
    return {
      value: c.id,
      title: c.name,
      icon: undefined,
      description: c.surveyType && `${c.surveyType?.toUpperCase()}`,
    };
  });
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        surveyQuestionId: Yup.string().required("Required"),
        to: Yup.array().min(1, "Required").required("Required").nullable(),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        return (
          <Box>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <UserFilterForm {...props} label="Audience" />
            <Flex alignItems="center" mt={3}>
              <MenuSelect
                type="list"
                placeholder="Survey"
                label="Survey"
                name={`${defaultFieldName}.surveyQuestionId`}
                value={field.config?.surveyQuestionId}
                onBlur={handleBlur}
                onChange={(name, option) => {
                  setFieldValue(`${defaultFieldName}.title`, option?.title);
                  setFieldValue(name, option?.value);
                }}
                options={surveyOptions}
              />
              <Text m={2} pt={2}>
                or create
                <Link href="/settings/product/surveys/new" mx={1}>
                  new survey
                </Link>
              </Text>
            </Flex>
            {errors.surveyQuestionId && (
              <Text variant="muted" color="red">
                {errors.surveyQuestionId}
              </Text>
            )}
          </Box>
        );
      }}
    </Formik>
  );
}

export default SelectSurveyForm;
