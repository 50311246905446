import React, { useEffect } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Flex, Button, Text, Box, Link } from "rebass";
import { getApplicationKeys } from "hooks/getApplicationKeys";
import Loader from "Components/Common/Loader";
import { HelpIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";

function ApplicationKeys(props) {
  const { id } = useParams();
  const history = useHistory();
  const { loading, data } = getApplicationKeys();

  if (loading) return <Loader />;
  return (
    <>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={1} justifyContent="space-between">
            <Text variant="h2">Application Keys</Text>
            <PrimaryButton
              onClick={() => {
                history.push("/settings/product/api/new");
              }}
            >
              <PlusIcon />
            </PrimaryButton>
          </Flex>

          {data &&
            data.apis?.map((api) => (
              <Flex
                key={api.id}
                variant="box"
                bg={api.id === id ? "brandHighlight" : "white"}
                sx={{
                  cursor: "pointer",
                  borderColor: api.id === id ? "brandLight" : "accent",
                }}
                p={2}
                my={2}
                flexDirection="column"
                onClick={() => history.push(`/settings/product/api/${api.id}`)}
              >
                <Text variant="h4" color="text">
                  {api.name}
                </Text>
                <Flex justifyContent="space-between">
                  <Text variant="muted">
                    {api.product ? api.product.name : "Global"}
                  </Text>
                </Flex>
              </Flex>
            ))}
          {(!data || data.apis.length === 0) && (
            <Flex p={2} my={2} flexDirection="column">
              <Text variant="muted">
                Create an application key and lets start integrating your
                product today.
              </Text>
              <Link href="#" my={2}>
                <Flex alignItems="center">
                  <HelpIcon /> <Text mx={2}>Help</Text>
                </Flex>
              </Link>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
}

export default withRouter(ApplicationKeys);
