import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import TagsQuery from "../graphql/queries/Tags.gql";

export function getTags(modelType) {
  const tagsQuery = useQuery(TagsQuery, {
    variables: {
      modelType: modelType,
    },
  });

  return {
    ...tagsQuery,
  };
}
