import React from "react";
import { Link, Flex, Text, Box } from "rebass";
import { Editor } from "@tinymce/tinymce-react";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import { getUsers } from "hooks/getUsers";
import { getCurrentUser } from "hooks/getCurrentUser";
import { CheckCircleIcon, MailIcon, SaveIcon } from "Components/Common/Icons";
import getEmailDomains from "hooks/getEmailDomains";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import EmailTemplateMutation from "../../../../graphql/mutations/EmailTemplate.gql";
import EmailTemplateDeleteMutation from "../../../../graphql/mutations/EmailTemplateDelete.gql";
import EmailTemplateTestMutation from "../../../../graphql/mutations/EmailTemplateTest.gql";
import EmailTemplateQuery from "../../../../graphql/queries/EmailTemplate.gql";
import EmailTemplatesQuery from "../../../../graphql/queries/EmailTemplates.gql";
import { getProduct } from "hooks/getProduct";

import "assets/styles/skin.css";
import TagsEditor from "../../../Common/Tags/TagsEditor";
import moment from "moment";

const tokens = [
  { text: "First Name", value: "{{firstName}}" },
  { text: "Last Name", value: "{{lastName}}" },
  { text: "Email", value: "{{email}}" },
  { text: "Product Name", value: "{{product}}" },
  { text: "Author", value: "{{author}}" },
];
function EmailTemplatesForm(props) {
  const { id } = useParams();
  const { data: currentProduct } = getProduct();
  const { addToast } = useToasts();
  const history = useHistory();
  const usersQuery = getUsers();
  const currentUser = getCurrentUser();
  const [save] = useMutation(EmailTemplateMutation, {
    refetchQueries: [
      {
        query: EmailTemplatesQuery,
        variables: {
          searchText: undefined,
          tags: [],
        },
        fetchPolicy: "network-only",
      },
    ],
  });
  const [destroy] = useMutation(EmailTemplateDeleteMutation, {
    refetchQueries: [
      {
        query: EmailTemplatesQuery,
        variables: {
          searchText: undefined,
          tags: [],
        },
        fetchPolicy: "network-only",
      },
    ],
  });
  const [send] = useMutation(EmailTemplateTestMutation);
  const { data: emailDomains } = getEmailDomains();

  const userOptions = usersQuery.data?.users?.map((user) => {
    return { value: user.id, label: user.name, firstName: user.firstName };
  });
  const { data, loading, refetch } = useQuery(EmailTemplateQuery, {
    variables: { id },
    skip: !id || id === "new",
  });
  const validatedDomains = emailDomains?.emailDomains
    .filter((domain) => domain.verified)
    .map((domain) => domain.domain);

  const emailTemplate = id &&
    id !== "new" && {
      ...data?.emailTemplate,
      fromUserId: data?.emailTemplate?.fromUser?.id,
      replyToUserId: data?.emailTemplate?.replyToUser?.id,
    };

  const sendTestTemplate = () => {
    send({
      variables: {
        id,
      },
    }).then(
      (response) => {
        if (response.data?.emailTemplateTest?.errors) {
          addToast(<Flex>Check your email!</Flex>, SuccessParams);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const verifyDomainIsValidated = (userId) => {
    const fromEmail = usersQuery.data?.users?.find(
      (user) => user.id === userId
    ).email;
    const fromDomain = fromEmail.substring(fromEmail.lastIndexOf("@") + 1);
    const okDomain = validatedDomains.includes(fromDomain);
    console.log(`We found a validated from? ${okDomain}`);
    return okDomain;
  };

  const saveTemplate = (values, actions) => {
    save({
      variables: {
        ...values,
        live: true,
        id: values.id ? values.id : null,
      },
    }).then(
      (response) => {
        if (response.data?.emailTemplate?.errors) {
          addToast(
            <Flex>{response.data?.emailTemplate?.errors[0]}</Flex>,
            ErrorParams
          );
        } else {
          actions.resetForm({
            values: response.data?.emailTemplate?.emailTemplate,
          });
          addToast(<Flex>Saved!</Flex>, SuccessParams);
          history.push("/settings/product/library?tab=emails");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={
          emailTemplate || {
            subject: "",
            body: "<p>Initial Email Body Content</p>",
            fromUserId: currentUser?.currentUser?.id,
            replyToUserId: currentUser?.currentUser?.id,
            includeUnsubscribe: true,
            live: true,
          }
        }
        autoComplete={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          subject: Yup.string().required("Required"),
          body: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          saveTemplate(values, actions);
        }}
      >
        {(formProps) => {
          const {
            values,
            setFieldValue,
            handleBlur,
            handleSubmit,
            dirty,
            errors,
          } = formProps;
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex flexGrow={1} flexDirection="column">
                <Flex justifyContent="space-between">
                  <Text variant="h2">Email Template</Text>

                  <Flex flex={1} justifyContent="flex-end">
                    <DeleteButton
                      callback={destroy}
                      refetch={refetch}
                      onCancel={() => history.goBack()}
                      id={id}
                      name="Email Template"
                    />
                    {values.id && (
                      <Flex mr={3}>
                        <SecondaryButton
                          onClick={sendTestTemplate}
                          leftIcon={<MailIcon />}
                        >
                          Send Test
                        </SecondaryButton>
                      </Flex>
                    )}
                    <PrimaryButton
                      leftIcon={<SaveIcon />}
                      onClick={handleSubmit}
                    >
                      Save
                    </PrimaryButton>
                  </Flex>
                </Flex>
                <Flex flexDirection="column" mx={3} px={2}>
                  <CustomFormGroup
                    placeholder="Name"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors && errors.name && errors.name}
                  />
                  <CustomFormGroup
                    placeholder="Subject"
                    label="Subject"
                    name="subject"
                    value={values.subject}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors && errors.subject && errors.subject}
                  />
                </Flex>
                <Box px={4}>
                  <TagsEditor
                    parentType="EmailTemplate"
                    label="Category"
                    value={values.tags}
                    onChange={(tags) => setFieldValue("tags", tags)}
                  />
                </Box>
                <Box my={2} />
                <Flex
                  p={3}
                  bg={
                    currentProduct?.currentProduct?.brandBgColor || "gray.100"
                  }
                  flexDirection="column"
                  alignItems={"center"}
                >
                  <Flex sx={{ width: 800 }} flexDirection="column">
                    <Flex px={3} width="100%">
                      {currentProduct?.currentProduct?.emailHeader ? (
                        <Flex
                          width="100%"
                          dangerouslySetInnerHTML={{
                            __html: currentProduct?.currentProduct.emailHeader,
                          }}
                        ></Flex>
                      ) : (
                        <Link href="/settings/product/general">
                          Set up default email header
                        </Link>
                      )}
                    </Flex>
                    <Flex flex={1} flexDirection="column">
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <Editor
                          apiKey="8ud3ffgr7gphz0gnv98gughb6nw20ifeanz8fw6htk7vch8a"
                          value={values.body}
                          tagName="div"
                          init={{
                            skin: "CUSTOM",
                            toolbar:
                              "undo redo | formatselect | bold italic | forecolor backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | image link | tokens | removeformat | code",
                            plugins:
                              "autoresize code link lists quickbars noneditable image paste",
                            quickbars_selection_toolbar:
                              "tokens | removeformat ",
                            quickbars_insert_toolbar: false,
                            menubar: false,
                            noneditable_regexp: /\{\{[^\}]+\}\}/g, // matches {{handlebar}},
                            noneditable_editable_class: "mceEditable",
                            content_style: `
                .mceNonEditable {
                    background-color: #D6F0FF;
                    padding: 1px 0;
                    color: #44719B;
                    font-family: "Inter", "Courier New", monospace;
                    font-size: 0.9375em;
                }
            `,
                            setup: (editor) => {
                              // Register a custom toolbar menu button to insert tokens
                              // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#menubutton
                              editor.ui.registry.addMenuButton("tokens", {
                                text: "Merge Tags",
                                tooltip: "Insert merge tag",
                                fetch: (callback) => {
                                  const items = tokens.map((token) => {
                                    return {
                                      type: "menuitem",
                                      text: token.text,
                                      onAction: () => {
                                        // Insert content at the location of the cursor.
                                        // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#insertcontent
                                        editor.insertContent(token.value);
                                      },
                                    };
                                  });
                                  callback(items);
                                },
                              });
                            },
                          }}
                          onEditorChange={(content) =>
                            setFieldValue("body", content)
                          }
                        />
                      </Box>
                    </Flex>
                    <Flex width="100%">
                      {currentProduct?.currentProduct?.emailFooter ? (
                        <Flex
                          width="100%"
                          dangerouslySetInnerHTML={{
                            __html: currentProduct?.currentProduct.emailFooter,
                          }}
                        ></Flex>
                      ) : (
                        <Link href="/settings/product/general">
                          Set up default email footer
                        </Link>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex mt={2}>
                  <Flex flexDirection="column">
                    <Text variant="label">Created By</Text>
                    <Text>{values.createdBy?.name}</Text>
                  </Flex>
                  <Flex flexDirection="column" mx={3}>
                    <Text variant="label">On</Text>
                    <Text>
                      {moment(values.createdAt).format("DD MMM YYYY")}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default EmailTemplatesForm;
