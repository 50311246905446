import React from "react";
import { useMutation } from "@apollo/react-hooks";
import EntityDeleteMutation from "../../graphql/mutations/EntityDelete.gql";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text, Box } from "rebass";
import AdminOnly from "../MyAccount/AdminOnly";
import { MODEL_TYPES } from "../../consts";
import ImpersonateLink from "../Common/ImpersonateLink";

function EntityAdminMenu({ id, parentType, companyId, onDelete }) {
  const [destroy] = useMutation(EntityDeleteMutation);

  const history = useHistory();
  const menuButtons = [];
  window.scrollTo(0, 0);

  if (parentType == MODEL_TYPES.EndUser.name && companyId) {
    menuButtons.push(<ImpersonateLink userId={id} companyId={companyId} />);
  }

  return (
    <AdminOnly>
      <Flex alignItems="center" justifyContent="flex-end">
        {menuButtons.map((m) => m)}
      </Flex>
    </AdminOnly>
  );
}
EntityAdminMenu.propTypes = {
  parentType: PropTypes.string,
  id: PropTypes.string,
  companyId: PropTypes.string, // Only used in EndUser??
  onDelete: PropTypes.func,
};
export default EntityAdminMenu;
