import React, { useState } from "react";
import { Flex, Text, Link, Box } from "rebass";
import Table from "Components/Common/Table";
import { Link as RouterLink, useParams } from "react-router-dom";
import Loader from "Components/Common/Loader";
import { getEventGroupSummary } from "hooks/getEvents";
import moment from "moment";
import Select from "Components/Common/Forms/Select";
import { getData } from "hooks/getData";
import { convertNumber, dataFilterOptions } from "consts";
import LineGraph from "Components/Common/Graphs/LineGraph";
import EventPage from ".";
import TableEmpty from "../TableEmpty";
import DoubleStatCard from "../../Dashboard/Widgets/DoubleStatCard";
import { MODEL_TYPES } from "../../../consts";
import EntityAdminMenu from "../EntityAdminMenu";

const columns = [
  {
    name: "Account",
    selector: (row) => row.companyName,
    sortable: true,
    cell: (row) => (
      <RouterLink
        data-tag="allowRowEvents"
        to={`/adoption/accounts/${row.companyId}`}
        component={Link}
      >
        {row.companyName}
      </RouterLink>
    ),
  },
  {
    name: "User",
    selector: (row) => row.endUserName,
    sortable: true,
    cell: (row) => (
      <RouterLink
        data-tag="allowRowEvents"
        to={`/adoption/accounts/${row.companyId}/users/${row.endUserId}`}
        component={Link}
      >
        {row.endUserName}
      </RouterLink>
    ),
  },
  {
    name: "Count",
    selector: (row) => row.count,
    sortable: true,
  },
  {
    name: "Last Seen",
    selector: (row) => row.lastSeenAt,
    sortable: true,
    cell: (row) => {
      return moment(row.lastSeenAt).format("DD MMM YYYY hh:mma");
    },
  },
];

function Event(props) {
  const { id } = useParams();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const parentId = params.get("i");
  const parentType = params.get("t");
  const [daysAgo, setDaysAgo] = useState({ value: 90, label: "Last 90 days" });
  const { data: parentData } = getData(MODEL_TYPES["Event"], parentId);
  const { loading, data } = getEventGroupSummary(
    id,
    daysAgo?.value,
    parentId,
    parentType
  );

  const parent =
    parentType === MODEL_TYPES.Company.name
      ? parentData?.company
      : parentData?.endUser;

  const summary = data?.eventGroupSummary?.summary[0];
  let graphData = [];

  let eventCategory = data?.eventGroupSummary?.eventCategory;
  let groups = data?.eventGroupSummary?.eventGroups;

  graphData = data?.eventGroupSummary?.eventSummary.map((d) => {
    const count =
      d.eventGroups && d.eventGroups.length > 0
        ? d.eventGroups.map((g) => g.count).reduce((acc, v, i, a) => acc + v)
        : 0;
    return {
      date: moment(d.date),
      count,
    };
  });

  return (
    <EventPage {...props}>
      <Flex bg="white" px={4} flexGrow={1} flex={1} justifyContent="center">
        <Flex flex={1} flexDirection="column">
          <Flex justifyContent="space-between">
            <Flex alignItems="center">
              <Text variant="h2">{eventCategory?.name}</Text>
              {parent && (
                <Flex mx={2}>
                  <RouterLink
                    data-tag="allowRowEvents"
                    to={
                      parentType == "Company"
                        ? `/adoption/accounts/${parent?.id}`
                        : `/adoption/accounts/${parent?.company?.id}/users/${parent.id}`
                    }
                    component={Link}
                  >
                    for {parent?.name}
                  </RouterLink>
                </Flex>
              )}
            </Flex>
            <Box>
              <Select
                width="180px"
                value={daysAgo}
                options={dataFilterOptions}
                onChange={(name, value) => {
                  setDaysAgo(value);
                }}
              />
            </Box>
          </Flex>
          <Flex my={2} alignItems="center">
            <Text variant="muted" ml={2}>
              Contains events:
            </Text>
            {groups?.map((group) => (
              <Flex
                variant="badge"
                alignItems="center"
                justifyContent="center"
                bg="gray.200"
                fontSize="xs"
                key={group.id}
                mr={2}
              >
                {group.name}
              </Flex>
            ))}
          </Flex>
          <Flex justifyContent="center" variant="box" mb={3}>
            <Flex flex={1} flexDirection="column">
              <Text
                color="gray.700"
                fontWeight="bold"
                mx={3}
                my={2}
                fontSize="xs"
              >
                Events
              </Text>
              <DoubleStatCard
                value={convertNumber(summary?.count, 0)}
                value2={convertNumber(summary?.avg)}
                label="Total"
                label2="Daily AVG"
                title="Events"
                increase={summary?.increase}
              />
            </Flex>

            <Box
              sx={{
                borderRightColor: "accent",
                borderRightStyle: "solid",
                borderRightWidth: 1,
              }}
              my={3}
            />
            <Flex flex={1} flexDirection="column">
              <Text
                color="gray.700"
                fontWeight="bold"
                mx={3}
                my={2}
                fontSize="xs"
              >
                Active Accounts
              </Text>
              <DoubleStatCard
                value={convertNumber(summary?.companyCount, 0)}
                value2={convertNumber(summary?.companyAvg)}
                label="Total"
                label2="Daily AVG"
                increase={summary?.increase}
              />
            </Flex>
            <Box
              sx={{
                borderRightColor: "accent",
                borderRightStyle: "solid",
                borderRightWidth: 1,
              }}
              my={3}
            />
            <Flex flex={1} flexDirection="column">
              <Text
                color="gray.700"
                fontWeight="bold"
                mx={3}
                my={2}
                fontSize="xs"
              >
                Active Users
              </Text>
              <DoubleStatCard
                value={convertNumber(summary?.userCount, 0)}
                value2={convertNumber(summary?.userAvg)}
                label="Total"
                label2="Daily AVG"
                title="Active Users"
                increase={summary?.increase}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <Text
              color="gray.700"
              fontWeight="bold"
              mx={3}
              my={2}
              fontSize="xs"
            >
              Events trending
            </Text>
            <LineGraph
              days={daysAgo?.value || 90}
              series={[
                {
                  name: eventCategory?.name,
                  data: graphData,
                },
              ]}
              ytitle="Count"
              height={200}
            />
          </Flex>
          <Box my={3} />
          <Text color="gray.700" fontWeight="bold" mx={3} my={2} fontSize="xs">
            Summary
          </Text>
          <Table
            defaultSortField="name"
            selectableRows={false}
            columns={columns}
            data={data?.eventGroupSummary?.events}
            loading={loading}
            noDataText={
              <TableEmpty help="No data returned, check selected filters." />
            }
          />
        </Flex>
      </Flex>
    </EventPage>
  );
}

export default Event;
