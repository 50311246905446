import { useTheme } from "emotion-theming";
import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { Flex } from "rebass";
import { SpinnerDotted, SpinnerRoundFilled } from "spinners-react";

function AnimatedIcon(props) {
  return props.large ? (
    <Flex alignItems="center" justifyContent="center">
      <SpinnerDotted size={50} thickness={100} speed={100} color="#DDECF9" />
    </Flex>
  ) : (
    <Flex alignItems="center" justifyContent="center">
      <SpinnerRoundFilled
        size={40}
        thickness={75}
        speed={125}
        color="#DDECF9"
      />
    </Flex>
  );
}

export function UploadAnimatedIcon(props) {
  return (
    <Flex alignItems="center" justifyContent="center">
      <SpinnerDotted size={50} thickness={100} speed={100} color="#DDECF9" />
    </Flex>
  );
}
export function ErrorAnimatedIcon(props) {
  const theme = useTheme();
  return (
    <Flex alignItems="center" justifyContent="center">
      <RiErrorWarningLine size={40} color={theme.colors.red} />
    </Flex>
  );
}
export default AnimatedIcon;
