import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";

function StepThree(props) {
  return (
    <Formik
      initialValues={{
        accountName: "",
        productName: "",
        externalId: "doesnotmatter",
        subdomain: "",
      }}
      validationSchema={Yup.object().shape({
        accountName: Yup.string().required("Required"),
        productName: Yup.string().required("Required"),
      })}
      autoComplete={false}
      onSubmit={(values, actions) => {
        props.onUpdate(values);
        props.nextStep();
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;

        return (
          <Flex
            flexDirection="column"
            justifyContent="center"
            noValidate
            width="100%"
            sx={{
              minWidth: 350,
            }}
            py={4}
          >
            <Text variant="h2" my={4} color="brandLight">
              Tell me about your company & product?
            </Text>
            <Flex alignItems="center" flexDirection="column">
              <CustomFormGroup
                placeholder="Company Name"
                label="Company Name"
                name="accountName"
                value={values.accountName}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
                fieldHelpText="Your company name to be used for your userlot account"
                onBlur={() => {
                  let subdomain = values.accountName
                    ?.toLowerCase()
                    .replace(/\s/g, "");
                  //
                  // externalId =
                  //   externalId?.charAt(0).toLowerCase() +
                  //   externalId?.substring(1);
                  //
                  // if (!values.externalId || values.externalId === "") {
                  //   setFieldValue("externalId", externalId);
                  // }
                  if (!values.subdomain || values.subdomain === "") {
                    setFieldValue("subdomain", subdomain);
                  }
                  // handleBlur();
                }}
                helperText={errors.accountName && errors.accountName}
              />
              <CustomFormGroup
                placeholder="Product Name"
                label="Product Name"
                name="productName"
                value={values.productName}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
                onBlur={handleBlur}
                helperText={errors.productName && errors.productName}
              />
              {/*<CustomFormGroup*/}
              {/*  placeholder="External Id"*/}
              {/*  label="Product Id"*/}
              {/*  name="externalId"*/}
              {/*  value={values.externalId}*/}
              {/*  onChange={(name, value) => {*/}
              {/*    setFieldValue(name, value);*/}
              {/*  }}*/}
              {/*  fieldHelpText="Unique id that you send from your product to identify it in Userlot"*/}
              {/*  onBlur={handleBlur}*/}
              {/*  helperText={errors.externalId && errors.externalId}*/}
              {/*/>*/}
            </Flex>

            <Flex mt={2} justifyContent="space-between">
              <SecondaryButton onClick={props.previousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton onClick={handleSubmit}>Next</PrimaryButton>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
}

export default StepThree;
