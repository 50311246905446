import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Box, Flex, Link, Text } from "rebass";
import Select from "Components/Common/Forms/Select";
import Loader from "Components/Common/Loader";
import { getEndUserData } from "hooks/getEndUserData";
import EndUserMutation from "../../../graphql/mutations/EndUser";
import CompaniesQuery from "../../../graphql/queries/CompaniesList.gql";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import Modal from "Components/Common/Modal";
import Label from "../../Common/Forms/Label";
import GhostButton from "../../Common/Buttons/GhostButton";
import { ArrowRightIcon } from "../../Common/Icons";
import { useHistory, useParams } from "react-router-dom";
import { MODEL_TYPES } from "consts";

function AssignAccount(props) {
  const { addToast } = useToasts();
  const { companyId: defaultCompanyId } = useParams();

  const history = useHistory();
  const [open, setOpen] = useState(undefined);
  const [companyId, setCompanyId] = useState(undefined);
  const { loading, data, error } = getEndUserData(props.id, defaultCompanyId);
  const [save, { loading: saving }] = useMutation(EndUserMutation);
  const companyQuery = useQuery(CompaniesQuery, { skip: !open });
  const endUser = data && data.endUser;

  const companyItems =
    companyQuery?.data?.companiesNameSearch.map((d) => {
      return { value: d.id, label: d.name, type: MODEL_TYPES.Company.name };
    }) || [];
  const options = companyItems.map((item) => {
    return {
      value: `${item.value}`,
      label: item.label,
      type: item.type,
      name: item.label,
      id: item.value,
    };
  });

  const baseRoute = "/contacts";
  const route = `${baseRoute}/accounts/${endUser?.company?.id}`;
  const AssignAccount = () => {
    save({
      variables: {
        ...endUser,
        companyId: companyId,
      },
    }).then(
      (response) => {
        if (!response.data?.endUser?.errors) {
          setOpen(false);
          const route = `${baseRoute}/accounts/${companyId}/users/${props.id}`;
          history.push(route);
        } else {
          addToast(<Flex>{response.data?.endUser?.errors[0]}</Flex>, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <>
      <Box my={2}>
        <Label>Account</Label>
        <Flex alignItems={"center"}>
          {endUser?.company?.name ? (
            <Link href={route}>{endUser?.company?.name}</Link>
          ) : (
            <Flex alignItems={"center"}>
              <Text variant="muted">No accounts assigned</Text>
              <GhostButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <ArrowRightIcon />
              </GhostButton>
            </Flex>
          )}
        </Flex>
      </Box>
      <Modal
        title={"Assign Account"}
        width={900}
        open={open}
        onDismiss={() => {
          setOpen(false);
        }}
      >
        <Flex pb={4} sx={{ minHeight: 500 }} flex={1}>
          <Flex flexDirection="column" px={3} flex={1}>
            {saving ? (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Loader />
                <Text mx={2}>
                  Please wait we are updating your user's account
                </Text>
              </Flex>
            ) : (
              <Flex flexDirection={"column"} flex={1}>
                <Select
                  placeholder="Account"
                  label="Account"
                  name="companyId"
                  value={options?.find((o) => o.value === `${companyId}`)}
                  options={options}
                  onChange={(name, option) => setCompanyId(option?.value)}
                  width="100%"
                />
                <Flex justifyContent={"flex-end"}>
                  <PrimaryButton onClick={() => AssignAccount()}>
                    Assign
                  </PrimaryButton>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}

export default AssignAccount;
