import React, { useState, useEffect } from "react";
import { Input } from "@rebass/forms";
import { Text, Flex, Box, Link } from "rebass";
import { CloseIcon, PlusIcon } from "./Icons";
import PropTypes from "prop-types";

ListEditor.propTypes = {
  parentType: PropTypes.string,
  value: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

const _ = require("lodash");

export default function ListEditor({ parentType, value, label, onChange }) {
  const [editMode, setEditMode] = useState(false);
  const [tags, setTags] = useState(value || []);
  const [tag, setTag] = useState(undefined);

  useEffect(() => {
    if (value && tags.length !== value.length) {
      setTags(value);
    }
  }, [value]);

  useEffect(() => {
    if (!_.isEqual((value || []).sort(), (tags || []).sort())) {
      onChange(tags);
    }
  }, [tags]);

  return (
    <Flex flexDirection="column" justifyContent="center">
      <Text variant="label">{label}</Text>
      <Flex flexWrap="wrap" alignItems="center" justfiyContent="center">
        {tags.map((t) => (
          <Flex
            height={30}
            key={t}
            bg="gray.200"
            mx={1}
            mt={2}
            px={1}
            sx={{ borderRadius: 10 }}
            alignItems="center"
            justfiyContent="center"
          >
            <Flex alignItems="center" justfiyContent="space-between" pl={1}>
              <Flex alignItems="center">
                <Text mx={1} variant="muted">
                  {t}
                </Text>
              </Flex>
              <Flex
                width={30}
                alignItems="center"
                justifyContent="center"
                color="line"
                onClick={() => {
                  setTags(
                    _.reject(tags, (o) => {
                      return o === t;
                    })
                  );
                  setEditMode(undefined);
                }}
              >
                <CloseIcon size={20} />
              </Flex>
            </Flex>
          </Flex>
        ))}
        {editMode && (
          <Flex flexDirection={"column"} sx={{ position: "relative" }}>
            <Flex alignItems={"center"}>
              <Flex
                pl={2}
                pt={2}
                alignItems="center"
                sx={{ position: "relative", width: editMode ? 200 : 0 }}
              >
                <Input
                  bg="white"
                  sx={{
                    zIndex: 1,
                  }}
                  placeholder=""
                  value={tag}
                  onChange={(e) => {
                    setTag(_.trimStart(e.target.value));
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13 || e.key === "Enter") {
                      if (tag !== "") {
                        setTags(_.union(tags, [tag]));
                      }
                      setTag("");
                      setEditMode(false);
                    }
                  }}
                />
                <Flex
                  sx={{
                    position: "absolute",
                    right: 1,
                    top: 1,
                    bottom: 0,
                    zIndex: 2,
                  }}
                  width={35}
                  alignItems="center"
                  justifyContent="center"
                  color="line"
                  p={2}
                  onClick={() => {
                    setTag("");
                    setEditMode(false);
                  }}
                >
                  <CloseIcon size={20} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        {!editMode && (
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="brandLight"
            color="white"
            mx={1}
            mt={2}
            onClick={() => {
              setEditMode(true);
            }}
            sx={{
              borderRadius: 999,
              width: 30,
              height: 30,
              cursor: "pointer",
              "&:hover": { backgroundColor: "brand" },
            }}
          >
            <PlusIcon />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
