import React from "react";
import CustomFormGroup from "../../../../Common/Forms/CustomFormGroup";

const UserpassForm = ({values, errors, setFieldValue, handleBlur}) => {
  return (
    <>
      <CustomFormGroup
        placeholder="Username"
        label="UsernameXXX"
        name="username"
        value={values.username}
        onChange={setFieldValue}
        onBlur={handleBlur}
        helperText={errors && errors.username && errors.username}
      />
      {!values.verified && (
        <CustomFormGroup
          placeholder="Password"
          type="password"
          label="Password"
          name="password"
          value={values.password}
          onChange={setFieldValue}
          onBlur={handleBlur}
          helperText={errors && errors.password && errors.password}
        />
      )}
    </>
  );
} 

export default UserpassForm;
