import React, { useState, useEffect } from "react";
import { Text, Flex, Box, Image, Button } from "rebass";
import PropTypes from "prop-types";
import Loader from "Components/Common/Loader";
import moment from "moment";
import { useTheme } from "emotion-theming";
import { getData } from "hooks/getData";
import { MODEL_TYPES } from "consts";
import { CheckCircleIcon } from "Components/Common/Icons";
import { getLifeCycles } from "hooks/getLifeCycles";
import ProgressBar from "@ramonak/react-progress-bar";
import Label from "../../Common/Forms/Label";
import SimpleStatCard from "../../Dashboard/Widgets/SimpleStatCard";
import {
  ArrowDropDownIcon,
  CalendarIcon,
  CheckCircleOffIcon,
  PlaybookIcon,
  TasksIcon,
  WarningIcon,
  WorkflowIcon,
} from "../../Common/Icons";
import CompanyMenuModal from "../../Dashboard/CompanyHealth/CompanyMenuModal";
import { useHistory } from "react-router";
import {
  RiCheckboxBlankFill,
  RiCheckLine,
  RiClockwiseLine,
} from "react-icons/ri";
import { FaThumbsUp } from "react-icons/fa";

const _ = require("lodash");

function LifeCycle(props) {
  const theme = useTheme();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [lifeCycleStageId, setLifeCycleStageId] = useState();
  const { data: lcData } = getLifeCycles();

  const {
    data: entityData,
    loading,
    refetch,
  } = getData(MODEL_TYPES[props.parentType], props.id);
  const currentLifeCycle = entityData?.company?.details?.lifeCycleStage;
  const currentStatus = entityData?.company?.details?.status;
  const lifeCycles =
    lcData?.lifeCycles?.find((l) => l.name === currentStatus?.name)?.stages ||
    [];

  const companyPlan = entityData?.company?.details?.companyPlan;
  useEffect(() => {
    if (currentLifeCycle) {
      setLifeCycleStageId(currentLifeCycle.id);
    }
  }, [currentLifeCycle]);

  const companyPlanSteps = companyPlan?.companyPlanSteps.filter(
    (s) => s.lifeCycleStageId === lifeCycleStageId
  );

  const overdueItems =
    companyPlanSteps
      ?.filter((s) => !s.completedAt)
      .filter((s) =>
        moment()
          .utc()
          .endOf("day")
          .isAfter(moment(s.expectedCompletedAt).endOf("day"))
      ).length || 0;

  const daysToComplete =
    moment().diff(moment(companyPlan?.startedAt), "days") + 1;

  const maxStartDay = _.maxBy(companyPlanSteps, "startDay");
  const maxDuration = maxStartDay?.durationDays;
  const fullDuration = maxStartDay?.startDay + maxDuration || 0;

  const totalDaysDisplay = fullDuration < 14 ? 14 : fullDuration;

  const totalDuration = moment(companyPlan?.expectedCompletedAt).diff(
    moment(companyPlan?.startedAt),
    "days"
  );
  const completed =
    companyPlanSteps?.filter((s) => !s.completedAt).length === 0;
  const completedSteps = companyPlanSteps?.filter((s) => s.completedAt).length;
  const totalSteps = companyPlanSteps?.length;

  const percentComplete = completed
    ? 100
    : totalDuration > 0
    ? ((completedSteps / totalSteps) * 100).toFixed(0)
    : 0;

  return (
    <Flex flexGow={1} flex={1}>
      <Flex flex={1} flexGrow={1} bg="white" flexDirection="column">
        <Text variant="h2">Lifecycle</Text>
        {loading && <Loader />}
        {!loading && !companyPlan ? (
          <Flex
            p={4}
            flexDirection="column"
            bg="gray.100"
            alignItems="center"
            justifyContent="center"
            sx={{ borderRadius: "medium" }}
          >
            <Flex
              sx={{
                height: "450px",
                boxShadow: "large",
                borderRadius: "medium",
              }}
              bg="white"
              p={4}
            >
              <Image
                src={require("assets/images/LifecycleEmptyState.png")}
                sx={{ height: "400px", objectFit: "contain" }}
              />
            </Flex>
            <Box my={3} />
            <Text variant="muted">
              Stage plan configuration has not been completed.
            </Text>
          </Flex>
        ) : (
          <Flex my={3} flexDirection="column" flex={1}>
            <Flex>
              <Button
                variant="default"
                bg="brandHighlight"
                color="brandLight"
                ml={0}
                mr={1}
                py={0}
                onClick={() => {
                  setOpenModal(true);
                }}
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                  height: 30,
                  marginLeft: 30,
                  marginRight: 30,
                  paddingLeft: 25,
                  position: "relative",
                  borderRadius: 0,
                  borderTopLeftRadius: 99,
                  borderBottomLeftRadius: 99,
                }}
              >
                <Flex alignItems="center" justifyContent="space-between">
                  <Box>
                    <Text mx={2}>{companyPlan?.name}</Text>
                  </Box>
                  <ArrowDropDownIcon />
                </Flex>
              </Button>
              {lifeCycles?.map((lc, i) => (
                <Flex key={i} alignItems="center">
                  <Button
                    variant="default"
                    mx={1}
                    bg={lifeCycleStageId === lc.id ? "brand" : "gray.200"}
                    color={lifeCycleStageId === lc.id ? "white" : "brandLight"}
                    ml={0}
                    mr={1}
                    py={0}
                    my={0}
                    key={lc.id}
                    onClick={() => setLifeCycleStageId(lc.id)}
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      height: 30,
                      marginLeft: 30,
                      marginRight: 30,
                      paddingLeft: i === 0 ? 12 : 30,
                      paddingRight: i === lifeCycles.length - 1 ? 20 : 0,
                      position: "relative",
                      borderRadius: 0,
                      borderTopRightRadius:
                        i === lifeCycles.length - 1 ? 99 : 0,
                      borderBottomRightRadius:
                        i === lifeCycles.length - 1 ? 99 : 0,
                      "&:before": {
                        // triangle hover color
                        borderLeft: i === 0 ? 0 : `15px solid white`,
                        // the triangle
                        content: `''`,
                        position: "absolute",
                        borderBottom: "15px solid transparent",
                        borderTop: "15px solid transparent",
                        height: 0,
                        width: 0,
                        marginTop: 0,
                        left: 0,
                        top: 0,
                        zIndex: 1,
                      },
                      "&:after": {
                        // triangle hover color
                        borderLeft:
                          i < lifeCycles.length - 1
                            ? `15px solid ${
                                lifeCycleStageId === lc.id
                                  ? theme.colors.brand
                                  : theme.colors.gray[200]
                              }`
                            : 0,
                        // the triangle
                        content: `''`,
                        position: "absolute",
                        borderBottom: "15px solid transparent",
                        borderTop: "15px solid transparent",
                        height: 0,
                        width: 0,
                        marginRight: -15,
                        marginTop: 0,
                        top: 0,
                        right: 0,
                        zIndex: 2,
                      },
                      "&:hover": {
                        color: "white",
                        backgroundColor: "brandLight",
                        "&:after": {
                          // triangle hover
                          borderLeft:
                            i < lifeCycles.length - 1
                              ? `15px solid ${theme.colors.brandLight}`
                              : 0,
                        },
                      },
                    }}
                  >
                    {lc.name}
                  </Button>
                </Flex>
              ))}
            </Flex>
            {companyPlan && (
              <Flex alignItems="center" mt={3}>
                <SimpleStatCard
                  value={`${completedSteps} / ${totalSteps || 1}`}
                  label="Steps Completed"
                  icon={<CalendarIcon />}
                />
                <SimpleStatCard
                  value={`${percentComplete >= 0 ? percentComplete : 0}%`}
                  label="Journey Progress"
                  icon={<TasksIcon />}
                />
                <SimpleStatCard
                  value={overdueItems == 0 ? "Great!" : `Delayed`}
                  label="Progression Status"
                  icon={overdueItems == 0 ? <FaThumbsUp /> : <WarningIcon />}
                  bg={overdueItems > 0 && "redLight"}
                  color={overdueItems > 0 && "red"}
                />
              </Flex>
            )}
            <Flex>
              <Flex flex={1} flexDirection="column">
                <Flex flex={1}></Flex>
                <Flex flex={1} justifyContent="flex-end" mt={3}>
                  <Text variant="label" width={100}>
                    Completed
                  </Text>
                  <Text variant="label" width={100}>
                    Start Day
                  </Text>
                  <Flex width={1 / 2}>
                    {[...Array(totalDaysDisplay).keys()].map((i) => (
                      <Flex
                        key={i}
                        width={1 / totalDaysDisplay}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          backgroundColor:
                            moment(companyPlan?.startedAt)
                              .add(i, "days")
                              .isSame(moment(), "day") && "brandHighlight",
                        }}
                      >
                        <Text fontSize="xxs" variant="muted">
                          {moment(companyPlan?.startedAt)
                            .add(i, "days")
                            .format("D/MM")}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
                {companyPlanSteps &&
                  companyPlanSteps.map((step, index) => {
                    return (
                      <Flex
                        flex={1}
                        key={`${step.id}-${index}`}
                        alignItems="center"
                      >
                        <Flex
                          flex={1}
                          flexDirection="column"
                          justifyContent="flex-end"
                        >
                          <PlanSteps
                            key={`step-${index}`}
                            index={index}
                            step={step}
                            days={totalDaysDisplay}
                            companyPlan={companyPlan}
                          />
                        </Flex>
                      </Flex>
                    );
                  })}
              </Flex>
            </Flex>
          </Flex>
        )}
        <CompanyMenuModal
          selectedRows={[
            { ...entityData?.company, parentType: props.parentType },
          ]}
          open={openModal}
          formType="Plan"
          moduleType={entityData?.company?.details?.status?.name}
          onSave={() => {
            setOpenModal(false);
            refetch({
              variables: { id: props.id, parentType: props.parentType },
            });
          }}
          onDismiss={() => {
            setOpenModal(false);
          }}
        />
      </Flex>
    </Flex>
  );
}

LifeCycle.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
};
export default LifeCycle;

const PlanSteps = (props) => {
  const { step: field, companyPlan, days } = props;

  const [open, setOpen] = useState(false);
  const index = companyPlan.companyPlanSteps.indexOf(field);

  const startDay = field.startDay - 1;
  const endDay = startDay + field?.durationDays - 1;

  let statusColor = "brandLight";

  if (
    !field.completedAt &&
    moment(field.expectedCompletedAt).isBefore(moment())
  ) {
    statusColor = "orange";
  }
  if (field.completedAt) {
    statusColor = "gray.300";
  }

  return (
    <Flex flex={1} width="100%" alignItems="center">
      <Flex
        p={2}
        alignItems="center"
        flex={1}
        sx={{
          height: "100%",
          width: "100%",
          borderBottomStyle: "solid",
          borderBottomColor: "accent",
          borderBottomWidth: 1,
        }}
      >
        <Flex flex={1}>
          <Flex mx={2} alignItems="center">
            {field.completedAt && (
              <Box mt={1} mr={2}>
                <Flex
                  flex={1}
                  bg="brandHighlight"
                  sx={{ borderRadius: 99, width: 20, height: 20 }}
                  alignItems="center"
                  justifyContent="center"
                  color="brandLight"
                >
                  <RiCheckLine size={18} />
                </Flex>
              </Box>
            )}
            {!field.completedAt && (
              <Text mt={1} mr={2} color="brandLight">
                <CheckCircleOffIcon size={20} />
              </Text>
            )}
            <Box mx={2}>
              <Text fontSize="xs">{field?.workflowName}</Text>
            </Box>
          </Flex>
        </Flex>
        <Box px={2}>
          <Text variant="muted" width={80}>
            {field?.completedAt &&
              moment(field?.completedAt).format("DD MMM YYYY")}
          </Text>
        </Box>
        <Box px={2}>
          <Text variant="muted" width={80}>
            {field?.startDay}
          </Text>
        </Box>
      </Flex>

      <Flex height="100%" width={1 / 2}>
        {[...Array(days).keys()].map((i) => (
          <Flex
            key={i}
            height={60}
            bg={
              moment(companyPlan?.startedAt)
                .add(i, "days")
                .isSame(moment(), "day")
                ? "brandHighlight"
                : i % 2 == 0 && "gray.200"
            }
            width={1 / days}
            alignItems="center"
          >
            {field?.durationDays === 0 && startDay === i ? (
              <Flex
                width="100%"
                alignItems="center"
                color="brandLight"
                sx={{ transform: "rotate(45deg)" }}
              >
                <RiCheckboxBlankFill size="100%" />
              </Flex>
            ) : (
              <Box
                width="100%"
                height={35}
                bg={i >= startDay && i <= endDay && statusColor}
              />
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

PlanSteps.propTypes = {
  step: PropTypes.object,
  companyPlan: PropTypes.object,
  days: PropTypes.number,
};
