import React from "react";
import { useEffect } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { Flex, Text, Box, Link } from "rebass";
import Loader from "Components/Common/Loader";
import { useQuery } from "@apollo/react-hooks";
import { HelpIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import PlanQuery from "../../../../graphql/queries/Plans.gql";

function PlanList(props) {
  const { id } = useParams();
  const history = useHistory();
  const { loading, data, error, refetch } = useQuery(PlanQuery, {});

  if (loading) return <Loader />;
  return (
    <>
      <Flex
        flex={1}
        w="100%"
        p={2}
        style={{
          color: "text",
        }}
      >
        <Flex flexGrow={1} flexDirection="column">
          <Flex my={1} justifyContent="space-between">
            <Text variant="h2">Journeys</Text>
            <PrimaryButton
              onClick={() => {
                history.push("/settings/product/plans/new");
              }}
            >
              <PlusIcon />
            </PrimaryButton>
          </Flex>

          {(!data || data.plans?.length === 0) && (
            <Flex p={2} my={2} flexDirection="column">
              <Text variant="muted">
                Create plans to log from your product and start collecting your
                data.
              </Text>
              <Link href="/#" my={2}>
                <Flex alignItems="center">
                  <HelpIcon /> <Text mx={2}>Help</Text>
                </Flex>
              </Link>
            </Flex>
          )}
          {data &&
            data.plans?.map((trigger) => (
              <Flex
                key={trigger.id}
                variant="box"
                bg={trigger.id === id ? "brandHighlight" : "white"}
                sx={{
                  cursor: "pointer",
                  borderColor: trigger.id === id ? "brandLight" : "accent",
                }}
                p={2}
                my={2}
                alignItems="center"
                onClick={() =>
                  history.push(`/settings/product/plans/${trigger.id}`)
                }
              >
                <Box m={2}>
                  <Flex alignItems="center">
                    <Text variant="h3">{trigger.name}</Text>

                    {trigger.default && (
                      <Text
                        bg="brandHighlight"
                        color="brand"
                        sx={{
                          borderColor: "brandLight",
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderRadius: "medium",
                        }}
                        p={1}
                        mx={2}
                        fontSize="xs"
                      >
                        Default
                      </Text>
                    )}
                  </Flex>
                  <Text variant="muted">{trigger.planType?.name}</Text>
                </Box>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </>
  );
}

export default withRouter(PlanList);
