import React, { useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import "react-vertical-timeline-component/style.min.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "assets/styles/react-tabs.css";
import Attachments from "../Attachments";
import EndUsers from "../EndUsers";
import { MODEL_TYPES } from "consts";
import Events from "../Events";
import { getData } from "hooks/getData";
import Activity from "../../Activity";
import CompanyForm from "./CompanyForm";
import HealthDashboard from "../Health/";
import AutomationDashboard from "../Automation";
import HealthScoreIcon from "./HealthScoreIcon";
import StatusBadge from "./StatusBadge";
import CompanyLogo from "./CompanyLogo";
import LifeCycle from "../Lifecycle";
import Label from "../../Common/Forms/Label";
import GhostButton from "../../Common/Buttons/GhostButton";
import {
  ArrowDropDownIcon,
  CompanyIcon,
  InfoIcon,
  JourneyIcon,
  OwnerChangeIcon,
} from "../../Common/Icons";
import CompanyMenuModal from "../../Dashboard/CompanyHealth/CompanyMenuModal";
import CompanyRevenueEntries from "./CompanyRevenueEntries";
import UserBadge from "../../Common/UserBadge";
import PropTypes from "prop-types";
import BccAddress from "../BccAddress";
import { useHistory } from "react-router-dom";
import {
  Ri4KLine,
  RiAttachmentLine,
  RiGitMergeLine,
  RiGlobalLine,
  RiImage2Line,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import CompanyDomains from "./CompanyDomains";
import { useQuery } from "@apollo/client";
import HealthByEntity from "../../../graphql/queries/HealthByEntity.gql";
import HealthBadge from "./HealthBadge";
import numeral from "numeral";
import moment from "moment";
import CompanyProductList from "./CompanyProductList";
import Tags from "../../Common/Tags";
import TagsEditor from "../../Common/Tags/TagsEditor";
import EventSummary from "../Events/Summary";
import ReactTooltip from "react-tooltip";
import ExternalIds from "../ExternalIds";

function CompanyDetail(props) {
  const { id, parentType } = props;
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(moment());
  const dataQuery = getData(MODEL_TYPES[parentType], id);
  const { data: healthData } = useQuery(HealthByEntity, {
    variables: {
      id,
      parentType,
      days: 30,
    },
  });

  const data =
    dataQuery?.data && dataQuery.data[MODEL_TYPES[parentType].singular];

  const details = data?.details;
  const planType = details?.planType;

  return (
    <Flex flex={1} w="100%" flexDirection="column">
      <Flex flex={1} bg="white" flexDirection="row" justifyContent="center">
        <Flex
          flexDirection="column"
          sx={{
            borderRightColor: "gray.200",
            borderRightStyle: "solid",
            borderRightWidth: 1,
            maxWidth: "320px",
          }}
          my={2}
        >
          <Box>
            <Box>
              {data?.parent && (
                <Flex my={2}>
                  <Button
                    variant="ghost"
                    ml={0}
                    mr={1}
                    py={0}
                    onClick={() => {
                      history.push(
                        window.location.pathname.replace(
                          props.id,
                          data?.parent?.id
                        )
                      );
                    }}
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      height: 30,
                      marginLeft: 30,
                      marginRight: 30,
                      paddingLeft: 25,
                      position: "relative",
                      borderRadius: 99,
                    }}
                  >
                    <Flex alignItems="center" justifyContent="space-between">
                      <CompanyIcon />
                      <Text mx={2} fontSize="xs">
                        {data?.parent?.name}
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              )}
              <Flex flex={1} alignItems={"center"}>
                {details?.status && (
                  <Flex
                    flex={1}
                    justifyContent="space-between"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenModal("Status");
                    }}
                  >
                    <StatusBadge
                      status={details?.status}
                      lifecycle={details?.lifeCycleStage}
                    />
                  </Flex>
                )}
                {planType && (
                  <Flex flexDirection="column" flex={1}>
                    <Box>
                      <ReactTooltip
                        id="planType"
                        place="top"
                        effect="solid"
                        getContent={(dataTip) => dataTip}
                      />
                      <Button
                        data-for="planType"
                        data-tip="Lifecycle Journey"
                        variant="default"
                        bg="brandHighlight"
                        color="brandLight"
                        ml={0}
                        mr={1}
                        py={0}
                        onClick={() => {
                          setOpenModal("Plan");
                        }}
                        sx={{
                          cursor: "pointer",
                          display: "inline-block",
                          height: 30,
                          marginLeft: 30,
                          marginRight: 30,
                          paddingLeft: 25,
                          position: "relative",
                          borderRadius: 99,
                        }}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <JourneyIcon />
                          <Text mx={2} fontSize="xs">
                            {planType?.name}
                          </Text>
                          <ArrowDropDownIcon />
                        </Flex>
                      </Button>
                    </Box>
                  </Flex>
                )}
              </Flex>

              <Flex flex={1} mr={1} mt={2}>
                {details?.status?.name == "Paid" && (
                  <Flex
                    flex={1}
                    onClick={() => {
                      props.onSelect(2);
                      history.push(`${window.location.pathname}?tab=Health`);
                    }}
                    mx={1}
                    sx={{ cursor: "pointer" }}
                  >
                    <HealthScoreIcon
                      status={details?.status}
                      category={details?.healthCategory}
                      value={healthData?.healthByEntity?.value}
                      createdAt={healthData?.healthByEntity?.createdAt}
                      valueDiff={healthData?.healthByEntity?.valueDiff}
                      trend={healthData?.healthByEntity?.trend}
                    />
                  </Flex>
                )}
                <HealthBadge
                  key={`sentiment-${refresh.format("hhmmss")}`}
                  name="Sentiment"
                  id={id}
                  parentType={parentType}
                  required
                  onClick={() => setOpenModal("Feedback")}
                />
              </Flex>
              <Box mt={2}>
                <GhostButton onClick={() => setOpenModal("Owner")}>
                  {details?.owner ? (
                    <UserBadge
                      name={details.owner.name}
                      email={details.owner.email}
                      subtitle="Owner"
                      avatarUrl={details.owner.avatarUrl}
                    />
                  ) : (
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      fontSize="xs"
                    >
                      <Flex
                        bg="accent"
                        justifyContent="center"
                        alignItems="flex-end"
                        sx={{ borderRadius: 999, width: 25, height: 25 }}
                      >
                        <OwnerChangeIcon />
                      </Flex>
                      <Text mx={2} fontWeight="bold">
                        Assign Owner
                      </Text>
                    </Flex>
                  )}
                </GhostButton>
              </Box>
              {details?.owner && (
                <Box>
                  <GhostButton onClick={() => setOpenModal("ShadowOwner")}>
                    {details?.shadowOwner ? (
                      <UserBadge
                        name={details.shadowOwner.name}
                        email={details.shadowOwner.email}
                        subtitle="Shadow Owner"
                        avatarUrl={details.shadowOwner.avatarUrl}
                      />
                    ) : (
                      <Flex
                        alignItems="center"
                        justifyContent="flex-start"
                        fontSize="xs"
                      >
                        <Flex
                          bg="accent"
                          justifyContent="center"
                          alignItems="flex-end"
                          sx={{ borderRadius: 999, width: 25, height: 25 }}
                        >
                          <OwnerChangeIcon />
                        </Flex>
                        <Text mx={2} fontWeight="bold">
                          Assign Shadow Owner
                        </Text>
                      </Flex>
                    )}
                  </GhostButton>
                </Box>
              )}
            </Box>
            {details?.churnReason ? (
              <Box my={3} mr={2} p={3} bg="gray.200">
                <Flex alignItems={"flex-start"} flex={1}>
                  <Box width={1 / 2}>
                    <Label>Churned</Label>
                    <Text my={1} mt={2}>
                      {details?.churnReason?.name}
                    </Text>
                    <Text variant="muted">
                      {moment(details?.churnReasonUpdatedAt).format(
                        "DD MMM YYYY"
                      )}
                    </Text>
                  </Box>
                  <Box width={1 / 2} pl={3}>
                    <Label>ARR</Label>
                    <Text mt={2} color="red">
                      ${numeral(details?.arr).format(`0,0.00`)}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ) : (
              <Box my={3} mr={2} p={3} bg="gray.100">
                <Flex alignItems={"center"} flex={1}>
                  <Box width={1 / 2}>
                    <Label>ARR</Label>
                    <Text>${numeral(details?.arr).format(`0,0.00`)}</Text>
                  </Box>
                  <Box width={1 / 2}>
                    <Label>MRR</Label>
                    <Text>${numeral(details?.mrr).format(`0,0.00`)}</Text>
                  </Box>
                </Flex>
                <Flex alignItems={"center"} flex={1} mt={3}>
                  <Box width={1 / 2}>
                    <Label>Start Date</Label>
                    <Text>
                      {details?.startDate ? (
                        moment(details?.startDate).format("DD MMM YYYY")
                      ) : (
                        <Text variant="muted">No set</Text>
                      )}
                    </Text>
                  </Box>
                  <Box width={1 / 2}>
                    <Label>Renewal Date</Label>

                    {details?.renewalDate ? (
                      <Text
                        px={moment(details?.renewalDate) < moment() && 2}
                        ml={moment(details?.renewalDate) < moment() && -2}
                        bg={
                          moment(details?.renewalDate) < moment() && "redLight"
                        }
                        sx={{
                          borderRadius:
                            moment(details?.renewalDate) < moment() && 99,
                        }}
                        color={
                          moment(details?.renewalDate) < moment()
                            ? "red"
                            : "text"
                        }
                      >
                        {moment(details?.renewalDate).format("DD MMM YYYY")}
                      </Text>
                    ) : (
                      <Text variant="muted">No set</Text>
                    )}
                  </Box>
                </Flex>
              </Box>
            )}
            <>
              <BccAddress address={data?.bccAddress} />
              <Flex flexDirection="column" mt={2}>
                <EventSummary
                  id={id}
                  parentType={parentType}
                  lastSeenAt={details?.lastSeenAt}
                />
              </Flex>
              <Box mt={3} />
              <TagsEditor
                id={id}
                parentType="Company"
                label="Tags"
                value={data?.tagList}
              />
              <Flex flexDirection="column" mt={3}>
                <Tags
                  id={id}
                  parentType={parentType}
                  label="Segments"
                  value={data?.segmentList}
                />
              </Flex>
              <Flex flexDirection="column">
                <CompanyProductList products={data?.products} />
              </Flex>
            </>
          </Box>

          <CompanyMenuModal
            moduleType={details?.status?.name}
            selectedRows={[{ ...data }]}
            parentType={props.parentType}
            open={!!openModal}
            formType={openModal ? openModal : undefined}
            onSave={() => {
              setOpenModal(undefined);
              setRefresh(moment());
              dataQuery.refetch({ variables: { id, parentType } });
            }}
            onDismiss={() => {
              setOpenModal(undefined);
            }}
          />
        </Flex>
        <Flex flex={1} bg="white">
          <Tabs
            selectedIndex={props.selected}
            onSelect={props.onSelect}
            className="primary-tabs"
          >
            <TabPanel key="Summary">
              <Flex
                className="SummaryDetail"
                flex={1}
                px={4}
                py={3}
                g="white"
                flexDirection="column"
              >
                <Activity
                  id={id}
                  parentType={parentType}
                  actionableName={
                    dataQuery?.data &&
                    (dataQuery?.data[MODEL_TYPES[parentType].name]?.name ||
                      `${
                        dataQuery?.data[MODEL_TYPES[parentType]?.name]
                          ?.firstName
                      } ${
                        dataQuery?.data[MODEL_TYPES[parentType]?.name]?.lastName
                      }`)
                  }
                />
              </Flex>
            </TabPanel>
            <TabPanel key="Lifecycle">
              <Flex
                className="LifecycleDetail"
                flex={1}
                px={4}
                py={3}
                g="white"
                flexDirection="column"
              >
                <LifeCycle id={id} parentType={parentType} />
              </Flex>
            </TabPanel>
            <TabPanel key="Health">
              <Flex
                className="HealthDetail"
                flex={1}
                px={4}
                py={3}
                g="white"
                flexDirection="column"
              >
                <HealthDashboard id={id} parentType={parentType} />
              </Flex>
            </TabPanel>
            <TabPanel key="Automation">
              <Flex
                className="AutomationDetail"
                flex={1}
                px={4}
                py={3}
                bg="white"
                flexDirection="column"
              >
                <AutomationDashboard id={id} parentType={parentType} />
              </Flex>
            </TabPanel>
            <TabPanel key="Profile">
              <Flex flex={1} bg="white" px={4} py={3} flexDirection="column">
                <Flex flex={1} className="profile">
                  <Flex pr={4} flex={1}>
                    <Flex
                      flexDirection="column"
                      width="50%"
                      bg="gray.100"
                      px={3}
                      py={2}
                    >
                      <CompanyForm
                        id={id}
                        typeName={parentType}
                        readOnly={false}
                      />
                    </Flex>
                    <Flex
                      flexDirection="column"
                      width="50%"
                      px={3}
                      py={2}
                      mx={2}
                    >
                      <Tabs className="primary-tabs">
                        <TabList>
                          <Tab>
                            <Flex
                              justifyContent="center"
                              alignItems={"center"}
                              flexDirection={"column"}
                            >
                              <RiMoneyDollarCircleLine />
                              <Text fontSize={"xs"} mx={2}>
                                Revenue
                              </Text>
                            </Flex>
                          </Tab>
                          <Tab>
                            <Flex
                              justifyContent="center"
                              alignItems={"center"}
                              flexDirection={"column"}
                            >
                              <RiAttachmentLine />
                              <Text fontSize={"xs"} mx={2}>
                                Attach
                              </Text>
                            </Flex>
                          </Tab>
                          <Tab>
                            <Flex
                              justifyContent="center"
                              alignItems={"center"}
                              flexDirection={"column"}
                            >
                              <RiGitMergeLine />
                              <Text fontSize={"xs"} mx={2}>
                                API
                              </Text>
                            </Flex>
                          </Tab>
                          <Tab>
                            <Flex
                              justifyContent="center"
                              alignItems={"center"}
                              flexDirection={"column"}
                            >
                              <RiGlobalLine />

                              <Text fontSize={"xs"} mx={2}>
                                Domains
                              </Text>
                            </Flex>
                          </Tab>
                          <Tab>
                            <Flex
                              justifyContent="center"
                              alignItems={"center"}
                              flexDirection={"column"}
                            >
                              <RiImage2Line />
                              <Text fontSize={"xs"} mx={2}>
                                Logo
                              </Text>
                            </Flex>
                          </Tab>
                        </TabList>
                        <TabPanel key="Details">
                          <Flex pl={2}>
                            <CompanyRevenueEntries
                              id={id}
                              typeName={parentType}
                              onSave={() => dataQuery.refetch()}
                            />
                          </Flex>
                        </TabPanel>
                        <TabPanel>
                          <Flex pl={2} flexDirection={"column"}>
                            <Text variant="h3" mt={3}>
                              Attach
                            </Text>
                            <Attachments id={id} parentType={parentType} />
                          </Flex>
                        </TabPanel>
                        <TabPanel>
                          <Flex pl={2}>
                            <ExternalIds id={id} parentType={parentType} />
                          </Flex>{" "}
                        </TabPanel>
                        <TabPanel>
                          <Flex pl={2}>
                            <CompanyDomains
                              id={id}
                              typeName={parentType}
                              onSave={() => dataQuery.refetch()}
                            />
                          </Flex>
                        </TabPanel>
                        <TabPanel key="info">
                          <Flex flexDirection="column" p={2}>
                            <Text variant="h3" mb={2}>
                              Logo
                            </Text>
                            <CompanyLogo id={id} />
                          </Flex>
                        </TabPanel>
                      </Tabs>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </TabPanel>
            <TabPanel key="Users">
              <Flex className="UserDetail" bg="white" px={4} py={3}>
                <EndUsers id={id} />
              </Flex>
            </TabPanel>
            <TabPanel key="Events">
              <Flex className="EventDetail" bg="white" px={4} py={3}>
                <Events id={id} parentType={parentType} />
              </Flex>
            </TabPanel>
          </Tabs>
        </Flex>
      </Flex>
    </Flex>
  );
}

CompanyDetail.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
  selected: PropTypes.number,
  onSelect: PropTypes.func,
};
export default CompanyDetail;
