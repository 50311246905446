import React, { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Flex, Button, Text, Box } from "rebass";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TypeSchemaQuery from "../../../../graphql/queries/TypeSchemas.gql";
import Loader from "Components/Common/Loader";
import { useMutation, useQuery } from "@apollo/react-hooks";
import DraggableItem from "./DraggableItem";
import DndContext from "./DNDContext";
import DroppableSection from "./DroppableSection";
import { ModelTypeIcon } from "consts";
import ImportMutation from "../../../../graphql/mutations/Import.gql";
import { getProducts } from "hooks/getProducts";
import { getSearchFilter } from "hooks/getSearchFilter";
import { ImportIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import GhostButton from "../../../Common/Buttons/GhostButton";
import { CloseIcon, InfoIcon, TrashIcon } from "../../../Common/Icons";
import { RiKey2Fill, RiKey2Line } from "react-icons/ri";

const _ = require("lodash");

const buildFieldMapping = (fieldMapping) => {
  let fields = {};
  fields["primaryKeys"] = [];
  if (fieldMapping) {
    for (const [model, attributes] of Object.entries(fieldMapping)) {
      for (const [field, column] of Object.entries(attributes)) {
        fields[column] = `${model}.${field}`;

        if (
          fieldMapping["keys"] &&
          fieldMapping["keys"][model] &&
          fieldMapping["keys"][model].includes(field)
        ) {
          fields["primaryKeys"].push(column);
        }
      }
    }
  }

  return fields;
};
function MapFields(props) {
  const history = useHistory();
  const { importData } = props;
  const { data: searchFilters } = getSearchFilter();
  const productsQuery = getProducts();
  const selectedProduct = searchFilters?.filters?.productId;
  let product = productsQuery.data?.products.find(
    (p) => p.id === selectedProduct
  );
  if (!product) {
    product = _.first(productsQuery.data?.products);
  }
  const { loading, data, error, refetch } = useQuery(TypeSchemaQuery, {
    fetchPolicy: "network-only",
  });

  const {
    id,
    fileFormat: columns,
    includeHeaders,
    mergeData,
    fieldMapping,
    sourceId,
    sourceType,
  } = importData;

  const [selectedTab, setSelectedTab] = useState(0);
  const [headers, setHeaders] = useState(includeHeaders);
  const [save, {}] = useMutation(ImportMutation);
  const [fields, setFields] = useState();
  const [errors, setErrors] = useState();

  useEffect(() => {
    setFields(buildFieldMapping(fieldMapping));
  }, [fieldMapping]);

  if (!importData) return <Loader />;
  if (loading) return <Loader />;

  const saveImport = () => {
    let mapping = {};
    mapping["keys"] = {};
    columns &&
      columns.forEach((col) => {
        let fieldMapping = fields[col.name];
        if (fieldMapping) {
          let modelFields = fieldMapping.split(".");
          let model = modelFields[0];
          let field = modelFields[1];
          mapping[model] = mapping[model] || {};
          mapping[model][field] = col.name;

          if (fields["primaryKeys"].includes(col.name)) {
            if (!mapping["keys"][model]) {
              mapping["keys"][model] = [];
            }
            mapping["keys"][model].push(field);
          }
        }
      });

    let objects = _.pull(Object.keys(mapping), "keys");
    let requiredModels = _.difference(objects, Object.keys(mapping["keys"]));
    requiredModels = _.without(
      requiredModels,
      "Score",
      "Address",
      "Comment",
      "Event"
    );
    if (requiredModels.length > 0) {
      setErrors(
        `A primary key for ${requiredModels
          .map((o) => dataModels.find((m) => m.typeName === o)?.displayName)
          .join(", ")} need to be defined before continuing`
      );
      window.scrollTo(0, 0);
      return;
    }
    save({
      variables: {
        id: id,
        includeHeaders: headers,
        mergeData: mergeData,
        fieldMapping: mapping,
        sourceId: sourceId,
        sourceType: sourceType,
      },
      context: {
        hasUpload: true,
      },
    }).then(
      (response) => {
        if (!response.data?.importUpload?.errors) {
          props.onUploadComplete(response.data?.import?.import);
          props.nextStep();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  if (loading) <Loader />;

  const dataModels = data?.typeSchemas?.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );
  const defaultPrimaryKeys = _.flatten(
    dataModels
      ?.map((schema) => {
        return {
          name: schema.typeName,
          fields: schema?.attributes
            .filter((f) => f.primaryKey)
            .map((f) => f.name),
        };
      })
      .filter((f) => f.fields?.length > 0)
      .map((s) => s.fields.map((f) => `${s.name}.${f}`))
  );

  return (
    <Flex flexDirection="column" p={3} my={3}>
      <Flex
        py={2}
        variant="box"
        justifyContent="center"
        alignItems="center"
        color="gray.400"
      >
        <ImportIcon size={40} />
        <Text fontWeight="bold" mx={2} color="black">
          {importData.filename}
        </Text>
      </Flex>
      <Flex my={2} flexDirection="column">
        <Text my={2}>
          Drag fields from the right side picker to the left side boxes matching
          your data.
        </Text>
        <Text my={2} color="red">
          {errors}
        </Text>
      </Flex>
      <Flex my={2}>
        <DndContext
          items={fields}
          defaultPrimaryKeys={defaultPrimaryKeys}
          onChange={(data) => {
            setFields(data);
          }}
        >
          <Flex width={1 / 2} flexDirection="column">
            <Text variant="h3">Spreadsheet Columns</Text>
            <Box>
              {columns &&
                columns.map((column) => {
                  let modelFields = fields[column.name]?.split(".");
                  let field = undefined;
                  let schema = undefined;
                  if (modelFields && modelFields.length === 2) {
                    let modelName = modelFields[0];
                    let fieldName = modelFields[1];
                    schema = dataModels?.find((s) => s.typeName === modelName);
                    field = schema?.attributes.find(
                      (f) => f.name === fieldName
                    );
                  }
                  return (
                    <Flex
                      key={column.name}
                      my={1}
                      p={2}
                      alignItems="center"
                      variant="box"
                      bg={!field && "gray.100"}
                      sx={{ position: "relative" }}
                    >
                      <GhostButton
                        onClick={() => {
                          let newFields = { ...fields };
                          let key = column.name;
                          if (newFields["primaryKeys"].includes(key)) {
                            var index = _.indexOf(
                              newFields["primaryKeys"],
                              key
                            );
                            newFields["primaryKeys"].splice(index, 1);
                          } else {
                            newFields["primaryKeys"].push(key);
                          }
                          setFields(newFields);
                        }}
                      >
                        <Text
                          color={
                            fields["primaryKeys"]?.includes(column.name)
                              ? "yellow"
                              : "brandLight"
                          }
                        >
                          {fields["primaryKeys"]?.includes(column.name) ? (
                            <RiKey2Fill />
                          ) : (
                            <RiKey2Line />
                          )}
                        </Text>
                      </GhostButton>
                      <Flex width={1 / 2} flexDirection="column">
                        <Text fontWeight="bold">{column.name}</Text>
                        <Text variant="muted">{column.data}</Text>
                      </Flex>
                      <Flex width={1 / 2} alignItems="center">
                        <DroppableSection droppableId={column.name}>
                          <Flex width="100%" alignItems="center">
                            {field && (
                              <>
                                {ModelTypeIcon(schema.typeName)}
                                <Text mx={2}>{field.displayName}</Text>
                              </>
                            )}
                            {!field && (
                              <Text variant="muted">Drag field here</Text>
                            )}
                          </Flex>
                        </DroppableSection>
                      </Flex>
                      <Flex justifyItems={"flex-end"}>
                        <Box>
                          <GhostButton
                            onClick={() => {
                              let newFields = { ...fields };
                              newFields[column.name] = undefined;
                              setFields(newFields);
                            }}
                          >
                            <CloseIcon />
                          </GhostButton>
                        </Box>
                      </Flex>
                    </Flex>
                  );
                })}
            </Box>
          </Flex>
          <Flex width={1 / 2} mx={2} px={2} flexDirection="column">
            <Text variant="h3">Userlot Fields</Text>
            <Tabs selectedIndex={selectedTab} onSelect={setSelectedTab}>
              <TabList>
                {dataModels
                  ?.filter((schema) => schema.displayName !== "Product")
                  .map((schema, i) => (
                    <Tab key={schema.id}>
                      <Flex alignItems="center" flexDirection={"column"}>
                        {ModelTypeIcon(schema.typeName, 16)}
                        <Text fontSize="xs">{schema?.displayName}</Text>
                      </Flex>
                    </Tab>
                  ))}
              </TabList>
              {dataModels
                ?.filter((schema) => schema.displayName !== "Product")
                .map((schema) => {
                  return (
                    <TabPanel key={schema.id}>
                      <Flex bg="white" flexDirection="column">
                        <DroppableSection droppableId={schema.id}>
                          {schema?.attributes
                            .filter(
                              (f) =>
                                (!f.productId || f.productId === product?.id) &&
                                f.access == "readwrite"
                            )
                            .sort((x, y) =>
                              x.displayName.localeCompare(y.displayName)
                            )
                            .sort((x, y) => (x.secondaryKey ? -1 : 1))
                            .sort((x, y) => (x.primaryKey ? -1 : 1))
                            .map((field, index) => (
                              <DraggableItem
                                key={index}
                                typeName={schema.typeName}
                                item={field}
                                index={index}
                              >
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                  width="100%"
                                >
                                  <Flex
                                    width="100%"
                                    flexDirection={"column"}
                                    justifyContent="center"
                                  >
                                    <Flex alignItems="center">
                                      <Flex alignItems="center">
                                        <Text
                                          fontWeight={
                                            (field.primaryKey ||
                                              field.secondaryKey) &&
                                            "bold"
                                          }
                                        >
                                          {ModelTypeIcon(schema.typeName, 16)}
                                        </Text>
                                        <Text
                                          fontWeight={
                                            (field.primaryKey ||
                                              field.secondaryKey) &&
                                            "bold"
                                          }
                                          mx={1}
                                        >
                                          {field.displayName}
                                        </Text>
                                      </Flex>
                                      {field.productId && (
                                        <Text variant="muted" mx={2}>
                                          {product?.name}
                                        </Text>
                                      )}
                                      {field.primaryKey && (
                                        <Flex alignItems={"center"}>
                                          <Text color={"yellow"}>
                                            <RiKey2Fill />
                                          </Text>
                                          <Text vaiant="muted" fontSize={"xxs"}>
                                            Product
                                          </Text>
                                        </Flex>
                                      )}
                                      {field.secondaryKey && (
                                        <Flex alignItems={"center"}>
                                          <Text color={"green"}>
                                            <RiKey2Fill />
                                          </Text>
                                          <Text vaiant="muted" fontSize={"xxs"}>
                                            Other
                                          </Text>
                                        </Flex>
                                      )}
                                    </Flex>
                                    <Text
                                      variant="muted"
                                      fontSize={"xxs"}
                                      mx={2}
                                    >
                                      {field.description}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </DraggableItem>
                            ))}
                        </DroppableSection>
                      </Flex>
                    </TabPanel>
                  );
                })}
            </Tabs>
          </Flex>
        </DndContext>
      </Flex>
      <Flex justifyContent="space-between">
        <Button
          onClick={() => {
            props.previousStep();
          }}
          variant={"secondary"}
        >
          Back
        </Button>
        <Box>
          <Button
            mx={2}
            onClick={() => {
              history.go("/settings/product/import#");
            }}
            variant={"secondary"}
          >
            Cancel
          </Button>
          <PrimaryButton
            onClick={() => {
              saveImport();
            }}
          >
            Next
          </PrimaryButton>
        </Box>
      </Flex>
    </Flex>
  );
}

export default withRouter(MapFields);
