import React from "react";
import CompanyStatusForm from "./CompanyStatusForm";
import { Flex, Image } from "rebass";
import Settings from "../ProductSettings";

function CompanyStatuses(props) {
  return (
    <Settings>
      <Flex width="100%">
        <Flex flex={1} p={4} bg="white">
          <CompanyStatusForm />
        </Flex>
      </Flex>
    </Settings>
  );
}

export default CompanyStatuses;
