import React, { useState } from "react";
import { Flex, Text, Box, Image } from "rebass";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import WidgetWrapper from "./WidgetWrapper";
import DashboardGraph from "../../Common/Graphs/DashboardGraph";
import Loader from "../../Common/Loader";

function AccountGrowth(props) {
  const mrrData = props.mrrData;
  const companyTypes = ["new", "existing", "churned"];

  const graphData = companyTypes.map((ev, i) => {
    return {
      name: ev,
      data: mrrData
        ?.filter((d) => d.type === ev)
        .map((d) => {
          return {
            date: moment(d.date, "YYYY-MM-DD"),
            count: d["count"],
          };
        }),
    };
  });
  const hasData = mrrData?.filter((d) => d.count > 0).length > 0;

  return (
    <WidgetWrapper
      title="Annual Account Growth"
      help={"Graph is based on account start date"}
    >
      {props.loading ? (
        <Loader />
      ) : hasData ? (
        <Box width="100%">
          <DashboardGraph series={graphData} height={200} />
        </Box>
      ) : (
        <Flex flex={1} alignItems="center" justifyContent="center" p={3}>
          <Image src={require("assets/images/GrowthEmpty.png")} width={100} />
        </Flex>
      )}
    </WidgetWrapper>
  );
}

export default AccountGrowth;
