import React, { useState } from "react";
import "../../../../node_modules/react-vis/dist/style.css";
import {
  FlexibleWidthXYPlot,
  YAxis,
  VerticalBarSeries,
  XAxis,
  VerticalGridLines,
} from "react-vis";
import { useTheme } from "emotion-theming";
import { Box, Flex, Image, Text } from "rebass";

const _ = require("lodash");

export default function SimpleBarGraph(props) {
  const theme = useTheme();
  const positive = props.series.map((ev, i) => {
    return {
      y: ev.count || 0,
      x: ev.day,
      name: ev.name,
      color:
        ev.classify > 0
          ? theme.colors.green
          : ev.classify < 0
          ? theme.colors.red
          : theme.colors.gray["400"],
    };
  });
  const ticks = props.series?.map((s) => s.day);

  return props.series && props.series.length > 0 ? (
    <Flex>
      <FlexibleWidthXYPlot
        colorType="literal"
        height={props.height || 250}
        margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
      >
        <VerticalGridLines tickValues={ticks} />
        <XAxis
          orientation="top"
          tickValues={ticks}
          tickFormat={(v, i) =>
            v % 1 === 0 &&
            props.series[props.max - v - 1] &&
            `${props.series[props.max - v - 1].percentage}%`
          }
          style={{
            line: { stroke: "none" },
            ticks: { stroke: theme.colors.gray["700"] },
            text: { stroke: "none", fill: theme.colors.gray["700"] },
          }}
        />
        <XAxis
          tickValues={ticks}
          tickFormat={(v, i) => v % 1 === 0 && v}
          style={{
            line: { stroke: "none" },
            text: { stroke: "none", fill: theme.colors.gray["700"] },
          }}
        />
        <VerticalBarSeries
          animation={{ damping: 8, stiffness: 200 }}
          data={positive}
        />
      </FlexibleWidthXYPlot>
    </Flex>
  ) : (
    <Flex p={3} flexDirection="column" justifyContent="center">
      <Image
        src={require("assets/images/EventsEmpty.png")}
        sx={{ width: "70px", objectFit: "contain" }}
      ></Image>
    </Flex>
  );
}
