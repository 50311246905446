import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Flex, Text } from "rebass";
import GhostButton from "../Buttons/GhostButton";
import { CopyIcon } from "../Icons";

const CopyableText = (props) => {
  const [value, setValue] = useState(props.children);
  const [copied, setCopied] = useState(false);

  const copy = () => {
    setCopied(true);
  };

  return (
    <Flex alignItems="center">
      {!props.buttonOnly && (
        <Text as="span" {...props} mr={2}>
          {props.children}
        </Text>
      )}
      <CopyToClipboard text={props.children}>
        <GhostButton color="brandLight">
          <CopyIcon onClick={copy} sx={{ cursor: "pointer" }} />
        </GhostButton>
      </CopyToClipboard>
    </Flex>
  );
};

export default CopyableText;
