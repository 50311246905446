import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Flex, Text, Box } from "rebass";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TypeSchemaQuery from "../../../../../graphql/queries/TypeSchemas.gql";
import Loader from "Components/Common/Loader";
import { useQuery } from "@apollo/react-hooks";
import DraggableItem from "./DraggableItem";
import DndContext from "./DNDContext";
import DroppableSection from "./DroppableSection";
import { ModelTypeIcon } from "consts";
import PropTypes from "prop-types";
import GhostButton from "../../../../Common/Buttons/GhostButton";
import { CloseIcon, InfoIcon } from "../../../../Common/Icons";
import { getProduct } from "hooks/getProduct";
import { RiKey2Fill, RiKey2Line, RiSearchEyeLine } from "react-icons/ri";
import ReactTooltip from "react-tooltip";

const _ = require("lodash");

const buildFieldMapping = (fieldMapping) => {
  let fields = {};
  fields.keys = {};
  fields.secondaryKeys = {};

  if (fieldMapping) {
    for (const [model, attributes] of Object.entries(fieldMapping)) {
      if (model !== "keys" && model !== "secondaryKeys") {
        for (const [field, column] of Object.entries(attributes)) {
          if (
            !_.startsWith(field, "keys.") &&
            !_.startsWith(field, "secondaryKeys.")
          ) {
            fields[column] = `${model}.${field}`;
          }
        }
      }
    }
  }

  fields.keys = fieldMapping.keys;
  fields.secondaryKeys = fieldMapping.secondaryKeys;
  return fields;
};
function MapFields({ fieldMapping, fieldDefinition, onChange, title }) {
  const [fields, setFields] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [errors, setErrors] = useState();

  const { data: currentProduct } = getProduct();
  const { loading, data, error, refetch } = useQuery(TypeSchemaQuery, {
    fetchPolicy: "network-only",
  });
  const product = currentProduct.currentProduct;

  useEffect(() => {
    setFields(buildFieldMapping(fieldMapping));
  }, [fieldMapping]);

  if (loading) return <Loader />;

  const dataModels = data?.typeSchemas?.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  const buildMapping = (data) => {
    let mapping = {};
    fieldDefinition &&
      fieldDefinition.forEach((col) => {
        let fieldMapping = data[col.name];
        if (fieldMapping) {
          let modelFields = fieldMapping.split(".");
          let model = modelFields[0];
          let field = modelFields[1];
          mapping[model] = mapping[model] || {};
          mapping[model][field] = col.name;
        }
      });

    mapping.keys = data.keys;

    let objects = _.pull(Object.keys(mapping), "keys");
    let differences = _.difference(objects, Object.keys(mapping["keys"]));
    if (differences.length > 0) {
      setErrors(
        `A primary key for ${differences
          .map((o) => dataModels.find((m) => m.typeName === o)?.displayName)
          .join(", ")} need to be defined before continuing`
      );
      return;
    } else {
      setErrors(undefined);
    }

    mapping.secondaryKeys = data.secondaryKeys;
    onChange(mapping);
  };

  const defaultPrimaryKeys =
    dataModels &&
    _.flatten(
      dataModels
        ?.map((schema) => {
          return {
            name: schema.typeName,
            fields: schema?.attributes
              .filter((f) => f.primaryKey)
              .map((f) => f.name),
          };
        })
        .filter((f) => f.fields?.length > 0)
        .map((s) => s.fields.map((f) => `${s.name}.${f}`))
    );

  const newFieldDefinition =
    fieldDefinition &&
    fieldDefinition.map((column) => {
      let modelFields = fields[column.name]?.split(".");
      let field = undefined;
      let schema = undefined;
      if (modelFields && modelFields.length === 2) {
        let modelName = modelFields[0];
        let fieldName = modelFields[1];
        schema = dataModels.find((s) => s.typeName === modelName);
        field = schema?.attributes.find((f) => f.name === fieldName);
      }
      return {
        ...column,
        hasField: !!field,
        field: field,
        schemaTypeName: schema?.typeName,
      };
    });

  const getFieldName = (schemaName, colName) => {
    if (fieldMapping[schemaName]) return fieldMapping[schemaName][colName];
  };
  let keys = Object.keys(fieldMapping["keys"] || {});
  let secondaryKeys = Object.keys(fieldMapping["secondaryKeys"] || {});
  const pkField = keys
    .map((k) => getFieldName(k, _.first(fieldMapping.keys[k])))
    .join(", ");

  const luField = secondaryKeys
    .map((k) => getFieldName(k, _.first(fieldMapping.secondaryKeys[k])))
    .join(", ");

  return (
    <Flex flexDirection="column">
      <Text variant="h2">{title} Field Mapping</Text>
      <Text my={2} color="red">
        {errors}
      </Text>
      {(pkField || luField) && (
        <Text mt={2} bg="gray.100" p={3} color="muted">
          <Flex>
            {pkField && (
              <Flex>
                <Text color="yellow" mx={2}>
                  <RiKey2Fill />
                </Text>
                Match based on{" "}
                <Text mx={1} fontWeight={"bold"}>
                  {pkField}
                </Text>{" "}
                from source data
              </Flex>
            )}
            {luField && (
              <Flex mx={2}>
                , otherwise,
                <Text color="brandLight" mx={1}>
                  <RiSearchEyeLine />
                </Text>
                lookup on
                <Text mx={1} fontWeight={"bold"}>
                  {luField}
                </Text>
              </Flex>
            )}
          </Flex>
        </Text>
      )}
      <Flex mt={3}>
        <DndContext
          defaultPrimaryKeys={defaultPrimaryKeys}
          items={fields}
          onChange={(data) => {
            if (data["id"] && data["id"] !== fields["id"]) {
              // Add as a primary key
              data.keys[schema.typeName] = ["id"];
            }
            setFields(data);
            buildMapping(data);
          }}
        >
          <Flex width={1 / 2} flexDirection="column">
            <Text variant="h4">Api Field Definitions</Text>
            <Box sx={{ maxHeight: 600, overflowY: "scroll" }}>
              {newFieldDefinition &&
                newFieldDefinition
                  .sort((a, b) => {
                    return (
                      (a.hasField === b.hasField ? 0 : a.hasField ? -1 : 1) ||
                      a.label.localeCompare(b.label)
                    );
                  })
                  .map((column) => {
                    const isPk =
                      fields.keys &&
                      fields.keys[column.schemaTypeName] &&
                      fields.keys[column.schemaTypeName].includes(
                        column.field?.name
                      );
                    const isLookup =
                      fields.secondaryKeys &&
                      fields.secondaryKeys[column.schemaTypeName] &&
                      fields.secondaryKeys[column.schemaTypeName].includes(
                        column.field?.name
                      );
                    const isPkSet =
                      fields.keys &&
                      fields.keys[column.schemaTypeName] &&
                      fields.keys[column.schemaTypeName].length > 0;

                    return (
                      <Flex
                        key={column.name}
                        my={1}
                        p={2}
                        variant="box"
                        alignItems="center"
                        bg={!column.field && "gray.100"}
                        sx={{ position: "relative" }}
                      >
                        {column.schemaTypeName &&
                        (isPk || (!isPkSet && !isLookup)) ? (
                          <GhostButton
                            onClick={() => {
                              let newFields = { ...fields };
                              let key = column.field?.name;
                              let schema = column.schemaTypeName;

                              if (!!schema) {
                                if (!newFields.keys) newFields.keys = {};
                                if (!newFields.keys[schema])
                                  newFields.keys[schema] = [];

                                newFields.keys[schema] = newFields.keys[
                                  schema
                                ].includes(key)
                                  ? []
                                  : [key];

                                setFields(newFields);
                                buildMapping(newFields);
                              }
                            }}
                          >
                            <Text color={isPk ? "yellow" : "gray.600"}>
                              {isPk ? (
                                <>
                                  <RiKey2Fill />
                                  <Text fontSize={"xxs"}>ID</Text>
                                </>
                              ) : (
                                <RiKey2Line />
                              )}
                            </Text>
                          </GhostButton>
                        ) : (
                          column.schemaTypeName && (
                            <GhostButton
                              onClick={() => {
                                let newFields = { ...fields };
                                let key = column.field?.name;
                                let schema = column.schemaTypeName;

                                if (!!schema) {
                                  if (!newFields.secondaryKeys)
                                    newFields.secondaryKeys = {};
                                  if (!newFields.secondaryKeys[schema]) {
                                    newFields.secondaryKeys[schema] = [];
                                  }

                                  newFields.secondaryKeys[schema] =
                                    newFields.secondaryKeys[schema].includes(
                                      key
                                    )
                                      ? []
                                      : [key];

                                  setFields(newFields);
                                  buildMapping(newFields);
                                }
                              }}
                            >
                              <Text color={isLookup ? "blue" : "gray.600"}>
                                {isLookup ? (
                                  <>
                                    <RiSearchEyeLine />
                                    <Text fontSize={"xxs"}>Lookup</Text>
                                  </>
                                ) : (
                                  <RiSearchEyeLine />
                                )}
                              </Text>
                            </GhostButton>
                          )
                        )}

                        {!column.schemaTypeName && <Box width={52}></Box>}
                        <Flex
                          width={2 / 3}
                          flexDirection="column"
                          sx={{ wordBreak: "break-all" }}
                        >
                          <Text fontWeight="bold">{column.label}</Text>
                          <Text variant="muted">{column.name}</Text>
                          <Text variant="muted">{column.data}</Text>
                        </Flex>
                        <Flex width={1 / 3} alignItems="center">
                          <DroppableSection droppableId={column.name}>
                            <Flex width="100%" alignItems="center">
                              {column.field && (
                                <>
                                  {ModelTypeIcon(column.schemaTypeName)}
                                  <Text mx={2}>{column.field.displayName}</Text>
                                </>
                              )}
                              {!column.field && (
                                <Text variant="muted" mx={2}>
                                  Drag field here
                                </Text>
                              )}
                            </Flex>
                          </DroppableSection>
                        </Flex>
                        <Flex justifyItems={"flex-end"}>
                          <Box>
                            <GhostButton
                              onClick={() => {
                                let newFields = { ...fields };
                                //newFields[column.name] = undefined;
                                delete newFields[column.name];
                                setFields(newFields);
                                buildMapping(newFields);
                              }}
                            >
                              <CloseIcon />
                            </GhostButton>
                          </Box>
                        </Flex>
                      </Flex>
                    );
                  })}
            </Box>
          </Flex>
          <Flex width={1 / 2} mx={2} px={2} flexDirection="column">
            <Text variant="h4">Userlot Fields</Text>
            <Tabs selectedIndex={selectedTab} onSelect={setSelectedTab}>
              <TabList>
                {dataModels
                  ?.filter((s) => s.typeName != "Product")
                  .map((schema, i) => (
                    <Tab key={schema.id}>
                      <Flex alignItems="center" flexDirection={"column"}>
                        {ModelTypeIcon(schema.typeName, 16)}
                        <Text fontSize="xs">{schema?.displayName}</Text>
                      </Flex>
                    </Tab>
                  ))}
              </TabList>
              {dataModels
                ?.filter((s) => s.typeName != "Product")
                .map((schema) => {
                  console.log(schema.attributes);
                  return (
                    <TabPanel key={schema.id}>
                      <Flex bg="white" flexDirection="column">
                        <DroppableSection droppableId={schema.id}>
                          {schema?.attributes
                            .filter(
                              (f) =>
                                (!f.productId || f.productId === product?.id) &&
                                (f.access == "readwrite" || f.access == "write")
                            )
                            .sort((x, y) =>
                              x.displayName.localeCompare(y.displayName)
                            )
                            .sort((x, y) => (x.secondaryKey ? -1 : 1))
                            .sort((x, y) => (x.primaryKey ? -1 : 1))
                            .map((field, index) => (
                              <DraggableItem
                                key={index}
                                typeName={schema.typeName}
                                item={field}
                                index={index}
                              >
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                  width="100%"
                                >
                                  <Flex
                                    width="100%"
                                    flexDirection={"column"}
                                    justifyContent="center"
                                  >
                                    <Flex alignItems="center">
                                      <Flex alignItems="center">
                                        {ModelTypeIcon(schema.typeName, 16)}
                                        <Text
                                          mx={1}
                                          fontWeight={
                                            (field.primaryKey ||
                                              field.secondaryKey) &&
                                            "bold"
                                          }
                                        >
                                          {field.displayName}
                                        </Text>
                                      </Flex>
                                      {field.productId && (
                                        <Text variant="muted" mx={2}>
                                          {product?.name}
                                        </Text>
                                      )}
                                      {field.primaryKey && (
                                        <Flex alignItems={"center"}>
                                          <Text color={"yellow"}>
                                            <RiKey2Fill />
                                          </Text>
                                          <Text vaiant="muted" fontSize={"xxs"}>
                                            Product
                                          </Text>
                                        </Flex>
                                      )}
                                      {field.secondaryKey && (
                                        <Flex alignItems={"center"}>
                                          <Text color={"green"}>
                                            <RiKey2Fill />
                                          </Text>
                                          <Text vaiant="muted" fontSize={"xxs"}>
                                            Other
                                          </Text>
                                        </Flex>
                                      )}
                                    </Flex>
                                    <Text
                                      variant="muted"
                                      fontSize={"xxs"}
                                      mx={2}
                                    >
                                      {field.description}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </DraggableItem>
                            ))}
                        </DroppableSection>
                      </Flex>
                    </TabPanel>
                  );
                })}
            </Tabs>
          </Flex>
        </DndContext>
      </Flex>
    </Flex>
  );
}
MapFields.propTypes = {
  fieldMapping: PropTypes.object,
  fieldDefinition: PropTypes.object,
  title: PropTypes.string,
  onChange: PropTypes.func,
};

export default withRouter(MapFields);
