import { Helmet } from "react-helmet/es/Helmet";

import { onAdminDomain, onAppDomain } from "../auth-helper";
import { useEffect, useState } from "react";

export const userlotInit = () => {
  const userlotKey = window.UserlotConfig?.userlotKey;
  const userlotUrl = window.UserlotConfig?.userlotUrl;
  const [userlotScript, setUserlotScript] = useState("");
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (onAdminDomain() || onAppDomain() || window.userlot) {
      return;
    }

    console.log(userlotUrl);
    setUserlotScript(`
              window.Userlot=window.Userlot||[];
              function userlot(){window.Userlot.push(arguments);}
              (function(a,b,c,d){var cz=a.createElement(b); cz.type=c; cz.async=true; cz.src=d;var s=a.getElementsByTagName(b)[0];s.parentNode.insertBefore(cz, s);})(document, "script",'text/javascript', '${userlotUrl}/analytics.js');
              userlot(['init', 'admin', '${userlotKey}']);
                `);
    setInitialised(true);
  }, []);

  return userlotKey ? (
    <Helmet>
      <script>{userlotScript}</script>
    </Helmet>
  ) : (
    ""
  );
};

const userlotLoadedCheck = () => {
  if (!window.userlotUser) {
    window.userlotUser = {};
  }
  return window.userlot;
};

export const userlotIdentify = (user) => {
  if (!userlotLoadedCheck() || !user) {
    return;
  }

  const currentUser = window.userlotUser;

  if (currentUser?.id !== user?.id) {
    window.userlotUser = user;
    console.log(`🧑 Got user ${JSON.stringify(window.userlotUser)}`);
    window.userlot([
      "identify",
      window.userlotUser.email,
      window.userlotUser.id,
      window.userlotUser.companyName,
      window.userlotUser.companyId,
    ]);
  }
};

export const userlotTrackEvent = (eventName, data) => {
  if (!userlotLoadedCheck()) {
    return;
  }
  const currentUser = window.userlotUser;

  if (currentUser) {
    window.userlot(["trackEvent", eventName, 0, data]);
  }
};
