import React from "react";
import { Flex, Box, Text } from "rebass";
import PropTypes from "prop-types";
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "Components/Common/Icons";
import Label from "Components/Common/Forms/Label";

function DoubleStatCard(props) {
  return (
    <Flex
      p={2}
      flex={1}
      flexGrow={1}
      alignItems="center"
      flexDirection="column"
    >
      <Flex
        flex={1}
        width="100%"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex mx={3} flexDirection="column">
          <Text variant="muted" name={props.label}>
            {props.label}
          </Text>
          <Text variant="h1" textAlign="center" color="brand">
            {props.value}
          </Text>
        </Flex>
        <Flex mx={3} flexDirection="column">
          <Text variant="muted" name={props.label2}>
            {props.label2}
          </Text>
          <Text variant="h1" color="brand" textAlign="center">
            {props.value2}
          </Text>
        </Flex>
        {props.increase !== undefined && (
          <Flex flexDirection="column">
            <Text
              variant="muted"
              sx={{ textTransform: "uppercase" }}
              textAlign="center"
              fontWeight="bold"
              color="gray.500"
            >
              <Flex justifyContent="center">
                <Text color={props.increase > 0 ? "green" : "gray.500"}>
                  <ArrowUpIcon size={12} />
                </Text>
                <Text color={props.increase < 0 ? "red" : "gray.500"}>
                  <ArrowDownIcon size={12} />
                </Text>
              </Flex>
            </Text>
            <Flex>
              <Text variant="h1" color="green">
                {props.increase}
              </Text>
              <Text variant="h1" color="green" sx={{ fontSize: "0.83em" }}>
                %
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

DoubleStatCard.propTypes = {
  label: PropTypes.string,
  label2: PropTypes.string,
  increase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default DoubleStatCard;
