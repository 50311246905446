import { useMutation, useQuery } from "@apollo/react-hooks";
import React from "react";
import { useToasts } from "react-toast-notifications";
import { Box, Flex, Text } from "rebass";
import ExternalIdMutation from "../../../graphql/mutations/ExternalId.gql";
import SourcesQuery from "../../../graphql/queries/Sources.gql";

import Modal from "Components/Common/Modal";
import CustomFormGroup from "../../Common/Forms/CustomFormGroup";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import GhostButton from "../../Common/Buttons/GhostButton";
import { SaveIcon, TrashIcon } from "../../Common/Icons";
import { Formik } from "formik";
import * as Yup from "yup";

function ExternalIdForm(props) {
  const { addToast } = useToasts();
  const [save, { loading }] = useMutation(ExternalIdMutation);
  const sourcesQuery = useQuery(SourcesQuery, {
    fetchPolicy: "network-only",
  });
  const sources =
    sourcesQuery &&
    sourcesQuery.data?.sources?.map((s, i) => {
      const d = {
        value: s.value,
        label: (
          <Box key={s.value} mx={2}>
            <Text>{s.label}</Text>
            <Text variant="muted">{s.info}</Text>
          </Box>
        ),
        sourceId: s.value,
        sourceType: s.info,
      };
      return d;
    });
  console.log(props.item);
  console.log(sources);
  return (
    <Modal
      width="500px"
      title="Edit External Id"
      open={props.open}
      onDismiss={() => {
        props.onDismiss();
      }}
    >
      <Flex flexDirection="column" px={3} pb={4} alignItems={"center"} flex={1}>
        <Formik
          enableReinitialize
          initialValues={props.item || {}}
          autoComplete={false}
          validationSchema={Yup.object().shape({
            externalId: Yup.string().required("Required"),
            sourceId: Yup.string().required("Required"),
          })}
          onSubmit={(values, actions) => {
            console.log(values);
            save({
              variables: {
                id: props.id,
                entityType: props.typeName,
                externalId: values.externalId,
                sourceId: values.sourceId,
                sourceType: values.sourceType,
                deleteId: props.item.id,
              },
            }).then(
              (response) => {
                if (!response.data?.entityExternalId?.errors) {
                  props.onSave();
                } else {
                  addToast(
                    <Flex>{response.data?.entityExternalId?.errors[0]}</Flex>,
                    {
                      appearance: "error",
                      autoDismiss: false,
                    }
                  );
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }}
        >
          {(formProps) => {
            const { values, errors, setFieldValue, handleBlur, handleSubmit } =
              formProps;
            return (
              <Flex flexDirection="column" width={500}>
                {props.item?.id ? (
                  <Box bg="redLight" p={3} mb={2}>
                    <Text>
                      Are you sure you want to remove this link to this account.
                      This may impact API and data updates in the future.
                    </Text>
                  </Box>
                ) : (
                  <Box bg="gray.100" p={3} mb={2}>
                    <Text>
                      Adding an ID will cause data through the APIs to link to
                      this account.
                    </Text>
                  </Box>
                )}
                <Flex flexDirection={"column"} flex={1}>
                  <CustomFormGroup
                    type="list"
                    placeholder="Source"
                    label="Source"
                    name="sourceId"
                    options={sources}
                    value={values.sourceId}
                    readOnly={props.item?.id}
                    onChange={(name, value) => {
                      setFieldValue(name, value?.value);
                      setFieldValue("sourceType", value?.sourceType);
                    }}
                    helperText={errors && errors.sourceId && errors.sourceId}
                  />
                  <CustomFormGroup
                    placeholder="ID"
                    label="ID"
                    name="externalId"
                    value={values.externalId}
                    onChange={setFieldValue}
                    readOnly={props.item?.id}
                    helperText={
                      errors && errors.externalId && errors.externalId
                    }
                  />
                </Flex>
                <Box my={1} />
                <Flex alignItems="center" justifyContent="flex-end">
                  {props.onClose && (
                    <GhostButton onClick={props.onClose}>Close</GhostButton>
                  )}

                  {props.item?.id ? (
                    <PrimaryButton
                      sx={{ background: "warning" }}
                      leftIcon={<TrashIcon />}
                      onClick={() => handleSubmit()}
                    >
                      Delete
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      leftIcon={<SaveIcon />}
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </PrimaryButton>
                  )}
                </Flex>
              </Flex>
            );
          }}
        </Formik>
      </Flex>
    </Modal>
  );
}

export default ExternalIdForm;
