import React, { useState } from "react";
import Select from "Components/Common/Forms/Select";
import { useQuery } from "@apollo/react-hooks";
import { Box, Flex, Link, Text } from "rebass";
import Label from "Components/Common/Forms/Label";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import EmailTemplateQuery from "../../../../graphql/queries/EmailTemplates.gql";
import TagsEditor from "../../../Common/Tags/TagsEditor";
import getEmailDomains from "hooks/getEmailDomains";
import { getUsers } from "hooks/getUsers";
import CheckboxGroup from "../../../Common/CheckboxGroup";
import RadioGroup from "../../../Common/RadioGroup";
import UserFilterForm from "./UserFilterForm";

import { Formik } from "formik";
import * as Yup from "yup";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";

function EmailForm(props) {
  const { field, fieldName, setFieldValue, index, handleBlur, values } = props;
  const { loading, data, error, client } = useQuery(EmailTemplateQuery, {});
  const { data: emailDomains } = getEmailDomains();
  const usersQuery = getUsers();
  const users = usersQuery.data?.users;

  const validatedDomains = emailDomains?.emailDomains
    .filter((domain) => domain.verified)
    .map((domain) => domain.domain);

  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  const verifyDomainIsValidated = (userId) => {
    const fromEmail = users?.find((user) => user.id === userId)?.email;
    const fromDomain = fromEmail?.substring(fromEmail?.lastIndexOf("@") + 1);
    const okDomain = validatedDomains?.includes(fromDomain);
    console.log(`We found a validated from? ${okDomain}`);
    return okDomain;
  };

  const options =
    (!loading &&
      data?.emailTemplates &&
      data.emailTemplates.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      })) ||
    [];

  const emailTemplate =
    !loading &&
    data?.emailTemplates &&
    data.emailTemplates.find((t) => t.id === field?.config?.emailTemplateId);

  const fromAccounts = [
    { value: "account", label: "Account Owner" },
    { value: "custom", label: "Custom" },
  ];

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        emailTemplateId: Yup.string().required("Required"),
        to: Yup.array().min(1, "Required").required("Required").nullable(),
        from: Yup.string().required("Required"),
        fromEmail: Yup.string().when("from", {
          is: (value) => value == "custom",
          then: Yup.string().email("Not a valid email").required("Required"),
          otherwise: Yup.string(),
        }),
        toAccountOwner: Yup.boolean().when("to", {
          is: (value) => value == "none",
          then: Yup.boolean().required("Required").oneOf([true], "Required"),
          otherwise: Yup.boolean(),
        }),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        console.log(errors);
        return (
          <Flex flex={1} flexDirection={"column"}>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <Box mt={2}>
              <Box>
                <UserFilterForm {...props} label="To" />
              </Box>

              <Flex alignItems="center" my={3} mx={4}>
                <Label>INCLUDE</Label>
                <Box mx={2}>
                  <CustomFormGroup
                    placeholder="Account Owner"
                    label="Account Owner"
                    noMargin
                    name={`${defaultFieldName}.toAccountOwner`}
                    value={field.config?.toAccountOwner}
                    type="checkbox"
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={errors.toAccountOwner && errors.toAccountOwner}
                  />
                </Box>
              </Flex>
              <Flex alignItems="top" flex={1} my={3}>
                <Box my={1}>
                  <Label>From</Label>
                </Box>
                <Flex alignItems="top">
                  <Box mx={2} width="350px">
                    <RadioGroup
                      name={`${defaultFieldName}.from`}
                      selectedOption={
                        fromAccounts.find((o) => o.value === field.config?.from)
                          ?.value
                      }
                      options={fromAccounts}
                      onChange={(name, value) => {
                        setFieldValue(name, value?.value);
                        setFieldValue(
                          `${defaultFieldName}.fromAccountOwner`,
                          value?.value == "account"
                        );
                      }}
                    />
                    {errors?.from && (
                      <Text variant="muted" color="red">
                        {errors?.from}
                      </Text>
                    )}
                  </Box>
                  {field.config?.from == "custom" && (
                    <Flex alignItems="center">
                      <Box mx={1}>
                        <CustomFormGroup
                          label=""
                          noMargin
                          placeholder="Custom from Email"
                          width="300px"
                          name={`${defaultFieldName}.fromEmail`}
                          value={field.config?.fromEmail}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          helperText={errors.fromEmail && errors.fromEmail}
                        />
                        {field.config?.fromEmail &&
                          !errors?.config?.fromEmail &&
                          !verifyDomainIsValidated(field.config?.fromEmail) && (
                            <Text variant="muted" color="red">
                              Please validate your Email Domain first in Account
                              Settings
                            </Text>
                          )}
                      </Box>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Box>

            <Box my={2} />
            <Flex alignItems="center">
              <Box>
                <Select
                  placeholder="Template"
                  label="Template"
                  width={300}
                  name={`${defaultFieldName}.emailTemplateId`}
                  value={options?.find(
                    (obj) => obj.value === field?.config?.emailTemplateId
                  )}
                  onChange={(name, value) => {
                    setFieldValue(name, value?.value);
                    setFieldValue(`${defaultFieldName}.title`, value?.label);
                  }}
                  options={options}
                  helperText={errors.emailTemplateId && errors.emailTemplateId}
                />
              </Box>
              <Text m={2} pt={2}>
                or create
                <Link href="/settings/product/emailtemplates/new" mx={1}>
                  new email template
                </Link>
              </Text>
            </Flex>
            {emailTemplate && (
              <Flex my={3} p={3} variant="box" flexDirection={"column"}>
                <Label>Subject</Label>
                <Text p={2} bg="gray.100">
                  {emailTemplate.subject}
                </Text>
                <Box my={2} />
                <Label>Body</Label>
                <Text
                  p={2}
                  bg="gray.100"
                  dangerouslySetInnerHTML={{
                    __html: emailTemplate.body,
                  }}
                ></Text>
              </Flex>
            )}
          </Flex>
        );
      }}
    </Formik>
  );
}

export default EmailForm;
