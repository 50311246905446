import React, { useState } from "react";
import { Box, Flex, Image, Text } from "rebass";
import Table from "Components/Common/Table";
import { MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import moment from "moment";
import numeral from "numeral";
import GhostButton from "../../Common/Buttons/GhostButton";
import { EditIcon, PlusIcon, TrashIcon } from "../../Common/Icons";
import CompanyRevenueEntryForm from "./CompanyRevenueEntryForm";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";

function CompanyRevenueEntries(props) {
  const { loading, data, refetch } = getData(MODEL_TYPES.Company, props.id);
  const dataTable = data?.company?.details?.companyRevenueEntries || [];
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  let defaultRevenueType = "mrr";

  const readOnly = !!data?.company?.details?.churnReasonUpdatedAt;

  switch (
    _.first(dataTable.filter((d) => d.revenueType !== "2"))?.revenueType
  ) {
    case "0":
      defaultRevenueType = "mrr";
      break;
    case "1":
      defaultRevenueType = "arr";
      break;
    case "2":
      defaultRevenueType = "nonrr";
      break;
  }

  return (
    <Flex flex={1} flexDirection="column">
      <Flex my={2} justifyContent="space-between" alignItems="center">
        <Text variant="h3">Revenue</Text>
        {!readOnly && (
          <PrimaryButton
            leftIcon={<PlusIcon />}
            onClick={() => {
              setSelectedItem(undefined);
              setOpen(true);
            }}
          >
            Add
          </PrimaryButton>
        )}
      </Flex>
      <Box bg="gray.100" p={3} my={2}>
        <Text variant="muted">
          Add or update revenue details for this account. This can log monthly,
          annual or ad hoc revenue.
        </Text>
      </Box>
      <Flex flexDirection={"column"} fontSize={"sm"}>
        <Flex flex={1}>
          <Flex flex={2 / 4} fontWeight={"bold"}>
            Type
          </Flex>
          <Flex flex={1 / 4} fontWeight={"bold"}>
            MRR
          </Flex>
          <Flex flex={1 / 4} fontWeight={"bold"}>
            ARR
          </Flex>
          <Flex width={80}></Flex>
        </Flex>
        <Flex
          sx={{ overflowY: "scroll", maxHeight: 500 }}
          flexDirection={"column"}
        >
          {dataTable.map((row) => {
            let arrValue = row.revenueValue;
            let mrrValue = row.revenueValue;
            if (row.revenueType == 0) {
              arrValue = row.revenueValue * 12;
            } else if (row.revenueType == 1) {
              mrrValue = row.revenueValue / 12;
            }

            let bg = "white";
            if (
              moment().isSameOrAfter(moment(row.createdAt)) &&
              moment().isSameOrBefore(moment(row.endAt))
            ) {
              bg = "brandHighlight";
            }
            return (
              <Flex key={row.id} flex={1} bg={bg} p={2} fontSize={"sm"}>
                <Flex flex={2 / 4} flexDirection={"column"}>
                  {row.revenueType == 0
                    ? "Subscription (Monthly)"
                    : row.revenueType == 1
                    ? "Subscription (Annual)"
                    : "Other"}
                  <Text variant="muted">
                    {moment(row.createdAt).format("DD MMM yy")}-
                    {moment(row.endAt).format("DD MMM yy")}
                  </Text>
                </Flex>
                <Flex flex={1 / 4}>
                  <Text color={row.revenueValue == 0 && "red"}>{`$${numeral(
                    mrrValue
                  ).format("0,0")}`}</Text>
                </Flex>
                <Flex flex={1 / 4}>
                  <Text color={row.revenueValue == 0 && "red"}>{`$${numeral(
                    arrValue
                  ).format("0,0")}`}</Text>
                </Flex>
                <Flex width={80}>
                  {!readOnly && (
                    <GhostButton
                      onClick={() => {
                        setSelectedItem(row);
                        setOpen(true);
                      }}
                    >
                      <EditIcon />
                    </GhostButton>
                  )}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      <CompanyRevenueEntryForm
        key={selectedItem?.id}
        item={selectedItem}
        id={props.id}
        typeName={props.parentType}
        defaultRevenueType={defaultRevenueType}
        renewalDate={data?.company?.details?.renewalDate}
        open={open}
        onDismiss={() => setOpen(false)}
        onSave={() => {
          setOpen(false);
          refetch();
        }}
      />
    </Flex>
  );
}

export default CompanyRevenueEntries;
