import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { Flex, Text, Button, Box, Link } from "rebass";
import PropTypes from "prop-types";
import UserBadge from "../Common/UserBadge";
import moment from "moment";
import { triggerActions } from "../../consts";

export const ActivityAutomationTitle = ({ activity }) => {
  return (
    <Flex>
      <Text fontWeight={"bold"} mr={1}>
        Automation
      </Text>
      triggered
    </Flex>
  );
};
function ActivityAutomation({ activity, refetch }) {
  const [open, setOpen] = useState(false);

  const type = triggerActions.find(
    (t) => t.id == activity.actionable.actionType
  );
  return (
    <Flex flexDirection={"column"} bg="gray.100" p={3}>
      <Flex flexDirection="column">
        {activity.actionable?.name}
        <Flex alignItems={"center"} mt={2}>
          <Text variant="muted" mr={1}>
            {type.icon}
          </Text>
          <Text variant="muted">{type.label}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
ActivityAutomation.propTypes = {
  activity: PropTypes.object,
};
export default ActivityAutomation;
