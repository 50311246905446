import React, { useState } from "react";
import { Flex, Text } from "rebass";
import { useHistory } from "react-router-dom";
import { useDynamicSchema } from "hooks/DynamicSchema";
import { MODEL_TYPES } from "consts";
import Table from "Components/Common/Table";
import TableEmpty from "../TableEmpty";
import { getContactPeopleFields } from "../../../hooks/DynamicSchema";
import CompanyMenu from "../../Dashboard/CompanyHealth/CompanyMenu";
import CompanyFilter from "../../Dashboard/CompanyHealth/CompanyFilter";

function EndUsers({
  data,
  loading,
  onChangeRowsPerPage,
  onChangePage,
  paginationTotalRows,
  onSelectedRowsChange,
  refetch,
  onSort,
}) {
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const { columns } = useDynamicSchema(
    MODEL_TYPES.EndUser,
    getContactPeopleFields(MODEL_TYPES.EndUser)
  );

  return (
    <Flex flex={1} flexDirection="column">
      <Table
        title=""
        defaultSortField="name"
        selectableRows
        columns={columns}
        data={data}
        loading={loading}
        exportable
        configurable
        onSort={onSort}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        paginationTotalRows={paginationTotalRows}
        selectedRows={selectedRows}
        onSelectedRowsChange={(rows) => {
          setSelectedRows(rows);
          onSelectedRowsChange && onSelectedRowsChange(rows);
        }}
        onRowClicked={(p) => {
          const route = p.company
            ? `contacts/accounts/${p.company?.id}/users`
            : `contacts/users`;

          history.push(`/${route}/${p.endUserId}`);
        }}
        search={
          <CompanyFilter
            module={"contacts"}
            onChange={() => {
              setSelectedRows([]);
              refetch && refetch();
            }}
          />
        }
        actions={
          <CompanyMenu
            selectedRows={selectedRows.map((d) => {
              return { ...d, id: d.endUserId };
            })}
            parentType="EndUser"
            onChange={() => {
              setSelectedRows([]);
              refetch && refetch();
            }}
          />
        }
        noDataText={
          <TableEmpty help="No data returned, check selected filters." />
        }
      />
    </Flex>
  );
}

export default EndUsers;
