import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { Flex, Button, Text, Box } from "rebass";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "Components/Common/Loader";
import AssignTagMutation from "../../../graphql/mutations/AssignTags.gql";
import { getAllTags } from "../../../hooks/getAllTags";
import PropTypes from "prop-types";
import TagsEditor from "../../Common/Tags/TagsEditor";

function AssignTags(props) {
  const { addToast } = useToasts();
  const [selectedTags, setSelectedTags] = useState([]);

  const { data } = getAllTags();
  const [save, { loading }] = useMutation(AssignTagMutation);

  const tags = data?.allTags || [];

  const assignTag = (del) => {
    save({
      variables: {
        ids: props.ids,
        parentType: props.parentType,
        tagList: selectedTags,
        delete: del,
      },
    }).then(
      (response) => {
        if (!response.data?.assignTags?.errors) {
          props.onSave();
        } else {
          addToast(<Flex>{response.data?.assignTags?.errors[0]}</Flex>, {
            appearance: "error",
            autoDismiss: false,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Flex flexDirection="column" px={3} pb={4}>
      {loading ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Loader />
          <Text mx={2}>Please wait we are updating your accounts tags</Text>
        </Flex>
      ) : (
        <>
          <Flex>
            <TagsEditor
              parentType={props.parentType}
              label=""
              value={selectedTags}
              onChange={(t) => {
                setSelectedTags(t);
              }}
            />
          </Flex>
          <Flex mt={3} justifyContent="space-between">
            <Button
              variant={selectedTags ? "primary" : "disabled"}
              disabled={!selectedTags}
              onClick={() => assignTag(false)}
            >
              Add Selected Tags
            </Button>
            <Button
              variant={"error"}
              disabled={!selectedTags}
              onClick={() => assignTag(true)}
            >
              Remove Selected Tags
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
}
AssignTags.propTypes = {
  companyIds: PropTypes.array,
  onSave: PropTypes.func,
};
export default AssignTags;
