import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { Flex, Text, Box, Link } from "rebass";
import { Input } from "@rebass/forms";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import {
  CheckCircleIcon,
  CheckCircleOffIcon,
  CloseIcon,
  ListIcon,
  PlaybookIcon,
  RefreshIcon,
  SearchIcon,
  WorkflowIcon,
} from "../../../Common/Icons";
import WorkflowsQuery from "../../../../graphql/queries/Workflows.gql";
import TagsQuery from "../../../../graphql/queries/Tags.gql";
import MenuMultiSelect from "../../../Common/Forms/MenuMultiSelect";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";

function LibrarySearch(props) {
  const history = useHistory();
  const [searchText, setSearchText] = useState(undefined);
  const [selectedType, setSelectedType] = useState("all");
  const [selectedTags, setSelectedTags] = useState([]);

  const {
    data: workflowData,
    refetch,
    loading,
  } = useQuery(WorkflowsQuery, {
    variables: {
      searchText: searchText,
      tags: selectedTags,
    },
    fetchPolicy: "network-only",
  });

  const { data: tags } = useQuery(TagsQuery, {
    variables: {
      modelType: "categories",
    },
  });

  const workflows = workflowData?.workflows;
  let title = (selectedType === "playbook" && "Playbooks") || "";
  title = (selectedType === "workflow" && "Workflows") || title;

  return (
    <Flex flex={1} flexDirection="column" height="100%">
      <Flex flexDirection="column" my={3}>
        <Flex justifyContent="space-between">
          <Flex sx={{ position: "relative" }} alignItems="center">
            <Input
              bg="white"
              sx={{
                zIndex: 1,
              }}
              placeholder=""
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                refetch({
                  searchText: e.target.value,
                  tags: selectedTags,
                });
              }}
            />
            <Flex
              sx={{
                position: "absolute",
                right: 4,
                top: 0,
                bottom: 0,
                zIndex: 2,
              }}
              width={35}
              alignItems="center"
              justifyContent="center"
              color="line"
              p={2}
              onClick={() => {
                setSearchText("");
              }}
            >
              <CloseIcon size={20} />
            </Flex>
            <Flex
              sx={{
                position: "absolute",
                right: 1,
                top: 0,
                bottom: 0,
                zIndex: 2,
              }}
              width={35}
              alignItems="center"
              justifyContent="center"
              color="brandLight"
              p={2}
            >
              <SearchIcon size={20} />
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <Box m={2}>
              <MenuMultiSelect
                onChange={(types) => {
                  setSelectedTags(types);
                  refetch({
                    searchText: searchText,
                    tags: types,
                  });
                }}
                options={tags?.tags.map((o) => {
                  return { value: o, label: o };
                })}
                value={selectedTags}
              />
            </Box>
            <SecondaryButton onClick={() => refetch()}>
              <RefreshIcon />
            </SecondaryButton>
          </Flex>
        </Flex>
      </Flex>
      {workflows && workflows.length > 0 ? (
        <Flex sx={{ maxHeight: 550, overflow: "scroll" }}>
          <Flex flex={1} flexDirection="column">
            {workflows?.map((workflow, index) => (
              <Flex
                key={workflow.id}
                variant="box"
                sx={{
                  cursor: "pointer",
                  borderColor: props.selectedId === workflow.id && "brandLight",
                  ":hover": {
                    backgroundColor: "brandHighlight",
                    borderBottomWidth: 0,
                  },
                }}
                m={2}
                p={2}
                px={4}
                bg={props.selectedId === workflow.id && "brandHighlight"}
                color={props.selectedId === workflow.id && "brandLight"}
                alignItems="center"
                onClick={() => props.onChange(workflow)}
              >
                <Flex
                  flex={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex flex={1} mx={2} flexDirection="column">
                    <Text
                      variant="h4"
                      color={props.selectedId === workflow.id && "brandLight"}
                    >
                      {workflow.name}
                    </Text>
                    <Text
                      mt={1}
                      variant="muted"
                      color={props.selectedId === workflow.id && "brandLight"}
                    >
                      {workflow.description}
                    </Text>
                  </Flex>
                  <Flex flex={1} justifyContent="flex-end">
                    <Flex width={1 / 3} flexDirection="column">
                      <Text
                        textAlign="center"
                        variant="label"
                        fontSize="xxs"
                        color={props.selectedId === workflow.id && "brandLight"}
                      >
                        Total Days
                      </Text>
                      <Text textAlign="center">{workflow.totalDays || 0}</Text>
                    </Flex>
                    <Flex width={1 / 3} flexDirection="column">
                      <Text
                        textAlign="center"
                        variant="label"
                        fontSize="xxs"
                        color={props.selectedId === workflow.id && "brandLight"}
                      >
                        Total Steps
                      </Text>
                      <Text textAlign="center">{workflow.totalSteps || 0}</Text>
                    </Flex>
                    <Flex justifyContent="flex-end" alignItems="center">
                      {props.selectedId === workflow.id ? (
                        <Box color="brand">
                          <CheckCircleIcon />
                        </Box>
                      ) : (
                        <Box color="gray.500">
                          <CheckCircleOffIcon />
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center" m={4} p={4}>
          No automations have been configured.
          <Link href="/settings/product/library" mx={2}>
            Create new automations for your product.
          </Link>
        </Flex>
      )}
      {(!workflows || workflows.length === 0) && title && (
        <Flex
          sx={{ maxWidth: 400 }}
          p={2}
          my={3}
          flexDirection="column"
          justifyContent="center"
        >
          <Text variant="muted" mb={3}>
            No {title.toLowerCase()} have been found.
          </Text>
          <Box>
            <PrimaryButton
              onClick={() =>
                history.push(`/settings/product/${title.toLowerCase()}/new`)
              }
            >
              <Flex alignItems="center" justifyContent="center">
                <PlaybookIcon />
                <Text mx={2}>Create</Text>
              </Flex>
            </PrimaryButton>
          </Box>
        </Flex>
      )}
    </Flex>
  );
}

export default LibrarySearch;
