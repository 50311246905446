import React, { useState } from "react";
import { Flex, Text, Box, Button } from "rebass";
import "react-tabs/style/react-tabs.css";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { FieldArray } from "formik";
import { useDynamicSchemas } from "hooks/DynamicSchema";
import { dateOffsetOptions, MODEL_TYPES, ModelTypeIcon } from "consts";
import { CloseIcon, PlusIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import {
  GetSegmentOptions,
  matchesOptions,
} from "../../../Filter/Segment/SegmentSubForm";
const _ = require("lodash");

const DEFAULT_SCORE = 50;
function FactorForm(props) {
  const { values, errors, setFieldValue } = props;

  let dataModels = useDynamicSchemas();
  dataModels = dataModels?.filter(
    (m) =>
      m.typeName !== MODEL_TYPES.Comment.name &&
      m.typeName !== MODEL_TYPES.Event.name &&
      m.typeName !== MODEL_TYPES.Ticket.name &&
      m.typeName !== MODEL_TYPES.Product.name
  );

  let fields = [];
  dataModels?.map((entity) => {
    entity?.attributes.map((m) => {
      fields.push({
        value: `${entity.id}.${m.name}`,
        label: `${entity.displayName} - ${m.displayName}`,
        field: m.name,
        entity: entity.id,
        type: entity.type,
      });
    });
  });
  return (
    <Flex flex={1} flexDirection="column">
      <Flex flexDirection="column" w="100%">
        <>
          <FieldArray
            name={`config.productFactors`}
            render={(factorArrayHelpers) => (
              <>
                <Flex justifyContent="flex-end">
                  <Box mx={2}>
                    <PrimaryButton
                      onClick={() => {
                        factorArrayHelpers.push({
                          name: "",
                          score: DEFAULT_SCORE,
                          criteria: [
                            {
                              score: DEFAULT_SCORE,
                              items: [{ type: "good" }, { type: "poor" }],
                            },
                          ],
                          type: "fixed",
                        });
                      }}
                      leftIcon={<PlusIcon />}
                    >
                      Add
                    </PrimaryButton>
                  </Box>
                </Flex>
                {values?.config.productFactors &&
                  values?.config.productFactors.length > 0 &&
                  values?.config.productFactors.map((field, idex) => {
                    return (
                      <Box
                        key={idex}
                        my={2}
                        bg="gray.100"
                        p={2}
                        sx={{ position: "relative" }}
                      >
                        <Button
                          variant="circle"
                          bg="gray.300"
                          sx={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            width: 25,
                            height: 25,
                            padding: 0,
                          }}
                          onClick={() => factorArrayHelpers.remove(idex)}
                        >
                          <Flex
                            color="primary"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              width: 25,
                              height: 25,
                            }}
                          >
                            <CloseIcon size={16} />
                          </Flex>
                        </Button>
                        <Flex>
                          <CustomFormGroup
                            label="Name"
                            name={`config.productFactors[${idex}].name`}
                            value={field?.name}
                            onBlur={props.handleBlur}
                            onChange={setFieldValue}
                          />
                        </Flex>
                        <Box my={2} />
                        <FieldArray
                          name={`config.productFactors[${idex}].criteria`}
                          render={(arrayHelpers) => (
                            <Box>
                              {values?.config.productFactors[idex]?.criteria &&
                              values?.config.productFactors[idex]?.criteria
                                .length > 0
                                ? values?.config.productFactors[
                                    idex
                                  ]?.criteria.map((item, idx) => (
                                    <Flex
                                      key={idx}
                                      alignItems="flex-end"
                                      justifyContent="center"
                                    >
                                      <SearchSubForm
                                        index={idx}
                                        fieldName={`config.productFactors[${idex}].criteria`}
                                        field={item}
                                        factor={
                                          values?.config.productFactors[idex]
                                        }
                                        factorType={field.type}
                                        setFieldValue={props.setFieldValue}
                                        values={values}
                                        errors={errors}
                                        onBlur={props.handleBlur}
                                        onDelete={() =>
                                          arrayHelpers.remove(idx)
                                        }
                                      />
                                    </Flex>
                                  ))
                                : undefined}
                              <PrimaryButton
                                onClick={() =>
                                  arrayHelpers.push({
                                    score: DEFAULT_SCORE,
                                    items: [{ type: "good" }, { type: "poor" }],
                                  })
                                }
                                leftIcon={<PlusIcon size="12" />}
                              >
                                And
                              </PrimaryButton>
                            </Box>
                          )}
                        />
                      </Box>
                    );
                  })}
              </>
            )}
          />
        </>
      </Flex>
    </Flex>
  );
}

export default FactorForm;

const HealthBadge = (props) => {
  let color =
    props.type === "good" ? "green" : props.type === "poor" ? "red" : "yellow";
  let label =
    props.type === "good"
      ? "Healthy"
      : props.type === "poor"
      ? "At Risk"
      : "Ok";
  return (
    <Flex
      variant="badge"
      bg={color}
      justifyContent="center"
      alignItems="center"
      mx={2}
      width={60}
    >
      <Text textAlign="center">{label}</Text>
    </Flex>
  );
};

export function SearchSubForm(props) {
  const selectedField = props.field;
  let dataModels = useDynamicSchemas();
  dataModels = dataModels?.filter(
    (m) =>
      m.typeName !== MODEL_TYPES.Comment.name &&
      m.typeName !== MODEL_TYPES.Event.name &&
      m.typeName !== MODEL_TYPES.Ticket.name &&
      m.typeName !== MODEL_TYPES.Product.name
  );

  let fields = [];
  dataModels?.map((entity) => {
    entity?.attributes.map((m) => {
      fields.push({
        value: `${entity.id}.${m.name}`,
        label: (
          <Flex alignItems="center">
            <Text mx={2}>{ModelTypeIcon(entity.typeName, 16)}</Text>
            {m.displayName}
          </Flex>
        ),
        field: m.name,
        entity: entity.id,
        type:
          m.calculatedType && m.calculatedType === "exists"
            ? "boolean"
            : m.type,
      });
    });
  });

  const fieldDef = fields.find(
    (o) =>
      o.field === selectedField?.field && o.entity === selectedField?.entity
  );
  const fieldType = fieldDef?.type;
  return (
    <Flex width="100%" variant="box" m={2} flexDirection="column">
      <Flex width="100%" flexDirection="column" p={2}>
        <Flex alignItems="flex-end" sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <Button variant="ghost" onClick={props.onDelete}>
              <Flex alignItems="center" justifyContent="center">
                <CloseIcon />
              </Flex>
            </Button>
          </Box>
          <CustomFormGroup
            type="list"
            label="Field"
            width={300}
            name={`${props.fieldName}[${props.index}].field`}
            value={
              fields.find(
                (o) =>
                  o.field === selectedField?.field &&
                  o.entity === selectedField?.entity
              )?.value
            }
            onBlur={props.handleBlur}
            onChange={(name, value) => {
              props.setFieldValue(`${props.fieldName}[${props.index}]`, {
                ...selectedField,
                entity: value?.entity,
                field: value?.field,
              });
            }}
            options={fields}
          />
          {fieldType == "string" && (
            <Text variant="muted" mx={2}>
              {`eg. "test", "te", "st", "test1,test2,test3"`}
            </Text>
          )}
        </Flex>
        <Flex flexDirection="column">
          {selectedField.items?.map((item, itemidx) => (
            <Flex key={`selectedField-${itemidx}`} alignItems="center">
              <HealthBadge type={item.type} />
              <GetFieldEditor {...props} itemidx={itemidx} />
            </Flex>
          ))}
          <Flex my={2}>
            <HealthBadge type={"ok"} />
            <Text variant="muted" mx={2}>
              If <strong>Healthy</strong> or <strong>At Risk</strong> conditions
              not met
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

const getValueType = (fieldType) => {
  let type = undefined;
  switch (fieldType) {
    case "list":
      type = "list";
      break;
    case "boolean":
      type = "checkbox";
      break;
    default:
      type = "string";
      break;
  }
  return type;
};

const getFieldSize = (fieldType) => {
  let size = 80;
  switch (fieldType) {
    case "number":
    case "date":
    case "currency":
    case "calculated":
      size = 80;
      break;
    default:
      size = 250;
      break;
  }
  return size;
};

export const GetFieldEditor = (props) => {
  const { factorType, itemidx } = props;
  const selectedField = props.field;
  let dataModels = useDynamicSchemas();
  dataModels = dataModels?.filter(
    (m) => m.typeName !== MODEL_TYPES.Comment.name
  );

  let fields = [];
  dataModels?.map((entity) => {
    entity?.attributes.map((m) => {
      fields.push({
        value: `${entity.id}.${m.name}`,
        label: `${entity.displayName} - ${m.displayName}`,
        field: m.name,
        entity: entity.id,
        type: m.type,
      });
    });
  });

  const fieldDef = fields.find(
    (o) =>
      o.field === selectedField?.field && o.entity === selectedField?.entity
  );
  const fieldType = fieldDef?.type;

  let options = GetSegmentOptions(selectedField);

  const showOperators = !options && fieldType !== "boolean";
  const showTextField =
    selectedField?.items[itemidx]?.operator !== "EXISTS" &&
    selectedField?.items[itemidx]?.operator !== "BLANK";
  return (
    <Flex alignItems={"center"}>
      {showOperators && factorType !== "range" && (
        <>
          <CustomFormGroup
            type="list"
            label=""
            placeholder="compare to"
            width={250}
            name={`${props.fieldName}[${props.index}].items[${itemidx}].operator`}
            value={selectedField?.items[itemidx].operator}
            onChange={(name, value) => {
              props.setFieldValue(name, value?.value);
            }}
            onBlur={props.handleBlur}
            options={matchesOptions.filter((o) => o.types.includes(fieldType))}
          />
          <Box mr={1} />
        </>
      )}
      {options ? (
        <CustomFormGroup
          noMargin
          key={`${props.fieldName}[${props.index}].field-options`}
          type={"list"}
          options={options}
          width={200}
          name={`${props.fieldName}[${props.index}].value`}
          value={selectedField?.items[itemidx].value}
          onChange={(name, value) => {
            props.setFieldValue(name, value.value);
          }}
        />
      ) : (
        showTextField && (
          <Flex alignItems="center">
            <CustomFormGroup
              label=""
              type={getValueType(fieldType)}
              width={getFieldSize(fieldType)}
              name={`${props.fieldName}[${props.index}].items[${itemidx}].min`}
              value={selectedField?.items[itemidx].min}
              onBlur={props.handleBlur}
              onChange={(name, value) => {
                props.setFieldValue(name, value);
              }}
            />
          </Flex>
        )
      )}
      {selectedField?.items[itemidx].operator === "BETWEEN" && (
        <>
          <Box mr={1} />
          <CustomFormGroup
            label=""
            width={80}
            name={`${props.fieldName}[${props.index}].items[${itemidx}].max`}
            value={selectedField?.items[itemidx].max}
            onBlur={props.handleBlur}
            onChange={(name, value) => {
              props.setFieldValue(name, value);
            }}
          />
        </>
      )}
      {fieldType === "date" && (
        <>
          <Box mr={1} />
          <CustomFormGroup
            label=""
            type="list"
            options={dateOffsetOptions}
            width={190}
            name={`${props.fieldName}[${props.index}].items[${itemidx}].dateOffset`}
            value={selectedField?.items[itemidx].dateOffset}
            onBlur={props.handleBlur}
            onChange={(name, value) => {
              props.setFieldValue(name, value?.value);
            }}
          />
        </>
      )}
    </Flex>
  );
};
