import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import { Box, Flex, Text } from "rebass";
import { useTheme } from "emotion-theming";
import TaskIcon, { TaskCategories } from "./TaskDefaults";
import { CheckCircleIcon, TimeIcon } from "Components/Common/Icons";
import "react-big-calendar/lib/sass/styles";
import "assets/styles/override-react-big-calendar";
import { CustomIcon } from "Components/Common/Icons";

const localizer = momentLocalizer(moment);

function UserlotCalendar(props) {
  const categories = TaskCategories();
  const DragAndDropCalendar = withDragAndDrop(Calendar);
  const theme = useTheme();

  let data =
    props.data?.map((data) => {
      return {
        ...data,
        id: !data.id ? new Date().getTime() : data.id,
        type: "Task",
        start: moment(data.dueAt).toDate(),
        end: moment(data.dueAt).isAfter(data.endAt)
          ? moment(data.dueAt).toDate()
          : data.endAt
          ? moment(data.endAt).toDate()
          : moment(data.dueAt).add(30, "minutes").toDate(),
      };
    }) || [];

  const selectedEvent = data?.find((s) => s.selected);

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    let allDay = event.allDay;

    if (!allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (allDay && !droppedOnAllDaySlot) {
      allDay = false;
      end = moment(start).add(30, "minutes"); // the calendar makes it a whole day
    }

    const updatedEvent = { ...event, dueAt: start, endAt: end, allDay: allDay };

    props.onMoveEvent(updatedEvent);
  };

  const newEvent = (slot) => {
    let event = {
      title: "",
      dueAt: slot.start,
      type: "Task",
      endAt: slot.end,
    };
    props.onEventSelected && props.onEventSelected(event);
  };

  function Event({ event }) {
    const taskCategory = categories.find(
      (f) => f.value == event.taskCategoryId
    );
    return (
      <Box>
        <Flex alignItems="center">
          {event.completedAt ? (
            <Box color="green">
              <CheckCircleIcon />
            </Box>
          ) : (
            <CustomIcon componentName={event?.taskCategoryIcon} size={12} />
          )}
          <Text fontSize={"xxs"} ml={1} fontWeight="bold">
            {event.title ? (
              _.truncate(event.title, { length: 18, separator: " " })
            ) : (
              <Flex>{taskCategory?.description}</Flex>
            )}
          </Text>
        </Flex>
      </Box>
    );
  }

  const getEventStyle = (e, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: isSelected ? `${theme.colors.brandLight}` : "white",
        border: !isSelected && `solid thin ${theme.colors.brandLight}`,
        color: isSelected ? theme.colors.white : theme.colors.black,
        minHeight: isSelected && "40px",
        boxShadow: !isSelected
          ? `2px 0px 6px -1px ${theme.colors.brandLight}`
          : "",
      },
    };
  };

  return (
    <Flex flexDirection="column" width="100%">
      <DragAndDropCalendar
        startAccessor="start"
        endAccessor="end"
        allDayAccessor="allDay"
        localizer={localizer}
        selectable
        resizable
        scrollToTime={
          props.defaultDate
            ? moment(props.defaultDate).toDate()
            : moment().set("hour", 8).set("minute", 0)
        }
        defaultDate={props.defaultDate && moment(props.defaultDate).toDate()}
        step={30}
        views={props.defaultView ? [props.defaultView] : ["week"]}
        defaultView={props.defaultView || "week"}
        events={data}
        style={{
          height: props.height || window.outerHeight - 50,
          width: "100%",
        }}
        onNavigate={(event) => {
          props.onNavigate && props.onNavigate(event);
        }}
        onEventDrop={(event) => moveEvent(event)}
        selected={selectedEvent}
        onSelectSlot={(event) => newEvent(event)}
        onRangeChange={(event) => {
          props.onRangeChange && props.onRangeChange(event);
        }}
        onSelectEvent={(event) => {
          props.onEventSelected && props.onEventSelected(event);
        }}
        eventPropGetter={getEventStyle}
        components={{
          event: Event,
        }}
      />
    </Flex>
  );
}

export default UserlotCalendar;
