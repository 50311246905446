import React from "react";
import PropTypes from "prop-types";
import { Button, Text, Flex } from "rebass";

const PrimaryButton = ({
  leftIcon,
  rightIcon,
  onClick,
  children,
  disabled,
  sx,
}) => {
  return (
    <Button
      bg={disabled && "gray.500"}
      variant="action"
      onClick={onClick}
      disabled={!!disabled}
      sx={sx}
    >
      <Flex alignItems="center" justifyContent="center">
        {leftIcon && leftIcon}
        <Text ml={leftIcon && 1} mr={rightIcon && 2}>
          {children}
        </Text>
        {rightIcon && rightIcon}
      </Flex>
    </Button>
  );
};

PrimaryButton.propTypes = {
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  diabled: PropTypes.bool,
};
PrimaryButton.defaultProps = {
  leftIcon: undefined,
  rightIcon: undefined,
  diabled: false,
};

export default PrimaryButton;
