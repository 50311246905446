import React, { useEffect, useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import { Prompt } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { deepOmit } from "../../../../consts";
import IntegrationAuthenticationMutation from "../../../../graphql/mutations/IntegrationAuthentication.gql";

import AuthenticationForm from "./Authentication/AuthenticationForm";
import _ from "lodash";

function IntegrationAuthForm(props) {
  const { addToast } = useToasts();
  const [appAuth, setAppAuth] = useState();
  const [saveIntegrationAuthentication, {}] = useMutation(
    IntegrationAuthenticationMutation
  );

  const apiData = {
    name: props.app?.name,
    apiType: props.app?.authenticationType,
    apiName: props.app?.name,
    authenticationType: props.app?.authenticationType,
    username: "",
    password: "",
    settings: { subdomain: "" },
    authEndpoint: props.app?.authEndpoint,
    authenticationBaseUrl: props.app?.authenticationBaseUrl,
    definitionId: props.app?.id,
    ...props.selectedConnection,
  };

  const handleSave = (values, callback) => {
    const newValues = deepOmit(values, "__typename");
    saveIntegrationAuthentication({
      variables: {
        ...newValues,
        id: newValues.id ? newValues.id : null,
        definitionId: newValues.definitionId,
        name: `My ${newValues.name}`,
      },
    }).then(
      (response) => {
        const auth =
          response.data?.integrationAuthentication?.integrationAuthentication;

        if (response.data?.integrationAuthentication.errors) {
          addToast(
            <Flex>{response.data?.integrationAuthentication.errors[0]}</Flex>,
            ErrorParams
          );
          return null;
        } else {
          setAppAuth(auth);
          if (callback) {
            callback(auth);
          } else {
            props.onUpdate(auth);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={apiData}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {}}
      >
        {(formProps) => {
          const {
            values,
            setFieldValue,
            handleBlur,
            handleSubmit,
            dirty,
            errors,
            loading,
          } = formProps;

          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex flexGrow={1} flexDirection="column">
                <CustomFormGroup
                  placeholder="Name"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  helperText={errors && errors.name && errors.name}
                />
                {values.authenticationBaseUrl?.includes("{{subdomain}}") && (
                  <CustomFormGroup
                    placeholder="Subdomain"
                    label="Subdomain"
                    name="settings.subdomain"
                    value={values.settings?.subdomain}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    helperText={
                      errors &&
                      errors.settings &&
                      errors.settings.subdomain &&
                      errors.settings.subdomain
                    }
                  />
                )}
                <AuthenticationForm
                  {...formProps}
                  handleSave={handleSave}
                  onComplete={(appAuth) => {
                    props.onUpdate(appAuth);
                  }}
                />
              </Flex>
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default IntegrationAuthForm;
