import React, { useEffect, useState } from "react";
import { Flex, Text, Box } from "rebass";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { MODEL_TYPES } from "consts";
import CommandCenter from "Components/Dashboard/CommandCenter";
import Filter from "Components/Filter";
import TicketFilter, { ticketOptionTypes } from "./TicketFilter";
import DatePicker, { defaultDate, getLabel } from "../DatePicker";
import TicketList from "./TicketList";
import TicketStats from "./TicketStats";
import moment from "moment";
import { getSearchFilter } from "../../../hooks/getSearchFilter";
import TicketsQuery from "../../../graphql/queries/Tickets.gql";

function Tickets(props) {
  const { data: searchFilters } = getSearchFilter();
  const [forceRefresh, setForceRefresh] = useState();
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(undefined);
  const [defaultCalendarView, setDefaultCalendarView] = useState("week");
  const [ticketType, setTicketType] = useState({
    type: "date",
    startDate: defaultDate?.startDate,
    endDate: defaultDate?.endDate,
  });

  const startOffset = moment(ticketType.startDate).diff(
    moment({ hours: 0 }),
    "days"
  );
  const endOffset = moment(ticketType.endDate).diff(moment(), "days");

  const [ticketOptions, setTicketOptions] = useState(
    ticketOptionTypes.map((o) => o.value)
  );

  const ticketsQuery = useQuery(TicketsQuery, {
    variables: {
      ticketOptions,
      ownerUserId: searchFilters?.filters?.ownerUserId,
      teamId: searchFilters?.filters?.teamId,
      segmentId: searchFilters?.filters?.segmentId,
      startDays: startOffset || -30,
      endDays: endOffset || 0,
    },
  });
  const { data: tickets, loading } = ticketsQuery;

  useEffect(() => {
    ticketsQuery.refetch({
      ticketOptions,
      startDays: startOffset || 0,
      endDays: endOffset || 0,
      ownerUserId: searchFilters?.filters?.ownerUserId,
      segmentId: searchFilters?.filters?.segmentId,
    });
  }, [open, forceRefresh]);

  const getCalendarView = (startDate, endDate) => {
    const label = getLabel(startDate, endDate);
    let view = "week";
    switch (label) {
      case "Today":
      case "Yesterday":
      case "Tomorrow":
        view = "day";
        break;
      case "Last Week":
      case "This Week":
        view = "week";
        break;
      case "Last Month":
      case "This Month":
        view = "month";
        break;
      default:
        view = "week";
        break;
    }
    setDefaultCalendarView(view);
  };

  return (
    <CommandCenter>
      <Filter
        inlineSegments
        menu={<Text variant="h2">Support Tickets</Text>}
        onChange={() => {
          setForceRefresh(new Date());
        }}
        typeName={MODEL_TYPES.Company.name}
      >
        <Flex width="100%" flexDirection="column">
          <Flex flex={1} alignItems="center" justifyContent="space-between">
            <Flex flex={1} flexWrap="flex" justifyContent="flex-end">
              <TicketFilter
                onChange={(types) => setTicketOptions(types)}
                types={ticketOptions}
              />
              <Box mx={1} />
              <DatePicker
                dates={ticketType}
                onChange={(type, startDate, endDate) => {
                  getCalendarView(startDate, endDate);
                  setTicketType({
                    type,
                    startDate: startDate || ticketType.startDate,
                    endDate: endDate || ticketType.endDate,
                  });
                }}
              />
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="column" px={3}>
            <TicketStats data={tickets?.tickets} />
            <Flex flex={1} alignItems="center" justifyContent="space-between">
              <TicketList
                data={tickets?.tickets}
                loading={loading}
                detailedView
                onSelected={(event) => {
                  setEvent(event);
                  setOpen(true);
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Filter>
    </CommandCenter>
  );
}

export default Tickets;
