import React from "react";
import { Flex, Text, Box, Link, Image } from "rebass";
import Table from "Components/Common/Table";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { getEventGroups } from "hooks/getEventGroups";
import LineGraph from "Components/Common/Graphs/LineGraph";
import BarGraph from "Components/Common/Graphs/BarGraph";
import Loader from "Components/Common/Loader";
import TableEmpty from "Components/Data/TableEmpty";
import { getEventsByEntity } from "hooks/getEventsByEntity";
import { convertNumber } from "consts";
import { getEventCategories } from "../../../hooks/getEventCategories";

const _ = require("lodash");

function Event(props) {
  const history = useHistory();
  const { loading, data } = getEventCategories();

  const eventDashboard = getEventsByEntity(props.id, props.parentType);
  const columns = [
    {
      name: "Event",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => {
        return (
          <RouterLink
            to={`/adoption/events/${row.key}?i=${props.id}&t=${props.parentType}`}
            component={Link}
          >
            {row.name}
          </RouterLink>
        );
      },
    },
    {
      name: "Event Count",
      selector: (row) => row.count,
      sortable: true,
      cell: (row) => convertNumber(row.count, 0),
    },
    {
      name: "Events Avg/Day",
      selector: (row) => row.avg,
      sortable: true,
      cell: (row) => convertNumber(row.avg),
    },
    {
      name: "User Count",
      selector: (row) => row.userCount,
      sortable: true,
      cell: (row) => convertNumber(row.userCount, 0),
    },
    {
      name: "User Avg/Day",
      selector: (row) => row.userAvg,
      sortable: true,
      cell: (row) => convertNumber(row.userAvg),
    },
  ];

  const eventSummary = eventDashboard?.data?.eventsByEntity?.eventSummary;

  const timeSpentCategory = data?.eventCategories.find(
    (c) => c.label === "time-spent"
  );
  const timeSpentGraphData = data?.eventCategories
    .filter((c) => c.id === timeSpentCategory?.id)
    .map((ev, i) => {
      let d0 = {
        name: ev.label,
        data: eventSummary?.map((d) => {
          const x = d.eventGroups?.find((g) => g.value === ev.id);
          return {
            date: d.date,
            count: x ? x.count : 0,
          };
        }),
      };
      return d0.data && d0.data?.filter((d1) => d1.count > 0).length > 0
        ? d0
        : undefined;
    })
    .filter((d0) => !!d0);

  const graphData = data?.eventCategories
    .filter((c) => c.id !== timeSpentCategory?.id)
    .map((ev, i) => {
      let d0 = {
        name: ev.label,
        data: eventSummary?.map((d) => {
          const x = d.eventGroups?.find((g) => g.value === ev.id);
          return {
            date: d.date,
            count: x ? x.count : 0,
          };
        }),
      };
      return d0.data && d0.data?.filter((d1) => d1.count > 0).length > 0
        ? d0
        : undefined;
    })
    .filter((d0) => !!d0);

  const flattened = _.flatten(
    eventSummary?.map((d) => {
      return d.eventGroups
        .filter((c) => c.value !== timeSpentCategory?.id) // remove time spent
        .map((dd) => {
          return {
            name: data?.eventCategories.find((ev) => ev.id === dd.value)?.label,
            count: dd.count,
          };
        });
    })
  );

  const kpiAggregate =
    data &&
    _(flattened)
      .groupBy("name")
      .map((objs, key) => ({
        name: key,
        count: _.sumBy(objs, "count"),
      }))
      .value();

  const summaryData = eventDashboard.data?.eventsByEntity?.summary.filter(
    (d) => d.name !== "time-spent"
  );
  return (
    <Flex flex={1} flexDirection="column">
      <Flex justifyContent="space-between">
        <Text variant="h2">Recent Events</Text>
        <Text variant="muted">Past 90 days</Text>
      </Flex>

      {(loading || eventDashboard.loading) && <Loader />}
      {!(loading || eventDashboard.loading) && eventSummary?.length === 0 ? (
        <Flex
          p={4}
          flexDirection="column"
          bg="gray.100"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: "medium" }}
        >
          <Flex
            sx={{
              boxShadow: "large",
              borderRadius: "medium",
              maxWidth: "90%",
              height: "450px",
            }}
            bg="white"
          >
            <Image
              src={require("assets/images/EventsEmptyState.png")}
              sx={{ height: "400px", objectFit: "contain" }}
            />
          </Flex>
          <Box my={3} />
          <Text variant="muted">No events have been added yet.</Text>
        </Flex>
      ) : (
        !(loading || eventDashboard.loading) && (
          <>
            <Flex mt={4} flexDirection="column">
              <Text variant="h4">Time in app</Text>
              <LineGraph
                days={90}
                series={_.flatten(timeSpentGraphData)}
                height={120}
                noLegend
              />
            </Flex>
            <Flex mt={4}>
              <Box width={2 / 4}>
                <Text variant="h4">Events trending</Text>
                <LineGraph
                  days={90}
                  series={_.flatten(graphData)}
                  height={200}
                />
              </Box>
              <Box width={2 / 4}>
                <Text variant="h4">Top events</Text>
                <BarGraph series={kpiAggregate} height={200} />
              </Box>
            </Flex>
            <Box my={3} />
            <Text variant="h4">Summary</Text>
            <Table
              title=""
              selectableRows={false}
              columns={columns}
              defaultSortField="count"
              data={summaryData}
              loading={loading}
              onRowClicked={(row) =>
                history.push(`/adoption/events/${row.key}`)
              }
              noDataText={
                <TableEmpty help="No data returned, check selected filters." />
              }
            />
          </>
        )
      )}
    </Flex>
  );
}

export default Event;
