import React, { useState } from "react";
import UsersQuery from "../../../../graphql/queries/Users.gql";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { useTheme } from "emotion-theming";
import { useQuery } from "@apollo/react-hooks";
import { Flex, Text } from "rebass";
import Avatar from "react-avatar";
import { Formik } from "formik";
import * as Yup from "yup";
import WithOnValidationChange from "../../../Common/Forms/WithOnValidationChange";
function AssignOwnerForm(props) {
  const theme = useTheme();
  const { data: userData, loading: userLoading } = useQuery(UsersQuery);
  const { field, setFieldValue, fieldName, handleBlur } = props;
  const defaultFieldName =
    !fieldName || fieldName === "" ? `config` : `${fieldName}.config`;

  const userOptions = userData?.users?.map((c) => {
    return {
      value: c.id,
      title: c.name,
      label: (
        <Flex alignItems="center">
          <Avatar
            size={25}
            round
            name={c.name}
            src={c.avatarUrl}
            email={c.email}
          />
          <Text mx={2}>{c.name}</Text>
        </Flex>
      ),
    };
  });
  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={field?.config}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        ownerUserId: Yup.string().required("Required"),
      })}
    >
      {(formProps) => {
        const { errors } = formProps;
        return (
          <>
            <WithOnValidationChange
              errors={errors}
              values={formProps.values}
              onError={() => props.onError && props.onError(errors)}
              onValid={() => props.onValid && props.onValid()}
            />
            <CustomFormGroup
              type="list"
              placeholder="Owner"
              label="Owner"
              name={`${defaultFieldName}.ownerUserId`}
              value={field.config?.ownerUserId}
              onBlur={handleBlur}
              onChange={(name, value) => {
                setFieldValue(`${defaultFieldName}.title`, value?.title);
                setFieldValue(name, value?.value);
              }}
              options={userOptions}
              helperText={errors.ownerUserId && errors.ownerUserId}
            />
          </>
        );
      }}
    </Formik>
  );
}

export default AssignOwnerForm;
