import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Button, Flex, Text, Box, Image } from "rebass";
import * as Yup from "yup";
import SurveyQuery from "../../../../graphql/queries/Survey.gql";
import SurveysQuery from "../../../../graphql/queries/Surveys.gql";
import SurveyResponseMutation from "../../../../graphql/mutations/SurveyResponse.gql";
import CustomFormGroup from "../../../Common/Forms/CustomFormGroup";
import { ErrorParams } from "../../../Common/Notifications/Error";
import { SuccessParams } from "../../../Common/Notifications/Success";
import SurveyButtons from "./SurveyButtons";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import Loader from "../../../Common/Loader";
import { useEffectOnce } from "react-use";
import { SurveyWidget } from "./SurveyWidget";
import { getProduct } from "hooks/getProduct";

export const WebDeliveryType = 0;
export const EmailDeliveryType = 1;

function SurveyResponseForm(props) {
  const { addToast } = useToasts();
  const { id } = useParams();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const rid = params.get("id");
  const s = params.get("s");
  const [requestId, setRequestId] = useState(rid);
  const [request, setRequest] = useState({
    id: requestId,
    responses: [],
    surveyQuestions: [],
  });
  const [save, {}] = useMutation(SurveyResponseMutation);
  const { data: currentProduct } = getProduct();

  const { data, loading } = useQuery(SurveyQuery, {
    variables: { id: id },
    skip: !id,
  });

  let survey = data?.survey;
  useEffect(() => {
    if (survey?.surveyQuestions?.length > 0) {
      let question = survey?.surveyQuestions?.find(
        (q) => (q.primary || q.orderSeq == 0) && !q.isArchived
      );

      if (question) {
        question.score = Number(s);
      }

      let r = {
        id: requestId,
        surveyQuestions: survey.surveyQuestions.filter((q) => !q.isArchived),
        responses: survey.surveyQuestions
          .filter((q) => !q.isArchived)
          .map((q) => {
            return {
              id: q.id,
              surveyQuestionId: undefined,
              score: q.score,
              responseText: q.responseText,
            };
          }),
      };
      saveResponse(r);
      setRequest(r);
    }
  }, survey);

  const saveResponse = (values) => {
    save({
      variables: {
        id: requestId,
        responses: values.responses.map((q) => {
          return {
            id: q.surveyResponseId,
            surveyQuestionId: q.id,
            score: q.score,
            responseText: q.responseText,
          };
        }),
      },
    }).then(
      (response) => {
        if (response.data?.surveyResponse?.errors) {
          addToast(
            <Flex>{response.data?.surveyResponse?.errors[0]}</Flex>,
            ErrorParams
          );
        } else {
          const surveyRequest = response.data?.surveyResponse?.surveyRequest;
          survey.surveyQuestions.map((a) => {
            a.score = surveyRequest.surveyResponses.find(
              (b) => a.id == b.surveyQuestionId
            )?.score;
          });
          setRequest({ ...survey, ...surveyRequest });
          setRequestId(surveyRequest?.id);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return loading ? (
    <Loader />
  ) : (
    <Flex
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      pt={4}
    >
      <Formik
        enableReinitialize
        initialValues={request}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          saveResponse(values);
        }}
      >
        {(formProps) => {
          const { setFieldValue, handleSubmit, values } = formProps;
          console.log(values);
          return (
            <SurveyWidget
              data={values}
              product={currentProduct?.currentProduct}
              onSubmit={(questions) => {
                saveResponse({
                  ...values,
                  responses: questions.map((q) => {
                    return {
                      id: q.id,
                      score: q.score,
                      responseText: q.responseText,
                    };
                  }),
                });
              }}
            />
          );
        }}
      </Formik>
    </Flex>
  );
}

export default SurveyResponseForm;
