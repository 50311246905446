import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import EntityScoresQuery from "../graphql/queries/EntityScores.gql";

export function getEntityScores(id, parentType, scoreType, days) {
  const query = useQuery(EntityScoresQuery, {
    variables: {
      id: id,
      parentType: parentType,
      scoreType: scoreType,
      days: days,
    },
  });

  return {
    ...query,
  };
}
