import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Flex, Text, Box } from "rebass";
import { FieldArray, Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import { useDynamicSchema } from "hooks/DynamicSchema";
import Loader from "Components/Common/Loader";
import { MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import { SuccessParams } from "Components/Common/Notifications/Success";
import { ErrorParams } from "Components/Common/Notifications/Error";
import Tags from "Components/Common/Tags";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import DynamicForm from "../DynamicForm";
import Summary from "../Events/Summary";
import CompanyQuery from "../../../graphql/queries/CompanyAddress.gql";
import { useDynamicGql } from "../../../graphql/DynamicGql";
import TagsEditor from "../../Common/Tags/TagsEditor";
import CompanyProductList from "./CompanyProductList";
import moment from "moment";
import Label from "../../Common/Forms/Label";
import CustomFormGroup from "../../Common/Forms/CustomFormGroup";
import LocalisationForm from "../../MyAccount/Settings/Localisation";

const parentType = MODEL_TYPES.Company.name;

function Company(props) {
  const { id } = props;
  const history = useHistory();
  const { addToast } = useToasts();
  const [readOnly, setReadOnly] = useState(!props.open);
  const [expanded, setExpanded] = useState(true);
  const [file, setFile] = useState(undefined);
  const { mutation } = useDynamicGql(MODEL_TYPES.Company, false);
  const [saveCompany, { error: saveError }] = useMutation(mutation);
  const { loading, data } = getData(MODEL_TYPES.Company, id);

  const { initialValues, validations } = useDynamicSchema(MODEL_TYPES.Company);
  const addressQuery = useQuery(CompanyQuery, {
    variables: { id: id },
    skip: !id || id === "new",
  });
  const company = data &&
    addressQuery.data && {
      details: { startDate: undefined, renewalDate: undefined },
      ...data.company,
      addresses: addressQuery.data?.companyAddresses,
    };

  if (addressQuery?.loading || loading || !validations) {
    return <Loader />;
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          company || {
            ...initialValues,
            details: { startDate: undefined, renewalDate: undefined },
          }
        }
        autoComplete={false}
        onSubmit={(values, actions) => {
          saveCompany({
            variables: {
              id: values.id,
              fields: values.fields || {},
              startDate: values.details?.startDate,
              renewalDate: values.details?.renewalDate,
              churnReasonId: values.details?.churnReasonId,
              churnReasonUpdatedAt: values.details?.churnReasonUpdatedAt,
              arr: values.details?.arr?.toString(),
              mrr: values.details?.mrr?.toString(),
              name: values.name,
              ownerUserId: values.details?.owner?.id,
              lifeCycleStageId: values.details?.lifeCycleStageId,
              conversionAdoptionScore:
                values.details?.conversionAdoptionScore?.toString(),
              conversionFitScore:
                values.details?.conversionFitScore?.toString(),
              file: file && file,
              tagList: values.tagList,
              isParent: values.isParent,
              timezone: values.timezone,
              locale: values.locale,
            },
            context: {
              hasUpload: !!file,
            },
          }).then(
            (response) => {
              const result = response.data.company;
              if (result?.errors) {
                actions.setErrors([result?.errors]);
                addToast(<Flex>{result?.errors[0]}</Flex>, ErrorParams);
              } else {
                props.onSave && props.onSave(result.company.id);
                addToast(<Flex>Saved!</Flex>, SuccessParams);
                if (props.modalAdd) {
                  history.push(`/contacts/accounts/${result.company.id}`);
                  setReadOnly(true);
                }
              }
            },
            (error) => {
              addToast(<Flex>Error occured saving</Flex>, ErrorParams);
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, errors, handleSubmit, setFieldValue } = formProps;

          return (
            <Box
              className={!props.readOnly && "profile"}
              width="100%"
              sx={{ height: props.modalAdd ? 650 : "auto" }}
            >
              {!props.readOnly && (
                <Flex justifyContent="space-between">
                  <Flex>
                    <Text variant="h2">Profile</Text>
                  </Flex>
                  <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
                </Flex>
              )}
              <Box mt={3} />
              {!!values.isParent ? (
                <>
                  <CustomFormGroup
                    name="name"
                    label="name"
                    value={values.name}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                  />
                </>
              ) : (
                <DynamicForm
                  readOnly={props.readOnly}
                  expanded={expanded}
                  detailView={!props.summary}
                  typeName={MODEL_TYPES.Company.name}
                  {...formProps}
                />
              )}
              <LocalisationForm {...formProps} />
              <Box my={3}></Box>
              {!props.summary && (
                <FieldArray
                  name="addresses"
                  render={(arrayHelpers) => (
                    <Flex my={2} flexDirection="column" width="100%">
                      {values.addresses && values.addresses.length > 0 && (
                        <Flex width="100%" justifyContent="space-between">
                          <Flex>
                            <Text variant="h4">Addresses</Text>
                          </Flex>
                        </Flex>
                      )}
                      <Box>
                        {values.addresses &&
                          values.addresses.map((child, index) => {
                            let address = [];
                            if (child.street) {
                              address.push(child.street);
                            }
                            if (child.street2) {
                              address.push(child.street2);
                            }
                            if (child.city) {
                              address.push(child.city);
                            }
                            if (child.state) {
                              address.push(child.state);
                            }
                            if (child.postalCode) {
                              address.push(child.postalCode);
                            }
                            if (child.country) {
                              address.push(child.country);
                            }
                            return (
                              <Flex
                                key={index}
                                my={3}
                                alignItems="center"
                                width="100%"
                              >
                                <Flex alignItems="center">
                                  <Text mx={2}>{address.join(", ")}</Text>
                                </Flex>
                              </Flex>
                            );
                          })}
                      </Box>
                    </Flex>
                  )}
                />
              )}
              {props.summary && values?.details?.churnReason && (
                <Box mb={2}>
                  <Label>Churn Reason</Label>
                  <Text my={1} mt={2}>
                    {values?.details?.churnReason?.name}
                  </Text>
                  <Text variant="muted">
                    {moment(values?.details?.churnReasonUpdatedAt).format(
                      "DD MMM YYYY"
                    )}
                  </Text>
                </Box>
              )}
              {props.summary && (
                <>
                  <Flex flexDirection="column" mt={3}>
                    <Summary id={id} parentType={parentType} />
                  </Flex>
                  <Box mt={3} />
                  <TagsEditor
                    parentType="Company"
                    label="Tags"
                    value={values.tagList}
                    onChange={(tags) => {
                      setFieldValue("tagList", tags);
                      handleSubmit();
                    }}
                  />
                  <Flex flexDirection="column" mt={2}>
                    <Tags
                      id={id}
                      parentType={parentType}
                      label="Tags"
                      value={values.segmentList}
                    />
                  </Flex>
                  <Flex flexDirection="column" mt={2}>
                    <CompanyProductList products={values?.products} />
                  </Flex>
                </>
              )}
            </Box>
          );
        }}
      </Formik>
    </>
  );
}
Company.propTypes = {
  summary: PropTypes.bool,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  modalAdd: PropTypes.bool,
  open: PropTypes.bool,
  onSave: PropTypes.func,
};
export default Company;
