import React, { useState } from "react";
import { YAxis, AreaSeries, FlexibleWidthXYPlot, XAxis } from "react-vis";
import { useTheme } from "emotion-theming";
import moment from "moment";
import { curveBasis } from "d3-shape";
import { Box } from "rebass";

const _ = require("lodash");

export default function DashboardGraph(props) {
  const theme = useTheme();

  const newData = props.series
    ?.find((s) => s.name === "new")
    ?.data.map((e, idx) => {
      return { y: e.count, x: idx + 1, name: e.name, date: e.date };
    });

  const existingData = props.series
    ?.find((s) => s.name === "existing")
    ?.data.map((e, idx) => {
      return { y: e.count, x: idx + 1, name: e.name, date: e.date };
    });

  return (
    props.series &&
    props.series.length > 0 && (
      <Box>
        <FlexibleWidthXYPlot height={props.height || 250} stackBy="y">
          <YAxis />
          <XAxis
            tickFormat={(v, i) => {
              let date =
                i % 2 == 0 &&
                v < existingData.length &&
                moment(existingData[v].date);
              return date && moment(date).format("MMM");
            }}
          />
          <AreaSeries
            data={existingData}
            className="first-series"
            animation={{ damping: 9, stiffness: 300 }}
            color={theme.colors.brandLight}
            strokeWidth={0}
            curve={curveBasis}
          />

          <AreaSeries
            data={newData}
            className="first-series"
            animation={{ damping: 9, stiffness: 300 }}
            color={theme.colors.brandHighlight}
            strokeWidth={0}
            curve={curveBasis}
          />
        </FlexibleWidthXYPlot>
      </Box>
    )
  );
}
