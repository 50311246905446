import React from "react";
import { Flex } from "rebass";
export const ErrorParams = {
  appearance: "warning",
  autoDismiss: false,
};
const ShowError = ({ message, detail }) => {
  return <Flex alignItems="center">{message}</Flex>;
};

export default ShowError;
