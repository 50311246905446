import { useEffect, useState } from "react";

import ReactGA from "react-ga4";

const gaTracking = () => {
  const [initialized, setInitialized] = useState(false);

  if (window.UserlotConfig?.googleApiKey) {
    useEffect(() => {
      ReactGA.initialize(window.UserlotConfig.googleApiKey);
      setInitialized(true);
    }, []);

    useEffect(() => {
      if (initialized) {
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname + location.search,
        });
      }
    }, [initialized]);
  }
};

export default gaTracking;
