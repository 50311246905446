import moment from "moment";
import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Box, Flex, Image, Text } from "rebass";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import CommentMutation from "../../../graphql/mutations/Comment.gql";
import { useMutation } from "@apollo/client";
import UserBadge from "Components/Common/UserBadge";

function TaskComments(props) {
  const [text, setText] = useState();
  const [save] = useMutation(CommentMutation);
  const errors = "";

  const showAddOnly = props.showAddOnly || false;
  const showLastNote = props.showLastNote || false;

  const saveComment = () => {
    save({
      variables: {
        id: props.values?.id,
        parentType: "Task",
        comment: text,
      },
    }).then(
      (response) => {
        setText("");
        props.onSave && props.onSave();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  if (!props.values?.id) {
    return null;
  }
  return (
    <Flex flexDirection="column" flexGrow={1}>
      {!showLastNote && props.values?.id && (
        <Flex flexDirection="column">
          <CustomFormGroup
            placeholder="Notes"
            label="Notes"
            type="textarea"
            name="note"
            value={text}
            onChange={(name, value) => setText(value)}
            noMargin
            helperText={errors && errors}
          />
          <Flex mt={1} justifyContent="flex-end">
            <PrimaryButton onClick={() => saveComment()}>
              Add Note
            </PrimaryButton>
          </Flex>
        </Flex>
      )}
      {!showAddOnly && (
        <Box>
          <Text variant="h3" mt={3} sx={{ width: 300 }}>
            Notes
          </Text>
          <Flex
            flex={1}
            mt={2}
            width="100%"
            flexDirection={"column"}
            sx={{ maxHeight: 400, overflowY: "auto" }}
          >
            {(!props.values?.comments ||
              props.values?.comments?.length == 0) && (
              <Flex>
                <Text variant="muted">No comments added </Text>
              </Flex>
            )}
            {props.values?.comments?.map((comment) => (
              <Comment key={comment.id} comment={comment} />
            ))}
          </Flex>
        </Box>
      )}
    </Flex>
  );
}

const Comment = ({ comment }) => {
  return (
    <Flex key={comment.id} p={2} my={2} bg="white" flexDirection={"column"}>
      <Flex justifyContent={"space-between"} alignItems="center">
        <Text variant="muted" fontSize={"xxs"}>
          <UserBadge
            height={30}
            name={comment.user?.name}
            email={comment.user?.email}
            avatarUrl={comment.user?.avatarUrl}
          />
        </Text>
        <Text variant="muted" fontSize={"xxs"}>
          {moment(comment.createdAt).format("DD MMM YYYY hh:mm a")}
        </Text>
      </Flex>
      <Text
        my={2}
        ml={4}
        fontSize="xs"
        dangerouslySetInnerHTML={{ __html: comment.comment }}
      ></Text>
    </Flex>
  );
};
export default withRouter(TaskComments);
