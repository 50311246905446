import React, { useState } from "react";
import { Flex, Button, Text, Box } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import UserMutation from "../../../../graphql/mutations/UserResetPassword.gql";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import Modal from "Components/Common/Modal";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import SecondaryButton from "Components/Common/Buttons/SecondaryButton";

function PasswordForm(props) {
  const [successReset, setSuccessReset] = useState();
  const [resetPassword, {}] = useMutation(UserMutation);
  const [readOnly, setReadOnly] = useState(true);
  return (
    <Formik
      enableReinitialize
      initialValues={props.data || {}}
      autoComplete={false}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Required"),
        currentPassword: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        resetPassword({
          variables: {
            password: values.password,
            currentPassword: values.currentPassword,
          },
        }).then(
          (response) => {
            if (response.data?.user?.errors) {
              console.log(response.data.user.errors);
            } else {
              props.onSave();
              setSuccessReset(true);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }}
    >
      {(formProps) => {
        const { values, errors, setFieldValue, handleBlur, handleSubmit } =
          formProps;
        return (
          <>
            <Modal
              width={900}
              open={props.open}
              onDismiss={() => {
                props.onDismiss();
              }}
            >
              <Flex flexDirection="column" px={3} pb={4}>
                <Flex w="100%" flexDirection="column" my={2}>
                  <Flex flex={1} flexDirection="column" m={2}>
                    {successReset && (
                      <Flex variant="successBox" p={2} mb={2}>
                        Successfully reset password
                      </Flex>
                    )}

                    <Text variant="h3">Change Password</Text>
                    <CustomFormGroup
                      placeholder="Current Password"
                      label="Current Password"
                      type="password"
                      name="currentPassword"
                      value={values.currentPassword}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.currentPassword && errors.currentPassword
                      }
                    />
                    <Box variant="divider" />
                    <CustomFormGroup
                      placeholder="Password"
                      label="Password"
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                      helperText={errors.password && errors.password}
                    />
                    <CustomFormGroup
                      placeholder="Re-enter Password"
                      label="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.confirmPassword && errors.confirmPassword
                      }
                    />
                    <Flex justifyContent="space-between" mt={2}>
                      <SecondaryButton onClick={() => props.onDismiss()}>
                        Cancel
                      </SecondaryButton>
                      {!readOnly && (
                        <PrimaryButton onClick={handleSubmit}>
                          Save
                        </PrimaryButton>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}

export default PasswordForm;
