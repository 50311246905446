import React, { useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import "react-vertical-timeline-component/style.min.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "assets/styles/react-tabs.css";
import Attachments from "../Attachments";
import EndUsers from "../EndUsers";
import { MODEL_TYPES } from "consts";
import { getData } from "hooks/getData";
import Activity from "../../Activity";
import CompanyForm from "./CompanyForm";
import CompanyLogo from "./CompanyLogo";
import Label from "../../Common/Forms/Label";
import GhostButton from "../../Common/Buttons/GhostButton";
import { OwnerChangeIcon } from "../../Common/Icons";
import CompanyMenuModal from "../../Dashboard/CompanyHealth/CompanyMenuModal";
import CompanyRevenueEntries from "./CompanyRevenueEntries";
import UserBadge from "../../Common/UserBadge";
import PropTypes from "prop-types";
import BccAddress from "../BccAddress";
import { useHistory } from "react-router-dom";
import {
  Ri4KLine,
  RiAttachmentLine,
  RiGitMergeLine,
  RiGlobalLine,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import CompanyDomains from "./CompanyDomains";
import { useQuery } from "@apollo/client";
import HealthByEntity from "../../../graphql/queries/HealthByEntity.gql";
import numeral from "numeral";
import moment from "moment";
import CompanyProductList from "./CompanyProductList";
import Tags from "../../Common/Tags";
import TagsEditor from "../../Common/Tags/TagsEditor";
import ExternalIds from "../ExternalIds";
import CompanyHierarchy from "./CompanyHierarchy";

function CompanyParentDetail(props) {
  const { id, parentType } = props;
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(moment());
  const dataQuery = getData(MODEL_TYPES[parentType], id);
  const { data: healthData } = useQuery(HealthByEntity, {
    variables: {
      id,
      parentType,
      days: 30,
    },
  });

  const data =
    dataQuery?.data && dataQuery.data[MODEL_TYPES[parentType].singular];

  const details = data?.details;
  return (
    <Flex flex={1} w="100%" flexDirection="column">
      <Flex flex={1} bg="white" flexDirection="row" justifyContent="center">
        <Flex
          flexDirection="column"
          sx={{
            borderRightColor: "gray.200",
            borderRightStyle: "solid",
            borderRightWidth: 1,
            maxWidth: "320px",
          }}
          my={2}
        >
          <Box>
            <Box>
              <Box mt={2}>
                <GhostButton onClick={() => setOpenModal("Owner")}>
                  {details?.owner ? (
                    <UserBadge
                      name={details.owner.name}
                      email={details.owner.email}
                      subtitle="Owner"
                      avatarUrl={details.owner.avatarUrl}
                    />
                  ) : (
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      fontSize="xs"
                    >
                      <Flex
                        bg="accent"
                        justifyContent="center"
                        alignItems="flex-end"
                        sx={{ borderRadius: 999, width: 25, height: 25 }}
                      >
                        <OwnerChangeIcon />
                      </Flex>
                      <Text mx={2} fontWeight="bold">
                        Assign Owner
                      </Text>
                    </Flex>
                  )}
                </GhostButton>
              </Box>
              {details?.owner && (
                <Box>
                  <GhostButton onClick={() => setOpenModal("ShadowOwner")}>
                    {details?.shadowOwner ? (
                      <UserBadge
                        name={details.shadowOwner.name}
                        email={details.shadowOwner.email}
                        subtitle="Shadow Owner"
                        avatarUrl={details.shadowOwner.avatarUrl}
                      />
                    ) : (
                      <Flex
                        alignItems="center"
                        justifyContent="flex-start"
                        fontSize="xs"
                      >
                        <Flex
                          bg="accent"
                          justifyContent="center"
                          alignItems="flex-end"
                          sx={{ borderRadius: 999, width: 25, height: 25 }}
                        >
                          <OwnerChangeIcon />
                        </Flex>
                        <Text mx={2} fontWeight="bold">
                          Assign Shadow Owner
                        </Text>
                      </Flex>
                    )}
                  </GhostButton>
                </Box>
              )}
            </Box>

            <Box my={3} mr={2} bg="gray.100" p={3}>
              <Flex alignItems={"center"} flex={1}>
                <Box width={1 / 2}>
                  <Label>ARR</Label>
                  <Text>${numeral(details?.arr).format(`0,0.00`)}</Text>
                </Box>
                <Box width={1 / 2}>
                  <Label>MRR</Label>
                  <Text>${numeral(details?.mrr).format(`0,0.00`)}</Text>
                </Box>
              </Flex>
            </Box>
            <>
              {details?.churnReason && (
                <Box mb={2}>
                  <Label>Churn Reason</Label>
                  <Text my={1} mt={2}>
                    {details?.churnReason?.name}
                  </Text>
                  <Text variant="muted">
                    {moment(details?.churnReasonUpdatedAt).format(
                      "DD MMM YYYY"
                    )}
                  </Text>
                </Box>
              )}
              <BccAddress address={data?.bccAddress} />
              <Box mt={3} />
              <TagsEditor
                id={id}
                parentType="Company"
                label="Tags"
                value={data?.tagList}
              />
              <Flex flexDirection="column" mt={3}>
                <Tags
                  id={id}
                  parentType={parentType}
                  label="Segments"
                  value={data?.segmentList}
                />
              </Flex>
              <Flex flexDirection="column">
                <CompanyProductList products={data?.products} />
              </Flex>
            </>
          </Box>

          <CompanyMenuModal
            moduleType={details?.status?.name}
            selectedRows={[{ ...data }]}
            parentType={props.parentType}
            open={!!openModal}
            formType={openModal ? openModal : undefined}
            onSave={() => {
              setOpenModal(undefined);
              setRefresh(moment());
              dataQuery.refetch({ variables: { id, parentType } });
            }}
            onDismiss={() => {
              setOpenModal(undefined);
            }}
          />
        </Flex>
        <Flex flex={1} bg="white">
          <Tabs
            selectedIndex={props.selected}
            onSelect={props.onSelect}
            className="primary-tabs"
          >
            <TabPanel key="Summary">
              <Flex
                className="SummaryDetail"
                flex={1}
                px={4}
                py={3}
                g="white"
                flexDirection="column"
              >
                <Activity
                  id={id}
                  parentType={parentType}
                  actionableName={
                    dataQuery?.data &&
                    (dataQuery?.data[MODEL_TYPES[parentType].name]?.name ||
                      `${
                        dataQuery?.data[MODEL_TYPES[parentType]?.name]
                          ?.firstName
                      } ${
                        dataQuery?.data[MODEL_TYPES[parentType]?.name]?.lastName
                      }`)
                  }
                />
              </Flex>
            </TabPanel>
            <TabPanel key="Hierarchy">
              <Flex
                className="HealthDetail"
                flex={1}
                px={4}
                py={3}
                g="white"
                flexDirection="column"
              >
                <CompanyHierarchy id={id} parentType={parentType} />
              </Flex>
            </TabPanel>
            <TabPanel key="Profile">
              <Flex flex={1} bg="white" px={4} py={3} flexDirection="column">
                <Flex flex={1} className="profile">
                  <Flex pr={4} flex={1}>
                    <Flex flexDirection="column" width="100%">
                      <Flex flex={1}>
                        <Flex width={1 / 2} flexDirection="column" p={2}>
                          <CompanyForm
                            id={id}
                            typeName={parentType}
                            readOnly={false}
                          />
                          <Text variant="h3" mb={2}>
                            Logo
                          </Text>
                          <CompanyLogo id={id} />
                        </Flex>
                        <Flex width={1 / 2} pl={2}>
                          <Tabs className="primary-tabs">
                            <TabList>
                              <Tab>
                                <Flex
                                  flexDirection={"column"}
                                  justifyContent="center"
                                  alignItems={"center"}
                                >
                                  <RiAttachmentLine />
                                  <Text fontSize={"xs"} mx={2}>
                                    Attach
                                  </Text>
                                </Flex>
                              </Tab>
                            </TabList>

                            <TabPanel>
                              <Text variant="h3" mt={3}>
                                Attach
                              </Text>
                              <Attachments id={id} parentType={parentType} />
                            </TabPanel>
                          </Tabs>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </TabPanel>
            <TabPanel key="Users">
              <Flex className="UserDetail" bg="white" px={4} py={3}>
                <EndUsers id={id} />
              </Flex>
            </TabPanel>
          </Tabs>
        </Flex>
      </Flex>
    </Flex>
  );
}

CompanyParentDetail.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
  selected: PropTypes.number,
  onSelect: PropTypes.func,
};
export default CompanyParentDetail;
