import React, { useCallback, useEffect, useState } from "react";
import Page from "Components/Page";
import { Flex, Text } from "rebass";
import CompaniesList from "Components/Data/Company/CompaniesContacts";
import { getAllData } from "hooks/getAllData";
import { MODEL_TYPES } from "consts";
import Filter from "Components/Filter";
import { getSearchFilter } from "hooks/getSearchFilter";
import { useMutation } from "@apollo/react-hooks";
import ContactsTopbar from "./ContactsTopbar";
import SearchFilterMutation from "../../../graphql/mutations/SearchFilter";
import { useUrlParams } from "../../../hooks/useUrlParams";
import PropTypes from "prop-types";

const pageTitle = "Accounts";
function CompanyContacts(props) {
  const [triggerRefresh, setTriggerRefresh] = useState();
  const [saveFilter] = useMutation(SearchFilterMutation);
  const [getUrlParameter, setUrlParameter] = useUrlParams();
  const defaultTags = getUrlParameter("tags");
  const { data: searchFilters } = getSearchFilter();

  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(50);
  const [perPage, setPerPage] = useState(50);
  const [sort, setSort] = useState(undefined);

  const handleSort = async (column, sortDirection) => {
    setSort({
      column: column.id,
      direction: sortDirection,
      schemaName: column.schemaName,
    });
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const filters = {
    filterText: searchFilters?.filters?.contacts?.filterText,
    tags: searchFilters?.filters?.tags,
    segmentId: searchFilters?.filters?.segmentId,
    ownerUserId: searchFilters?.filters?.ownerUserId,
    teamId: searchFilters?.filters?.teamId,
    page: page,
    size: perPage,
    sort: sort,
  };

  const { data, loading, error, refetch } = getAllData(MODEL_TYPES.Company, {
    ...filters,
  });

  useEffect(() => {
    if (!loading && data?.companies.total) {
      setTotalRows(data?.companies.total);
    }
  }, [data]);

  useEffect(() => {
    /* If there is a tags in the url set the state for searching. */
    if (
      searchFilters?.filters &&
      (!searchFilters.filters.tags || searchFilters.filters.tags.length == 0) &&
      defaultTags?.split(",").length > 0
    ) {
      if (defaultTags.split(",").length > 0) {
        updateFilter(
          "tags",
          defaultTags.split(","),
          `Tags (${defaultTags.split(",").length})`
        );
      } else {
        updateFilter("tags", [], undefined);
      }
    }
  }, [searchFilters?.filters]);

  const updateFilter = (name, id, label) => {
    const newFilters = searchFilters?.filters;

    let ref = newFilters;
    let nestedNames = name.split(".").reverse();
    while (nestedNames.length > 1) {
      const n = _.last(nestedNames);
      if (!ref[n]) {
        ref[n] = {};
      }
      ref = ref[n];
      nestedNames = nestedNames.slice(0, -1);
    }
    const n = _.last(nestedNames);
    ref[n] = id;

    if (!newFilters.labels) {
      newFilters.labels = {};
    }
    newFilters.labels[name] = label;

    saveFilter({
      variables: {
        filters: newFilters,
      },
    }).then(
      (response) => {
        props.onChange && props.onChange();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Page title="Accounts" topBar={<ContactsTopbar />}>
      <Filter
        title={pageTitle}
        menu={
          <Flex alignItems="center">
            <Text variant="h2" mr={3}>
              All Accounts
            </Text>
          </Flex>
        }
        typeName={MODEL_TYPES.Company.name}
        includeCoreSegments
        onChange={() =>
          refetch({
            searchFilters: {
              filterText: searchFilters?.filters?.contacts?.filterText,
              tags: searchFilters?.filters?.tags,
              segmentId: searchFilters?.filters?.segmentId,
              ownerUserId: searchFilters?.filters?.ownerUserId,
              page: page,
              size: perPage,
              sort: sort,
            },
          })
        }
      >
        <Flex flexDirection="column" px={4} flex={1}>
          <CompaniesList
            title=""
            data={data?.companies?.companies}
            error={error}
            loading={loading}
            module="contacts"
            onSort={handleSort}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            refetch={() =>
              refetch({
                searchFilters: {
                  filterText: searchFilters?.filters?.contacts?.filterText,
                  tags: searchFilters?.filters?.tags,
                  segmentId: searchFilters?.filters?.segmentId,
                  ownerUserId: searchFilters?.filters?.ownerUserId,
                  page: page,
                  size: perPage,
                  sort: sort,
                },
              })
            }
          />
        </Flex>
      </Filter>
    </Page>
  );
}
CompanyContacts.propTypes = {
  onChange: PropTypes.func,
};
export default CompanyContacts;
