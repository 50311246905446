import React, { useState } from "react";
import { Flex, Button, Text, Box, Link } from "rebass";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { MODEL_TYPES } from "consts";
import { useHistory, useParams, Prompt } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ErrorParams } from "Components/Common/Notifications/Error";
import { SuccessParams } from "Components/Common/Notifications/Success";
import DeleteButton from "Components/MyAccount/Settings/Common/DeleteButton";
import { SaveIcon } from "Components/Common/Icons";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import TagMutation from "../../../../graphql/mutations/Tag.gql";
import TagDeleteMutation from "../../../../graphql/mutations/TagDelete.gql";
import TagQuery from "../../../../graphql/queries/Tag.gql";
import TagsQuery from "../../../../graphql/queries/TagDetails.gql";
import { ProductLogo } from "../../../Common/Icons";

const taggingTypes = [
  { label: "Account", value: "CompanyDetail" },
  { label: "User", value: "EndUser" },
];
function TagForm(props) {
  const { id } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();
  const [save] = useMutation(TagMutation, {
    refetchQueries: [{ query: TagsQuery }],
  });
  const [destroy, {}] = useMutation(TagDeleteMutation, {
    refetchQueries: [{ query: TagsQuery }],
  });
  const { data, loading, error } = useQuery(TagQuery, {
    variables: { id: id },
    skip: !id || id === "new",
  });
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Formik
        enableReinitialize
        initialValues={
          data?.tag || {
            name: "",
          }
        }
        autoComplete={false}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          save({
            variables: {
              id: values.id ? values.id : null,
              name: values.name,
              taggableTypes: values.taggableTypes,
            },
          }).then(
            (response) => {
              if (response.data?.tag?.errors) {
                addToast(
                  <Flex>{response.data?.tag?.errors[0]}</Flex>,
                  ErrorParams
                );
              } else {
                const id = response.data?.tag?.tag?.id;
                window.location.replace(`/settings/account/tags/${id}`);
                addToast(<Flex>Saved!</Flex>, SuccessParams);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }}
      >
        {(formProps) => {
          const {
            values,
            setFieldValue,
            handleBlur,
            handleSubmit,
            errors,
            dirty,
          } = formProps;
          return (
            <>
              <Prompt
                when={dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Flex flexDirection="column">
                <Flex alignItems="center" justifyContent="flex-end">
                  {values.segments?.length == 0 &&
                    values.automations?.length == 0 && (
                      <DeleteButton
                        callback={destroy}
                        redirectUrl={"/settings/account/tags"}
                        id={id}
                        name="Tag"
                      />
                    )}
                  <PrimaryButton leftIcon={<SaveIcon />} onClick={handleSubmit}>
                    Save
                  </PrimaryButton>
                </Flex>
                <CustomFormGroup
                  placeholder="Name"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  helperText={errors && errors.name && errors.name}
                />
                <Text fontSize={"xs"} color="red">
                  Warning: changing a tag name will mean existing segments and
                  automations will no longer match on this name.
                </Text>
                <Flex mt={3}>
                  <Flex>
                    {taggingTypes.map((key) => {
                      return (
                        <Flex key={key.label} alignItems={"center"} m={2}>
                          {/*<Box>
                            <CustomFormGroup
                              type="checkbox"
                              noMargin
                              name="taggableTypes"
                              value={
                                !!values.taggableTypes?.find(
                                  (t) => t === key.value
                                )
                              }
                              readOnly={
                                values.taggings &&
                                values.taggings[key.label] > 0
                              }
                              onChange={(name, value) => {
                                console.log(value);
                                var added = value;
                                let newTypes = values.taggableTypes || [];
                                if (added) {
                                  newTypes.push(key.value);
                                } else {
                                  _.pull(newTypes, key.value);
                                }
                                setFieldValue(name, newTypes);
                              }}
                              onBlur={(name, value) => {
                                console.log(value);
                                var added = value;
                                let newTypes = values.taggableTypes || [];
                                if (added) {
                                  newTypes.push(key.label);
                                } else {
                                  _.pull(newTypes, key.label);
                                }
                                setFieldValue(name, newTypes);
                              }}
                            />
                            </Box>*/}
                          <Flex alignItems={"center"}>
                            <Text mx={2}>{key.label}</Text>
                            <Flex
                              variant="badge"
                              bg="brandLight"
                              color="white"
                              mx={0}
                              alignItems={"center"}
                              justifyContent="center"
                              sx={{ minWidth: 20 }}
                            >
                              {values.taggings
                                ? values.taggings[key.label] || 0
                                : 0}
                            </Flex>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
                <Flex variant="box" flexDirection={"column"} p={3} my={3}>
                  <Text variant="h3">Usage</Text>
                  <Flex flex={1} width="100%">
                    <Flex flexDirection={"column"} flex={1}>
                      <Text mt={3} fontWeight="bold" fontSize={"xs"}>
                        Automations
                      </Text>
                      <Flex flexDirection={"column"}>
                        {values.automations?.length > 0 ? (
                          values.automations?.map((key) => {
                            return (
                              <Flex
                                key={key.label}
                                alignItems={"center"}
                                m={2}
                                flex={1}
                              >
                                <ProductLogo product={key.product} />
                                <Text
                                  mx={2}
                                  fontSize="small"
                                  sx={{ width: "100%" }}
                                >
                                  {key.name}
                                </Text>
                              </Flex>
                            );
                          })
                        ) : (
                          <Text variant="muted" my={2}>
                            Not used by any automations
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                    <Flex flexDirection={"column"} flex={1}>
                      <Text mt={3} fontWeight="bold" fontSize={"xs"}>
                        Segments
                      </Text>
                      <Flex flexDirection={"column"}>
                        {values.segments?.length > 0 ? (
                          values.segments?.map((key) => {
                            return (
                              <Flex
                                key={key.label}
                                alignItems={"center"}
                                m={2}
                                flex={1}
                              >
                                <ProductLogo product={key.product} />
                                <Text
                                  mx={2}
                                  fontSize="small"
                                  sx={{ width: "100%" }}
                                >
                                  {key.name}
                                </Text>
                              </Flex>
                            );
                          })
                        ) : (
                          <Text variant="muted" my={2}>
                            Not used by any segments
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default TagForm;
