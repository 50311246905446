import React, { useState } from "react";
import { Flex, Box, Link, Button, Text } from "rebass";
import UsersQuery from "../../../graphql/queries/Users.gql";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Settings from ".";
import Table from "Components/Common/Table";
import CustomFormGroup from "Components/Common/Forms/CustomFormGroup";
import { PlusIcon } from "Components/Common/Icons";
import Modal from "Components/Common/Modal";
import ShowError, { ErrorParams } from "Components/Common/Notifications/Error";
import ShowSuccess, {
  SuccessParams,
} from "Components/Common/Notifications/Success";
import Select from "Components/Common/Forms/Select";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { Formik, FieldArray } from "formik";
import moment from "moment";
import UserStatusBadge from "./UserStatusBadge";
import Avatar from "react-avatar";
import PrimaryButton from "Components/Common/Buttons/PrimaryButton";
import TeamsQuery from "../../../graphql/queries/Teams.gql";
import RolesQuery from "../../../graphql/queries/Roles.gql";
import UserMutation from "../../../graphql/mutations/User.gql";
import UserTeamMutation from "../../../graphql/mutations/UserTeam.gql";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const columns = [
  {
    name: "",
    selector: (row) => row.email,
    width: "50px",
    cell: (row) => {
      return (
        <Avatar
          src={row?.avatarUrl}
          size="30"
          name={`${row?.firstName} ${row?.lastName}`}
          email={row?.email}
          round
          textSizeRatio={2}
        />
      );
    },
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "First Name",
    selector: (row) => row.firstName,
    sortable: true,
  },
  {
    name: "Last Name",
    selector: (row) => row.lastName,
    sortable: true,
  },
  {
    name: "Last Seen",
    selector: (row) => row.lastSeenAt,
    sortable: true,
    cell: (row) => {
      return row.lastSeenAt && moment(row.lastSeenAt).format("DD MMM YYYY");
    },
  },
  {
    name: "Status",
    selector: (row) => row.invitedAt,
    sortable: true,
    cell: (row) => {
      return <UserStatusBadge user={row} />;
    },
  },
];
function Users(props) {
  const [openInvite, setOpenInvite] = useState(false);
  const history = useHistory();
  const { loading, data, error, refetch } = useQuery(UsersQuery, {});
  return (
    <Settings>
      <Flex flex={1} flexDirection="column" bg="white" p={4}>
        <Flex justifyContent="space-between">
          <Text variant="h2">Users</Text>
          <PrimaryButton onClick={() => setOpenInvite(true)}>
            <PlusIcon style={{ marginLeft: "-3px", marginTop: "3px" }} />
          </PrimaryButton>
        </Flex>
        <Table
          title=""
          defaultSortField="lastSeenAt"
          selectableRows={false}
          columns={columns}
          data={data && data.users}
          loading={loading}
          onRowClicked={(p) => {
            history.push(`/settings/account/users/${p.id}`);
          }}
        />
        <InviteUser open={openInvite} onDismiss={() => setOpenInvite(false)} />
      </Flex>
    </Settings>
  );
}

export default Users;

const InviteUser = (props) => {
  const { addToast } = useToasts();
  const [saveUser, {}] = useMutation(UserMutation);
  const [saveUserTeams, {}] = useMutation(UserTeamMutation, {
    refetchQueries: [{ query: TeamsQuery }],
  });
  const { refetch } = useQuery(UsersQuery, {});
  const teamsQuery = useQuery(TeamsQuery, {});
  const rolesQuery = useQuery(RolesQuery, {});

  const teams = teamsQuery.data?.teams;
  const teamOptions = teams?.map((r) => {
    return { value: r.id, label: r.name, data: r };
  });
  const roles = rolesQuery.data?.roles;

  return (
    <Modal title="Invite User" open={props.open} onDismiss={props.onDismiss}>
      <Formik
        enableReinitialize
        initialValues={{ email: "", memberships: [] }}
        autoComplete={false}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required("Required"),
          memberships: Yup.array()
            .of(
              Yup.object().shape({
                teamId: Yup.string().required("Required"),
              })
            )
            .required("At least one team needs to be added"),
        })}
        onSubmit={(values, actions) => {
          saveUser({
            variables: {
              id: null,
              firstName: values.email,
              email: values.email,
            },
          }).then(
            (response) => {
              let result = response.data?.userMutation;
              if (result?.errors) {
                actions.setErrors(result?.errors);
                ShowError(result?.errors && result?.errors[0]);
              } else {
                let userId = result?.user?.id;

                if (values.memberships && values.memberships.length > 0) {
                  actions.resetForm({ email: "", memberships: [] });
                  saveUserTeams({
                    variables: {
                      id: userId,
                      memberships: values.memberships.map((team) => {
                        return {
                          deleted: false,
                          teamId: team.teamId,
                          roleName: "User",
                        };
                      }),
                    },
                  }).then(
                    (response) => {
                      refetch({ networkOnly: true });
                      let result = response.data?.userTeamMutation;
                      if (result?.errors) {
                        actions.setErrors(result?.errors);
                        addToast(
                          <ShowError message={result?.errors[0]} />,
                          ErrorParams
                        );
                      } else {
                        props.onDismiss();
                        addToast(
                          <ShowSuccess message="Saved!" />,
                          SuccessParams
                        );
                      }
                    },
                    (error) => {
                      addToast(
                        <ShowError message="Save error!" />,
                        ErrorParams
                      );
                    }
                  );
                } else {
                  addToast(<ShowSuccess message="Saved!" />, SuccessParams);
                }
              }
            },
            (error) => {
              addToast(<ShowError message="Save error!" />, ErrorParams);
            }
          );
        }}
      >
        {(formProps) => {
          const { values, errors, setFieldValue, handleBlur, handleSubmit } =
            formProps;

          return (
            <Flex px={4} py={2} flexDirection="column">
              <Text mb={3} variant="muted">
                People you add will receive an invite automatically to finish
                setting up their account.
              </Text>
              <CustomFormGroup
                placeholder="Email"
                label="Email"
                name="email"
                value={values.email}
                onChange={setFieldValue}
                onBlur={handleBlur}
                helperText={errors.email && errors.email}
              />
              <Select
                placeholder="Select teams to add as a user"
                value={values.memberships}
                isMulti
                label="Teams"
                width="100%"
                name="memberships"
                onChange={(name, value) => {
                  let items = [];
                  items = value?.map((item) => {
                    return {
                      id: item.value,
                      teamId: item.value,
                      value: item.value,
                      label: item.label,
                      role: roles?.find((r) => r.name === "User"),
                    };
                  });
                  setFieldValue(name, items);
                }}
                mb={0}
                options={teamOptions}
              />
              <Text variant="label" color="error">
                {errors.memberships &&
                  typeof errors.memberships === "string" &&
                  errors.memberships}
              </Text>
              <Box my={3} />
              <Box>
                <PrimaryButton onClick={handleSubmit}>
                  Invite User
                </PrimaryButton>
              </Box>
            </Flex>
          );
        }}
      </Formik>
    </Modal>
  );
};
InviteUser.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSelected: PropTypes.func,
};
