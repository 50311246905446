import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import { Flex, Text, Button } from "rebass";
import { MODEL_TYPES } from "consts";
import { SatisfactionIcon, SentimentIcon } from "Components/Common/Icons";
import TaskIcon, {
  TaskCategories,
} from "Components/CommandCenter/Tasks/TaskDefaults";
import PropTypes from "prop-types";
import RadioGroupButton from "../Common/RadioGroupButton";
import MenuModal from "./MenuModal";
import { MessageIcon } from "../Common/Icons";
import ReactTooltip from "react-tooltip";

function AddButton({ id, parentType, onSave }) {
  const theme = useTheme();
  const categories = TaskCategories();
  const [open, setOpen] = useState(false);
  const [taskCategory, setTaskCategory] = useState(undefined);
  return (
    <Flex
      ml={2}
      flex={1}
      sx={{ position: "relative" }}
      justifyContent="space-between"
    >
      <Flex>
        <RadioGroupButton
          name="taskCategoryId"
          options={categories?.filter((c) => c.active)}
          onChange={(name, value) => {
            // value?.value
            setTaskCategory(value);
            setOpen("task");
          }}
        />
        <RadioGroupButton
          name="notes"
          options={[
            {
              value: "note",
              label: (
                <Flex
                  flexWrap={1}
                  alignItems="center"
                  data-for={"note"}
                  data-tip={"Note"}
                >
                  <MessageIcon />
                  <ReactTooltip
                    id={"note"}
                    place="top"
                    effect="solid"
                    getContent={(dataTip) => dataTip}
                  />
                </Flex>
              ),
            },
          ]}
          onChange={(name, value) => {
            // value?.value
            setOpen("comment");
          }}
        />
      </Flex>
      <MenuModal
        open={!!open}
        onDismiss={() => setOpen(undefined)}
        onSave={() => {
          setOpen(undefined);
          onSave();
        }}
        formType={open}
        taskCategoryId={taskCategory?.id}
        taskCategoryName={taskCategory?.description}
        id={id}
        parentType={parentType}
      />
    </Flex>
  );
}
AddButton.propTypes = {
  id: PropTypes.string,
  parentType: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  active: PropTypes.bool,
};
export default AddButton;
